import styled from 'styled-components'

const RightPanel = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 400px;
`
export default RightPanel
