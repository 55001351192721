import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import styled from 'styled-components'
import { colors } from '../../../../assets/colors'
import Icon from '../../../../../components/Icon'
import Checkmark from '../../../../../components/Icon/Checkmark'

interface ButtonTextProps {
  fill: string
}

const StyledButton = styled.button<ButtonTextProps>`
  display: flex;
  width: 169px;
  height: 46px;
  background: #ffffff;
  align-items: center;
  padding: 10px;
  border: 0px;
  cursor: pointer;
`
const ButtonText = styled.p<ButtonTextProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.fill};
  margin-left: 10px;
`

const ClipboardButton = ({ url, style }: { url: any; style?: any }) => {
  const [fill, setFill] = useState<string>(colors.darkBlue)
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    if (isCopied) {
      const copiedTimeout = setTimeout(() => {
        setIsCopied(false)
      }, 3000)

      return () => {
        clearTimeout(copiedTimeout)
      }
    }
  }, [isCopied])

  const handleMouseEnter = (e: any) => {
    setFill('#1A659E')
  }

  const handleMouseLeave = (e: any) => {
    setFill(colors.darkBlue)
  }

  const handleMouseDown = (e: any) => {
    setFill('#3A93D8')
  }

  const handleMouseUp = (e: any) => {
    setFill(colors.darkBlue)
  }
  return (
    <CopyToClipboard
      text={url}
      onCopy={() => {
        setIsCopied(true)
      }}
    >
      <StyledButton
        fill={fill}
        style={style}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        {isCopied ? (
          <Checkmark width={'20'} fill={'#1D70B6'} style={{ minWidth: '30px' }} />
        ) : (
          <Icon name={'copyToClipboard'} fill={fill} style={{ minWidth: '30px' }} />
        )}
        <ButtonText fill={fill}>{isCopied ? 'Copied!' : 'Copy Invite Link'}</ButtonText>
      </StyledButton>
    </CopyToClipboard>
  )
}

export default ClipboardButton
