import styled from 'styled-components'
import React, { ReactNode } from 'react'
import { colors } from '../../../../../assets/colors'
import Icon from '../../../../../components/Icon'

const Text = styled.div`
  padding: 15px 0;
`

const UpToDateBoxWrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 90%;
  background-color: ${colors.white};
  color: ${colors.grey};
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  padding: 5px;
`
type UpToDateBoxProps = {
  children?: ReactNode
}
const UpToDateBox = ({ children, ...rest }: UpToDateBoxProps) => {
  return (
    <UpToDateBoxWrapper>
      <Icon name={'dialog'} fill={colors.mediumGrey} width={'60px'} />
      <UpToDateBox.Text style={{ fontWeight: 400, color: colors.mediumGrey }}>You&apos;re up to date</UpToDateBox.Text>
    </UpToDateBoxWrapper>
  )
}

UpToDateBox.Text = Text

export default UpToDateBox
