import React, {
  useRef,
  useState,
  Fragment,
  MouseEvent,
  FocusEvent,
  useEffect,
  ReactNode,
  ChangeEvent,
  SyntheticEvent,
  KeyboardEvent
} from 'react'
import { colors } from '../../../../assets/colors'
import styled from 'styled-components'
import { Recipient } from '../../../../types'

const Container = styled.div`
  position: relative;
  background: ${colors.white};
`

const SelectWrapper = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-height: 40px;
  padding-right: 20px;
`

const SelectLabel = styled.span`
  font-size: 15px;
  color: ${colors.mediumGrey};
  margin-right: 5px;
  font-family: 'Open Sans', sans-serif;
`

const SelectInput = styled.input`
  display: flex;
  border: none;
  background-color: transparent;
  color: ${colors.black};
  outline: none;
  flex: 1;
  max-width: 100%;
  font-size: 16px;
`

const InputRightArea = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px 0;
`

const OptionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: auto;
  background: ${colors.white};
  z-index: 9;
`

const OptionsList = styled.ul`
  list-style: none;
`

const OptionsListItem = styled.li`
  height: 40px;
  min-height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid ${colors.lightSliver};
  color: ${colors.grey};
  cursor: pointer;
`

const SelectedOptionsInlineContainer = styled.div`
  font-size: 14px;
  flex: 1;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const SelectedOption = styled.div`
  font-size: 14px;
  padding: 3px 5px;
  margin: 3px 5px 3px 0;
  border-radius: 2px;
  background: ${colors.mediumGrey};
  color: ${colors.white};
`

type MultiSelectInputItem = {
  id: string
  label: string
}

interface IMultiSelectInput {
  options: MultiSelectInputItem[]
  values: MultiSelectInputItem[]
  onSelect: (selectedItemId: string) => void
  onSearchQuery: (searchQuery: string) => void
  onRemove: () => void
  rightArea: ReactNode
}

const MultiSelectInputDesktop: React.FC<IMultiSelectInput> = ({
  options,
  values,
  onSelect,
  onRemove,
  onSearchQuery,
  rightArea
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    onSearchQuery(searchQuery)
  }, [searchQuery])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  const onFocus = (e: FocusEvent) => {
    setDropdownVisible(true)
  }

  const handleOptionClick = (e: SyntheticEvent, option: Recipient) => {
    e.preventDefault()
    onSelect(option.id)
    setSearchQuery('')
  }

  const handleBlur = (e: FocusEvent) => {
    setDropdownVisible(false)
    setSearchQuery('')
  }

  const handleInputFocus = (e: MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement
    if (target.tagName !== 'DIV') return null

    if (!inputRef) return null

    if (inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handleRemoveSelectedItem = () => {
    if (values.length) {
      onRemove()
    }
  }

  const onKeyDown = (e: KeyboardEvent) => {
    const keyCode = e.keyCode || e.charCode || ''
    const key = e.key || ''
    if (keyCode === 8 && searchQuery.length === 0) {
      handleRemoveSelectedItem()
    }
    if (keyCode === 13 || key === 'Enter') {
      if (inputRef.current) inputRef.current.blur()
      setDropdownVisible(false)
      setSearchQuery('')
    }
  }

  const renderSelectedItems = () => {
    if (!values.length) return null

    if (dropdownVisible) {
      return (
        <Fragment>
          {values.map((item) => (
            <SelectedOption key={item.id}>{item.label}</SelectedOption>
          ))}
        </Fragment>
      )
    }

    return (
      <SelectedOptionsInlineContainer>
        {values
          .map((item) => {
            return item.label
          })
          .join(', ')}
      </SelectedOptionsInlineContainer>
    )
  }

  return (
    <Container>
      <SelectWrapper id="selectWrapper" onClick={handleInputFocus}>
        <SelectLabel>To:</SelectLabel>
        {renderSelectedItems()}
        <SelectInput
          ref={inputRef}
          type="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={onFocus}
          value={searchQuery}
          tabIndex={-1}
          style={{
            minWidth: dropdownVisible ? '50px' : '5px',
            maxWidth: dropdownVisible ? '100%' : '5px'
          }}
          onKeyDown={onKeyDown}
        />
        <InputRightArea>{rightArea}</InputRightArea>
      </SelectWrapper>
      {dropdownVisible && options.length ? (
        <OptionsContainer>
          <OptionsList>
            {options.map((option) => (
              <OptionsListItem key={option.id} onMouseDown={(e) => handleOptionClick(e, option)}>
                {option.label}
              </OptionsListItem>
            ))}
          </OptionsList>
        </OptionsContainer>
      ) : null}
    </Container>
  )
}

export default MultiSelectInputDesktop
