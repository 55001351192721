import styled from 'styled-components'
import { colors } from '../assets/colors'
import { isDesktop } from '../constants'

const AppContainer = styled.main`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`

export default AppContainer
