import styled from 'styled-components'

const DialogDescription = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #707070;
  width: 242px;
`

export default DialogDescription
