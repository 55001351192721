import { create } from 'zustand'

export interface AudioMessageState {
  messageId: string | null
  currentMessageId: string | null
  audioMessagesSrcList: { [id: string]: string }
  playing: boolean
  duration: number
  currentTime: {}
  loading: boolean
  seek: number
  speed: number
  actions: {
    setAudioMessageId: (messageId: string | null) => void
    setCurrentMessageId: (messageId: string | null) => void
    setAudioPlaying: (playing: boolean) => void
    setAudioDuration: (duration: number) => void
    setAudioLoading: (loading: boolean) => void
    setAudioCurrentTime: (messageId: string, currentTime: number) => void
    setAudioSeek: (seek: number) => void
    setAudioSpeed: (speed: number) => void
    setAudioMessagesSrcList: (audioMessagesSrcList: { [id: string]: string }) => void
    clearAudioPlayer: () => void
  }
}

const INITIAL_STATE: Partial<AudioMessageState> = {
  messageId: '',
  audioMessagesSrcList: {},
  playing: false,
  duration: 0,
  currentTime: {},
  loading: false,
  seek: 0,
  speed: 1
}

export const useAudioMessageStore = create<AudioMessageState>((set, get) => ({
  messageId: '',
  currentMessageId: '',
  playing: false,
  audioMessagesSrcList: {},
  duration: 0,
  currentTime: {},
  loading: false,
  seek: 0,
  speed: 1,
  actions: {
    setAudioMessageId: (messageId: string) => set({ messageId }),
    setCurrentMessageId: (currentMessageId: string) => set({ currentMessageId }),
    setAudioPlaying: (playing: boolean) => set({ playing }),
    setAudioDuration: (duration: number) => set({ duration }),
    setAudioLoading: (loading: boolean) => set({ loading }),
    setAudioCurrentTime: (id: string, time: number) => {
      return set((state) => {
        const currentTime = { ...state.currentTime }
        currentTime[id] = time
        return { currentTime }
      })
    },
    setAudioSeek: (seek: number) => set({ seek }),
    setAudioSpeed: (speed: number) => set({ speed }),
    setAudioMessagesSrcList: (newAudioMessageSrcObj: { [id: string]: string }) => {
      const stateAudioMessageSrcObj = get().audioMessagesSrcList
      // Update with new object
      const updatedAudioMessagesSrcList = { ...stateAudioMessageSrcObj, ...newAudioMessageSrcObj }
      set({ audioMessagesSrcList: updatedAudioMessagesSrcList })
    },
    clearAudioPlayer: () => set({ ...INITIAL_STATE })
  }
}))

export const useAudioMessageActions = () => useAudioMessageStore((state) => state.actions)
