import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import WaveSurfer from 'wavesurfer.js'

export interface AudioPlayerState {
  waveSurfer: WaveSurfer | null // Assuming waveSurfer can be null initially
  actions: {
    setWaveSurfer: (waveSurfer: WaveSurfer) => void
  }
}

export const useAudioPlayerStore = create<AudioPlayerState>((set) => ({
  waveSurfer: null, // Initialized as null or however you wish to initialize it
  actions: {
    setWaveSurfer: (waveSurfer: WaveSurfer) =>
      set(() => ({
        waveSurfer
      }))
  }
}))

export const useWaveSurfer = (): [WaveSurfer | null, (waveSurfer: WaveSurfer) => void] => {
  return useAudioPlayerStore((state) => [state.waveSurfer, state.actions.setWaveSurfer])
}
