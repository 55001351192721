import styled, { StyledComponentBase } from 'styled-components'
import { Link } from 'react-router-dom'
import { colors } from '../../../../../../assets/colors'

interface IStyledCreateContactForm {
  Label: StyledComponentBase<'label', any>
  Input: StyledComponentBase<'input', any>
  TextAreaInput: StyledComponentBase<'textarea', any>
  RadioWrapper: StyledComponentBase<'div', any>
  Wrapper: StyledComponentBase<'div', any>
  GroupSectionWrapper: StyledComponentBase<'div', any>
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`

const GroupSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
`

const Label = styled.label`
  font-size: 12px;
  color: ${colors.mediumGrey};
  margin-bottom: 5px;
  display: block;
`

const Input = styled.input`
  border-radius: 4px;
  border: 1px solid rgba(112, 112, 112, 0.2);
  height: 46px;
  padding: 5px 10px;
  width: 100%;

  &::placeholder {
    color: rgba(23, 23, 23, 0.3);
  }
`

const TextAreaInput = styled.textarea`
  border-radius: 4px;
  border: 1px solid rgba(112, 112, 112, 0.2);
  height: 92px;
  padding: 5px 10px;
  width: 100%;
  resize: none;
`

const RadioWrapper = styled.div`
  width: 100%;
  padding: 20px 15px;
  border: ${({ active }: { active?: boolean }) =>
    active ? `1px solid ${colors.darkBlue}` : `1px solid rgba(112, 112, 112, 0.20)`};
  font-weight: ${({ active }: { active?: boolean }) => (active ? 700 : 400)};
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
`

export const StyledCreateContactForm: IStyledCreateContactForm = {
  Label,
  Input,
  TextAreaInput,
  RadioWrapper,
  Wrapper,
  GroupSectionWrapper
}
