import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from '../../../../../../../components/Button'
import BackArrow from '../../../../../../../components/Icon/BackArrow'
import StyledLink from '../../../../../../../components/Button/Link'
import useIsMobile from '../../../Shared/Common/Hooks/useIsMobile'
import { useHistory } from 'react-router'

const SetupForm = () => {
  const stripe = useStripe()
  const elements = useElements()
  const isMobile = useIsMobile()
  const history = useHistory()
  const toastId = React.useRef(null)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null
    }

    toastId.current = toast('Updating payment method...', {
      type: 'info',
      autoClose: 5000
    })
    setLoading(true)
    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/billing?tab=payment-options-update-status`
      }
    })
    setLoading(false)

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      console.error('Update payment method failed', error)

      toast.dismiss(toastId.current)
      toastId.current = toast.error(`Update payment method failed: ${error.message}`, {
        autoClose: 5000
      })
    } else {
      // Your customer might be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <StyledLink
        style={{
          position: 'absolute',
          left: isMobile ? '20px' : '50px',
          top: isMobile ? '20px' : '50px',
          display: 'flex',
          alignItems: 'center'
        }}
        onClick={() =>
          history.push(isMobile ? '/billing?tab=payment-options&action=add' : '/options/billing?tab=payment-options')
        }
      >
        <BackArrow style={{ marginRight: '10px' }} />
        Back
      </StyledLink>
      <p style={{ textAlign: 'center', marginBottom: '30px', fontSize: '15px', fontWeight: 600 }}>
        Add your credit or debit card
      </p>
      <PaymentElement />
      <div style={{ marginTop: '30px' }}>
        <Button isLoading={loading} type="submit">
          Submit
        </Button>
      </div>
    </form>
  )
}

export default SetupForm
