import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../assets/colors'
import { IChildren, ReactTransitionGroupStateType } from '../../../types'
import { createPortal } from 'react-dom'
import { Transition } from 'react-transition-group'
import { ModalAction } from './ModalAction'

const BackgroundContainer = styled.div<ReactTransitionGroupStateType>`
  transition: 200ms;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.25);
  opacity: ${(props) => (props.state === 'entered' ? 1 : 0)};
  z-index: 99999999;
`

const ActionsModalContainer = styled.div<ReactTransitionGroupStateType>`
  transition: opacity 250ms, transform 300ms;
  width: 95%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  color: ${colors.contrastBlue};
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  position: relative;
  opacity: ${(props) => (props.state === 'entered' ? 1 : 0)};
  transform: ${(props) => (props.state === 'entering' ? 'scale(1.15)' : 'scale(1)')};
`

interface IActionsModal extends IChildren {
  show?: boolean
  onCancel: () => void
}

const ActionsModal = ({ show, children, onCancel }: IActionsModal) => {
  const modalRoot = document.getElementById('modal')

  if (!modalRoot) return null
  return createPortal(
    <Transition in={show} timeout={350} unmountOnExit mountOnEnter>
      {(state) => (
        <BackgroundContainer state={state}>
          <ActionsModalContainer state={state}>{children}</ActionsModalContainer>
          <ActionsModalContainer state={state}>
            <ModalAction onClick={onCancel}>Cancel</ModalAction>
          </ActionsModalContainer>
        </BackgroundContainer>
      )}
    </Transition>,
    modalRoot
  )
}

ActionsModal.Action = ModalAction

export default ActionsModal
