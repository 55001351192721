import { useHistory } from 'react-router'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import APIClient from '../../../../../../apiClient'
import { showInfoModal } from '../../../../../../store/modals'
import { ContactItemType, GroupItemType } from '../../../../../../types'

interface IProps {
  currentGroup?: GroupItemType
  groupContacts?: ContactItemType[]
}

const useEditGroup = ({ currentGroup, groupContacts }: IProps) => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const [contactsSelectVisible, setContactsSelectVisible] = useState(false)
  const [selectedContactsIds, setSelectedContactsIds] = useState<string[]>([])
  const [groupName, setGroupName] = useState('')
  const [error, setError] = useState('')
  const [saving, setSaving] = useState(false)
  const [confirmShow, setConfirmShow] = useState(false)

  useEffect(() => {
    setGroupName(currentGroup?.name)
  }, [currentGroup])

  useEffect(() => {
    setSelectedContactsIds(groupContacts?.map((c) => c.id) || [])
  }, [groupContacts])

  const toggleContactId = (id: string) => {
    if (selectedContactsIds.includes(id)) {
      setSelectedContactsIds((prevState) => prevState.filter((oldId) => oldId !== id))
    } else {
      setSelectedContactsIds((prevState) => [...prevState, id])
    }
  }

  const showContactsSelect = () => {
    setContactsSelectVisible(true)
  }

  const hideContactsSelect = () => {
    setContactsSelectVisible(false)
  }

  const editGroup = async () => {
    if (!groupName) {
      setError('Group name is required')
      return
    }
    if (groupName) {
      try {
        setSaving(true)
        const updateGroupResponse = await APIClient.contactGroupUpdate({
          id: currentGroup.id,
          req: {
            name: groupName,
            contacts: {
              add: selectedContactsIds
                ?.filter((memId) => !groupContacts?.map((c) => c.id)?.find((memberId) => memberId === memId))
                ?.map((memId) => {
                  return { id: memId, phone_numbers: ['kate_number'] }
                }),
              remove: groupContacts
                ?.map((c) => c.id)
                ?.filter((memId) => !selectedContactsIds?.find((memberId) => memberId === memId))
            }
          }
        })
        if (updateGroupResponse.status >= 300)
          throw new Error(`group update error ${updateGroupResponse.status}, ${updateGroupResponse.statusText}`)

        showInfoModal('Group edited')
        await queryClient.invalidateQueries(['contacts'])
        await queryClient.invalidateQueries(['groups'])

        history.push(`/groups?id=${currentGroup.id}`)
      } catch (e) {
        console.log('error on group update ===', e)
        window.alert('Ups, group cannot be updated')
      } finally {
        setSaving(false)
      }
    }
  }

  const deleteFunction = async () => {
    try {
      const deleteResponse = await APIClient.contactGroupDelete(currentGroup.id)
      if (deleteResponse.status >= 300)
        throw new Error(`status = ${deleteResponse.status}, ${deleteResponse.statusText}`)

      await queryClient.invalidateQueries(['groups'])
      await queryClient.invalidateQueries(['contacts'])
      history.push('/groups')
    } catch (e) {
      console.log('error on group delete ===', e)
      window.alert('Ups, group cannot be deleted. Please reload the page and try again.')
    }
  }

  return {
    history,
    editGroup,
    saving,
    setGroupName,
    setError,
    groupName,
    error,
    showContactsSelect,
    selectedContactsIds,
    toggleContactId,
    setConfirmShow,
    confirmShow,
    deleteFunction,
    contactsSelectVisible,
    hideContactsSelect
  }
}

export default useEditGroup
