import React from 'react'
import Description from '../../../../../../components/Icon/Description'
import StyledTextArea from '../../../../StyledTextArea'
import Subtitle from '../../../../Subtitle'
import InputContainer from '../../components/InputContainer'
import './style.css'
import styled from 'styled-components'

const Container = styled.div`
  margin-top: 5%;
  margin-bottom: 5%;
`

interface Props {
  value: string
  handleChange: (
    eventOrPath: string | React.ChangeEvent<any>
  ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void)
}

const DescriptionField = ({ value, handleChange }: Props) => {
  return (
    <Container>
      <InputContainer>
        <Description />
        <Subtitle>Description</Subtitle>
      </InputContainer>
      <StyledTextArea
        name={'description'}
        value={value}
        onChange={handleChange}
        placeholder="Add description"
        rows={4}
        cols={50}
      ></StyledTextArea>
    </Container>
  )
}

export default DescriptionField
