import React from 'react'
import WaitingRoom from '../../../../../components/blocks/WaitingRoom'
import SwitchButton from '../../../../../../components/Button/SwitchButton'
import styled from 'styled-components'
import useAudio from '../hooks/useAudio'
import { useVideoActions, useVideoStore } from '../../../../../store/video'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
`

const AudioVideoSwitch = () => {
  const { toggleAudio, toggleVideo } = useVideoActions()
  const audio = useVideoStore((state) => state.audio)
  const video = useVideoStore((state) => state.video)
  const volume = useAudio(true)

  return (
    <Container>
      <WaitingRoom.SwitchContainer>
        Camera
        <SwitchButton isActive={video} onClick={() => toggleVideo()} />
      </WaitingRoom.SwitchContainer>
      <WaitingRoom.SwitchContainer>
        Audio
        {audio && <WaitingRoom.AudioStrength strength={Math.round(volume / 10)} />}
        <SwitchButton isActive={audio} onClick={() => toggleAudio()} />
      </WaitingRoom.SwitchContainer>
    </Container>
  )
}

export default AudioVideoSwitch
