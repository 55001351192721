import { create } from 'zustand'
import APIClient from '../apiClient'
import { persist } from 'zustand/middleware'
import * as Sentry from '@sentry/react'

interface UserStore {
  user: IUser
  avatar: string | null
  isAuthenticated: boolean
  accountNeedAttention: boolean
  actions: {
    fetchUser: () => Promise<any>
    fetchUserAvatar: () => Promise<any>
    setAuthenticated: (isAuthenticated: boolean) => void
    setAcccountNeedAttention: (isAuthenticated: boolean) => void
    clearUser: () => void
  }
}
export const useUserStore = create<UserStore>()(
  persist(
    (set, get) => ({
      user: null,
      avatar: null,
      isAuthenticated: false,
      accountNeedAttention: false,
      actions: {
        fetchUser: async () => {
          if (!get().isAuthenticated) return
          const { data: user } = await APIClient.account()
          if (user?.account_number)
            Sentry.setUser({
              id: user?.account_number
            })
          set({ user })
        },
        fetchUserAvatar: async () => {
          if (!get().isAuthenticated) return
          const { data: userAvatar } = await APIClient.getUserPhoto()
          set({ avatar: userAvatar?.Location })
        },
        setAuthenticated: (isAuthenticated) => {
          if (!isAuthenticated) {
            localStorage.setItem('user-storage', '')
          }
          return set({ isAuthenticated })
        },
        clearUser: () => set({ user: null, avatar: null, isAuthenticated: false }),
        setAcccountNeedAttention: (accountNeedAttention) => set({ accountNeedAttention })
      }
    }),
    {
      name: 'user-storage',
      partialize: (state) => ({ user: state.user, avatar: state.avatar, isAuthenticated: state.isAuthenticated })
    }
  )
)

export interface IUser {
  hqa?: boolean
  first_name: string
  last_name: string
  eng_beta_tester?: boolean
  access_numbers: (string | null)[]
  account_number: string
  katemsg_api_key: string
  jwt: string
  aws_api_endpoint: string
  billing_provider?: string
  phone_settings: {
    voice_mail_greeting?: boolean
    name_recording?: boolean
    transfer_voice_mail?: boolean
    home?: {
      phone_number: string
      transfer: boolean
    }
    mobile?: {
      phone_number: string
      transfer: boolean
    }
    cell?: {
      phone_number: string
      transfer: boolean
    }
    office?: {
      phone_number: string
      transfer: boolean
    }
    [key: string]: any
  }
}

export const useUserActions = () => useUserStore((state) => state.actions)

export const useUser = () => useUserStore((state) => state.user)
export const useAvatar = () => useUserStore((state) => state.avatar)
export const useAuth = () => useUserStore((state) => state.isAuthenticated)
