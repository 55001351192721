import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { colors } from '../../assets/colors'
import SideButtons from './SideButtons'
import Title from './Title'
import IconButton from './IconButton'
import EndButtons from './EndButtons'
import { Profile } from './Profile'

const HeaderContainer = styled.header`
  position: sticky;
  top: 0;
  height: 50px;
  min-height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colors.darkBlue};
  background: linear-gradient(90deg, ${colors.blue} 0%, ${colors.darkBlue} 100%);
  color: ${colors.white};
  padding: 5px 15px;
  z-index: 10;
`

type HeaderProps = {
  children?: ReactNode
  style?: object
}

const Header = ({ children, ...rest }: HeaderProps) => <HeaderContainer {...rest}>{children}</HeaderContainer>

Header.SideButtons = SideButtons
Header.EndButtons = EndButtons
Header.Title = Title
Header.IconButton = IconButton
Header.Profile = Profile

export default Header
