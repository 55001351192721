import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../assets/colors'
import Icon from '../../Icon'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 0 0;
  padding: 0 15px 0 10px;
  position: relative;
`

const StyledInput = styled.input`
  font-size: 14px;
  border: none;
  color: ${colors.black};
  outline: none;
  height: 40px;
  width: 100%;
  margin-left: 15px;
  background-color: ${colors.backgroundGrey};
  padding: 8px 25px 8px 30px;
  border-radius: 10px;
`

const CancelButton = styled.button`
  outline: 0;
  border: 0;
  text-decoration: none;
  color: ${colors.lightGrey};
  background-color: transparent;
  font-size: 14px;
  padding: 0 10px 0 0;
`

const StyledIcon = styled(Icon)`
  position: absolute;
  width: 20px;
  height: 20px;
  min-width: 20px;
  max-width: 20px;
`

const LeftIcon = styled(StyledIcon)`
  margin-left: 5px;
  top: 10px;
  left: 25px;
`
const RightIcon = styled(StyledIcon)``

interface SearchProps {
  showCancel?: boolean
  onChange?: (e: { target: { value: string } }) => void
  onClick?: () => void
  onFocus?: () => void
  value?: string
}

const SearchInput = ({ showCancel, onChange, onClick, onFocus, ...props }: SearchProps) => {
  const history = useHistory()
  const location = useLocation()

  const onRemoveFilters = () => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete('filter')
    history.replace({
      search: queryParams.toString()
    })
  }

  return (
    <Wrapper>
      <LeftIcon name={'search'} fill={colors.lightGrey} />
      {props.value && (
        <div style={{ position: 'absolute', top: 10, right: 20, cursor: 'pointer' }} onClick={onRemoveFilters}>
          <RightIcon name={'exit'} fill={colors.lightGrey} width={'20px'} height={'20px'} />
        </div>
      )}
      <StyledInput placeholder="Search" onChange={onChange} onFocus={onFocus} {...props} />
    </Wrapper>
  )
}

export default SearchInput
