import styled from 'styled-components'

const HiddenInput = styled.input`
  border: 0;
  outline: 0;
  padding: 3px;
  margin: 3px;
  border-radius: 3px;
  font-size: 14px;
`

export default HiddenInput
