import { useHistory } from 'react-router'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import APIClient from '../../../../../../apiClient'
import { showInfoModal } from '../../../../../../store/modals'

const useCreateGroup = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const [contactsSelectVisible, setContactsSelectVisible] = useState(false)
  const [selectedContactsIds, setSelectedContactsIds] = useState<string[]>([])

  const [groupName, setGroupName] = useState('')
  const [error, setError] = useState('')
  const [saving, setSaving] = useState(false)

  const toggleContactId = (id: string) => {
    if (selectedContactsIds.includes(id)) {
      setSelectedContactsIds((prevState) => prevState.filter((oldId) => oldId !== id))
    } else {
      setSelectedContactsIds((prevState) => [...prevState, id])
    }
  }

  const showContactsSelect = () => {
    setContactsSelectVisible(true)
  }

  const hideContactsSelect = () => {
    setContactsSelectVisible(false)
  }

  const createGroup = async () => {
    if (!groupName) {
      setError('Group name is required')
      return
    }
    if (groupName) {
      try {
        setSaving(true)
        const createGroupResponse = await APIClient.contactGroupCreate({ name: groupName })
        if (createGroupResponse.status >= 300)
          throw Error(`group create error ${createGroupResponse.status}, ${createGroupResponse.statusText}`)

        const updateGroupResponse = await APIClient.contactGroupUpdate({
          id: createGroupResponse.data.id,
          req: {
            contacts: {
              add: selectedContactsIds.map((memId) => {
                return { id: memId, phone_numbers: ['kate_number'] }
              })
            }
          }
        })
        if (updateGroupResponse.status >= 300)
          throw new Error(`group update error ${updateGroupResponse.status}, ${updateGroupResponse.statusText}`)

        showInfoModal('Group created')

        await queryClient.invalidateQueries(['groups'])
        await queryClient.invalidateQueries(['contacts'])
        history.push(`/groups?id=${createGroupResponse.data.id}`)
      } catch (e) {
        console.log('error on group create ===', e)
        window.alert('Ups, group cannot be created')
      } finally {
        setSaving(false)
      }
    }
  }

  return {
    history,
    createGroup,
    saving,
    setGroupName,
    setError,
    error,
    showContactsSelect,
    groupName,
    selectedContactsIds,
    toggleContactId,
    contactsSelectVisible,
    hideContactsSelect
  }
}

export default useCreateGroup
