import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useFormik } from 'formik'
import { colors } from '../../../../assets/colors'
import { ContactItemType } from '../../../../types'
import Header from '../../../../components/Header'
import { InlineButton } from '../../../../components/Button/InlineButton'
import Input from '../../../../components/Input'
import Form from '../../../../components/Input/Form'
import LongTextInput from '../../../../components/Input/LongTextInput'
import Divider from '../../../../components/Divider'
import SearchInput from '../../../../components/Input/SearchInput'
import MessageList from '../../../../components/blocks/MessageList'
import Field from '../../../../components/Field'
import Icon from '../../../../components/Icon'
import { Loader } from '../../../../components/Loader/Loader'
import APIClient from '../../../../apiClient'
import ChangeViewButton from '../../../../components/Button/ChangeViewButton'
import ActionsModal from '../../../../components/Modal/ActionsModal'
import { showAlertModal, showInfoModal } from '../../../../store/modals'
import { useQueryClient } from '@tanstack/react-query'
import { useContactGroupListQuery } from '../../../../hooks/queries/useContactGroupListQuery'
import { useGroupsQuery } from '../../../../hooks/queries/useGroupsQuery'

const ContactCreate: React.FC = () => {
  const history = useHistory()
  const urlParams = new URLSearchParams(history.location.search)
  const search = urlParams.get('filter') || ''
  const defaultContactValues: {} | any = history.location.state

  const queryClient = useQueryClient()

  const { data: groupsSearch } = useContactGroupListQuery(search, 'groups')
  const { data: groups } = useGroupsQuery()

  const data = search ? groupsSearch : groups

  const [mobileTouched, setMobileTouched] = useState<boolean>(false)
  const [selectGroups, setSelectGroups] = useState<boolean>(false)
  const [searchTouched, setTouched] = useState<boolean>(false)
  const [lookupTouched, setLookupTouched] = useState<boolean>(false)
  const [selected, setSelected] = useState<{ [id: string]: boolean }>({})
  const [kateNumber, setKateNumber] = useState<string>('')
  const [valid, setValid] = useState<boolean>(false)
  const [isSaving, setSaving] = useState<boolean>(false)
  const [selectAccount, setSelectAccount] = useState<boolean>(false)
  const [accounts, setAccounts] = useState<{ [key: string]: any }[]>([])

  const parseNumber = useCallback((value: string) => {
    const re = /[\D+\W+]/
    const matchingRe = /^\(\d{3}\) \d{3}-\d{4}$/
    if (value.length === 10 && !value.match(re)) {
      setValid(true)
      return `(${value[0]}${value[1]}${value[2]}) ${value[3]}${value[4]}${value[5]}-${value[6]}${value[7]}${value[8]}${value[9]}`
    }
    if (value.match(matchingRe)) {
      setValid(true)
    } else {
      setValid(false)
    }

    return value
  }, [])

  const formik = useFormik({
    initialValues: {
      id: 'new',
      first_name: '',
      last_name: '',
      kate_number: ''
    },
    onSubmit: async (values: ContactItemType) => {
      const valid = validate(values)
      if (valid) {
        try {
          // @ts-ignore
          delete values.id
          const groupsToAdd = values.groups
          delete values.groups
          const createResponse = await APIClient.contactCreate(values)
          if (createResponse.status >= 300)
            throw Error(`contact create error ${createResponse.status}, ${createResponse.statusText}`)

          // adding contact to all selected groups
          groupsToAdd &&
            (await Promise.all(
              groupsToAdd.map(async (group) => {
                const updateResponse = await APIClient.contactGroupUpdate({
                  id: group.id,
                  req: {
                    name: group.name,
                    contacts: {
                      add: [
                        {
                          id: createResponse.data.id,
                          phone_numbers: ['kate_number']
                        }
                      ]
                    }
                  }
                })
                if (updateResponse.status > 300)
                  throw new Error(`group update error ${updateResponse.status}, ${updateResponse.statusText}`)
              })
            ))

          showInfoModal('Contact added')

          await queryClient.invalidateQueries(['contacts'])
          await queryClient.invalidateQueries(['groups'])

          history.push('/contacts')
        } catch (e) {
          console.log('Error on save ===', e)
          showAlertModal('Ups, unable to create contact')
          history.push('/contacts')
        }
      }
    }
  })

  const { values, handleChange, handleBlur, handleSubmit, handleReset, isSubmitting, setValues, setFieldValue } = formik

  const findUser = useCallback(async () => {
    setSaving(true)
    if (valid) {
      try {
        const lookupResponse = await APIClient.phoneReverseLookup({ phone_number: kateNumber })
        if (!lookupResponse.data) throw new Error(`${lookupResponse.status}, ${lookupResponse.statusText}`)
        if (lookupResponse.data.length > 1) {
          setAccounts(lookupResponse.data)
          setSelectAccount(true)
        } else {
          const newValues = {
            id: 'new',
            first_name: lookupResponse.data[0].first_name,
            last_name: lookupResponse.data[0].last_name,
            email2: lookupResponse.data[0].emails[0],
            kate_number: kateNumber
          }
          setValues(newValues)
        }
      } catch (e) {
        console.log('error on user lookup ===', e)
        showAlertModal(
          "It appears that this is not a CommuniKate number. We've moved the number to the Mobile Phone field."
        )
        setFieldValue('mobile_phone', kateNumber)
        setKateNumber('')
      }
    }
    setSaving(false)
  }, [kateNumber, valid])

  const chooseAccount = useCallback(
    (account) => {
      setSaving(true)
      const newValues = {
        id: account.account_number,
        first_name: account.first_name,
        last_name: account.last_name,
        email2: account.emails[0],
        kate_number: kateNumber
      }
      setValues(newValues)
      setSelectAccount(false)
      setSaving(false)
    },
    [kateNumber]
  )

  const createMany = useCallback(async () => {
    setSaving(true)
    setSelectAccount(false)
    try {
      await Promise.all(
        accounts.map(async (account) => {
          const accountValues = {
            first_name: account.first_name,
            last_name: account.last_name,
            email2: account.emails[0],
            kate_number: kateNumber
          }
          const createResponse = await APIClient.contactCreate(accountValues)
          if (createResponse.status >= 300) {
            throw Error(`contact edit error ${createResponse.status}, ${createResponse.statusText}`)
          }

          await queryClient.invalidateQueries(['contacts'])
        })
      )
      showInfoModal('Contacts created')
      setSaving(false)
      history.push('/contacts')
    } catch (e) {
      console.log('error on Contact create ===', e)
      showAlertModal('Contact could not be created')
      setSaving(false)
    }
  }, [kateNumber, accounts])

  const selectGroupFunction = useCallback(
    async (groupId: string) => {
      if (!values.groups) {
        values.groups = []
      }
      const shouldDeselect = new Set(values.groups.map((group) => group.id)).has(groupId)
      if (shouldDeselect) {
        values.groups = values.groups.filter((group) => group.id !== groupId)
      } else {
        const group = groups.find((group) => group.id === groupId)
        values.groups.push(group)
      }
      setSelected({ ...selected, [groupId]: !shouldDeselect })
    },
    [values, selected, groups]
  )

  // set form data from route state
  useEffect(() => {
    if (!defaultContactValues) return
    const mobilePhone = defaultContactValues.source || ''
    const kateAccount = defaultContactValues.source_kate_account || ''

    // abort when no source
    if (!mobilePhone) return

    // set form values when kate account detected
    if (kateAccount) {
      const newValues = {
        id: 'new',
        first_name: kateAccount.first_name,
        last_name: kateAccount.last_name,
        kate_number: ''
      }
      setValues(newValues)
    }

    setKateNumber(parseNumber(mobilePhone))
  }, [defaultContactValues])

  // Find user by kate number
  useEffect(() => {
    if (!kateNumber) return
    findUser()
  }, [kateNumber])

  if (lookupTouched) {
    return (
      <>
        <Header>
          <Header.SideButtons>
            <InlineButton onClick={() => setLookupTouched(false)}>Cancel</InlineButton>
          </Header.SideButtons>
          <Header.Title>
            <span>Lookup</span>
          </Header.Title>
          <Header.EndButtons>
            <InlineButton
              style={valid ? {} : { color: colors.lightGrey }}
              onClick={async () => {
                await findUser()
                setLookupTouched(false)
              }}
            >
              Done
            </InlineButton>
          </Header.EndButtons>
        </Header>
        <div style={{ height: '100%', width: '100%', padding: '15px 0', backgroundColor: colors.silver }}>
          <Divider />
          <Input style={{ border: 0 }}>
            <Input.Label>CommuniKate:</Input.Label>
            <Input.StyledInput
              type="text"
              name={'communiKate'}
              onChange={(event: { target: { value: string } }) => setKateNumber(parseNumber(event.target.value))}
              value={kateNumber}
            />
          </Input>
          <Divider />
        </div>
        {isSaving && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: colors.lightSliver,
              opacity: '40%',
              paddingTop: '50%'
            }}
          >
            <Loader width={'20px'} height={'20px'} fill={colors.grey} />
          </div>
        )}
      </>
    )
  }

  if (selectGroups) {
    return (
      <>
        <Header>
          <Header.SideButtons>
            <InlineButton onClick={() => setSelectGroups(false)}>Contact</InlineButton>
          </Header.SideButtons>
          <Header.Title>Select Groups</Header.Title>
          <Header.SideButtons />
        </Header>
        <SearchInput
          onFocus={() => setTouched(true)}
          onClick={() => {
            history.replace(`contactCreate`)
            setTouched(false)
          }}
          onChange={(e: { target: { value: string } }) => history.replace(`contactCreate?filter=${e.target.value}`)}
          showCancel={searchTouched}
          value={search}
        />
        <MessageList>
          {data?.length ? (
            data.map((group) => (
              <div key={group.id}>
                <Field onClick={() => selectGroupFunction(group.id)}>
                  <Field.InfoContainer>
                    <Field.Content>{group.name}</Field.Content>
                  </Field.InfoContainer>
                  {selected[group.id] ? <Icon name={'checkmark'} fill={colors.darkBlue} width={'20px'} /> : null}
                </Field>
                <Divider />
              </div>
            ))
          ) : (
            // eslint-disable-next-line react/no-unescaped-entities
            <MessageList.Header>You don't have any groups yet.</MessageList.Header>
          )}
        </MessageList>
      </>
    )
  }
  return (
    <>
      <Header>
        <Header.SideButtons>
          <InlineButton onClick={() => history.goBack()}>Cancel</InlineButton>
        </Header.SideButtons>
        <Header.Title>
          <span>New Contact</span>
        </Header.Title>
        <Header.EndButtons>
          {isSubmitting ? (
            <Loader width={'20px'} height={'20px'} fill={colors.white} style={{ left: '90%' }} />
          ) : (
            <InlineButton onClick={handleSubmit}>Done</InlineButton>
          )}
        </Header.EndButtons>
      </Header>
      <Form
        autoComplete="off"
        onReset={handleReset}
        style={{
          backgroundColor: colors.silver,
          width: '100%',
          height: '100%'
        }}
      >
        <ChangeViewButton
          style={{
            width: '70%',
            marginLeft: '30%',
            height: '40px',
            fontSize: '14px'
          }}
        >
          <ChangeViewButton.Button onClick={() => setLookupTouched(true)}>
            {values.kate_number || '( CommuniKate )'}
          </ChangeViewButton.Button>
          <Icon name={'chevronRight'} width={'20px'} fill={colors.mediumGrey} />
        </ChangeViewButton>
        <Input
          style={{
            width: '70%',
            marginLeft: '30%',
            height: '40px',
            fontSize: '14px'
          }}
        >
          <Input.StyledInput
            autoComplete="off"
            name="first_name"
            placeholder="First"
            type="text"
            value={values.first_name}
            onChange={handleChange}
          />
        </Input>
        <Input
          style={{
            width: '70%',
            marginLeft: '30%',
            height: '40px',
            fontSize: '14px'
          }}
        >
          <Input.StyledInput
            autoComplete="off"
            name="last_name"
            placeholder="Last"
            type="text"
            value={values.last_name}
            onChange={handleChange}
          />
        </Input>
        <div style={{ width: '100%', minHeight: '30px' }} />
        <Divider marginLeft={'0px'} />
        <ChangeViewButton>
          <ChangeViewButton.Label>Groups:</ChangeViewButton.Label>
          <ChangeViewButton.Button onClick={() => setSelectGroups(true)}>
            {values?.groups?.map((group) => (
              <ChangeViewButton.Value key={group.id}>{group.name}</ChangeViewButton.Value>
            )) || '( Select Groups )'}
          </ChangeViewButton.Button>
        </ChangeViewButton>
        <Input>
          <Input.Label>Work Email:</Input.Label>
          <Input.StyledInput
            autoComplete="off"
            type="email"
            name="email2"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email2}
          />
        </Input>
        {mobileTouched || values.mobile_phone ? (
          <Input>
            <Input.Label>Mobile Phone:</Input.Label>
            <Input.StyledInput
              autoComplete="off"
              autoFocus
              type="text"
              name="mobile_phone"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.mobile_phone}
            />
          </Input>
        ) : (
          <Input>
            <Input.Label>Mobile Phone:</Input.Label>
            <Input.StyledInput
              autoComplete="off"
              autoFocus
              type="text"
              name="mobile_phone"
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={() => setMobileTouched(true)}
              value={values.mobile_phone}
              placeholder={'add mobile phone'}
            />
            <Icon name={'add'} width={'30px'} fill={colors.mediumGrey} />
          </Input>
        )}
        <LongTextInput
          autoComplete="off"
          placeholder={'tap to add contact note'}
          name="extra_info"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.extra_info}
        />
      </Form>
      <ActionsModal show={selectAccount} onCancel={() => setSelectAccount(false)}>
        {accounts.map((account) => (
          <ActionsModal.Action key={account.account_number} onClick={() => chooseAccount(account)}>
            {account.first_name} {account.last_name}
          </ActionsModal.Action>
        ))}
        <ActionsModal.Action onClick={createMany}>both accounts</ActionsModal.Action>
      </ActionsModal>
    </>
  )
}

const validate = (values: ContactItemType) => {
  const errors = []

  if (!values.first_name) {
    errors.push('First')
  }

  const errorMessage = `Please enter ${errors.join(', ')}`
  if (Object.keys(errors).length) {
    showAlertModal(errorMessage)
  }
  return !Object.keys(errors).length
}

export default ContactCreate
