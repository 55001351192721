import styled from 'styled-components'
import { colors } from '../../assets/colors'

const CaptionText = styled.span`
  font-size: 12px;
  color: ${colors.mediumGrey};
  background-color: transparent;
  width: 100%;
  text-align: center;
  padding: 20px 0;
`

export default CaptionText
