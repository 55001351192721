import styled, { StyledComponentBase } from 'styled-components'
import { colors } from '../../../../../../../assets/colors'
import { Link } from 'react-router-dom'

interface IStyledPaymentOptions {
  Wrapper: StyledComponentBase<'div', any>
  ExistingCardRow: any
  PreferredLabel: StyledComponentBase<'div', any>
  StyledTextButton: StyledComponentBase<'button', any>
  StyledLinkWithIcon: any
  EmptyCardWrapper: StyledComponentBase<'div', any>
}

const Wrapper = styled.div`
  width: 400px;
  max-width: 90%;
  margin: 50px auto;
`

const ExistingCardRow = styled.div<{ isDefaultPayment: boolean }>`
  display: flex;
  border: ${(props: { isDefaultPayment?: boolean }) =>
    props.isDefaultPayment ? `1px solid ${colors.darkBlue}` : `1px solid ${colors.lightGrey}`}
  padding: 14px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
  height: 55px;
`

const PreferredLabel = styled.div`
  color: #707070;
  padding: 6px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 70px;
`

const StyledTextButton = styled.button<{ small?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: ${colors.red};
  text-transform: capitalize;
  border: 0;
  font-size: ${(props) => (props.small ? '14px' : '0.875rem')};
  border-radius: 30px;
  padding: ${(props) => (props.small ? '9px 15px' : '15px 30px')};
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  &:hover {
    background: rgba(244,244,244,0.5);
  }
  &:active {
    background: rgba(244,244,244,0.5);
`

const StyledLinkWithIcon = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${colors.darkBlue};
  margin: 30px 0;
`

const EmptyCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledPaymentOptions: IStyledPaymentOptions = {
  Wrapper,
  ExistingCardRow,
  PreferredLabel,
  StyledTextButton,
  StyledLinkWithIcon,
  EmptyCardWrapper
}
