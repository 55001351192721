import React from 'react'
import { RouteComponentProps, useParams } from 'react-router-dom'
import MessageListView from '../../../../../containers/MessageList'
import { MessagesType, useGetMessagesByType } from '../../../../../hooks/queries/useGetMessagesByType'

const Folder: React.FC<RouteComponentProps> = () => {
  const { type } = useParams<{ type?: string }>()
  const { data: messages, isLoading } = useGetMessagesByType(type, {
    refetchInterval: 5000,
    params: { confidential: true }
  })

  return <MessageListView messages={messages} isLoading={isLoading} />
}

export default Folder
