import React from 'react'
import { AddressElement, Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { BillingContentTitle } from '../Shared/UI/Components/BillingContentTitle'

// const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_STRIPE_KEY)

const BillingAddress = () => {
  return (
    <>
      <BillingContentTitle>Billing address</BillingContentTitle>
      {/*<Elements stripe={stripePromise}>*/}
      {/*  <AddressElement options={{ mode: 'billing' }} />*/}
      {/*</Elements>*/}
    </>
  )
}

export default BillingAddress
