import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../../../assets/colors'

const Container = styled.div`
  width: 60px;
  height: 60px;
  @media (max-width: 360px) {
    width: 40px;
    height: 40px;
  }
  border-radius: 50%;
  background: #e9f1f8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px;
`

const StyledText = styled.p`
  color: #1d70b6;
  font-size: 20px;
`

const FullContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 95px;
  max-width: 95px;
  @media (max-width: 360px) {
    width: 64px;
    max-width: 64px;
  }
  justify-content: center;
  align-items: center;
`

const NameText = styled.a`
  margin-top: 5px;
  font-size: 14px;
  @media (max-width: 360px) {
    font-size: 11px;
  }
  text-align: center;
  height: 20px;
  white-space: nowrap;
`

interface IProps {
  text?: string | number
  name: string
}

const TextAvatar = (props: IProps) => {
  return (
    <FullContainer>
      <Container>
        <StyledText>{props.text}</StyledText>
      </Container>
      <NameText>{props.name?.length > 12 ? props.name.substring(0, 10) + '...' : props.name}</NameText>
    </FullContainer>
  )
}

export default TextAvatar
