import styled from 'styled-components'
import React from 'react'

const Avatar = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: 0 5px;
`

export default Avatar
