import styled, { css } from 'styled-components'
import React from 'react'
import { colors } from '../../../assets/colors'

const newMessage = css`
  background-color: ${colors.darkBlue};
`

const urgentMessage = css`
  background-color: ${colors.red};
`

const privateMessage = css`
  background-color: ${colors.mediumGrey2};
`

type Props = {
  new?: boolean
  urgent?: boolean
  private?: boolean
}

const Status = styled.div`
  height: 40px;
  width: 4px;
  background-color: transparent;
  border-radius: 3px;
  color: ${colors.mediumGrey};
  display: flex;
  align-items: center;
  ${(props: Props) => props.new && newMessage};
  ${(props: Props) => props.private && privateMessage};
  ${(props: Props) => props.urgent && urgentMessage};
`

export default Status
