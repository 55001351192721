import React from 'react'
import { StyledAccordionElement } from '../Styled'
import OptionAccordion from '../Components/OptionAccordion'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import useOptionsNavigation from '../../Common/Hooks/useOptionsNavigation'
import Modal from '../../../../../../components/Modal'
import { colors } from '../../../../../../assets/colors'
import { CentralPanel } from '../../../../../../layout/Layout'
import { NavigationLink } from '../../../../../shared/Options/Billing/Shared/UI/Components/NavigationLink'
import { useUserStore } from '../../../../../../store/user'
import { useModalActions, useModalsStore } from '../../../../../../store/modals'
import CloseAccountModal from '../Components/CloseAccountModal'
import { useShallow } from 'zustand/react/shallow'
import { useClosureStatusQuery } from '../Components/CloseAccountModal/hooks'

const OptionsNavigation = () => {
  const history = useHistory()
  const location = useLocation()
  const accountNeedAttention = useUserStore((state) => state.accountNeedAttention)
  const {
    confirmLogoutModal,
    onConfirmLogout,
    showConfirmLogoutModal,
    hideConfirmLogoutModal,
    billingProvider
  } = useOptionsNavigation()
  const { data: closureStatus } = useClosureStatusQuery()
  const { setCloseAccountModal } = useModalActions()
  const closeAccountVisible = useModalsStore(useShallow((state) => state.closeAccount.visible))

  return (
    <CentralPanel style={{ paddingTop: '30px' }}>
      {billingProvider && (
        <OptionAccordion title="Billing" active={location.pathname.includes('/options/billing')}>
          <StyledAccordionElement
            onClick={() => history.push('/options/billing?tab=payment-options')}
            active={location.pathname === '/options/billing' && location.search === '?tab=payment-options'}
          >
            Payment options
          </StyledAccordionElement>
          <StyledAccordionElement
            onClick={() => history.push('/options/billing?tab=billing-portal')}
            active={location.pathname === '/options/billing' && location.search === '?tab=billing-portal'}
          >
            Billing portal
          </StyledAccordionElement>
          {accountNeedAttention && (
            <StyledAccordionElement
              onClick={() => history.push('/options/billing?tab=resolve-now')}
              active={location.pathname === '/options/billing' && location.search === '?tab=resolve-now'}
              style={{ color: colors.red2 }}
            >
              Billing Issues
            </StyledAccordionElement>
          )}
        </OptionAccordion>
      )}
      <OptionAccordion
        title="Account information"
        onClick={() => history.push('/options/account-information')}
        active={location.pathname === '/options/account-information'}
      ></OptionAccordion>
      <OptionAccordion
        title="Auto play new"
        onClick={() => history.push('/options/autoplay')}
        active={location.pathname === '/options/autoplay'}
      ></OptionAccordion>
      <OptionAccordion
        title="Voice settings"
        active={location.pathname === '/options/voice-mail-greeting' || location.pathname === '/options/name-recording'}
      >
        <StyledAccordionElement
          onClick={() => history.push('/options/call-forwarding')}
          active={location.pathname === '/options/call-forwarding'}
        >
          Call forwarding
        </StyledAccordionElement>
        <StyledAccordionElement
          onClick={() => history.push('/options/voice-mail-greeting')}
          active={location.pathname === '/options/voice-mail-greeting'}
        >
          Voice mail greeting
        </StyledAccordionElement>
        <StyledAccordionElement
          onClick={() => history.push('/options/name-recording')}
          active={location.pathname === '/options/name-recording'}
        >
          Name recording
        </StyledAccordionElement>
      </OptionAccordion>
      <OptionAccordion
        title="Send feedback"
        onClick={() => history.push('/options/send-feedback')}
        active={location.pathname === '/options/send-feedback'}
      ></OptionAccordion>
      <OptionAccordion
        title="Send debug info"
        onClick={() => history.push('/options/debug-info')}
        active={location.pathname === '/options/debug-info'}
      ></OptionAccordion>
      <OptionAccordion
        title="Contact support"
        onClick={() => {
          //@ts-ignore
          SnapEngage.openProactiveChat(true, true)
        }}
      ></OptionAccordion>
      <OptionAccordion
        style={closureStatus?.closurePending ? { color: 'red', fontWeight: 'bold' } : {}}
        title={closureStatus?.closurePending ? 'Account Closure Pending' : 'Request Account Cancellation'}
        onClick={() => setCloseAccountModal(true)}
      ></OptionAccordion>
      <OptionAccordion title="Logout" onClick={showConfirmLogoutModal}></OptionAccordion>
      {closeAccountVisible && <CloseAccountModal />}
      {confirmLogoutModal && (
        <Modal show={confirmLogoutModal}>
          <Modal.TextContainer>
            <Modal.HeroText>CommuniKate</Modal.HeroText>
            <Modal.DescriptionText>Do you want to logout?</Modal.DescriptionText>
          </Modal.TextContainer>
          <Modal.ButtonsContainer>
            <Modal.Button onClick={hideConfirmLogoutModal} bold color={colors.contrastBlue}>
              No
            </Modal.Button>
            <Modal.Button onClick={onConfirmLogout} color={colors.contrastBlue}>
              Yes
            </Modal.Button>
          </Modal.ButtonsContainer>
        </Modal>
      )}
    </CentralPanel>
  )
}

export default OptionsNavigation
