import React from 'react'
import styled, { css } from 'styled-components'
import { colors } from '../../../../assets/colors'
import { Stream } from '@opentok/client'

export const Background = styled.div<{ height?: number; width: number; isSharingScreen?: boolean }>`
  position: absolute;
  top: ${({ isSharingScreen }) => (isSharingScreen ? '77%' : '66.7%')};
  left: ${({ isSharingScreen }) => (isSharingScreen ? '0' : '66.7%')};
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  clip-path: content-box;
  overflow: hidden;
  transition: outline 0.25s ease-in-out;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: ${({ width }) => `calc(${width}% - 20px)`};
  height: ${({ height, isSharingScreen }) => (isSharingScreen ? `calc(${height}%)` : `calc(${height}% - 1.5%)`)};
  z-index: 10000;

  margin: 10px 5px 5px 10px;
  background: ${colors.grey};
  ${({ isSharingScreen }) => isSharingScreen && isSharingScreenStyles}
`
const isSharingScreenStyles = css`
  bottom: 0 !important;
  width: 98%;
  height: 25%;
  z-index: 10;

  @media (min-width: 1279px) {
    margin-bottom: 0;
    height: 25%;
    top: 75%;
  }

  @media (min-width: 1365px) {
    margin-bottom: 0;
    height: 25%;
    top: 75%;
  }

  @media (min-width: 1599px) {
    top: 76%;
    height: 25%;
  }

  @media (min-width: 1921px) {
    height: 25%;
    top: 75%;
  }
`

const PersonsText = styled.p`
  font-family: 'Open Sans, sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: ${colors.white};
  text-shadow: 0px 1px 12px ${colors.veryDark};
`

export const ConferenceAtendee = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px !important;
  height: 30px !important;
  background: #fff;
  border: 1px solid ${colors.mediumGrey};
  border-radius: 30px;
  overflow: hidden;
`

export const ConferenceAtendees = styled.div`
  display: flex;
  margin-left: 5px;
  width: 200px !important;
  height: 40px !important;
  justify-content: center;
`

interface IProps {
  visibleStreams: Stream[]
  remainingCount?: number
  height?: number
  width: number
  isSharingScreen?: boolean
}

const OtherParticipantsContainer: React.FC<IProps> = (props: IProps) => {
  return (
    <Background height={props.height} width={props.width} isSharingScreen={props.isSharingScreen}>
      <ConferenceAtendees className="avatars">
        {props?.visibleStreams?.map((stream: Stream, index) => (
          <ConferenceAtendee
            key={stream.streamId}
            style={{
              marginLeft: index === 0 ? 0 : index === 1 ? -12 : -(index + 8)
            }}
          >
            {stream?.name?.[0]?.toUpperCase()}
          </ConferenceAtendee>
        ))}
      </ConferenceAtendees>
      <PersonsText>+ {props.remainingCount} persons</PersonsText>
    </Background>
  )
}

export default OtherParticipantsContainer
