import React from 'react'
import NewMessageView from './NewMessageView'
import { AudioPlayingProvider } from './provider/AudioPlayingProvider'

const NewMessage = () => {
  return (
    <>
      <NewMessageView />
    </>
  )
}

export default NewMessage
