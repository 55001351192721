import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface IProps {
  children: ReactNode
  onClick: () => void
}

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #f43737;
  opacity: 0.92;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

const RoundedIcon = ({ children, onClick }: IProps) => <IconContainer onClick={onClick}>{children}</IconContainer>

export default RoundedIcon
