import styled from 'styled-components'

const HelpSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 0;
`

export default HelpSection
