import React from 'react'
import { useHistory, useLocation } from 'react-router'
import APIClient from '../../../../apiClient'
import { MessageType, TagType } from '../../../../types'
import TagMessagesView from './TagMessagesView'
import { useQuery } from '@tanstack/react-query'

interface ILocation {
  state: {
    name: string
  }
}

export const getMessagesWithTags = (tag?: string) => {
  const location: { state: { name: string } } = useLocation<any>()
  const tagName = tag || (location.state && location.state.name)
  const sent = tagName === '#Sent Items'

  return useQuery<unknown, unknown, MessageType[]>({
    queryKey: ['messagesWithTags', tagName],
    queryFn: async () => {
      const { data } = await APIClient.messagesWithTagsList()
      const myTag = data?.tags.find((tag: TagType) => tag.name === tagName)
      const viewTag = data?.views.find((tag: TagType) => tag.name === tagName)
      if (myTag) {
        const tagMessages = data?.messages.filter((message) => new Set(myTag.messages).has(message.uniqueId))
        return tagMessages.map((message) => ({ ...message, isSentMessage: sent }))
      }

      if (viewTag) {
        const viewTagMessages = data?.messages.filter((message) => new Set(viewTag.messages).has(message.uniqueId))
        return viewTagMessages.map((message) => ({ ...message, isSentMessage: sent }))
      }
      return []
    },
    meta: {
      errorMessage: 'Problem with fetching messages'
    },
    cacheTime: 1000 * 60 // 1 minute
  })
}

const TagMessagesPage: React.FC = () => {
  const location: ILocation = useLocation<any>()
  const name = location.state && location.state.name
  const history = useHistory()
  const { data: messages, isLoading } = getMessagesWithTags()

  return (
    <TagMessagesView
      name={name}
      onBackClick={() => history.push('/tags', {})}
      messages={messages}
      isLoading={isLoading}
    />
  )
}

export default TagMessagesPage
