import React from 'react'
import Header from '../../../../../components/Header'
import { InlineButton } from '../../../../../components/Button/InlineButton'
import { BillingWrapper } from '../../../../shared/Options/Billing/Shared/UI/Components/BillingWrapper'
import BillingNavigation from '../../../../shared/Options/Billing/Shared/UI/Blocks/BillingNavigation'
import { useHistory } from 'react-router'
import Content from '../../../../shared/Options/Billing/Shared/UI/Blocks/Content'
import useIsMobile from '../../../../shared/Options/Billing/Shared/Common/Hooks/useIsMobile'
import useMobileNavigation from '../../../../shared/Options/Billing/Shared/Common/Hooks/useMobileNavigation'
import { colors } from '../../../../../assets/colors'
import Icon from '../../../../../components/Icon'

interface IProps {
  children: React.ReactNode
}

const MobileBilling = ({ children }: IProps) => {
  const history = useHistory()
  const isMobile = useIsMobile()
  const { expanded, toggleNavigationExpanded } = useMobileNavigation()

  return (
    <>
      <Header>
        <Header.SideButtons>
          {isMobile ? (
            <InlineButton onClick={toggleNavigationExpanded}>
              <Icon width="32px" fill={colors.white} name={'menu'} style={{ marginBottom: '-10px' }} />
            </InlineButton>
          ) : (
            <InlineButton onClick={() => history.push('/options')}>Options</InlineButton>
          )}
        </Header.SideButtons>
        <Header.Title>
          <span>Billing</span>
        </Header.Title>
        <div style={{ width: '80px' }} />
      </Header>
      <BillingWrapper>
        <BillingNavigation expanded={expanded} toggleNavigationExpanded={toggleNavigationExpanded} />
        <Content />
      </BillingWrapper>
    </>
  )
}

export default MobileBilling
