import React, { useRef } from 'react'
import styled from 'styled-components'
import { colors } from '../../../assets/colors'
import { useNewMessageStore } from '../../../navigation/mobile/pages/NewMessage/store/NewMessageStore'

const PlaybackBarContainer = styled.div`
  user-select: none;
  width: 100%;
  padding: 0 5px;
`

const PlaybackBarTime = styled.div`
  color: ${colors.mediumGrey};
  font-size: 14px;
  text-align: center;
`

const PlaybackBarProgress = styled.div`
  border-radius: 5px;
  width: 100%;
  margin: 5px 0 13px 0;
  height: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${colors.darkBlue};
`

const PlaybackBarProgressKnob = styled.div<{ color: string }>`
  position: relative;
  height: 15px;
  width: 15px;
  border: 1.5px solid ${({ color }) => color};
  border-radius: 50%;
  background-color: ${({ color }) => color};
`

interface INewMessagePlaybackBar {
  duration: number
  curTime: number
  playProgress: number
  onTimeUpdate: (time: number | undefined) => void
}

function formatDuration(duration: number) {
  const minutes = Math.floor(duration / 60)
  const seconds = Math.floor(duration - minutes * 60)
  const minutesStr = minutes ? (minutes < 10 ? `0${minutes}` : minutes) : '00'
  const secondsStr = seconds ? (seconds < 10 ? `0${seconds}` : seconds) : '00'
  return `${minutesStr}:${secondsStr}`
}

export const NewMessagePlaybackBar: React.FC<INewMessagePlaybackBar> = (props: INewMessagePlaybackBar) => {
  const progressBar = useRef<HTMLDivElement>(null)
  const { duration, curTime, onTimeUpdate, playProgress } = props
  const isConfidential = useNewMessageStore((state) => state.isConfidential)

  const calcClickedTime = (e: MouseEvent | TouchEvent) => {
    let clickPositionInPage
    if ('pageX' in e) {
      clickPositionInPage = e.pageX
    } else {
      clickPositionInPage = e.changedTouches[e.changedTouches.length - 1].pageX
    }

    const bar: HTMLDivElement | null = progressBar.current
    if (bar) {
      const barStart = bar.getBoundingClientRect().left + window.scrollX
      const barWidth = bar.offsetWidth
      const clickPositionInBar = clickPositionInPage - barStart >= 0 ? clickPositionInPage - barStart : 0
      const timePerPixel = duration / barWidth
      return timePerPixel * clickPositionInBar
    }
  }

  function handleTimeDrag(e: MouseEvent | TouchEvent | any) {
    onTimeUpdate(calcClickedTime(e))

    const updateTimeOnMove = (eMove: MouseEvent | TouchEvent) => {
      onTimeUpdate(calcClickedTime(eMove))
    }
    if (e.type === 'mousedown') {
      document.addEventListener('mousemove', updateTimeOnMove)

      document.addEventListener('mouseup', () => {
        document.removeEventListener('mousemove', updateTimeOnMove)
      })
    } else {
      document.addEventListener('touchmove', updateTimeOnMove)

      document.addEventListener('touchend', () => {
        document.removeEventListener('touchmove', updateTimeOnMove)
      })
    }
  }

  return (
    <PlaybackBarContainer className="bar">
      <PlaybackBarProgress
        ref={progressBar}
        className="bar__progress"
        onMouseDown={(e) => handleTimeDrag(e)}
        onTouchStart={(e) => handleTimeDrag(e)}
      >
        <PlaybackBarProgressKnob
          color={isConfidential ? colors.mediumGrey2 : colors.darkBlue}
          className="bar__progress__knob"
          style={{ left: `${playProgress - 0.5}%` }}
        />
      </PlaybackBarProgress>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <PlaybackBarTime className="bar__time">{formatDuration(curTime)}</PlaybackBarTime>
        <PlaybackBarTime className="bar__time">{formatDuration(duration)}</PlaybackBarTime>
      </div>
    </PlaybackBarContainer>
  )
}
