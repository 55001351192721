import styled from 'styled-components'
import { colors } from '../../../../assets/colors'

const Container = styled.div`
  flex: 1;
  background: #fafbfc;
`

const Content = styled.div`
  height: calc(100% - 100px);
  width: 100%;
  max-width: 1140px;
  padding: 0 15px;
  display: flex;
  flex: 1;
  align-items: center;
  margin: 0 auto;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
  @media (min-width: 1024px) {
    justify-content: space-between;
  }
`

const Title = styled.h2`
  color: ${colors.darkBlue};
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  @media (max-width: 460px) {
    font-size: 24px;
    line-height: 30px;
  }
`

const Description = styled.p`
  color: ${colors.mediumGrey};
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 460px) {
    font-size: 14px;
    line-height: 20px;
  }
`

const LeftContainer = styled.div`
  @media (min-width: 1024px) {
    flex: 1;
    max-width: 405px;
  }
  @media (max-width: 1023px) {
    max-width: 600px;
    width: 100%;
    margin-bottom: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 599px) {
    width: 100%;
  }
`

export { Content, Container, LeftContainer, RightContainer, Title, Description }
