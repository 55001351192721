import React from 'react'
import styled from 'styled-components'

interface IProps {
  selected: boolean
  selectedSrc: string | undefined
  notSelectedSrc: string | undefined
  onClick: () => void
}

interface RoundedContainerProps {
  selected: boolean
}

const RoundedContainer = styled.div<RoundedContainerProps>`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${(props) => (props.selected ? '#4b4c51' : '#DB2A2A')};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.95;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

const RoundedIcon = ({ selected, selectedSrc, notSelectedSrc, onClick }: IProps) => (
  <RoundedContainer selected={selected} onClick={onClick}>
    <img src={selected ? selectedSrc : notSelectedSrc} />
  </RoundedContainer>
)

export default RoundedIcon
