import styled from 'styled-components'
import React, { ReactNode } from 'react'
import { colors } from '../../../../assets/colors'

const ItemsListWrapper = styled.div`
  flex: 1;
  min-height: 300px;
  width: 100%;
  height: 100%;
  background-color: ${colors.silver};
  flex-direction: column;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.875rem;
  overflow-x: auto;
`

interface ItemsListPageLayoutProps {
  children?: ReactNode
}

const ItemsList = ({ children, ...rest }: ItemsListPageLayoutProps) => (
  <ItemsListWrapper {...rest}>{children}</ItemsListWrapper>
)

export default ItemsList
