import { useUser } from '../../store/user'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import APIClient from '../../apiClient'

export const useGetMonthReport = () => {
  const user = useUser()
  const { from, to } = useParams<{ from: string; to: string }>()

  return useQuery<unknown, unknown, any>({
    queryKey: ['report', `${from}-${to}`],
    queryFn: async () => {
      const res = await APIClient.getMonthReport({ from, to })
      return res.data
    },
    cacheTime: 1000 * 60 * 30, // 30 minutes
    enabled: !!user?.account_number
  })
}
