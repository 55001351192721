import React, { ReactNode } from 'react'
import styled, { CSSObject } from 'styled-components'
import { Loader } from '../Loader/Loader'
import { colors } from '../../assets/colors'

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  text-transform: capitalize;
  border: 0;
  font-size: 0.875rem;
  border-radius: 30px;
  padding: 15px 30px;
  width: 100%;
  cursor: pointer;

  &:active {
    background-color: ${colors.darkBlue};
    opacity: 0.7;
  }

  &:hover {
    background-color: #1e6ca8;
  }
`

type ButtonProps = {
  children: ReactNode
  onClick?: (e: any) => void
  isLoading?: boolean
  type?: 'submit' | 'reset' | 'button'
  style?: CSSObject
}

export const Button: React.FC<ButtonProps> = ({ isLoading = false, children, ...props }) => {
  return (
    <StyledButton onClick={props.onClick} disabled={isLoading} {...props}>
      {isLoading ? <Loader height={'20px'} width={'20px'} /> : children}
    </StyledButton>
  )
}
