import React, { useEffect, useState } from 'react'
import Spinner from '../../../../../components/Icon/Spinner'
import { colors } from '../../../../../assets/colors'
import styled from 'styled-components'
import appSyncClient from '../../../../../gql/client'
import {
  stripeCreateCustomerPortalSession,
  StripeCreateCustomerPortalSessionResponse
} from '../../../../../gql/queries/stripeCreateCustomerPortalSession'
import { toast } from 'react-toastify'

const StyledHref = styled.a`
  color: ${colors.darkBlue};
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin: 5px;
`

const InfoContainer = styled.div`
  font-family: Open Sans, sans-serif;
  font-size: 16px;
`
const BillingPortal = () => {
  const [stripeUrl, setStripeUrl] = useState<string | null>(null)

  useEffect(() => {
    ;(async () => {
      try {
        const res: StripeCreateCustomerPortalSessionResponse = await appSyncClient.graphql({
          query: stripeCreateCustomerPortalSession,
          variables: { returnUrl: `${window.location.origin}/` }
        })
        setStripeUrl(res?.data?.stripeCreateCustomerPortalSession?.url)
      } catch (err) {
        toast.error('Something went wrong - please try again')
        console.log(err)
      }
    })()
  }, [])

  useEffect(() => {
    if (!stripeUrl) return
    window.location.href = stripeUrl
  }, [stripeUrl])

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      {!stripeUrl ? (
        <Spinner width={'20px'} fill={colors.darkBlue} />
      ) : (
        <InfoContainer>Redirecting to Billing Page...</InfoContainer>
      )}
    </div>
  )
}

export default BillingPortal
