import React, { ReactNode } from 'react'
import styled, { keyframes } from 'styled-components'
import { colors } from '../../../assets/colors'
import Icon from '../../Icon'

interface IRecordingVisualizerProps {
  children?: ReactNode
}

const pulseAnimation = keyframes`
  0% {
    transform: scale(.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(0,0,0,0);
  }
  100% {
    transform: scale(.9);
    box-shadow: 0 0 0 0 rgba(0,0,0,0);
  }
`

const RecordingVisualizerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 200px;
  width: 100%;
`

const RecordingVisualizer = styled.div`
  width: 110px;
  height: 110px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  animation: ${pulseAnimation} 1.5s infinite;
`

const RecordingVisualizerBox = ({ children, ...rest }: IRecordingVisualizerProps) => (
  <RecordingVisualizerWrapper {...rest}>
    <RecordingVisualizer>
      <Icon name="microphone" fill={colors.lightGrey} style={{ width: '100px', height: '100px' }} />
    </RecordingVisualizer>
  </RecordingVisualizerWrapper>
)

export default RecordingVisualizerBox
