import React, { SVGProps } from 'react'

const Email = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="
      M3.6 1C2.17122 1 1 2.28103 1 3.84375V12.1562C1 13.719 2.17122 15 3.6 15H14.4C15.8288 15 17 13.719 17 12.1562V3.84375C17 2.28103 15.8288 1 14.4 1H3.6ZM3.6 2.3125H14.4C15.18 2.3125 15.8 2.9906 15.8 3.84375V4.32739L9 8.34778L2.2 4.32739V3.84375C2.2 2.9906 2.81998 2.3125 3.6 2.3125ZM2.2 5.81934L8.71484 9.67139C8.80244 9.72313 8.90044 9.75023 9 9.75023C9.09956 9.75023 9.19756 9.72313 9.28516 9.67139L15.8 5.81934V12.1562C15.8 13.0094 15.18 13.6875 14.4 13.6875H3.6C2.81998 13.6875 2.2 13.0094 2.2 12.1562V5.81934Z"
      fill="#1D70B6"
    />
  </svg>
)

export default Email
