import styled from 'styled-components'
import { colors } from '../../../../../assets/colors'

export const MinutesSubtitle = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.mediumGrey};
`
