import useIsMobile from '../../../../../../hooks/useIsMobile'
import { useLocation } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { ContactItemType } from '../../../../../../types'
import { useHistory } from 'react-router'
import { useContactsActions } from '../../../../../../store/contacts'
import { useAutoPlayStore } from '../../../../../../store/autoPlay'
import { shallow } from 'zustand/shallow'
import { useContactGroupListQuery } from '../../../../../../hooks/queries/useContactGroupListQuery'
import APIClient from '../../../../../../apiClient'
import { queryClient } from '../../../../../../index'
import { showAlertModal } from '../../../../../../store/modals'
import { useGroupsQuery } from '../../../../../../hooks/queries/useGroupsQuery'
import { useNewMessageActions } from '../../../NewMessage/store/NewMessageStore'

const useContacts = () => {
  const isMobile = useIsMobile()
  const searchParams = new URLSearchParams(useLocation().search)
  const currentContactId = searchParams.get('id')

  const [filteredContacts, setFilteredContacts] = useState<ContactItemType[]>([])
  const { setContact } = useContactsActions()
  const { setRecipients } = useNewMessageActions()
  const nextUnreadMessageId = useAutoPlayStore((state) => state.nextUnreadMessageId, shallow)

  const history = useHistory()

  const urlParams = new URLSearchParams(history.location.search)
  const search = urlParams.get('filter') || ''

  const { data, isLoading } = useContactGroupListQuery()

  /*
   Redirect to first found contact
   */
  useEffect(() => {
    if (!data?.length || history.location.search) return
    history.replace(`/contacts?id=${data?.[0]?.id}`)
  }, [data, history.location.pathname])

  useEffect(() => {
    if (search.length >= 2) {
      const foundContacts = data?.filter((contact) => {
        return (
          contact?.first_name?.toLowerCase()?.startsWith(search?.toLowerCase()) ||
          contact?.last_name?.toLowerCase()?.startsWith(search?.toLowerCase())
        )
      })

      setFilteredContacts(foundContacts)
    } else {
      setFilteredContacts(data)
    }
  }, [data, search])

  const deleteContactFunction = useCallback(async (id: string) => {
    try {
      const deleteResponse = await APIClient.contactDelete(id)
      await queryClient.invalidateQueries({ queryKey: ['contacts'] })
      if (deleteResponse.status >= 300)
        throw new Error(`status = ${deleteResponse.status}, ${deleteResponse.statusText}`)
    } catch (e) {
      console.log('error on contact delete ===', e)
      showAlertModal('Ups, contact cannot be deleted. Please reload the page and try again.')
    }
  }, [])

  const onContactsGroupClick = useCallback(() => {
    history.push('/groups')
  }, [nextUnreadMessageId])

  const currentContact = data?.find((contact) => contact.id === currentContactId)

  const sendMessage = useCallback(() => {
    if (!currentContact) return
    const recipients = [
      {
        id: currentContact.id,
        label: `${currentContact?.first_name} ${currentContact?.last_name}`
      }
    ]
    // history.push(`${history.location.pathname}?id=${currentContact.id}`, { newMessage: true, recipients })
    setRecipients(recipients)
    history.push(`${history.location.pathname}?action=new-message`, { recipients })
  }, [currentContact])

  return {
    isLoading,
    isMobile,
    currentContact,
    currentContactId,
    search,
    filteredContacts,
    sendMessage
  }
}

export default useContacts
