import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../assets/colors'

const Container = styled.div`
  height: 35px;
  width: 100%;
  padding: 5px 10px;
  background: ${colors.silver};
`

const TabWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: stretch;
  background: ${colors.lightSliver};
  border-radius: 4px;
  position: relative;
`

const Tab = styled.div<TabType>`
  flex: 1;
  align-items: center;
  text-align: center;
  padding: 6px 5px;
  font-size: 12px;
  color: ${(props) => (props.isActive ? colors.white : colors.darkBlue)};
  position: relative;
  z-index: 2;
  transition: color 250ms;
`

const TabActiveSlider = styled.div<TabActiveSliderType>`
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: ${(props) => (props.activeElementCount ? `calc(33.333% * ${props.activeElementCount})` : 0)};
  width: 33%;
  margin: 0 2px;
  border-radius: 4px;
  background: ${colors.darkBlue};
  transition: left 250ms;
`

type TabActiveSliderType = {
  activeElementCount: number
}

type TabType = {
  key: string
  isActive: boolean
  onClick: () => void
}

type TabsType = {
  tabs: string[]
  defaultTab: string
  onChange: (tab: string) => void
}

export const Tabs: React.FC<TabsType> = ({ tabs, defaultTab, onChange }) => {
  const [activeTab, setActiveTab] = useState(defaultTab)

  useEffect(() => {
    onChange(activeTab)
  }, [activeTab])

  return (
    <Container>
      <TabWrapper>
        {tabs.map((tab: string) => (
          <Tab key={tab} onClick={() => setActiveTab(tab)} isActive={tab === activeTab}>
            {tab}
          </Tab>
        ))}
        <TabActiveSlider activeElementCount={tabs.indexOf(activeTab)} />
      </TabWrapper>
    </Container>
  )
}
