import React from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import OptionsLayout from './Ui/Layout/OptionsLayout'
import Billing from './Billing'
import './Common/Syles/index.css'
import SendDebugInfo from './SendDebugInfo'
import SendFeedback from './SendFeedback'
import AutoplayNew from './AutoplayNew'
import { Layout } from '../../../../layout/Layout'
import VoiceMailGreeting from './VoiceMailGreeting'
import NameRecording from './NameRecording'
import CallForwarding from './CallForwarding'
import AccountInformation from './AccountInformation'

const OptionsPage: React.FC<RouteProps & { match: any }> = (props) => {
  return (
    <Layout>
      <OptionsLayout>
        <Switch>
          <Route path="/options/billing" component={Billing} />
          <Route path="/options/account-information" component={AccountInformation} />
          <Route path="/options/send-feedback" component={SendFeedback} />
          <Route path="/options/debug-info" component={SendDebugInfo} />
          <Route path="/options/autoplay" component={AutoplayNew} />
          <Route path="/options/voice-mail-greeting" component={VoiceMailGreeting} />
          <Route path="/options/name-recording" component={NameRecording} />
          <Route path="/options/call-forwarding" component={CallForwarding} />
          <Route exact path="/options">
            <Redirect to={'/options/account-information'} />
          </Route>
        </Switch>
      </OptionsLayout>
    </Layout>
  )
}

export default OptionsPage
