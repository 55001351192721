import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { colors } from '../../../assets/colors'

const ConferencesLabel = styled.div`
  color: ${colors.mediumGrey};
`

const Container = styled.div`
  max-height: 95vh;
  overflow: auto;
  @media (min-width: 1024px) {
    min-width: 500px;
  }
  @media (max-width: 1023px) and (min-width: 600px) {
    min-width: 600px;
    max-width: 600px;
  }
  @media (max-width: 599px) {
    width: 100%;
  }
`

const TitleContainer = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

type ScheduledConferencesProps = {
  children: ReactNode
  showTitle: boolean
  icons?: any
}

const ScheduledConferences: FC<ScheduledConferencesProps> = ({ children, showTitle, icons }) => {
  return (
    <Container>
      <TitleContainer>
        {showTitle ? <ConferencesLabel>Upcoming meetings</ConferencesLabel> : null}
        {icons}
      </TitleContainer>
      {children}
    </Container>
  )
}

export default ScheduledConferences
