import styled from 'styled-components'

const Container = styled.div`
  width: 340px;
  height: auto;
  min-height: 340px;
  display: block;
`

export default Container
