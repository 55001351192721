import React from 'react'

interface SkipToNextProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const SkipToNext = ({
  style = {},
  fill = '#000',
  width = '30px',
  className = '',
  viewBox = '0 0 30 30'
}: SkipToNextProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M 7 6 A 1 1 0 0 0 6 7 A 1 1 0 0 0 6 7.0039062 L 6 15 L 6 22.996094 A 1 1 0 0 0 6 23 A 1 1 0 0 0 7 24 A 1 1 0 0 0 7.5917969 23.804688 L 7.59375 23.804688 A 1 1 0 0 0 7.6210938 23.783203 L 18.541016 15.841797 A 1 1 0 0 0 19 15 A 1 1 0 0 0 18.556641 14.169922 L 7.59375 6.1953125 A 1 1 0 0 0 7 6 z M 22.5 6 C 21.672 6 21 6.672 21 7.5 L 21 22.5 C 21 23.328 21.672 24 22.5 24 C 23.328 24 24 23.328 24 22.5 L 24 7.5 C 24 6.672 23.328 6 22.5 6 z"
    />
  </svg>
)

export default SkipToNext
