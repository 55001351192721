import styled from 'styled-components'

export const BillingWrapper = styled.div`
  width: 100vw;
  overflow-y: auto;
  min-height: calc(100vh - 50px);
  max-width: 100%;
  background: rgb(239, 239, 239);
  display: flex;

  & *::placeholder {
    color: #808080;
  }
`
