import styled from 'styled-components'
import React from 'react'

const Main = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  margin: 0 5px;
`

export default Main
