import styled from 'styled-components'
import { colors } from '../../../assets/colors'

const Tag = styled.input`
  padding: 3px;
  background-color: ${colors.mediumGrey};
  color: ${colors.white};
  border: 0;
  outline: 0;
  margin: 3px;
  border-radius: 3px;
  font-size: 14px;
`

export default Tag
