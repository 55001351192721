import React, { memo, useMemo } from 'react'
import format from 'date-fns/format'
import { shallow } from 'zustand/shallow'
import { useAudioMessageStore } from '../../store/audioMessage'
import { MessageType } from '../../types'
import { useMessageList } from '../MessageList/useMessageList'
import styled from 'styled-components'
import SwipeItem from '../../components/blocks/Message/SwipeItem'
import { colors } from '../../assets/colors'
import Message from '../../components/blocks/Message'
import AvatarPlaceholder from '../../assets/images/avatar_placeholder.png'
import { Loader } from '../../components/Loader/Loader'
import Icon from '../../components/Icon'
import ImageField from '../../components/Field/ImageField'
import useIsMobile from '../../hooks/useIsMobile'

interface IMessageListItemView {
  message: MessageType
}

const InfoButton = styled.div`
  cursor: pointer;
  opacity: 0.85;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

export const messageDate = (message) => {
  if (!message || !message?.timestamp) return ''
  return format(new Date(message?.timestamp * 1000), 'd MMM, hh:mm aaa')
}

export const contactName = (message) => {
  if (!message) return ''
  if (!message?.contact && message?.source) return message?.source
  return `${message.contact?.first_name} ${message.contact?.last_name}`
}

export const sentMessageTo = (message) => {
  if (!message?.isSentMessage) return ''
  if (message?.recipients && message?.recipients.length) {
    const messageRecipient = message?.recipients?.[0]
    if (messageRecipient?.first_name && messageRecipient?.last_name) {
      return `${messageRecipient?.first_name} ${messageRecipient?.last_name}`
    }
    return messageRecipient?.kate_number || ''
  }

  return message.source || ''
}

export const Contact = ({ message, addContact }) => {
  const contactHasPhoto = message?.contact?.['has_photo'] && message?.contact?.['photo_url']

  return message?.contact ? (
    contactHasPhoto ? (
      <Message.Avatar src={message?.contact['photo_url']} />
    ) : (
      <Message.Avatar src={AvatarPlaceholder} />
    )
  ) : sentMessageTo(message) ? (
    <Message.Avatar src={AvatarPlaceholder} />
  ) : (
    <Message.AvatarButton onClick={() => addContact(message?.uniqueId)}>Add contact</Message.AvatarButton>
  )
}

const MessageListItem = ({ message }: IMessageListItemView) => {
  const isMobile = useIsMobile()

  if (!message) return null

  const {
    replyMessage,
    forwardMessage,
    deleteMessage,
    addContact,
    messageInfoClick,
    selectMessageAndLoadAudio
  } = useMessageList(message)

  const currentTime = useAudioMessageStore((state) => state.currentTime[message?.uniqueId], shallow)
  const audioDuration = useAudioMessageStore((state) => state.duration, shallow)
  const playing = useAudioMessageStore(
    ({ messageId, playing }) => (messageId === message?.uniqueId ? playing : false),
    shallow
  )
  const isLoading = useAudioMessageStore(
    (state) => (state.messageId === message?.uniqueId ? state.loading : false),
    shallow
  )

  const currentMessageId = useAudioMessageStore((state) => state.currentMessageId, shallow)
  const isCurrentMessage = currentMessageId === message?.uniqueId

  const playProgress = useMemo(() => {
    if (!currentTime) return 0
    return (currentTime / audioDuration) * 100 || 0
  }, [currentTime, audioDuration])

  const isConfidential = message?.confidential

  const messageSwipeActions = [
    {
      text: 'Reply',
      onPress: () => replyMessage(message.uniqueId),
      style: { backgroundColor: colors.lightGrey, color: colors.white, overflow: 'hidden' }
    },
    {
      text: 'Forward',
      onPress: () => forwardMessage(message.uniqueId),
      style: { backgroundColor: colors.orange, color: colors.white, overflow: 'hidden' }
    },
    {
      text: 'Delete',
      onPress: () => deleteMessage(message.uniqueId),
      style: { backgroundColor: colors.red, color: colors.white, overflow: 'hidden' }
    }
  ].filter((option) => (isConfidential ? option.text !== 'Forward' : option))

  return (
    <SwipeItem
      autoClose
      right={messageSwipeActions}
      key={message?.uniqueId}
      onClick={() => selectMessageAndLoadAudio(message?.uniqueId, isConfidential)}
    >
      <Message isCurrentMessage={isCurrentMessage}>
        <Message.Status new={message?.new} urgent={message?.urgent} private={isConfidential} />
        <Contact message={message} addContact={addContact} />
        <Message.Main>
          <Message.ClickHandler />
          <Message.About>
            {sentMessageTo(message) ? (
              <Message.ToContact>To: {sentMessageTo(message)}</Message.ToContact>
            ) : (
              <Message.ContactName>{contactName(message)}</Message.ContactName>
            )}
            <Message.Note new={message?.new} private={isConfidential}>
              {isConfidential && <Icon name={'confidential'} style={{ width: 16, height: 16 }} />}
              <span style={{ marginLeft: isConfidential ? 3 : 0 }}>
                {message?.note ? message?.note : message?.timestamp && messageDate(message)}
              </span>
            </Message.Note>
          </Message.About>
          {isCurrentMessage ? (
            <Message.PlayArea>
              {isLoading && <Loader fill={colors.blue} />}
              {!isLoading && playing ? (
                <Icon name="pause" style={{ height: 25 }} fill={colors.blue} />
              ) : (
                <Icon name="play" style={{ height: 25 }} fill={colors.blue} />
              )}
            </Message.PlayArea>
          ) : (
            <Message.AudioLength>{!!message?.length && message?.length}</Message.AudioLength>
          )}
          {isCurrentMessage && !isLoading && playProgress > 0 && (
            <Message.PlaybackProgress style={{ width: playProgress + '%' }} />
          )}
        </Message.Main>
        <Message.Details onClick={() => messageInfoClick(message?.uniqueId, isConfidential)}>
          <InfoButton>
            <Icon name="info" fill={colors.lightGrey} style={{ width: '30px' }} />
          </InfoButton>
        </Message.Details>
      </Message>
      {message?.photo_url && (
        <Message.Photo isCurrentMessage={isCurrentMessage}>
          <ImageField size="small" src={message.photo_url} alt={'message_photo'} />
        </Message.Photo>
      )}
    </SwipeItem>
  )
}

export default memo(MessageListItem)
