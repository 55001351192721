import styled from 'styled-components'
import React from 'react'
import Copyright from './Copyright'
import Header from './Header'
import HelpSection from './HelpSection'
import Content from './Content'

const LoginWrapper = styled.div`
  max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin: 0 auto;
  height: 100vh;
`

interface LoginPageLayoutProps {
  children: JSX.Element | JSX.Element[]
}

const Login = ({ children, ...rest }: LoginPageLayoutProps) => <LoginWrapper {...rest}>{children}</LoginWrapper>

Login.Content = Content
Login.Header = Header
Login.Copyright = Copyright
Login.HelpSection = HelpSection

export default Login
