import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const InlineLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`

export default InlineLink
