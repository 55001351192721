import styled from 'styled-components'

interface IStatus {
  color?: string
}

const Status = styled.div<IStatus>`
  width: 5px;
  border-radius: 2px;
  height: 100%;
  background-color: ${(props) => props.color || 'transparent'};
`

export default Status
