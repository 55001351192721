import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import Label from './Label'
import Content from './Content'
import Actions from './Actions'
import Action from './Action'
import InfoContainer from './InfoContainer'
import LongTextField from './LongTextField'
import { colors } from '../../assets/colors'
import RowContent from './RowContent'

const border = css`
  border-bottom: 1px solid ${colors.lightGrey};
`

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  ${(props: IField) => props.border && border}
`

interface IField {
  children: ReactNode
  onClick?: () => void
  border?: boolean
}

const Field = ({ children, onClick, border }: IField) => (
  <Wrapper onClick={onClick} border={border}>
    {children}
  </Wrapper>
)

Field.Label = Label
Field.Content = Content
Field.RowContent = RowContent
Field.Actions = Actions
Field.Action = Action
Field.InfoContainer = InfoContainer
Field.LongTextField = LongTextField

export default Field
