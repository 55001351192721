import styled from 'styled-components'
import { colors } from '../../../../assets/colors'

export const PriceText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: ${colors.darkBlue}
}
`
