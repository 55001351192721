import styled from 'styled-components'

const WeekDaysSpan = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #707070;
`

export default WeekDaysSpan
