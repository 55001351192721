import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../assets/colors'

const Container = styled.div`
  font-size: 14px;
  color: ${colors.lightGrey};
  margin-bottom: 15px;
`
interface Props {
  children?: string
}

const Title: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>
}

export default Title
