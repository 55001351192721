import { create } from 'zustand'
import { Recipient } from '../../../../../types'

interface NewMessageStore {
  recipients: Recipient[]
  isUrgent: boolean
  isConfidential: boolean
  isRecording: boolean
  mp3File: File | null
  imageFile?: File | null
  image?: string | null
  afterRecording: boolean
  selectedRecipients: { [key: string]: boolean }
  actions: {
    setMp3File: (file: File) => void
    setImageFile: (file: File) => void
    setImage: (image: string) => void
    setIsUrgent: (isUrgent: boolean) => void
    setIsConfidential: (isConfidential: boolean) => void
    setRecipients: (recipients: Recipient[]) => void
    setDiscardAudioModal: (value: boolean) => void
    setSelectedRecipients: (recipientId: string) => void
    clearSelectedRecipients: () => void
    setCancelMessageModal: (value: boolean) => void
    setAfterRecording: (value: boolean) => void
    setImageModal: (value: boolean) => void
    setIsRecording: (value: boolean) => void
    setSelectRecipientsModal: (value: boolean) => void
    clear: () => void
    onDiscardMessage: () => void
    onDiscardAudio: () => void
  }
  modals: {
    discardAudio: boolean
    cancelMessage: boolean
    selectRecipientsModal: boolean
    imageModal: boolean
  }
}
export const useNewMessageStore = create<NewMessageStore>()((set, getState) => ({
  recipients: [],
  selectedRecipients: {},
  isUrgent: false,
  isConfidential: false,
  mp3File: null,
  imageFile: null,
  image: null,
  isRecording: false,
  afterRecording: false,
  actions: {
    setMp3File: (mp3File: File) => set({ mp3File }),
    setImageFile: (imageFile: File) => set({ imageFile }),
    setImage: (image: string) => set({ image }),
    setIsUrgent: (value: boolean) => set({ isUrgent: value }),
    setIsConfidential: (value: boolean) => set({ isConfidential: value }),
    setIsRecording: (value: boolean) => set({ isRecording: value }),
    setRecipients: (recipients: Recipient[]) => set({ recipients }),
    setSelectedRecipients: (recipientId: string) => {
      const selectedRecipients = getState().selectedRecipients
      set({ selectedRecipients: { ...selectedRecipients, [recipientId]: !selectedRecipients[recipientId] } })
    },
    clearSelectedRecipients: () => set({ selectedRecipients: {} }),
    setDiscardAudioModal: (discardAudio: boolean) => set({ modals: { ...getState().modals, discardAudio } }),
    setImageModal: (imageModal: boolean) => set({ modals: { ...getState().modals, imageModal } }),
    setCancelMessageModal: (cancelMessage: boolean) => set({ modals: { ...getState().modals, cancelMessage } }),
    setSelectRecipientsModal: (selectRecipientsModal: boolean) =>
      set({ modals: { ...getState().modals, selectRecipientsModal } }),
    clear: () =>
      set({
        recipients: [],
        isUrgent: false,
        mp3File: null,
        imageFile: null,
        image: null,
        afterRecording: false,
        selectedRecipients: {}
      }),
    setAfterRecording: (afterRecording: boolean) => set({ afterRecording }),
    onDiscardMessage: () => {
      const { setCancelMessageModal, clear } = getState().actions
      setCancelMessageModal(false)
      clear()
    },
    onDiscardAudio: () => {
      const { setDiscardAudioModal } = getState().actions
      setDiscardAudioModal(false)
      set({ mp3File: null, afterRecording: false })
    }
  },
  modals: {
    discardAudio: false,
    cancelMessage: false,
    imageModal: false,
    selectRecipientsModal: false
  }
}))

export const useNewMessageModals = () => useNewMessageStore((state) => state.modals)
export const useNewMessageActions = () => useNewMessageStore((state) => state.actions)
