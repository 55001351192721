import React, { useEffect, useState } from 'react'
import { createGlobalStyle } from 'styled-components'
import { BrowserRouter as Router, Switch, Route, Redirect, RouteProps } from 'react-router-dom'

import { Reset } from './assets/reset'
import LoginPage from './navigation/shared/Login'
import AppContainer from './components/AppContainer'
import Modal from './components/Modal'
import MainApp from './MainApp'
import { Loader } from './components/Loader/Loader'
import { colors } from './assets/colors'
import Video from './VideoConferencing/pages/Video/'
import { hideAlertModal, useModalsStore } from './store/modals'
import { shallow } from 'zustand/shallow'
import { useAuth } from './store/user'
import tokenManager from './services/TokenManager'

export const broadcastChannel = new BroadcastChannel('video_channel')

const GlobalStyle = createGlobalStyle`
  ${Reset}
`

const App: React.FC = () => {
  const authenticated = useAuth()
  const alertModal = useModalsStore((state) => state.alert, shallow)

  const [loading] = useState(false)

  useEffect(() => {
    ;(function () {
      const uv = document.createElement('script')
      uv.type = 'text/javascript'
      uv.async = true
      uv.src = '//widget.uservoice.com/0nccIx9c7BpUheNL7NA.js'
      const s = document.getElementsByTagName('script')[0]
      if (s && s.parentNode) s.parentNode.insertBefore(uv, s)
    })()
  }, [])

  if (loading) {
    return (
      <AppContainer>
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Loader fill={colors.mediumGrey} />
        </div>
      </AppContainer>
    )
  }

  return (
    <AppContainer>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route exact path="/conference/:id" component={Video} />
          <PrivateRoute path="/" component={MainApp} authenticated={authenticated} />
          <Route path="*">
            <Redirect to={'/'} />
          </Route>
        </Switch>
      </Router>

      <Modal show={alertModal.visible}>
        <Modal.TextContainer>
          <Modal.HeroText>Error</Modal.HeroText>
          <Modal.DescriptionText>{alertModal.message}</Modal.DescriptionText>
        </Modal.TextContainer>
        <Modal.ButtonsContainer>
          <Modal.Button onClick={() => hideAlertModal()}>Ok</Modal.Button>
        </Modal.ButtonsContainer>
      </Modal>
    </AppContainer>
  )
}

interface IPrivateRoute extends RouteProps {
  authenticated: boolean
}

function PrivateRoute(props: IPrivateRoute) {
  const { authenticated, ...rest } = props
  if (!authenticated) return <Redirect to={{ pathname: '/login' }} />
  return <Route {...rest} />
}

export default App
