import styled from 'styled-components'
import { colors } from '../../../../../assets/colors'

export const ConferenceAtendee = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #fff;
  border: 1px solid ${colors.mediumGrey};
  border-radius: 30px;
  overflow: hidden;
`
