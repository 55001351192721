import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import Header from '../../../../components/Header'
import { InlineButton } from '../../../../components/Button/InlineButton'
import { colors } from '../../../../assets/colors'
import APIClient from '../../../../apiClient'
import { useUser } from '../../../../store/user'

const FeedbackPage: React.FC = (props) => {
  const history = useHistory()
  const userVoiceWidgetRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const user = useUser()
  const [userEmails, setUserEmails] = useState<string[]>([])

  useEffect(() => {
    async function getUserEmail() {
      if (!user) return
      const phoneNumber = user.access_numbers[0] || '' // use first number by default for Feedback
      if (!phoneNumber) return
      const { data } = await APIClient.phoneReverseLookup({ phone_number: phoneNumber })
      if (!data[0]) return // use first account by default
      setUserEmails(data[0].emails)
    }

    getUserEmail()
  }, [user])

  useEffect(() => {
    if (!userEmails?.length) return

    const identifiedUser = {
      email: userEmails[0],
      name: `${user.first_name || ''} ${user.last_name || ''}`,
      id: user.account_number,
      type: 'User'
    }

    // @ts-ignore
    window.UserVoice.push(['identify', identifiedUser])

    setLoading(false)
  }, [userEmails])

  useLayoutEffect(() => {
    // @ts-ignore
    if (!user || !window.UserVoice || !userVoiceWidgetRef.current) return
    // @ts-ignore
    window.UserVoice.push([
      'embed',
      '#target',
      {
        mode: 'post_idea',
        height: '100%',
        width: '100%',

        forum_id: '928516',
        accent_color: colors.darkBlue,
        locale: 'en',
        ticket_custom_fields: {
          Product: 'Progressive Web App',
          Type: 'Feedback'
        },
        screenshot_enabled: true,
        strings: {
          post_suggestion_title: '',
          post_suggestion_body: '',
          post_suggestion_message_placeholder: 'Describe your feedback'
        }
      }
    ])
  }, [loading])

  return (
    <>
      <Header>
        <Header.SideButtons>
          <InlineButton onClick={() => history.goBack()}>Back</InlineButton>
        </Header.SideButtons>
        <Header.Title>
          <span>Feedback</span>
        </Header.Title>
        <Header.SideButtons />
      </Header>
      <div style={{ display: 'flex', width: '100%', height: '100%', backgroundColor: colors.silver }}>
        <div style={{ flex: 1 }} ref={userVoiceWidgetRef} id="target" />
      </div>
    </>
  )
}

export default FeedbackPage
