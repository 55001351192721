import React, { ReactNode } from 'react'
import styled from 'styled-components'

const Button = styled.button`
  padding: 0;
  outline: 0;
  border: 0;
  background-color: transparent;
`

interface IAction {
  children: ReactNode
  onClick: () => void
}

const Action = ({ children, onClick }: IAction) => <Button onClick={onClick}>{children}</Button>

export default Action
