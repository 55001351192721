import { AxiosInstance } from 'axios'
import { BillableEventData } from '../../apiClient'
import { vonageClient, vonageGuestClient } from '../../client'
import * as Sentry from '@sentry/react'

export const filterEventsAfterSendingToApi = (sendedEvent: BillableEventData, eventsToFilter: BillableEventData[]) => {
  const eventsCopy = [...eventsToFilter]
    .filter((ev) => {
      if (ev.timestampEnd) {
        if (!sendedEvent.timestampEnd) return true
        return ev.timestampEnd !== sendedEvent.timestampEnd
      }
      return true
    })
    .filter((ev) => ev.timestampStart !== sendedEvent.timestampStart)

  const connectionStartArr = eventsCopy.filter((ev) => ev.event === 'connectionCreated')

  const connectionEndArr = eventsCopy.filter((ev) => ev.event === 'connectionDestroyed')

  const emptyArr: BillableEventData[] = []

  const lastConnectionHeartBeat: BillableEventData | undefined = eventsCopy
    .filter((ev) => ev.event === 'connectionHeartBeat')
    .sort((ev1, ev2) => {
      if (ev1.timestampEnd && ev2.timestampEnd) {
        return ev1.timestampEnd - ev2.timestampEnd
      }
      return 0
    })
    .pop()

  let lastHeartbeat: BillableEventData[] = lastConnectionHeartBeat ? [lastConnectionHeartBeat] : emptyArr

  if (sendedEvent.event === 'connectionHeartBeat' && lastHeartbeat.length > 0) {
    lastHeartbeat = lastHeartbeat.filter((ev: BillableEventData) => {
      if (ev.timestampEnd) {
        if (!sendedEvent.timestampEnd) return false
        return ev.timestampEnd > sendedEvent.timestampEnd
      }
      return false
    })
  }

  const combinedArray = connectionStartArr.concat(lastHeartbeat, connectionEndArr)
  return combinedArray
}

export const removeUnnecessaryHeartbeats = (eventsCopy: BillableEventData[]) => {
  const connectionStartArr = eventsCopy.filter((ev) => ev.event === 'connectionCreated')

  const connectionEndArr = eventsCopy.filter((ev) => ev.event === 'connectionDestroyed')

  const lastHeartbeat =
    eventsCopy
      .filter((ev) => ev.event === 'connectionHeartBeat')
      .sort((ev1, ev2) => {
        if (ev1.timestampEnd && ev2.timestampEnd) {
          return ev1.timestampEnd - ev2.timestampEnd
        }
        return 0
      })
      .pop() || []
  return connectionStartArr.concat(lastHeartbeat, connectionEndArr)
}

export const sortArrayAfterApiFails = (eventsToFilter: BillableEventData[]) => {
  const eventsCopy = [...eventsToFilter]
  return removeUnnecessaryHeartbeats(eventsCopy)
}

export const sortArrayAfterApiFailsWith400 = (eventsToFilter: BillableEventData[]) => {
  const eventsCopy = [...eventsToFilter].slice(1)
  return removeUnnecessaryHeartbeats(eventsCopy)
}

export class ConferenceEventsLogger {
  events: BillableEventData[]
  sendingLocked = false
  client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
    this.events = JSON.parse(localStorage.getItem('bevents') || '[]')
    this.sendNextEventFromTheStack = this.sendNextEventFromTheStack.bind(this)
    this.sendEventToAPI = this.sendEventToAPI.bind(this)
  }

  saveEventsToStorage(eventsToSave: BillableEventData[]) {
    localStorage.setItem('bevents', JSON.stringify(eventsToSave))
  }

  enqueueEvent(event: BillableEventData) {
    this.events.push(event)
    this.saveEventsToStorage(this.events)
    this.sendNextEventFromTheStack()
  }

  async sendEventToAPI(event: BillableEventData) {
    this.sendingLocked = true

    try {
      // await APIClient.postBillableEvent([event])
      await this.client.post(`/katemobile/billable_event`, [event], {
        'axios-retry': {
          retries: 3,
          retryDelay: (counter) => {
            switch (counter) {
              case 1:
                return 2000
              case 2:
                return 4000
              case 3:
                return 8000
              default:
                return 4000
            }
          },
          retryCondition: (error) => true
        }
      })

      const combinedArray = filterEventsAfterSendingToApi(event, this.events)

      this.saveEventsToStorage(combinedArray)
      this.events = combinedArray

      this.sendingLocked = false
      this.sendNextEventFromTheStack()
    } catch (e) {
      Sentry.captureException(e)
      Sentry.addBreadcrumb({
        category: 'conference',
        message: `Unable to send event: ${event.event}`,
        level: 'error'
      })
      if (this.events.length > 1) {
        // @ts-ignore
        if (e?.response?.status === 400) {
          const combinedArray: BillableEventData[] = []
          this.saveEventsToStorage(combinedArray)
          this.events = combinedArray
        } else {
          const combinedArray = sortArrayAfterApiFails(this.events)
          this.saveEventsToStorage(combinedArray)
          this.events = combinedArray
        }
      }

      setTimeout(() => {
        this.sendingLocked = false
        this.sendNextEventFromTheStack()
      }, 2000)
    }
  }

  sendNextEventFromTheStack() {
    if (!this.events.length) {
      return
    }

    if (this.sendingLocked) {
      return
    }

    const event = this.events[0]

    // @ts-ignore
    if (event.shortId) {
      const eventCopy = { ...event }
      // @ts-ignore
      eventCopy.meetingId = eventCopy.shortId.replace('meetingId=', '')
      // @ts-ignore
      const { shortId, ...objToSend } = eventCopy
      this.sendEventToAPI(objToSend)
    } else {
      this.sendEventToAPI(event)
    }
  }

  clearQueue() {
    this.events = []
  }
}

export const conferenceEventsLogger = new ConferenceEventsLogger(vonageClient)
export const guestConferenceEventsLogger = new ConferenceEventsLogger(vonageGuestClient)
