import jwt_decode from 'jwt-decode'
import { authClient } from '../client'
import tokenManager from './TokenManager'

export default class JwtService {
  private kateToken: string
  private refreshToken: string
  private refreshInterval: number
  constructor() {
    this.kateToken = localStorage.getItem('kateTokenX')
    this.refreshToken = localStorage.getItem('refreshToken')
    this.refreshInterval = null
    this.checkToken()
  }

  decodeToken(token) {
    return jwt_decode(token)
  }

  checkToken() {
    const kateToken = localStorage.getItem('kateTokenX')

    if (kateToken) {
      const decodedToken = this.decodeToken(kateToken)
      const now = Date.now().valueOf() / 1000

      // @ts-ignore
      if (typeof decodedToken.exp !== 'undefined' && decodedToken.exp < now) {
        tokenManager.refreshKateToken()
      }
    }
  }

  scheduleTokenRefresh(timeToExpiration) {
    // Refresh token 30 seconds before it expires
    const refreshTime = (timeToExpiration - 30) * 1000
    this.refreshInterval = setTimeout(() => {
      tokenManager.refreshKateToken().then(() => {
        this.checkToken()
      })
    }, refreshTime)
  }
}
