import styled from 'styled-components'
import React from 'react'

const ContactName = styled.span`
  font-size: 1.125rem;
  padding-bottom: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export default ContactName
