import React, { useState } from 'react'
import { Layout, RightPanel } from '../../../../layout/Layout'
import useContacts from '../../../mobile/pages/Contacts/Common/Hooks/useContacts'
import ContactsListPanel from './Ui/Partials/ContactsListPanel'
import ContactInfo from './Ui/Partials/ContactInfo'
import useCurrentParam from '../../../../hooks/useCurrentParam'
import { Wrapper } from './Ui/Styled'
import CreateContactForm from './Ui/Partials/CreateContactForm'
import EditContactForm from './Ui/Partials/EditContactForm'
import { useGroupsQuery } from '../../../../hooks/queries/useGroupsQuery'
import NoContacts from '../../../../components/Icon/NoContacts'
import TextNote from '../../components/TextNote'
import styled from 'styled-components'
import NewActionButton from '../../components/NewActionButton'
import { useHistory } from 'react-router-dom'
import NoContactsYet from '../../containers/NoContactsYet'
import { hideContactsModal, showContactsModal, useModalsStore } from '../../../../store/modals'
import NotSavedFields from './Ui/Components/Modals/NotSavedFields'

const ContactDetails = ({ currentContactId, currentContact, sendMessage }) => {
  const { data: groupsData } = useGroupsQuery()
  const [currentTab] = useCurrentParam<'create' | 'edit' | null>('action')

  if (!currentContactId && currentTab === null) {
    return <NoContactsYet />
  }

  return (
    <>
      {currentTab === null && (
        <ContactInfo currentContactId={currentContactId} currentContact={currentContact} sendMessage={sendMessage} />
      )}
      {currentTab === 'create' && <CreateContactForm groupsData={groupsData} />}
      {currentTab === 'edit' && <EditContactForm groupsData={groupsData} />}
    </>
  )
}

const Contacts = () => {
  const history = useHistory()
  const { isLoading, search, currentContact, currentContactId, filteredContacts, sendMessage } = useContacts()
  const visible = useModalsStore((state) => state.contacts.visible)

  return (
    <>
      <Layout>
        <ContactsListPanel
          search={search}
          filteredContacts={filteredContacts}
          isLoading={isLoading}
          currentContactId={currentContactId}
        />
        <RightPanel>
          <Wrapper>
            <ContactDetails
              currentContactId={currentContactId}
              currentContact={currentContact}
              sendMessage={sendMessage}
            />
          </Wrapper>
        </RightPanel>
      </Layout>
    </>
  )
}

export default Contacts
