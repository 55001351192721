import React from 'react'
import Copy from '../../../../../../components/Icon/Copy'
import Container from './Container'
import CopyMeetingUrl from './CopyMeetingUrl'
import MeetingUrl from './Meeting'

interface UrlContainerProps {
  meetingUrl: string
}

const UrlContainer = ({ meetingUrl }: UrlContainerProps) => {
  return (
    <Container>
      <CopyMeetingUrl style={{ marginRight: 5 }}>Copy Meeting URL:</CopyMeetingUrl>
      <MeetingUrl style={{ marginRight: 5 }}>{meetingUrl}</MeetingUrl>
      <Copy />
    </Container>
  )
}

export default UrlContainer
