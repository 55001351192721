import { useMemo } from 'react'
import { getDate } from 'date-fns'
import format from 'date-fns/format'

function useDateForConference(createdAt: string, startDate?: string, endDate?: string) {
  return useMemo(() => {
    if (!startDate || !endDate) {
      return {
        day: getDate(new Date(createdAt)),
        month: format(new Date(createdAt), 'MMM'),
        timeRange: ''
      }
    }
    return {
      day: getDate(new Date(startDate)),
      month: format(new Date(startDate), 'MMM'),
      timeRange: `${format(new Date(startDate), 'p')} - ${format(new Date(endDate), 'p')}`
    }
  }, [startDate, endDate, createdAt])
}

export default useDateForConference
