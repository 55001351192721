import styled from 'styled-components'

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #8e8e93;
  opacity: 0.2;
  margin-top: 10px;
  margin-bottom: 10px;
`
