import { create } from 'zustand'
import { shallow } from 'zustand/shallow'

interface Modal {
  visible: boolean
  message: string
}

interface ModalStoreState {
  info: Modal
  alert: Modal
  contacts: {
    visible: boolean
  }
  closeAccount: {
    visible: boolean
  }
  actions: {
    setInfoModal: ({ visible, message }) => void
    setAlertModal: ({ visible, message }) => void
    setContactsModal: ({ visible }) => void
    setCloseAccountModal: (visible: boolean) => void
  }
}

export const useModalsStore = create<ModalStoreState>()((set) => ({
  info: {
    visible: false,
    message: ''
  },
  alert: {
    visible: false,
    message: ''
  },
  contacts: {
    visible: false
  },
  closeAccount: {
    visible: false
  },
  actions: {
    setInfoModal: ({ visible, message }) => set((state) => ({ ...state, info: { visible, message } })),
    setAlertModal: ({ visible, message }) => set((state) => ({ ...state, alert: { visible, message } })),
    setContactsModal: ({ visible }) => set((state) => ({ ...state, contacts: { visible } })),
    setCloseAccountModal: (visible) => set((state) => ({ ...state, closeAccount: { visible } }))
  }
}))

export const useModalActions = () => useModalsStore((state) => state.actions, shallow)
const modalStoreState = () => useModalsStore.getState()

export const showInfoModal = (message: string) =>
  useModalsStore.setState({ ...modalStoreState, info: { visible: true, message } })
export const hideInfoModal = () =>
  useModalsStore.setState({ ...modalStoreState, info: { visible: false, message: '' } })
export const showAlertModal = (message: string) =>
  useModalsStore.setState({ ...modalStoreState, alert: { visible: true, message } })
export const hideAlertModal = () =>
  useModalsStore.setState({ ...modalStoreState, alert: { visible: false, message: '' } })
export const showContactsModal = () => useModalsStore.setState({ ...modalStoreState, contacts: { visible: true } })
export const hideContactsModal = () => useModalsStore.setState({ ...modalStoreState, contacts: { visible: false } })
