import React from 'react'
import styled from 'styled-components'
import { Profile } from '../../components/Header/Profile'
import { colors } from '../../assets/colors'
import { useUser } from '../../store/user'
import { useHistory, useLocation } from 'react-router'
import Icon from '../../components/Icon'

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 208px;
  height: 100%;
`
const Name = styled.span`
  font-size: 15px;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  padding: 0 10px;
  text-align: center;
`
const KateNumber = styled.span`
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  color: ${colors.lightGrey};
  margin-top: 5px;
`

const Menu = styled.div`
  width: 100%;
  margin-top: 20px;
`
const MenuItem = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: ${({ active }) => (active ? colors.darkBlue : colors.mediumGrey)};
  height: 46px;
  border-left: ${({ active }) => (active ? `4px solid ${colors.darkBlue}` : 'none')};
  cursor: pointer;

  &:hover {
    background-color: rgba(29, 112, 182, 0.05);
  }

  > span {
    padding-left: 16px;
  }
`

const LeftPanel = () => {
  const user = useUser()
  const { pathname } = useLocation()
  const history = useHistory()

  const IS_ENG_BETA_TESTER = user?.eng_beta_tester

  return (
    <Container>
      <Profile width={60} height={60} borderRadius={60} style={{ margin: '20px 0 20px 0' }} />
      <Name>
        {user?.first_name} {user?.last_name}
      </Name>
      <KateNumber>{user?.access_numbers?.[0]}</KateNumber>
      <Menu>
        <MenuItem
          active={pathname.includes('/messages')}
          onClick={() => {
            history.push('/messages')
          }}
        >
          <Icon
            name={'voiceMail'}
            fill={pathname.includes('/messages') ? colors.darkBlue : colors.mediumGrey}
            style={{ width: '24px', marginLeft: '15px' }}
          />
          <span>Messages</span>
        </MenuItem>
        <MenuItem
          active={pathname.includes('tags')}
          onClick={() => {
            history.push('/tags')
          }}
        >
          <Icon
            name={'bookmark'}
            fill={pathname.includes('/tags') ? colors.darkBlue : colors.mediumGrey}
            style={{ width: '24px', marginLeft: '15px' }}
          />
          <span>Tags</span>
        </MenuItem>
        <MenuItem
          active={pathname.includes('/contacts') || pathname.includes('/groups')}
          onClick={() => {
            history.push('/contacts')
          }}
        >
          <Icon
            name={'userAccount'}
            fill={pathname.includes('/contacts') || pathname.includes('/groups') ? colors.darkBlue : colors.mediumGrey}
            style={{ width: '24px', marginLeft: '15px' }}
          />
          <span>Contacts</span>
        </MenuItem>
        {IS_ENG_BETA_TESTER ? (
          <MenuItem
            active={pathname.includes('video-conferencing')}
            onClick={() => {
              history.push('/video-conferencing')
            }}
          >
            <Icon
              name={'movieCamera'}
              fill={pathname.includes('/video-conferencing') ? colors.darkBlue : colors.mediumGrey}
              style={{ width: '24px', marginLeft: '15px' }}
            />
            <span>Video Conference</span>
          </MenuItem>
        ) : null}
        {IS_ENG_BETA_TESTER ? (
          <MenuItem
            active={pathname.includes('chat')}
            onClick={() => {
              history.push(
                localStorage.getItem('lastChannel') ? `/chat/${localStorage.getItem('lastChannel')}` : '/chat'
              )
            }}
          >
            <Icon
              name={'chatMessage'}
              fill={pathname.includes('/chat') ? colors.darkBlue : colors.mediumGrey}
              style={{ width: '24px', marginLeft: '15px' }}
            />
            <span>Chat</span>
          </MenuItem>
        ): null}
        <MenuItem
          active={pathname.includes('options')}
          onClick={() => {
            history.push('/options')
          }}
        >
          <Icon
            name={'settings'}
            fill={pathname.includes('/options') ? colors.darkBlue : colors.mediumGrey}
            style={{ width: '24px', marginLeft: '15px' }}
          />
          <span>Settings</span>
        </MenuItem>
      </Menu>
    </Container>
  )
}

export default LeftPanel
