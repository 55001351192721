import styled from 'styled-components'
import { colors } from '../../../../assets/colors'

const Title = styled.div`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: ${colors.darkBlue};
`

export default Title
