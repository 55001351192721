import React, { useEffect } from 'react'
import { Avatar, Box, Typography } from '@mui/material'
import styled from 'styled-components'
import { colors } from '../../../../../../assets/colors'
import { useGetMessagesById } from '../../../../../../hooks/queries/useGetMessagesById'
import { useLocation } from 'react-router'
import { contactName, sentMessageTo } from '../../../../../../containers/MessageListItem'
import Message from '../../../../../../components/blocks/Message'
import format from 'date-fns/format'
import { useAudioMessageActions, useAudioMessageStore } from '../../../../../../store/audioMessage'
import { shallow } from 'zustand/shallow'
import Spinner from '../../../../../../components/Icon/Spinner'
import MessagesIcon from '../../../../../../components/Icon/MessagesIcon'
import TextNote from '../../../../components/TextNote'
import NewActionButton from '../../../../components/NewActionButton'
import { useHistory } from 'react-router-dom'
import { useGetMessagesByType } from '../../../../../../hooks/queries/useGetMessagesByType'
import Line from '../../../../components/Line'
import AudioPlayer from '../../containers/AudioPlayer'
import ReplyMessage from '../../ReplyMessage'
import ForwardMessage from '../../ForwardMessage'
import ActionButtons from './components/ActionButtons'
import AvatarContact from '../AvatarContact'

const NewMessageButton = styled(NewActionButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 136px;

  margin-top: 1rem;
`

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const MessageView = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const id = params.get('id')
  const action = params.get('action')

  const history = useHistory()

  const type = location?.pathname?.split('/messages/')?.[1] || null

  const { data: message, isLoading: isLoadingMessage } = useGetMessagesById(id, true)
  const { data: messages, isLoading: isLoadingAllMessages } = useGetMessagesByType(type, {
    params: { confidential: true }
  })

  const isLoadingAudio = useAudioMessageStore((state) => state.loading, shallow)

  const isLoading = isLoadingMessage || isLoadingAudio

  const { setAudioPlaying, setAudioMessageId, setCurrentMessageId, setAudioCurrentTime } = useAudioMessageActions()

  useEffect(() => {
    if (message) {
      setCurrentMessageId(message.uniqueId)
      setAudioMessageId(message.uniqueId)
    }
    return () => {
      setAudioMessageId(null)
      setCurrentMessageId(null)
      setAudioPlaying(false)
      setAudioCurrentTime(message?.uniqueId, 0)
    }
  }, [message])

  if (!messages?.length && !isLoadingAllMessages) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%'
        }}
      >
        <MessagesIcon />
        <TextNote>You have no messages yet</TextNote>
        <NewMessageButton onClick={() => history.push(`${window.location.pathname}?action=new-message`)}>
          <span style={{ fontSize: '22px', marginRight: '5px' }}>+ </span>New Message
        </NewMessageButton>
      </div>
    )
  }

  if (isLoading)
    return (
      <MainContainer>
        <Spinner fill={colors.darkBlue} width={'50px'} />
      </MainContainer>
    )

  if (action === 'reply') {
    const label = message?.contact ? `${message?.contact?.first_name} ${message?.contact?.last_name}` : message?.source
    return (
      <ReplyMessage
        label={label}
        loading={isLoading}
        contactId={message?.contact?.id || null}
        contact={
          message?.contact || {
            kate_number: message?.source || null
          }
        }
      />
    )
  }

  if (action === 'forward') {
    const label = message?.contact ? `${message?.contact?.first_name} ${message?.contact?.last_name}` : message?.source
    return (
      <ForwardMessage
        label={label}
        loading={isLoading}
        contactId={message?.contact?.id || null}
        contact={
          message?.contact || {
            kate_number: message?.source || null
          }
        }
      />
    )
  }

  return (
    <>
      <Box width={'100%'} height={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Box width={'100%'} display={'flex'} alignItems={'center'} p={2} justifyContent={'space-between'}>
          <Box display={'flex'}>
            <AvatarContact message={message} />
            <Box ml={'10px'}>
              <Typography fontSize={'15px'} fontWeight={400}>
                {sentMessageTo(message) ? (
                  <Message.ToContact>To: {sentMessageTo(message)}</Message.ToContact>
                ) : (
                  <Message.ContactName>{contactName(message)}</Message.ContactName>
                )}
              </Typography>
              <Typography fontSize={'12px'} fontWeight={400} color={colors.mediumGrey}>
                {message?.contact ? message?.contact?.kateNumber : ''}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography fontSize={'12px'} fontWeight={400} color={colors.mediumGrey}>
              Received on {message && format(new Date(message?.timestamp * 1000), 'd MMM yyyy hh:mm aaa')}
            </Typography>
            <Typography fontSize={'12px'} fontWeight={400} color={colors.mediumGrey}>
              Expires on {message && format(new Date(message?.expiration_timestamp * 1000), 'd MMM yyyy hh:mm aaa')}
            </Typography>
          </Box>
        </Box>
        <Line />
        <Box width={'100%'} p={2} height={'70px'}>
          {message?.note && (
            <Box>
              <Typography maxHeight={'70px'} sx={{ overflowY: 'scroll' }}>
                {message?.note}
              </Typography>
            </Box>
          )}
        </Box>
        <AudioPlayer />
        <Line style={{ marginBottom: '2%' }} />
        <ActionButtons message={message} />
      </Box>
    </>
  )
}

export default MessageView
