import styled from 'styled-components'
import { colors } from '../../../../assets/colors'

const AudioSpeedButton = styled.button`
  width: 40px;
  outline: 0;
  border: 0;
  font-size: 12px;
  color: ${colors.mediumGrey};
  background-color: transparent;
`

export default AudioSpeedButton
