import React, { useCallback, useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
import { colors } from '../../../../assets/colors'
import Header from '../../../../components/Header'
import { InlineButton } from '../../../../components/Button/InlineButton'
import Icon from '../../../../components/Icon'
import MessageList from '../../../../components/blocks/MessageList'
import Field from '../../../../components/Field'
import Divider from '../../../../components/Divider'
import SwipeItem from '../../../../components/blocks/Message/SwipeItem'
import { Loader } from '../../../../components/Loader/Loader'
import APIClient from '../../../../apiClient'
import { RouteProps } from 'react-router-dom'
import styled from 'styled-components'
import { useQueryClient } from '@tanstack/react-query'
import { useGroupsQuery } from '../../../../hooks/queries/useGroupsQuery'

const ClickableDiv = styled.div`
  background-color: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
  opacity: 0.92;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

const MobileGroupsPage: React.FC<RouteProps & { match: any }> = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { data: groups, isLoading } = useGroupsQuery()

  const deleteGroupFunction = useCallback(async (id: string) => {
    try {
      const conf: boolean = window.confirm(
        `This action will permanently delete group refugees. Please double check this is the action you want.`
      )
      if (conf) {
        const deleteResponse = await APIClient.contactGroupDelete(id)
        if (deleteResponse.status >= 300)
          throw new Error(`status = ${deleteResponse.status}, ${deleteResponse.statusText}`)

        await queryClient.invalidateQueries(['groups'])
      }
    } catch (e) {
      console.log('error on group delete ===', e)
      window.alert('Ups, group cannot be deleted. Please reload the page and try again.')
    }
  }, [])

  const onDoneButtonClick = useCallback(() => {
    history.push('/contacts')
  }, [])

  return (
    <>
      <Header>
        <Header.SideButtons>
          <InlineButton onClick={onDoneButtonClick}>Done</InlineButton>
        </Header.SideButtons>
        <Header.EndButtons>
          <InlineButton onClick={() => history.push('/groupCreate')}>
            <Icon name={'plus'} fill={colors.white} width={'20px'} />
          </InlineButton>
        </Header.EndButtons>
      </Header>
      <MessageList>
        {isLoading ? (
          <div style={{ width: '100%', height: '100%', backgroundColor: colors.silver, paddingTop: '20px' }}>
            <Loader width={'20px'} height={'20px'} fill={colors.lightGrey} />
          </div>
        ) : groups?.length ? (
          groups.map((group) => {
            return (
              <SwipeItem
                key={group?.id}
                autoClose
                right={[
                  {
                    text: 'remove',
                    onPress: () => deleteGroupFunction(group?.id),
                    style: { backgroundColor: colors.red, color: colors.white, overflow: 'hidden' }
                  }
                ]}
              >
                <div style={{ backgroundColor: colors.silver }}>
                  <Field onClick={() => history.push(`/groupEdit?id=${group?.id}`)}>
                    <Field.InfoContainer>
                      <Field.Content>
                        <ClickableDiv>{group?.name}</ClickableDiv>
                      </Field.Content>
                    </Field.InfoContainer>
                  </Field>
                  <Divider />
                </div>
              </SwipeItem>
            )
          })
        ) : (
          <>
            <MessageList.Header>You don&apos;t have any groups yet.</MessageList.Header>
            <MessageList.Header> Tap on the plus sign to add one.</MessageList.Header>
          </>
        )}
      </MessageList>
    </>
  )
}

export default MobileGroupsPage
