import React, { SVGProps } from 'react'

const CameraOn = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="
      M2.83333 4C2.47971 4 2.14057 4.14048 1.89052 4.39052C1.64048 4.64057 1.5 4.97971 1.5 5.33333L1.5 14.6667C1.5 15.0203 1.64048 15.3594 1.89052 15.6095C2.14057 15.8595 2.47971 16 2.83333 16H14.1667C14.5203 16 14.8594 15.8595 15.1095 15.6095C15.3595 15.3594 15.5 15.0203 15.5 14.6667V5.33333C15.5 4.97971 15.3595 4.64057 15.1095 4.39052C14.8594 4.14048 14.5203 4 14.1667 4H2.83333ZM20.8333 5.33333C20.6591 5.33282 20.4916 5.40055 20.3667 5.522L16.8333 8.66667V11.3333L20.3827 14.4907L20.3933 14.5C20.4896 14.5846 20.6082 14.6396 20.7349 14.6585C20.8616 14.6774 20.9911 14.6594 21.1078 14.6067C21.2246 14.5539 21.3237 14.4687 21.3932 14.3611C21.4628 14.2535 21.4998 14.1281 21.5 14V6C21.5 5.82319 21.4298 5.65362 21.3047 5.5286C21.1797 5.40357 21.0101 5.33333 20.8333 5.33333Z"
      fill="#FFFFFF"
    />
  </svg>
)

export default CameraOn
