import React from 'react'
import styled from 'styled-components'
import ShareScreen from '../../../../../components/Icon/ShareScreen'
import { colors } from '../../../../assets/colors'

interface IProps {
  onClick: () => void
  selected: boolean
  showText: boolean
}

interface IconContainerProps {
  selected: boolean
}

const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  min-width: 70px;
  opacity: ${(props) => (props.selected ? 0.92 : 0.7)};
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
  @media (max-width: 599px) {
    min-width: 30px;
    max-width: 30px;
    max-height: 20px;
  }
`

const ShareScreenText = styled.a<{ selected: boolean }>`
  font-size: 15px;
  background: ${({ selected }) => (selected ? colors.red : '-webkit-linear-gradient(#3fb5e9, #1d70b6)')};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const ShareScreenIcon = ({ onClick, selected, showText }: IProps) => (
  <IconContainer selected={selected} onClick={onClick}>
    <div style={{ margin: 4, marginBottom: 6 }}>
      <ShareScreen selected={selected} />
    </div>
    {showText ? (
      <ShareScreenText selected={selected}>{selected ? 'Stop Sharing' : 'Share Screen'}</ShareScreenText>
    ) : null}
  </IconContainer>
)

export default ShareScreenIcon
