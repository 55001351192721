import styled from 'styled-components'
import { colors } from '../../../../assets/colors'

const TextNote = styled.span`
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  color: ${colors.mediumGrey};
  margin-top: 5px;
  line-height: 1.3;
`

export default TextNote
