import React from 'react'
import styled from 'styled-components'
import { Day } from '../../../types/calendar/Day'
import DisabledDay from '../days/DisabledDay'
import MonthDay from '../days/MonthDay'
import PreviousMonthDay from '../days/PreviousMonthDay'
import SelectedMonthDay from '../days/SelectedMonthDay'

const MonthDays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 5px;
`

interface MonthDaysGridProps {
  days: Day[]
  selectedDay: Day
  onDayClick: (day: Day) => void
  monthNumber: number
}

const MonthDaysGrid = ({ days, selectedDay, onDayClick, monthNumber }: MonthDaysGridProps) => {
  const now = new Date().getTime()
  return (
    <MonthDays>
      {days.map((day) => {
        return day.date === selectedDay.date && day.month === selectedDay.month && day.year === selectedDay.year ? (
          <SelectedMonthDay onClick={() => onDayClick(day)} key={day.timestamp}>
            {day.date}
          </SelectedMonthDay>
        ) : monthNumber !== day.monthNumber ? (
          <PreviousMonthDay
            // onClick={() => window.alert('Past date cannot be selected')}
            key={day.timestamp}
          >
            {day.date}
          </PreviousMonthDay>
        ) : now - day.timestamp > 64800000 ? (
          <MonthDay
            style={{ color: '#9a9a9a' }}
            // onClick={() => window.alert('Past date cannot be selected')}
            key={day.timestamp}
          >
            {day.date}
          </MonthDay>
        ) : (
          <MonthDay onClick={() => onDayClick(day)} key={day.timestamp}>
            {day.date}
          </MonthDay>
        )
      })}
    </MonthDays>
  )
}

export default MonthDaysGrid
