import React, { useState, useEffect } from 'react'
import Container from './components/Container'
import { Calendar } from '../../types/calendar/Calendar'
import { Day } from '../../types/calendar/Day'
import CalendarHeader from './components/CalendarHeader'
import WeekDaysGrid from './components/WeekDaysGrid'
import MonthDaysGrid from './components/MonthDaysGrid'

interface IProps {
  selectedDay: Day
  onDayChange: (day: Day) => void
}

const CalendarContainer: React.FC<IProps> = (props: IProps) => {
  const [calendar] = useState<Calendar>(new Calendar())
  const [days, setDays] = useState<Day[]>([])

  const prevMonth = () => {
    calendar.goToPreviousMonth()
    setDays(getMonthDaysGrid())
  }

  const nextMonth = () => {
    calendar.goToNextMonth()
    setDays(getMonthDaysGrid())
  }

  const changeDay = (day: Day) => {
    props.onDayChange(day)
  }

  const getMonthDaysGrid = () => {
    const firstDayOfTheMonth = calendar.month.getDay(1)
    const prevMonth = calendar.getPreviousMonth()
    const totalLastMonthFinalDays = firstDayOfTheMonth.dayNumber - 1
    const totalDays = calendar.month.numberOfDays + totalLastMonthFinalDays
    const monthList: Day[] = Array.from({ length: totalDays })

    for (let i = totalLastMonthFinalDays; i < totalDays; i++) {
      monthList[i] = calendar.month.getDay(i + 1 - totalLastMonthFinalDays)
    }

    for (let i = 0; i < totalLastMonthFinalDays; i++) {
      const inverted = totalLastMonthFinalDays - (i + 1)
      monthList[i] = prevMonth.getDay(prevMonth.numberOfDays - inverted)
    }

    return monthList
  }
  //
  useEffect(() => {
    setDays(getMonthDaysGrid())
  }, [])

  const headerLabel = `${props.selectedDay.day}, ${props.selectedDay.date} ${props.selectedDay.month} ${props.selectedDay.year}`

  return (
    <Container>
      <CalendarHeader label={headerLabel} onPrevClick={() => prevMonth()} onNextClick={() => nextMonth()} />
      <WeekDaysGrid />
      <MonthDaysGrid
        days={days}
        selectedDay={props.selectedDay}
        monthNumber={calendar.month.number}
        onDayClick={changeDay}
      />
    </Container>
  )
}

export default CalendarContainer
