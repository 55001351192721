import React, { ReactNode } from 'react'
import styled from 'styled-components'
import Title from './Title'
import Avatar from './Avatar'

const Wrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
`

interface HeaderProps {
  children?: ReactNode
}

const ContactShowHeader = ({ children }: HeaderProps) => <Wrapper>{children}</Wrapper>

ContactShowHeader.Title = Title
ContactShowHeader.Avatar = Avatar

export default ContactShowHeader
