import React from 'react'
import { ContactItemType, GroupItemType } from '../../../../../../../types'
import { StyledContactInfo } from '../../../../../../desktop/pages/Contacts/Ui/Styled/StyledContactInfo'
import PencilIcon from '../../../../../../desktop/pages/Contacts/Ui/Components/Icons/PencilIcon'
import { Loader } from '../../../../../../../components/Loader/Loader'
import { colors } from '../../../../../../../assets/colors'
import ContactRow from '../../../../../../../components/GroupsAndContacts/ContactRow'
import Icon from '../../../../../../../components/Icon'
import { Virtuoso } from 'react-virtuoso'

interface IProps {
  currentGroup?: GroupItemType
  groupContacts?: ContactItemType[]
  contactsLoading: boolean
}

const GroupInfo = ({ currentGroup, groupContacts, contactsLoading }: IProps) => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '30px'
        }}
      >
        <p style={{ fontSize: '32px', fontWeight: 600 }}>{currentGroup?.name}</p>
        <StyledContactInfo.StyledEditLink to={`/groups?id=${currentGroup.id}&action=edit`}>
          <PencilIcon />
          Edit
        </StyledContactInfo.StyledEditLink>
      </div>
      <div style={{ overflowY: 'auto', height: 'calc(100% - 50px)' }}>
        {contactsLoading ? (
          <Loader width="20px" height="20px" fill={colors.lightGrey} />
        ) : groupContacts?.length ? (
          <Virtuoso
            style={{ height: '100%' }}
            data={groupContacts}
            itemContent={(_, contact) => {
              return (
                <ContactRow
                  nameStyle={{ width: '100%' }}
                  wrapperStyle={{ paddingLeft: 0, paddingRight: 0 }}
                  to={`/contacts?id=${contact.id}`}
                  active={false}
                  avatar={{
                    initials: `${contact?.first_name ? contact?.first_name?.[0] : ''}${
                      contact?.last_name ? contact?.last_name?.[0] : ''
                    }`,
                    src: contact?.photo_url && contact?.photo_url
                  }}
                  name={`${contact?.first_name || ''} ${contact?.last_name || ''}`}
                  kateNumber={`${contact?.kate_number || ''}`}
                />
              )
            }}
          />
        ) : (
          <p style={{ color: colors.lightGrey, marginTop: '20px' }}>No contacts in group</p>
        )}
      </div>
    </div>
  )
}

export default GroupInfo
