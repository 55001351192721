import React from 'react'

interface VideocamProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const Calendar = ({
  style = {},
  fill = '#707070',
  width = '16px',
  className = '',
  viewBox = '0 0 16 16'
}: VideocamProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M2.80769 0.5V1.65385H2.23077C1.27975 1.65385 0.5 2.43359 0.5 3.38462V13.7692C0.5 14.7203 1.27975 15.5 2.23077 15.5H13.7692C14.7203 15.5 15.5 14.7203 15.5 13.7692V3.38462C15.5 2.43359 14.7203 1.65385 13.7692 1.65385H13.1923V0.5H12.0385V1.65385H3.96154V0.5H2.80769ZM2.23077 2.80769H2.80769V3.96154H3.96154V2.80769H12.0385V3.96154H13.1923V2.80769H13.7692C14.0938 2.80769 14.3462 3.0601 14.3462 3.38462V5.11538H1.65385V3.38462C1.65385 3.0601 1.90625 2.80769 2.23077 2.80769ZM1.65385 6.26923H14.3462V13.7692C14.3462 14.0938 14.0938 14.3462 13.7692 14.3462H2.23077C1.90625 14.3462 1.65385 14.0938 1.65385 13.7692V6.26923ZM5.11538 7.42308V8.57692H6.26923V7.42308H5.11538ZM7.42308 7.42308V8.57692H8.57692V7.42308H7.42308ZM9.73077 7.42308V8.57692H10.8846V7.42308H9.73077ZM12.0385 7.42308V8.57692H13.1923V7.42308H12.0385ZM2.80769 9.73077V10.8846H3.96154V9.73077H2.80769ZM5.11538 9.73077V10.8846H6.26923V9.73077H5.11538ZM7.42308 9.73077V10.8846H8.57692V9.73077H7.42308ZM9.73077 9.73077V10.8846H10.8846V9.73077H9.73077ZM12.0385 9.73077V10.8846H13.1923V9.73077H12.0385ZM2.80769 12.0385V13.1923H3.96154V12.0385H2.80769ZM5.11538 12.0385V13.1923H6.26923V12.0385H5.11538ZM7.42308 12.0385V13.1923H8.57692V12.0385H7.42308Z"
    />
  </svg>
)

export default Calendar
