import styled from 'styled-components'
import { colors } from '../../../../assets/colors'

export const YearText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.darkBlue};
`
