import React from 'react'
import { CardBrand } from '../../../../../../../gql/queries/getStripeCustomerPaymentMethodsQuery'
import VisaImg from '../../../Shared/Common/assets/Cc_assets/visa.png'
import MasterCardImg from '../../../Shared/Common/assets/Cc_assets/mastercard.png'
import AmexImg from '../../../Shared/Common/assets/Cc_assets/amex.png'
import DiscoverImg from '../../../Shared/Common/assets/Cc_assets/discover.png'
import DefaultCardImg from '../../../Shared/Common/assets/Cc_assets/Default.png'

const CardImg = ({ brand }: { brand: CardBrand }) => {
  switch (brand) {
    case 'visa':
      return <img src={VisaImg} width={50} height={16} alt="visa" />
    case 'mastercard':
      return <img src={MasterCardImg} width={46} height={33} alt="master card" />
    case 'amex':
      return <img src={AmexImg} width={40} height={40} alt="Amex" />
    case 'discover':
      return <img src={DiscoverImg} width={50} height={25} alt="Discover" />
    default:
      return <img src={DefaultCardImg} width={40} height={25} alt="Discover" />
  }
}

export default CardImg
