import React, { createContext, useContext, useMemo } from 'react'
import useAudioPlaying from '../../../../../hooks/useAudioPlaying'
import { Howl } from 'howler'

interface AudioPlayingContextProps {
  audio: Howl | null
  curTime: number
  duration: number
  playing: boolean
  playProgress: number
  setClickedTime: (time: number) => void
  setAudio: (audio: Howl | null) => void
  pauseAudio: () => void
  stopAudio: () => void
  playAudio: () => void
}

export const AudioPlayingContext = createContext<AudioPlayingContextProps>({} as AudioPlayingContextProps)
export const AudioPlayingProvider = ({ children }) => {
  const audioPlaying = useAudioPlaying()

  return <AudioPlayingContext.Provider value={{ ...audioPlaying }}>{children}</AudioPlayingContext.Provider>
}

export const useAudioPlayingContext = () => useContext(AudioPlayingContext)
