import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import APIClient from '../apiClient'
import { MessagesType } from './queries/useGetMessagesByType'
import { useUser } from '../store/user'
import * as Sentry from '@sentry/react'

export const usePrefetchData = () => {
  const user = useUser()
  const queryClient = useQueryClient()

  useEffect(() => {
    ;(async function () {
      try {
        await queryClient.prefetchQuery({
          queryKey: ['contacts', ''],
          queryFn: async () => {
            const res = await APIClient.contactList()
            return res.data
          }
        })
        await queryClient.prefetchQuery({
          queryKey: ['tags'],
          queryFn: async () => {
            const { data } = await APIClient.messagesWithTagsList()
            return data
          }
        })
        await queryClient.prefetchQuery({
          queryKey: ['messages', MessagesType.new],
          queryFn: async () => {
            const res = await APIClient.voiceMailList(MessagesType.new)
            return res.data
          },
          cacheTime: 1000 * 60 * 10 // 10 minutes
        })
        await queryClient.prefetchQuery({
          queryKey: ['messages', MessagesType.inbox],
          queryFn: async () => {
            const res = await APIClient.voiceMailList(MessagesType.inbox)
            return res.data
          },
          cacheTime: 1000 * 60 * 10 // 10 minutes
        })
        await queryClient.prefetchQuery({
          queryKey: ['messages', MessagesType.saved],
          queryFn: async () => {
            const res = await APIClient.voiceMailList(MessagesType.saved)
            return res.data
          },
          cacheTime: 1000 * 60 * 10 // 10 minutes
        })
        await queryClient.prefetchQuery({
          queryKey: ['recentMeetings'],
          queryFn: async () => {
            const res = await APIClient.getRecentMeeting()
            return res.data
          },
          cacheTime: 1000 * 60 * 15 // 15 minutes
        })
        await queryClient.prefetchQuery({
          queryKey: ['conferences', user?.account_number],
          queryFn: async () => {
            const res = await APIClient.getConferences(user?.account_number)
            return res.data
          },
          cacheTime: 1000 * 60 * 15 // 15 minutes
        })
      } catch (e) {
        console.log('Error on prefetching', e)
        Sentry.captureException(e, {
          tags: {
            section: 'usePrefetchData'
          }
        })
      }
    })()
  }, [])
}
