import React, { CSSProperties } from 'react'
import styled from 'styled-components'

const StyledAvatar = styled.img`
  vertical-align: middle;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 3px;
  object-fit: cover;
  @media (max-width: 360px) {
    width: 40px;
    height: 40px;
  }
`

interface IProps {
  src: string
  alt: string
  name: string
  style?: CSSProperties
}

const Avatar = (props: IProps) => {
  return (
    <>
      <StyledAvatar src={props.src} alt={props.alt} style={props?.style || {}} />
    </>
  )
}

export default Avatar
