import React, { ReactNode } from 'react'
import styled, { CSSObject } from 'styled-components'
import { Loader } from '../../../components/Loader/Loader'
import { colors } from '../../../assets/colors'

const StyledButton = styled.button<{ small?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(224.26deg, #3fb5e9 -23.22%, #1d70b6 123.1%);
  color: ${colors.white};
  text-transform: capitalize;
  border: 0;
  font-size: ${(props) => (props.small ? '14px' : '0.875rem')};
  border-radius: 30px;
  padding: ${(props) => (props.small ? '9px 15px' : '15px 30px')};
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  &:hover {
    background: ${(props) =>
      props.disabled
        ? 'linear-gradient(224.26deg, #3FB5E9 -23.22%, #1D70B6 123.1%)'
        : 'linear-gradient(224.26deg, #39A8D7 -23.22%, #165F97 123.1%)'};
  }
  &:active {
    background: ${(props) =>
      props.disabled
        ? 'linear-gradient(224.26deg, #3FB5E9 -23.22%, #1D70B6 123.1%)'
        : 'linear-gradient(224.26deg, #3FB5E9 -23.22%, #1D70B6 123.1%)'};
    opacity: ${(props) => (props.disabled ? 0.5 : 0.8)};
  }
`

type ButtonProps = {
  children: ReactNode
  onClick?: (e: any) => void
  isLoading?: boolean
  type?: 'submit' | 'reset' | 'button'
  small?: boolean
  style?: CSSObject
  disabled?: boolean
}

const Button: React.FC<ButtonProps> = ({ isLoading = false, children, ...props }) => {
  return (
    <StyledButton onClick={props.onClick} disabled={isLoading} {...props}>
      {isLoading ? <Loader height={'20px'} width={'20px'} /> : children}
    </StyledButton>
  )
}

export default Button
