import React from 'react'

interface EnvelopeProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const Envelope = ({
  style = {},
  fill = '#000',
  width = '30px',
  className = '',
  viewBox = '0 0 30 30'
}: EnvelopeProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 6 5 z M 4.5585938 9 L 13.783203 17.515625 C 14.124203 17.830625 14.562 17.988281 15 17.988281 C 15.438 17.988281 15.875797 17.830625 16.216797 17.515625 L 25.441406 9 L 4.5585938 9 z M 3 10.283203 L 3 23.585938 L 9.9179688 16.669922 L 3 10.283203 z M 27 10.283203 L 20.082031 16.669922 L 27 23.585938 L 27 10.283203 z M 11.388672 18.027344 L 4.4140625 25 L 25.585938 25 L 18.613281 18.027344 L 17.574219 18.986328 C 16.864219 19.642328 15.912953 19.971422 15.001953 19.982422 C 14.090953 19.972422 13.137734 19.642328 12.427734 18.986328 L 11.388672 18.027344 z"
    />
  </svg>
)

export default Envelope
