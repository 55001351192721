import React, { useEffect, useRef } from 'react'
import APIClient from '../../apiClient'
import useGetAudioSrc from '../../hooks/useGetAudioSrc'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Howl } from 'howler'
import { shallow } from 'zustand/shallow'
import { useAudioMessageActions, useAudioMessageStore } from '../../store/audioMessage'
import { useAutoPlayActions, useAutoPlayStore } from '../../store/autoPlay'
import { showAlertModal } from '../../store/modals'
import { useUser } from '../../store/user'
import { useGetMessagesById } from '../../hooks/queries/useGetMessagesById'

const AudioProvider: React.FC = () => {
  const history = useHistory()

  const {
    setAudioMessageId,
    setAudioLoading,
    setAudioPlaying,
    setAudioCurrentTime,
    setAudioSeek,
    setAudioDuration,
    setAudioMessagesSrcList
  } = useAudioMessageActions()
  const { setAutoPlayVisible } = useAutoPlayActions()

  const user = useUser()

  const messageId = useAudioMessageStore((state) => state.messageId)
  const { data: message } = useGetMessagesById(messageId)

  const autoPlayEnabled = useAutoPlayStore((state) => state.enabled, shallow)
  const autoPlayNextUnreadMessageId = useAutoPlayStore((state) => state.nextUnreadMessageId, shallow)

  const audioSpeed = useAudioMessageStore((state) => state.speed, shallow)
  const playing = useAudioMessageStore((state) => state.playing, shallow)
  const seek = useAudioMessageStore((state) => state.seek, shallow)
  const loading = useAudioMessageStore((state) => state.loading, shallow)

  const src = useAudioMessageStore((state) => (messageId ? state.audioMessagesSrcList[messageId] : null), shallow)
  const messageAudioSrc = useGetAudioSrc(message, user, !!src)

  const howl: { current: null | Howl } = useRef(null)

  useEffect(() => {
    if (!howl.current) return
    howl.current.rate(audioSpeed)
  }, [audioSpeed])

  useEffect(() => {
    if (playing) setAudioMessageId('')
  }, [history.location])

  useEffect(() => {
    if (!messageAudioSrc) return

    setAudioMessagesSrcList({ [messageId]: messageAudioSrc })
  }, [messageAudioSrc])

  useEffect(() => {
    if (!messageId) return
    setAudioLoading(true)
  }, [messageId])

  useEffect(() => {
    Howler.autoSuspend = false
  }, [])

  useEffect(() => {
    if (!src) return

    async function updateMessageAsPlayed(messageId: string) {
      try {
        if (!message || messageId !== message?.uniqueId) return

        if (message?.new || message?.urgent) {
          await APIClient.markMessagesItemStatus({
            id: messageId,
            status: 'played'
          })
        }
      } catch (e) {
        console.error('Error on update message as played: ', e)
      }
    }

    function onEnd() {
      setAudioPlaying(false)
      if (!autoPlayEnabled || !autoPlayNextUnreadMessageId) return
      // show autoplay container with counter
      setAutoPlayVisible(true)
    }

    function onStop() {
      setAudioPlaying(false)
    }

    function onPause() {
      setAudioPlaying(false)
    }

    function destroyHowler() {
      if (howl.current) {
        howl.current.off() // Remove all event listeners
        howl.current.stop() // Stop playback
        howl.current.unload() // Remove sound from pool
        howl.current = null // Destroy it
      }
    }

    function step() {
      // Get the Howl we want to manipulate.
      const audio = howl.current
      if (!audio) return
      // Determine our current seek position.
      const currentTime = audio.seek() || 0
      setAudioCurrentTime(messageId, +currentTime)
      // If the sound is still playing, continue stepping.
      if (audio.playing()) {
        requestAnimationFrame(step)
      }
    }

    async function onPlaying() {
      await updateMessageAsPlayed(messageId)
      if (!playing) {
        setAudioPlaying(true)
      }
      step()
    }

    async function onLoad() {
      setAudioLoading(false)
      if (howl.current) setAudioDuration(howl.current.duration())
    }

    function onLoadError(id: any, errorMessage: any) {
      if (process.env.REACT_APP_ENV) Sentry.captureMessage(`Error on message load. Error message: ${errorMessage}`)
      setAudioLoading(false)
      setAudioMessageId('')
      showAlertModal(`Error on message load. Error message: ${errorMessage}`)
    }

    function onPlayError(id: any, errorMessage: any) {
      if (process.env.REACT_APP_ENV) Sentry.captureMessage(`Error on message play. Error message: ${errorMessage}`)
      setAudioLoading(false)
      setAudioMessageId('')
      showAlertModal(`Error on message play. Error message: ${errorMessage}`)
    }

    destroyHowler()

    // Set new audio howl
    howl.current = new Howl({
      preload: true,
      html5: true,
      src: [src],
      format: ['mp3'],
      rate: audioSpeed
    })

    howl.current.on('end', onEnd)
    howl.current.on('stop', onStop)
    howl.current.on('pause', onPause)
    howl.current.on('play', onPlaying)
    howl.current.on('seek', step)
    howl.current.on('load', onLoad)
    howl.current.on('loaderror', onLoadError)
    howl.current.on('playerror', onPlayError)

    return () => {
      if (howl.current) destroyHowler()
    }
  }, [howl, src, messageId])

  // play or pause when audio.playing in redux will change
  useEffect(() => {
    if (!howl.current || loading) return

    if (playing && !howl.current.playing()) {
      howl.current.play()
    }

    if (!playing && howl.current.playing()) {
      howl.current.pause()
    }
  }, [playing, howl, loading])

  useEffect(() => {
    if (!howl.current || !seek) return
    howl.current.seek(seek)
    setAudioSeek(0)
  }, [seek])

  return <div id={'AudioProvider'} />
}

export default AudioProvider
