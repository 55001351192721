import React from 'react'

const PencilIcon = () => (
  <div style={{ marginRight: '10px' }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M4.27614 10.5933L11.0375 3.83183L10.0947 2.88902L3.33333 9.65045V10.5933H4.27614ZM4.82843 11.9266H2V9.09819L9.62333 1.47481C9.88373 1.21445 10.3058 1.21445 10.5661 1.47481L12.4518 3.36042C12.7121 3.62077 12.7121 4.04288 12.4518 4.30323L4.82843 11.9266ZM2 13.2599H14V14.5933H2V13.2599Z"
        fill="#1D70B6"
      />
    </svg>
  </div>
)

export default PencilIcon
