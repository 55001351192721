import React, { ChangeEvent, FC, MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../../assets/colors'
import Participants from '../../../components/Icon/Participants'
import SelectableContact from './components/SelectableContact'
import RemovableContact from './components/RemovableContact'

type ConferenceParticipantsProps = {
  selectParticipant: (event: MouseEvent<HTMLElement>) => void
  removeParticipant: (event: MouseEvent<HTMLElement>) => void
  contactsList: any[]
  selectedParticipantsIds: any[]
  selectedParticipants: any[]
}

const Container = styled.div`
  padding: 15px;
  width: 100%;
  min-width: 310px;
`

const ParticipantsLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.mediumGrey};
  margin-left: 10px;
  margin-bottom: 5px;
`

const ParticipantSearchInput = styled.input`
  width: 100%;
  background: ${colors.silver};
  border-radius: 10px;
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 12px 15px;
`

const SelectBoxContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 8px 20px rgba(154, 169, 188, 0.1);
  border-radius: 10px;
  overflow: hidden;
`

const SelectedBoxContainer = styled.div`
  width: 100%;

  height: calc(100vh - 310px);
  overflow-y: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const SelectBoxListContainer = styled.div`
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
`

const ConferenceParticipants: FC<ConferenceParticipantsProps> = ({
  selectParticipant,
  removeParticipant,
  contactsList,
  selectedParticipantsIds,
  selectedParticipants
}) => {
  const participantsSearchInputRef = useRef<HTMLInputElement>(null)
  const participantsListRef = useRef<HTMLInputElement>(null)
  const [isContactsListVisible, setContactsListVisible] = useState<boolean>(false)

  const [filterPhrase, setFilterPhrase] = useState('')

  const handleClickOutsideParticipantsList = (event: any) => {
    if (
      participantsListRef.current &&
      participantsSearchInputRef.current &&
      !(
        participantsListRef.current?.contains(event.target) ||
        participantsSearchInputRef.current?.contains(event.target)
      )
    ) {
      setContactsListVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideParticipantsList, true)
    return () => {
      document.removeEventListener('click', handleClickOutsideParticipantsList, true)
    }
  }, [])

  const handeInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterPhrase(event.target.value)
  }

  const filteredContacts = useMemo(() => {
    if (!filterPhrase.length) return contactsList

    return contactsList.filter((contact) => {
      return (
        (contact.first_name && contact.first_name.toLowerCase().includes(filterPhrase.toLowerCase())) ||
        (contact.last_name && contact.last_name.toLowerCase().includes(filterPhrase.toLowerCase())) ||
        (contact.email && contact.email.toLowerCase().includes(filterPhrase.toLowerCase())) ||
        (contact.email2 && contact.email2.toLowerCase().includes(filterPhrase.toLowerCase()))
      )
    })
  }, [contactsList, filterPhrase])

  useEffect(() => {
    if (!participantsSearchInputRef.current) return
    function toggleContactsListVisibility() {
      setContactsListVisible(true)
    }
    participantsSearchInputRef.current.addEventListener('focus', toggleContactsListVisibility)
    return () => {
      participantsSearchInputRef?.current?.removeEventListener('focus', toggleContactsListVisibility)
    }
  }, [participantsSearchInputRef])

  const onSelectParticipantClick = (event: MouseEvent<HTMLElement>) => {
    setContactsListVisible(false)
    selectParticipant(event)
    setFilterPhrase('')
  }

  return (
    <Container>
      <div style={{ display: 'flex' }}>
        <Participants />
        <ParticipantsLabel>Participants</ParticipantsLabel>
      </div>

      <div style={{ position: 'relative', height: '100%' }}>
        <ParticipantSearchInput
          value={filterPhrase}
          onChange={handeInputOnChange}
          ref={participantsSearchInputRef}
          placeholder={'Add participants'}
        />
        {!!selectedParticipants.length && (
          <SelectedBoxContainer>
            {selectedParticipants.map((participant) => (
              <RemovableContact
                key={participant.id}
                onClick={removeParticipant}
                id={participant.id}
                firstname={participant.first_name}
                lastname={participant.last_name}
                photoUrl={participant.photo_url}
                kateNumber={participant.kate_number}
              />
            ))}
          </SelectedBoxContainer>
        )}
        {isContactsListVisible && (
          <SelectBoxContainer ref={participantsListRef}>
            <SelectBoxListContainer>
              {filteredContacts.map((contact) => (
                <SelectableContact
                  onClick={onSelectParticipantClick}
                  key={contact.id}
                  id={contact.id}
                  firstname={contact.first_name}
                  lastname={contact.last_name}
                  photoUrl={contact.photo_url}
                  kateNumber={contact.kate_number}
                  selected={selectedParticipantsIds.includes(contact.id)}
                />
              ))}
            </SelectBoxListContainer>
          </SelectBoxContainer>
        )}
      </div>
    </Container>
  )
}

export default ConferenceParticipants
