import React from 'react'
import Add from './Add'
import Bin from './Bin'
import Bookmark from './Bookmark'
import Camera from './Camera'
import Cancel from './Cancel'
import Checkmark from './Checkmark'
import ChevronRight from './ChevronRight'
import Dialog from './Dialog'
import Envelope from './Envelope'
import Important from './Important'
import ImportantFilled from './ImportantFilled'
import Inbox from './Inbox'
import Info from './Info'
import Letter from './Letter'
import Microphone from './Microphone'
import Pause from './Pause'
import PauseButton from './PauseButton'
import Phone from './Phone'
import Play from './Play'
import Plus from './Plus'
import Remove from './Remove'
import Save from './Save'
import Search from './Search'
import Spinner from './Spinner'
import UserAccount from './UserAccount'
import VoiceMail from './VoiceMail'
import Settings from './Settings'
import SkipToStart from './SkipToStart'
import Reply5 from './Reply5'
import Ellipsis from './Ellipsis'
import Download from './Download'
import ForwardArrow from './ForwardArrow'
import ReplyArrow from './ReplyArrow'
import Car from './Car'
import Delete from './Delete'
import PlayCircle from './PlayCircle'
import PauseCircle from './PauseCircle'
import SkipToNext from './SkipToNext'
import MovieCamera from './MovieCamera'
import CopyToClipboard from './CopyToClipboard'
import Mic from './Mic'
import MicOff from './MicOff'
import Videocam from './Videocam'
import VideocamOff from './VideocamOff'
import CallEnd from './CallEnd'
import ScreenShare from './ScreenShare'
import ScreenShareOff from './ScreenShareOff'
import ArrowLeft from './ArrowLeft'
import ArrowRight from './ArrowRight'
import Description from './Description'
import Copy from './Copy'
import Mute from './Mute'
import Unmute from './Unmute'
import CameraOn from './CameraOn'
import CameraOff from './CameraOff'
import Confidential from './Confidential'
import ConfidentialFilled from './ConfidentialFilled'
import Menu from './Menu'
import ChatMessage from './ChatMessage'
import Exit from './Exit'

type IconProps = {
  name: string
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const Icon = ({ name, ...rest }: IconProps) => {
  switch (name) {
    case 'dialog':
      return <Dialog {...rest} />
    case 'spinner':
      return <Spinner {...rest} />
    case 'info':
      return <Info {...rest} />
    case 'envelope':
      return <Envelope {...rest} />
    case 'bin':
      return <Bin {...rest} />
    case 'remove':
      return <Remove {...rest} />
    case 'inbox':
      return <Inbox {...rest} />
    case 'save':
      return <Save {...rest} />
    case 'play':
      return <Play {...rest} />
    case 'pause':
      return <Pause {...rest} />
    case 'pauseCircle':
      return <PauseCircle {...rest} />
    case 'pauseButton':
      return <PauseButton {...rest} />
    case 'bookmark':
      return <Bookmark {...rest} />
    case 'microphone':
      return <Microphone {...rest} />
    case 'voiceMail':
      return <VoiceMail {...rest} />
    case 'userAccount':
      return <UserAccount {...rest} />
    case 'important':
      return <Important {...rest} />
    case 'importantFilled':
      return <ImportantFilled {...rest} />
    case 'add':
      return <Add {...rest} />
    case 'chevronRight':
      return <ChevronRight {...rest} />
    case 'search':
      return <Search {...rest} />
    case 'phone':
      return <Phone {...rest} />
    case 'menu':
      return <Menu {...rest} />
    case 'plus':
      return <Plus {...rest} />
    case 'letter':
      return <Letter {...rest} />
    case 'cancel':
      return <Cancel {...rest} />
    case 'checkmark':
      return <Checkmark {...rest} />
    case 'settings':
      return <Settings {...rest} />
    case 'skipToStart':
      return <SkipToStart {...rest} />
    case 'skipToNext':
      return <SkipToNext {...rest} />
    case 'reply5':
      return <Reply5 {...rest} />
    case 'ellipsis':
      return <Ellipsis {...rest} />
    case 'download':
      return <Download {...rest} />
    case 'forwardArrow':
      return <ForwardArrow {...rest} />
    case 'replyArrow':
      return <ReplyArrow {...rest} />
    case 'car':
      return <Car {...rest} />
    case 'camera':
      return <Camera {...rest} />
    case 'delete':
      return <Delete {...rest} />
    case 'playCircle':
      return <PlayCircle {...rest} />
    case 'movieCamera':
      return <MovieCamera {...rest} />
    case 'copyToClipboard':
      return <CopyToClipboard {...rest} />
    case 'mic':
      return <Mic {...rest} />
    case 'mic_off':
      return <MicOff {...rest} />
    case 'videocam':
      return <Videocam {...rest} />
    case 'videocam_off':
      return <VideocamOff {...rest} />
    case 'screen_share':
      return <ScreenShare {...rest} />
    case 'screen_share_off':
      return <ScreenShareOff {...rest} />
    case 'call_end':
      return <CallEnd {...rest} />
    case 'arrow_left':
      return <ArrowLeft {...rest} />
    case 'arrow_right':
      return <ArrowRight {...rest} />
    case 'description':
      return <Description {...rest} />
    case 'copy':
      return <Copy {...rest} />
    case 'mute':
      return <Mute {...rest} />
    case 'unmute':
      return <Unmute {...rest} />
    case 'camera_on':
      return <CameraOn {...rest} />
    case 'camera_off':
      return <CameraOff {...rest} />
    case 'confidential':
      return <Confidential {...rest} />
    case 'confidentialFilled':
      return <ConfidentialFilled {...rest} />
    case 'chatMessage':
      return <ChatMessage {...rest} />
    case 'exit':
      return <Exit {...rest} />
    default:
      return <div />
  }
}

export default Icon
