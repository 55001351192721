import APIClient from '../apiClient'
import { accountLockService, LockReason } from '../services/AccountLockService'
import * as Sentry from '@sentry/react'

interface ILoginUser {
  username: string
  password: string
}

export const loginUser = async ({ username, password }: ILoginUser) => {
  try {
    const loginUserResponse = await APIClient.login({
      identity: username,
      password
    })

    const loginResponseData = loginUserResponse?.data

    if (loginUserResponse.status !== 200) return { success: false, errorMessage: `Something's wrong. Try again later.` }
    localStorage.setItem('refreshToken', loginUserResponse?.data?.refresh_token)
    localStorage.setItem('awsRefreshToken', loginUserResponse?.data?.aws?.tokens?.refresh)
    localStorage.setItem('awsAccessToken', loginUserResponse?.data?.aws?.tokens?.access)
    localStorage.setItem('awsAccessTokenId', loginUserResponse?.data?.aws?.tokens?.id)
    localStorage.setItem('chatUrl', loginUserResponse?.data?.aws?.urls?.chat)
    localStorage.setItem('chatUrlWebSocket', loginUserResponse?.data?.aws?.urls?.chat_websocket)

    if (loginResponseData.locked) {
      if (loginResponseData?.reason.includes('billing')) {
        accountLockService.setLockReason(LockReason.BILLING_LOCK)
      }
      return { success: false, locked: true }
    }

    return { success: true }
  } catch (e: any) {
    if (e.response && e.response.status && e.response.status === 401) {
      if (e.response.data.locked) {
        const errorMessage = 'Account is locked'
        return { success: false, errorMessage, accountLockReason: LockReason.GENERAL }
      }
      const errorMessage = 'Wrong email or password'
      return { success: false, errorMessage }
    }

    Sentry.captureException(e)

    return { success: false, errorMessage: e.message }
  }
}
