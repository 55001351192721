import React from 'react'
import { StyledPaymentOptions } from '../Styled'
import CardsLogo from '../../../Shared/Common/assets/CardsLogo.png'
import useIsMobile from '../../../Shared/Common/Hooks/useIsMobile'

const EmptyCardView = () => {
  const isMobile = useIsMobile()

  return (
    <StyledPaymentOptions.EmptyCardWrapper
      style={{
        marginTop: isMobile ? '150px' : '30vh'
      }}
    >
      <img src={CardsLogo} alt="Visa, MasterCard" width={73} height={14} />
      <p style={{ marginTop: '12px' }}>You didn&apos;t add any payment method yet</p>
      <StyledPaymentOptions.StyledLinkWithIcon to="/billing?tab=payment-options&action=add">
        <span style={{ fontSize: '20px', marginRight: '5px' }}>+</span> Add Payment Method
      </StyledPaymentOptions.StyledLinkWithIcon>
    </StyledPaymentOptions.EmptyCardWrapper>
  )
}

export default EmptyCardView
