import React, { FC } from 'react'
import format from 'date-fns/format'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import VideoConferencingHeader from '../../components/Header'
import ReportsRoom from '../../components/blocks/ReportsRoom'
import ColumnView from '../../components/blocks/ColumnView'
import { LeftContainer } from '../../components/blocks/ColumnView/components/LeftContainer'
import ReportRoom from '../../components/blocks/ReportRoom'
import { Loader } from '../../../components/Loader/Loader'
import { colors } from '../../assets/colors'
import { useParams } from 'react-router'
import { useGetReports } from '../../../hooks/queries/useGetReports'
import { useGetMonthReport } from '../../../hooks/queries/useGetMonthReport'

const StyledLeftContainer = styled(LeftContainer)`
  align-items: flex-start;
`

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const MonthReport: FC = () => {
  const { from } = useParams<{ from?: string }>()
  const { data: monthReport, isLoading: isLoadingReport } = useGetMonthReport()
  const { data, isLoading: isLoadingReports } = useGetReports()

  const isLoading = isLoadingReport || isLoadingReports

  const year = parseInt(from?.split('-')[0])
  const month = from?.split('-')[1]

  const formatMonth = (month: string) => {
    if (month[0] === '0') {
      return parseInt(month[1])
    }
    return parseInt(month)
  }

  const currentYear = data?.find((report: any) => report.year === year)
  const currentMonth = currentYear?.months?.find((report: any) => report.month === formatMonth(month))
  const currentMonthName = monthNames[formatMonth(month) - 1]

  return (
    <ColumnView>
      <VideoConferencingHeader>
        <VideoConferencingHeader.Element>{format(new Date(), 'h:mm aaaa')}</VideoConferencingHeader.Element>
        <VideoConferencingHeader.Element>{format(new Date(), 'EEE, d MMMM')}</VideoConferencingHeader.Element>
        <VideoConferencingHeader.Element>
          <Link to={'/options'}>
            <VideoConferencingHeader.ProfileLink />
          </Link>
        </VideoConferencingHeader.Element>
      </VideoConferencingHeader>
      <div style={{ marginLeft: 20 }}>
        <ReportsRoom.BackLink />
      </div>
      <ColumnView.Content>
        {isLoading ? (
          <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}
          >
            <Loader width={'20px'} height={'20px'} fill={colors.darkGrey} style={{ marginTop: 50 }} />
          </div>
        ) : (
          <>
            <StyledLeftContainer>
              <div style={{ marginBottom: 15 }}>
                <ReportRoom.MonthText>{currentMonthName}</ReportRoom.MonthText>
                {currentMonth?.dollarAmount ? (
                  <ReportRoom.PriceText>{currentMonth?.dollarAmount}</ReportRoom.PriceText>
                ) : null}
              </div>
            </StyledLeftContainer>
            <ColumnView.RightContainer>
              <>
                {monthReport?.map((meeting) => {
                  return (
                    <ReportRoom.MeetingCard
                      key={meeting?.meetingId}
                      title={meeting?.title}
                      date={''}
                      timeFrom={meeting?.meetingStart}
                      timeTo={meeting?.meetingEnd}
                      time={meeting?.totalMinutes}
                      price={meeting?.dollarAmount}
                      attendees={meeting?.participants}
                    />
                  )
                })}
              </>
            </ColumnView.RightContainer>
          </>
        )}
      </ColumnView.Content>
    </ColumnView>
  )
}

export default MonthReport
