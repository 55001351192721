import React from 'react'
import styled from 'styled-components'
import VideoRoom from '../index'
import IcVideo from '../../../../../assets/images/ic_video.png'
import Blur from '../../../../../assets/icons/blur.svg'
import IcNoVideo from '../../../../../assets/images/ic_no_video.png'
import IcMute from '../../../../../assets/images/ic_mute.png'
import IcUnmute from '../../../../../assets/images/ic_unmute.png'
import NoPhones from '../../../../../components/Icon/NoPhones'
import useWindowSize from '../../../../hooks/useWindowSize'
import { useVideoStore } from '../../../../store/video'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px;
  height: 5%;
  background-color: #171717;
  align-self: flex-end;
  align-items: center;
  justify-content: space-between;
  z-index: 11;
`

const CenteredIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 599px) {
    height: 40px;
    padding-top: 8px;
  }
`

const StyledText = styled.a`
  font-size: 15px;
  color: white;
  @media (max-width: 599px) {
    font-size: 11px;
  }
`

interface IProps {
  url: string
  participants?: number
  quitCall: () => void
  toggleVideo: () => void
  toggleAudio: () => void
  toggleScreenshare: () => void
  video: boolean
  audio: boolean
  screenshare: boolean
  toggleBlurredBackground: () => void
  blurredBackground: boolean
}

const MainControls = (props: IProps) => {
  const { windowSize } = useWindowSize()
  const videoData = useVideoStore((state) => state.videoData)
  const publisherState = useVideoStore((state) => state.publisherState)

  const roomUrl = `${window.location.origin}/conference/${videoData?.meetingId}`

  return (
    <StyledContainer>
      <div>
        <StyledText>
          {props?.participants} {props?.participants > 1 ? 'participants' : 'participant'}
        </StyledText>
      </div>
      <CenteredIconsContainer>
        <VideoRoom.FooterIcon
          selected={props.audio}
          selectedText={'Mute'}
          notSelectedText={'Unmute'}
          selectedSrc={IcUnmute}
          notSelectedSrc={IcMute}
          onClick={props.toggleAudio}
          width={50}
          showText={windowSize.width >= 600}
        />
        <VideoRoom.FooterIcon
          selected={props.video}
          selectedText={'Camera On'}
          notSelectedText={'Camera Off'}
          selectedSrc={IcVideo}
          notSelectedSrc={IcNoVideo}
          onClick={props.toggleVideo}
          showText={windowSize.width >= 600}
        />
        <VideoRoom.FooterIcon
          selected={props.blurredBackground}
          disabled={false}
          selectedText={'Blur on'}
          notSelectedText={'Blur off'}
          selectedSrc={Blur}
          notSelectedSrc={Blur}
          onClick={() => {
            props.toggleBlurredBackground()
          }}
          showText={windowSize.width >= 600}
        />
        <VideoRoom.ShareScreenIcon
          selected={props.screenshare}
          onClick={props.toggleScreenshare}
          showText={windowSize.width >= 600}
        />
        <VideoRoom.CopyInviteLink url={roomUrl} showText={windowSize.width >= 600} />
        <VideoRoom.More />
      </CenteredIconsContainer>
      <VideoRoom.RoundedIcon onClick={props.quitCall}>
        <NoPhones />
      </VideoRoom.RoundedIcon>
    </StyledContainer>
  )
}

export default MainControls
