import React from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import Logo from './components/Icon/Logo'

const ErrorFallback = ({ error, resetError, user }) => {
  return (
    <>
      <Container style={{ padding: '2rem', marginTop: '2rem' }}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" spacing={2}>
          <Logo width={'250'} height={'100'} />

          <Box textAlign="center" p={3}>
            <Typography variant="h5" component="h2" gutterBottom>
              Oops, something went wrong! 🚧
            </Typography>
            <Typography variant="body1" gutterBottom>
              We&apos;re truly sorry, but it seems like we&apos;ve hit a bump on our journey. Our team has been notified
              and is working to get things back on track.
            </Typography>
            <Box marginTop={'30px'}>
              <Typography variant="body1" gutterBottom style={{ fontWeight: 600 }}>
                In the meantime:
              </Typography>
              <Box width={'100%'} textAlign={'left'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <ul>
                  <Typography component="li" variant="body2">
                    You might want to try refreshing the page.
                  </Typography>
                  <Typography component="li" variant="body2">
                    If the issue persists, please contact our support team or check back later.
                  </Typography>
                </ul>
              </Box>
            </Box>

            <Typography marginTop={'30px'} variant="body1">
              We appreciate your patience and understanding!
            </Typography>
          </Box>
          <Button variant="contained" color="primary" onClick={resetError}>
            Try Again
          </Button>

          <Box marginTop={'30px'}>
            <Typography variant="body1" gutterBottom>
              AccountId: {user.account_number} {' || '} {error.toString()}
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default ErrorFallback
