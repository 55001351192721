import React, { ReactNode } from 'react'
import styled from 'styled-components'
import Button from './Button'
import AudioSpeedButton from './AudioSpeedButton'

const Container = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface IProps {
  children: ReactNode
}

const AudioButtons = ({ children }: IProps) => {
  return <Container>{children}</Container>
}

AudioButtons.Button = Button
AudioButtons.SpeedButton = AudioSpeedButton

export default AudioButtons
