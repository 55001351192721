import styled from 'styled-components'

const RowContent = styled.div`
  display: flex;
  align-items: center;
  max-height: 100%;
  width: 90%;
  margin-right: 10%;
  cursor: pointer;
  & div {
    min-height: 0;
    box-sizing: border-box;
    padding: 0 0 0 5px;
  }
`

export default RowContent
