import { useQuery } from '@tanstack/react-query'
import APIClient from '../../apiClient'

export const useGetRecentMeetings = () => {
  return useQuery<unknown, unknown, any>({
    queryKey: ['recentMeetings'],
    queryFn: async () => {
      const res = await APIClient.getRecentMeeting()
      return res.data
    },
    cacheTime: 1000 * 60 * 15 // 15 minutes
  })
}
