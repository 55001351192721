import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useVideoActions, useVideoStore } from '../../../store/video'
import APIClient, { BillableEventData } from '../../../../apiClient'
import { useAuth, useAvatar, useUser } from '../../../../store/user'
import { useParams } from 'react-router-dom'
import OT, { Session, Stream } from '@opentok/client'
import { showAlertModal } from '../../../../store/modals'
import { useBillingEvents } from '../hooks/useBillingEvents'
import { createBillableEvent } from '../events'
import { conferenceEventsLogger, guestConferenceEventsLogger } from '../../../logger/ConferenceEventsLogger'
import { useHistory } from 'react-router'
import * as Sentry from '@sentry/react'

const SessionContext = createContext<{
  session: Session
  streams: any[]
  sharedStream: any
  publisherDiv: any
  isLoading: boolean
  setIsLoading: any
}>({
  session: null,
  streams: [],
  sharedStream: null,
  publisherDiv: null,
  isLoading: false,
  setIsLoading: () => {}
})

const styles = {
  insertMode: 'append',
  width: '100%',
  height: '100%',
  style: {
    archiveStatusDisplayMode: 'auto',
    nameDisplayMode: 'off',
    buttonDisplayMode: 'off',
    audioLevelDisplayMode: 'off',
    videoDisabledDisplayMode: 'off'
  }
}

export const SessionProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [session, setSession] = useState(null)
  const [streams, setStreams] = useState([])
  const [sharedStream, setSharedStream] = useState<Stream | null>(null)
  const videoData = useVideoStore((state) => state.videoData)
  const authenticated = useAuth()
  const user = useUser()
  const userAvatar = useAvatar()
  const history = useHistory()
  const { id: meetingId } = useParams<{ id: string }>()
  const publisherDiv = useRef()
  const { setPublisherState } = useVideoActions()
  const { onConnectedEvent, onDestroyEvent } = useBillingEvents()

  let retryCount = useRef(0)
  const maxRetries = 3

  let sentSignal = null

  const getUserInfo = () => {
    if (authenticated) {
      return user?.first_name + ' ' + user?.last_name
    } else {
      const firstName = localStorage.getItem('guestFirstName') ? localStorage.getItem('guestFirstName') : ''
      const lastName = localStorage.getItem('guestLastName') ? localStorage.getItem('guestLastName') : ''
      return firstName + ' ' + lastName + ','
    }
  }

  const retryPublish = ({ session, token }): OT.Publisher => {
    if (retryCount.current <= maxRetries) {
      let publisher: OT.Publisher = null
      console.log(`Retry publishing (${retryCount.current}/${maxRetries})`)

      // @ts-ignore
      publisher = OT.initPublisher(
        publisherDiv.current,
        // @ts-ignore
        { ...styles, name: `${getUserInfo()}#img#${userAvatar}` },
        (error) => {
          if (error) {
            console.log('Publisher Error init', error)
            showAlertModal(error.message)
            Sentry.captureException(error, {
              tags: {
                context: 'Publisher Error init'
              }
            })
          } else {
            setIsLoading(false)
            console.log('Publisher initialized')
            // setPublisherState(newPublisher)
          }
        }
      )

      session.publish(publisher, (error) => {
        if (error) {
          if (error.code === 1500) {
            retryCount.current++
            retryPublish({ session, token })
            Sentry.captureException(error, {
              tags: {
                context: 'Retry publishing - code 1500'
              }
            })
          } else {
            Sentry.captureException(error, {
              tags: {
                context: 'Retry publishing'
              }
            })
            showAlertModal(error.message)
          }
        } else {
          setPublisherState(publisher)
          console.log('Publishing retry successful')
          setIsLoading(false)
        }
      })
      return publisher
    } else {
      showAlertModal('Failed to connect after retries. Please restart your browser and try again.')
      setIsLoading(false)
      setTimeout(() => {
        history.push('/')
      }, 2000)
    }
  }

  useEffect(() => {
    const vonageSession = OT.initSession(process.env.REACT_APP_VONAGE_API_KEY, videoData?.sessionId)

    // @ts-ignore
    let publisher = OT.initPublisher(
      publisherDiv.current,
      // @ts-ignore
      { ...styles, name: `${getUserInfo()}#img#${userAvatar}` },
      (error) => {
        if (error) {
          console.log('Publisher Error init', error)
          showAlertModal(error.message)
          Sentry.captureException(error, {
            tags: {
              context: 'Publisher Error init'
            }
          })
        } else {
          console.log('Publisher initialized')
          setPublisherState(publisher)
        }
      }
    )

    vonageSession.connect(videoData?.token, (err: any) => {
      if (err) {
        Sentry.captureException(err, {
          tags: {
            context: 'Session Error'
          }
        })
        console.log('Session Error', err)
      } else {
        console.log('Session initialized')
        setSession(vonageSession)
        vonageSession.publish(publisher, (error) => {
          if (error) {
            console.log('Publisher Error', error)
            Sentry.captureException(err, {
              tags: {
                context: 'Publisher Session Connect Error'
              }
            })
            showAlertModal('Problem with the connection. Reconnecting...')

            // @ts-ignore
            if (error.code === 1500) {
              session.unpublish(publisher)
              setPublisherState(null)
              // Try to publish again
              publisher = retryPublish({ session: vonageSession, token: videoData?.token })
            }
          } else {
            console.log('Publisher published')
            publisher.publishAudio(useVideoStore.getState().audio)
            publisher.publishVideo(useVideoStore.getState().video)
            setPublisherState(publisher)
            setIsLoading(false)

            if (authenticated) {
              APIClient.canGetPoll(meetingId, 'joined')
            } else {
              APIClient.canGetPollGuest(meetingId, 'joined')
            }
          }
        })
      }
    })

    publisher.on('streamCreated', (event) => {
      const billableEvent: BillableEventData = createBillableEvent(event, 'connectionCreated', true)
      localStorage.setItem('bevent', JSON.stringify(billableEvent))
      localStorage.setItem('created_time', billableEvent.timestampStart.toString())
      if (authenticated) {
        conferenceEventsLogger.enqueueEvent(billableEvent)
      } else {
        guestConferenceEventsLogger.enqueueEvent(billableEvent)
      }
      onConnectedEvent()
    })

    publisher.on('streamDestroyed', (event) => {
      onDestroyEvent(event)
      console.log('publisher streamDestroyed', event)
      setPublisherState(null)

      if (authenticated) {
        APIClient.canGetPoll(meetingId, 'left')
      } else {
        APIClient.canGetPollGuest(meetingId, 'left')
      }
    })

    vonageSession.on('signal', (event) => {
      if (event?.data && sentSignal !== event?.data) {
        showAlertModal(event?.data)
        sentSignal = event?.data
      }
    })

    vonageSession.on('streamCreated', (event) => {
      if (event.stream.videoType === 'screen') {
        setSharedStream(event.stream)
      }
      if (streams?.find((stream) => stream.id === event.stream.streamId)) {
        setStreams((prevStreams) => {
          const index = prevStreams.findIndex((stream) => stream.streamId === event.stream.streamId)
          const newStreams = [...prevStreams]
          newStreams[index] = event.stream
          return newStreams
        })
      }

      setStreams((prevStreams) => [...prevStreams, event.stream])
    })

    OT.on('exception', (event) => {
      Sentry.captureException(event, {
        tags: {
          context: 'Session Exception'
        }
      })
    })

    vonageSession.on('streamDestroyed', (event) => {
      if (event.stream.videoType === 'screen') {
        setSharedStream(null)
      }
      setStreams((prevState) => prevState?.filter((stream) => stream.id !== event.stream.streamId))
    })

    vonageSession.on('streamPropertyChanged', (event) => {
      // Change stream in array when modified
      setStreams((prevStreams) => {
        const index = prevStreams.findIndex((stream) => stream.streamId === event.stream.streamId)
        if (index < 0) return prevStreams
        const newStreams = [...prevStreams]
        newStreams[index] = event.stream
        return newStreams
      })
    })

    return () => {
      if (vonageSession) {
        vonageSession.unpublish(publisher)
        vonageSession.disconnect()
        vonageSession.off('streamCreated')
        vonageSession.off('streamDestroyed')
        vonageSession.off('streamPropertyChanged')
        vonageSession.off('signal')
        vonageSession.off('exception')

        publisher.off('streamCreated')
        publisher.off('streamDestroyed')
        publisher.off('streamPropertyChanged')

        console.log('SESSION DISCONNECTED', vonageSession)
        if (authenticated) {
          APIClient.canGetPoll(meetingId, 'left')
        } else {
          APIClient.canGetPollGuest(meetingId, 'left')
        }
      }
    }
  }, [])

  useEffect(() => {
    if (streams && session) {
      const sharedStream = streams.find((stream) => stream.videoType === 'screen')
      setSharedStream(sharedStream)
    }
  }, [streams, session])

  const streamsFiltered = streams?.filter((v, i, a) => {
    const isFirstOccurrence = a.findIndex((t) => t.id === v.streamId) === i
    const isNotScreenVideo = v.videoType !== 'screen'
    return isFirstOccurrence && isNotScreenVideo
  })

  // eslint-disable-next-line react/react-in-jsx-scope
  return (
    <SessionContext.Provider
      value={{ session, streams: streamsFiltered, sharedStream, publisherDiv, isLoading, setIsLoading }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export const useSession = () => {
  return useContext(SessionContext)
}
