import styled from 'styled-components'
import { colors } from '../../../../assets/colors'
import React, { ReactNode } from 'react'
import Button from './Button'

const Container = styled.div<{ isConfidential: boolean }>`
  width: 100%;
  height: 50px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ isConfidential }) => (isConfidential ? 'space-evenly' : 'space-between')};
  padding: 10px;
  overflow: hidden;
  margin-bottom: 20px;
`
interface IProps {
  children?: ReactNode
  onClick?: () => void
  isConfidential?: boolean
  style?: React.CSSProperties
}

const ButtonsBar = ({ children, isConfidential, style = {} }: IProps) => {
  return (
    <Container style={style} isConfidential={isConfidential}>
      {children}
    </Container>
  )
}

ButtonsBar.Button = Button

export default ButtonsBar
