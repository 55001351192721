import React, { useState } from 'react'
import SwitchButton from '../../../../../../../components/Button/SwitchButton'
import { DebugInfoFormWrapper } from '../Styled'

const DebugInfoForm = () => {
  const [active, setActive] = useState(localStorage.getItem('debugActive') === 'true' || false)

  return (
    <DebugInfoFormWrapper>
      <p style={{ fontSize: '13px' }}>Send debug info enabled</p>
      <SwitchButton
        isActive={active}
        onClick={() => {
          localStorage.setItem('debugActive', active ? 'false' : 'true')
          setActive((prevState) => !prevState)
        }}
      />
    </DebugInfoFormWrapper>
  )
}

export default DebugInfoForm
