import React, { useEffect } from 'react'
import { Transition } from 'react-transition-group'
import Header from '../../../../components/Header'
import { InlineButton } from '../../../../components/Button/InlineButton'
import { colors } from '../../../../assets/colors'
import { NewMessagePlaybackBar } from '../../../../components/blocks/Message/NewMessagePlaybackBar'
import WaveformVisualizer from '../../../../components/WaveformVisualizer'
import RecordButton from '../../../../components/Button/RecordButton'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Modals from './Modals'
import { useRecording } from '../../../shared/hooks/useRecording'
import { useNewMessageActions, useNewMessageStore } from './store/NewMessageStore'
import { useForm } from './Form/useForm'
import { useRecipients } from '../../../shared/hooks/useRecipients'
import Form from './Form'
import { useAudioPlayingContext } from './provider/AudioPlayingProvider'
import SelectRecipients from './SelectRecipients'
import { useHistory } from 'react-router'
import { Recipient } from '../../../../types'
import { color } from '@storybook/addon-knobs'
import Spinner from '../../../../components/Icon/Spinner'

const Container = styled.div<{ state: string }>`
  transition: top 300ms ease-in;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  max-height: 100%;
  background: ${colors.silver};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  top: ${({ state }) => (state === 'entered' ? '0%' : '100%')};
`

const PlaybackBarContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: ${colors.silver};
  padding-bottom: 135px;
`

interface INewMessageLocation {
  newMessage?: any
  background?: any
  recipients?: { id: string; label: string }[]
}

// fallback for Safari's AudioContext reference
// @ts-ignore
window.AudioContext = window.AudioContext || window.webkitAudioContext

const NewMessageNew = () => {
  const history = useHistory()
  const location = useLocation<INewMessageLocation>()
  const isRecording = useNewMessageStore((state) => state.isRecording)
  const recipients = useNewMessageStore((state) => state.recipients)
  const isConfidential = useNewMessageStore((state) => state.isConfidential)
  const afterRecording = useNewMessageStore((state) => state.afterRecording)
  const imageFile = useNewMessageStore((state) => state.imageFile)
  const image = useNewMessageStore((state) => state.image)
  const selectRecipientsModal = useNewMessageStore((state) => state.modals.selectRecipientsModal)
  const { setCancelMessageModal, setDiscardAudioModal, setRecipients } = useNewMessageActions()

  const formik = useForm()
  const { formattedTimeLeft, stopRecording, startRecording } = useRecording()
  const recipientsActions = useRecipients()
  const { onNewSelectedRecipientList } = recipientsActions

  const {
    audio,
    playProgress,
    setClickedTime,
    playing,
    pauseAudio,
    playAudio,
    curTime,
    duration
  } = useAudioPlayingContext()

  const locationParams =
    location?.pathname && location?.search
      ? `${location?.pathname}${location?.search}`
      : location?.pathname
      ? location?.pathname
      : '/'

  const handleCancelSendingMessage = () => {
    if (recipients?.length || imageFile || formik.values?.note || formik?.values.recipients.length || image) {
      setCancelMessageModal(true)
      return
    }
    return history.push(locationParams, { newMessage: false, recipients: [] })
  }

  const confirmRemoveAudio = () => {
    if (playing) pauseAudio()
    setDiscardAudioModal(true)
  }

  useEffect(() => {
    // Set recipients when replying to a message
    if (location?.state?.recipients) {
      setRecipients(location?.state?.recipients)
    }
  }, [location?.state?.recipients])

  return (
    <>
      <Transition
        in={!!(location && location.state && location.state.newMessage)}
        timeout={550}
        unmountOnExit
        mountOnEnter
      >
        {(state) => (
          // state change: exited -> entering -> entered -> exiting -> exited
          <Container state={state}>
            <Header style={isConfidential ? { background: colors.mediumGrey2 } : null}>
              <Header.SideButtons>
                <InlineButton onClick={handleCancelSendingMessage}>Cancel</InlineButton>
              </Header.SideButtons>
              <Header.Title>
                <span>New Message</span>
              </Header.Title>
              <Header.EndButtons>
                {formik.isLoadingSend ? (
                  <Spinner style={{ width: 18, height: 18 }} />
                ) : (
                  <InlineButton style={{ fontWeight: 600 }} onClick={formik.handleSubmit}>
                    Send
                  </InlineButton>
                )}
              </Header.EndButtons>
            </Header>
            <Form recipientsActions={recipientsActions} formik={formik} />
            <PlaybackBarContainer>
              {audio && (
                <NewMessagePlaybackBar
                  curTime={curTime}
                  duration={duration}
                  playProgress={playProgress}
                  onTimeUpdate={(time) => {
                    setClickedTime(time)
                  }}
                />
              )}
              {isRecording && <WaveformVisualizer />}
            </PlaybackBarContainer>
            <RecordButton
              onRecord={startRecording}
              onRemove={confirmRemoveAudio}
              onStopRecording={stopRecording}
              onPause={pauseAudio}
              onPlay={playAudio}
              isRecording={isRecording}
              afterRecording={afterRecording}
              isPlaying={playing}
              timeLeft={formattedTimeLeft}
            />

            <Modals />
          </Container>
        )}
      </Transition>

      {/*Show the modal when a background page is set */}
      <Transition in={!!(location && location.state && location.state.background)} timeout={250}>
        <SelectRecipients state={selectRecipientsModal ? 'entered' : 'exited'} onChange={onNewSelectedRecipientList} />
      </Transition>
    </>
  )
}

export default NewMessageNew
