import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../../assets/colors'

const Container = styled.div`
  position: absolute;
  bottom: 3%;
  left: 3%;
  text-shadow: 0px 1px 8px rgba(23, 23, 23, 0.7);
  z-index: 2;
`

const NameText = styled.div`
  color: ${colors.white}
  font-size: calc(0.3vw + 0.5vh + 1vmin);
  font-weight: 600;
`

interface IProps {
  name: string
}

const NameContainer: React.FC<IProps> = (props: IProps) => {
  return (
    <Container>
      <NameText>{props?.name?.split(',')?.[0]}</NameText>
    </Container>
  )
}

export default NameContainer
