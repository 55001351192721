import styled from 'styled-components'
import React, { memo, ReactNode } from 'react'
import { colors } from '../../../assets/colors'

const StyledAudioLength = styled.div`
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${colors.mediumGrey};
`

interface IAudioLength {
  children: ReactNode
}

const AudioLength = ({ children, ...props }: IAudioLength) => {
  const convertMessageLength = (length: number) => {
    const minutes = Math.floor(length / 60)
    const seconds = length - minutes * 60

    return `${minutes ? minutes + 'm' : ''} ${seconds ? seconds + 's' : ''}`
  }

  if (typeof children !== 'number') {
    return <StyledAudioLength>{children}</StyledAudioLength>
  }
  return <StyledAudioLength>{convertMessageLength(children)}</StyledAudioLength>
}

export default memo(AudioLength)
