import styled from 'styled-components'
import { colors } from '../assets/colors'

interface IDivider {
  marginLeft?: string
}

const Divider = styled.div<IDivider>`
  width: calc(100% - ${(props) => props.marginLeft || '10px'});
  height: 1px;
  min-height: 1px;
  background-color: ${colors.lightGrey};
  margin-left: ${(props) => props.marginLeft || '10px'};
  box-sizing: border-box;
`

export default Divider
