import styled from 'styled-components'
import React, { ReactNode } from 'react'
import { colors } from '../../../../assets/colors'
import Avatar from './Avatar'
import ContactName from './ContactName'
import KateNumber from './KateNumber'
import Info from './Info'

const ContactWrapper = styled.div`
  flex-shrink: 0;
  height: 50px;
  max-height: 50px;
  min-height: 50px;
  width: 100%;
  background-color: ${colors.silver};
  border-bottom: 1px solid ${colors.lightSliver};
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.875rem;
  justify-content: stretch;
  padding: 5px 3px;
  cursor: pointer;
`

export interface ContactProps {
  onClick?: () => void
  onMouseEnter?: () => void
  uniqueId?: string
  children?: ReactNode
  border?: boolean
  contact?: {
    photo_url: string
    first_name: string
    last_name: string
    kate_number: string
  }
}

const Contact = ({ children, border = true, ...rest }: ContactProps) => (
  <ContactWrapper style={border ? {} : { border: 0 }} {...rest}>
    {children}
  </ContactWrapper>
)

Contact.Avatar = Avatar
Contact.ContactName = ContactName
Contact.KateNumber = KateNumber
Contact.Date = Date
Contact.Info = Info

export default Contact
