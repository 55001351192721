import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface IProps {
  children: ReactNode
}

const Container = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const RoundedIconsContainer = ({ children }: IProps) => <Container>{children}</Container>

export default RoundedIconsContainer
