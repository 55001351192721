import React, { SVGProps } from 'react'

const Trash = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.5 3.6H18v1.8h-1.8v11.7a.9.9 0 0 1-.9.9H2.7a.9.9 0 0 1-.9-.9V5.4H0V3.6h4.5V.9a.9.9 0 0 1 .9-.9h7.2a.9.9 0 0 1 .9.9v2.7Zm.9 1.8H3.6v10.8h10.8V5.4ZM6.3 1.8v1.8h5.4V1.8H6.3Z"
      fill="#707070"
    />
  </svg>
)

export default Trash
