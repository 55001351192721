import styled from 'styled-components'
import { colors } from '../../../../../assets/colors'

export const Input = styled.input`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: ${colors.veryDark};
  background: transparent;
  width: 460px;
`

export default Input
