import { useState } from 'react'

const useMobileNavigation = () => {
  const [expanded, setExpanded] = useState(false)

  const toggleNavigationExpanded = () => {
    setExpanded((prevState) => !prevState)
  }

  return {
    expanded,
    toggleNavigationExpanded
  }
}

export default useMobileNavigation
