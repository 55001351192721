import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'

const useAudio = (audio: boolean) => {
  const [volume, setVolume] = useState(0)
  const [context, setContext] = useState<AudioContext>()
  const [audioStr, setAudioStr] = useState<MediaStream | null>(null)

  const audioVolume = async () => {
    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      setAudioStr(audioStream)

      const audioContext = new AudioContext()
      const analyser = audioContext.createAnalyser()
      const microphone = audioContext.createMediaStreamSource(audioStream)
      const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1)

      analyser.smoothingTimeConstant = 0.8
      analyser.fftSize = 1024

      microphone.connect(analyser)
      analyser.connect(javascriptNode)
      javascriptNode.connect(audioContext.destination)
      javascriptNode.onaudioprocess = function () {
        const array = new Uint8Array(analyser.frequencyBinCount)
        analyser.getByteFrequencyData(array)
        let values = 0

        const length = array.length
        for (let i = 0; i < length; i++) {
          values += array[i]
        }

        const average = values / length

        setVolume(Math.round(average))
        setContext(audioContext)
      }
    } catch (e) {
      console.log('audio context is not supported in the browser', e)
      Sentry.captureException(e, {
        tags: {
          section: 'useAudio'
        }
      })
    }
  }

  useEffect(() => {
    return () => {
      audioStr?.getTracks().forEach(function (track) {
        track.stop()
      })
    }
  }, [audioStr])

  useEffect(() => {
    audioVolume()
  }, [])

  useEffect(() => {
    if (!audio) {
      context?.suspend()
      setVolume(0)
    }
    if (audio) context?.resume()
  }, [audio])

  return volume
}

export default useAudio
