import styled from 'styled-components'
import { colors } from '../../../../../../../assets/colors'

export const DebugInfoFormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid ${colors.lightGrey};
`
