import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Redirect, Route, RouteComponentProps, Switch, useParams } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router'
import { Virtuoso } from 'react-virtuoso'
import { colors } from '../../../../assets/colors'
import { TagType } from '../../../../types'
import { useGetTags } from '../../../../hooks/queries/useGetTags'
import Spinner from '../../../../components/Icon/Spinner'
import { CentralPanel, Layout, RightPanel } from '../../../../layout/Layout'
import MessageTagView from './MessageTagView'
import { Typography } from '@mui/material'

const activeBtn = css`
  font-weight: 600;
  color: ${colors.black};
  border-bottom: 2px solid ${colors.darkBlue};
`

const StyledBtnNavibar = styled.button<{ active?: boolean }>`
  color: ${colors.mediumGrey};
  background: none;
  border: none;
  padding-bottom: 5px;
  flex: 1;

  font-size: 14px;
  cursor: pointer;

  ${(props) => props.active && activeBtn}
`

const NavibarContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
`

const TagsNavibar = () => {
  const history = useHistory()
  const { type: activeTab } = useParams<{ type?: string }>()

  return (
    <NavibarContainer>
      <StyledBtnNavibar onClick={() => history.push(`/tags/${Tabs.SmartView}`)} active={activeTab === Tabs.SmartView}>
        Smart view
      </StyledBtnNavibar>
      <StyledBtnNavibar
        onClick={() => history.push(`/tags/${Tabs.PersonalTags}`)}
        active={activeTab === Tabs.PersonalTags}
      >
        Personal tags
      </StyledBtnNavibar>
      <StyledBtnNavibar onClick={() => history.push(`/tags/${Tabs.FromSender}`)} active={activeTab === Tabs.FromSender}>
        From Sender
      </StyledBtnNavibar>
    </NavibarContainer>
  )
}

enum TagsTypes {
  PersonalTags = 'personal-tags',
  SmartViews = 'smart-view',
  TagsFromSender = 'from-sender'
}

const TagsList: React.FC<RouteComponentProps> = () => {
  const { type: activeTab } = useParams<{ type?: string }>()
  const history = useHistory()
  const location = useLocation()
  const [personalTags, setPersonalTags] = useState<TagType[]>([])
  const [smartViews, setSmartViews] = useState<TagType[]>([])
  const [tagsFromSender, setTagsFromSender] = useState<TagType[]>([])

  const { data: tagsData, isLoading } = useGetTags()

  const tagName = location.pathname.split('/')?.[3]

  useEffect(() => {
    if (!tagsData) return
    setPersonalTags(tagsData.tags)
    setSmartViews(tagsData.views.filter((item: TagType) => !item.categories || !item.categories.sender))
    setTagsFromSender(tagsData.views.filter((item: TagType) => item.categories && item.categories.sender))
  }, [tagsData])

  useEffect(() => {
    if (activeTab === TagsTypes.SmartViews) {
      if (smartViews?.length && !tagName) {
        const firstTagInSmartViews = smartViews?.[0]
        history.push(`/tags/${activeTab}/${firstTagInSmartViews.name.split('#').join('')}`)
      }
    }
    if (activeTab === TagsTypes.TagsFromSender) {
      if (tagsFromSender?.length && !tagName) {
        const firstTag = tagsFromSender?.[0]
        history.push(`/tags/${activeTab}/${firstTag.name.split('#').join('')}`)
      }
    }
    if (activeTab === TagsTypes.PersonalTags) {
      if (personalTags?.length && !tagName) {
        const firstTag = personalTags?.[0]
        history.push(`/tags/${activeTab}/${firstTag.name.split('#').join('')}`)
      }
    }
  }, [activeTab, smartViews, tagsFromSender, personalTags, tagName])

  const data = {
    [Tabs.SmartView]: smartViews,
    [Tabs.PersonalTags]: personalTags,
    [Tabs.FromSender]: tagsFromSender
  }

  const dataTags = data[activeTab] || []

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spinner fill={colors.darkBlue} width={'20px'} />
      </div>
    )
  }

  if (!dataTags?.length) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
        <Typography color={colors.mediumGrey}>No tags</Typography>
      </div>
    )
  }

  return (
    <Virtuoso
      style={{ height: '100%' }}
      data={dataTags as any}
      itemContent={(index, tag) => {
        return (
          <TagItem
            key={tag.id}
            first={index === 0}
            active={`#${tagName}` === tag.name}
            name={tag.name}
            amount={tag?.messages?.length}
            onClick={() => {
              history.push(`/tags/${activeTab}/${tag.name.split('#').join('')}`)
            }}
          />
        )
      }}
    />
  )
}

const TagItemWrapper = styled.div<{ first?: boolean; active?: boolean }>`
  display: flex;
  width: 100%;
  height: 58px;
  align-items: center;
  justify-content: flex-start;

  > div {
    height: 100%;
    width: 100%;
    margin: 0 10px;
    display: flex;
    align-items: center;
    border-top: ${({ first }) => (first ? `1px solid ${colors.silver}` : 'none')};
    border-bottom: 1px solid ${colors.silver};
  }

  :hover {
    background-color: ${colors.veryLightBlue2};
  }

  background-color: ${({ active }) => (active ? colors.veryLightBlue2 : 'none')};

  cursor: pointer;
`
const TagItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 10px;
  color: ${colors.white};
  height: 32px;
  background: ${colors.mediumGrey};
`

const LabelContainer = styled.span`
  padding: 0 3px;
`

const TagItem = ({ name, amount, first, onClick, active }) => {
  return (
    <TagItemWrapper first={first} onClick={onClick} active={active}>
      <div>
        <TagItemContainer>
          <LabelContainer>{name}</LabelContainer>
          <LabelContainer>|</LabelContainer>
          <LabelContainer>{amount}</LabelContainer>
        </TagItemContainer>
      </div>
    </TagItemWrapper>
  )
}

enum Tabs {
  SmartView = 'smart-view',
  PersonalTags = 'personal-tags',
  FromSender = 'from-sender'
}

const TagsPage = () => {
  return (
    <Layout>
      <CentralPanel>
        <TagsNavibar />
        <Switch>
          <Route path="/tags/:type" component={TagsList} />
          <Route exact path="/tags">
            <Redirect to={'/tags/smart-view'} />
          </Route>
        </Switch>
      </CentralPanel>
      <RightPanel>
        <MessageTagView />
      </RightPanel>
    </Layout>
  )
}

export default TagsPage
