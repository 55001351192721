import React, { FC, MouseEvent } from 'react'
import styled from 'styled-components'
import AvatarPlaceholder from '../../../../assets/images/avatar_placeholder.png'
import ParticipantSelected from '../../../../components/Icon/ParticipantSelected'
import ParticipantSelectEmpty from '../../../../components/Icon/ParticipantSelectEmpty'
import { colors } from '../../../../assets/colors'

const Container = styled.div`
  font-size: 12px;
  padding: 10px;
  display: flex;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.025);
  }
`

const Avatar = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 30px;
  margin-right: 10px;
`

const ContactName = styled.div`
  font-size: 14px;
`

const ContactNumber = styled.div`
  font-size: 12px;
  color: ${colors.mediumGrey};
`

const ContactInfo = styled.div`
  padding: 5px 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`

type SelectableContactProps = {
  selected: boolean
  id: string
  photoUrl?: string
  firstname?: string
  lastname?: string
  kateNumber?: string
  onClick: (event: MouseEvent<HTMLElement>) => void
}

const SelectableContact: FC<SelectableContactProps> = ({
  id,
  selected,
  kateNumber,
  firstname,
  lastname,
  photoUrl,
  ...rest
}) => {
  return (
    <Container data-id={id} {...rest}>
      {photoUrl ? <Avatar src={photoUrl} alt="Contact avatar" /> : <Avatar src={AvatarPlaceholder} />}
      <ContactInfo>
        <ContactName>
          {firstname || ''} {lastname || ''}
        </ContactName>
        <ContactNumber>{kateNumber || ''}</ContactNumber>
      </ContactInfo>
      <ActionContainer>{selected ? <ParticipantSelected /> : <ParticipantSelectEmpty />}</ActionContainer>
    </Container>
  )
}

export default SelectableContact
