import styled, { css } from 'styled-components'
import { colors } from '../../../assets/colors'

interface INote {
  new?: boolean
  private?: boolean
}

const privateMessage = css`
  color: ${colors.mediumGrey2};
`

const newMessage = css`
  color: ${colors.green};
`

const defaultColor = css`
  color: ${colors.mediumGrey};
`

const Note = styled.div<INote>`
  display: flex;
  align-items: center;
  font-size: 0.725rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: normal;
  max-width: calc(100vw - 200px);
  ${(props: INote) => props.new && newMessage};
  ${(props: INote) => props.private && privateMessage};
  ${(props: INote) => !props.new && !props.private && defaultColor};
`

export default Note
