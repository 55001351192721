import { useQuery } from '@tanstack/react-query'
import { ContactItemType } from '../../types'
import APIClient from '../../apiClient'

export const useGetGroupQuery = (groupId) => {
  return useQuery<unknown, unknown, ContactItemType[]>({
    queryKey: ['groups', groupId],
    queryFn: async () => {
      const res = await APIClient.contactList({ GroupID: groupId })
      return res.data
    },
    cacheTime: 1000 * 60 * 15 // 15 minutes
  })
}
