import { create } from 'zustand'
import { RoomInformationData } from '../pages/Video/video.types'

export enum VideoStateEnum {
  Conference = 'conference',
  WaitingRoom = 'waitingRoom'
}

interface VideoData {
  meetingId: string
  sessionId: string
  token: string
  message?: string
}

export interface VideoState {
  audio: boolean
  video: boolean
  sharingScreen: boolean
  publisherProperties: {
    publishAudio: boolean
    publishVideo: boolean
    resolution: string
  }
  publisherState: any
  blurredBackground: boolean
  room: RoomInformationData | null
  videoData: VideoData | null
  videoRoomState: VideoStateEnum.Conference | VideoStateEnum.WaitingRoom
  actions: {
    setAudio: (value: boolean) => void
    setVideo: (value: boolean) => void
    toggleAudio: () => void
    toggleVideo: () => void
    setPublisherState: (publisherState: any) => void
    setPublisherProperties: (publisherProperties: any) => void
    setBlurredBackground: (blurredBackground: boolean) => void
    setRoom: (room: RoomInformationData) => void
    setVideoData: (videoData: VideoData) => void
    setVideoRoomState: (videoRoomState: VideoStateEnum) => void
    // toggleBlurredBackground: () => void
    setSharingScreen: (sharingScreen: boolean) => void
  }
}

export const useVideoStore = create<VideoState>()((set, get) => ({
  audio: true,
  video: true,
  room: null,
  videoData: null,
  videoRoomState: VideoStateEnum.WaitingRoom,
  publisherProperties: {
    publishAudio: true,
    publishVideo: true,
    resolution: '1280x720'
  },
  publisherState: null,
  blurredBackground: false,
  sharingScreen: false,
  actions: {
    setAudio: (audio) => set({ audio }),
    setVideo: (video) => set({ video }),
    setRoom: (room) => set({ room }),
    setVideoData: (videoData) => set({ videoData }),
    setVideoRoomState: (videoRoomState) => set({ videoRoomState }),
    setBlurredBackground: (blurredBackground) => set({ blurredBackground }),
    setPublisherState: (publisherState) => set({ publisherState }),
    setPublisherProperties: (publisherProperties) => set({ publisherProperties }),
    setSharingScreen: (sharingScreen) => set({ sharingScreen }),
    toggleAudio: () => {
      const audio = get().audio
      set({ audio: !audio })
    },
    toggleVideo: () => {
      const video = get().video
      set({ video: !video })
    }
    // toggleBlurredBackground: async () => {
    //   const publisherState = get().publisherState
    //   if (!publisherState) return
    //   const videoFilter = await publisherState.getVideoFilter()
    //   if (videoFilter?.type === 'backgroundBlur') {
    //     get().actions.setBlurredBackground(false)
    //     await publisherState.clearVideoFilter()
    //     return
    //   }
    //   await publisherState?.applyVideoFilter({ type: 'backgroundBlur', blurStrength: 'high' })
    //   get().actions.setBlurredBackground(true)
    // }
  }
}))

export const useVideoActions = () => useVideoStore((state) => state.actions)
