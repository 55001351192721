import React from 'react'
import styled from 'styled-components'
import { ConferenceAtendees } from './components/ConferenceAtendees'
import { ConferenceAtendee } from './components/ConferenceAttendee'
import DateInfo from './components/DateInfo'
import { MeetingContainer } from './components/MeetingContainer'
import { MeetingPriceText } from './components/MeetingPriceText'
import { MeetingTitle } from './components/MeetingTitle'
import { MinutesSubtitle } from './components/MinutesSubtitle'
import { Separator } from './components/Separator'
import TimeInfo from './components/TimeInfo'
import { TimeText } from './components/TimeText'
import format from 'date-fns/format'

interface Attendee {
  participantName: string
  participantId: number
}

interface MeetingCardProps {
  title: string
  date?: string
  attendees: Attendee[]
  timeFrom?: string
  timeTo?: string
  time: number
  price: string
}

const BillableMinutesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const PriceContainer = styled.div`
  flex: 1;
`

const MinutesPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 3;
`

const MeetingCard = ({ title, date, attendees, timeFrom, timeTo, time, price }: MeetingCardProps) => {
  // @ts-ignore
  const ids = attendees.map((o) => o.participantId)
  const uniqueParticipants = attendees.filter(({ participantId }, index) => !ids.includes(participantId, index + 1))

  return (
    <MeetingContainer>
      <MeetingTitle>{title}</MeetingTitle>
      {timeFrom && <DateInfo date={new Date(timeFrom).toISOString().slice(0, 10)} />}
      {timeFrom && timeTo && (
        <TimeInfo timeFrom={format(new Date(timeFrom), 'p')} timeTo={format(new Date(timeTo), 'p')} />
      )}
      <ConferenceAtendees className="avatars">
        {uniqueParticipants.map((attendee, index) => (
          <ConferenceAtendee key={attendee.participantId} style={{ marginLeft: -(index + 8) }}>
            {attendee.participantName[0].toUpperCase()}
          </ConferenceAtendee>
        ))}
      </ConferenceAtendees>
      <Separator />
      <MinutesPriceContainer>
        <BillableMinutesContainer>
          <TimeText>{time} min</TimeText>
          <MinutesSubtitle>Total Billable Minutes</MinutesSubtitle>
        </BillableMinutesContainer>
        <PriceContainer>
          <MeetingPriceText>{price}</MeetingPriceText>
        </PriceContainer>
      </MinutesPriceContainer>
    </MeetingContainer>
  )
}

export default MeetingCard
