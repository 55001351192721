import styled from 'styled-components'
import { colors } from '../../../../../assets/colors'

const Line = styled.div<{ error: boolean }>`
  width: 460px;
  height: 1px;
  background: ${({ error }) => (error ? '#b91c1c' : colors.veryDark)};
`

export default Line
