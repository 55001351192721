import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { toast } from 'react-toastify'

type TTabs = null | 'success'

const useCurrentActionTab = () => {
  const searchParams = new URLSearchParams(useLocation().search)
  const currentTab = searchParams.get('status') as TTabs

  useEffect(() => {
    if (currentTab === 'success') {
      toast.success('Success! Your payment method has been saved.')
    }
  }, [currentTab])
}

export default useCurrentActionTab
