import React from 'react'
import styled from 'styled-components'
import Button from '../Button'
import Clock from '../../../components/Icon/Clock'
import { colors } from '../../assets/colors'
import useDateForConference from './useDateForConference'
import { useHistory } from 'react-router'
import DeleteConf from '../../../components/Icon/DeleteConf'
import CopyToClipboard from 'react-copy-to-clipboard'
import InviteLink from '../../../components/Icon/InviteLink'
import ShareConferenceLinkButton from '../ShareConferenceLink'

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 20px 0;
`

const DateContainer = styled.div`
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 4px;
    border-radius: 10px;
    background: ${colors.darkBlue};
  }
`

const Date = styled.div`
  font-size: 20px;
  color: ${colors.darkBlue};
  margin-bottom: 5px;
`

const Month = styled.div`
  font-size: 12px;
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${colors.white}
  box-shadow: 0 8px 20px rgba(154, 169, 188, 0.1);
  border-radius: 15px;
  padding: 20px;
  margin-left: 15px;
`

const ConferenceTitleContainer = styled.div`
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  height: 20px;
`

const ConferenceTitle = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 12px;
  max-width: 70%;
  @media (max-width: 460px) {
    font-size: 16px;
  }
`

const ConferenceIconsContainer = styled.div`
  display: 'flex';
  flex-direction: row;
`

const SingleIconContainer = styled.button`
  background-color: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

const ConferenceDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.mediumGrey};
  margin-bottom: 12px;
`

const ConferenceTimeRange = styled.div`
  display: flex;
  margin-bottom: 12px;

  & > div {
    margin-right: 5px;
  }
`

const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const ConferenceAtendees = styled.div`
  display: flex;
`

const ConferenceAtendee = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #fff;
  border: 1px solid ${colors.mediumGrey};
  border-radius: 30px;
  overflow: hidden;
`

type RecentConferenceCardProps = {
  startDate?: string
  endDate?: string
  createdAt: string
  title: string
  description: string
  meetingId: string
  attendees?: any[]
  onPressDelete: () => void
}

const RecentConferenceCard = ({
  startDate,
  endDate,
  createdAt,
  title,
  description,
  meetingId,
  attendees,
  onPressDelete
}: RecentConferenceCardProps) => {
  const history = useHistory()
  const { day, month, timeRange } = useDateForConference(createdAt, startDate, endDate)

  return (
    <CardContainer>
      <DateContainer>
        <Date>{day}</Date>
        <Month>{month}</Month>
      </DateContainer>

      <ContentContainer>
        <ConferenceTitleContainer>
          <ConferenceTitle>{title}</ConferenceTitle>
          <ConferenceIconsContainer>
            <ShareConferenceLinkButton meetingId={meetingId} />
          </ConferenceIconsContainer>
        </ConferenceTitleContainer>
        <ConferenceDescription>{description}</ConferenceDescription>
        {timeRange ? (
          <ConferenceTimeRange>
            <div>
              <Clock />
            </div>
            {timeRange}
          </ConferenceTimeRange>
        ) : (
          <div style={{ height: 20 }}></div>
        )}
        <CardFooter>
          {attendees ? (
            <ConferenceAtendees className="avatars">
              {attendees.map((attendee, index) => (
                <ConferenceAtendee key={attendee.email} style={{ marginLeft: -(index + 8) }}>
                  {attendee.email[0].toUpperCase()}
                </ConferenceAtendee>
              ))}
            </ConferenceAtendees>
          ) : (
            <div />
          )}
          <Button small onClick={() => history.push(`/conference/${meetingId}`)}>
            Join Meeting
          </Button>
        </CardFooter>
      </ContentContainer>
    </CardContainer>
  )
}

export default RecentConferenceCard
