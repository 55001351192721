import React from 'react'
import MobileGroupPage from '../../../mobile/pages/Groups'
import { Layout, RightPanel } from '../../../../layout/Layout'
import useCurrentParam from '../../../../hooks/useCurrentParam'
import useGroups from '../../../mobile/pages/Groups/Common/Hooks/useGroups'
import { Wrapper } from '../../../mobile/pages/Groups/Ui/Styled'
import GroupsListPanel from '../../../mobile/pages/Groups/Ui/Partials/GroupsListPanel'
import GroupInfo from '../../../mobile/pages/Groups/Ui/Partials/GroupInfo'
import CreateGroupForm from '../../../mobile/pages/Groups/Ui/Partials/CreateGroupForm'
import EditGroupForm from '../../../mobile/pages/Groups/Ui/Partials/EditGroupForm'
import NoContactsYet from '../../containers/NoContactsYet'

const GroupDetails = ({ currentGroup, groupContacts, groupContactsLoading, contactsData, isLoading }) => {
  const [currentTab] = useCurrentParam<'create' | 'edit' | null>('action')

  if (!currentGroup && currentTab === null && !isLoading) {
    return <NoContactsYet buttonLabel={'New Group'} text={'You don’t have any groups yet'} />
  }

  return (
    <>
      {currentTab === null && currentGroup && (
        <GroupInfo currentGroup={currentGroup} groupContacts={groupContacts} contactsLoading={groupContactsLoading} />
      )}
      {currentTab === 'create' && <CreateGroupForm contactsData={contactsData} />}
      {currentTab === 'edit' && (
        <EditGroupForm currentGroup={currentGroup} contactsData={contactsData} groupContacts={groupContacts} />
      )}
    </>
  )
}

const Groups = () => {
  const {
    isMobile,
    currentGroup,
    filteredGroups,
    isLoading,
    search,
    groupContacts,
    groupContactsLoading,
    contactsData
  } = useGroups()

  return (
    <Layout>
      <GroupsListPanel search={search} groups={filteredGroups} isLoading={isLoading} currentGroup={currentGroup} />
      <RightPanel>
        <Wrapper>
          <GroupDetails
            isLoading={isLoading}
            currentGroup={currentGroup}
            groupContacts={groupContacts}
            groupContactsLoading={groupContactsLoading}
            contactsData={contactsData}
          />
        </Wrapper>
      </RightPanel>
    </Layout>
  )
}

export default Groups
