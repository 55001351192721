import { Redirect, Route, Switch } from 'react-router-dom'
import TermsConditions from '../shared/TermsConditions'
import VideoPage from '../../VideoConferencing/pages/Video'
import MeetingRoom from '../../VideoConferencing/pages/MeetingRoom'
import VideoConferencing from '../../VideoConferencing/pages/VideoConferencing'
import Reports from '../../VideoConferencing/pages/Reports'
import MonthReport from '../../VideoConferencing/pages/MonthReport'
import StreamConference from '../../VideoConferencing/pages/StreamConference'
import Modal from '../../components/Modal'
import React, { lazy, Suspense } from 'react'
import { useModalsStore } from '../../store/modals'
import { shallow } from 'zustand/shallow'
import Messages from '../desktop/pages/Messages'
import TagsPage from '../desktop/pages/Tags'
import OptionsPage from '../desktop/pages/Options'
import Contacts from '../desktop/pages/Contacts'
import Groups from '../desktop/pages/Groups'
import Chat from '../../Chat'
import { useUser } from '../../store/user'

export const DesktopRouter = () => {
  const successModal = useModalsStore((state) => state.info, shallow)
  const user = useUser()
  const IS_ENG_BETA_TESTER = user?.eng_beta_tester

  return (
    <>
      <Switch>
        <Route path="/messages" component={Messages} />

        {/*// Contained in above router //messages*/}
        {/*<Route path="/message/:id" component={MessageShowPage} />*/}

        {/*// Shared Routes for Video Conferencing*/}
        {IS_ENG_BETA_TESTER && <Route path="/video-conferencing" component={VideoConferencing} />}
        {IS_ENG_BETA_TESTER && <Route exact path="/conference" component={VideoPage} />}
        {IS_ENG_BETA_TESTER && <Route path="/meeting" component={MeetingRoom} />}
        {IS_ENG_BETA_TESTER && <Route path="/reports" component={Reports} />}
        {IS_ENG_BETA_TESTER && <Route path="/report/:from/:to" component={MonthReport} />}
        {IS_ENG_BETA_TESTER && <Route path="/stream-conference/:meetingId" component={StreamConference} />}

        {/*// */}

        <Route exact path="/tags" component={TagsPage} />
        <Route exact path="/tags/:type" component={TagsPage} />
        <Route exact path="/tags/:type/:tag" component={TagsPage} />

        <Route path="/options" component={OptionsPage} />
        <Route path="/contacts" component={Contacts} />
        {/*<Route path="/contactShow" component={ContactShowPage} />*/}
        <Route path="/groups" component={Groups} />

        {/* Chat */}
        {IS_ENG_BETA_TESTER && <Route path="/chat" component={Chat} />}

        {/*//*/}
        {/*<Route path="/tags/messages" component={TagMessagesPage} />*/}
        <Route path="/termsAndConditions" component={TermsConditions} />
        <Route path="*">
          <Redirect to={'/messages'} />
        </Route>
      </Switch>
      {/*<Route path={`/`} component={NewMessage} />*/}

      <Modal show={successModal.visible}>
        <Modal.IconInfo text={successModal.message} icon={'checkmark'} />
      </Modal>
    </>
  )
}
