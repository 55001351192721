import React from 'react'

interface AddProps {
  style?: object
  fill?: string
  width?: string
  height?: string
  className?: string
  viewBox?: string
}

const BackArrow = ({
  style = {},
  fill = '#1D70B6',
  width = '9px',
  height = '18px',
  className = '',
  viewBox = '0 0 9 18'
}: AddProps) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M8.78114 16.9182L2.49403 8.75372L9 1.33126L7.57425 0.000122561L0 8.64091L7.43325 18L8.78114 16.9182Z"
    />
  </svg>
)

export default BackArrow
