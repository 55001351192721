import React from 'react'
import styled from 'styled-components'
import { colors } from '../../assets/colors'

interface ButtonProps {
  isActive?: boolean
}

const StyledButton = styled.button<ButtonProps>`
  background-color: ${(props) => (props.isActive ? colors.darkBlue : 'transparent')};
  border: 1px solid ${(props) => (props.isActive ? colors.darkBlue : colors.lightSliver)};
  border-radius: 24px;
  padding: 0;
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.isActive ? 'flex-end' : 'flex-start')};
  align-items: center;
  width: 40px;
  height: 24px;
  cursor: pointer;
`

const Switch = styled.div`
  background-color: ${colors.white};
  width: 22px;
  height: 22px;
  border-radius: 22px;
  box-shadow: -1px 1px 5px -2px rgba(0, 0, 0, 0.75);
`

interface BoolButtonProps {
  isActive?: boolean
  onClick?: () => void
}

const SwitchButton = ({ isActive, onClick }: BoolButtonProps) => (
  <StyledButton isActive={isActive} onClick={onClick}>
    <Switch />
  </StyledButton>
)

export default SwitchButton
