import React from 'react'
import styled from 'styled-components'
import { colors } from '../../assets/colors'

const Footer = styled.header`
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: ${colors.shadowGrey};
  border-top: 1px solid ${colors.shadowGrey};
  background-color: ${colors.white};
`

export default Footer
