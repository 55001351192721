import styled from 'styled-components'

interface ContainerProps {
  height?: number
}

const InfoContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => (props.height ? props.height : 400)}px;
  min-width: 380px;
`

export default InfoContainer
