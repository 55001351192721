import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Field from '../../../../components/Field'
import Icon from '../../../../components/Icon'
import { colors } from '../../../../assets/colors'
import useAudioPlaying from '../../../../hooks/useAudioPlaying'
// @ts-ignore
import MicRecorder from 'mic-recorder-to-mp3'
import { Howl } from 'howler'
import { NewMessagePlaybackBar } from '../../../../components/blocks/Message/NewMessagePlaybackBar'
import RecordingVisualizerBox from '../../../../components/blocks/RecordingVisualizerBox'
import Header from '../../../../components/Header'
import { InlineButton } from '../../../../components/Button/InlineButton'
import APIClient from '../../../../apiClient'
import { Loader } from '../../../../components/Loader/Loader'
import styled from 'styled-components'
import { showAlertModal, showInfoModal } from '../../../../store/modals'
import { useUser } from '../../../../store/user'

const StyledButton = styled.button`
  background-color: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
  opacity: 0.92;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

const NameRecordingPage: React.FC = () => {
  // fallback for Safari's AudioContext reference
  // @ts-ignore
  window.AudioContext = window.AudioContext || window.webkitAudioContext
  const user = useUser()
  const history = useHistory()
  const [isRecording, setRecording] = useState(false)
  const [isLoading, setLoading] = useState(true)

  // Audio from database
  const currentGreeting = useAudioPlaying()

  const getCurrentMp3 = useCallback(async () => {
    try {
      if (user && !user.phone_settings.name_recording) return
      const res = await APIClient.nameRecordingGet()
      if (res.data) {
        const blob = new Blob([res.data], { type: 'audio/mp3' })
        // @ts-ignore
        const blobURL = window.URL.createObjectURL(blob)
        currentGreeting.setAudio(
          new Howl({
            html5: true,
            src: blobURL,
            format: ['mp3']
          })
        )
      }
    } catch (e) {
      console.log('error on getCurrentMp3:', e)
      showAlertModal("Can't get current name recording")
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getCurrentMp3()
  }, [])

  // Audio record
  const {
    audio,
    curTime,
    duration,
    playing,
    playProgress,
    setAudio,
    playAudio,
    pauseAudio,
    setClickedTime
  } = useAudioPlaying()

  const [mp3File, setMp3File] = useState<File | null>(null)
  const [Mp3Recorder] = useState(new MicRecorder({ bitRate: 128, sampleRate: 22050 }))

  const startRecording = async () => {
    try {
      const recordingStarted = await Mp3Recorder.start()
      if (recordingStarted) {
        setRecording(true)
      }
    } catch (e) {
      console.log('error on startRecording:', e)
      showAlertModal('Recording error')
    }
  }

  const stopRecording = async () => {
    try {
      const [buffer, blob] = await Mp3Recorder.stop().getMp3()

      if (buffer && blob) {
        const timestamp = Date.now()

        const file = new File(buffer, `name-recording-${timestamp}.mp3`, {
          type: 'audio/mpeg',
          lastModified: Date.now()
        })

        const blobURL = URL.createObjectURL(file)
        setMp3File(file)
        setRecording(false)
        setAudio(
          new Howl({
            html5: true,
            src: blobURL,
            format: ['mp3']
          })
        )
      }
      // TODO Add Proper types
    } catch (e: any) {
      showAlertModal(`error on stop recording: ${e.toString()}`)
      console.log('error on stopRecording:', e)
    }
  }

  const submitFile = useCallback(async () => {
    if (!mp3File) {
      showAlertModal(`No greeting recorded`)
      return null
    }
    try {
      const formData = new FormData()
      formData.append('recording', mp3File)
      await APIClient.nameRecordingSave(formData)
      showInfoModal('Recording updated')
      history.goBack()
      // TODO Add Proper types
    } catch (e: any) {
      showAlertModal(`error on submitting file: ${e.toString()}`)
      console.log('error on submitFile:', e)
    }
  }, [mp3File])

  const playCurrent = async () => {
    if (currentGreeting.playing) {
      await currentGreeting.pauseAudio()
    } else {
      await currentGreeting.playAudio()
    }
  }

  return (
    <>
      <Header>
        <Header.SideButtons>
          <InlineButton onClick={() => history.goBack()}>Options</InlineButton>
        </Header.SideButtons>
        <Header.Title>
          <span>Name Recording</span>
        </Header.Title>
        <Header.EndButtons>
          {isLoading && <Loader fill={colors.white} width={'20px'} />}
          {mp3File && <InlineButton onClick={submitFile}>Save</InlineButton>}
        </Header.EndButtons>
      </Header>

      <div style={{ width: '100%', height: '100%', backgroundColor: colors.silver }}>
        {currentGreeting.audio ? (
          <Field border>
            <Field.InfoContainer>
              <Field.Content>Current Recording</Field.Content>
            </Field.InfoContainer>
            <Field.Actions onClick={playCurrent}>
              {currentGreeting.playing ? (
                <StyledButton>
                  <Icon name={'pause'} fill={colors.darkBlue} width={'30px'} />
                </StyledButton>
              ) : (
                <StyledButton>
                  <Icon name={'play'} fill={colors.darkBlue} width={'30px'} />
                </StyledButton>
              )}
            </Field.Actions>
          </Field>
        ) : (
          <Field border>
            <Field.InfoContainer>
              <Field.Content>No Recording Present.</Field.Content>
            </Field.InfoContainer>
          </Field>
        )}

        <Field>
          <Field.InfoContainer>
            <Field.Content>Record New Name</Field.Content>
          </Field.InfoContainer>
          <Field.Actions onClick={isRecording ? stopRecording : startRecording}>
            {isRecording ? (
              <StyledButton>
                <Icon name={'pause'} fill={colors.darkBlue} width={'30px'} />
              </StyledButton>
            ) : (
              <StyledButton>
                <Icon name={'microphone'} fill={colors.darkBlue} width={'30px'} />
              </StyledButton>
            )}
          </Field.Actions>
        </Field>
        {audio && (
          <Field>
            <NewMessagePlaybackBar
              curTime={curTime}
              duration={duration}
              playProgress={playProgress}
              onTimeUpdate={(time) => {
                setClickedTime(time)
              }}
            />
            <Field.Actions onClick={playing ? pauseAudio : playAudio}>
              {playing ? (
                <StyledButton>
                  <Icon name={'pause'} fill={colors.darkBlue} width={'30px'} />
                </StyledButton>
              ) : (
                <StyledButton>
                  <Icon name={'play'} fill={colors.darkBlue} width={'30px'} />
                </StyledButton>
              )}
            </Field.Actions>
          </Field>
        )}
        {isRecording && <RecordingVisualizerBox />}
      </div>
    </>
  )
}

export default NameRecordingPage
