import React from 'react'

interface ScreenShareOffProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const ScreenShareOff = ({
  style = {},
  fill = '#000',
  width = '24px',
  className = '',
  viewBox = '0 0 1024 1024'
}: ScreenShareOffProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M905.386667 768.853333l85.333333 85.333334H1024v-85.333334h-118.613333z m32.853333-85.333333l0.426667-426.666667c0-47.36-38.4-85.333333-85.333334-85.333333H308.053333L531.2 394.666667c7.68-1.706667 15.36-2.986667 23.466667-4.266667v-90.88l170.666666 159.146667-67.413333 62.72 236.373333 236.373333a84.586667 84.586667 0 0 0 43.946667-74.24zM101.973333 73.813333L47.36 128 113.066667 193.706667C96 209.066667 85.333333 231.68 85.333333 256.853333v426.666667c0 46.933333 37.973333 85.333333 85.333334 85.333333H0v85.333334h773.546667l115.626666 115.626666 54.186667-54.186666L101.973333 73.813333zM298.666667 640.853333c13.226667-63.146667 39.253333-125.866667 88.32-173.226666l67.84 67.84c-65.706667 16.213333-115.2 50.346667-156.16 105.386666z"
    />
  </svg>
)

export default ScreenShareOff
