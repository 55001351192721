import AutoPlayer from '../../components/blocks/AutoPlayer'
import { InlineButton } from '../../components/Button/InlineButton'
import React from 'react'

interface IAutoView {
  visible: boolean
  messageFrom: string | undefined
  messageDate: string | undefined
  onTimerEnd: () => void
  onTimerCancel: () => void
}

export const AutoPlayView = ({ visible, messageFrom, messageDate, onTimerEnd, onTimerCancel, ...props }: IAutoView) => {
  return (
    <AutoPlayer visible={visible}>
      <AutoPlayer.Title>Up Next</AutoPlayer.Title>
      <AutoPlayer.From>{`From: ${messageFrom}`}</AutoPlayer.From>
      <AutoPlayer.FormattedDate>{messageDate}</AutoPlayer.FormattedDate>
      <AutoPlayer.Counter onTimerEnd={onTimerEnd} />
      <AutoPlayer.Cancel>
        <InlineButton onClick={onTimerCancel}>Cancel</InlineButton>
      </AutoPlayer.Cancel>
    </AutoPlayer>
  )
}
