import styled from 'styled-components'

interface IProps {
  size?: string
}
const ImageField = styled('img')<IProps>`
  width: ${({ size = 'normal' }) => (size === 'small' ? '80px' : (size === 'big' && '250px') || '150px')};
  height: ${({ size = 'normal' }) => (size === 'small' ? '80px' : (size === 'big' && '250px') || '150px')};
  border-radius: 10px;
`

export default ImageField
