function getWeekNumber(date: Date) {
  const firstDayOfTheYear = new Date(date.getFullYear(), 0, 1)
  const pastDaysOfYear = (date.getTime() - firstDayOfTheYear.getTime()) / 86400000
  return Math.ceil((pastDaysOfYear + firstDayOfTheYear.getDay() + 1) / 7)
}

export class Day {
  Date: Date
  date: number
  day: string
  dayNumber: number
  dayShort: string
  year: number
  yearShort: string
  month: string
  monthShort: string
  monthNumber: number
  timestamp: number
  week: number

  constructor(date: Date | null = null, lang = 'en') {
    const dateCopy = date ? date : new Date()
    this.Date = dateCopy
    this.date = dateCopy.getDate()
    this.day = dateCopy.toLocaleString(lang, { weekday: 'long' })
    this.dayNumber = dateCopy.getDay() + 1
    this.dayShort = dateCopy.toLocaleString(lang, { weekday: 'short' })
    this.year = dateCopy.getFullYear()
    this.yearShort = dateCopy.toLocaleString(lang, { year: '2-digit' })
    this.month = dateCopy.toLocaleString(lang, { month: 'long' })
    this.monthShort = dateCopy.toLocaleString(lang, { month: 'short' })
    this.monthNumber = dateCopy.getMonth() + 1
    this.timestamp = dateCopy.getTime()
    this.week = getWeekNumber(dateCopy)
  }

  get isToday() {
    return this.isEqualTo(new Date())
  }

  isEqualTo(date: Day | Date) {
    date = date instanceof Day ? date.Date : date

    return date.getDate() === this.date && date.getMonth() === this.monthNumber - 1 && date.getFullYear() === this.year
  }

  format(formatStr: string) {
    return formatStr
      .replace(/\bYYYY\b/, this.year.toString())
      .replace(/\bYYY\b/, this.yearShort)
      .replace(/\bWW\b/, this.week.toString().padStart(2, '0'))
      .replace(/\bW\b/, this.week.toString())
      .replace(/\bDDDD\b/, this.day)
      .replace(/\bDDD\b/, this.dayShort)
      .replace(/\bDD\b/, this.date.toString().padStart(2, '0'))
      .replace(/\bD\b/, this.date.toString())
      .replace(/\bMMMM\b/, this.month)
      .replace(/\bMMM\b/, this.monthShort)
      .replace(/\bMM\b/, this.monthNumber.toString().padStart(2, '0'))
      .replace(/\bM\b/, this.monthNumber.toString())
  }
}
