import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../../../../../store/user'
import { useParams } from 'react-router-dom'
import { useSession } from '../../../providers/SessionProvider'
import styled from 'styled-components'
import { Publisher } from '@opentok/client'
import { useVideoActions, useVideoStore } from '../../../../../store/video'
import * as Sentry from '@sentry/react'
import { showAlertModal } from '../../../../../../store/modals'

const ScreenShare = styled.div`
  display: flex;
  height: calc(100% - 110px);
  width: 65%;
  margin: 25px 25px 20px 25px;
  background: #1d70b6;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  clip-path: content-box;
  overflow: hidden;
  order: 1;
`

const styles = {
  insertMode: 'replace',
  width: '100%', // Szerokość każdego elementu Subscriber
  height: '100%', // Wysokość każdego elementu Subscriber
  videoSource: 'screen',
  // fitMode: 'contain'
  style: {
    archiveStatusDisplayMode: 'auto',
    nameDisplayMode: 'off',
    buttonDisplayMode: 'off',
    audioLevelDisplayMode: 'off',
    videoDisabledDisplayMode: 'off'
  }
}

const ACCESS_DENIED = 'OT_USER_MEDIA_ACCESS_DENIED'

export const ScreenPublisher = () => {
  const publisherDiv = useRef()
  const { session } = useSession()
  const { setSharingScreen } = useVideoActions()

  useEffect(() => {
    let publisher: Publisher = null
    if (session) {
      try {
        // @ts-ignore
        publisher = OT.initPublisher(publisherDiv.current, styles, (err) => {
          if (err) {
            console.log('Screenpublish Error init', err)
            Sentry.captureException(err, {
              tags: {
                context: 'Screensharing Error'
              }
            })
          }
        })

        publisher.on('accessDenied', (event) => {
          console.log('Access denied:', event)
          setSharingScreen(false)
        })

        // @ts-ignore

        session.publish(publisher, (error) => {
          if (error) {
            console.log('Screenpublish Error publish', error)
            Sentry.captureException(error, {
              tags: {
                context: 'Screen publish - Screensharing Error'
              }
            })
          }
        })

        publisher.on('streamDestroyed', (event) => {
          if (event.reason === 'mediaStopped') {
            setSharingScreen(false)
            // User clicked stop sharing
          } else if (event.reason === 'forceUnpublished') {
            setSharingScreen(false)
            // A moderator forced the user to stop sharing.
          }
        })
      } catch (e) {
        console.log('Screenpublish Error Catch', e)
        Sentry.captureException(e, {
          tags: {
            context: '[Catch] Screen publish - Screensharing Error'
          }
        })
      }
    }

    return () => {
      if (publisher && publisher.stream) {
        session.unpublish(publisher)
        publisher.destroy()
      }
    }
  }, [session])

  // @ts-ignore
  return <ScreenShare ref={publisherDiv} />
}
