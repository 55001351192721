import React from 'react'

interface ReplyArrowProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const ReplyArrow = ({
  style = {},
  fill = '#000',
  width = '30px',
  className = '',
  viewBox = '0 0 30 30'
}: ReplyArrowProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
M26,25c0-0.024-0.022-2.437-1.917-4.889C21.631,16.938,17.12,15.185,11,15.014V19c0,0.404-0.244,0.77-0.617,0.924 c-0.374,0.155-0.804,0.069-1.09-0.217l-7-7c-0.391-0.391-0.391-1.023,0-1.414l7-7c0.286-0.286,0.716-0.373,1.09-0.217 C10.756,4.23,11,4.596,11,5v4.016C27.515,9.553,28,23.395,28,25c0,0.553-0.448,1-1,1S26,25.553,26,25z
"
    />
  </svg>
)

export default ReplyArrow
