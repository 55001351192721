import React from 'react'
import styled, { keyframes } from 'styled-components'
import Icon from '../Icon'

const loadAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`

const StyledLoader = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 99;
  padding: 30px;
`

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledLoaderLabel = styled.div`
  font-size: 24px;
  font-weight: bold;
`

const SpinnerImage = styled(Icon)`
  width: 50px;
  height: 50px;
  margin: 30px 0;
`

const ProgressBar = styled.div`
  width: 0;
  height: 40px;
  background: white;
  animation: ${loadAnimation} 250ms linear forwards;
`

interface FullscreenLoaderProps {
  fill?: string
  height?: number | string
  width?: number | string
  style?: object
  name?: string
  progressBar?: boolean
}

export const FullscreenProcessingLoader: React.FC<FullscreenLoaderProps> = ({ name = '', progressBar = true }) => (
  <StyledLoader>
    <LoaderWrapper>
      <StyledLoaderLabel>{name ? name : 'Processing, please wait...'}</StyledLoaderLabel>
      <SpinnerImage name="spinner" />
    </LoaderWrapper>
    {progressBar ? <ProgressBar /> : null}
  </StyledLoader>
)
