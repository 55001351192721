import React from 'react'

interface AddProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const ArrowRight = ({
  style = {},
  fill = '#707070',
  width = '12px',
  className = '',
  viewBox = '0 0 10 14'
}: AddProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill={fill} d="M0.5 12.3545L6.06312 7L0.5 1.64554L2.21225 0L9.5 7L2.21225 14L0.5 12.3545Z" />
  </svg>
)

export default ArrowRight
