import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 50px;
  background-color: #171717;
  align-items: center;
  justify-content: space-between;
  z-index: 11;
  bottom: 0;
`

interface IProps {
  children: React.ReactNode
}

const Footer = (props: IProps) => {
  return <StyledContainer>{props.children}</StyledContainer>
}

export default Footer
