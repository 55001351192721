import React from 'react'
import InboxHeader from '../../../../../../../components/blocks/InboxHeader'
import useAccountInfo from '../../Common/Hooks/useAccountInfo'
import { colors } from '../../../../../../../assets/colors'
import { AccountInfoContentTitle, AccountInfoContentWrapper } from '../Styled'
import buildNumber from '../../../../../../../buildNumber'
import styled from 'styled-components'
import { ImageCropDesktop } from '../../../../../containers/ImageCropDesktop/ImageCrop'

const BuildNumber = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${colors.mediumGrey};
  span {
    margin-left: 10px;
  }
`

const AccountInfoContent = () => {
  const { user, avatar, messageCounter, onImageUpload } = useAccountInfo()

  return (
    <>
      <AccountInfoContentWrapper>
        <ImageCropDesktop saveCroppedImage={onImageUpload} element={<div style={{ cursor: 'pointer' }} />}>
          <InboxHeader.Avatar src={avatar} width="100px" fontSize="8px" />
        </ImageCropDesktop>
        <AccountInfoContentTitle>{`${user?.first_name} ${user?.last_name}`}</AccountInfoContentTitle>
        <p style={{ marginBottom: '10px', fontSize: '13px', fontWeight: 600, color: colors.darkGrey }}>
          {user?.access_numbers ? user?.access_numbers?.[0] : '000'}
        </p>
        <p style={{ marginBottom: '10px', fontSize: '13px', color: colors.mediumGrey }}>{`(${(
          (messageCounter.total * 100) /
          messageCounter.limit
        ).toFixed()}% full) ${messageCounter.total}/${messageCounter.limit}`}</p>
      </AccountInfoContentWrapper>

      <BuildNumber>
        <span>v{buildNumber}</span>
      </BuildNumber>
    </>
  )
}

export default AccountInfoContent
