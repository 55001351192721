import React from 'react'
import styled from 'styled-components'
import Clock from '../../../../../../components/Icon/Clock'
import Subtitle from '../../../../Subtitle'
import './style.css'
import { format } from 'date-fns'

interface TimePickerFieldProps {
  onChange: React.ChangeEventHandler<HTMLSelectElement> | undefined
  name: string
  subtitle: string
  value: string
  times: Date[]
  error?: boolean
  disabled?: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const SelectBox = styled.select<{ error?: boolean }>`
  background: rgba(23, 23, 23, 0.05);
  border-radius: 10px;
  border-color: ${({ error }) => (error ? '#b91c1c' : `rgba(23, 23, 23, 0.05)`)};
  width: auto;
  height: 42px;
  margin-left: 5px;
  margin-right: 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  text-align-last: center;
  -moz-text-align-last: center;
`

const TimePickerFragment = ({ name, subtitle, value, onChange, times, error, disabled }: TimePickerFieldProps) => {
  return (
    <Container>
      <Clock />
      <Subtitle>{subtitle}</Subtitle>
      <SelectBox disabled={disabled} name={name} value={value} onChange={onChange} defaultValue={null} error={error}>
        <option key={0} value={null}>
          Choose time
        </option>
        {times.map((item: Date) => {
          const date = format(item, 'p')
          return (
            <option key={item.toISOString()} value={item?.toISOString()}>
              {date}
            </option>
          )
        })}
      </SelectBox>
    </Container>
  )
}

export default TimePickerFragment
