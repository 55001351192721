import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface MessageCounter {
  new: number
  urgent: number
  total: number
  inbox: number
  saved: number
  deleted: number
  limit?: number
}

export interface MessagesState {
  messageCounter: MessageCounter
  actions: {
    setMessageCounter: (counter: MessageCounter) => void
  }
}

export const useMessagesStore = create<MessagesState>()(
  persist(
    (set) => ({
      messageCounter: {
        new: 0,
        urgent: 0,
        total: 0,
        inbox: 0,
        saved: 0,
        deleted: 0,
        limit: 0
      },
      actions: {
        setMessageCounter: (messageCounter) => set({ messageCounter })
      }
    }),
    {
      name: 'messages-storage',
      partialize: (state) => ({ messageCounter: state.messageCounter })
    }
  )
)

export const useMessagesActions = () => useMessagesStore((state) => state.actions)
