import React from 'react'
import styled from 'styled-components'
import { colors } from '../../assets/colors'

const StyledLink = styled.a`
  margin: 5px 0;
  color: ${colors.darkBlue};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #15517f;
  }

  &:active {
    color: ${colors.darkBlue};
    opacity: 0.7;
  }
`

export default StyledLink
