import styled from 'styled-components'

const SelectedMonthDay = styled.a`
  padding: 8px 5px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: white;
  background: linear-gradient(224.26deg, #3fb5e9 -23.22%, #1d70b6 123.1%);
  border-radius: 24px;
  width: 36px;
  height: 36px;
  opacity: 0.95;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

export default SelectedMonthDay
