import React from 'react'
import { colors } from '../../assets/colors'

interface ConfidentialProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const Confidential = ({
  style = {},
  fill = '#000',
  width = '30px',
  className = '',
  viewBox = '0 0 30 30'
}: ConfidentialProps) => (
  <svg width="16" height="17" style={style} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.76923 0C4.39733 0 2.46154 1.90987 2.46154 4.25V5.46429H1.23077C0.550769 5.46429 0 6.00768 0 6.67857V13.9643C0 14.6352 0.550769 15.1786 1.23077 15.1786H4.5625C4.24496 14.7675 4.04984 14.4388 3.97476 14.3046C3.78707 14.0077 3.69231 13.6892 3.69231 13.3571C3.69231 13.0092 3.79625 12.6766 4.0024 12.367C4.19933 12.0622 6.58954 8.5 10.4615 8.5C11.6295 8.5 12.6597 8.82657 13.5385 9.28739V6.67857C13.5385 6.00768 12.9877 5.46429 12.3077 5.46429H11.0769V4.25C11.0769 1.98632 9.25328 0.163058 6.98798 0.0438756C6.91838 0.0162171 6.84425 0.00134735 6.76923 0V0ZM6.76923 1.21429C8.4761 1.21429 9.84615 2.56599 9.84615 4.25V5.46429H3.69231V4.25C3.69231 2.56599 5.06236 1.21429 6.76923 1.21429ZM10.4615 9.71429C7.13846 9.71429 5.03486 13.0275 5.03486 13.0275C4.97024 13.1234 4.92308 13.2327 4.92308 13.3571C4.92308 13.4822 4.97083 13.5921 5.03606 13.688C5.03606 13.688 6.76923 17 10.4615 17C14.1538 17 15.8858 13.6892 15.8858 13.6892C15.9517 13.5926 16 13.4828 16 13.3571C16 13.2321 15.9529 13.1228 15.887 13.0263C15.887 13.0263 13.7846 9.71429 10.4615 9.71429ZM10.4615 10.9286C11.8209 10.9286 12.9231 12.016 12.9231 13.3571C12.9231 14.6983 11.8209 15.7857 10.4615 15.7857C9.10215 15.7857 8 14.6983 8 13.3571C8 12.016 9.10215 10.9286 10.4615 10.9286ZM10.4615 12.1429C9.78154 12.1429 9.23077 12.6862 9.23077 13.3571C9.23077 14.028 9.78154 14.5714 10.4615 14.5714C11.1415 14.5714 11.6923 14.028 11.6923 13.3571C11.6923 12.6862 11.1415 12.1429 10.4615 12.1429Z"
      fill={colors.mediumGrey2}
    />
  </svg>
)

export default Confidential
