import styled from 'styled-components'
import React, { ReactNode } from 'react'
import { colors } from '../../../../assets/colors'
import Header from './Header'
import ItemsList from './ItemsList'

const ContactsListWrapper = styled.div`
  flex: 1;
  min-height: 300px;
  width: 100%;
  background-color: ${colors.silver};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.875rem;
  overflow-x: auto;
`

interface ContactsListPageLayoutProps {
  children?: ReactNode
}

const ContactsList = ({ children, ...rest }: ContactsListPageLayoutProps) => (
  <ContactsListWrapper {...rest}>{children}</ContactsListWrapper>
)

ContactsList.Header = Header
ContactsList.ItemsList = ItemsList

export default ContactsList
