import styled from 'styled-components'
import { colors } from '../../../../assets/colors'
import React, { ReactNode } from 'react'
import Icon from '../../../Icon'
import Tag from './Tag'

const Container = styled.div`
  width: 100%;
  min-height: 40px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  overflow: hidden;
`
interface IProps {
  children?: ReactNode
  onClick?: () => void
}

const TagsBar = ({ children, onClick }: IProps) => {
  return (
    <Container onClick={onClick}>
      <Icon
        name={'bookmark'}
        fill={colors.mediumGrey}
        width={'30px'}
        style={{ marginRight: '5px', minWidth: '30px' }}
      />
      {children}
    </Container>
  )
}

TagsBar.Tag = Tag

export default TagsBar
