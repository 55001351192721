import React, { memo, useEffect, useState } from 'react'
import InboxHeader from '../../../../../components/blocks/InboxHeader'
import TabButton from '../../../../../components/Button/TabButton'
import Icon from '../../../../../components/Icon'
import InlineLink from '../../../../../components/Button/InlineLink'
import { shallow } from 'zustand/shallow'
import { useMessagesActions, useMessagesStore } from '../../../../../store/messages'
import { useAvatar } from '../../../../../store/user'
import { useVoiceMailCounts } from '../../../../../hooks/queries/useVoiceMailCounts'
import { isDesktop } from '../../../../../constants'

const MessagesNavigation: React.FC = () => {
  const messageCounter = useMessagesStore((state) => state.messageCounter, shallow)
  const avatar = useAvatar()
  const { data: messageCount } = useVoiceMailCounts()
  const { setMessageCounter } = useMessagesActions()

  useEffect(() => {
    if (!messageCount) return
    setMessageCounter(messageCount)
  }, [messageCount])

  return (
    <InboxHeader>
      <InboxHeader.TabButtonsContainer>
        <>
          <TabButton numberOf={messageCounter.new} text={'New'} to={'/messages/new'}>
            <Icon name="envelope" />
          </TabButton>
          <TabButton numberOf={messageCounter.inbox} text={'Inbox'} to={'/messages/inbox'}>
            <Icon name={'inbox'} />
          </TabButton>
          <TabButton numberOf={messageCounter.saved} text={'Saved'} to={'/messages/saved'}>
            <Icon name={'save'} />
          </TabButton>
          <TabButton numberOf={messageCounter.deleted} text={'Trash'} to={'/messages/deleted'}>
            <Icon name={'bin'} />
          </TabButton>
          {/*<TabButton numberOf={messageCounter.deleted} text={'Outbox'} to={'/messages/outbox'}>*/}
          {/*  <Icon name={'bin'} />*/}
          {/*</TabButton>*/}
        </>
      </InboxHeader.TabButtonsContainer>
      {!isDesktop && (
        <InlineLink to={'/options'}>
          <InboxHeader.Avatar src={avatar} />
        </InlineLink>
      )}
    </InboxHeader>
  )
}

export default memo(MessagesNavigation)
