export const stripeCreateCustomerPortalSession = `mutation StripeCreateCustomerPortalSession($returnUrl: String!) {
  stripeCreateCustomerPortalSession(input: {
      return_url: $returnUrl
  }) {
      url
  }
}`

export interface StripeCreateCustomerPortalSessionResponse {
  data: {
    stripeCreateCustomerPortalSession: {
      url: string
    }
  }
}
