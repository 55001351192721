import React from 'react'
import styled from 'styled-components'

interface IProps {
  selected: boolean
  selectedSrc: string
  notSelectedSrc: string
  selectedText: string
  notSelectedText: string | undefined
  onClick: () => void
  width?: number
  showText: boolean
  disabled?: boolean
}

interface IconContainerProps {
  selected: boolean
  width?: number
  disabled?: boolean
}

const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  min-width: ${(props) => (props.width ? props.width : 80)}px;
  max-width: ${(props) => (props.width ? props.width : 80)}px;
  opacity: ${(props) => (props.selected ? 0.92 : 0.7)};
  cursor: pointer;
  ${(props) => {
    if (!props.disabled) {
      return `
        &:hover {
          opacity: 1;
        }
        &:active {
          opacity: 0.5;
        }
      `
    }
  }}
  //&:hover {
  //  opacity: 1;
  //}
  //&:active {
  //  opacity: 0.5;
  //}
  
  @media (max-width: 599px) {
    min-width: 30px;
    max-width: 30px;
    max-height: 20px;
  }

  ${(props) => (props.disabled ? `opacity: 0.5;` : `opacity: 1;`)}
`

const StyledImg = styled.img`
  margin-bottom: 8px;
`

const StyledText = styled.a`
  font-size: 15px;
  color: white;
`

const FooterIcon = ({
  selected,
  selectedSrc,
  notSelectedSrc,
  onClick,
  selectedText,
  notSelectedText,
  width,
  showText,
  disabled
}: IProps) => (
  <IconContainer disabled={disabled} selected={selected} width={width} onClick={onClick}>
    <StyledImg width={20} height={20} src={selected ? selectedSrc : notSelectedSrc} />
    {showText ? (
      <StyledText>{selected ? selectedText : notSelectedText ? notSelectedText : selectedText}</StyledText>
    ) : null}
  </IconContainer>
)

export default FooterIcon
