import { TagType } from '../../../../types'
import TagGroup from '../../../../components/blocks/Tags/TagGroup'
import Tag from '../../../../components/blocks/Tags/Tag'
import Icon from '../../../../components/Icon'
import { colors } from '../../../../assets/colors'
import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
  background-color: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
  opacity: 0.95;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

interface Props {
  item: TagType
  onClickItem: (name: string) => void
}

const TagsGroupItemView = ({ item, onClickItem }: Props) => {
  return (
    <TagGroup.Item onClick={() => onClickItem(item?.name)}>
      <StyledButton>
        <Tag label={item?.name} count={item?.messages?.length} />
      </StyledButton>
      <StyledButton>
        <Icon name={'chevronRight'} width={'14px'} fill={colors.mediumGrey} />
      </StyledButton>
    </TagGroup.Item>
  )
}

export default TagsGroupItemView
