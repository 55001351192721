import React from 'react'
import { useHistory } from 'react-router'
import Footer from '../../components/Footer'
import FooterButton from '../../components/Footer/FooterButton'
import { colors } from '../../assets/colors'
import { useUser } from '../../store/user'

const ChangeViewFooter: React.FC = () => {
  const history = useHistory()
  const user = useUser()
  const IS_ENG_BETA_TESTER = user?.eng_beta_tester

  const checkIfActive = (path: string) => {
    if (history.location.pathname.includes(path)) {
      return colors.darkBlue
    }
    return colors.shadowGrey
  }

  const onClickNewMessage = () => {
    // @ts-ignore
    history.push(`${history.location.pathname}`, { ...history.location.state, newMessage: true, recipients: [] })
  }

  const onClickTags = () => {
    history.push('/tags')
  }

  const onClickContacts = () => {
    history.push('/contacts')
  }

  const onClickVideo = () => {
    history.push('/video-conferencing')
  }

  return (
    <Footer>
      <>
        <FooterButton
          name={'voiceMail'}
          id={'main'}
          onClick={() => history.push('/messages')}
          fill={checkIfActive('/messages')}
        />
        <FooterButton name={'microphone'} id={'newMessage'} onClick={onClickNewMessage} fill={colors.shadowGrey} />
        <FooterButton name={'userAccount'} id={'groups'} onClick={onClickContacts} fill={checkIfActive('/contacts')} />
        <FooterButton name={'bookmark'} id={'tags'} onClick={onClickTags} fill={checkIfActive('/tags')} />
        {IS_ENG_BETA_TESTER && (
          <FooterButton
            name={'movieCamera'}
            id={'video'}
            onClick={onClickVideo}
            fill={checkIfActive('/video-conferencing')}
          />
        )}
      </>
    </Footer>
  )
}

export default ChangeViewFooter
