import React from 'react'
import SendMessage from '../SendMessage'

const Forward = () => {
  return (
    <>
      <SendMessage />
    </>
  )
}

export default Forward
