import styled from 'styled-components'
import { colors } from '../../../assets/colors'
import { useUserStore } from '../../../store/user'

export const Photo = styled('div')<{ isCurrentMessage?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: ${colors.silver};
  padding: 0 10px 10px 10px;
`
