import React from 'react'

interface ChevronRightProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const ChevronRight = ({
  style = {},
  fill = '#000',
  width = '30px',
  className = '',
  viewBox = '0 0 30 30'
}: ChevronRightProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
      M12,27h-2c-0.386,0-0.738-0.223-0.904-0.572s-0.115-0.762,0.13-1.062L17.708,15L9.226,4.633 c-0.245-0.299-0.295-0.712-0.13-1.062S9.614,3,10,3h2c0.3,0,0.584,0.135,0.774,0.367l9,11c0.301,0.369,0.301,0.898,0,1.267l-9,11 C12.584,26.865,12.3,27,12,27z
      "
    />
  </svg>
)

export default ChevronRight
