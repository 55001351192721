import { client, vonageClient, vonageGuestClient } from './client'
import { MessageType, TagType } from './types'
import axios from 'axios'

type LoginRequestType = {
  identity: string
  password: string
}

type LoginResponseType = {
  refreshToken: string
  [key: string]: any
}

type VoiceMailCountsResponseType = {
  new: number
  urgent: number
  total: number
  inbox: number
  saved: number
  deleted: number
  limit: number
}

export type AccountResponseType = {
  first_name: string
  last_name: string
  account_number: string
  access_numbers: string[]
  link_account_type: string
  katemsg_api_key: string
  jwt: string
  hqa: boolean
  aws_api_endpoint: string
  phone_settings: {
    home: {
      phone_number: string
      transfer: boolean
    }
    office: {
      phone_number: string
      transfer: boolean
    }
    transfer_voice_mail: boolean
    voice_mail_greeting: boolean
    name_recording: boolean
  }
}

export type ContactAPIType = {
  id: string
  first_name: string
  last_name: string
  kate_number: string
  link_account_type?: number
  email?: string
  email2?: string
  home_fax?: string
  work_fax?: string
  home_phone?: string
  mobile_phone?: string
  work_phone?: string
  work_mobile_phone?: string
  home_street_address?: string
  home_city?: string
  home_state?: string
  home_zip?: string
  home_country?: string
  company?: string
  job_title?: string
  department?: string
  office?: string
  work_street_address?: string
  work_city?: string
  work_state?: string
  work_zip?: string
  work_country?: string
  title?: string
  gender?: string
  date_of_birth?: string
  photo_url?: string
  extra_info?: string
}

export type ContactSearchRequestType = {
  search?: string
  has?: string[]
  limit?: number
}

export type ContactSearchResponseType = {
  contacts: ContactAPIType[]
  groups: {
    name: string
    id: string
  }[]
}

export type ContactListRequestType = {
  GroupID?: string
  fields?: string[]
}

export type ContactListResponseType = ContactAPIType[]

export type ContactGroupListResponseType = {
  id: string
  name: string
}[]

export type ContactCreateRequestType = {
  first_name: string
  last_name: string
  kate_number: string
  link_account_type?: number
  email?: string
  email2?: string
  home_fax?: string
  work_fax?: string
  home_phone?: string
  mobile_phone?: string
  work_phone?: string
  work_mobile_phone?: string
  home_street_address?: string
  home_city?: string
  home_state?: string
  home_zip?: string
  home_country?: string
  company?: string
  job_title?: string
  department?: string
  office?: string
  work_street_address?: string
  work_city?: string
  work_state?: string
  work_zip?: string
  work_country?: string
  title?: string
  gender?: string
  date_of_birth?: string
  photo_url?: string
  extra_info?: string
}

export type ContactCreateResponseType = {
  id: string
}

export type ContactGroupUpdateRequestType = {
  id: string
  req: {
    name?: string
    contacts?: {
      add?: {
        id: string
        phone_numbers: string[]
      }[]
      remove?: string[]
    }
  }
}

export type PhoneReverseLookupResponseType = {
  account_number: string
  first_name: string
  last_name: string
  is_kate: boolean
  link_account_type?: number
  emails: string[]
  mobile_phone?: string
}[]

export type PhoneReverseLookupRequestType = {
  phone_number: string
  link_account_type?: number
}

export type ContactUpdateRequestType = {
  id: string
  req: ContactAPIType
}

export type ContactGroupCreateRequestType = {
  name: string
}

export type ContactGroupCreateResponseType = {
  id: string
}

export type HighQualityAudioDataType = {
  katemsg_api_key: string
  aws_api_endpoint: string
  jwt: string
  message_id: string
}

export type TagsGetRequestType = {
  tags: TagType[]
  views: TagType[]
  messages: MessageType[]
}

export type MessageTagResponse = {
  tag: string
}[]

export type MessageNoteUpdateRequestType = {
  messageUniqueId: string
  note: string
}

export type MessageUpdateTagRequestType = {
  id: string
  newTag: string
}

export type SuggestedTag = {
  tag: string
  curated?: boolean
}

export type SuggestedTagsResponse = {
  tag: string
  curated?: boolean
}[]

export type UpdatePhoneSettingsReqType = {
  jsonData: {
    voice_mail_greeting?: boolean
    name_recording?: boolean
    transfer_voice_mail?: boolean
    home?: {
      phone_number: string
      transfer: boolean
    }
    mobile?: {
      phone_number: string
      transfer: boolean
    }
    cell?: {
      phone_number: string
      transfer: boolean
    }
    office?: {
      phone_number: string
      transfer: boolean
    }
  }
}

type MoveToFolder = {
  id: string
  toFolder: string
}

type MarkMessageStatus = {
  id: string
  status: string
}

// type Events = {
//   batch: {
//     events: IEventLog[]
//   }
// }

type Attendee = {
  email: string
}

export type EventData = {
  startTime: string
  endTime: string
  attendees: Attendee[]
  description: string
  title: string
}

export type RecentMeetingData = {
  shortId: string
}

export type BillableEventData = {
  event: string
  timestampStart: number
  timestampEnd?: number
  meetingId: string
  connectionId: string
  customerIdParticipant: number
  participantName: string
  participantIsSubscriber: boolean
}

export type GuestData = {
  firstName: string
  lastName: string
  email: string
}

export type ReportApiType = {
  from: string
  to: string
}

const APIClient = {
  login(req: LoginRequestType) {
    return client.post<LoginResponseType>('/login', req, {
      headers: {
        'X-App-Version': 'pwa:dev:8.0'
      }
    })
  },
  logout() {
    return client.post('/logout')
  },
  refreshToken() {
    const verifier = localStorage.getItem('refreshToken')
    return client.post('/refreshtoken', {
      verifier
    })
  },
  ackRefreshToken(ack: string) {
    return client.post('/refreshtoken', {
      ack
    })
  },
  pwaRegister(registrationId: string) {
    return client.post(
      '/register',
      {
        registration_id: registrationId
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  },
  pwaDeregister(registrationId: string) {
    return client.post(
      '/deregister',
      {
        registration_id: registrationId
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  },
  voiceMailCounts() {
    return client.get<VoiceMailCountsResponseType>(`/kate/voicemail/counts`)
  },
  voiceMailList(messagesType: string, params?: any) {
    return client.get(`/kate/voicemail/listmessages?selectFolder=${messagesType}`, { params })
  },
  account() {
    return client.get<AccountResponseType>(`/kate/account`)
  },
  closureStatus() {
    return client.get(`/closurestatus`)
  },
  balanceNotice() {
    return client.get(`/balancenotice`)
  },
  closureReasons() {
    return client.get(`/closurereasons`)
  },
  closeAccount(req) {
    return client.post(`/requestclosure`, req)
  },
  contactSearch(req: ContactSearchRequestType) {
    return client.post<ContactSearchResponseType>(`/kate/contact/search`, req)
  },
  contactList(req?: ContactListRequestType) {
    return client.get<ContactListResponseType>('/kate/contact/list', { params: req })
  },
  contactGroupList() {
    return client.get<ContactGroupListResponseType>(`/kate/contactgroup/list`)
  },
  contactCreate(req: ContactCreateRequestType) {
    return client.post<ContactCreateResponseType>(`/kate/contact/create`, req)
  },
  contactGroupUpdate({ id, req }: ContactGroupUpdateRequestType) {
    return client.post(`/kate/contactgroup/update/${id}`, req)
  },
  contactView(id: string) {
    return client.get<ContactAPIType>(`/kate/contact/view/${id}`)
  },
  phoneReverseLookup(req: PhoneReverseLookupRequestType) {
    return client.post<PhoneReverseLookupResponseType>(`/kate/phonereverselookup`, req)
  },
  contactUpdate({ id, req }: ContactUpdateRequestType) {
    return client.post(`/kate/contact/update/${id}`, req)
  },
  contactDelete(id: string) {
    return client.post(`/kate/contact/delete/${id}`)
  },
  contactGroupCreate(req: ContactGroupCreateRequestType) {
    return client.post<ContactGroupCreateResponseType>('/kate/contactgroup/create', req)
  },
  contactGroupDelete(id: string) {
    return client.post(`/kate/contactgroup/delete/${id}`)
  },
  voiceMessageSubmit(formData: FormData) {
    return client({
      method: 'post',
      url: '/kate/voicemail/sendmessage',
      data: formData,
      headers: {
        'content-type': `multipart/form-data`
      }
    })
  },
  highQualityAudio(hqaData: HighQualityAudioDataType) {
    return client.get(hqaData.aws_api_endpoint, {
      headers: {
        'x-api-key': hqaData.katemsg_api_key,
        authorizationtoken: hqaData.jwt,
        message_id: hqaData.message_id
      }
    })
  },
  standardAudio(messageUniqueId: string) {
    return client.get(`/kate/voicemail/getaudio/id/${messageUniqueId}`, {
      responseType: 'blob'
    })
  },
  messagesWithTagsList() {
    return client.get<TagsGetRequestType>(`/kate/tag/listMessages`, {
      headers: {
        Accept: 'application/vnd.nextgenuc.kate+json;version=2',
        'Content-Type': 'application/vnd.nextgenuc.kate+json;version=2'
      }
    })
  },
  messagesItem(messageUniqueId: string, confidential = false, params?: any) {
    return client.get<MessageType>(
      `/kate/voicemail/get/id/${messageUniqueId}${confidential ? '?confidential=true' : ''}`,
      {
        params
      }
    )
  },
  moveMessagesItem({ id, toFolder }: MoveToFolder) {
    return client.post(`/kate/voicemail/move/id/${id}`, { toFolder })
  },
  markMessagesItemStatus({ id, status }: MarkMessageStatus) {
    return client.post(`/kate/voicemail/mark/id/${id}`, { status })
  },
  messageTagsGet(messageUniqueId: string) {
    return client.get<MessageTagResponse>(`/kate/voicemail/tags/id/${messageUniqueId}`, {
      headers: {
        Accept: 'application/vnd.nextgenuc.kate+json;version=2',
        'Content-Type': 'application/vnd.nextgenuc.kate+json;version=2'
      }
    })
  },
  messageAddTag(req: MessageUpdateTagRequestType) {
    return client.post(
      `/kate/tag/add?messageId=${req.id}&tag=${req.newTag.slice(1, req.newTag.length)}`,
      {},
      {
        headers: {
          Accept: 'application/vnd.nextgenuc.kate+json;version=2',
          'Content-Type': 'application/vnd.nextgenuc.kate+json;version=2'
        }
      }
    )
  },
  messageRemoveTag(req: MessageUpdateTagRequestType) {
    return client.post(
      `/kate/tag/remove?messageId=${req.id}&tag=${req.newTag.slice(1, req.newTag.length)}`,
      {},
      {
        headers: {
          Accept: 'application/vnd.nextgenuc.kate+json;version=2',
          'Content-Type': 'application/vnd.nextgenuc.kate+json;version=2'
        }
      }
    )
  },
  messageNoteUpdate({ messageUniqueId, note }: MessageNoteUpdateRequestType) {
    return client.post(`/kate/voicemail/savenote/id/${messageUniqueId}`, {
      note_text: note
    })
  },
  phoneSettingsUpdate(req: UpdatePhoneSettingsReqType) {
    return client.post(`/kate/updatephonesettings`, req.jsonData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
  },
  suggestedTagsGet() {
    return client.get<SuggestedTagsResponse>(`/kate/tag/search`, {
      headers: {
        Accept: 'application/vnd.nextgenuc.kate+json;version=2',
        'Content-Type': 'application/vnd.nextgenuc.kate+json;version=2'
      }
    })
  },
  voiceMailGreetingSave(formData: FormData) {
    return client({
      method: 'post',
      url: '/kate/savevoicemailgreeting',
      data: formData
    })
  },
  voiceMailGreetingGet() {
    return client.get<File>(`/kate/getvoicemailgreeting`, {
      responseType: 'blob'
    })
  },
  nameRecordingSave(formData: FormData) {
    return client({
      method: 'post',
      url: '/kate/savenamerecording',
      data: formData,
      headers: {
        'content-type': `multipart/form-data`
      }
    })
  },
  nameRecordingGet() {
    return client.get(`/kate/getnamerecording`, {
      responseType: 'blob'
    })
  },
  getUserPhoto() {
    return client.get('/kate/getPhoto?no_redirect=true')
  },
  setUserPhoto(formData: FormData) {
    return client({
      method: 'post',
      url: '/kate/savePhoto',
      data: formData
    })
  },
  // setUserStats(events: Events) {
  //   return client({
  //     method: 'post',
  //     url: '/kate/mobile/trackclick',
  //     data: events
  //   })
  // },
  createSession() {
    return vonageClient.post(`/vonage/session`)
  },
  createEventMeeting(eventData: EventData) {
    return vonageClient.post(`/vonage/meeting_event`, eventData)
  },
  deleteMeetingEvent(meetingId: string) {
    return vonageClient.delete(`/vonage/meeting_event/${meetingId}`)
  },
  getStatus() {
    return vonageClient.get(`/status`)
  },
  createToken(meetingUrl: string) {
    return vonageClient.post(`/conference/${meetingUrl}`)
  },
  createTokenAsGuest(meetingUrl: string) {
    return vonageGuestClient.post(`/conference/${meetingUrl}`)
  },
  getConferences(customerId: string) {
    return vonageClient.get(`/conference/eventConferences/${customerId}`)
  },
  getRecentMeeting() {
    return vonageClient.get(`/recent_meetings`)
  },
  getRecentMeetingAsGuest() {
    return vonageGuestClient.get(`/recent_meetings`)
  },
  postBillableEvent(eventData: BillableEventData[]) {
    return vonageClient.post(`/katemobile/billable_event`, eventData, {
      'axios-retry': {
        retries: 3,
        retryDelay: (counter) => {
          switch (counter) {
            case 1:
              return 2000
            case 2:
              return 4000
            case 3:
              return 8000
            default:
              return 4000
          }
        },
        retryCondition: () => true
      }
    })
  },
  postBillableEventAsGuest(eventData: BillableEventData[]) {
    return vonageGuestClient.post(`/katemobile/billable_event`, eventData, {
      'axios-retry': {
        retries: 3,
        retryDelay: (counter) => {
          switch (counter) {
            case 1:
              return 2000
            case 2:
              return 4000
            case 3:
              return 8000
            default:
              return 4000
          }
        },
        retryCondition: () => true
      }
    })
  },
  getGuestJWT(guestData: GuestData) {
    return vonageGuestClient.post(`/guest_jwt`, guestData)
  },
  getMeetingInfo(meetingUrl: string) {
    return vonageClient.get(`conference/meetingId/${meetingUrl}`)
  },
  getMeetingInfoGuest(meetingUrl: string) {
    return vonageGuestClient.get(`conference/meetingId/${meetingUrl}`)
  },
  canGetPoll(meetingUrl: string, status: string) {
    return vonageClient.post(`meeting_room/${meetingUrl}/participant`, { status: status })
  },
  canGetPollGuest(meetingUrl: string, status: string) {
    return vonageGuestClient.post(`meeting_room/${meetingUrl}/participant`, { status: status })
  },
  reportMonths() {
    return vonageClient.get(`report/months`)
  },
  getMonthReport(req: ReportApiType) {
    return vonageClient.get(`report/month`, { params: req })
  },
  startBroadcast(meetingId: string, { serverUrl, streamKey }: { serverUrl: string; streamKey: string }) {
    return vonageClient.post(`broadcast/${meetingId}`, {
      serverUrl,
      streamKey
    })
  },
  stopBroadcast(meetingId: string, broadcastId: string) {
    return vonageClient.post(`broadcast/${meetingId}/stop`, {
      broadcastId
    })
  },
  getBroadcast(meetingId: string) {
    return vonageClient.get(`broadcast/${meetingId}`)
  }
}

export default APIClient
