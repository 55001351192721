import React, { useEffect, useState } from 'react'
import { Box, Popover, Typography } from '@mui/material'
import AudioVisualizer from './AudioVisualizer'
import { colors } from '../../../../../../assets/colors'
import AudioSpeed from '../../components/MessageView/components/AudioSpeed'
import ActionButton from '../../components/MessageView/components/ActionButtons/ActionButton'
import Remove from '../../../../../../components/Icon/Remove'
import Download from '../../../../../../components/Icon/Download'
import ReplyArrow from '../../../../../../components/Icon/ReplyArrow'
import ForwardArrow from '../../../../../../components/Icon/ForwardArrow'
import Ellipsis from '../../../../../../components/Icon/Ellipsis'
import Button from '@mui/material/Button'
import ImageModal from '../../components/ImageModal'
import WaveSurfer from 'wavesurfer.js'
import { useGetMessagesById } from '../../../../../../hooks/queries/useGetMessagesById'
import { useLocation } from 'react-router'
import ImagePreview from '../../../../../../components/Icon/ImagePreview'
import styled from 'styled-components'
import Line from '../../../../components/Line'
import AudioPlayerButtons from './AudioPlayerButtons'
import { useWaveSurfer } from './audioPlayerStore'
import { useAudioMessageStore } from '../../../../../../store/audioMessage'
import { shallow } from 'zustand/shallow'

export const ImagePreviewButton = ({ onShowImage, right = '2%' }) => {
  return (
    // @ts-ignore
    <Box
      component={'div'}
      sx={{
        opacity: 0.5,
        cursor: 'pointer',
        transition: '.2s opacity ease-in-out',
        backgroundColor: colors.mediumGrey,
        ':hover': {
          opacity: 1
        }
      }}
      position={'absolute'}
      right={right}
      top={'2%'}
      borderRadius={'75%'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      width={'30px'}
      height={'30px'}
      zIndex={999}
      onClick={onShowImage}
    >
      <ImagePreview width={19} height={19} />
    </Box>
  )
}

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  opacity: 0.25;
  filter: grayscale('100%');
`

export const ImageComponent = (props) => {
  return (
    <Image
      src={props.src}
      style={{ objectFit: props.isContactPhoto && !props.isImageAttached ? 'contain' : 'cover' }}
      {...props}
    />
  )
}

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60)
  const secondsRemainder = Math.round(seconds) % 60
  const paddedSeconds = `0${secondsRemainder}`.slice(-2)
  return `${minutes}:${paddedSeconds}`
}

interface Props {
  audio?: Howl
}
const AudioPlayer = ({ audio }: Props) => {
  const [isImageModalVisible, setIsImageModalVisible] = useState(false)

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const id = params.get('id')

  const [currentTime, setCurrentTime] = useState('0:00')
  const [duration, setDuration] = useState('0:00')

  const { data: message } = useGetMessagesById(id, true)

  const src = useAudioMessageStore(
    (state) => (message?.uniqueId ? state.audioMessagesSrcList[message?.uniqueId] : null),
    shallow
  )

  const [waveSurfer] = useWaveSurfer()

  useEffect(() => {
    if (!waveSurfer) return

    const setDurationTime = (seconds) => {
      setDuration(formatTime(seconds))
    }
    const setCurrentAudioTime = (seconds) => {
      setCurrentTime(formatTime(seconds))
    }

    waveSurfer?.on('decode', setDurationTime)
    waveSurfer?.on('timeupdate', setCurrentAudioTime)

    return () => {
      waveSurfer?.un('decode', setDurationTime)
      waveSurfer?.un('timeupdate', setCurrentAudioTime)
    }
  }, [waveSurfer])

  return (
    <>
      <Box
        width={'100%'}
        height={'84%'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          '@media (min-width: 1200px)': {
            height: '68%'
          },
          '@media (min-width: 1365px)': {
            height: '69%'
          },
          '@media (min-width: 1599px)': {
            height: '72%'
          },
          '@media (min-width: 1920px)': {
            height: '76%'
          }
        }}
      >
        <Box
          width={'100%'}
          height={'100%'}
          overflow={'hidden'}
          display={'flex'}
          position={'relative'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {message?.photo_url || message?.contact?.photo_url ? (
            <Box width={'100%'} height={'100%'} position={'relative'}>
              <ImageComponent
                loading={'lazy'}
                src={message?.photo_url || message?.contact?.photo_url}
                isContactPhoto={message?.contact?.photo_url}
                isImageAttached={message?.photo_url}
              />
              <ImagePreviewButton onShowImage={() => setIsImageModalVisible(true)} />
            </Box>
          ) : null}

          <Box width={'100%'} position={'absolute'} bottom={-100}>
            <AudioVisualizer audioUrl={src} />
          </Box>
        </Box>
        <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-end'}>
          <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Box p={1}>
              <Typography color={colors.mediumGrey}>{currentTime}</Typography>
            </Box>
            <Box display={'flex'} p={1}>
              <AudioSpeed />
              <Typography color={colors.mediumGrey}>{duration}</Typography>
            </Box>
          </Box>

          <AudioPlayerButtons key={message?.uniqueId} />
        </Box>
      </Box>
      <ImageModal
        imageUrl={message?.photo_url || message?.contact?.photo_url}
        visible={isImageModalVisible}
        onClose={() => setIsImageModalVisible(false)}
      />
    </>
  )
}

export default AudioPlayer
