import React, { useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router'
import apiClient from '../../../../../../../apiClient'
import ArrowDown from '../../../../../../../components/Icon/ArrowDown'
import * as Sentry from '@sentry/react'

const Live = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f73737;
  color: #fff;
  border-radius: 15px 0px 0px 15px;
  width: 66px;
  height: 40px;
  span {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
  }
`

const CustomLiveStreamingService = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 292px;
  height: 40px;
  background: rgba(41, 41, 41, 0.4);
  border-radius: 0px 15px 15px 0px;
  box-shadow: 0px 8px 20px rgba(154, 169, 188, 0.1);
  span {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #fff;
  }
`

const StopStreaming = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #292929;
  border-radius: 15px;
  width: 174px;
  height: 40px;
  padding: 10px 20px;
  span {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: #f53939;
  }
`

const StopIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0001 18.3333C5.39771 18.3333 1.66675 14.6023 1.66675 9.99996C1.66675 5.39758 5.39771 1.66663 10.0001 1.66663C14.6024 1.66663 18.3334 5.39758 18.3334 9.99996C18.3334 14.6023 14.6024 18.3333 10.0001 18.3333ZM10.0001 16.6666C13.682 16.6666 16.6667 13.6819 16.6667 9.99996C16.6667 6.31806 13.682 3.33329 10.0001 3.33329C6.31818 3.33329 3.33341 6.31806 3.33341 9.99996C3.33341 13.6819 6.31818 16.6666 10.0001 16.6666ZM7.50008 7.49996H12.5001V12.5H7.50008V7.49996Z"
        fill="#F73838"
      />
    </svg>
  )
}

const StreamingLive = ({
  broadcastId,
  setBroadcastId
}: {
  broadcastId?: string | null
  setBroadcastId?: (v: string | null) => void
}) => {
  const history = useHistory()
  const { id: meetingId } = useParams<{ id: string }>()
  const [menu, setMenu] = useState(false)

  return (
    <>
      <div style={{ position: 'absolute', top: 10, left: 20, display: 'flex', zIndex: 10000, height: 40, width: 358 }}>
        <Live>
          <span>Live</span>
        </Live>
        <CustomLiveStreamingService>
          <span>on Custom Live Streaming Service</span>
          <div
            onClick={() => setMenu((prev) => !prev)}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: 15,
              position: 'relative',
              transform: menu ? 'rotate(180deg)' : 'rotate(0deg)',
              cursor: 'pointer'
            }}
          >
            <ArrowDown />
          </div>
          {menu && (
            <StopStreaming
              onClick={async () => {
                if (!broadcastId) return
                try {
                  await apiClient.stopBroadcast(meetingId, broadcastId)
                  setMenu(false)
                  history.push(`/conference/${meetingId}`)
                  setBroadcastId(null)
                } catch (error) {
                  console.log('Error stop broadcasting', error)
                  Sentry.captureException(error, {
                    tags: {
                      section: 'StreamingLive - stopBroadcast'
                    }
                  })
                }
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 10 }}>
                <StopIcon />
              </div>
              <span>Stop Streaming</span>
            </StopStreaming>
          )}
        </CustomLiveStreamingService>
      </div>
    </>
  )
}

export default StreamingLive
