import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { colors } from '../../assets/colors'

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0;
  color: ${colors.white};
  font-size: 0.875rem;
  cursor: pointer;
  outline: none;
  transition: opacity 200ms ease;
  padding: 0;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }
`

type TextButtonProps = {
  children?: ReactNode
  onClick?: (e: any) => void
  disabled?: boolean
  style?: {}
}

export const InlineButton = ({ children, ...props }: TextButtonProps) => (
  <StyledButton onClick={props.onClick} {...props}>
    {children}
  </StyledButton>
)
