import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import ConferenceParticipants from '../../../ConferenceParticipants'
import APIClient from '../../../../../apiClient'

interface Participant {
  email?: string
  email2?: string
  first_name?: string
  last_name?: string
  id?: string
  kate_number?: string
  groups?: any[]
  has_photo?: boolean
}

type ConferenceParticipantsProps = {
  onChangeSelectedParticipants: (selectedParticipantsEmails: Participant[]) => void
}

function useContacts() {
  const [contacts, setContacts] = useState<any[]>([])

  useEffect(() => {
    async function getContacts() {
      const result = await APIClient.contactList()
      if (result.data) setContacts(result.data)
    }

    getContacts()
  }, [])

  return useMemo(() => {
    return contacts.filter((contact) => contact.email || contact.email2)
  }, [contacts])
}

const ConferenceParticipantsContainer: FC<ConferenceParticipantsProps> = ({ onChangeSelectedParticipants }) => {
  const contacts = useContacts()

  const [selectedParticipantsIds, setSelectedParticipantsIds] = useState<string[]>([])

  const removeParticipant = (event: MouseEvent<HTMLElement>) => {
    const participantId = event.currentTarget.getAttribute('data-id')
    if (!participantId) return

    setSelectedParticipantsIds((currentParticipants) => {
      const arr = currentParticipants.filter((i) => i !== participantId)
      const participantsToAdd = contacts.filter((contact) => arr.includes(contact.id))
      onChangeSelectedParticipants(participantsToAdd)
      return arr
    })
  }

  const selectParticipant = (event: MouseEvent<HTMLElement>) => {
    const participantId = event.currentTarget.getAttribute('data-id')

    if (!participantId) return

    setSelectedParticipantsIds((currentParticipants) => {
      if (currentParticipants.includes(participantId)) {
        return currentParticipants.filter((i) => i !== participantId)
      }
      const arr =
        participantId && currentParticipants.length ? [...currentParticipants, participantId] : [participantId]
      const participantsToAdd = contacts.filter((contact) => arr.includes(contact.id))
      onChangeSelectedParticipants(participantsToAdd)
      return arr
    })
  }

  const selectedParticipants = useMemo(() => {
    return selectedParticipantsIds.map((id) => contacts.find((item) => item.id === id))
  }, [selectedParticipantsIds, contacts])

  return (
    <ConferenceParticipants
      removeParticipant={removeParticipant}
      selectParticipant={selectParticipant}
      selectedParticipantsIds={selectedParticipantsIds}
      selectedParticipants={selectedParticipants}
      contactsList={contacts}
    />
  )
}

export default ConferenceParticipantsContainer
