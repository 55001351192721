import styled from 'styled-components'

export const DialogTitle = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #171717;
`

export default DialogTitle
