import React, { FC } from 'react'
import Header from '../../../../components/Header'
import Icon from '../../../../components/Icon'
import { colors } from '../../../../assets/colors'
import TagList from '../../../../components/blocks/Tags/TagList'
import { Loader, LoaderContainer } from '../../../../components/Loader/Loader'
import TagGroup from '../../../../components/blocks/Tags/TagGroup'
import ChangeViewFooter from '../../../../containers/ChangeViewFooter'
import TagsGroupItemView from './TagsGroupItemView'
import { TagType } from '../../../../types'

interface ITagsView {
  onOptionsClick: () => void
  onTagItemClick: (name: string) => void
  loading: boolean
  smartViews: TagType[]
  personalTags: TagType[]
  tagsFromSender: TagType[]
}

const TagsView: FC<ITagsView> = ({
  onOptionsClick,
  onTagItemClick,
  loading,
  smartViews,
  personalTags,
  tagsFromSender
}) => {
  return (
    <>
      <Header>
        <Header.SideButtons />
        <Header.Title>
          <span>My Kates</span>
        </Header.Title>
        <Header.SideButtons>
          <Header.IconButton onClick={onOptionsClick}>
            <Icon name={'settings'} fill={'#fff'} width={'30px'} />
          </Header.IconButton>
        </Header.SideButtons>
      </Header>
      <TagList>
        <>
          {loading && (
            <LoaderContainer>
              <Loader fill={colors.lightGrey} />
            </LoaderContainer>
          )}
          {!loading && !smartViews?.length && !personalTags?.length && !tagsFromSender?.length && (
            <div
              style={{
                display: 'flex',
                height: '100%',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              Sorry, there is no tags.
            </div>
          )}
          {!!smartViews?.length && (
            <TagGroup>
              <TagGroup.Title>Smart Views</TagGroup.Title>
              <TagGroup.Content>
                {smartViews?.map((item) => (
                  <TagsGroupItemView key={item?.name} item={item} onClickItem={onTagItemClick} />
                ))}
              </TagGroup.Content>
            </TagGroup>
          )}

          {!!personalTags?.length && (
            <TagGroup>
              <TagGroup.Title>Personal Tags</TagGroup.Title>
              <TagGroup.Content>
                {personalTags?.map((item) => (
                  <TagsGroupItemView key={item?.name} item={item} onClickItem={onTagItemClick} />
                ))}
              </TagGroup.Content>
            </TagGroup>
          )}

          {!!tagsFromSender?.length && (
            <TagGroup>
              <TagGroup.Title>From Sender</TagGroup.Title>
              <TagGroup.Content>
                {tagsFromSender?.map((item) => (
                  <TagsGroupItemView key={item?.name} item={item} onClickItem={onTagItemClick} />
                ))}
              </TagGroup.Content>
            </TagGroup>
          )}
        </>
      </TagList>
      <ChangeViewFooter />
    </>
  )
}

export default TagsView
