import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useFormik } from 'formik'
import { colors } from '../../../../assets/colors'
import { GroupItemType } from '../../../../types'
import Header from '../../../../components/Header'
import { InlineButton } from '../../../../components/Button/InlineButton'
import Icon from '../../../../components/Icon'
import Divider from '../../../../components/Divider'
import RoundedInput from '../../../../components/Input/RoundedInput'
import Form from '../../../../components/Input/Form'
import CaptionText from '../../../../components/Field/CaptionText'
import ContactsList from '../../../../components/blocks/Contact/ContactsList/ContactsList'
import SwipeItem from '../../../../components/blocks/Message/SwipeItem'
import Contact from '../../../../components/blocks/Contact/ContactItem'
import SearchInput from '../../../../components/Input/SearchInput'
import { Loader, LoaderContainer } from '../../../../components/Loader/Loader'
import APIClient from '../../../../apiClient'
import ChangeViewButton from '../../../../components/Button/ChangeViewButton'
import { showInfoModal } from '../../../../store/modals'
import { useContactGroupListQuery } from '../../../../hooks/queries/useContactGroupListQuery'
import { useQueryClient } from '@tanstack/react-query'

const GroupCreate: React.FC = () => {
  const history = useHistory()
  const urlParams = new URLSearchParams(history.location.search)
  const search = urlParams.get('filter') || ''
  const queryClient = useQueryClient()

  const { data: contacts, isLoading } = useContactGroupListQuery(search)

  const [memberIds, setMemberIds] = useState<string[]>([])
  const [added, setAdded] = useState<{ [id: string]: boolean }>({})
  const [contactsIds, setContactsIds] = useState<string[]>([])
  const [selectMembers, setSelectMembers] = useState<boolean>(false)
  const [touched, setTouched] = useState<boolean>(false)

  const formik = useFormik({
    initialValues: { name: '', id: '' },
    onSubmit: async (values: GroupItemType) => {
      const valid = validate(values)
      if (valid) {
        try {
          const createGroupResponse = await APIClient.contactGroupCreate({ name: values.name })
          if (createGroupResponse.status >= 300)
            throw Error(`group create error ${createGroupResponse.status}, ${createGroupResponse.statusText}`)

          const updateGroupResponse = await APIClient.contactGroupUpdate({
            id: createGroupResponse.data.id,
            req: {
              contacts: {
                add: memberIds.map((memId) => {
                  return { id: memId, phone_numbers: ['kate_number'] }
                })
              }
            }
          })
          if (updateGroupResponse.status >= 300)
            throw new Error(`group update error ${updateGroupResponse.status}, ${updateGroupResponse.statusText}`)

          showInfoModal('Group created')

          await queryClient.invalidateQueries(['groups'])
          await queryClient.invalidateQueries(['contacts'])

          history.push('/groups')
        } catch (e) {
          console.log('error on group create ===', e)
          window.alert('Ups, group cannot be created')
        }
      }
    }
  })

  const memberDeleteFunction = (memberId: string) => {
    setContactsIds([...contactsIds, memberId])
    setMemberIds(memberIds.filter((memId) => memId !== memberId))
  }

  const { values, handleChange, handleSubmit, handleReset, isSubmitting } = formik

  if (selectMembers) {
    return (
      <>
        <Header>
          <Header.SideButtons>
            <InlineButton onClick={() => setSelectMembers(false)}>Back</InlineButton>
          </Header.SideButtons>
          <Header.Title>
            <span>Select contacts</span>
          </Header.Title>
          <Header.EndButtons>
            <InlineButton
              onClick={() => {
                setMemberIds([...memberIds, ...Object.keys(added).filter((addId) => added[addId])])
                setSelectMembers(false)
                setAdded({})
              }}
            >
              Done
            </InlineButton>
          </Header.EndButtons>
        </Header>
        {isLoading ? (
          <LoaderContainer>
            <Loader width={'20px'} height={'20px'} fill={colors.lightGrey} />
          </LoaderContainer>
        ) : (
          <>
            <SearchInput
              onFocus={() => setTouched(true)}
              onClick={() => {
                history.replace(`groupCreate`)
                setTouched(false)
              }}
              onChange={(e: { target: { value: string } }) => history.replace(`groupCreate&filter=${e.target.value}`)}
              showCancel={touched}
              value={search}
            />
            <ContactsList>
              <ContactsList.ItemsList>
                {contacts?.length ? (
                  contacts.map((contact) => {
                    return (
                      <div key={contact.id}>
                        <Contact
                          border={false}
                          onClick={() => setAdded({ ...added, [contact.id]: !added[contact.id] })}
                        >
                          <Contact.Avatar
                            initials={`${contact.first_name?.[0]}${contact.last_name?.[0]}`}
                            src={contact.photo_url}
                          />
                          <Contact.Info>
                            <Contact.ContactName>{`${contact.first_name} ${contact.last_name}`}</Contact.ContactName>
                            <Contact.KateNumber>{contact.kate_number}</Contact.KateNumber>
                          </Contact.Info>
                          {added[contact.id] ? <Icon name={'checkmark'} fill={colors.darkBlue} width={'20px'} /> : null}
                        </Contact>
                        <Divider marginLeft={'60px'} />
                      </div>
                    )
                  })
                ) : (
                  <ContactsList.Header>No Options</ContactsList.Header>
                )}
              </ContactsList.ItemsList>
            </ContactsList>
          </>
        )}
      </>
    )
  }

  return (
    <>
      <Header>
        <Header.SideButtons>
          <InlineButton onClick={() => history.goBack()}>Cancel</InlineButton>
        </Header.SideButtons>
        <Header.Title>
          <span>New Group</span>
        </Header.Title>
        <Header.EndButtons>
          {isSubmitting ? (
            <Loader width={'20px'} height={'20px'} fill={colors.white} style={{ left: '90%' }} />
          ) : (
            <InlineButton onClick={handleSubmit}>Save</InlineButton>
          )}
        </Header.EndButtons>
      </Header>
      <Form
        autoComplete="off"
        onReset={handleReset}
        style={{
          backgroundColor: colors.silver,
          width: '100%',
          height: '100%'
        }}
      >
        <RoundedInput
          placeholder={'add group name'}
          value={values.name}
          onChange={handleChange}
          padding={'30px 10px 10px 0'}
          name={'name'}
        />
        <Divider marginLeft={'10%'} />
        <ChangeViewButton style={{ width: '90%', marginLeft: '10%' }}>
          <Icon name={'add'} width={'30px'} fill={colors.mediumGrey} />
          <ChangeViewButton.Button onClick={() => setSelectMembers(true)}>add group members</ChangeViewButton.Button>
        </ChangeViewButton>
        <CaptionText>Swipe to Remove</CaptionText>
        <ContactsList>
          <ContactsList.ItemsList>
            {memberIds ? (
              memberIds.map((memberId) => {
                if (contacts[memberId]) {
                  return (
                    <SwipeItem
                      key={memberId}
                      autoClose
                      right={[
                        {
                          text: 'remove',
                          onPress: () => memberDeleteFunction(memberId),
                          style: { backgroundColor: colors.red, color: colors.white, overflow: 'hidden' }
                        }
                      ]}
                    >
                      <Contact>
                        <Contact.Avatar
                          initials={`${contacts[memberId].first_name[0]}${contacts[memberId].last_name[0]}`}
                          src={contacts[memberId].photo_url}
                        />
                        <Contact.Info>
                          <Contact.ContactName>{`${contacts[memberId].first_name} ${contacts[memberId].last_name}`}</Contact.ContactName>
                          <Contact.KateNumber>{contacts[memberId].kate_number}</Contact.KateNumber>
                        </Contact.Info>
                      </Contact>
                    </SwipeItem>
                  )
                }
              })
            ) : (
              <ContactsList.Header>No Options</ContactsList.Header>
            )}
          </ContactsList.ItemsList>
        </ContactsList>
      </Form>
    </>
  )
}

const validate = (values: GroupItemType) => {
  const errors = []
  if (!values.name) {
    errors.push('Name')
  }

  const errorMessage = `Please enter ${errors.join(', ')}`
  if (Object.keys(errors).length) window.alert(errorMessage)
  return !Object.keys(errors).length
}

export default GroupCreate
