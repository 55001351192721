import React from 'react'

interface SkipToStartProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const SkipToStart = ({
  style = {},
  fill = '#000',
  width = '30px',
  className = '',
  viewBox = '0 0 30 30'
}: SkipToStartProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
M 7.5 6 C 6.672 6 6 6.672 6 7.5 L 6 22.5 C 6 23.328 6.672 24 7.5 24 C 8.328 24 9 23.328 9 22.5 L 9 7.5 C 9 6.672 8.328 6 7.5 6 z M 23 6 A 1 1 0 0 0 22.40625 6.1953125 A 1 1 0 0 0 22.378906 6.2167969 L 11.460938 14.15625 A 1 1 0 0 1 11.458984 14.158203 L 11.443359 14.169922 A 1 1 0 0 0 11 15 A 1 1 0 0 0 11.449219 15.833984 L 22.40625 23.804688 A 1 1 0 0 0 23 24 A 1 1 0 0 0 24 23 A 1 1 0 0 0 24 22.996094 L 24 15 L 24 7.0039062 A 1 1 0 0 0 24 7 A 1 1 0 0 0 23 6 z
"
    />
  </svg>
)

export default SkipToStart
