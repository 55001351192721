import { useHistory } from 'react-router'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { ContactItemType } from '../../../../../../types'
import APIClient from '../../../../../../apiClient'
import { showAlertModal, showInfoModal } from '../../../../../../store/modals'
import * as Yup from 'yup'

const validate = (values: ContactItemType) => {
  const errors = []

  if (!values.first_name) {
    errors.push('First')
  }

  const errorMessage = `Please enter ${errors.join(', ')}`
  if (Object.keys(errors).length) {
    showAlertModal(errorMessage)
  }
  return !Object.keys(errors).length
}

const CreateContactSchema = Yup.object().shape({
  firstName: Yup.string().max(50, 'First name too long').required('Required field'),
  lastName: Yup.string().max(50, 'Last name too long'),
  personalEmail: Yup.string().email('Invalid email'),
  workEmail: Yup.string().email('Invalid email')
})

const useCreateContact = () => {
  const history = useHistory()
  const urlParams = new URLSearchParams(history.location.search)
  const defaultContactValues: {} | any = history.location.state

  const queryClient = useQueryClient()

  const [selectGroups, setSelectGroups] = useState<boolean>(false)
  const [lookupTouched, setLookupTouched] = useState<boolean>(false)
  const [selected, setSelected] = useState<{ [id: string]: boolean }>({})
  const [kateNumber, setKateNumber] = useState<string>('')
  const [valid, setValid] = useState<boolean>(false)
  const [isSaving, setSaving] = useState<boolean>(false)
  const [selectAccount, setSelectAccount] = useState<boolean>(false)
  const [accounts, setAccounts] = useState<{ [key: string]: any }[]>([])
  const [selectedRadioOption, setSelectedRadioOption] = useState<string | null>(null)
  const [groupSelectionVisible, setGroupSelectionVisible] = useState(false)

  const parseNumber = useCallback((value: string) => {
    const re = /[\D+\W+]/
    const matchingRe = /^\(\d{3}\) \d{3}-\d{4}$/
    if (value.length === 10 && !value.match(re)) {
      setValid(true)
      return `(${value[0]}${value[1]}${value[2]}) ${value[3]}${value[4]}${value[5]}-${value[6]}${value[7]}${value[8]}${value[9]}`
    }
    if (value.match(matchingRe)) {
      setValid(true)
    } else {
      setValid(false)
    }

    return value
  }, [])

  const formik = useFormik({
    initialValues: {
      id: 'new',
      first_name: '',
      last_name: '',
      kate_number: ''
    },
    onSubmit: async (values: ContactItemType) => {
      const valid = validate(values)
      if (valid) {
        setSaving(true)
        try {
          // @ts-ignore
          delete values.id
          const groupsToAdd = values.groups
          delete values.groups
          const createResponse = await APIClient.contactCreate(values)
          if (createResponse.status >= 300)
            throw Error(`contact create error ${createResponse.status}, ${createResponse.statusText}`)

          // adding contact to all selected groups
          groupsToAdd &&
            (await Promise.all(
              groupsToAdd.map(async (group) => {
                const updateResponse = await APIClient.contactGroupUpdate({
                  id: group.id,
                  req: {
                    name: group.name,
                    contacts: {
                      add: [
                        {
                          id: createResponse.data.id,
                          phone_numbers: ['kate_number']
                        }
                      ]
                    }
                  }
                })
                if (updateResponse.status > 300)
                  throw new Error(`group update error ${updateResponse.status}, ${updateResponse.statusText}`)
              })
            ))

          showInfoModal('Contact added')

          await queryClient.invalidateQueries(['contacts'])
          await queryClient.invalidateQueries(['groups'])

          if (createResponse.data?.id) {
            history.push(`/contacts?id=${createResponse.data?.id}`)
          }
        } catch (e) {
          console.log('Error on save ===', e)
          showAlertModal('Ups, unable to create contact')
          history.push('/contacts')
        } finally {
          setSaving(false)
        }
      }
    }
  })

  const { values, handleChange, handleBlur, handleSubmit, handleReset, isSubmitting, setValues, setFieldValue } = formik

  const findUser = useCallback(async () => {
    setSaving(true)
    if (valid) {
      try {
        const lookupResponse = await APIClient.phoneReverseLookup({ phone_number: kateNumber })
        if (!lookupResponse.data) throw new Error(`${lookupResponse.status}, ${lookupResponse.statusText}`)
        if (lookupResponse.data.length > 1) {
          setAccounts(lookupResponse.data)
          setSelectedRadioOption(lookupResponse.data?.[0]?.account_number)
          setSelectAccount(true)
        } else {
          const newValues = {
            id: 'new',
            first_name: lookupResponse.data[0].first_name,
            last_name: lookupResponse.data[0].last_name,
            email2: lookupResponse.data[0].emails[0],
            kate_number: kateNumber
          }
          setValues(newValues)
        }
      } catch (e) {
        console.log('error on user lookup ===', e)
        showAlertModal(
          "It appears that this is not a CommuniKate number. We've moved the number to the Mobile Phone field."
        )
        setFieldValue('mobile_phone', kateNumber)
        setFieldValue('kate_number', '')
        setKateNumber('')
      }
    }
    setSaving(false)
  }, [kateNumber, valid])

  const chooseAccount = useCallback(
    (account) => {
      setSaving(true)
      const newValues = {
        id: account.account_number,
        first_name: account.first_name,
        last_name: account.last_name,
        email2: account.emails[0],
        kate_number: kateNumber
      }
      setValues(newValues)
      setSelectAccount(false)
      setSaving(false)
    },
    [kateNumber]
  )

  const createMany = async () => {
    setSaving(true)
    setSelectAccount(false)
    if (formik.dirty) {
      formik.resetForm({ values: formik.values })
    }
    try {
      await Promise.all(
        accounts.map(async (account) => {
          const accountValues = {
            first_name: account.first_name,
            last_name: account.last_name,
            email2: account.emails[0],
            kate_number: kateNumber
          }
          const createResponse = await APIClient.contactCreate(accountValues)
          if (createResponse.status >= 300) {
            throw Error(`contact edit error ${createResponse.status}, ${createResponse.statusText}`)
          }

          await queryClient.invalidateQueries(['contacts'])
        })
      )

      showInfoModal('Contacts created')
      setSaving(false)
      history.push('/contacts')
    } catch (e) {
      console.log('error on Contact create ===', e)
      showAlertModal('Contact could not be created')
      setSaving(false)
    }
  }

  // set form data from route state
  useEffect(() => {
    if (!defaultContactValues) return
    const mobilePhone = defaultContactValues.source || ''
    const kateAccount: { id?: string; first_name?: string; last_name?: string; link_account_type?: string } | null =
      defaultContactValues.source_kate_account || null

    // abort when no source
    if (!mobilePhone) return

    // set form values when kate account detected
    if (kateAccount) {
      const newValues = {
        id: 'new',
        first_name: kateAccount.first_name,
        last_name: kateAccount.last_name,
        kate_number: ''
      }
      setValues(newValues)
    }

    setKateNumber(parseNumber(mobilePhone))
  }, [defaultContactValues])

  // Find user by kate number
  useEffect(() => {
    if (!kateNumber) return
    findUser()
  }, [kateNumber])

  return {
    lookupTouched,
    selectGroups,
    selectAccount,
    findUser,
    setKateNumber,
    parseNumber,
    formik,
    accounts,
    setSelectedRadioOption,
    selectedRadioOption,
    setSelectAccount,
    createMany,
    chooseAccount,
    isSaving,
    groupSelectionVisible,
    setGroupSelectionVisible
  }
}

export default useCreateContact
