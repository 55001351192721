import styled, { StyledComponentBase } from 'styled-components'
import { Link } from 'react-router-dom'
import { colors } from '../../../../../../assets/colors'

interface IStyledContactInfo {
  StyledContactInfoButton: StyledComponentBase<'button', any>
  StyledContactName: StyledComponentBase<'p', any>
  StyledContactNumber: StyledComponentBase<'p', any>
  StyledContactEmail: StyledComponentBase<'p', any>
  StyledNotesWrapper: StyledComponentBase<'div', any>
  StyledEditLink: StyledComponentBase<'a', any>
}

const StyledContactInfoButton = styled.button`
  border-radius: 10px;
  background-color: rgba(29, 112, 182, 0.1);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 5px;

  &:hover {
    background-color: rgba(29, 112, 182, 0.2);
  }
`

const StyledContactName = styled.p`
  margin-top: 10px;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
`
const StyledContactNumber = styled.p`
  margin-top: 10px;
  font-size: 13px;
  font-weight: 600;
  height: 13px;
`
const StyledContactEmail = styled.p`
  margin-top: 10px;
  font-size: 13px;
  color: ${colors.mediumGrey};
  height: 13px;
`

const StyledNotesWrapper = styled.div`
  width: 100%;
  height: 90px;
  border-top: 1px solid rgba(112, 112, 112, 0.2);
  padding-top: 10px;
  font-size: 13px;
  color: ${colors.mediumGrey};
  overflow: hidden;
`

const StyledEditLink = styled(Link)`
  height: 30px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${colors.darkBlue};
  border-radius: 10px;
  padding: 5px 10px;

  &:hover {
    background-color: rgba(29, 112, 182, 0.1);
  }
`

export const StyledContactInfo: IStyledContactInfo = {
  StyledContactInfoButton,
  StyledContactName,
  StyledContactNumber,
  StyledContactEmail,
  StyledNotesWrapper,
  StyledEditLink
}
