import styled from 'styled-components'
import { colors } from '../../assets/colors'
import { HTMLAttributes } from 'react'

interface IModalButton extends HTMLAttributes<HTMLButtonElement> {
  color?: string
  bold?: boolean
}

export const ModalButton = styled.button<IModalButton>`
  width: 100%;
  padding: 10px 30px;
  margin: 0;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  background: transparent;
  border: none;
  outline: none;
  color: ${(props) => (props.color ? props.color : colors.contrastBlue)};
  font-size: 1rem;
  text-align: center;
  cursor: pointer;

  &:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
  }
`
