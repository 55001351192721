import styled from 'styled-components'
import React from 'react'

const About = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export default About
