import React, { useCallback, useEffect, useMemo } from 'react'
import MultiSelectInput from '../../../../../components/Input/MultiSelectInput'
import { useLocation } from 'react-router-dom'
import Icon from '../../../../../components/Icon'
import { colors } from '../../../../../assets/colors'
import Input from '../../../../../components/Input'
import BoolButton from '../../../../../components/Button/BoolButton'
import PhotoCapture from '../../../../../components/PhotoCapture'
import Message from '../../../../../components/blocks/Message'
import AvatarPlaceholder from '../../../../../assets/images/avatar_placeholder.png'
import IconButton from '../../../../../components/Header/IconButton'
import ImageField from '../../../../../components/Field/ImageField'
import { FullscreenProcessingLoader } from '../../../../../components/Loader/FullscreenProcessingLoader'
import Form from '../../../../../components/Input/Form'
import styled from 'styled-components'
import { useNewMessageActions, useNewMessageStore } from '../store/NewMessageStore'
import { useGetMessagesById } from '../../../../../hooks/queries/useGetMessagesById'

export const convertTimestampToDate = (ts: number) => new Date(ts * 1000).toDateString()

const StyledButton = styled.div`
  cursor: pointer;
  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

const NewMessageForm = ({ formik, recipientsActions }) => {
  const location = useLocation<{ forwardId?: string }>()
  const { handleReset, handleChange, handleBlur, isSubmitting, values } = formik
  const { onSelectedRecipient, onRemoveRecipient, onSearchRecipient, recipientsOptions, recipients } = recipientsActions

  const isUrgent = useNewMessageStore((state) => state.isUrgent)
  const isConfidential = useNewMessageStore((state) => state.isConfidential)
  const image = useNewMessageStore((state) => state.image)

  const { setImageFile, setIsUrgent, setImage, setSelectRecipientsModal, setIsConfidential } = useNewMessageActions()
  const forwardId = useMemo(() => location.state && location.state.forwardId, [location.state])
  const { data: forwardMessage } = useGetMessagesById(forwardId)

  // adding image
  const createImage = useCallback((file: any) => {
    setImageFile(file)
    const blob = URL.createObjectURL(file)
    setImage(blob)
  }, [])

  useEffect(() => {
    return () => {
      formik.resetForm()
    }
  }, [])

  return (
    <Form autoComplete="off" onReset={handleReset}>
      <MultiSelectInput
        onSelect={onSelectedRecipient}
        onRemove={onRemoveRecipient}
        onSearchQuery={onSearchRecipient}
        options={recipientsOptions}
        values={recipients}
        rightArea={
          <StyledButton
            onClick={() => {
              setSelectRecipientsModal(true)
            }}
          >
            <Icon
              name={'add'}
              fill={colors.mediumGrey}
              style={{
                width: '25px',
                height: '25px',
                marginRight: '3px'
              }}
            />
          </StyledButton>
        }
      />
      <Input>
        <Input.Label>Subject:</Input.Label>
        <Input.StyledInput
          tabIndex={-1}
          autoComplete="off"
          name="note"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.note}
        />
      </Input>
      <div style={{ background: colors.silver, padding: 10 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <BoolButton
              style={{
                paddingRight: 10,
                borderColor: isUrgent ? colors.red2 : colors.mediumGrey2,
                backgroundColor: isUrgent ? colors.red2 : 'transparent'
              }}
              name={'important'}
              activeName={'important'}
              label={'Urgent'}
              onClick={() => setIsUrgent(!isUrgent)}
              isActive={isUrgent}
            />
            <BoolButton
              style={{
                marginLeft: 10,
                paddingRight: 10,
                borderColor: colors.mediumGrey2,
                backgroundColor: isConfidential ? colors.mediumGrey2 : 'transparent'
              }}
              iconStyle={{ margin: 5 }}
              name={'confidential'}
              activeName={'confidentialFilled'}
              label={'Private'}
              onClick={() => setIsConfidential(!isConfidential)}
              isActive={isConfidential}
            />
          </div>
          {!image && (
            <PhotoCapture element={<div />} onCapturedImage={createImage}>
              <StyledButton>
                <Icon name={'camera'} width={'30px'} fill={colors.mediumGrey} />
              </StyledButton>
            </PhotoCapture>
          )}
        </div>
        {forwardMessage && (
          <Message key={forwardMessage?.uniqueId} noBorder>
            <Message.Status />
            {forwardMessage?.contact &&
            forwardMessage?.contact?.['has_photo'] &&
            forwardMessage?.contact?.['photo_url'] ? (
              <Message.Avatar src={forwardMessage?.contact?.['photo_url']} />
            ) : (
              <Message.Avatar src={AvatarPlaceholder} />
            )}
            <Message.Main>
              <Message.About>
                <Message.ContactName>
                  {forwardMessage?.contact &&
                    `${forwardMessage?.contact?.first_name} ${forwardMessage?.contact?.last_name}`}
                </Message.ContactName>
                <Message.Note>
                  {forwardMessage?.note
                    ? forwardMessage?.note
                    : forwardMessage?.timestamp && convertTimestampToDate(forwardMessage?.timestamp)}
                </Message.Note>
              </Message.About>
            </Message.Main>
          </Message>
        )}
        {image && (
          <div style={{ padding: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '150px' }}>
              <IconButton onClick={() => setImage(null)}>
                <Icon name={'delete'} width={'20px'} fill={colors.mediumGrey} />
              </IconButton>
            </div>
            <ImageField src={image} alt={'uploaded_image'} />
          </div>
        )}
      </div>
      {isSubmitting && <FullscreenProcessingLoader />}
    </Form>
  )
}

export default NewMessageForm
