import React, { SVGProps } from 'react'

interface ClockProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const Clock = ({ style = {}, fill = '#1D70B6', width = '16px', className = '', viewBox = '0 0 16 16' }: ClockProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M7.318.5c-.376 0-.682.28-.682.625v.745C3.152 2.46.5 5.263.5 8.625.5 12.415 3.866 15.5 8 15.5s7.5-3.085 7.5-6.875c0-3.362-2.652-6.165-6.136-6.755v-.745C9.364.78 9.058.5 8.682.5H7.318Zm6.13 1.244a.727.727 0 0 0-.38.106.64.64 0 0 0-.252.284.577.577 0 0 0-.035.363.612.612 0 0 0 .191.32l.682.625a.735.735 0 0 0 .974.01.624.624 0 0 0 .15-.207.58.58 0 0 0-.006-.484.627.627 0 0 0-.154-.203l-.681-.625a.686.686 0 0 0-.224-.14.735.735 0 0 0-.265-.05ZM8 3c3.397 0 6.136 2.511 6.136 5.625S11.397 14.25 8 14.25c-3.397 0-6.136-2.511-6.136-5.625S4.603 3 8 3Zm-.01 1.241a.713.713 0 0 0-.48.19.598.598 0 0 0-.192.444v2.67c-.207.11-.379.267-.498.456-.12.19-.183.405-.184.624 0 .22.064.435.183.624.12.19.292.348.5.458v.793a.58.58 0 0 0 .048.242.623.623 0 0 0 .147.206.688.688 0 0 0 .223.137.737.737 0 0 0 .526 0 .688.688 0 0 0 .223-.137.623.623 0 0 0 .147-.206.58.58 0 0 0 .049-.242v-.795c.207-.11.379-.267.498-.456.12-.19.183-.405.184-.624 0-.22-.064-.435-.183-.624a1.313 1.313 0 0 0-.5-.458V4.875a.58.58 0 0 0-.05-.244.624.624 0 0 0-.15-.207.69.69 0 0 0-.225-.137.737.737 0 0 0-.267-.046Z"
      fill={fill}
    />
  </svg>
)

export default Clock
