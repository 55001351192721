import React from 'react'
import styled from 'styled-components'
import { colors } from '../../assets/colors'

const EndButtons = styled.div`
  display: flex;
  height: 100%;
  width: 80px;
  align-items: center;
  justify-content: flex-end;
  color: ${colors.white};
`

export default EndButtons
