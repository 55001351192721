import styled from 'styled-components'
import { colors } from '../../../assets/colors'

const Label = styled.span`
  font-size: 14px;
  color: ${colors.mediumGrey};
  margin: 10px;
`

export default Label
