import styled from 'styled-components'

const Panels = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 30px;
`

export default Panels
