import React from 'react'
import { StyledPaymentOptions } from '../Styled'
import CustomCCInput from '../Components/CustomCCInput'
import useIsMobile from '../../../Shared/Common/Hooks/useIsMobile'
import { StripePaymentMethodResponse } from '../../../../../../../gql/queries/getStripeCustomerPaymentMethodsQuery'
import PreferredLabel from '../Components/PreferredLabel'
import { ToastContainer } from 'react-toastify'
import useCurrentActionTab from '../../Common/Hooks/useCurrentStatusTab'
import CardImg from '../Components/CardImg'
import useGetDefaultPayment from '../../Common/Hooks/useGetDefaultPayment'

interface IProps {
  cardsData: StripePaymentMethodResponse
}

const ExistingCardsView = (props: IProps) => {
  const isMobile = useIsMobile()
  useCurrentActionTab()
  const { data: defaultPaymentData, loading: defaultPaymentDataLoading } = useGetDefaultPayment()

  return (
    <div style={{ paddingTop: '32px' }}>
      <p
        style={{
          position: 'absolute',
          left: isMobile ? '20px' : '50px',
          top: isMobile ? '20px' : '50px',
          display: 'flex',
          alignItems: 'center',
          color: '#171717',
          fontSize: '15px',
          fontWeight: 600
        }}
      >
        Current payment method on your account
      </p>
      <h6 style={{ textAlign: 'center', marginBottom: '32px', color: '#171717' }}>Manage payment info</h6>
      {props.cardsData?.data?.map((existingCard, key) => {
        const isDefaultPayment =
          defaultPaymentData?.data?.getStripeCustomer?.invoice_settings?.default_payment_method === existingCard?.id
        return (
          <StyledPaymentOptions.ExistingCardRow key={key} isDefaultPayment={isDefaultPayment}>
            <CardImg brand={existingCard?.card?.brand} />
            <CustomCCInput ccNumber={`**** **** **** ${existingCard.card.last4}`} />
            <PreferredLabel isDefaultPayment={isDefaultPayment} loading={defaultPaymentDataLoading} />
          </StyledPaymentOptions.ExistingCardRow>
        )
      })}
      <StyledPaymentOptions.StyledLinkWithIcon
        to={isMobile ? '/billing?tab=payment-options&action=add' : '/options/billing?tab=payment-options&action=add'}
      >
        <span style={{ fontSize: '20px', marginRight: '5px' }}>+</span> Change Payment Method
      </StyledPaymentOptions.StyledLinkWithIcon>
      <ToastContainer theme="light" position="bottom-right" autoClose={5000} />
    </div>
  )
}

export default ExistingCardsView
