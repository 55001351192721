import { Redirect, Route, Switch } from 'react-router-dom'
import MessagesPage from '../mobile/pages/Messages'
import ContactShowPage from '../mobile/pages/Contacts/ContactShow'
import GroupEdit from '../mobile/pages/Groups/GroupEdit'
import GroupCreate from '../mobile/pages/Groups/GroupCreate'
import ContactCreate from '../mobile/pages/Contacts/ContactCreate'
import ContactEdit from '../mobile/pages/Contacts/ContactEdit'
import TagsPage from '../mobile/pages/Tags'
import TagMessagesPage from '../mobile/pages/TagMessages'
import MessageShowPage from '../mobile/pages/MessageShow/Message'
import TermsConditions from '../shared/TermsConditions'
import VideoPage from '../../VideoConferencing/pages/Video'
import MeetingRoom from '../../VideoConferencing/pages/MeetingRoom'
import VideoConferencing from '../../VideoConferencing/pages/VideoConferencing'
import Reports from '../../VideoConferencing/pages/Reports'
import MonthReport from '../../VideoConferencing/pages/MonthReport'
import StreamConference from '../../VideoConferencing/pages/StreamConference'
import NewMessage from '../mobile/pages/NewMessage'
import Modal from '../../components/Modal'
import AudioProvider from '../../containers/Audio'
import AutoPlayContainer from '../../containers/AutoPlay'
import React from 'react'
import { useModalsStore } from '../../store/modals'
import { shallow } from 'zustand/shallow'
import MobileOptionsPage from '../mobile/pages/Options'
import MobileBilling from '../mobile/pages/Options/Billing'
import MobileContactPage from '../mobile/pages/Contacts'
import MobileGroupsPage from '../mobile/pages/Groups'
import CallForwardingPage from '../mobile/pages/Options/CallForwarding'
import GreetingPage from '../mobile/pages/Options/Greeting'
import NameRecordingPage from '../mobile/pages/Options/NameRecording'
import FeedbackPage from '../mobile/pages/Options/Feedback'
import Chat from '../../Chat'
import { useUser } from '../../store/user'

export const MobileRouter = () => {
  const successModal = useModalsStore((state) => state.info, shallow)
  const user = useUser()
  const IS_ENG_BETA_TESTER = user?.eng_beta_tester

  return (
    <>
      <Switch>
        <Route path="/messages" component={MessagesPage} />
        <Route path="/options" component={MobileOptionsPage} />
        <Route path="/billing" component={MobileBilling} />
        <Route path="/contacts" component={MobileContactPage} />
        <Route path="/contactShow" component={ContactShowPage} />
        <Route path="/groups" component={MobileGroupsPage} />
        <Route path="/groupEdit" component={GroupEdit} />
        <Route path="/groupCreate" component={GroupCreate} />
        <Route path="/contactCreate" component={ContactCreate} />
        <Route path="/contactEdit" component={ContactEdit} />
        {/*<Route path="/billing/account-balance" component={AccountBalance} />*/}
        <Route path="/callForwarding" component={CallForwardingPage} />
        <Route path="/greeting" component={GreetingPage} />
        <Route path="/nameRecording" component={NameRecordingPage} />
        <Route path="/feedback" component={FeedbackPage} />
        <Route exact path="/tags" component={TagsPage} />
        <Route path="/tags/messages" component={TagMessagesPage} />
        <Route path="/message/:id" component={MessageShowPage} />
        <Route path="/termsAndConditions" component={TermsConditions} />
        {IS_ENG_BETA_TESTER && <Route path="/video-conferencing" component={VideoConferencing} />}
        {IS_ENG_BETA_TESTER && <Route exact path="/conference" component={VideoPage} />}
        {IS_ENG_BETA_TESTER && <Route path="/meeting" component={MeetingRoom} />}
        {IS_ENG_BETA_TESTER && <Route path="/reports" component={Reports} />}
        {IS_ENG_BETA_TESTER && <Route path="/report/:from/:to" component={MonthReport} />}
        {IS_ENG_BETA_TESTER && <Route path="/stream-conference/:meetingId" component={StreamConference} />}
        {IS_ENG_BETA_TESTER && <Route path="/chat" component={Chat} />}

        <Route path="*">
          <Redirect to={'/messages'} />
        </Route>
      </Switch>
      <Route path={`/`} component={NewMessage} />

      <Modal show={successModal.visible}>
        <Modal.IconInfo text={successModal.message} icon={'checkmark'} />
      </Modal>
    </>
  )
}
