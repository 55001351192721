import React, { SVGProps } from 'react'

const TagSparkles = (props: SVGProps<SVGSVGElement>) => (
  <svg width="86" height="79" viewBox="0 0 86 79" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37.7902 54.7214L37 54.0299L36.2098 54.7214L25.2 64.3554V31.2569C25.2038 30.9812 25.2603 30.7096 25.3657 30.4575C25.4718 30.2036 25.625 29.9754 25.8154 29.7851C26.0057 29.5948 26.2293 29.4462 26.4727 29.3464C26.7161 29.2466 26.9754 29.1971 27.236 29.2001L27.236 29.2002H27.25H46.75V29.2003L46.764 29.2001C47.0246 29.1971 47.2839 29.2466 47.5273 29.3464C47.7707 29.4462 47.9943 29.5948 48.1846 29.7851C48.375 29.9754 48.5282 30.2036 48.6343 30.4575C48.7397 30.7095 48.7962 30.9812 48.8 31.2568V64.3554L37.7902 54.7214ZM49.1317 64.6457L49.1313 64.6453L49.1317 64.6457Z"
      stroke="#D5D5D7"
      strokeWidth="2.4"
    />
    <path
      opacity="0.3"
      d="M86 10L78.5 7.5L76 0L75.0909 9.08909L66 10L73.5 12.5L76 20L76.9091 10.9073L86 10Z"
      fill="#8E8E93"
    />
    <path opacity="0.3" d="M76 0L73.5 7.5L66 10L76 9.99818V0Z" fill="#8E8E93" />
    <path opacity="0.3" d="M76 19.9999L78.5 12.4999L86 9.99986L76 9.99805V19.9999Z" fill="#1D70B6" />
    <path
      opacity="0.3"
      d="M63.9961 72.9988L59.4974 71.4989L57.998 67L57.331 72.3328L52 72.9988L56.4987 74.4986L57.998 78.9976L58.6638 73.6661L63.9961 72.9988Z"
      fill="#8E8E93"
    />
    <path opacity="0.3" d="M57.998 78.9978L59.4974 74.4989L63.9961 72.999H57.998V78.9978Z" fill="#8E8E93" />
    <path opacity="0.3" d="M52 72.9988L56.4987 71.4989L57.998 67V72.9988H52Z" fill="#1D70B6" />
    <path
      opacity="0.3"
      d="M17.9995 27L11.1892 24.8109L9.00003 18L8.437 26.437L0 27L6.81091 29.1891L9.00003 36.0001L9.56193 27.5619L17.9995 27Z"
      fill="#8E8E93"
    />
    <path
      opacity="0.3"
      d="M8.99977 36.0012L11.189 29.1898L17.9998 27.0006L8.99921 27L8.99977 36.0012Z"
      fill="#8E8E93"
    />
    <path opacity="0.3" d="M9.00063 18L6.81136 24.8114L0 27.0006L9.00007 27.0001L9.00063 18Z" fill="#8E8E93" />
    <path
      d="M53.7902 42.7214L53 42.0299L52.2098 42.7214L41.2 52.3554V19.2569C41.2038 18.9812 41.2603 18.7096 41.3657 18.4575C41.4718 18.2036 41.625 17.9754 41.8154 17.7851C42.0057 17.5948 42.2293 17.4462 42.4727 17.3464C42.7161 17.2466 42.9754 17.1971 43.236 17.2001L43.236 17.2002H43.25H62.75V17.2003L62.764 17.2001C63.0246 17.1971 63.2839 17.2466 63.5273 17.3464C63.7707 17.4462 63.9943 17.5948 64.1846 17.7851C64.375 17.9754 64.5282 18.2036 64.6343 18.4575C64.7397 18.7095 64.7962 18.9812 64.8 19.2568V52.3554L53.7902 42.7214ZM65.1317 52.6457L65.1313 52.6453L65.1317 52.6457Z"
      stroke="#1D70B6"
      strokeWidth="2.4"
    />
  </svg>
)

export default TagSparkles
