import React from 'react'

const SVG = ({ style = {}, fill = '#000', width = '50pt', className = '', viewBox = '0 0 128 128' }) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    fill={fill}
    version="1.1"
  >
    <g id="surface8143921">
      <path
        style={{
          fillRule: 'nonzero',
          fill,
          fillOpacity: 1,
          strokeWidth: 5,
          strokeLinecap: 'butt',
          strokeLinejoin: 'round',
          stroke: fill,
          strokeOpacity: 1,
          strokeMiterlimit: 10
        }}
        d="M64,117c-29.2,0-53-23.8-53-53s23.8-53,53-53c29.2,0,53,23.8,53,53S93.2,117,64,117z M64,17c-25.9,0-47,21.1-47,47s21.1,47,47,47s47-21.1,47-47S89.9,17,64,17z"
      />

      <path
        style={{
          fillRule: 'nonzero',
          fill,
          fillOpacity: 1,
          strokeWidth: 2,
          strokeLinecap: 'butt',
          strokeLinejoin: 'round',
          stroke: fill,
          strokeOpacity: 1,
          strokeMiterlimit: 10
        }}
        d="M56.5,82c-0.5,0-1-0.1-1.5-0.4c-0.9-0.5-1.5-1.5-1.5-2.6V49c0-1.1,0.6-2.1,1.5-2.6c0.9-0.5,2.1-0.5,3,0l26,15c0.9,0.5,1.5,1.5,1.5,2.6s-0.6,2.1-1.5,2.6l-26,15C57.5,81.9,57,82,56.5,82z M59.5,54.2v19.6l17-9.8L59.5,54.2z"
      />
    </g>
  </svg>
)

export default SVG
