import React, { CSSProperties } from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 95px;
  max-width: 95px;
  @media (max-width: 360px) {
    width: 64px;
    max-width: 64px;
  }
  justify-content: center;
  align-items: center;
`

const StyledAvatar = styled.img`
  vertical-align: middle;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 3px;
  object-fit: cover;
  @media (max-width: 360px) {
    width: 40px;
    height: 40px;
  }
`

const StyledText = styled.a`
  margin-top: 5px;
  font-size: 14px;
  @media (max-width: 360px) {
    font-size: 11px;
  }
  text-align: center;
  height: 20px;
  white-space: nowrap;
`

interface IProps {
  src: string
  alt: string
  name: string
  style?: CSSProperties
}

const Avatar = (props: IProps) => {
  return (
    <StyledContainer>
      <StyledAvatar src={props.src} alt={props.alt} style={props?.style || {}} />
      <StyledText>{props.name.length > 12 ? props.name.substring(0, 10) + '...' : props.name}</StyledText>
    </StyledContainer>
  )
}

export default Avatar
