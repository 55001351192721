import styled from 'styled-components'
import { colors } from '../../../assets/colors'
import { HTMLAttributes } from 'react'

interface IModalAction extends HTMLAttributes<HTMLButtonElement> {
  color?: string
  bold?: boolean
}

export const ModalAction = styled.button<IModalAction>`
  width: 100%;
  min-height: 50px;
  padding: 10px 30px;
  margin: 0;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  background: transparent;
  border: none;
  outline: none;
  color: ${(props) => (props.color ? props.color : colors.contrastBlue)};
  font-size: 1rem;
  text-align: center;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
`
