import styled from 'styled-components'
import { colors } from '../../../assets/colors'

const Button = styled.button`
  border: 0;
  background-color: transparent;
  padding: 0 0 0 2px;
  outline: 0;
  color: ${colors.black};
  outline: none;
  width: 100%;
  font-size: 14px;
  display: flex;
`

export default Button
