import React from 'react'
import styled from 'styled-components'
import { colors } from '../../assets/colors'

const SideButtons = styled.div`
  display: flex;
  height: 100%;
  width: 80px;
  align-items: center;
  color: ${colors.white};
  justify-content: flex-start;

  &:last-child {
    justify-content: flex-end;
  }
`

export default SideButtons
