import React from 'react'

interface ScreenShareProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const ScreenShare = ({
  style = {},
  fill = '#000',
  width = '24px',
  className = '',
  viewBox = '0 0 1024 1024'
}: ScreenShareProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
      M853.333333 768c46.933333 0 84.906667-38.4 84.906667-85.333333L938.666667 256c0-47.36-38.4-85.333333-85.333334-85.333333H170.666667c-47.36 0-85.333333 37.973333-85.333334 85.333333v426.666667c0 46.933333 37.973333 85.333333 85.333334 85.333333H0v85.333333h1024v-85.333333h-170.666667z m-298.666666-150.613333v-93.44c-118.613333 0-196.693333 36.266667-256 116.053333 23.893333-113.92 90.026667-227.413333 256-250.453333V298.666667l170.666666 159.146666-170.666666 159.573334z
      "
    />
  </svg>
)

export default ScreenShare
