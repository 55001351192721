import styled from 'styled-components'

const BasicInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: space-between;
  align-items: center;
`

export default BasicInfoContainer
