import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface IProps {
  children: ReactNode
  height?: number
}

const Container = styled.div`
  max-width: 640px;
  width: 640px;
  height: 480px;
  border-radius: 10px;
  background: #292929;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 1.5;
`

const WebcamContainer = ({ children }: IProps) => <Container>{children}</Container>

export default WebcamContainer
