import styled from 'styled-components'

const ArrowContainer = styled.div`
  width: 40px;
  margin-top: 2px;
  justify-content: space-between;
  align-items: center;
  display: flex;
`

export default ArrowContainer
