import styled from 'styled-components'
import { colors } from '../../../../assets/colors'

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: space-between;
  background: ${colors.white};
  width: 370px;
  padding: 30px;
  border-radius: 10px;
`

export default DialogContainer
