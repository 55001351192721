import React, { useEffect, useRef } from 'react'
import { useSession } from '../../../providers/SessionProvider'
import styled from 'styled-components'
import { Subscriber } from '@opentok/client'
import * as Sentry from '@sentry/react'

const ScreenShare = styled.div`
  display: flex;
  height: calc(100% - 110px);
  width: 97%;
  margin: 15px;
  background: #1d70b6;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  clip-path: content-box;
  overflow: hidden;
  order: 1;
`

const styles = {
  insertMode: 'append',
  width: '100%', // Szerokość każdego elementu Subscriber
  height: '100%', // Wysokość każdego elementu Subscriber
  videoSource: 'screen',
  style: {
    archiveStatusDisplayMode: 'auto',
    nameDisplayMode: 'off',
    buttonDisplayMode: 'off',
    audioLevelDisplayMode: 'off',
    videoDisabledDisplayMode: 'off'
  }
}

export const ScreenSubscriber = () => {
  const subscriberDiv = useRef()
  const { session, sharedStream } = useSession()

  useEffect(() => {
    let subscriber: Subscriber = null
    if (!sharedStream && session) return
    // @ts-ignore
    subscriber = session.subscribe(sharedStream, subscriberDiv?.current, styles, (error) => {
      if (error) {
        console.log('Screenpublish Error init', error)
        Sentry.captureException(error, {
          tags: {
            context: 'Subscriber Screen subscribe - Error'
          }
        })
      }
    })

    return () => {
      session.unsubscribe(subscriber)
    }
  }, [session, sharedStream])

  // @ts-ignore
  return <ScreenShare ref={subscriberDiv} />
}
