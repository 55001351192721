import React from 'react'
import styled from 'styled-components'
import Footer from './components/Footer'
// import UserStreamContainer from './components/UserStreamContainer'
import { colors } from '../../../assets/colors'
import ShareScreenIcon from './components/ShareScreenIcon'
import FooterIcon from './components/FooterIcon'
import RoundedIcon from './components/RoundedIcon'
import MainControls from './components/MainControls'
import CopyInviteLink from './components/CopyInviteLink'
import More from './components/More'

interface IProps {
  children: React.ReactNode
}

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  //padding-bottom: 3%;
  background: ${colors.darkGrey};
`

const VideoRoom = (props: IProps) => {
  return <Container>{props.children}</Container>
}

VideoRoom.Footer = Footer
VideoRoom.MainControls = MainControls
// VideoRoom.UserStreamContainer = UserStreamContainer
VideoRoom.ShareScreenIcon = ShareScreenIcon
VideoRoom.CopyInviteLink = CopyInviteLink
VideoRoom.FooterIcon = FooterIcon
VideoRoom.RoundedIcon = RoundedIcon
VideoRoom.More = More

export default VideoRoom
