import React from 'react'
import styled from 'styled-components'
import { Content } from './components/Content'
import { LeftContainer } from './components/LeftContainer'
import { RightContainer } from './components/RightContainer'
import { Title } from './components/Title'

interface IProps {
  children: React.ReactNode
}

const Container = styled.div`
  flex: 1;
  background: #fafbfc;
  height: 100%;
  width: 100%;
`

const ColumnView = (props: IProps) => {
  return <Container>{props.children}</Container>
}

ColumnView.Content = Content
ColumnView.LeftContainer = LeftContainer
ColumnView.RightContainer = RightContainer
ColumnView.Title = Title

export default ColumnView
