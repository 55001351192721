import React, { ReactNode } from 'react'
import styled from 'styled-components'
import Avatar from './Avatar'
import AboutContainer from './AboutContainer'
import AboutInfo from './AboutInfo'
import ContactName from './ContactName'
import AddButton from './AddButton'
import { colors } from '../../../../assets/colors'
import Status from './Status'

const Container = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.silver};
  padding: 10px;
`

interface IProps {
  children?: ReactNode
}

const MessageInfo = ({ children }: IProps) => {
  return <Container>{children}</Container>
}

MessageInfo.Avatar = Avatar
MessageInfo.Status = Status
MessageInfo.AboutContainer = AboutContainer
MessageInfo.AboutInfo = AboutInfo
MessageInfo.ContactName = ContactName
MessageInfo.AddButton = AddButton

export default MessageInfo
