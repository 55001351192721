import React from 'react'

interface CopyProps {
  style?: object
  fill?: string
  width?: string
  height?: string
  className?: string
  viewBox?: string
}

const Copy = ({
  style = {},
  fill = '#707070',
  width = '20px',
  height = '20px',
  className = '',
  viewBox = '0 0 20 20'
}: CopyProps) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M2 0C0.895 0 0 0.895 0 2V16H2V2H16V0H2ZM6 4C4.895 4 4 4.895 4 6V18C4 19.105 4.895 20 6 20H18C19.105 20 20 19.105 20 18V6C20 4.895 19.105 4 18 4H6ZM6 6H18V18H6V6Z"
    />
  </svg>
)

export default Copy
