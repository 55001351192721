import styled from 'styled-components'

const Subtitle = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #707070;
  margin-left: 5px;
`
export default Subtitle
