import { useQuery } from '@tanstack/react-query'
import APIClient from '../../apiClient'
import { MessageType, TagType } from '../../types'

interface TagsApi {
  tags: TagType[]
  views: TagType[]
  messages: MessageType[]
}
export const useGetTags = () => {
  return useQuery<unknown, unknown, TagsApi>({
    queryKey: ['tags'],
    queryFn: async () => {
      const { data } = await APIClient.messagesWithTagsList()
      return data
    },
    cacheTime: 1000 * 60 * 15 // 15 minutes
  })
}
