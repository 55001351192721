import { addMinutes, isBefore } from 'date-fns'

export const generateTimeSlots = ({
  start,
  end,
  interval = 15
}: {
  start: Date
  end: Date
  interval?: number
}): Date[] => {
  const step = (x: Date): Date => addMinutes(x, interval)
  const blocks = []
  let cursor = start

  while (isBefore(cursor, end)) {
    blocks.push(cursor)
    cursor = step(cursor)
  }

  return blocks
}
