import styled from 'styled-components'
import React from 'react'

const ClickHandler = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 0;
`

export default ClickHandler
