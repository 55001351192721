import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CalendarContainer from '../../components/Calendar'
import './styles.css'
import { Day } from '../../types/calendar/Day'
import Meeting from '../../components/blocks/Meeting'
import CancelActionButton from '../../components/CancelActionButton'
import GradientButton from '../../components/GradientButton'
import VideoConferencingHeader from '../../components/Header'
import { colors } from '../../assets/colors'
import { useMeetingRoom } from './useMeetingRoom'
import { isBefore, isToday, roundToNearestMinutes, set, startOfTomorrow } from 'date-fns'
import { generateTimeSlots } from './generateTimeSlots'
import { useHistory } from 'react-router'

const Container = styled.div`
  flex: 1;
  background: ${colors.paleGrey};
  overflow: auto;
`

const ErrorMessage = styled.div`
  color: #b91c1c;
`

const MeetingRoom: React.FC = () => {
  const today = new Date()
  const tomorrow = startOfTomorrow()
  const history = useHistory()

  const isBefore5PM = isBefore(today, set(new Date(), { hours: 17, minutes: 0, seconds: 0 }))
  const [selectedDay, setSelectedDay] = useState<Day>(new Day(isBefore5PM ? today : tomorrow))

  const { handleSubmit, handleChange, values, setValues, errors, resetForm, touched, isLoading } = useMeetingRoom()

  const handleStartDate = isToday(selectedDay.Date)
    ? roundToNearestMinutes(today, { nearestTo: 15, roundingMethod: 'ceil' })
    : set(selectedDay.Date, { hours: 8, seconds: 0, minutes: 0, milliseconds: 0 })

  const startTimes = generateTimeSlots({
    start: handleStartDate,
    end: set(selectedDay.Date, { hours: 17, minutes: 46, seconds: 0 })
  })

  const endTimes = generateTimeSlots({
    start: handleStartDate,
    end: set(selectedDay.Date, { hours: 18, minutes: 1, seconds: 0 })
  }).filter((endTime) => isBefore(new Date(values.startTime), new Date(endTime)))

  useEffect(() => {
    // Reset start and end times, when selected day was changed
    resetForm({ values: { ...values, startTime: '', endTime: '' } })
  }, [selectedDay])

  useEffect(() => {
    // Set end time 30 minutes later than start time
    // Each date in endTimes array is different by 15 minutes
    const defaultEndTime = endTimes?.length >= 2 ? endTimes?.[1]?.toISOString() : endTimes?.[0]?.toISOString()
    setValues({ ...values, endTime: defaultEndTime })
  }, [values.startTime])

  return (
    <Container>
      <VideoConferencingHeader />
      <Meeting.Container>
        <Meeting.BasicInfoContainer>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Meeting.Title>New meeting</Meeting.Title>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                position: 'relative',
                marginLeft: 40
              }}
            >
              <Meeting.LargeInput
                value={values.title}
                handleChange={handleChange('title')}
                name={'title'}
                placeholder={'Add title'}
                error={!!errors.title && touched.title}
              />
              {touched.title && errors.title && (
                <ErrorMessage style={{ position: 'absolute', bottom: -20 }}>{errors.title}</ErrorMessage>
              )}
            </div>
          </div>

          <div>
            <CancelActionButton style={{ marginRight: 20, height: 46 }} onClick={() => history.goBack()}>
              Cancel
            </CancelActionButton>
            <GradientButton style={{ height: 46, width: 156 }} onClick={() => handleSubmit()} isLoading={isLoading}>
              Create meeting
            </GradientButton>
          </div>
        </Meeting.BasicInfoContainer>
        <Meeting.Panels>
          <Meeting.LeftPanel>
            <CalendarContainer selectedDay={selectedDay} onDayChange={(day: Day) => setSelectedDay(day)} />
            <Meeting.InputContainer>
              <Meeting.TimePickerFragment
                name={'startTime'}
                subtitle="Start"
                onChange={handleChange('startTime')}
                times={startTimes}
                value={values.startTime}
                error={!!errors.startTime && !!touched.startTime}
              />
              <Meeting.TimePickerFragment
                name={'endTime'}
                subtitle="End"
                onChange={handleChange('endTime')}
                times={endTimes}
                value={values.endTime}
                error={!!errors.endTime && !!touched.endTime}
              />
            </Meeting.InputContainer>

            <div style={{ marginTop: 10 }}>
              {touched.startTime && errors.startTime && <ErrorMessage>{errors.startTime}</ErrorMessage>}
              {touched.endTime && errors.endTime && <ErrorMessage>{errors.endTime}</ErrorMessage>}
            </div>

            <Meeting.DescriptionField value={values.description} handleChange={handleChange('description')} />
          </Meeting.LeftPanel>
          <Meeting.RightPanel>
            <Meeting.ConferenceParticipantsContainer
              onChangeSelectedParticipants={(selectedParticipants) => {
                const participantsEmails = selectedParticipants.map((participant) => {
                  return { email: participant.email || participant.email2 }
                })
                setValues({ ...values, participants: participantsEmails })
              }}
            />
            {/*// @ts-ignore*/}
            {touched.participants && errors.participants && <ErrorMessage>{errors.participants}</ErrorMessage>}
          </Meeting.RightPanel>
        </Meeting.Panels>
      </Meeting.Container>
    </Container>
  )
}

export default MeetingRoom
