import React from 'react'
import styled from 'styled-components'
import Icon from '../../../Icon'
import { colors } from '../../../../assets/colors'

const Container = styled.button`
  width: 40px;
  height: 40px;
  outline: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  opacity: 0.9;
  &:active {
    opacity: 0.7;
  }
  &:hover {
    opacity: 1;
  }
`

interface IProps {
  onClick: () => void
  isConfidential?: boolean
}

const AddButton = ({ onClick, isConfidential }: IProps) => {
  return (
    <Container onClick={onClick}>
      <Icon name={'add'} fill={isConfidential ? colors.mediumGrey2 : colors.darkBlue} />
    </Container>
  )
}

export default AddButton
