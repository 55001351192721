import styled from 'styled-components'
import React, { ReactNode } from 'react'
import { colors } from '../../assets/colors'

interface IField {
  padding?: string
}

const FieldWrapper = styled.div<IField>`
  width: 90%;
  margin-left: 10%;
  min-height: 50px;
  padding: ${(props) => props.padding || '10px'};
`
const RoundWrapper = styled.div`
  border-radius: 15px;
  width: 100%;
  height: 30px;
  background-color: ${colors.lightSliver};
  display: flex;
  align-items: center;
  padding: 5px;
`

const StyledInput = styled.input`
  width: 100%;
  border: 0;
  background-color: transparent;
  outline: 0;
  font-size: 14px;
  color: ${colors.mediumGrey};
`

interface IInput extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> {
  children?: ReactNode
  padding?: string
  value?: string
}

const RoundedInput = ({ children, padding, ...props }: IInput) => (
  <FieldWrapper padding={padding}>
    <RoundWrapper>
      <StyledInput {...props} />
      {children}
    </RoundWrapper>
  </FieldWrapper>
)

export default RoundedInput
