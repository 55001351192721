import React, { FC } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { colors } from '../../../../../assets/colors'
import BackArrow from '../../../../../../components/Icon/BackArrow'
import { useUser } from '../../../../../../store/user'

const StyledLink = styled.a`
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 54px;
  justify-content: space-between;
  cursor: pointer;
  opacity: 0.95;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`
const BackText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.darkBlue};
`

const BackLink: FC = () => {
  const history = useHistory()
  const user = useUser()

  if (!user) return null

  return (
    <StyledLink onClick={() => history.goBack()}>
      <BackArrow />
      <BackText>Back</BackText>
    </StyledLink>
  )
}

export default BackLink
