import React, { FC } from 'react'
import MovieCamera from '../../../../components/Icon/MovieCamera'

const IconLeft: FC = () => {
  return (
    <div style={{ alignItems: 'center', marginRight: 15, paddingTop: 2 }}>
      <MovieCamera fill={'#FFF'} />
    </div>
  )
}

export default IconLeft
