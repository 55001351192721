import React, { useState, useEffect } from 'react'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { BillingContentTitle } from '../Shared/UI/Components/BillingContentTitle'
import { toast, ToastContainer } from 'react-toastify'
import { loadStripe } from '@stripe/stripe-js'
import appSyncClient from '../../../../../gql/client'
import { setDefaultPaymentMethod } from '../../../../../gql/queries/setDefaultPaymentMethod'
import { useHistory } from 'react-router'
import { colors } from '../../../../../assets/colors'
import { Loader } from '../../../../../components/Loader/Loader'
const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_STRIPE_KEY)

const PaymentOptionsUpdateStatusChecker = () => {
  const stripe = useStripe()
  const [message, setMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (!stripe) {
      return
    }

    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get('setup_intent_client_secret')

    setLoading(true)

    // Retrieve the SetupIntent
    stripe.retrieveSetupIntent(clientSecret).then(async ({ setupIntent }) => {
      // Set new added cc as default and remove other cards
      try {
        await appSyncClient.graphql({
          query: setDefaultPaymentMethod,
          variables: {
            paymentMethod: setupIntent.payment_method
          }
        })
      } catch (e) {
        console.error('Error during setting card as default', e)
      } finally {
        setLoading(false)
      }

      // Inspect the SetupIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (setupIntent.status) {
        case 'succeeded':
          // toast.success('Success! Your payment method has been saved.')
          // setMessage('Success! Your payment method has been saved.')
          setLoading(false)
          history.push('/billing?tab=payment-options&status=success')
          break

        case 'processing':
          toast.info("Processing payment details. We'll update you when processing is complete.")
          setMessage("Processing payment details. We'll update you when processing is complete.")
          setLoading(false)
          break

        case 'requires_payment_method':
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          toast.error('Failed to process payment details. Please try another payment method.')
          setMessage('Failed to process payment details. Please try another payment method.')
          setLoading(false)
          break
      }
    })
  }, [stripe])

  return (
    <>
      <BillingContentTitle>Payment Update Status</BillingContentTitle>
      <p>{message}</p>
      {loading && <Loader fill={colors.mediumGrey} />}
      <ToastContainer theme="light" position="bottom-right" autoClose={5000} />
    </>
  )
}

const PaymentOptionsUpdateStatus = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentOptionsUpdateStatusChecker />
    </Elements>
  )
}

export default PaymentOptionsUpdateStatus
