import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import Calendar from '../../../../../../components/Icon/Calendar'
import { MeetingDate } from './MeetingDate'

interface DateInfoProps {
  date: string
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`

const DateInfo = ({ date }: DateInfoProps) => {
  return (
    <Container>
      <Calendar />
      <MeetingDate>{date}</MeetingDate>
    </Container>
  )
}

export default DateInfo
