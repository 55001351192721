import React, { ReactNode, useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { colors } from '../../../assets/colors'

interface IAutoPlayerCounter {
  children?: ReactNode
  onTimerEnd: () => void
}

const countdownAnimation = keyframes`
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 183px;
  }
`

const Container = styled.div`
  padding: 30px 0;
`

const Countdown = styled.div`
  position: relative;
  height: 80px;
  width: 80px;
  text-align: center;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 80px;
    transform: rotateY(-180deg) rotateZ(-90deg);
  }

  svg circle {
    stroke-dasharray: 183px;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke-width: 2px;
    stroke: white;
    fill: none;
  }

  svg .circle {
    stroke: gray;
    animation: ${countdownAnimation} 3s linear forwards;
  }

  svg .circleBg {
    stroke: white;
  }
`

const CountdownNumber = styled.div`
  color: ${colors.white};
  display: inline-block;
  line-height: 80px;
`

const Counter = ({ children, onTimerEnd, ...props }: IAutoPlayerCounter) => {
  const intervalRef = useRef<number>()
  const [time, setTime] = useState<number>(3)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTime((time) => time - 1)
    }, 1000)

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  const stopTimer = () => {
    if (intervalRef.current) clearInterval(intervalRef.current)
    if (onTimerEnd) onTimerEnd()
  }

  useEffect(() => {
    if (time > 0) return
    stopTimer()
  }, [time])

  return (
    <Container>
      <Countdown>
        <CountdownNumber>{time}</CountdownNumber>
        <svg>
          <circle className={'circleBg'} r="28" cx="40" cy="40" />
          <circle className={'circle'} r="28" cx="40" cy="40" />
        </svg>
      </Countdown>
    </Container>
  )
}

export default Counter
