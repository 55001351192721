import React, { FC } from 'react'
import Header from '../../../../components/Header'
import MessageListView from '../../../../containers/MessageList'
import { InlineButton } from '../../../../components/Button/InlineButton'

interface ITagMessagesView {
  onBackClick: () => void
  name: string
  messages: any[]
  isLoading: boolean
}

const TagMessagesView: FC<ITagMessagesView> = ({ onBackClick, name, messages, isLoading }) => {
  return (
    <>
      <Header>
        <Header.SideButtons>
          <InlineButton onClick={onBackClick}>Back</InlineButton>
        </Header.SideButtons>
        <Header.Title>
          <span>{name}</span>
        </Header.Title>
        <Header.EndButtons />
      </Header>
      <MessageListView messages={messages} isLoading={isLoading} />
    </>
  )
}

export default TagMessagesView
