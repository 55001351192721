import { useQuery } from '@tanstack/react-query'
import APIClient, { SuggestedTag } from '../../apiClient'

export const getSuggestedTags = () => {
  return useQuery<unknown, unknown, SuggestedTag[]>({
    queryKey: ['suggestedTags'],
    queryFn: async () => {
      const res = await APIClient.suggestedTagsGet()
      return res.data
    },
    cacheTime: 1000 * 60 * 15 // 15 minutes
  })
}
