import { useEffect, useMemo, useState } from 'react'
import { useSession } from '../../../providers/SessionProvider'
import { VISIBLE_STREAMS, VISIBLE_STREAMS_SCREENSHARE } from '../ConferenceNew'
import { useVideoStore } from '../../../../../store/video'

interface CalcStreams {
  forceUpdate?: boolean
  streamId: string
  val: string
}

export const useSortStreams = () => {
  const { streams, session, sharedStream } = useSession()
  const [streamUpdated, setStreamUpdated] = useState({ id: null, audioLevel: 0 })
  const sharingScreen = useVideoStore((s) => s.sharingScreen)
  const [streamPositions, setStreamPositions] = useState([])

  const isSharingScreen = sharingScreen || !!sharedStream

  const onCalcPosition = (streamId, val, forceUpdate = false): CalcStreams => {
    if (streams.length <= VISIBLE_STREAMS && !isSharingScreen) {
      setStreamPositions([])
      return
    }
    if (streams.length <= VISIBLE_STREAMS_SCREENSHARE + 1 && isSharingScreen) {
      setStreamPositions([])
      return
    }

    const updatedStream = streamPositions.find((item) => item.streamId === streamId)

    // Do not calc position when:
    // 1. Sharing Screen and stream order is less or equal 4 and there are only 4 streams
    // 2. Not sharing screen and stream order is less than 9
    // 3. Sharing screen and stream order is less than 4 and there are more than 4 streams
    if (
      (!forceUpdate && updatedStream && updatedStream?.order <= 4 && isSharingScreen && streams?.length === 4) ||
      (!forceUpdate && updatedStream && updatedStream?.order < 9 && !isSharingScreen) ||
      (!forceUpdate && updatedStream && updatedStream?.order < 4 && isSharingScreen && streams?.length > 4)
    )
      return

    const initialStreams = streams.map((stream) => {
      const foundStream = streamPositions?.find((item) => item.streamId === stream.streamId)
      return { streamId: stream?.streamId, sortKey: foundStream?.sortKey || 0 }
    })

    const updateAndSortStreams = initialStreams
      .map((stream) => {
        if (stream.streamId === streamId) {
          return { streamId: streamId, sortKey: val }
        }
        return stream
      })
      .sort((a, b) => {
        return b?.sortKey - a?.sortKey
      })

    const orderedStreams = [...updateAndSortStreams].map((item, index) => {
      return { streamId: item?.streamId, sortKey: item?.sortKey, order: index + 1 }
    })

    const numColumns = isSharingScreen ? 1 : 3

    const numStreams = orderedStreams?.length
    let numRows

    if (numStreams <= numColumns) {
      numRows = 1
    } else if (numStreams <= numColumns * 2) {
      numRows = 2
    } else {
      numRows = 3
    }

    if (isSharingScreen) {
      numRows = 4
    }

    let positions = []
    orderedStreams.forEach((stream, i) => {
      const row = Math.floor(i / numColumns)
      const col = i % numColumns
      positions.push({
        streamId: stream.streamId,
        sortKey: stream.sortKey,
        order: stream.order,
        position: {
          top: `${(row / numRows) * 100}%`,
          left: `${(col / numColumns) * 100}%`,
          zIndex: stream.order,
          display: (stream.order > 4 && isSharingScreen) || (stream.order > 9 && !isSharingScreen) ? 'none' : 'flex'
        }
      })
    })

    setStreamPositions(positions)
  }

  useEffect(() => {
    onCalcPosition(streamUpdated?.id, streamUpdated?.audioLevel)
  }, [streamUpdated])

  useEffect(() => {
    onCalcPosition(streamUpdated?.id, streamUpdated?.audioLevel, true)
  }, [sharedStream, sharingScreen])

  const invisibleStreams = useMemo(() => {
    return streams.slice(isSharingScreen ? VISIBLE_STREAMS_SCREENSHARE : VISIBLE_STREAMS)
  }, [streams, isSharingScreen])

  useEffect(() => {
    if (!session) return

    session.on('streamDestroyed', (event) => {
      onCalcPosition(event.stream.streamId, 0, true)
    })
  }, [session])

  return {
    positions: streamPositions,
    invisibleStreams,
    onCalcPosition: setStreamUpdated
  }
}
