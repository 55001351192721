import styled from 'styled-components'
import { colors } from '../../../../../../assets/colors'

export const OptionsWrapper = styled.div`
  //width: 100vw;
  //overflow-y: auto;
  min-height: calc(100vh - 50px);
  max-width: 100%;
  background: rgb(239, 239, 239);
  display: flex;

  & *::placeholder {
    color: #808080;
  }
`

export const StyledOptionsNavigationWrapper = styled.aside`
  min-height: calc(100vh - 60px);
  width: 295px;
  margin: 5px 2.5px;
  border-radius: 10px;
  padding-top: 30px;
  background: ${colors.white};
`

export const StyledAccordionElement = styled.div<{ active?: boolean }>`
  padding: 15px 24px 15px 50px;
  cursor: pointer;
  color: ${({ active }: { active?: boolean }) => (active ? colors.darkBlue : colors.mediumGrey)};
  font-size: 13px;
  font-weight: 600;

  &:hover {
    background: rgba(29, 112, 182, 0.05);
  }
`

export const StyledAccordionTitleLink = styled.div<{ active: boolean }>`
  width: 100%;
  cursor: pointer;
  padding: 15px 24px;
  color: ${({ active }: { active: boolean }) => (active ? colors.darkBlue : colors.lightBlack)};
  font-size: 13px;
  font-weight: 600;

  &:hover {
    background: rgba(29, 112, 182, 0.05);
  }
`
