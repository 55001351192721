import { useState, useEffect } from 'react'

interface IWindowSize {
  width: number
  height: number
}

export default function useWindowSize(): {
  windowSize: IWindowSize
} {
  const [windowSize, setWindowSize] = useState<IWindowSize>({
    width: 0,
    height: 0
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return { windowSize }
}
