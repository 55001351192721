import styled from 'styled-components'
import { colors } from '../../assets/colors'

export const StyledInput = styled.input`
  border: none;
  background-color: transparent;
  color: ${colors.black};
  outline: none;
  width: 100%;
  font-size: 14px;
`
