import styled from 'styled-components'
import { colors } from '../../../../../assets/colors'

export const MeetingPriceText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: ${colors.darkBlue};
`
