import { useHistory } from 'react-router'
import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useGetContact } from '../../../../../../hooks/queries/useGetContact'
import { useGetContactStore } from '../../../../../../store/contacts'
import { useFormik } from 'formik'
import APIClient from '../../../../../../apiClient'
import { showAlertModal, showInfoModal } from '../../../../../../store/modals'
import { ContactItemType } from '../../../../../../types'

const validate = (values: ContactItemType) => {
  const errors = []
  if (!values.kate_number) {
    errors.push('CommunniKate')
  }
  if (!values.first_name) {
    errors.push('First name')
  }
  if (!values.last_name) {
    errors.push('Last name')
  }

  const errorMessage = `Please enter ${errors.join(', ')}`
  if (Object.keys(errors).length) {
    showAlertModal(errorMessage)
  }
  return !Object.keys(errors).length
}

const useEditContact = () => {
  const history = useHistory()
  const urlParams = new URLSearchParams(history.location.search)
  const id = urlParams.get('id') || ''
  const search = urlParams.get('filter') || ''
  const [saving, setSaving] = useState(false)
  const [confirmShow, setConfirmShow] = useState(false)
  const [groupSelectionVisible, setGroupSelectionVisible] = useState(false)

  const queryClient = useQueryClient()

  const { data: contactApi, isLoading: contactLoading, error } = useGetContact(id)
  const contactStore = useGetContactStore()

  const contact = contactApi || contactStore

  const formik = useFormik({
    initialValues: contact || { id: null, kate_number: '', first_name: '', last_name: '' },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const valid = validate(values)
      if (valid) {
        try {
          setSaving(true)
          const editResponse = await APIClient.contactUpdate({ id, req: values })
          if (editResponse.status >= 300)
            throw Error(`contact edit error ${editResponse.status}, ${editResponse.statusText}`)

          // adding contact to all selected groups
          values.groups &&
            (await Promise.all(
              values.groups.map(async (group) => {
                const updateResponse = await APIClient.contactGroupUpdate({
                  id: group.id,
                  req: {
                    name: group.name,
                    contacts: {
                      add: [
                        {
                          id: id,
                          phone_numbers: ['kate_number']
                        }
                      ]
                    }
                  }
                })
                if (updateResponse.status > 300)
                  throw new Error(`group update error ${updateResponse.status}, ${updateResponse.statusText}`)
              })
            ))

          // removing contact from all deselected groups
          const groupsToRemove =
            contact.groups &&
            contact.groups.filter((group) => values.groups && !new Set(values.groups.map((gr) => gr.id)).has(group.id))

          groupsToRemove &&
            (await Promise.all(
              groupsToRemove.map(async (group) => {
                const updateResponse = await APIClient.contactGroupUpdate({
                  id: group.id,
                  req: {
                    name: group.name,
                    contacts: {
                      remove: [id]
                    }
                  }
                })
                if (updateResponse.status > 300)
                  throw new Error(`group update error ${updateResponse.status}, ${updateResponse.statusText}`)
              })
            ))

          showInfoModal('Contact edited')
          await queryClient.invalidateQueries(['contacts'])
          await queryClient.invalidateQueries(['groups'])

          history.goBack()
        } catch (e) {
          console.log('e', e)
          showAlertModal('Ups, unable to edit contact')
        } finally {
          setSaving(false)
        }
      }
    }
  })

  const deleteFunction = async () => {
    try {
      const deleteResponse = await APIClient.contactDelete(id)
      if (deleteResponse.status >= 300)
        throw new Error(`status = ${deleteResponse.status}, ${deleteResponse.statusText}`)
      await queryClient.invalidateQueries(['contacts'])
      history.push('/contacts')
    } catch (e) {
      setConfirmShow(false)
      showAlertModal('Ups, unable to delete contact')
    }
  }

  return {
    history,
    formik,
    deleteFunction,
    saving,
    contactLoading,
    confirmShow,
    setConfirmShow,
    groupSelectionVisible,
    setGroupSelectionVisible
  }
}

export default useEditContact
