import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { colors } from '../../../../../assets/colors'
import BoolButton from '../../../../../components/Button/BoolButton'
import { useNewMessageActions, useNewMessageStore } from '../../../../mobile/pages/NewMessage/store/NewMessageStore'
import Modals from '../../../../mobile/pages/NewMessage/Modals'
import MultiSelectInputDesktop from '../../../components/MultiSelectInputDesktop'
import { useRecipients } from '../../../../shared/hooks/useRecipients'
import Icon from '../../../../../components/Icon'
import PhotoCapture from '../../../../../components/PhotoCapture'
import { useForm } from '../../../../mobile/pages/NewMessage/Form/useForm'
import Spinner from '../../../../../components/Icon/Spinner'
import { Box, Typography } from '@mui/material'
import SendAudioMessage from '../containers/SendAudioMessage'
import StyledLink from '../../../../../components/Button/Link'
import { useHistory } from 'react-router-dom'
import BackArrow from '../../../../../components/Icon/BackArrow'
import { useLocation } from 'react-router'
import { useGetMessagesById } from '../../../../../hooks/queries/useGetMessagesById'
import APIClient from '../../../../../apiClient'
import AvatarContact from '../components/AvatarContact'

const Input = styled.input`
  width: 100%;
  height: 33px;
  border-bottom: 1px solid ${colors.lightSliver};
`
const StyledCameraButton = styled.div`
  cursor: pointer;
  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

const Label = styled.label`
  display: flex;
  align-items: center;
  color: ${colors.mediumGrey};
  font-size: 15px;

  padding-right: 5px;

  height: 100%;
  border-bottom: 1px solid ${colors.lightSliver};
`

const InputWrapper = styled.div`
  width: 100%;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const SendButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  width: 100%;
`
const SendButton = styled.button`
  padding: 10px;
  background: ${colors.darkBlue};
  color: #fff;
  border: none;
  border-radius: 51px;

  width: 87px;
  height: 40px;
  cursor: pointer;
`

const MessageTypeContainer = styled.div`
  display: flex;
`

const CameraWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;

  cursor: pointer;
`

const ProcessingMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;

  span {
    margin-top: 15px;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: ${colors.darkBlue};
  }
`

const SendMessage = () => {
  const history = useHistory()
  const isConfidential = useNewMessageStore((state) => state.isConfidential)
  const isUrgent = useNewMessageStore((state) => state.isUrgent)

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const action = params.get('action')

  const forwardId = useMemo(() => location.state && location.state.forwardId, [location.state])
  const { data: forwardMessage } = useGetMessagesById(forwardId)

  const { onSelectedRecipient, onRemoveRecipient, onSearchRecipient, recipientsOptions, recipients } = useRecipients()
  const { setIsConfidential, setIsUrgent, setImage, setImageFile, setRecipients } = useNewMessageActions()

  const formik = useForm()

  const createImage = useCallback((file: any) => {
    setImageFile(file)
    const blob = URL.createObjectURL(file)
    setImage(blob)
  }, [])

  useEffect(() => {
    return () => {
      setImage(null)
      setImageFile(null)
      setRecipients([])
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (action === 'forward' && forwardId) {
        const res = await APIClient.messagesItem(forwardId, false)
        const data = res?.data
        setImage(data?.photo_url)
      }
    })()
  }, [])

  if (formik.isSubmitting) {
    return (
      <ProcessingMessage>
        <Spinner width={'50px'} fill={colors.darkBlue} />
        <span>Processing, please wait...</span>
      </ProcessingMessage>
    )
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
        <SendButtonWrapper style={{ justifyContent: action !== 'new-message' ? 'space-between' : 'flex-end' }}>
          {action !== 'new-message' && (
            <StyledLink
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px'
              }}
              onClick={() => history.goBack()}
            >
              <BackArrow style={{ marginRight: '10px' }} />
              {action === 'reply' ? 'Reply' : 'Forward'}
            </StyledLink>
          )}
          <SendButton
            onClick={() => {
              formik.handleSubmit()
            }}
          >
            Send
          </SendButton>
        </SendButtonWrapper>
        <div style={{ width: '100%', padding: '10px 20px' }}>
          <MultiSelectInputDesktop
            onSelect={onSelectedRecipient}
            onRemove={onRemoveRecipient}
            onSearchQuery={onSearchRecipient}
            options={recipientsOptions}
            values={recipients}
            rightArea={null}
          />
        </div>
        <div style={{ display: 'flex', padding: '10px 20px' }}>
          <InputWrapper>
            <Label style={{ borderBottom: 'none' }} htmlFor={'subject'}>
              Subject:
            </Label>
            <Input
              style={{ borderBottom: 'none' }}
              id={'subject'}
              tabIndex={-1}
              autoComplete="off"
              name="note"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.note}
            />
          </InputWrapper>
          <MessageTypeContainer>
            <CameraWrapper>
              <PhotoCapture element={<div />} onCapturedImage={createImage}>
                <StyledCameraButton>
                  <Icon name={'camera'} width={'30px'} fill={colors.mediumGrey} />
                </StyledCameraButton>
              </PhotoCapture>
            </CameraWrapper>
            <div>
              <BoolButton
                style={{
                  paddingRight: 10,
                  borderColor: isUrgent ? colors.red2 : colors.mediumGrey2,
                  backgroundColor: isUrgent ? colors.red2 : 'transparent'
                }}
                iconStyle={{ margin: 5 }}
                name={'important'}
                activeName={'important'}
                label={'Urgent'}
                onClick={() => setIsUrgent(!isUrgent)}
                isActive={isUrgent}
              />
            </div>
            <div>
              <BoolButton
                style={{
                  marginLeft: 10,
                  paddingRight: 10,
                  borderColor: colors.mediumGrey2,
                  backgroundColor: isConfidential ? colors.mediumGrey2 : 'transparent'
                }}
                iconStyle={{ margin: 5 }}
                name={'confidential'}
                activeName={'confidentialFilled'}
                label={'Private'}
                onClick={() => setIsConfidential(!isConfidential)}
                isActive={isConfidential}
              />
            </div>
          </MessageTypeContainer>
        </div>
        {action === 'forward' && forwardMessage && (
          <Box display={'flex'} ml={'20px'}>
            <AvatarContact message={forwardMessage} />
            <Box display={'flex'} flexDirection={'column'} ml={1.5}>
              {forwardMessage?.contact ? (
                <>
                  <Box display={'flex'}>
                    <Typography color={colors.black}>{forwardMessage?.contact?.first_name}</Typography>
                    <Typography ml={0.5} color={colors.black}>
                      {forwardMessage?.contact?.last_name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize={12} color={colors.mediumGrey}>
                      {forwardMessage?.contact?.kateNumber || forwardMessage?.source}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Typography>{forwardMessage?.source}</Typography>
              )}
              <Box mt={1}>
                <Typography color={colors.black}>{forwardMessage?.note}</Typography>
              </Box>
            </Box>
          </Box>
        )}

        <SendAudioMessage />
      </div>
      <Modals />
    </>
  )
}

export default SendMessage
