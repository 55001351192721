import React, { SVGProps } from 'react'

const PlayButtonFilled = ({ fill = '#1D70B6' }) => (
  <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="38.5" cy="38.5" r="38.5" fill={fill} />
    <path
      d="M28.9464 24.5C28.6376 24.5083 28.3448 24.6377 28.1323 24.8599C27.9197 25.0822 27.8049 25.3789 27.8129 25.6851C27.8129 25.6851 27.8129 25.6851 27.8129 25.6894V51.7481C27.8129 51.7481 27.8129 51.7481 27.8129 51.7524C27.8049 52.0586 27.9197 52.3553 28.1323 52.5776C28.3448 52.7998 28.6376 52.9292 28.9464 52.9375C29.1841 52.9363 29.415 52.8584 29.6041 52.7156L52.0879 39.795C52.2899 39.6918 52.459 39.5348 52.5759 39.3415C52.6929 39.1483 52.7531 38.9265 52.7499 38.7011C52.7466 38.4758 52.68 38.2558 52.5576 38.0659C52.4351 37.876 52.2617 37.7238 52.0567 37.6264L29.606 24.7201C29.4161 24.5775 29.1845 24.5002 28.9464 24.5Z"
      fill="white"
    />
  </svg>
)

export default PlayButtonFilled
