import { useCallback } from 'react'
import { useGetMessagesById } from '../../../../../../hooks/queries/useGetMessagesById'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'

export const useAddContact = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const id = params.get('id')
  const history = useHistory()
  const { data: message } = useGetMessagesById(id, true)

  const addContact = (message) => {
    if (!message) return
    if (message.source_is_kate && message.source_kate_account) {
      return history.push('/contacts?action=create', {
        source: message.source,
        source_kate_account: message.source_kate_account
      })
    }
    history.push('/contacts?action=create', {
      source: message.source
    })
  }

  return {
    addContact
  }
}
