import styled from 'styled-components'

export const Content = styled.div`
  height: calc(100% - 100px);
  width: 100%;
  max-width: 1140px;
  padding: 0 15px;
  display: flex;
  flex: 1;
  align-items: flex-start;
  margin: 15px auto 0 auto;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
  @media (min-width: 1024px) {
    justify-content: space-between;
  }
`
