import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Header from '../../../../components/Header'
import { InlineButton } from '../../../../components/Button/InlineButton'
import Field from '../../../../components/Field'
import SwitchButton from '../../../../components/Button/SwitchButton'
import { colors } from '../../../../assets/colors'
import RoundedInput from '../../../../components/Input/RoundedInput'
import Icon from '../../../../components/Icon'
import ActionsModal from '../../../../components/Modal/ActionsModal'
import { useFormik } from 'formik'
import APIClient from '../../../../apiClient'
import { Loader } from '../../../../components/Loader/Loader'
import styled from 'styled-components'
import { showAlertModal, showInfoModal } from '../../../../store/modals'
import { useUser } from '../../../../store/user'

const StyledButton = styled.div`
  cursor: pointer;
  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

const CallForwardingPage: React.FC = () => {
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const user = useUser()
  const [showModal, setShowModal] = useState(false)
  const numbersList = ['mobile', 'mobile 2', 'home', 'office']

  const [selectedTypes, setSelectedTypes] = useState<{ [key: string]: { phone_number: string; transfer: boolean } }>({})
  const [transferVoiceMail, setTransferVoiceMail] = useState<boolean>()

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      setLoading(true)
      const jsonData = {
        ...user.phone_settings,
        transfer_voice_mail: transferVoiceMail,
        ...selectedTypes
      }
      const transfers = Object.keys(selectedTypes).filter((type) => selectedTypes[type].transfer)
      try {
        if (transferVoiceMail || transfers?.length) {
          await APIClient.phoneSettingsUpdate({ jsonData })
          showInfoModal('Settings changed')
          history.goBack()
        } else {
          showAlertModal('At least one transfer has to be true.')
          setLoading(false)
        }
      } catch (e) {
        console.log('error ===', e)
      }
    }
  })

  useEffect(() => {
    setTransferVoiceMail(user.phone_settings?.transfer_voice_mail)
  }, [user])

  useEffect(() => {
    const phoneSettings: { [key: string]: { phone_number: string; transfer: boolean } } = {}
    if (user.phone_settings) {
      Object.keys(user.phone_settings).map((type) => {
        if (user.phone_settings[type].phone_number) {
          phoneSettings[type] = user.phone_settings[type]
        }
      })
    }
    setSelectedTypes({
      ...selectedTypes,
      ...phoneSettings
    })
    setLoading(false)
  }, [user])

  const changeKate = useCallback(() => {
    setTransferVoiceMail(!transferVoiceMail)
    const newTypes: { [key: string]: any } = {}
    Object.keys(selectedTypes).forEach((type: string) => {
      newTypes[type] = {
        phone_number: selectedTypes[type].phone_number,
        transfer: false
      }
    })
    setSelectedTypes(newTypes)
  }, [transferVoiceMail])

  const addPhone = useCallback(
    (type: string) => {
      setSelectedTypes({
        ...selectedTypes,
        [type === 'mobile 2' ? 'cell' : type]: {
          phone_number: '',
          transfer: false
        }
      })
      setShowModal(false)
    },
    [selectedTypes]
  )

  const changeTransfer = useCallback(
    (type: string) => {
      if (!selectedTypes[type].transfer) {
        setTransferVoiceMail(false)
      }
      setSelectedTypes({
        ...selectedTypes,
        [type]: {
          ...selectedTypes[type],
          transfer: !selectedTypes[type].transfer
        }
      })
    },
    [selectedTypes]
  )

  const parseNumber = useCallback((value: string) => {
    const re = /[\D+\W+]/
    if (value.length === 10 && !value.match(re)) {
      return `(${value[0]}${value[1]}${value[2]}) ${value[3]}${value[4]}${value[5]}-${value[6]}${value[7]}${value[8]}${value[9]}`
    }

    return value
  }, [])

  const changeNumber = useCallback(
    (value: string, type: string) => {
      const phoneNumber = parseNumber(value)
      setSelectedTypes({
        ...selectedTypes,
        [type === 'mobile 2' ? 'cell' : type]: {
          ...selectedTypes[type],
          phone_number: phoneNumber
        }
      })
    },
    [selectedTypes]
  )

  if (false) {
    return (
      <>
        <Header>
          <Header.SideButtons>
            <InlineButton onClick={() => history.goBack()}>Options</InlineButton>
          </Header.SideButtons>
          <Header.Title>
            <span>Call Forwarding</span>
          </Header.Title>
          <Header.EndButtons>
            <InlineButton onClick={() => formik.handleSubmit()}>Save</InlineButton>
          </Header.EndButtons>
        </Header>
        <Loader fill={colors.mediumGrey} style={{ margin: '5' }} />
      </>
    )
  }

  return (
    <>
      <Header>
        <Header.SideButtons>
          <InlineButton onClick={() => history.goBack()}>Options</InlineButton>
        </Header.SideButtons>
        <Header.Title>
          <span>Call Forwarding</span>
        </Header.Title>
        <Header.EndButtons>
          <InlineButton onClick={() => formik.handleSubmit()}>Save</InlineButton>
        </Header.EndButtons>
      </Header>
      <div style={{ backgroundColor: colors.silver, width: '100%', height: '100%' }}>
        <Field border onClick={changeKate}>
          <Field.InfoContainer>
            <Field.Content>{user.access_numbers[0]}</Field.Content>
            <Field.Label>Outbound minute charges may apply</Field.Label>
          </Field.InfoContainer>
          <Field.Actions>
            <SwitchButton isActive={transferVoiceMail} />
          </Field.Actions>
        </Field>
        {Object.keys(selectedTypes).map((type: string, index: number) => (
          <Field key={index} border onClick={() => changeTransfer(type)}>
            <Field.RowContent>
              <Field.Content>{type === 'cell' ? 'mobile2' : type}</Field.Content>
              <RoundedInput
                onChange={(event) => changeNumber(event.target.value, type)}
                value={selectedTypes?.[type].phone_number || ''}
              />
            </Field.RowContent>
            <Field.Actions>
              <SwitchButton isActive={selectedTypes?.[type].transfer} />
            </Field.Actions>
          </Field>
        ))}
        <StyledButton>
          <Field border onClick={() => setShowModal(true)}>
            <Field.RowContent>
              <Icon name={'add'} width={'30px'} fill={colors.mediumGrey} />
              <Field.Content>add mobile phone</Field.Content>
            </Field.RowContent>
          </Field>
        </StyledButton>
      </div>
      <ActionsModal show={showModal} onCancel={() => setShowModal(false)}>
        {numbersList
          .filter((type) => !selectedTypes[type === 'mobile 2' ? 'cell' : type])
          .map((type) => (
            <ActionsModal.Action key={type} onClick={() => addPhone(type)}>
              {type}
            </ActionsModal.Action>
          ))}
      </ActionsModal>
    </>
  )
}

export default CallForwardingPage
