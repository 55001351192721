import React, { FC } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import VideoConferencingLogo from '../../assets/images/video_conferencing_logo.png'
import HeaderProfileLink from './components/HeaderProfileLink'
import HeaderElement from './components/HeaderElement'

const HeaderContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const RightContainer = styled.div`
  display: flex;
  align-items: center;
`

interface Props {
  logoClickPathRedirect?: string
  children?: JSX.Element[] | JSX.Element
}

const Header: FC<Props> = ({ children, logoClickPathRedirect }) => {
  return (
    <HeaderContainer>
      <Link to={logoClickPathRedirect ? logoClickPathRedirect : '/video-conferencing'}>
        <img height="36" src={VideoConferencingLogo} alt="Video Conferencing Logo" />
      </Link>
      <RightContainer>{children}</RightContainer>
    </HeaderContainer>
  )
}

type HeaderProps = typeof Header & {
  Element: typeof HeaderElement
  ProfileLink: typeof HeaderProfileLink
}

const VideoConferencingHeader = Header as HeaderProps

VideoConferencingHeader.Element = HeaderElement
VideoConferencingHeader.ProfileLink = HeaderProfileLink

export default VideoConferencingHeader
