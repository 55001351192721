import { useUser } from '../../../../../../../store/user'
import { useHistory } from 'react-router'
import { useCallback, useEffect, useState } from 'react'
import useAudioPlaying from '../../../../../../../hooks/useAudioPlaying'
import APIClient from '../../../../../../../apiClient'
import { Howl } from 'howler'
import { showAlertModal, showInfoModal } from '../../../../../../../store/modals'
// @ts-ignore
import MicRecorder from 'mic-recorder-to-mp3'

const useVoiceMailGreeting = () => {
  // fallback for Safari's AudioContext reference
  // @ts-ignore
  window.AudioContext = window.AudioContext || window.webkitAudioContext
  const user = useUser()
  const history = useHistory()

  const [isRecording, setRecording] = useState(false)
  const [isLoading, setLoading] = useState(true)

  // Audio from database
  const currentGreeting = useAudioPlaying()

  const getCurrentMp3 = useCallback(async () => {
    try {
      if (user && !user.phone_settings.voice_mail_greeting) return
      const res = await APIClient.voiceMailGreetingGet()
      if (res.data) {
        const mp3 = new Blob([res.data], { type: 'audio/mp3' })
        const blobURL = URL.createObjectURL(mp3)
        currentGreeting.setAudio(
          new Howl({
            html5: true,
            src: blobURL,
            format: ['mp3']
          })
        )
      }
    } catch (e) {
      console.log('error on getCurrentMp3:', e)

      showAlertModal("Can't get current greeting")
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getCurrentMp3()
  }, [])

  // Audio record
  const {
    audio,
    curTime,
    duration,
    playing,
    playProgress,
    setAudio,
    playAudio,
    pauseAudio,
    setClickedTime
  } = useAudioPlaying()

  const [mp3File, setMp3File] = useState<File | null>(null)
  const [Mp3Recorder] = useState(new MicRecorder({ bitRate: 128, sampleRate: 22050 }))

  const startRecording = async () => {
    try {
      const recordingStarted = await Mp3Recorder.start()
      if (recordingStarted) {
        setRecording(true)
      }
    } catch (e) {
      console.log('error on startRecording:', e)
      showAlertModal(`Recording error`)
    }
  }

  const stopRecording = async () => {
    try {
      const [buffer, blob] = await Mp3Recorder.stop().getMp3()

      if (buffer && blob) {
        const timestamp = Date.now()

        const file = new File(buffer, `audio-greeting-${timestamp}.mp3`, {
          type: 'audio/mpeg',
          lastModified: Date.now()
        })

        const blobURL = URL.createObjectURL(file)
        setMp3File(file)
        setRecording(false)
        setAudio(
          new Howl({
            html5: true,
            src: blobURL,
            format: ['mp3']
          })
        )
      }
    } catch (e: any) {
      showAlertModal(`error on stop recording: ${e.toString()}`)
      console.log('error on stopRecording:', e)
    }
  }

  const submitFile = useCallback(async () => {
    if (!mp3File) {
      showAlertModal(`no greeting recorded`)
      return null
    }
    try {
      const formData = new FormData()
      formData.append('greeting', mp3File)
      formData.append('type', 'audio/mpeg')
      await APIClient.voiceMailGreetingSave(formData)
      showInfoModal('Greeting updated')
      setAudio(null)
      await getCurrentMp3()
    } catch (e: any) {
      showAlertModal(`error on submitting file: ${e.toString()}`)
      console.log('error on submitFile:', e)
    }
  }, [mp3File])

  const playCurrent = useCallback(() => {
    if (currentGreeting.playing) {
      currentGreeting.pauseAudio()
    } else {
      currentGreeting.playAudio()
    }
  }, [currentGreeting])

  return {
    currentGreeting,
    playCurrent,
    isRecording,
    startRecording,
    stopRecording,
    audio,
    curTime,
    duration,
    playProgress,
    setClickedTime,
    playing,
    pauseAudio,
    playAudio,
    setAudio,
    mp3File,
    submitFile
  }
}

export default useVoiceMailGreeting
