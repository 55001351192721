import { useQuery } from '@tanstack/react-query'
import APIClient from '../../apiClient'
import { MessageType } from '../../types'
import { useParams } from 'react-router-dom'

export enum MessagesType {
  new = 'new',
  inbox = 'inbox',
  saved = 'saved',
  deleted = 'deleted'
}

interface Options {
  refetchInterval?: number
  params?: {
    confidential?: boolean
  }
}

export const useGetMessagesByType = (type: MessagesType | string, options?: Options) => {
  return useQuery<unknown, unknown, MessageType[]>({
    queryKey: ['messages', type, options?.params],
    queryFn: async () => {
      const res = await APIClient.voiceMailList(type, { ...options?.params })
      return res.data
    },
    cacheTime: 1000 * 60 * 10, // 10 minutes
    ...options
  })
}
