import React from 'react'
import Header from './components/Header'
import Container from './components/Container'
import InfoContainer from './components/InfoContainer'
import PreviewContainer from './components/PreviewContainer'
import Avatar from './components/Avatar'
import NumberAvatar from './components/NumberAvatar'
import TextAvatar from './components/TextAvatar'
import AvatarsContainer from './components/AvatarsContainer'
import SwitchContainer from './components/SwitchContainer'
import AudioStrength from './components/AudioStrength'
import ClipboardButton from './components/ClipboardButton'
import CallText from './components/CallText'
import styled from 'styled-components'
import HostInfo from './components/HostInfo'

interface IProps {
  children: React.ReactNode
}

const RoomContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 108px);
  display: flex;
  flex-direction: row;
`

const WaitingRoom = (props: IProps) => {
  return <RoomContainer>{props.children}</RoomContainer>
}

WaitingRoom.Header = Header
WaitingRoom.Container = Container
WaitingRoom.InfoContainer = InfoContainer
WaitingRoom.PreviewContainer = PreviewContainer
WaitingRoom.Avatar = Avatar
WaitingRoom.NumberAvatar = NumberAvatar
WaitingRoom.TextAvatar = TextAvatar
WaitingRoom.AvatarsContainer = AvatarsContainer
WaitingRoom.SwitchContainer = SwitchContainer
WaitingRoom.AudioStrength = AudioStrength
WaitingRoom.ClipboardButton = ClipboardButton
WaitingRoom.CallText = CallText
WaitingRoom.HostInfo = HostInfo

export default WaitingRoom
