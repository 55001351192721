import styled from 'styled-components'
import { colors } from '../../../assets/colors'

const Caption = styled.span`
  color: ${colors.mediumGrey};
  font-size: 7px;
  text-align: center;
  padding: 10px;
`

export default Caption
