import React, { FC } from 'react'
import VideoConferencingHomePicture from '../../assets/images/video_conferencing_home.svg'
import format from 'date-fns/format'
import { colors } from '../../../assets/colors'
import DropDownButton from '../../components/DropDownButton'
import { Link } from 'react-router-dom'
import ScheduledConferences from '../../components/ScheduledConferences'
import ScheduledConferenceCard from '../../components/ScheduledConferenceCard'
import { Loader } from '../../../components/Loader/Loader'
import VideoConferencingHeader from '../../components/Header'
import { usePopup } from 'react-custom-popup'
import ConfirmDialog from '../../components/dialogs/ConfirmDialog'
import RecentConference from '../../components/RecentConference'
import RecentConferenceCard from '../../components/RecentConferenceCard'
import ReportsLink from '../../components/ReportsLink'
import { Container, Content, Description, LeftContainer, RightContainer, Title } from './components/styled'
import { useVideoConferencing } from './useVideoConferencing'
import BackArrow from '../../../components/Icon/BackArrow'
import { useHistory } from 'react-router'
import styled from 'styled-components'

const BackText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.darkBlue};
`

const StyledLink = styled.a`
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 54px;
  justify-content: space-between;
  cursor: pointer;
  opacity: 0.95;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

const VideoConferencing: FC = () => {
  const { isLoading, scheduledConferences, recentConference, onDelete } = useVideoConferencing()
  const { showModal, hideModal } = usePopup()
  const history = useHistory()

  const onPressDelete = async (conference: any) => {
    showModal(
      <ConfirmDialog
        title="Delete meeting"
        message="Are you sure you want to delete this meeting?"
        actionName="Delete"
        onCancelPress={() => hideModal()}
        onActionPress={() => onDelete(conference)}
      />
    )
  }

  return (
    <Container>
      <VideoConferencingHeader>
        <VideoConferencingHeader.Element>{format(new Date(), 'h:mm aaaa')}</VideoConferencingHeader.Element>
        <VideoConferencingHeader.Element>{format(new Date(), 'EEE, d MMMM')}</VideoConferencingHeader.Element>
        <VideoConferencingHeader.Element>
          <Link to={'/options'}>
            <VideoConferencingHeader.ProfileLink />
          </Link>
        </VideoConferencingHeader.Element>
      </VideoConferencingHeader>
      <div style={{ marginLeft: 20 }}>
        <StyledLink onClick={() => history.push(`${window.location.origin}/`)}>
          <BackArrow />
          <BackText>Back</BackText>
        </StyledLink>
      </div>

      <Content>
        <LeftContainer>
          <div style={{ marginBottom: 15 }}>
            <Title>Video Conferencing for business</Title>
          </div>
          <div style={{ marginBottom: 30 }}>
            <Description>Connect, collaborate and get ahead from anywhere with Kate Meetings.</Description>
          </div>
          <DropDownButton>New Meeting</DropDownButton>
        </LeftContainer>
        {isLoading ? (
          <Loader width={'20px'} height={'20px'} fill={colors.darkGrey} style={{ left: '90%' }} />
        ) : scheduledConferences?.length || (recentConference && recentConference?.createdAt) ? (
          <RightContainer>
            {recentConference && recentConference?.createdAt ? (
              <RecentConference
                icons={
                  <Link style={{ textDecoration: 'none' }} to={'/reports'}>
                    <ReportsLink />
                  </Link>
                }
              >
                <RecentConferenceCard
                  key={recentConference?.meetingId}
                  startDate={recentConference?.startTime}
                  endDate={recentConference?.endTime}
                  createdAt={recentConference?.createdAt}
                  title={recentConference?.title}
                  description={recentConference?.description}
                  meetingId={recentConference?.meetingId}
                  attendees={recentConference?.attendees}
                  onPressDelete={() => onPressDelete(recentConference)}
                />
              </RecentConference>
            ) : null}
            <ScheduledConferences
              icons={
                !recentConference || !recentConference?.createdAt ? (
                  <Link style={{ textDecoration: 'none' }} to={'/reports'}>
                    <ReportsLink />
                  </Link>
                ) : (
                  <div />
                )
              }
              showTitle={scheduledConferences?.length > 0}
            >
              {scheduledConferences?.map((conference) => (
                <ScheduledConferenceCard
                  key={conference.meetingId}
                  startDate={conference.startTime}
                  endDate={conference.endTime}
                  title={conference.title}
                  description={conference.description}
                  meetingId={conference.meetingId}
                  attendees={conference.attendees}
                  onPressDelete={() => onPressDelete(conference)}
                />
              ))}
            </ScheduledConferences>
          </RightContainer>
        ) : (
          <img src={VideoConferencingHomePicture} alt="Video Conferencing Home" />
        )}
      </Content>
    </Container>
  )
}

export default VideoConferencing
