import WaveSurfer from 'wavesurfer.js'
import React, { useEffect, useRef, useState } from 'react'
import './waveform.scss'
import { colors } from '../../../../../../../assets/colors'
import { useWaveSurfer } from '../audioPlayerStore'

const AudioVisualizer = ({ audioUrl = null, audioFile = null }) => {
  const waveformRef = useRef(null)
  const waveSurferRef = useRef<WaveSurfer>(null)
  const hoverRef = useRef(null)
  const [waveSurfer, setWaveSurfer] = useWaveSurfer()

  useEffect(() => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height * 1.35)
    gradient.addColorStop(0, '#656666') // Top color
    gradient.addColorStop((canvas.height * 0.7) / canvas.height, '#656666') // Top color
    // gradient.addColorStop((canvas.height * 0.7 + 1) / canvas.height, '#ffffff') // White line
    // gradient.addColorStop((canvas.height * 0.7 + 2) / canvas.height, '#ffffff') // White line
    // gradient.addColorStop((canvas.height * 0.6 + 2) / canvas.height, '#B1B1B1') // Bottom color
    // gradient.addColorStop(1, 'transparent') // Bottom color

    const progressGradient = ctx.createLinearGradient(0, 0, 0, canvas.height * 1.35)
    progressGradient.addColorStop(0, colors.darkBlue) // Top color
    progressGradient.addColorStop((canvas.height * 0.7) / canvas.height, colors.darkBlue) // Top color
    // progressGradient.addColorStop((canvas.height * 0.7 + 1) / canvas.height, '#ffffff') // White line
    // progressGradient.addColorStop((canvas.height * 0.7 + 2) / canvas.height, '#ffffff') // White line
    // progressGradient.addColorStop((canvas.height * 0.7 + 3) / canvas.height, 'transparent') // Bottom color
    // progressGradient.addColorStop(1, 'transparent') // Bottom color

    waveSurferRef.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: gradient,
      progressColor: progressGradient,
      barWidth: 4,
      barHeight: 0.7,
      height: 200
    })

    let mp3Url = ''
    if (audioFile) {
      const mp3Blob = new Blob([audioFile], { type: 'audio/mp3' })
      mp3Url = URL.createObjectURL(mp3Blob)
    }

    waveSurferRef.current.load(audioUrl || mp3Url)

    setWaveSurfer(waveSurferRef.current)

    // Cleanup wavesurfer instance on component unmount
    return () => {
      waveSurferRef?.current.destroy()
      setWaveSurfer(null)
    }
  }, [audioUrl, waveformRef?.current])

  useEffect(() => {
    if (!waveformRef?.current) return
    const hoverRefWidth = (e) => {
      if (hoverRef.current) {
        hoverRef.current.style.width = `${e.offsetX}px`
      }
    }
    waveformRef.current.addEventListener('pointermove', hoverRefWidth)

    return () => {
      if (waveformRef?.current) {
        waveformRef?.current.removeEventListener('pointermove', hoverRefWidth)
      }
    }
  }, [waveformRef, hoverRef])

  return (
    <>
      <div id="waveform" ref={waveformRef}>
        <div id="hover" ref={hoverRef} />
      </div>
    </>
  )
}

export default AudioVisualizer
