import React from 'react'
import styled from 'styled-components'
import { colors } from '../../assets/colors'
import Icon from '../Icon'

const StyledButton = styled.button`
  color: ${(props) => props.color}
  //border: 0;
  outline: none;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  width: fit-content;
  background: transparent;
  cursor: pointer;
  border: 1px solid ${colors.mediumGrey};
  border-radius: 42px;
`

interface BoolButtonProps {
  name: string
  activeName: string
  label: string
  isActive?: boolean
  onClick: () => void
  style?: React.CSSProperties
  iconStyle?: React.CSSProperties
}

const BoolButton = ({ name, activeName, label, isActive, onClick, style, iconStyle }: BoolButtonProps) => {
  const color = isActive ? colors.white : colors.mediumGrey
  return (
    <StyledButton
      color={color}
      style={style}
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
    >
      <Icon
        fill={color}
        name={isActive ? activeName : name}
        style={{ width: '20px', height: '20px', marginRight: '5px', ...iconStyle }}
      />
      {label}
    </StyledButton>
  )
}

export default BoolButton
