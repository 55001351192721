import React from 'react'
import { NavigationAside } from '../Components/NavigationAside'
import { NavigationLink } from '../Components/NavigationLink'
import useCurrentTab from '../../Common/Hooks/useCurrentTab'
import useIsMobile from '../../Common/Hooks/useIsMobile'
import { TUseMobileNavigation } from '../../Common/Types/UseMobileNavigation'
import Icon from '../../../../../../../components/Icon'
import { Link } from 'react-router-dom'
import { useUserStore } from '../../../../../../../store/user'
import { colors } from '../../../../../../../assets/colors'

const BillingNavigation = ({ expanded, toggleNavigationExpanded }: TUseMobileNavigation) => {
  const [currentTab] = useCurrentTab()
  const isMobile = useIsMobile()
  const accountNeedAttention = useUserStore((state) => state.accountNeedAttention)

  return (
    <>
      <NavigationAside isMobile={isMobile} expanded={expanded}>
        {isMobile && (
          <Link
            to="/options"
            style={{
              textDecoration: 'none',
              marginBottom: '30px',
              display: 'block',
              marginLeft: '10px',
              color: '#1D70B6'
            }}
          >
            <Icon
              fill="#1D70B6"
              name={'chevronRight'}
              style={{ width: '15px', height: '15px', transform: 'rotate(180deg)', marginRight: '10px' }}
            />
            Options
          </Link>
        )}
        <ul>
          {/*<li>*/}
          {/*  <NavigationLink active={currentTab === null} to="/billing" onClick={toggleNavigationExpanded}>*/}
          {/*    Account Balance*/}
          {/*  </NavigationLink>*/}
          {/*</li>*/}
          <li>
            <NavigationLink
              active={currentTab === 'payment-options'}
              to="/billing?tab=payment-options"
              onClick={toggleNavigationExpanded}
            >
              Payment Options
            </NavigationLink>
          </li>
          <li>
            <NavigationLink
              active={currentTab === 'billing-portal'}
              to="/billing?tab=billing-portal"
              onClick={toggleNavigationExpanded}
            >
              Billing Portal
            </NavigationLink>
          </li>
          {accountNeedAttention && (
            <li>
              <NavigationLink
                active={currentTab === 'resolve-now'}
                to="/billing?tab=resolve-now"
                onClick={toggleNavigationExpanded}
                style={{ color: colors.red2 }}
              >
                Billing Issues
              </NavigationLink>
            </li>
          )}
          {/*<li>*/}
          {/*  <NavigationLink*/}
          {/*    active={currentTab === 'price-plans'}*/}
          {/*    to="/billing?tab=price-plans"*/}
          {/*    onClick={toggleNavigationExpanded}*/}
          {/*  >*/}
          {/*    Price Plans*/}
          {/*  </NavigationLink>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <NavigationLink*/}
          {/*    active={currentTab === 'billing-address'}*/}
          {/*    to="/billing?tab=billing-address"*/}
          {/*    onClick={toggleNavigationExpanded}*/}
          {/*  >*/}
          {/*    Billing Address*/}
          {/*  </NavigationLink>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <NavigationLink*/}
          {/*    active={currentTab === 'billing-statements'}*/}
          {/*    to="/billing?tab=billing-statements"*/}
          {/*    onClick={toggleNavigationExpanded}*/}
          {/*  >*/}
          {/*    Billing Statements*/}
          {/*  </NavigationLink>*/}
          {/*</li>*/}
        </ul>
      </NavigationAside>
      {isMobile && expanded && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 2,
            background: 'rgba(4,23,40,0.7)'
          }}
          onClick={toggleNavigationExpanded}
        />
      )}
    </>
  )
}

export default BillingNavigation
