import React, { SVGProps } from 'react'

const ParticipantSelected = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24Zm6.707 9.707-7.56 7.56a1 1 0 0 1-1.414 0L6.28 13.814A1 1 0 0 1 7.694 12.4l2.746 2.746 6.853-6.853a1 1 0 1 1 1.414 1.414Z"
      fill="#1D70B6"
    />
  </svg>
)

export default ParticipantSelected
