import { useEffect, useRef, useState } from 'react'
import { createBillableEvent } from '../events'
import { conferenceEventsLogger, guestConferenceEventsLogger } from '../../../logger/ConferenceEventsLogger'
import { useUserStore } from '../../../../store/user'

export const useBillingEvents = () => {
  const heartbeatsInterval = useRef<number>()
  const [startBilling, setStartBilling] = useState<boolean>(false)
  const authenticated = useUserStore((state) => state.isAuthenticated)
  const [connectionCreatedTimestamp, setConnectionCreatedTimestamp] = useState<number>(0)

  const onConnectedEvent = () => {
    const perf = performance.now()
    setConnectionCreatedTimestamp(perf)
    localStorage.setItem('created_time_perf', perf.toString())
    setStartBilling(true)
  }

  const onDestroyEvent = (event: any) => {
    if (!event) return
    const currentTimestamp = performance.now()
    let billableEvent = createBillableEvent(event, 'connectionDestroyed', false)
    const created = localStorage.getItem('created_time_perf')
    billableEvent = {
      ...billableEvent,
      timestampEnd: Number(localStorage.getItem('created_time')) + (currentTimestamp - Number(created))
    }
    localStorage.setItem('bevent', '')
    if (authenticated) {
      conferenceEventsLogger.enqueueEvent(billableEvent)
    } else {
      guestConferenceEventsLogger.enqueueEvent(billableEvent)
    }
  }

  // TODO: Need to rethink it later
  useEffect(() => {
    if (startBilling && !heartbeatsInterval.current) {
      heartbeatsInterval.current = setInterval(() => {
        const currentTimestamp = performance.now()
        let billableEventToSave = null
        if (localStorage.getItem('bevent')) {
          billableEventToSave = JSON.parse(localStorage.getItem('bevent') || '')
        }
        if (billableEventToSave) {
          billableEventToSave = {
            ...billableEventToSave,
            timestampStart: Number(localStorage.getItem('created_time')),
            timestampEnd:
              Number(localStorage.getItem('created_time')) + (currentTimestamp - connectionCreatedTimestamp),
            event: 'connectionHeartBeat'
          }
          if (authenticated) {
            conferenceEventsLogger.enqueueEvent(billableEventToSave)
          } else {
            guestConferenceEventsLogger.enqueueEvent(billableEventToSave)
          }
        }
      }, 6000)
    }
    return () => clearInterval(heartbeatsInterval?.current)
  }, [startBilling])

  return {
    onConnectedEvent,
    onDestroyEvent
  }
}
