import React from 'react'
import { ListSelectionWrapper } from '../../../../../../../components/GroupsAndContacts/Styled'
import { colors } from '../../../../../../../assets/colors'
import { Button } from '../../../../../../../components/Button'
import { StyledCreateContactForm } from '../../Styled/StyledCreateContactForm'
import { GroupItemType } from '../../../../../../../types'
import styled from 'styled-components'
import Icon from '../../../../../../../components/Icon'
import ContactRow from '../../../../../../../components/GroupsAndContacts/ContactRow'
import { Virtuoso } from 'react-virtuoso'

interface IProps {
  onClose?: () => void
  groupsData?: GroupItemType[]
  formik: any
}

const GroupSelect = ({ onClose, groupsData, formik }: IProps) => {
  const toggleGroupSelect = (id: string) => {
    const groupsIds = formik?.values?.groups?.map((g) => g?.id)
    if (groupsIds?.includes(id)) {
      const updatedGroups = [...formik.values.groups.filter((g) => g.id !== id)]
      formik.setFieldValue('groups', updatedGroups)
    } else {
      const updatedGroups = [...(formik.values.groups || []), groupsData.find((g) => g.id === id)]
      formik.setFieldValue('groups', updatedGroups)
    }
  }

  return (
    <ListSelectionWrapper>
      <StyledCreateContactForm.Wrapper>
        <p style={{ color: colors.darkBlue, fontSize: '14px' }}>Edit groups</p>
        <Button style={{ width: '150px', height: '40px' }} type="submit" onClick={onClose}>
          Done
        </Button>
      </StyledCreateContactForm.Wrapper>
      <Virtuoso
        style={{ height: 'calc(100% - 90px)' }}
        data={groupsData}
        itemContent={(_, group) => {
          return (
            <StyledGroupRow key={group.id} onClick={() => toggleGroupSelect(group.id)}>
              <p>{group.name}</p>
              <div>
                {formik?.values?.groups?.map((g) => g.id)?.includes(group.id) ? (
                  <Icon name="checkmark" fill={colors.darkBlue} style={{ width: '20px', marginRight: '10px' }} />
                ) : null}
              </div>
            </StyledGroupRow>
          )
        }}
      />
    </ListSelectionWrapper>
  )
}

export default GroupSelect

const StyledGroupRow = styled.div`
  height: 55px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  padding: 18px 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: rgba(29, 112, 182, 0.05);
  }
`
