import React from 'react'
import styled from 'styled-components'
import Logo from '../components/Icon/Logo'
import LeftPanel from './components/LeftPanel'
import { colors } from '../assets/colors'
import { useLocation, useParams } from 'react-router-dom'
import { useHistory } from 'react-router'
import Plus from '../components/Icon/Plus'
import useCurrentParam from '../hooks/useCurrentParam'
import SendMessage from '../navigation/desktop/pages/Messages/SendMessage'
import NewActionButton from '../navigation/desktop/components/NewActionButton'
import CommuniKateLogoTitle from '../components/Icon/CommuniKateLogoTitle'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  background-color: #fff;
  padding: 0 24px;
`

const LogoAndActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const LogoContainer = styled.div`
  //width: 208px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NewMessageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: auto;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;

  span {
    font-size: 14px;
    font-family: Open Sans, sans-serif;
    margin-left: 5px;
    color: ${colors.darkBlue};
    font-weight: 600;
  }
`

const TopBar = () => {
  const location = useLocation()
  const history = useHistory()

  const redirectToNewContactForm = () => {
    history.push(`/contacts?action=create`)
  }

  const redirectToNewGroupForm = () => {
    history.push(`/groups?action=create`)
  }

  return (
    <>
      <Container>
        <LogoAndActionsWrapper>
          <div style={{ display: 'flex' }}>
            <LogoContainer>
              <CommuniKateLogoTitle />
            </LogoContainer>
          </div>

          <div>
            {location.pathname.includes('/messages') && !location.search.includes('action') && (
              <NewActionButton
                style={{ width: 'auto' }}
                onClick={() => history.push(`${window.location.pathname}?action=new-message`)}
              >
                <span style={{ fontSize: '22px', marginRight: '8px' }}>+ </span> New message
              </NewActionButton>
            )}
            {location.pathname.includes('/contacts') && !location.search.includes('action') && (
              <NewActionButton onClick={redirectToNewContactForm}>
                <span style={{ fontSize: '22px', marginRight: '5px' }}>+ </span> New contact
              </NewActionButton>
            )}
            {location.pathname.includes('/groups') && !location.search.includes('action') && (
              <NewActionButton onClick={redirectToNewGroupForm}>
                <span style={{ fontSize: '22px', marginRight: '5px' }}>+ </span> New group
              </NewActionButton>
            )}
          </div>
          {/*<TopCenterPanel>Menu</TopCenterPanel>*/}
        </LogoAndActionsWrapper>
        {/*<TopRightSidePanel>Voice Message Limit</TopRightSidePanel>*/}
      </Container>
    </>
  )
}

const MainContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
`

export const CentralPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 384px;
  height: 100%;
  background: ${colors.white};
  border-radius: 10px;
  margin: 5px 2.5px;
`
export const RightPanel = styled.div`
  display: flex;
  width: calc(100vw - 604.5px);
  height: 100%;
  background: ${colors.white};
  border-radius: 10px;
  margin: 5px 5px 5px 2.5px;
`

export const Layout = ({ children }) => {
  const [action] = useCurrentParam('action')

  return (
    <>
      <TopBar />
      <MainContainer>
        <LeftPanel />
        {children}
        {action === 'new-message' && (
          <RightPanel style={{ position: 'absolute', right: 0, zIndex: 100 }}>
            <SendMessage />
          </RightPanel>
        )}
      </MainContainer>
    </>
  )
}
