import React from 'react'
import styled from 'styled-components'
import Icon from '../Icon'

interface IFooterButtons {
  name: string
  id: string
  onClick: () => void
  fill: string
  gradientButton?: boolean
}

const Button = styled.button`
  border: none;
  background: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.75;
  }
`

const GradientButton = styled.button`
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.75;
  }
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background: linear-gradient(224.26deg, #3fb5e9 -23.22%, #1d70b6 123.1%);
  display: flex;
  justify-content: center;
  align-items: center;
`

const FooterButton = ({ name, id, onClick, fill, gradientButton }: IFooterButtons) => {
  if (gradientButton) {
    return (
      <GradientButton onClick={() => onClick()} id={id}>
        <Icon name={name} fill={fill} style={{ width: '24px' }} />
      </GradientButton>
    )
  }
  return (
    <Button id={id} onClick={() => onClick()}>
      <Icon name={name} fill={fill} style={{ width: '38px' }} />
    </Button>
  )
}

export default FooterButton
