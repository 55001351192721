import { useEffect, useState } from 'react'
import { logoutUser } from '../../../../../../auth/logout'
import { showAlertModal } from '../../../../../../store/modals'
import { useUserStore } from '../../../../../../store/user'
import useCurrentTab from '../../Billing/Shared/Common/Hooks/useCurrentTab'
import { useHistory } from 'react-router'

const useOptionsNavigation = () => {
  const [confirmLogoutModal, setConfirmLogoutModal] = useState(false)
  const billingProvider = useUserStore((state) => state.user?.billing_provider)
  const [currentTab] = useCurrentTab()
  const history = useHistory()

  useEffect(() => {
    if (history.location.pathname !== '/options') return
    if (!billingProvider) {
      history.push('/options/account-information')
      return
    }
    if (!currentTab) {
      history.push('/options/billing?tab=payment-options')
    }
  }, [])

  const logout = async () => {
    try {
      await logoutUser()
    } catch (e: any) {
      showAlertModal(e.toString())
      console.log('error on logout: ', e)
    }
  }

  const showConfirmLogoutModal = () => {
    setConfirmLogoutModal(true)
  }

  const hideConfirmLogoutModal = () => {
    setConfirmLogoutModal(false)
  }

  const onConfirmLogout = async () => {
    setConfirmLogoutModal(false)
    await logout()
  }

  return {
    onConfirmLogout,
    confirmLogoutModal,
    showConfirmLogoutModal,
    hideConfirmLogoutModal,
    billingProvider
  }
}

export default useOptionsNavigation
