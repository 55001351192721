export const getStripeCustomerPaymentMethodsQuery = `
  query getStripeCustomerPaymentMethodsQuery {
    getStripeCustomerPaymentMethods {
       data {
           id
           card {
               brand
               last4
               
           }
       }
    }
  }
`

export type CardBrand = 'visa' | 'mastercard' | 'amex' | 'discover' | 'diners' | 'jcb' | 'unionpay' | null | undefined

type StripeCard = {
  brand: CardBrand
  last4: string
}

type StripePaymentMethod = {
  id: string
  card: StripeCard
}

export type StripePaymentMethodResponse = {
  data: [StripePaymentMethod]
}
