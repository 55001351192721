import React from 'react'
import Button from '@mui/material/Button'
import { colors } from '../../../../../../../assets/colors'
import { Box, Popover, Typography } from '@mui/material'
import { WaveSurferEvents } from 'wavesurfer.js'
import { useWaveSurfer } from '../../../containers/AudioPlayer/audioPlayerStore'

const AudioSpeed = () => {
  const [speedAnchorEl, setSpeedAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [waveSurfer] = useWaveSurfer()
  const onChangeSpeed = (speed: number) => {
    if (!waveSurfer) return
    waveSurfer?.setPlaybackRate(speed)
    setSpeedAnchorEl(null)
  }

  return (
    <Box mr={2}>
      <Button
        onClick={(e) => setSpeedAnchorEl(e.currentTarget)}
        sx={{ cursor: 'pointer', textTransform: 'none', color: colors.darkBlue, margin: 0, padding: 0 }}
      >
        <Typography>{waveSurfer?.getPlaybackRate()}x speed</Typography>
      </Button>
      <Popover
        open={Boolean(speedAnchorEl)}
        anchorEl={speedAnchorEl}
        onClose={() => setSpeedAnchorEl(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Box display={'flex'} flexDirection={'column-reverse'}>
          <Button
            onClick={() => onChangeSpeed(1)}
            sx={{ cursor: 'pointer', textTransform: 'none', color: colors.mediumGrey }}
          >
            <Typography fontWeight={'400'} p={1}>
              1x
            </Typography>
          </Button>
          <Button
            onClick={() => onChangeSpeed(1.25)}
            sx={{ cursor: 'pointer', textTransform: 'none', color: colors.mediumGrey }}
          >
            <Typography fontWeight={'400'} p={1}>
              1.25x
            </Typography>
          </Button>
          <Button
            onClick={() => onChangeSpeed(1.5)}
            sx={{ cursor: 'pointer', textTransform: 'none', color: colors.mediumGrey }}
          >
            <Typography fontWeight={'400'} p={1}>
              1.5x
            </Typography>
          </Button>
          <Button
            onClick={() => onChangeSpeed(1.75)}
            sx={{ cursor: 'pointer', textTransform: 'none', color: colors.mediumGrey }}
          >
            <Typography fontWeight={'400'} p={1}>
              1.75x
            </Typography>
          </Button>
          <Button
            onClick={() => onChangeSpeed(2)}
            sx={{ cursor: 'pointer', textTransform: 'none', color: colors.mediumGrey }}
          >
            <Typography fontWeight={'400'} p={1}>
              2x
            </Typography>
          </Button>
        </Box>
      </Popover>
    </Box>
  )
}

export default AudioSpeed
