import React, { SVGProps } from 'react'

const Mute = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="
      M0.317264 -0.500122L-0.5 0.317142L15.6827 16.4999L16.5 15.6826L11.6687 10.8513C11.9089 10.3353 12.0457 9.76202 12.0457 9.15579V6.84397C12.0457 6.52436 11.7873 6.26601 11.4677 6.26601C11.1481 6.26601 10.8898 6.52436 10.8898 6.84397V9.15579C10.8898 9.4326 10.8394 9.69507 10.7645 9.94709L9.73386 8.91648V2.22033C9.73386 1.26266 8.95767 0.486465 8 0.486465C7.04233 0.486465 6.26614 1.26266 6.26614 2.22033V5.44875L0.317264 -0.500122ZM4.08413 6.49403C4.00784 6.59171 3.95432 6.70988 3.95432 6.84397V9.15579C3.95432 11.1896 5.46509 12.8731 7.42204 13.1552V14.3574H5.68818C5.36857 14.3574 5.11023 14.6157 5.11023 14.9353C5.11023 15.2549 5.36857 15.5133 5.68818 15.5133H10.3118C10.6314 15.5133 10.8898 15.2549 10.8898 14.9353C10.8898 14.6157 10.6314 14.3574 10.3118 14.3574H8.57795V13.1552C9.15244 13.0725 9.68672 12.8664 10.1572 12.5682L9.30717 11.7182C8.91301 11.9211 8.47277 12.0456 8 12.0456C6.40658 12.0456 5.11023 10.7492 5.11023 9.15579V7.52126L4.08413 6.49403ZM6.26614 8.67717V9.15579C6.26614 10.1135 7.04233 10.8897 8 10.8897C8.14622 10.8897 8.28581 10.8662 8.42105 10.8321L6.26614 8.67717Z"
      fill="#FFFFFF"
    />
  </svg>
)

export default Mute
