import styled from 'styled-components'
import React from 'react'
import { colors } from '../../../assets/colors'

const PlayArea = styled.div`
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${colors.mediumGrey};
  border-right: 1px solid ${colors.lightGrey};
`

export default PlayArea
