import React from 'react'
import { AutoplayFormWrapper } from '../Styled'
import SwitchButton from '../../../../../../../components/Button/SwitchButton'
import useAutoplay from '../../Common/Hooks/useAutoplay'

const AutoplayContent = () => {
  const { autoPlay, changeAutoPlay } = useAutoplay()
  return (
    <AutoplayFormWrapper>
      <p style={{ fontSize: '13px' }}>Autoplay enabled</p>
      <SwitchButton isActive={autoPlay} onClick={changeAutoPlay} />
    </AutoplayFormWrapper>
  )
}

export default AutoplayContent
