import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface AutoPlayStore {
  visible: boolean
  enabled: boolean
  nextUnreadMessageId: string
  actions: {
    setAutoPlayEnabled: (enabled: boolean) => void
    setAutoPlayVisible: (visible: boolean) => void
    setNextUnreadMessageId: (nextUnreadMessageId: string) => void
  }
}
export const useAutoPlayStore = create<AutoPlayStore>()(
  persist(
    (set) => ({
      visible: false,
      enabled: true,
      nextUnreadMessageId: '',
      actions: {
        setAutoPlayEnabled: (enabled: boolean) => set({ enabled }),
        setAutoPlayVisible: (visible: boolean) => set({ visible }),
        setNextUnreadMessageId: (nextUnreadMessageId: string) => set({ nextUnreadMessageId })
      }
    }),
    {
      name: 'auto-play-storage',
      partialize: (state) => ({ enabled: state.enabled })
    }
  )
)

export const useAutoPlayActions = () => useAutoPlayStore((state) => state.actions)
