import React, { FC, useEffect, useState } from 'react'
import format from 'date-fns/format'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import VideoConferencingHeader from '../../components/Header'
import ReportsRoom from '../../components/blocks/ReportsRoom'
import ColumnView from '../../components/blocks/ColumnView'
import { LeftContainer } from '../../components/blocks/ColumnView/components/LeftContainer'
import { Loader } from '../../../components/Loader/Loader'
import { colors } from '../../assets/colors'
import { getReports } from './getReports'
import { useGetReports } from '../../../hooks/queries/useGetReports'

const StyledLeftContainer = styled(LeftContainer)`
  align-items: flex-start;
`

const Reports: FC = () => {
  const { data, isLoading } = useGetReports()
  const years = getReports(data)

  return (
    <ColumnView>
      <VideoConferencingHeader>
        <VideoConferencingHeader.Element>{format(new Date(), 'h:mm aaaa')}</VideoConferencingHeader.Element>
        <VideoConferencingHeader.Element>{format(new Date(), 'EEE, d MMMM')}</VideoConferencingHeader.Element>
        <VideoConferencingHeader.Element>
          <Link to={'/options'}>
            <VideoConferencingHeader.ProfileLink />
          </Link>
        </VideoConferencingHeader.Element>
      </VideoConferencingHeader>
      <div style={{ marginLeft: 20 }}>
        <ReportsRoom.BackLink />
      </div>
      <ColumnView.Content>
        <StyledLeftContainer>
          <div style={{ marginBottom: 15 }}>
            <ColumnView.Title>Reports</ColumnView.Title>
          </div>
        </StyledLeftContainer>
        <ColumnView.RightContainer>
          <ReportsRoom>
            {isLoading ? (
              <Loader width={'20px'} height={'20px'} fill={colors.darkGrey} style={{ marginTop: 50 }} />
            ) : (
              <>
                {years?.map((year) => {
                  return (
                    <div style={{ width: '100%' }} key={year?.year}>
                      <ReportsRoom.YearText>{year?.year}</ReportsRoom.YearText>
                      <ReportsRoom.ReportRectangleContainer>
                        {year?.months?.map((month) => (
                          <Link
                            key={month?.name + year?.year}
                            to={`/report/${month?.from}/${month?.to}`}
                            style={{ textDecoration: 'none' }}
                          >
                            <ReportsRoom.ReportRectangle
                              key={month?.name}
                              month={month?.name}
                              price={month?.dollarAmount}
                            />
                          </Link>
                        ))}
                      </ReportsRoom.ReportRectangleContainer>
                    </div>
                  )
                })}
              </>
            )}
          </ReportsRoom>
        </ColumnView.RightContainer>
      </ColumnView.Content>
    </ColumnView>
  )
}

export default Reports
