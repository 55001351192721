import React, { ReactNode, useState, ChangeEventHandler } from 'react'
import styled, { CSSObject } from 'styled-components'
import { colors } from '../../../assets/colors'

const StyledInput = styled.input`
  width: 100%;
  background: ${colors.silver};
  border-radius: 10px;
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 12px 15px;

  :focus {
    border-bottom: 2px solid #1d70b6;
    padding-bottom: 10px;
  }
`

const StyledLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.mediumGrey};
  margin-left: 10px;
  margin-bottom: 5px;
`

const IconContainer = styled.div`
  margin-top: 2px;
`

type InputProps = {
  icon: ReactNode
  label: string
  value: string | null
  onChange?: ChangeEventHandler<HTMLInputElement>
  style?: CSSObject
  name?: string
}

const Input: React.FC<InputProps> = ({ icon, label, value, onChange, name, style }) => {
  return (
    <div style={style}>
      <div style={{ display: 'flex' }}>
        <IconContainer>{icon}</IconContainer>
        <StyledLabel>{label}</StyledLabel>
      </div>
      <StyledInput value={value} onChange={onChange} name={name} />
    </div>
  )
}

export default Input
