import React, { SVGProps } from 'react'

const CameraOff = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="
      M1.3535 0.5L0.6465 1.22109L14.6465 15.5L15.3535 14.7789L11 10.3387V4.43027C11 4.15977 10.8946 3.90035 10.7071 3.70908C10.5196 3.5178 10.2652 3.41035 10 3.41035H4.207L1.3535 0.5ZM1.4295 3.41749C1.1776 3.4348 0.941526 3.54899 0.76899 3.73699C0.596454 3.92498 0.500315 4.17277 0.5 4.43027L0.5 11.5697C0.5 11.8402 0.605357 12.0996 0.792893 12.2909C0.98043 12.4822 1.23478 12.5897 1.5 12.5897H10C10.1217 12.5886 10.2422 12.5649 10.3555 12.5198L1.4295 3.41749ZM15 4.43027C14.8693 4.42988 14.7437 4.48168 14.65 4.57459L12 6.98008V9.01992L14.67 11.4422C14.7422 11.5069 14.8311 11.549 14.9262 11.5635C15.0212 11.578 15.1183 11.5642 15.2059 11.5239C15.2934 11.4835 15.3677 11.4183 15.4199 11.336C15.4721 11.2537 15.4999 11.1578 15.5 11.0598V4.94023C15.5 4.80498 15.4473 4.67527 15.3536 4.57964C15.2598 4.484 15.1326 4.43027 15 4.43027Z"
      fill="#FFFFFF"
    />
  </svg>
)

export default CameraOff
