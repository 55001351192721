import React, { useEffect } from 'react'
import { CentralPanel, Layout, RightPanel } from '../../../../layout/Layout'
import MessagesNavigation from '../../../mobile/pages/Messages/components/MessagesNavigation'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'
import { useGetMessagesByType } from '../../../../hooks/queries/useGetMessagesByType'
import { Virtuoso } from 'react-virtuoso'
import styled, { css } from 'styled-components'
import { colors } from '../../../../assets/colors'
import format from 'date-fns/format'
import { useHistory, useLocation } from 'react-router'
import Spinner from '../../../../components/Icon/Spinner'
import Header from './components/Header'
import UpToDateBox from './components/UpToDateBox'
import MessageView from './components/MessageView'
import AvatarContact from './components/AvatarContact'
import { useUser } from '../../../../store/user'

const newMessage = css`
  background: ${colors.darkBlue};
`

const urgentMessage = css`
  background: ${colors.red3};
`

const privateMessage = css`
  background: ${colors.mediumGrey2};
`

type Props = {
  new?: boolean
  urgent?: boolean
  private?: boolean
}

const MessageContainer = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 58px;
  min-height: 58px;
  cursor: pointer;
  border-left: 4px solid transparent;
  background: ${({ active }) => (active ? colors.veryLightBlue2 : colors.white)};

  :hover {
    background-color: ${colors.veryLightBlue2};
  }
`

const Line = styled.div`
  height: 1px;
  background: rgba(112, 112, 112, 0.2);
  width: auto;
  margin-left: 50px;
`

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 10px;
`

const Name = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: ${colors.lightBlack};
`
const TextNote = styled.span`
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  color: ${colors.mediumGrey};
  margin-top: 5px;
`
const MessageDuration = styled.span`
  padding: 0 10px;
  width: 50px;
  color: ${colors.mediumGrey};
`
const Image = styled.img`
  width: 86px;
  height: 49px;
  border-radius: 10px;
  object-fit: cover;
`

const StatusLine = styled.div`
  width: 4px;
  height: 58px;
  background-color: transparent;
  border-radius: 20px;

  ${(props: Props) => props.new && newMessage};
  ${(props: Props) => props.private && privateMessage};
  ${(props: Props) => props.urgent && urgentMessage};
`

const MessagesData = () => {
  const { type } = useParams<{ type?: string }>()
  const { data: messages, isLoading } = useGetMessagesByType(type, {
    refetchInterval: 5000,
    params: { confidential: true }
  })
  return <MessageListViewDesktop messages={messages} isLoading={isLoading} />
}

const formatNote = (note: string) => {
  if (note?.length > 35) {
    return note?.slice(0, 35) + '...'
  }
  return note
}

const MessageListViewDesktop = ({ messages, isLoading }) => {
  const { type } = useParams<{ type?: string }>()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const id = params.get('id')
  const history = useHistory()
  const messagesFolderNew = history.location.pathname === '/messages/new'

  useEffect(() => {
    if (messages?.length && !id && !location.search.includes('new-message')) {
      history.push(
        `/messages/${type}?id=${messages?.[0]?.uniqueId}${messages?.[0]?.confidential ? '&confidential=true' : ''}`
      )
    }
  }, [messages, id])

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5%' }}>
        <Spinner fill={colors.darkBlue} width={'20px'} />
      </div>
    )
  }

  if (!isLoading && !messages?.length && messagesFolderNew) {
    return <UpToDateBox />
  }

  if (!isLoading && !messages?.length && !messagesFolderNew) {
    return <Header>No Messages</Header>
  }

  return (
    <Virtuoso
      style={{ height: 'calc(100% - 58px)' }}
      data={messages as any}
      itemContent={(index, message) => {
        const names =
          message?.contact?.first_name || message?.contact?.last_name
            ? `${message?.contact?.first_name || ''} ${message?.contact?.last_name || ''}`
            : message?.source_kate_account?.first_name || message?.source_kate_account?.last_name
            ? `${message?.source_kate_account?.first_name || ''} ${message?.source_kate_account?.last_name || ''}`
            : message?.source

        const label = message?.contact
          ? `${message?.contact?.first_name} ${message?.contact?.last_name}`
          : message?.source

        return (
          <div style={{ width: '100%', height: '100%', display: 'flex' }}>
            <StatusLine new={message?.new} urgent={message?.urgent} private={message?.confidential} />
            <MessageContainer
              active={id === message?.uniqueId}
              key={message?.uniqueId}
              onClick={() =>
                history.push(
                  `/messages/${type}?id=${message?.uniqueId}${message?.confidential ? '&confidential=true' : ''}`
                )
              }
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AvatarContact message={message} />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '0 10px',
                      height: '100%',
                      justifyContent: 'center'
                    }}
                  >
                    <Name>{label}</Name>
                    <TextNote>
                      {message?.note
                        ? formatNote(message?.note)
                        : format(new Date(message?.timestamp * 1000), 'MMM d, h:mm a')}
                    </TextNote>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                  {message?.photo_url ? <Image src={message?.photo_url} /> : null}
                  <MessageDuration>{message?.length}s</MessageDuration>
                </div>
              </div>
              <Line />
            </MessageContainer>
          </div>
        )
      }}
    />
  )
}

const Messages = () => {
  return (
    <Layout>
      <CentralPanel>
        <MessagesNavigation />
        <Switch>
          <Route path="/messages/:type" component={MessagesData} />
          <Route exact path="/messages">
            <Redirect to={'/messages/new'} />
          </Route>
        </Switch>
      </CentralPanel>
      <RightPanel>
        <MessageView />
      </RightPanel>
    </Layout>
  )
}

export default Messages
