import styled from 'styled-components'

const Tag = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-right: 5px;
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default Tag
