import React, { SyntheticEvent, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { colors } from '../../../../../../../assets/colors'
import PlayButtonFilled from '../../../../../../../components/Icon/PlayButtonFilled'
import Button from '@mui/material/Button'
import PauseCircle from '../../../../../../../components/Icon/PauseCircle'
import { useLocation } from 'react-router'
import SkipToStart from '../../../../../../../components/Icon/SkipToStart'
import Reply5 from '../../../../../../../components/Icon/Reply5'
import { useWaveSurfer } from '../audioPlayerStore'
import { useGetMessagesById } from '../../../../../../../hooks/queries/useGetMessagesById'
import APIClient from '../../../../../../../apiClient'
import { useQueryClient } from '@tanstack/react-query'
import * as Sentry from '@sentry/react'

const AudioPlayerButtons = () => {
  const location = useLocation()
  const [isPlaying, setIsPlaying] = useState(false)
  const params = new URLSearchParams(location.search)
  const id = params.get('id')
  const { data: message } = useGetMessagesById(id, true)

  const queryClient = useQueryClient()

  const [waveSurfer] = useWaveSurfer()

  const updateMessageAsPlayed = async () => {
    try {
      if (!message) return

      if (message?.new || message?.urgent) {
        await APIClient.markMessagesItemStatus({
          id: message?.uniqueId,
          status: 'played'
        })
        await queryClient.invalidateQueries(['message', message?.uniqueId])
      }
    } catch (e) {
      console.error('Error on update message as played: ', e)
      Sentry.captureException(e)
    }
  }

  const onToggleAudio = async () => {
    if (!waveSurfer) return
    await waveSurfer?.playPause()
    updateMessageAsPlayed()
  }

  const rewind5Seconds = async () => {
    if (!waveSurfer) return
    await waveSurfer?.skip(-5)
  }

  const rewindToStart = async () => {
    if (!waveSurfer) return
    await waveSurfer?.stop()
    await waveSurfer?.play()
  }

  useEffect(() => {
    if (!waveSurfer) return
    const setFinishAudio = () => {
      setIsPlaying(false)
    }
    waveSurfer?.on('finish', setFinishAudio)

    return () => {
      waveSurfer.un('finish', setFinishAudio)
    }
  }, [waveSurfer])

  const onAudioClick = async (e: SyntheticEvent) => {
    e.stopPropagation()
    try {
      await onToggleAudio()
      if (waveSurfer.isPlaying()) {
        return setIsPlaying(true)
      }
      setIsPlaying(false)
    } catch (e) {
      console.log('error ===', e)
      Sentry.captureException(e)
    }
  }

  const onRewindToStart = () => {
    setIsPlaying(true)
    rewindToStart()
  }

  useEffect(() => {
    return () => {
      setIsPlaying(false)
    }
  }, [])

  const isConfidential = message?.confidential

  return (
    <>
      <Box minHeight={150} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Button onClick={onRewindToStart}>
          <Box width={90} height={90} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <SkipToStart width={'50px'} fill={isConfidential ? colors.mediumGrey : colors.darkBlue} />
          </Box>
        </Button>
        <Button onClick={onAudioClick}>
          <Box width={90} height={90} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            {isPlaying ? (
              <PauseCircle fill={isConfidential ? colors.mediumGrey : colors.darkBlue} width={'90px'} />
            ) : (
              <PlayButtonFilled fill={isConfidential ? colors.mediumGrey : colors.darkBlue} />
            )}
          </Box>
        </Button>
        <Button onClick={() => rewind5Seconds()}>
          <Box width={90} height={90} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Reply5 width={'50px'} fill={isConfidential ? colors.mediumGrey : colors.darkBlue} />
          </Box>
        </Button>
      </Box>
    </>
  )
}

export default AudioPlayerButtons
