import React, { memo, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { colors } from '../../../../assets/colors'
import Header from '../../../../components/Header'
import { InlineButton } from '../../../../components/Button/InlineButton'
import SearchInput from '../../../../components/Input/SearchInput'
import Icon from '../../../../components/Icon'
import Contact from '../../../../components/blocks/Contact/ContactItem'
import SwipeItem from '../../../../components/blocks/Message/SwipeItem'
import ContactsList from '../../../../components/blocks/Contact/ContactsList/ContactsList'
import { Loader, LoaderContainer } from '../../../../components/Loader/Loader'
import APIClient from '../../../../apiClient'
import { RouteProps } from 'react-router-dom'
import ChangeViewFooter from '../../../../containers/ChangeViewFooter'
import { useAutoPlayStore } from '../../../../store/autoPlay'
import { shallow } from 'zustand/shallow'
import { queryClient } from '../../../../index'
import { useContactsActions } from '../../../../store/contacts'
import { useContactGroupListQuery } from '../../../../hooks/queries/useContactGroupListQuery'
import { showAlertModal } from '../../../../store/modals'
import { ContactItemType } from '../../../../types'

const MobileContactPage = () => {
  const [touched, setTouched] = useState<boolean>(false)
  const [filteredContacts, setFilteredContacts] = useState<ContactItemType[]>([])
  const history = useHistory()
  const { setContact } = useContactsActions()

  const nextUnreadMessageId = useAutoPlayStore((state) => state.nextUnreadMessageId, shallow)

  const urlParams = new URLSearchParams(history.location.search)
  const search = urlParams.get('filter') || ''

  const { data, isLoading } = useContactGroupListQuery()

  useEffect(() => {
    if (search.length >= 2) {
      const foundContacts = data?.filter((contact) => {
        return (
          contact?.first_name?.toLowerCase()?.startsWith(search?.toLowerCase()) ||
          contact?.last_name?.toLowerCase()?.startsWith(search?.toLowerCase())
        )
      })

      setFilteredContacts(foundContacts)
    } else {
      setFilteredContacts(data)
    }
  }, [data, search])

  const deleteContactFunction = useCallback(async (id: string) => {
    try {
      const deleteResponse = await APIClient.contactDelete(id)
      await queryClient.invalidateQueries({ queryKey: ['contacts'] })
      if (deleteResponse.status >= 300)
        throw new Error(`status = ${deleteResponse.status}, ${deleteResponse.statusText}`)
    } catch (e) {
      console.log('error on contact delete ===', e)
      showAlertModal('Ups, contact cannot be deleted. Please reload the page and try again.')
    }
  }, [])

  const onContactsGroupClick = useCallback(() => {
    history.push('/groups')
  }, [nextUnreadMessageId])

  return (
    <>
      <Header>
        <Header.SideButtons>
          <InlineButton onClick={onContactsGroupClick}>Groups</InlineButton>
        </Header.SideButtons>
        <Header.Title>
          <span>Kate Contacts</span>
        </Header.Title>
        <Header.SideButtons>
          <Header.IconButton onClick={() => history.push('/contactCreate')}>
            <Icon name={'plus'} fill={colors.white} width={'20px'} />
          </Header.IconButton>
        </Header.SideButtons>
      </Header>
      <SearchInput
        onFocus={() => setTouched(true)}
        onClick={() => {
          history.replace('/contacts')
          setTouched(false)
        }}
        onChange={(e: { target: { value: string } }) => history.replace(`/contacts?filter=${e.target.value}`)}
        showCancel={touched}
        value={search}
      />
      {isLoading ? (
        <LoaderContainer>
          <Loader width={'20px'} height={'20px'} fill={colors.lightGrey} />
        </LoaderContainer>
      ) : (
        <ContactsList>
          <ContactsList.ItemsList>
            {filteredContacts?.length ? (
              filteredContacts?.map((contact) => {
                return (
                  <SwipeItem
                    key={contact?.id}
                    autoClose
                    right={[
                      {
                        text: 'remove',
                        onPress: () => deleteContactFunction(contact.id),
                        style: { backgroundColor: colors.red, color: colors.white, overflow: 'hidden' }
                      }
                    ]}
                  >
                    <Contact
                      onClick={async () => {
                        setContact(contact)
                        history.push(`/contactShow?id=${contact.id}`)
                      }}
                    >
                      <Contact.Avatar
                        initials={`${contact?.first_name ? contact?.first_name?.[0] : ''}${
                          contact?.last_name ? contact?.last_name?.[0] : ''
                        }`}
                        src={contact?.photo_url && contact?.photo_url}
                      />
                      <Contact.Info>
                        <Contact.ContactName>{`${contact?.first_name || ''} ${
                          contact?.last_name || ''
                        }`}</Contact.ContactName>
                        <Contact.KateNumber>{contact?.kate_number}</Contact.KateNumber>
                      </Contact.Info>
                    </Contact>
                  </SwipeItem>
                )
              })
            ) : (
              <ContactsList.Header>You don&apos;t have any contacts</ContactsList.Header>
            )}
          </ContactsList.ItemsList>
        </ContactsList>
      )}
      <ChangeViewFooter />
    </>
  )
}

export default memo(MobileContactPage)
