import React from 'react'
import { Box, Typography } from '@mui/material'
import { colors } from '../../../../../../../../assets/colors'
import Button from '@mui/material/Button'

const ActionButton = ({ text, onClick, icon }) => {
  return (
    <Button sx={{ cursor: 'pointer', textTransform: 'none' }} onClick={onClick}>
      <Box
        width={75}
        height={75}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        {icon}
        <Typography variant="subtitle1" component="span" color={colors.mediumGrey}>
          {text}
        </Typography>
      </Box>
    </Button>
  )
}

export default ActionButton
