import styled from 'styled-components'
import { colors } from '../../../../assets/colors'

const Title = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.black};
  margin-left: 10px;
`

export default Title
