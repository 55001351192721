import styled, { css } from 'styled-components'
import React, { ReactNode } from 'react'
import Avatar from './Avatar'
import TabButtonsContainer from './TabButtonsContainer'
import { isDesktop } from '../../../constants'

const Wrapper = styled.div`
  height: 55px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;

  @media (min-width: 1024px) {
    justify-content: center;
  }
`

interface InboxHeaderProps {
  children?: ReactNode
}

const InboxHeader = ({ children, ...rest }: InboxHeaderProps) => <Wrapper {...rest}>{children}</Wrapper>

InboxHeader.Avatar = Avatar
InboxHeader.TabButtonsContainer = TabButtonsContainer

export default InboxHeader
