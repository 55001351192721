import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../../../../assets/colors'
import Clock from '../../../../../../components/Icon/Clock'
import { MeetingDate } from './MeetingDate'

interface TimeInfoProps {
  timeFrom: string
  timeTo: string
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`

const TimeInfo = ({ timeFrom, timeTo }: TimeInfoProps) => {
  return (
    <Container>
      <Clock fill={colors.mediumGrey} />
      <MeetingDate>{`${timeFrom} - ${timeTo}`}</MeetingDate>
    </Container>
  )
}

export default TimeInfo
