import { useQuery } from '@tanstack/react-query'
import APIClient from '../../apiClient'
import { MessageCounter } from '../../store/messages'

export const useVoiceMailCounts = () => {
  return useQuery<unknown, unknown, MessageCounter>({
    queryKey: ['messageCounter'],
    queryFn: async () => {
      const res = await APIClient.voiceMailCounts()
      return res.data
    },
    refetchInterval: 5000,
    cacheTime: 1000 * 60 // 1 minute
  })
}
