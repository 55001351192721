import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px 80px;
  min-height: 600px;
`

export default Container
