import React, { useEffect, useState } from 'react'
import AppContainer from './components/AppContainer'
import AudioProvider from './containers/Audio'
import AutoPlayContainer from './containers/AutoPlay'
import { conferenceEventsLogger } from './VideoConferencing/logger/ConferenceEventsLogger'
import './styles.css'
import { usePrefetchData } from './hooks/usePrefetchData'
import useIsMobile from './hooks/useIsMobile'
import AccountNeedAttention from './components/AccountNeedAttention'
import { ToastContainer } from 'react-toastify'
import { MobileRouter } from './navigation/routers/mobile'
import { DesktopRouter } from './navigation/routers/desktop'
import { colors } from './assets/colors'
import { AccountLockedRouter } from './navigation/routers/accountLocked'
import { accountLockService } from './services/AccountLockService'

const AccountLockedApp = () => {
  return <AccountLockedRouter />
}

const MainApp: React.FC = () => {
  const isMobile = useIsMobile()
  const isLocked = accountLockService.isAccountLocked()

  return (
    <AppContainer
      style={{
        backgroundColor: isMobile ? colors.white : colors.silver
      }}
      className={window.location.href.includes('report') ? 'noheightstyle' : 'heightstyle'}
    >
      {isLocked ? <AccountLockedApp /> : <FullAccessApp />}
    </AppContainer>
  )
}

const FullAccessApp = () => {
  usePrefetchData()
  const isMobile = useIsMobile()

  // [EVENT STATS] add landing page event
  useEffect(() => {
    conferenceEventsLogger.sendNextEventFromTheStack()
  }, [])

  return (
    <>
      <AccountNeedAttention />
      {isMobile ? <MobileRouter /> : <DesktopRouter />}
      <AudioProvider />
      <AutoPlayContainer />
      <ToastContainer position="top-right" />
    </>
  )
}

export default MainApp
