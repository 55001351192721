import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import APIClient from '../../../../apiClient'
import { useParams } from 'react-router-dom'
import { useUserStore } from '../../../../store/user'

export const useSentryConference = () => {
  const { id: meetingId } = useParams<{ id: string }>()
  const authenticated = useUserStore((state) => state.isAuthenticated)

  useEffect(() => {
    const fetchMeetingInfo = async () => {
      if (meetingId) {
        Sentry.setTag('meetingId', meetingId)
        // todo: fix for guest
        const meetingInfo = authenticated && (await APIClient.getMeetingInfo(meetingId))

        if (meetingInfo?.data) {
          Sentry.addBreadcrumb({
            category: 'conference',
            message: `customer id: ${meetingInfo?.data?.customerId ? meetingInfo?.data?.customerId : ''},
             attendee id: ${meetingInfo?.data?.attendeeId ? meetingInfo?.data?.attendeeId : ''},
             session id: ${meetingInfo?.data?.sessionId ? meetingInfo?.data?.sessionId : ''}`,
            level: 'info'
          })
        } else {
          Sentry.addBreadcrumb({
            category: 'conference',
            message: `Meeting id: ${meetingId}`,
            level: 'info'
          })
        }
      }
    }
    fetchMeetingInfo()
  }, [authenticated, meetingId])

  return {}
}
