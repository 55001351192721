import { authClient } from '../client'
import { accountLockService } from './AccountLockService'

class TokenManager {
  private kateToken: string | null
  private refreshToken: string | null
  private refreshingToken: Promise<any> | null
  constructor() {
    this.kateToken = localStorage.getItem('kateTokenX')
    this.refreshToken = localStorage.getItem('refreshToken')
    this.refreshingToken = null
  }

  async refreshKateToken() {
    // Checking if account is currently locked and skip the refreshing token process
    if (accountLockService.isAccountLocked()) {
      return
    }

    if (!this.refreshingToken) {
      this.refreshingToken = this.getRefreshToken()
    }

    try {
      const res = await this.refreshingToken
      return res
    } catch (error) {
      console.log('Error refreshing token', error)
      throw error
    } finally {
      this.refreshingToken = null
    }
  }

  async getRefreshToken() {
    try {
      const verifier = localStorage.getItem('refreshToken')
      const kateToken = localStorage.getItem('kateTokenX')

      const response = await authClient.post(
        `/refreshtoken`,
        {
          verifier
        },
        {
          headers: {
            Authorization: `Bearer ${kateToken}`,
            'X-App-Version': 'pwa:dev:8.0'
          }
        }
      )

      await authClient.post(
        `/refreshtoken`,
        {
          ack: response.data.refresh_token
        },
        {
          headers: {
            Authorization: `Bearer ${response.headers['x-session-token']}`
          }
        }
      )

      const kateTokenX = response.headers['x-session-token']
      const refreshToken = response.data.refresh_token
      const cognitoToken = response.data?.aws?.tokens?.id
      const chatUrl = response.data?.aws?.urls?.chat

      this.storeToken(kateTokenX, refreshToken, cognitoToken, chatUrl)

      return response
    } catch (error) {
      console.log('ERROR ON TOKEN REFRESHING...', error)
      console.log('Logging out...')

      const urlArr = window.location.pathname.split('/')
      if (!(window.location.href.includes('conference') && urlArr[2])) {
        window.location.href = '/login'
      }
      localStorage.setItem('kateTokenX', '')
      localStorage.setItem('refreshToken', '')
      localStorage.setItem('user-storage', '')
      localStorage.setItem('awsAccessTokenId', '')
      localStorage.setItem('chatUrl', '')
    }
  }

  storeToken(token, refreshToken, cognitoToken, chatUrl) {
    this.kateToken = token
    this.refreshToken = refreshToken
    localStorage.setItem('kateTokenX', token)
    localStorage.setItem('refreshToken', refreshToken)
    localStorage.setItem('awsAccessTokenId', cognitoToken)
    localStorage.setItem('chatUrl', chatUrl)
  }
}

const tokenManager = new TokenManager()
export default tokenManager
