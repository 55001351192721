import React, { SVGProps } from 'react'
import { colors } from '../../assets/colors'

const ShareScreen = (props: { selected: boolean }) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2607_6628)">
      <path
        d="M0.5 3.5C0.5 2.39543 1.39543 1.5 2.5 1.5H18.5C19.6046 1.5 20.5 2.39543 20.5 3.5V16.5C20.5 17.6046 19.6046 18.5 18.5 18.5H2.5C1.39543 18.5 0.5 17.6046 0.5 16.5V3.5Z"
        fill={`${props.selected ? colors.red : 'url(#paint0_linear_2607_6628)'}`}
      />
      <rect x="9.76953" y="6.5" width="1.4" height="8" rx="0.7" fill="#171717" />
      <rect
        x="9.48633"
        y="6.49023"
        width="1.4"
        height="5.45152"
        rx="0.7"
        transform="rotate(-45 9.48633 6.49023)"
        fill="#171717"
      />
      <rect
        x="10.4551"
        y="5.5"
        width="1.4"
        height="5.45339"
        rx="0.7"
        transform="rotate(45 10.4551 5.5)"
        fill="#171717"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2607_6628"
        x1="24.76"
        y1="-2.767"
        x2="0.633596"
        y2="26.364"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3FB5E9" />
        <stop offset="1" stopColor="#1D70B6" />
      </linearGradient>
      <clipPath id="clip0_2607_6628">
        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export default ShareScreen
