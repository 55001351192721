import React from 'react'
import AccountBalance from '../../../AccountBalance'
import PaymentOptions from '../../../PaymentOptions'
import PricePlans from '../../../PricePlans'
import BillingAddress from '../../../BillingAddress'
import BillingStatemets from '../../../BillingStatements'
import useCurrentTab from '../../Common/Hooks/useCurrentTab'
import useIsMobile from '../../Common/Hooks/useIsMobile'
import PaymentOptionsUpdateStatus from '../../../PaymentOptionsUpdateStatus'
import BillingPortal from '../../../BillingPortal'
import ResolveNow from '../../../ResolveNow'

const Content = () => {
  const [currentTab] = useCurrentTab()
  const isMobile = useIsMobile()

  return (
    <div
      style={{
        width: isMobile ? '100vw' : '100%',
        margin: '5px',
        padding: isMobile ? '12px' : '30px',
        background: '#FFF',
        borderRadius: '10px',
        overflowX: 'hidden',
        position: 'relative'
      }}
    >
      {currentTab === null && <AccountBalance />}
      {currentTab === 'payment-options' && <PaymentOptions />}
      {currentTab === 'price-plans' && <PricePlans />}
      {currentTab === 'billing-address' && <BillingAddress />}
      {currentTab === 'billing-statements' && <BillingStatemets />}
      {currentTab === 'payment-options-update-status' && <PaymentOptionsUpdateStatus />}
      {currentTab === 'billing-portal' && <BillingPortal />}
      {currentTab === 'resolve-now' && <ResolveNow />}
    </div>
  )
}

export default Content
