import { ContactItemType } from '../../types'
import { useQuery } from '@tanstack/react-query'
import APIClient from '../../apiClient'

export const useGetAllContacts = <T = ContactItemType[]>() => {
  return useQuery<unknown, unknown, T>({
    queryKey: ['allContacts'],
    queryFn: async () => {
      const res = await APIClient.contactList()
      return res.data
    },
    cacheTime: 1000 * 60 * 15 // 15 minutes
  })
}
