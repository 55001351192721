import { useEffect, useState } from 'react'
import { useVideoSession } from './useVideoSession'
import { useUser } from '../../../../store/user'
import { useVideoActions, useVideoStore, VideoStateEnum } from '../../../store/video'

export const usePollIntervals = () => {
  const [room, videoData] = useVideoStore((state) => [state.room, state.videoData])
  const [videoGranted, setVideoGranted] = useState(false)
  const [audioGranted, setAudioGranted] = useState(false)
  const { setVideoRoomState } = useVideoActions()
  const { join } = useVideoSession()
  const user = useUser()

  useEffect(() => {
    ;(async () => {
      // @ts-ignore
      const res2 = await navigator.permissions.query({ name: 'camera' }).then((res) => {
        if (res.state == 'granted') {
          // has permission
          setVideoGranted(true)
        }
      })

      // @ts-ignore
      const res3 = await navigator.permissions.query({ name: 'microphone' }).then((res) => {
        if (res.state == 'granted') {
          // has permission
          setAudioGranted(true)
        }
      })
    })()
    // @ts-ignore
  }, [room])

  const isHost = room?.hostId === user?.account_number

  useEffect(() => {
    if (!room) return

    const pollInterval = setInterval(async () => {
      await join()
    }, 2000)

    return () => {
      clearInterval(pollInterval)
    }
  }, [room])

  useEffect(() => {
    if (!room?.canJoin || !videoData?.sessionId || isHost || !audioGranted) return
    const timeout = setTimeout(() => {
      setVideoRoomState(VideoStateEnum.Conference)
    }, 2500)
    return () => {
      clearTimeout(timeout)
    }
  }, [room?.canJoin, videoData?.sessionId, isHost, videoGranted, audioGranted])
}
