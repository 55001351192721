import styled from 'styled-components'

const CancelActionButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: rgba(23, 23, 23, 0.05);
  color: #707070;
  text-transform: capitalize;
  border: 0;
  font-size: 0.875rem;
  border-radius: 30px;
  padding: 15px 30px;
  cursor: pointer;
  position: relative;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

export default CancelActionButton
