import styled from 'styled-components'
import React, { ReactNode } from 'react'
import { colors } from '../../../assets/colors'
import Header from './Header'
import UpToDateBox from './UpToDateBox'
import { isDesktop } from '../../../constants'

const MessageListWrapper = styled.div`
  flex: 1;
  min-height: 300px;
  height: 100%;
  width: 100%;
  background-color: ${isDesktop ? colors.white : colors.silver};
  flex-direction: column;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.875rem;
  overflow-x: auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

interface MessageListPageLayoutProps {
  children?: ReactNode
}

const MessageList = ({ children, ...rest }: MessageListPageLayoutProps) => (
  <MessageListWrapper {...rest}>{children}</MessageListWrapper>
)

MessageList.Header = Header
MessageList.UpToDateBox = UpToDateBox

export default MessageList
