import { useQuery } from '@tanstack/react-query'
import { GroupItemType } from '../../../../../../../../types'
import APIClient from '../../../../../../../../apiClient'

export const useClosureStatusQuery = () => {
  return useQuery<unknown, unknown, { closurePending: boolean }>({
    queryKey: ['closure-status'],
    queryFn: async () => {
      const res = await APIClient.closureStatus()
      return res.data
    },
    cacheTime: 1000 * 60 // 1 min
  })
}

export const useBalanceNoticeQuery = () => {
  return useQuery<
    unknown,
    unknown,
    { amount_due: number; billing_url: string; notice_message?: string; cancellation_policy_url?: string }
  >({
    queryKey: ['balance-notice'],
    queryFn: async () => {
      const res = await APIClient.balanceNotice()
      return res.data
    },
    cacheTime: 1000 * 60 // 1 min
  })
}

export const useClosureReasonsQuery = () => {
  return useQuery<unknown, unknown, { label: string; reasonCode: string }[]>({
    queryKey: ['closure-reasons'],
    queryFn: async () => {
      const res = await APIClient.closureReasons()
      return res.data
    },
    cacheTime: 1000 * 60 // 1 min
  })
}
