import React from 'react'
import ActionButton from './ActionButton'
import Remove from '../../../../../../../../components/Icon/Remove'
import { colors } from '../../../../../../../../assets/colors'
import Download from '../../../../../../../../components/Icon/Download'
import ReplyArrow from '../../../../../../../../components/Icon/ReplyArrow'
import ForwardArrow from '../../../../../../../../components/Icon/ForwardArrow'
import Ellipsis from '../../../../../../../../components/Icon/Ellipsis'
import { Box, Popover, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { useHistory } from 'react-router-dom'
import APIClient from '../../../../../../../../apiClient'
import { useQueryClient } from '@tanstack/react-query'
import { showInfoModal } from '../../../../../../../../store/modals'

const ActionButtons = ({ message }) => {
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const queryClient = useQueryClient()

  const isConfidential = message?.confidential

  const invalidateMessageLists = async () => {
    await queryClient.invalidateQueries(['messageCounter'])
    await queryClient.invalidateQueries(['messages', 'saved'])
    await queryClient.invalidateQueries(['messages', 'inbox'])
    await queryClient.invalidateQueries(['messages', 'new'])
    await queryClient.invalidateQueries(['messages', 'deleted'])
  }

  const replyMessage = () => {
    if (!message) return
    let recipients
    if (message && message?.contact) {
      recipients = [
        {
          id: message?.contact.id,
          label: `${message?.contact.first_name} ${message?.contact.last_name}`
        }
      ]
    }
    // @ts-ignore
    history.push(`${history.location.pathname}${history.location.search}&action=reply`, {
      ...history.location.state,
      recipients
    })
  }

  const forwardMessage = (id) => {
    history.push(`${history.location.pathname}${history.location.search}&action=forward`, {
      ...history.location.state,
      forwardId: id
    })
  }

  const deleteMessage = async (id) => {
    try {
      if (!id) return null
      await APIClient.moveMessagesItem({ id, toFolder: 'deleted' })
      await invalidateMessageLists()
      history.push('/messages')
    } catch (e) {
      console.log('error on deleteMessage: ', e)
    }
  }

  const markMessageUnread = async (id) => {
    try {
      if (!id) return null
      await APIClient.markMessagesItemStatus({ id, status: 'unplayed' })
      setAnchorEl(null)
      await queryClient.invalidateQueries(['message', id])
    } catch (e) {
      console.log('error ===', e)
    }
  }

  const markMessageUrgent = async (id) => {
    try {
      if (!id) return null
      await APIClient.markMessagesItemStatus({ id, status: 'urgent' })
      setAnchorEl(null)
      await queryClient.invalidateQueries(['message', id])
    } catch (e) {
      console.log('error ===', e)
    }
  }

  const saveMessage = async (id) => {
    try {
      if (!id) return null
      await APIClient.moveMessagesItem({ id, toFolder: 'saved' })
      await invalidateMessageLists()
      await queryClient.invalidateQueries(['message', id])
      showInfoModal('Message moved to saved')
    } catch (e) {
      console.log('error ===', e)
    }
  }

  return (
    <Box>
      <ActionButton
        icon={<Remove width={'30px'} fill={colors.mediumGrey} />}
        onClick={() => deleteMessage(message?.uniqueId)}
        text={'Delete'}
      />
      {!isConfidential && (
        <ActionButton
          icon={<Download width={'30px'} fill={colors.mediumGrey} />}
          onClick={() => saveMessage(message?.uniqueId)}
          text={'Save'}
        />
      )}
      <ActionButton
        icon={<ReplyArrow width={'30px'} fill={colors.mediumGrey} />}
        onClick={replyMessage}
        text={'Reply'}
      />
      {!isConfidential && (
        <ActionButton
          icon={<ForwardArrow width={'30px'} fill={colors.mediumGrey} />}
          onClick={() => forwardMessage(message?.uniqueId)}
          text={'Forward'}
        />
      )}
      <ActionButton
        icon={<Ellipsis width={'30px'} fill={colors.mediumGrey} />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        text={'More'}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Box display={'flex'} flexDirection={'column'}>
          <Button
            onClick={() => markMessageUnread(message?.uniqueId)}
            sx={{ cursor: 'pointer', textTransform: 'none', color: colors.mediumGrey }}
          >
            <Typography fontWeight={'400'} p={1}>
              Mark Unread
            </Typography>
          </Button>
          <Button
            onClick={() => markMessageUrgent(message?.uniqueId)}
            sx={{ cursor: 'pointer', textTransform: 'none', color: colors.mediumGrey }}
          >
            <Typography fontWeight={'400'} p={1}>
              Mark Urgent
            </Typography>
          </Button>
        </Box>
      </Popover>
    </Box>
  )
}

export default ActionButtons
