import React, { ChangeEvent, ReactComponentElement, ReactNode, SyntheticEvent, useRef } from 'react'
import { getOrientation, Orientation } from 'get-orientation/browser'
import { getRotatedImage } from './rotateImage'

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90
}

interface IPhotoCapture {
  onCapturedImage: (imageFile: any) => void
  element: ReactComponentElement<any>
  children: ReactNode
}

const PhotoCapture = ({ onCapturedImage, element, children, ...props }: IPhotoCapture) => {
  const photoInputRef = useRef<HTMLInputElement>(null)

  const readFile = (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    e.persist()
    if (!e.target.files || !e.target.files.length) return
    const orientation = await getOrientation(e.target.files[0])
    const defaultImageUrl = await readFile(e.target.files[0])
    // @ts-ignore
    const rotation = orientation ? ORIENTATION_TO_ANGLE[orientation] : undefined
    if (!rotation) {
      onCapturedImage(e.target.files[0])
      return closeImageCrop()
    } else {
      const rotatedFile = await getRotatedImage(defaultImageUrl, rotation)
      onCapturedImage(rotatedFile)
    }
    resetInput()
  }

  const resetInput = () => {
    // This will allow the same file to be selected again
    if (photoInputRef.current) photoInputRef.current.value = ''
  }

  const closeImageCrop = () => {
    if (photoInputRef.current) photoInputRef.current.blur()
    resetInput()
  }

  const onImageCropClick = (e: SyntheticEvent) => {
    e.stopPropagation()
    if (photoInputRef && photoInputRef.current) {
      photoInputRef.current.click()
    }
  }

  return (
    <div>
      <div>
        <input
          ref={photoInputRef}
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          // capture='camera'
          hidden
        />
      </div>
      <element.type {...element.props} onClick={(e: SyntheticEvent) => onImageCropClick(e)} {...props}>
        {children}
      </element.type>
    </div>
  )
}

export default PhotoCapture
