import React from 'react'

const SVG = ({ style = {}, fill = '#000', width = '100%', className = '', viewBox = '0 0 128 128' }) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    fill={fill}
  >
    <path
      fill={fill}
      style={{
        fillRule: 'nonzero',
        fill,
        fillOpacity: 1,
        strokeWidth: 5,
        strokeLinecap: 'butt',
        strokeLinejoin: 'round',
        stroke: fill,
        strokeOpacity: 1,
        strokeMiterlimit: 10
      }}
      d="M64,117c-29.2,0-53-23.8-53-53s23.8-53,53-53s53,23.8,53,53S93.2,117,64,117z M64,17c-25.9,0-47,21.1-47,47s21.1,47,47,47s47-21.1,47-47S89.9,17,64,17z"
    />
    <path
      fill={fill}
      style={{
        fillRule: 'nonzero',
        fill,
        fillOpacity: 1,
        strokeWidth: 2,
        strokeLinecap: 'butt',
        strokeLinejoin: 'round',
        stroke: fill,
        strokeOpacity: 1,
        strokeMiterlimit: 10
      }}
      d="M57 79.5c-1.7 0-3-1.3-3-3v-25c0-1.7 1.3-3 3-3s3 1.3 3 3v25C60 78.2 58.7 79.5 57 79.5zM71 79.5c-1.7 0-3-1.3-3-3v-25c0-1.7 1.3-3 3-3s3 1.3 3 3v25C74 78.2 72.7 79.5 71 79.5z"
    />
  </svg>
)

export default SVG
