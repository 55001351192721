import React, { SyntheticEvent, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import WaveformDesktop from '../../../../../../components/WaveformDesktop'
import styled from 'styled-components'
import PauseCircle from '../../../../../../components/Icon/PauseCircle'
import { colors } from '../../../../../../assets/colors'
import Button from '@mui/material/Button'
import Microphone from '../../../../../../components/Icon/Microphone'
import { useRecording } from '../../../../../shared/hooks/useRecording'
import { useNewMessageActions, useNewMessageStore } from '../../../../../mobile/pages/NewMessage/store/NewMessageStore'
import { useWaveSurfer } from '../AudioPlayer/audioPlayerStore'
import { ImageComponent, ImagePreviewButton } from '../AudioPlayer'
import AudioVisualizer from '../AudioPlayer/AudioVisualizer'
import CloseImageModal from '../../../../../../components/Icon/CloseImageModal'
import Remove from '../../../../../../components/Icon/Remove'
import Pause from '../../../../../../components/Icon/Pause'
import Play from '../../../../../../components/Icon/Play'
import Delete from '../../../../../../components/Icon/Delete'
import { useLocation } from 'react-router'

const WaveContainer = styled.div`
  position: relative;
  width: 100%;
  height: 40%;
  margin-top: 15%;

  display: flex;
  align-items: center;
  justify-content: center;
`

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60)
  const secondsRemainder = Math.round(seconds) % 60
  const paddedSeconds = `0${secondsRemainder}`.slice(-2)
  return `${minutes}:${paddedSeconds}`
}

const RemoveImageButton = ({ onRemoveImage }) => {
  return (
    <Box
      sx={{
        opacity: 0.5,
        cursor: 'pointer',
        transition: '.2s opacity ease-in-out',
        ':hover': {
          opacity: 1
        }
      }}
      position={'absolute'}
      right={'1%'}
      top={'2%'}
      borderRadius={'75%'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      backgroundColor={colors.mediumGrey}
      width={'30px'}
      height={'30px'}
      zIndex={999}
      onClick={onRemoveImage}
    >
      <CloseImageModal width={19} height={19} />
    </Box>
  )
}

const Wrapper = ({ children, ...props }) => {
  return (
    <Box
      width={'100%'}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      {...props}
    >
      {children}
    </Box>
  )
}
const SendAudioMessage = () => {
  const { startRecording, stopRecording, formattedTimeLeft } = useRecording()
  const { setImage, setImageFile, setImageModal, setMp3File } = useNewMessageActions()
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTime, setCurrentTime] = useState('0:00')
  const [duration, setDuration] = useState('0:00')

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const action = params.get('action')

  const isRecording = useNewMessageStore((state) => state.isRecording)
  const mp3File = useNewMessageStore((state) => state.mp3File)
  const imageSrc = useNewMessageStore((state) => state.image)
  const [waveSurfer] = useWaveSurfer()

  const onToggleAudio = async () => {
    if (!waveSurfer) return
    await waveSurfer?.playPause()
  }

  const onAudioClick = async (e: SyntheticEvent) => {
    e.stopPropagation()
    try {
      await onToggleAudio()
      if (waveSurfer.isPlaying()) {
        return setIsPlaying(true)
      }
      setIsPlaying(false)
    } catch (e) {
      console.log('error ===', e)
    }
  }

  useEffect(() => {
    if (!waveSurfer) return

    const setDurationTime = (seconds) => {
      setDuration(formatTime(seconds))
    }
    const setCurrentAudioTime = (seconds) => {
      setCurrentTime(formatTime(seconds))
    }

    waveSurfer?.on('decode', setDurationTime)
    waveSurfer?.on('timeupdate', setCurrentAudioTime)

    return () => {
      waveSurfer?.un('decode', setDurationTime)
      waveSurfer?.un('timeupdate', setCurrentTime)
    }
  }, [waveSurfer])

  useEffect(() => {
    if (!waveSurfer) return
    const setFinishAudio = () => {
      setIsPlaying(false)
    }
    waveSurfer?.on('finish', setFinishAudio)

    return () => {
      waveSurfer.un('finish', setFinishAudio)
    }
  }, [waveSurfer])

  const isForwarding = action === 'forward'

  if (mp3File) {
    return (
      <Wrapper justifyContent={'flex-start'}>
        <Box
          mt={2}
          width={'100%'}
          height={'60%'}
          overflow={'hidden'}
          display={'flex'}
          position={'relative'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            '@media (min-width: 1200px)': {
              height: isForwarding ? '32vh' : '42vh'
            },
            '@media (min-width: 1365px)': {
              height: isForwarding ? '32vh' : '48h'
            },
            '@media (min-width: 1599px)': {
              height: isForwarding ? '40vh' : '50h'
            },
            '@media (min-width: 1920px)': {
              height: isForwarding ? '50vh' : '60%'
            }
          }}
        >
          <>
            {imageSrc ? (
              <Box
                width={'100%'}
                height={'50vh'}
                display={'flex'}
                flexDirection={'column'}
                sx={{
                  '@media (min-width: 1200px)': {
                    height: '48vh'
                  },
                  '@media (min-width: 1365px)': {
                    height: '49vh'
                  },
                  '@media (min-width: 1599px)': {
                    height: '50vh'
                  },
                  '@media (min-width: 1920px)': {
                    height: '50vh'
                  }
                }}
              >
                <ImageComponent loading={'lazy'} src={imageSrc} />
                <RemoveImageButton
                  onRemoveImage={() => {
                    setImage(null)
                    setImageFile(null)
                  }}
                />
                <ImagePreviewButton onShowImage={() => setImageModal(true)} right={'3rem'} />
              </Box>
            ) : (
              <Box
                height={'100%'}
                minHeight={'100%'}
                maxHeight={'100%'}
                width={'100%'}
                backgroundColor={colors.silver}
              />
            )}
          </>

          <Box width={'100%'} position={'absolute'} bottom={-99}>
            <AudioVisualizer audioFile={mp3File} />
          </Box>
        </Box>
        <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box p={1}>
            <Typography color={colors.mediumGrey}>{currentTime}</Typography>
          </Box>
          <Box display={'flex'} p={1}>
            <Typography color={colors.mediumGrey}>{duration}</Typography>
          </Box>
        </Box>
        <Box mt={4} width={'100%'} height={'auto'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Button
            onClick={() => {
              setMp3File(null)
              if (action !== 'forward') {
                setImage(null)
                setImageFile(null)
              }
            }}
          >
            <Box width={90} height={90} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Remove width={'50px'} fill={colors.darkBlue} />
            </Box>
          </Button>
          <Button onClick={onAudioClick}>
            <Box width={90} height={90} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              {isPlaying ? (
                <Pause width={'90px'} fill={colors.darkBlue} />
              ) : (
                <Play width={'90px'} fill={colors.darkBlue} />
              )}
            </Box>
          </Button>
        </Box>
      </Wrapper>
    )
  }

  return (
    <Wrapper position={'relative'}>
      {imageSrc && (
        <Box width={'150px'} height={'150px'} position={'absolute'} top={0} left={10} borderRadius={20}>
          {action !== 'forward' && (
            <Box
              onClick={() => setImage(null)}
              width={20}
              height={20}
              position={'absolute'}
              right={-20}
              sx={{ cursor: 'pointer' }}
            >
              <Delete width={'25px'} fill={colors.mediumGrey} />
            </Box>
          )}
          <img
            src={imageSrc}
            alt={'uploaded_image'}
            style={{ objectFit: 'cover', borderRadius: 20, marginTop: 20 }}
            width={'100%'}
            height={'100%'}
          />
        </Box>
      )}
      <WaveContainer id={'waveFormDesktopParent'}>
        <WaveformDesktop startAnimation={isRecording} />
      </WaveContainer>
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        pt={3}
      >
        <Button
          sx={{ textTransform: 'none' }}
          onClick={async () => {
            if (isRecording) {
              return stopRecording()
            }
            await startRecording()
          }}
        >
          {isRecording ? (
            <PauseCircle width={'90px'} fill={colors.darkBlue} />
          ) : (
            <Microphone width={'90px'} fill={colors.darkBlue} />
          )}
        </Button>
        {isRecording ? (
          <Typography color={colors.mediumGrey} fontSize={15} mt={1}>
            Time left: {formattedTimeLeft || '4m 00s'}
          </Typography>
        ) : (
          <Typography color={colors.mediumGrey} fontSize={15} mt={1}>
            Record
          </Typography>
        )}
      </Box>
    </Wrapper>
  )
  // }

  // if is audio
  // return <></>
}

export default SendAudioMessage
