import React, { useState } from 'react'
import { colors } from '../../../../../../assets/colors'
import Icon from '../../../../../../components/Icon'
import { StyledAccordionTitleLink } from '../Styled'
import { CSSObject } from 'styled-components'

interface IProps {
  title: string
  children?: React.ReactNode
  active?: boolean
  onClick?: () => void
  style?: CSSObject
}

const OptionAccordion = ({ title, children, active, onClick, style }: IProps) => {
  const [expanded, setExpanded] = useState(true)

  const toggleExpanded = () => {
    setExpanded((prevState) => !prevState)
  }

  if (!children) {
    return (
      <StyledAccordionTitleLink active={active} onClick={onClick} style={style}>
        <p>{title}</p>
      </StyledAccordionTitleLink>
    )
  }

  return (
    <div>
      <StyledAccordionTitleLink
        active={active}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...style
        }}
        onClick={toggleExpanded}
      >
        <p>{title}</p>
        <Icon
          name={'chevronRight'}
          width={'14px'}
          fill={colors.mediumGrey}
          style={{ transform: expanded ? 'rotate(90deg)' : 'rotate(0)' }}
        />
      </StyledAccordionTitleLink>
      <div style={{ display: expanded ? 'block' : 'none' }}>{children}</div>
    </div>
  )
}

export default OptionAccordion
