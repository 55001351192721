import { useFormik } from 'formik'
import APIClient from '../../../../../../../apiClient'
import * as Sentry from '@sentry/react'
import { useVideoSession } from '../../../../hooks/useVideoSession'
import * as Yup from 'yup'

const JoinGuestSchemaValidation = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Must be a valid email').required('E-mail is required')
})

export const useInfo = () => {
  const { join } = useVideoSession()

  const formik = useFormik({
    initialValues: {
      firstName: localStorage.getItem('guestFirstName') ? localStorage.getItem('guestFirstName') : '',
      lastName: localStorage.getItem('guestLastName') ? localStorage.getItem('guestLastName') : '',
      email: localStorage.getItem('guestEmail') ? localStorage.getItem('guestEmail') : ''
    },
    validationSchema: JoinGuestSchemaValidation,

    onSubmit: async (values) => {
      try {
        const firstName = values.firstName
        const lastName = values.lastName
        const email = values.email

        const newTokenData = await APIClient.getGuestJWT({ firstName: firstName, lastName: lastName, email: email })
        if (newTokenData.data.jwtToken) {
          localStorage.setItem('guestFirstName', firstName)
          localStorage.setItem('guestLastName', lastName)
          localStorage.setItem('guestEmail', email)
          localStorage.setItem('kateGuestTokenX', newTokenData.data.jwtToken)

          Sentry.setUser({ username: email })

          await join()
        }
      } catch (err) {
        console.error('Error on getting guest jwt: ', err)
        Sentry.captureException(err)
      }
    }
  })

  return { ...formik }
}
