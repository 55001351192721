import React, { useEffect, useState } from 'react'
import WaitingRoom from '../../../../components/blocks/WaitingRoom'
import '../../styles.css'
import VideoConferencingHeader from '../../../../components/Header'
import { guestConferenceEventsLogger } from '../../../../logger/ConferenceEventsLogger'
import useWindowSize from '../../../../hooks/useWindowSize'
import Info from './components/Info'
import CameraPreview from './components/CameraPreview'
import styled from 'styled-components'
import { MICROPHONE_REQUIRED_INFO } from './constants'
import { useVideoSession } from '../../hooks/useVideoSession'
import BackLink from './components/BackLink'
import { useParams } from 'react-router-dom'
import { useSentryConference } from '../../hooks/useSentryConference'
import { usePollIntervals } from '../../hooks/usePollIntervals'
import { showAlertModal } from '../../../../../store/modals'
import { useUserStore } from '../../../../../store/user'
import { useHistory } from 'react-router'
import * as Sentry from '@sentry/react'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const Waiting: React.FC = () => {
  const history = useHistory()
  const { windowSize } = useWindowSize()
  const { id: meetingId } = useParams<{ id: string }>()

  useSentryConference()
  usePollIntervals()

  const authenticated = useUserStore((state) => state.isAuthenticated)
  const [isMobileDevice] = useState(/Mobi/i.test(window.navigator.userAgent))

  const { join } = useVideoSession()

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function (stream) {
        join()
      })
      .catch(function (err) {
        console.log('err', err)
        showAlertModal(MICROPHONE_REQUIRED_INFO)
        Sentry.captureException(err)
        history.push('/video-conferencing')
      })
  }, [])

  useEffect(() => {
    if (!authenticated) {
      guestConferenceEventsLogger.clearQueue()
      localStorage.setItem('bevents', '')
      localStorage.setItem('bevent', '')
    }
  }, [])

  return (
    <>
      <VideoConferencingHeader logoClickPathRedirect={'/'}>
        <VideoConferencingHeader.Element>
          {meetingId && <WaitingRoom.ClipboardButton url={`${window.location.origin}/conference/${meetingId}`} />}
        </VideoConferencingHeader.Element>
      </VideoConferencingHeader>
      <div style={{ marginLeft: 20 }}>
        <BackLink />
      </div>

      <WaitingRoom>
        <Wrapper>
          <Container>
            {!isMobileDevice && windowSize.width >= 1000 && <CameraPreview />}

            <Info />
          </Container>
        </Wrapper>
      </WaitingRoom>
    </>
  )
}

export default Waiting
