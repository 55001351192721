import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Loader } from '../../components/Loader/Loader'

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(224.26deg, #3fb5e9 -23.22%, #1d70b6 123.1%);
  color: white;
  text-transform: capitalize;
  border: 0;
  font-size: 0.875rem;
  border-radius: 30px;
  padding: 15px 30px;
  cursor: pointer;
  position: relative;

  &:hover {
    opacity: 0.95;
  }
`

type ButtonProps = {
  children: ReactNode
  onClick?: (e: any) => void
  isLoading?: boolean
  style?: React.CSSProperties
}

const GradientButton: React.FC<ButtonProps> = ({ isLoading = false, children, ...props }) => {
  return (
    <StyledButton disabled={isLoading} {...props}>
      {isLoading ? <Loader height={'20px'} width={'20px'} /> : children}
    </StyledButton>
  )
}

export default GradientButton
