import React from 'react'

interface CameraProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const Camera = ({ style = {}, fill = '#000', width = '30px', className = '', viewBox = '0 0 30 30' }: CameraProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
M 12.236328 4 C 11.478328 4 10.786266 4.4274688 10.447266 5.1054688 L 10.052734 5.8945312 C 9.7137344 6.5715313 9.0206719 7 8.2636719 7 L 4 7 C 2.895 7 2 7.895 2 9 L 2 22 C 2 23.105 2.895 24 4 24 L 26 24 C 27.105 24 28 23.105 28 22 L 28 9 C 28 7.895 27.105 7 26 7 L 21.736328 7 C 20.978328 7 20.286266 6.5725312 19.947266 5.8945312 L 19.552734 5.1054688 C 19.213734 4.4284687 18.520672 4 17.763672 4 L 12.236328 4 z M 6 5 C 5.448 5 5 5.448 5 6 L 8 6 C 8 5.448 7.552 5 7 5 L 6 5 z M 15 8 C 18.86 8 22 11.14 22 15 C 22 18.86 18.86 22 15 22 C 11.14 22 8 18.86 8 15 C 8 11.14 11.14 8 15 8 z M 24 9 C 24.552 9 25 9.448 25 10 C 25 10.552 24.552 11 24 11 C 23.448 11 23 10.552 23 10 C 23 9.448 23.448 9 24 9 z M 15 10 A 5 5 0 0 0 10 15 A 5 5 0 0 0 15 20 A 5 5 0 0 0 20 15 A 5 5 0 0 0 15 10 z
"
    />
  </svg>
)

export default Camera
