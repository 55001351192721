import { useHistory } from 'react-router'
import APIClient from '../../../../apiClient'
import * as Sentry from '@sentry/react'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { useUserStore } from '../../../../store/user'
import { useVideoActions } from '../../../store/video'
import { showAlertModal } from '../../../../store/modals'

export const useVideoSession = () => {
  const [isLoading, setIsLoading] = useState(false)
  const authenticated = useUserStore((state) => state.isAuthenticated)
  const { setAuthenticated } = useUserStore((state) => state.actions)
  const { setRoom, setVideoData } = useVideoActions()
  const { id: meetingId } = useParams<{ id?: string }>()

  const history = useHistory()

  const init = async () => {
    try {
      const room = await APIClient.createSession()
      setRoom(room.data)
      return room.data
    } catch (error) {
      setIsLoading(false)
      if (error.response?.status === 402) {
        showAlertModal(`Your account has reached a limit of free video conferencing minutes for this month.`)
        history.push('/video-conferencing')
      }
      console.log('Error', error)
      Sentry.captureException(error, {
        tags: {
          section: 'useVideoSession - init'
        }
      })
    }
  }

  const logoutWhenSessionError = (e: any) => {
    console.log('Error on session: ', e)
    if (authenticated) {
      localStorage.setItem('kateTokenX', '')
      localStorage.setItem('refreshToken', '')
      localStorage.setItem('user-storage', '')
      setAuthenticated(false)
    }
  }

  const join = async () => {
    try {
      const room = authenticated
        ? await APIClient.canGetPoll(meetingId, 'waiting')
        : await APIClient.canGetPollGuest(meetingId, 'waiting')

      await getRoomToken(meetingId)

      setRoom(room.data)
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          section: 'useVideoSession - join'
        }
      })
      if (error.response?.status === 402) {
        setIsLoading(false)
        showAlertModal(`Your account has reached a limit of free video conferencing minutes for this month.`)
        history.push('/video-conferencing')
        return
      }
      logoutWhenSessionError(error)
    }
  }

  const getPoll = async () => {
    const room = authenticated
      ? await APIClient.canGetPoll(meetingId, 'waiting')
      : await APIClient.canGetPollGuest(meetingId, 'waiting')
    setRoom(room.data)
  }

  const getRoomToken = async (meetingId) => {
    const tokenData = authenticated
      ? await APIClient.createToken(meetingId)
      : await APIClient.createTokenAsGuest(meetingId)
    setVideoData(tokenData?.data)
    return tokenData?.data
  }

  const start = async () => {
    setIsLoading(true)
    try {
      const room = await init()
      if (room) {
        // @ts-ignore
        history.push(`/conference/${room?.meetingId}`)
      }
    } catch (error) {
      console.log('Error on session: ', error)
      Sentry.captureException(error, {
        tags: {
          section: 'useVideoSession - start'
        }
      })
    } finally {
      setIsLoading(false)
    }
  }

  return {
    start,
    join,
    getPoll,
    isLoading,
    getRoomToken
  }
}
