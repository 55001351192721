import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { useFormik } from 'formik'
import { ContactItemType, GroupItemType } from '../../../../types'
import { colors } from '../../../../assets/colors'
import Header from '../../../../components/Header'
import { InlineButton } from '../../../../components/Button/InlineButton'
import Input from '../../../../components/Input'
import Form from '../../../../components/Input/Form'
import LongTextInput from '../../../../components/Input/LongTextInput'
import Divider from '../../../../components/Divider'
import RedButton from '../../../../components/Button/RedButton'
import SearchInput from '../../../../components/Input/SearchInput'
import MessageList from '../../../../components/blocks/MessageList'
import Field from '../../../../components/Field'
import Icon from '../../../../components/Icon'
import { Loader } from '../../../../components/Loader/Loader'
import APIClient from '../../../../apiClient'
import Modal from '../../../../components/Modal'
import ChangeViewButton from '../../../../components/Button/ChangeViewButton'
import { showAlertModal } from '../../../../store/modals'
import { useGetContact } from '../../../../hooks/queries/useGetContact'
import { useGetContactStore } from '../../../../store/contacts'
import { useQueryClient } from '@tanstack/react-query'
import { useContactGroupListQuery } from '../../../../hooks/queries/useContactGroupListQuery'
import { useGroupsQuery } from '../../../../hooks/queries/useGroupsQuery'

const inputStyle = {
  width: '70%',
  marginLeft: '30%',
  height: '40px',
  fontSize: '14px'
}

const ContactEdit: React.FC = () => {
  const history = useHistory()
  const urlParams = new URLSearchParams(history.location.search)
  const id = urlParams.get('id') || ''
  const search = urlParams.get('filter') || ''

  const queryClient = useQueryClient()

  const { data: contactApi } = useGetContact(id)
  const contactStore = useGetContactStore()

  const contact = contactApi || contactStore

  let initialGroups = {}
  if (contact && contact?.groups) {
    initialGroups = contact.groups?.reduce((acc, group) => {
      return {
        ...acc,
        [group?.id]: true
      }
    }, {})
  }

  const [mobileTouched, setMobileTouched] = useState(false)
  const [selectGroups, setSelectGroups] = useState(false)
  const [searchTouched, setTouched] = useState(false)
  const [selected, setSelected] = useState<{ [id: string]: boolean }>(initialGroups)
  const [confirmShow, setConfirmShow] = useState(false)

  const { data: groupsDataSearch } = useContactGroupListQuery<GroupItemType[]>(search, 'groups')
  const { data: groupsData } = useGroupsQuery()

  const groups = search ? groupsDataSearch : groupsData

  const { values, handleChange, handleBlur, handleSubmit, handleReset, isSubmitting } = useFormik({
    initialValues: contact || { id: null, kate_number: '', first_name: '', last_name: '' },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const valid = validate(values)
      if (valid) {
        try {
          const editResponse = await APIClient.contactUpdate({ id, req: values })
          if (editResponse.status >= 300)
            throw Error(`contact edit error ${editResponse.status}, ${editResponse.statusText}`)

          // adding contact to all selected groups
          values.groups &&
            (await Promise.all(
              values.groups.map(async (group) => {
                const updateResponse = await APIClient.contactGroupUpdate({
                  id: group.id,
                  req: {
                    name: group.name,
                    contacts: {
                      add: [
                        {
                          id: id,
                          phone_numbers: ['kate_number']
                        }
                      ]
                    }
                  }
                })
                if (updateResponse.status > 300)
                  throw new Error(`group update error ${updateResponse.status}, ${updateResponse.statusText}`)
              })
            ))

          // removing contact from all deselected groups
          const groupsToRemove =
            contact.groups &&
            contact.groups.filter((group) => values.groups && !new Set(values.groups.map((gr) => gr.id)).has(group.id))

          groupsToRemove &&
            (await Promise.all(
              groupsToRemove.map(async (group) => {
                const updateResponse = await APIClient.contactGroupUpdate({
                  id: group.id,
                  req: {
                    name: group.name,
                    contacts: {
                      remove: [id]
                    }
                  }
                })
                if (updateResponse.status > 300)
                  throw new Error(`group update error ${updateResponse.status}, ${updateResponse.statusText}`)
              })
            ))

          await queryClient.invalidateQueries(['contacts'])

          history.goBack()
        } catch (e) {
          console.log('e', e)
          showAlertModal('Ups, unable to edit contact')
        }
      }
    }
  })

  const deleteFunction = useCallback(async () => {
    try {
      const deleteResponse = await APIClient.contactDelete(id)
      if (deleteResponse.status >= 300)
        throw new Error(`status = ${deleteResponse.status}, ${deleteResponse.statusText}`)
      await queryClient.invalidateQueries(['contacts'])
      history.replace('/contacts')
    } catch (e) {
      setConfirmShow(false)
      showAlertModal('Ups, unable to delete contact')
    }
  }, [])

  const handleGroupsDeselect = useCallback(() => {
    const newSelected = { ...selected }
    Object.keys(newSelected).forEach((groupId) => {
      newSelected[groupId] = !!values?.groups?.map((group) => group.id).includes(groupId)
    })
    setSelected(newSelected)
  }, [selected, values])

  const handleGroupsSelect = useCallback(() => {
    values.groups = Object.keys(selected)
      .filter((groupId) => selected[groupId])
      .map((groupId) => {
        return groups?.find((group) => group.id === groupId)
      })
  }, [selected, values, groups])

  if (selectGroups) {
    return (
      <>
        <Header>
          <Header.SideButtons>
            <InlineButton
              onClick={() => {
                handleGroupsDeselect()
                setSelectGroups(false)
              }}
            >
              Contact
            </InlineButton>
          </Header.SideButtons>
          <Header.Title>Select Groups</Header.Title>
          <Header.SideButtons>
            <InlineButton
              onClick={() => {
                handleGroupsSelect()
                setSelectGroups(false)
              }}
            >
              Done
            </InlineButton>
          </Header.SideButtons>
        </Header>
        <SearchInput
          onFocus={() => setTouched(true)}
          onClick={() => {
            history.replace(`contactEdit?id=${id}`)
            setTouched(false)
          }}
          onChange={(e: { target: { value: string } }) =>
            history.replace(`contactEdit?id=${id}&filter=${e.target.value}`)
          }
          showCancel={searchTouched}
          value={search}
        />
        <MessageList>
          {groups?.length ? (
            groups.map((group) => {
              return (
                <div key={group.id}>
                  <Field onClick={() => setSelected({ ...selected, [group.id]: !selected[group.id] })}>
                    <Field.InfoContainer>
                      <Field.Content>{group?.name}</Field.Content>
                    </Field.InfoContainer>
                    {selected[group.id] ? <Icon name={'checkmark'} fill={colors.darkBlue} width={'20px'} /> : null}
                  </Field>
                  <Divider />
                </div>
              )
            })
          ) : (
            // eslint-disable-next-line react/no-unescaped-entities
            <MessageList.Header>You don't have any groups yet.</MessageList.Header>
          )}
        </MessageList>
      </>
    )
  }

  return (
    <>
      <Header>
        <Header.SideButtons>
          <InlineButton
            onClick={() => {
              history.goBack()
            }}
          >
            Cancel
          </InlineButton>
        </Header.SideButtons>
        <Header.Title>
          <span>Edit Contact</span>
        </Header.Title>
        <Header.EndButtons>
          {isSubmitting ? (
            <Loader width={'20px'} height={'20px'} fill={colors.white} style={{ left: '90%' }} />
          ) : (
            <InlineButton onClick={handleSubmit}>Done</InlineButton>
          )}
        </Header.EndButtons>
      </Header>
      <Form
        autoComplete="off"
        onReset={handleReset}
        style={{
          backgroundColor: colors.silver,
          width: '100%',
          height: '100%'
        }}
      >
        <Input>
          <Input.StyledInput autoComplete="off" type="text" readOnly value={values.kate_number} style={inputStyle} />
        </Input>
        <Input>
          <Input.StyledInput
            autoComplete="off"
            name="first_name"
            placeholder="First"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.first_name}
            style={inputStyle}
          />
        </Input>
        <Input>
          <Input.StyledInput
            autoComplete="off"
            name="last_name"
            placeholder="Last"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.last_name}
            style={inputStyle}
          />
        </Input>
        <div style={{ width: '100%', minHeight: '30px' }} />
        <Divider marginLeft={'0px'} />
        <ChangeViewButton style={{ paddingLeft: '10px' }} onClick={() => setSelectGroups(true)}>
          <Icon name={'add'} width={'30px'} fill={colors.mediumGrey} />
          <ChangeViewButton.Button onClick={() => setSelectGroups(true)}>
            {values?.groups?.map((group) => (
              <ChangeViewButton.Value key={group?.id}>{group?.name}</ChangeViewButton.Value>
            )) || '( Select Groups )'}
          </ChangeViewButton.Button>
        </ChangeViewButton>
        <Input>
          <Input.Label>Personal Email:</Input.Label>
          <Input.StyledInput
            autoComplete="off"
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
        </Input>
        <Input>
          <Input.Label>Work Email:</Input.Label>
          <Input.StyledInput
            autoComplete="off"
            type="email"
            name="email2"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email2}
          />
        </Input>
        {mobileTouched || values.mobile_phone ? (
          <Input>
            <Input.Label>Mobile Phone:</Input.Label>
            <Input.StyledInput
              autoComplete="off"
              autoFocus
              type="text"
              name="mobile_phone"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.mobile_phone}
            />
          </Input>
        ) : (
          <Input>
            <Input.Label>Mobile Phone:</Input.Label>
            <Input.StyledInput
              autoComplete="off"
              autoFocus
              type="text"
              name="mobile_phone"
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={() => setMobileTouched(true)}
              value={values.mobile_phone}
              placeholder={'add mobile phone'}
            />
            <Icon name={'add'} width={'30px'} fill={colors.mediumGrey} />
          </Input>
        )}
        <LongTextInput
          autoComplete="off"
          placeholder={'tap to add contact notes'}
          name="extra_info"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.extra_info}
        />
      </Form>
      <RedButton onClick={() => setConfirmShow(true)} style={{ position: 'absolute', bottom: '10px' }}>
        Delete Contact
      </RedButton>
      <Modal show={confirmShow}>
        <Modal.TextContainer>
          <Modal.HeroText>Are you sure you want to delete this contact?</Modal.HeroText>
        </Modal.TextContainer>
        <Modal.ButtonsContainer>
          <Modal.Button onClick={() => setConfirmShow(false)} bold color={colors.contrastBlue}>
            Keep it
          </Modal.Button>
          <Modal.Button onClick={deleteFunction} color={colors.red}>
            Delete
          </Modal.Button>
        </Modal.ButtonsContainer>
      </Modal>
    </>
  )
}

const validate = (values: ContactItemType) => {
  const errors = []
  if (!values.kate_number) {
    errors.push('CommunniKate')
  }
  if (!values.first_name) {
    errors.push('First name')
  }
  if (!values.last_name) {
    errors.push('Last name')
  }

  const errorMessage = `Please enter ${errors.join(', ')}`
  if (Object.keys(errors).length) {
    showAlertModal(errorMessage)
  }
  return !Object.keys(errors).length
}

export default ContactEdit
