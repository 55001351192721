import { useAutoPlayActions, useAutoPlayStore } from '../../../../../../../store/autoPlay'
import { shallow } from 'zustand/shallow'

const useAutoplay = () => {
  const { setAutoPlayEnabled } = useAutoPlayActions()
  const autoPlay = useAutoPlayStore((state) => state.enabled, shallow)

  const changeAutoPlay = () => {
    setAutoPlayEnabled(!autoPlay)
  }

  return {
    autoPlay,
    changeAutoPlay
  }
}

export default useAutoplay
