import { useMemo } from 'react'
import { getDate } from 'date-fns'
import format from 'date-fns/format'

function useDateForConference(startDate: string, endDate: string) {
  return useMemo(() => {
    return {
      day: getDate(new Date(startDate)),
      month: format(new Date(startDate), 'MMM'),
      timeRange: `${format(new Date(startDate), 'p')} - ${format(new Date(endDate), 'p')}`
    }
  }, [startDate, endDate])
}

export default useDateForConference
