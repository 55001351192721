import React from 'react'
import Avatar from '../../../../../../../components/blocks/Contact/ContactItem/Avatar'
import { StyledContactInfo } from '../../Styled/StyledContactInfo'
import { RightPanel } from '../../../../../../../layout/Layout'
import { ContactItemType } from '../../../../../../../types'
import PencilIcon from '../../Components/Icons/PencilIcon'
import PhoneIcon from '../../Components/Icons/PhoneIcon'
import MicIcon from '../../Components/Icons/MicIcon'
import MessageIcon from '../../Components/Icons/MessageIcon'
import useContacts from '../../../../../../mobile/pages/Contacts/Common/Hooks/useContacts'

interface IProps {
  currentContactId?: string
  currentContact?: ContactItemType
  sendMessage: () => void
}

const ContactInfo = ({ currentContactId, currentContact, sendMessage }: IProps) => {
  const { search } = useContacts()
  return (
    <>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        <StyledContactInfo.StyledEditLink
          to={
            search
              ? `/contacts?id=${currentContactId}&action=edit&filter=${search}`
              : `/contacts?id=${currentContactId}&action=edit`
          }
        >
          <PencilIcon />
          Edit
        </StyledContactInfo.StyledEditLink>
      </div>

      <div
        style={{
          width: '100%',
          height: 'calc(100% - 120px)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {currentContact && (
          <>
            <Avatar
              initials={`${currentContact?.first_name ? currentContact?.first_name?.[0] : ''}${
                currentContact?.last_name ? currentContact?.last_name?.[0] : ''
              }`}
              src={currentContact?.photo_url && currentContact?.photo_url}
              width="100px"
            />
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
              {currentContact.kate_number && (
                <>
                  <StyledContactInfo.StyledContactInfoButton as="a" href={`tel:${currentContact.kate_number}`}>
                    <PhoneIcon />
                  </StyledContactInfo.StyledContactInfoButton>
                  <StyledContactInfo.StyledContactInfoButton onClick={sendMessage}>
                    <MicIcon />
                  </StyledContactInfo.StyledContactInfoButton>
                </>
              )}
              {currentContact.email && (
                <StyledContactInfo.StyledContactInfoButton as="a" href={`mailto:${currentContact.email}`}>
                  <MessageIcon />
                </StyledContactInfo.StyledContactInfoButton>
              )}
            </div>
            <StyledContactInfo.StyledContactName>{`${currentContact?.first_name || ''} ${
              currentContact?.last_name || ''
            }`}</StyledContactInfo.StyledContactName>
            <StyledContactInfo.StyledContactNumber>{currentContact?.kate_number}</StyledContactInfo.StyledContactNumber>
            <StyledContactInfo.StyledContactEmail>{currentContact?.email}</StyledContactInfo.StyledContactEmail>
          </>
        )}
      </div>
      <StyledContactInfo.StyledNotesWrapper>Notes: {currentContact?.extra_info}</StyledContactInfo.StyledNotesWrapper>
    </>
  )
}

export default ContactInfo
