import React from 'react'

interface InboxProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const Inbox = ({ style = {}, fill = '#000', width = '30px', className = '', viewBox = '0 0 24 24' }: InboxProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
        M 5.75 3 C 5.269531 3 4.859375 3.339844 4.765625 3.808594 L 3.015625 12.808594 C 3.003906 12.871094 3 12.9375 3 13 L 3 19 C 3 20.09375 3.90625 21 5 21 L 19 21 C 20.09375 21 21 20.09375 21 19 L 21 13 C 21 12.9375 20.996094 12.871094 20.980469 12.808594 L 19.230469 3.808594 C 19.140625 3.339844 18.730469 3 18.25 3 Z M 6.574219 5 L 17.425781 5 L 18.980469 13 L 16 13 C 15.570312 13 15.1875 13.273438 15.050781 13.683594 L 14.453125 15.472656 C 14.351562 15.785156 14.054688 16 13.722656 16 L 10.277344 16 C 9.945312 16 9.648438 15.789062 9.542969 15.472656 L 8.945312 13.683594 C 8.8125 13.277344 8.429688 13 8 13 L 5.019531 13 Z M 6.574219 5
      "
    />
  </svg>
)

export default Inbox
