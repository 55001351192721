import React from 'react'
import SearchInput from '../../../../../../../components/GroupsAndContacts/ContactsGroupsSearchInput'
import { CentralPanel } from '../../../../../../../layout/Layout'
import { useHistory } from 'react-router'
import { GroupItemType } from '../../../../../../../types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Loader } from '../../../../../../../components/Loader/Loader'
import { colors } from '../../../../../../../assets/colors'
import MessageListItem from '../../../../../../../containers/MessageListItem'
import { Virtuoso } from 'react-virtuoso'
import { StyledNavTabLink } from '../../../../../../../components/GroupsAndContacts/Styled'

interface IProps {
  search?: string
  groups?: GroupItemType[]
  isLoading: boolean
  currentGroup?: GroupItemType
}

const GroupsListPanel = ({ search, groups, isLoading, currentGroup }: IProps) => {
  const history = useHistory()

  return (
    <CentralPanel style={{ paddingTop: '30px' }}>
      <div style={{ paddingLeft: '35px', display: 'flex' }}>
        <StyledNavTabLink to="/contacts" style={{ marginRight: '40px' }}>
          Contacts
        </StyledNavTabLink>
        <StyledNavTabLink active to="/groups">
          Groups
        </StyledNavTabLink>
      </div>
      <div>
        <SearchInput
          onClick={() => {
            history.replace(`/groups?id=${currentGroup?.id}`)
          }}
          onChange={(e: { target: { value: string } }) =>
            e.target.value
              ? history.replace(`/groups?id=${currentGroup?.id}&filter=${e.target.value}`)
              : history.replace(`/groups?id=${currentGroup?.id}`)
          }
          value={search}
        />
      </div>
      <div style={{ marginTop: '15px', height: '100%' }}>
        {isLoading ? (
          <Loader width={'20px'} height={'20px'} fill={colors.grey} />
        ) : (
          <Virtuoso
            style={{ height: '100%' }}
            data={groups}
            itemContent={(_, group) => {
              return (
                <StyledRow key={group.id} to={`/groups?id=${group.id}`} active={currentGroup?.id === group.id}>
                  <p>{group.name}</p>
                </StyledRow>
              )
            }}
          />
        )}
      </div>
    </CentralPanel>
  )
}

const StyledRow = styled(Link)`
  text-decoration: none;
  padding: 18px 15px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  cursor: pointer;
  width: 100%;
  display: block;
  background-color: ${({ active }: { active?: boolean }) => (active ? 'rgba(29, 112, 182, 0.05)' : 'transparent')};

  &:hover {
    background-color: rgba(29, 112, 182, 0.05);
  }

  & p {
    height: 17px;
    font-size: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 220px;
    color: #222;
  }
`

export default GroupsListPanel
