import React, { useState } from 'react'
import {
  Modal,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  CircularProgress
} from '@mui/material'
import Link from '@mui/material/Link'
import { useShallow } from 'zustand/react/shallow'
import { useModalsStore } from '../../../../../../../store/modals'
import { useBalanceNoticeQuery, useClosureReasonsQuery, useClosureStatusQuery } from './hooks'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import * as Sentry from '@sentry/react'
import APIClient from '../../../../../../../apiClient'

const ActionButton = styled(Button)`
  height: 36px;
  width: auto;
  padding: 12px;
  border-radius: 8px !important;
`

const AccountClosureModal = () => {
  const [step, setStep] = useState(0)
  const [isLoadingClosingAccount, setIsLoadingClosingAccount] = useState(false)
  const [reason, setReason] = useState('')
  const [feedback, setFeedback] = useState('')
  const closeAccountVisible = useModalsStore(useShallow((state) => state.closeAccount.visible))
  const setCloseAccountVisible = useModalsStore(useShallow((state) => state.actions.setCloseAccountModal))

  const { data: closureStatus, isLoading: isLoadingStatus } = useClosureStatusQuery()
  const { data: balanceNotice } = useBalanceNoticeQuery()
  const { data: closureReasons, isLoading: isLoadingReasons } = useClosureReasonsQuery()

  const handleClose = () => {
    setCloseAccountVisible(false)
    setStep(0)
    setReason('')
  }

  const closeAccount = async () => {
    try {
      setIsLoadingClosingAccount(true)
      await APIClient.closeAccount({ reasonCode: reason, comment: feedback })
      return true
    } catch (e) {
      console.log('error closing account', e)
      Sentry.captureException(e)
      return false
    } finally {
      setIsLoadingClosingAccount(false)
    }
  }

  const handleContinue = async () => {
    if (step === 0) {
      if (balanceNotice && balanceNotice.amount_due > 0) {
        setStep(1) // Show balance notice
      } else {
        setStep(2) // Skip to reason selection
      }
    } else if (step === 3) {
      try {
        const success = await closeAccount()
        if (success) {
          setStep(4) // Move to the last step after successful closure
        }
      } catch (error) {
        console.error('Error closing account:', error)
        // Handle error (e.g., show an error message to the user)
      }
    } else {
      setStep(step + 1)
    }
  }

  const handlePayNow = () => {
    if (balanceNotice?.billing_url) {
      window.open(balanceNotice.billing_url, '_blank')
    }
    setStep(step + 1)
  }

  const renderStepContent = () => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography sx={{ my: 1 }} fontWeight={'bold'} variant="h6">
              We&apos;re sorry you want to close your account
            </Typography>
            <Typography sx={{ my: 1 }}>
              Please keep in mind that all of your data will be deleted and your phone number will be re-allocated after
              a short retention period. For more information please see our cancellation policy{' '}
              <Link target="_blank" rel="noopener" href={balanceNotice?.cancellation_policy_url}>
                here
              </Link>
              .
            </Typography>
            <Box mt={4} display={'flex'} width={'100%'} justifyContent={'space-between'}>
              <ActionButton variant={'contained'} onClick={handleClose} sx={{ fontSize: { xs: 12, md: 14 } }}>
                Wait, I changed my mind
              </ActionButton>
              <ActionButton sx={{ fontSize: { xs: 12, md: 14 } }} onClick={handleContinue}>
                Continue, I still want to close
              </ActionButton>
            </Box>
          </>
        )
      case 1:
        return (
          <>
            {isLoadingStatus ? (
              <CircularProgress />
            ) : (
              <>
                <Typography variant={'body1'}>{balanceNotice?.notice_message}</Typography>
              </>
            )}
            {isLoadingStatus ? null : (
              <Box mt={4} display={'flex'} width={'100%'} justifyContent={'space-between'}>
                <ActionButton onClick={handleContinue} variant="outlined">
                  Pay Later
                </ActionButton>
                <ActionButton onClick={handlePayNow} variant="contained" color="primary">
                  Pay Now
                </ActionButton>
              </Box>
            )}
          </>
        )
      case 2:
        return (
          <>
            <Typography sx={{ mb: 1 }} fontWeight={'bold'} variant="h6">
              Select Reason to Close
            </Typography>
            {isLoadingReasons ? (
              <Box
                sx={{
                  my: 3,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <CircularProgress size={30} />
              </Box>
            ) : (
              <FormControl component="fieldset">
                <RadioGroup value={reason} onChange={(e) => setReason(e.target.value)}>
                  {closureReasons &&
                    closureReasons.map((item) => (
                      <FormControlLabel
                        key={item.reasonCode}
                        value={item.reasonCode}
                        control={<Radio />}
                        label={item.label}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            )}
            <Box mb={1} mt={3}>
              <TextField
                label="Comment"
                multiline
                maxRows={2}
                onChange={(e) => setFeedback(e.target.value)}
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Box>
            <Box mt={4} display={'flex'} width={'100%'} justifyContent={'space-between'}>
              <ActionButton onClick={handleClose} variant="outlined">
                Cancel
              </ActionButton>
              <ActionButton variant={'contained'} onClick={handleContinue} color="primary" disabled={!reason}>
                Continue
              </ActionButton>
            </Box>
          </>
        )
      case 3:
        return (
          <>
            <Typography sx={{ my: 1 }}>
              Thank you for giving us the opportunity to serve you as a customer, we&apos;re sorry to see you go. Your
              Account Closure request will be sent to our Customer Service Team for final review and processing before
              your account is fully closed.
            </Typography>
            <Box mt={4} display={'flex'} width={'100%'} justifyContent={'space-between'}>
              <ActionButton
                sx={{ fontSize: { xs: 12, md: 14 } }}
                disabled={isLoadingClosingAccount}
                variant={'contained'}
                onClick={handleClose}
              >
                Wait, I changed my mind
              </ActionButton>
              <ActionButton
                onClick={handleContinue}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: { xs: 12, md: 14 } }}
              >
                {isLoadingClosingAccount ? (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <CircularProgress size={20} sx={{ mr: 2 }} />
                    Processing
                  </Box>
                ) : (
                  'Submit for processing'
                )}
              </ActionButton>
            </Box>
          </>
        )

      case 4:
        return (
          <>
            <Typography variant="h6" fontWeight={'bold'}>
              Request submitted. Closing is pending
            </Typography>
            <Typography sx={{ my: 2 }}>Your account closure request has been submitted successfully.</Typography>
            <Box mt={2}>
              <Button onClick={handleClose} variant="contained" color="primary">
                Close
              </Button>
            </Box>
          </>
        )
      default:
        return null
    }
  }

  return (
    <Modal open={closeAccountVisible} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4
        }}
      >
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
          {closureStatus?.closurePending ? 'Account closure pending...' : renderStepContent()}
        </Box>
      </Box>
    </Modal>
  )
}

export default AccountClosureModal
