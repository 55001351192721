import styled from 'styled-components'
import React from 'react'

const Details = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Details
