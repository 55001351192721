import React, { SVGProps } from 'react'

const LinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.308 4c-.987 0-1.913.385-2.61 1.082l-1.232 1.23a3.669 3.669 0 0 0-1.081 2.611c0 .778.242 1.516.685 2.137l-1.01 1.01a3.663 3.663 0 0 0-2.137-.685c-.986 0-1.913.384-2.61 1.081l-1.231 1.231A3.669 3.669 0 0 0 4 16.307c0 .987.385 1.913 1.082 2.611A3.665 3.665 0 0 0 7.692 20c.987 0 1.913-.384 2.61-1.082l1.232-1.23a3.669 3.669 0 0 0 .946-3.6l-1.107 1.108a2.445 2.445 0 0 1-.71 1.621l-1.23 1.231c-.96.96-2.522.96-3.481 0a2.464 2.464 0 0 1 0-3.48l1.23-1.232c.48-.48 1.112-.718 1.742-.718.432 0 .86.12 1.243.345l-1.679 1.679a.616.616 0 1 0 .87.87l6.154-6.154a.616.616 0 1 0-.87-.87l-1.68 1.68a2.458 2.458 0 0 1 .374-2.985l1.231-1.231c.48-.48 1.11-.719 1.74-.719.632 0 1.261.24 1.741.719.96.96.96 2.521 0 3.48l-1.23 1.232c-.45.449-1.032.68-1.622.709l-1.107 1.107c.318.087.649.135.988.135.986 0 1.913-.384 2.61-1.081l1.231-1.231A3.669 3.669 0 0 0 20 7.693c0-.987-.384-1.913-1.082-2.611A3.669 3.669 0 0 0 16.308 4Z"
      fill="#707070"
      stroke="#707070"
      strokeWidth={0.525}
    />
  </svg>
)

export default LinkIcon
