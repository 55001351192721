export enum LockReason {
  GENERAL = 'GENERAL',
  BILLING_LOCK = 'BILLING_LOCK'
}

class AccountLockService {
  public isAccountLocked(): boolean {
    return !!localStorage.getItem('lockedReason')
  }

  public getLockReason(): LockReason | null {
    const reason = localStorage.getItem('lockedReason')
    return reason as LockReason | null
  }

  public setLockReason(reason: LockReason): void {
    localStorage.setItem('lockedReason', reason)
  }

  public clearLockReason(): void {
    localStorage.removeItem('lockedReason')
  }
}

export const accountLockService = new AccountLockService()
