import React, { SVGProps, useEffect, useState } from 'react'
import VideoConferencingHeader from '../../components/Header'
import styled from 'styled-components'
import { colors } from '../../../assets/colors'
import Input from './Input'
import Button from '../../components/Button'
import VideoConferencingLogo from '../../assets/images/video_conferencing_logo.png'
import { useHistory } from 'react-router'
import apiClient from '../../../apiClient'
import { useParams } from 'react-router-dom'
import { broadcastChannel } from '../../../App'
import * as Sentry from '@sentry/react'

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-left: 0;

  @media (min-width: 768px) {
    flex: 1;
    height: 480px;
    width: 100%;
    margin-left: 5%;
  }
`

const Title = styled.h2`
  color: ${colors.darkBlue};
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  @media (max-width: 460px) {
    font-size: 24px;
    line-height: 30px;
  }
`

const InputWrapper = styled.div`
  margin-top: 5px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const LinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.308 4c-.987 0-1.913.385-2.61 1.082l-1.232 1.23a3.669 3.669 0 0 0-1.081 2.611c0 .778.242 1.516.685 2.137l-1.01 1.01a3.663 3.663 0 0 0-2.137-.685c-.986 0-1.913.384-2.61 1.081l-1.231 1.231A3.669 3.669 0 0 0 4 16.307c0 .987.385 1.913 1.082 2.611A3.665 3.665 0 0 0 7.692 20c.987 0 1.913-.384 2.61-1.082l1.232-1.23a3.669 3.669 0 0 0 .946-3.6l-1.107 1.108a2.445 2.445 0 0 1-.71 1.621l-1.23 1.231c-.96.96-2.522.96-3.481 0a2.464 2.464 0 0 1 0-3.48l1.23-1.232c.48-.48 1.112-.718 1.742-.718.432 0 .86.12 1.243.345l-1.679 1.679a.616.616 0 1 0 .87.87l6.154-6.154a.616.616 0 1 0-.87-.87l-1.68 1.68a2.458 2.458 0 0 1 .374-2.985l1.231-1.231c.48-.48 1.11-.719 1.74-.719.632 0 1.261.24 1.741.719.96.96.96 2.521 0 3.48l-1.23 1.232c-.45.449-1.032.68-1.622.709l-1.107 1.107c.318.087.649.135.988.135.986 0 1.913-.384 2.61-1.081l1.231-1.231A3.669 3.669 0 0 0 20 7.693c0-.987-.384-1.913-1.082-2.611A3.669 3.669 0 0 0 16.308 4Z"
      fill="#1D70B6"
      stroke="#1D70B6"
      strokeWidth={0.525}
    />
  </svg>
)

const KeyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.58928 7.83436L6.92906 8.05854L7.21748 7.77129L13.81 1.20567L14.3529 1.74631L13.4572 2.63834L13.1014 2.99262L13.4572 3.34689L15.2914 5.17366L14.7486 5.71428L12.9114 3.88464L12.5586 3.53325L12.2058 3.88465L10.9544 5.13096L10.5987 5.48523L10.9544 5.8395L12.4758 7.35471L11.9329 7.89534L10.4086 6.37729L10.0558 6.02591L9.70298 6.37728L7.76323 8.30903L7.47247 8.59859L7.69975 8.94025C8.70824 10.4563 8.54152 12.5192 7.20008 13.8551L7.55291 14.2094L7.20008 13.8551C5.66737 15.3816 3.18146 15.3816 1.64871 13.8551C0.117088 12.3297 0.117103 9.85747 1.64871 8.33214L1.64872 8.33213C2.99081 6.99548 5.06513 6.82869 6.58928 7.83436ZM6.65435 13.3173C7.88731 12.0895 7.88728 10.0978 6.65434 8.86988C5.42256 7.64307 3.42626 7.64308 2.19446 8.86988C0.961547 10.0978 0.961525 12.0895 2.19448 13.3173C3.42627 14.5441 5.42257 14.5441 6.65435 13.3173Z"
      fill="#1D70B6"
      stroke="#1D70B6"
    />
  </svg>
)

const Arrow = () => {
  return (
    <svg width="41" height="16" viewBox="0 0 41 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.7071 8.70711C41.0976 8.31658 41.0976 7.68342 40.7071 7.29289L34.3431 0.928932C33.9526 0.538408 33.3195 0.538408 32.9289 0.928932C32.5384 1.31946 32.5384 1.95262 32.9289 2.34315L38.5858 8L32.9289 13.6569C32.5384 14.0474 32.5384 14.6805 32.9289 15.0711C33.3195 15.4616 33.9526 15.4616 34.3431 15.0711L40.7071 8.70711ZM0 9H40V7H0V9Z"
        fill="#171717"
      />
    </svg>
  )
}

const Live = () => {
  return (
    <svg width="101" height="45" viewBox="0 0 101 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.05" width="101" height="45" rx="10" fill="#171717" />
      <path
        d="M49.1016 30V15.7227H51.4355V27.998H57.4805V30H49.1016ZM59.9414 30V15.7227H62.2754V30H59.9414ZM74.1895 15.7227H76.6113L71.6113 30H69.1504L64.1699 15.7227H66.5723L69.5508 24.5996C69.707 25.0228 69.873 25.5697 70.0488 26.2402C70.2246 26.9043 70.3385 27.3991 70.3906 27.7246C70.4753 27.2298 70.6055 26.6569 70.7812 26.0059C70.957 25.3548 71.1003 24.873 71.2109 24.5605L74.1895 15.7227ZM86.5723 30H78.4961V15.7227H86.5723V17.6953H80.8301V21.582H86.2109V23.5352H80.8301V28.0176H86.5723V30Z"
        fill="#171717"
      />
      <path
        d="M32 20.2L37.2133 16.5507C37.4395 16.3924 37.7513 16.4474 37.9096 16.6736C37.9684 16.7576 38 16.8577 38 16.9603V29.0397C38 29.3158 37.7761 29.5397 37.5 29.5397C37.3974 29.5397 37.2973 29.5081 37.2133 29.4493L32 25.8V30C32 30.5523 31.5523 31 31 31H17C16.4477 31 16 30.5523 16 30V16C16 15.4477 16.4477 15 17 15H31C31.5523 15 32 15.4477 32 16V20.2ZM32 23.3587L36 26.1587V19.8413L32 22.6413V23.3587ZM18 17V29H30V17H18ZM20 19H22V21H20V19Z"
        fill="#171717"
      />
    </svg>
  )
}

const Progress = styled.div<{ progress: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props: any) => props.progress || 10}px;
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(270deg, #7ee9fd 2.09%, #1a85f9 98.39%);
  animation: animate 3.5s ease-in-out;

  @keyframes animate {
    0% {
      width: 0;
      left: 0;
    }
    100% {
      width: 100%;
      left: 0;
    }
  }
`

const Percent = styled.div`
  position: relative;
  top: 2px;
  width: 311px;
  height: 12px;
  background: #eff0f1;
  border-radius: 100px;
  margin: 0 10px;
  overflow: hidden;
`

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  margin-top: 20px;
`

const StreamConference = () => {
  const history = useHistory()
  const { meetingId } = useParams<{ meetingId: string }>()

  const [serverUrl, setServerUrl] = useState<string>(null)
  const [streamKey, setStreamKey] = useState<string>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)

  useEffect(() => {
    if (!isLoading) return
    const p = setInterval(() => {
      setProgress((prev) => prev + 50)
    }, 1000)

    if (progress >= 311) {
      clearInterval(p)
    }

    return () => {
      clearInterval(p)
    }
  }, [progress, isLoading])

  if (isLoading) {
    return (
      <>
        <VideoConferencingHeader />
        <InfoContainer>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img height="50" src={VideoConferencingLogo} alt="Video Conferencing Logo" />
            <div style={{ marginLeft: 25, marginRight: 25 }}>
              <Arrow />
            </div>
            <Live />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
            {progress >= 250 ? (
              <span style={{ color: colors.mediumGrey, fontFamily: 'Open Sans' }}>
                Setting Up your meeting for custom streaming service
              </span>
            ) : (
              <span style={{ color: colors.mediumGrey, fontFamily: 'Open Sans' }}>
                Preparing KateMeet to live stream
              </span>
            )}
          </div>
          <div>
            <Container>
              <Percent>
                <Progress progress={progress} />
              </Percent>
            </Container>
          </div>
        </InfoContainer>
      </>
    )
  }

  return (
    <>
      <VideoConferencingHeader />
      <InfoContainer>
        <Title>Stream the Katemeet to a Custom Streaming Service</Title>
        <InputWrapper>
          <Input
            style={{ width: '380px', marginTop: 30 }}
            icon={<LinkIcon />}
            onChange={(e) => setServerUrl(e.target.value)}
            label={'Server URL'}
            value={serverUrl}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            style={{ width: '380px', marginTop: 30 }}
            icon={<KeyIcon />}
            onChange={(e) => setStreamKey(e.target.value)}
            label={'Streaming Key (optional)'}
            value={streamKey}
          />
        </InputWrapper>
        <InputWrapper>
          <Button
            style={{ width: '380px', marginTop: 30 }}
            onClick={async () => {
              try {
                setIsLoading(true)
                const res = await apiClient.startBroadcast(meetingId, { serverUrl, streamKey })
                const data = res?.data
                const broadcastId = data?.id
                broadcastChannel.postMessage({ type: 'startBroadcasting', broadcastId })
                window.close()
              } catch (e) {
                console.log('Error starting broadcast', e)
                Sentry.captureException(e, {
                  tags: {
                    page: 'StreamConference - start broadcast'
                  }
                })
              } finally {
                setIsLoading(false)
              }
            }}
          >
            Go Live
          </Button>
        </InputWrapper>
      </InfoContainer>
    </>
  )
}

export default StreamConference
