import styled from 'styled-components'
import { colors } from '../../../../../../../assets/colors'

export const AccountInfoContentWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const AccountInfoContentTitle = styled.p`
  font-size: 32px;
  margin-bottom: 10px;
  margin-top: 20px;
  font-weight: 600;
  text-align: center;
  color: ${colors.darkGrey};
`
