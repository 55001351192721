import React from 'react'

interface PauseButtonProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const PauseButton = ({
  style = {},
  fill = '#000',
  width = '30px',
  className = '',
  viewBox = '0 0 24 24'
}: PauseButtonProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
        M 12 2 C 6.477 2 2 6.477 2 12 C 2 17.523 6.477 22 12 22 C 17.523 22 22 17.523 22 12 C 22 6.477 17.523 2 12 2 z M 12 4 C 16.411 4 20 7.589 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 7.589 7.589 4 12 4 z M 9 8 L 9 16 L 11 16 L 11 8 L 9 8 z M 13 8 L 13 16 L 15 16 L 15 8 L 13 8 z
      "
    />
  </svg>
)

export default PauseButton
