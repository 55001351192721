import React, { useEffect, useState } from 'react'
import APIClient from '../../../../apiClient'
import AvatarPlaceholder from '../../../../assets/images/avatar_placeholder.png'

const HeaderProfileLink = () => {
  const [userAvatar, setUserAvatar] = useState()

  useEffect(() => {
    async function getUserPhoto() {
      try {
        const response = await APIClient.getUserPhoto()
        if (response.data) return setUserAvatar(response.data.Location)
      } catch (e) {
        console.log('error: ', e)
      }
    }

    getUserPhoto()
  }, [])

  if (userAvatar) {
    return <img height="40px" width="40px" style={{ borderRadius: 20 }} src={userAvatar} alt="User Avatar" />
  }

  return (
    <div style={{ height: 40, width: 40 }}>
      <img
        height="40px"
        width="40px"
        style={{ borderRadius: 20 }}
        src={AvatarPlaceholder}
        alt="User Avatar Placeholder"
      />
    </div>
  )
}

export default HeaderProfileLink
