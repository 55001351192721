import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../assets/colors'
import appSyncClient from '../../gql/client'
import { getConfigQuery, GetConfigQueryResponse } from '../../gql/queries/getConfig'
import { useHistory, useLocation } from 'react-router'
import { useUserActions } from '../../store/user'
import useIsMobile from '../../hooks/useIsMobile'

const AccountNeedAttentionContainer = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  border-bottom-right-radius: 5px;

  padding: 2px;
  padding-left: 5px;

  background: ${colors.red};

  span {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: ${colors.white};
  }
`

const ResolveButton = styled.button`
  color: ${colors.white};
  border: none;
  background: none;
  text-decoration: underline;
  font-family: 'Open Sans', sans-serif;

  cursor: pointer;
`

const AccountNeedAttention = () => {
  const history = useHistory()
  const location = useLocation()
  const isMobile = useIsMobile()

  const { setAcccountNeedAttention } = useUserActions()
  const [paymentEvent, setPaymentEvent] = useState<{
    eventType: string
    paymentNextActionSecret?: string
    hostedVerificationUrl?: string
  }>(null)

  useEffect(() => {
    ;(async () => {
      const res = (await appSyncClient.graphql({ query: getConfigQuery })) as GetConfigQueryResponse
      const foundAchEvent = res?.data?.getConfig?.billing?.accountNeedsAttention?.find(
        (item) => item?.hostedVerificationUrl
      )
      if (foundAchEvent) {
        setPaymentEvent(foundAchEvent)
      } else {
        const foundPaymentEvent = res?.data?.getConfig?.billing?.accountNeedsAttention?.find(
          (item) => item?.paymentNextActionSecret
        )
        setPaymentEvent(foundPaymentEvent)
      }
    })()
  }, [])

  useEffect(() => {
    if (paymentEvent) {
      setAcccountNeedAttention(true)
      return
    }
    setAcccountNeedAttention(false)
  }, [paymentEvent])

  const onResolve = async () => {
    isMobile ? history.push('/billing?tab=resolve-now') : history.push('/options/billing?tab=resolve-now')
  }

  if (!paymentEvent || location.pathname.includes('/billing')) return null

  return (
    <AccountNeedAttentionContainer>
      <span>Your account needs attention</span>
      <ResolveButton onClick={onResolve}>Resolve Now</ResolveButton>
    </AccountNeedAttentionContainer>
  )
}

export default AccountNeedAttention
