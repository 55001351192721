import { useQuery } from '@tanstack/react-query'
import { ContactItemType, GroupItemType } from '../../types'
import APIClient from '../../apiClient'

export const useGroupsQuery = () => {
  return useQuery<unknown, unknown, GroupItemType[]>({
    queryKey: ['groups'],
    queryFn: async () => {
      const res = await APIClient.contactGroupList()
      return res.data
    },
    cacheTime: 1000 * 60 * 30 // 30 minutes
  })
}
