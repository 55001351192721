import React from 'react'
import styled from 'styled-components'
import ArrowLeft from '../../../../components/Icon/ArrowLeft'
import ArrowRight from '../../../../components/Icon/ArrowRight'
import ArrowContainer from './ArrowContainer'
import HeaderLabel from './HeaderLabel'

const Container = styled.div`
  display: flex;
  margin: 10px 0 30px;
  justify-content: space-between;
  flex-direction: row;
  align-itemms: center;
`

const StyledButton = styled.button`
  background-color: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

interface CalendarHeaderProps {
  label: string
  onPrevClick: () => void
  onNextClick: () => void
}

const CalendarHeader = ({ label, onPrevClick, onNextClick }: CalendarHeaderProps) => {
  return (
    <>
      <Container>
        <HeaderLabel>{label}</HeaderLabel>
        <ArrowContainer>
          <StyledButton onClick={onPrevClick}>
            <ArrowLeft />
          </StyledButton>
          <StyledButton onClick={onNextClick}>
            <ArrowRight />
          </StyledButton>
        </ArrowContainer>
      </Container>
    </>
  )
}

export default CalendarHeader
