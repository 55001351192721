import APIClient from '../../../apiClient'
import { ContactItemType, GroupItemType, Recipient } from '../../../types'
import { useNewMessageActions, useNewMessageStore } from '../../mobile/pages/NewMessage/store/NewMessageStore'
import { useState } from 'react'
import { useContactGroupListQuery } from '../../../hooks/queries/useContactGroupListQuery'
import { useGroupsQuery } from '../../../hooks/queries/useGroupsQuery'

function isContactItemType(item: any): item is ContactItemType {
  return item && (item.first_name !== undefined || item.last_name !== undefined)
}

function isGroupItemType(item: any): item is GroupItemType {
  return item && item.name !== undefined
}

export const useRecipients = () => {
  const setRecipients = useNewMessageStore((state) => state.actions.setRecipients)
  const recipients = useNewMessageStore((state) => state.recipients)
  const { setSelectedRecipients } = useNewMessageActions()
  const [recipientsOptions, setRecipientsOptions] = useState<Recipient[]>([])

  const { data: contacts } = useContactGroupListQuery()
  const { data: groups } = useGroupsQuery()

  const onRemoveRecipient = () => {
    if (!recipients.length) return null

    const currentRecipients = [...recipients]
    const poppedRecipient = currentRecipients.pop()
    if (poppedRecipient) setRecipientsOptions([...recipientsOptions, poppedRecipient])
    setRecipients(currentRecipients)
  }

  const onSearchRecipient = async (searchQuery: string) => {
    try {
      if (searchQuery.length >= 2) {
        const foundContacts = contacts?.filter((contact) => {
          return (
            contact?.first_name?.toLowerCase()?.startsWith(searchQuery?.toLowerCase()) ||
            contact?.last_name?.toLowerCase()?.startsWith(searchQuery?.toLowerCase())
          )
        })

        const foundGroups = groups?.filter((group) => {
          return group?.name?.toLowerCase()?.startsWith(searchQuery?.toLowerCase())
        })

        const options = [...foundContacts, ...foundGroups]
          .map((item) => {
            if (isContactItemType(item)) {
              return {
                id: item.id,
                label: item?.first_name ? `${item?.first_name} ${item?.last_name}` : ''
              }
            }
            if (isGroupItemType(item)) {
              return {
                id: item.id,
                label: item?.name
              }
            }
          })
          .filter((item) => {
            // Filter if already selected in recipients
            return !recipients.find((recipient) => recipient.id === item.id)
          })

        setRecipientsOptions(options)
      } else {
        setRecipientsOptions([])
      }
    } catch (e) {
      console.log('error on finding contacts ===', e)
      window.alert('Ups, contacts cannot be found')
    }
  }

  const onSelectedRecipient = (recipientId: string) => {
    if (recipientsOptions.length) {
      setRecipientsOptions((recipientsOptions) => recipientsOptions.filter((i) => i.id !== recipientId))
    }

    const group: GroupItemType = groups?.find((item) => item.id === recipientId)
    const contact: ContactItemType = contacts?.find((item) => item.id === recipientId)

    let label = ''

    if (contact) label = contact.first_name && contact.last_name ? `${contact.first_name} ${contact.last_name}` : ''

    if (group) label = group.name

    const newRecipient = {
      id: recipientId,
      label,
      isEmail: contact ? !contact.kate_number : false,
      isGroup: !!group
    }

    setRecipients([...recipients, newRecipient])
    // Update selected recipients, when selectedRecipients modal is open
    // setSelectedRecipients(newRecipient.id)
  }

  const onNewSelectedRecipientList = (recipientsIds: string[]) => {
    const newRecipientsData: Recipient[] = recipientsIds.map((item) => {
      const group: GroupItemType = groups?.find((group) => group.id === item)
      const contact: ContactItemType = contacts?.find((c) => c.id === item)

      return {
        id: item,
        label: contact ? `${contact.first_name} ${contact.last_name}` : group.name,
        isEmail: contact ? !contact.kate_number : false,
        isGroup: !!group
      }
    })
    // Don't overwrite it. Add new select state
    setRecipients(newRecipientsData)
  }

  return {
    onRemoveRecipient,
    onSearchRecipient,
    onSelectedRecipient,
    onNewSelectedRecipientList,
    recipientsOptions,
    recipients,
    setRecipientsOptions
  }
}
