import { useLocation } from 'react-router-dom'

type TTabs =
  | null
  | 'payment-options'
  | 'price-plans'
  | 'billing-address'
  | 'billing-statements'
  | 'payment-options-update-status'
  | 'billing-portal'

const useCurrentTab = () => {
  const searchParams = new URLSearchParams(useLocation().search)
  const currentTab = searchParams.get('tab') as TTabs

  return [currentTab]
}

export default useCurrentTab
