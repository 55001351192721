import React from 'react'

interface VideocamProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const Videocam = ({
  style = {},
  fill = '#000',
  width = '24px',
  className = '',
  viewBox = '0 0 24 24'
}: VideocamProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
      M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z
      "
    />
  </svg>
)

export default Videocam
