import React from 'react'
import { Avatar, Typography } from '@mui/material'
import { colors } from '../../../../../../assets/colors'
import { useAddContact } from './useAddContact'

const AvatarContact = ({ message }) => {
  const { addContact } = useAddContact()
  if (message?.contact) {
    if (!message?.contact.photo_url) {
      const initials = `${message?.contact?.first_name[0]}${message?.contact?.last_name[0]}`
      return (
        <Avatar sx={{ backgroundColor: colors.lightBlue2 }}>
          <Typography color={colors.darkBlue}>{initials}</Typography>
        </Avatar>
      )
    }

    return <Avatar src={message?.contact?.photo_url} />
  }

  return (
    <>
      <Avatar
        onClick={(e) => {
          e.stopPropagation()
          addContact(message)
        }}
        placeholder={'Add contact'}
        sx={{ backgroundColor: '#fff', border: `1px solid ${colors.mediumGrey}`, cursor: 'pointer' }}
      >
        <Typography
          fontSize={8}
          color={colors.mediumGrey}
          maxWidth={20}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          textAlign={'center'}
        >
          Add contact
        </Typography>
      </Avatar>
    </>
  )
}

export default AvatarContact
