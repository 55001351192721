import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

const FallbackComponent = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'white'
      }}
    >
      <CircularProgress />
    </div>
  )
}

export default FallbackComponent
