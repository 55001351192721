import React from 'react'
import styled from 'styled-components'
import { colors } from '../../assets/colors'
import { TextContainer } from './TextContainer'
import { ModalButton } from './Button'
import { HeroText } from './HeroText'
import { DescriptionText } from './DescriptionText'
import { ButtonsContainer } from './ButtonsContainer'
import { IChildren, ReactTransitionGroupStateType } from '../../types'
import { createPortal } from 'react-dom'
import { Transition } from 'react-transition-group'
import IconInfo from './IconInfo'

const BackgroundContainer = styled.div<ReactTransitionGroupStateType>`
  transition: 200ms;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.25);
  opacity: ${(props) => (props.state === 'entered' ? 1 : 0)};
  padding: 50px;
  z-index: 99999999;
`

const ModalContainer = styled.div<ReactTransitionGroupStateType>`
  transition: opacity 250ms, transform 300ms;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  color: ${colors.black};
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  position: relative;
  opacity: ${(props) => (props.state === 'entered' ? 1 : 0)};
  transform: ${(props) => (props.state === 'entering' ? 'scale(1.15)' : 'scale(1)')};
`

interface IModal extends IChildren {
  show?: boolean
}

const Modal = ({ show, children }: IModal) => {
  const modalRoot = document.getElementById('modal')

  if (!modalRoot) return null
  return createPortal(
    <Transition in={show} timeout={350} unmountOnExit mountOnEnter>
      {(state) => (
        <BackgroundContainer state={state}>
          <ModalContainer state={state}>{children}</ModalContainer>
        </BackgroundContainer>
      )}
    </Transition>,
    modalRoot
  )
}

Modal.TextContainer = TextContainer
Modal.HeroText = HeroText
Modal.DescriptionText = DescriptionText
Modal.ButtonsContainer = ButtonsContainer
Modal.Button = ModalButton
Modal.IconInfo = IconInfo

export default Modal
