import styled from 'styled-components'
import React, { ReactNode } from 'react'
import Label from './Label'
import Button from './Button'
import Value from './Value'

interface IProps {
  children: ReactNode
  style?: { [key: string]: any }
  onClick?: () => void
}

const Wrapper = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  min-height: 40px;
  cursor: pointer;
  opacity: 0.95;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

const ChangeViewButton = ({ children, style, onClick }: IProps) => (
  <Wrapper style={style} onClick={onClick}>
    {children}
  </Wrapper>
)

ChangeViewButton.Label = Label
ChangeViewButton.Button = Button
ChangeViewButton.Value = Value

export default ChangeViewButton
