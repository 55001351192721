import styled from 'styled-components'
import { colors } from '../../../assets/colors'

interface IProps {
  width?: string
  fontSize?: string
}

const AvatarButton = styled.div<IProps>`
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 40px;
  margin: 0 5px;
  border: 1px solid ${colors.lightGrey};
  color: ${colors.lightGrey};
  text-decoration: none;
  font-size: ${(props) => props.fontSize || '10px'};
  line-height: ${(props) => props.fontSize || '10px'};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${colors.white};
`

export default AvatarButton
