import { useQuery } from '@tanstack/react-query'
import APIClient, { SuggestedTag } from '../../apiClient'

export const useGetMessageTags = (messageId: string) => {
  return useQuery<unknown, unknown, SuggestedTag[]>({
    queryKey: ['messageTags', messageId],
    queryFn: async () => {
      const res = await APIClient.messageTagsGet(messageId)
      return res.data
    },
    cacheTime: 1000 * 60 * 15 // 15 minutes
  })
}
