import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ContactItemType, GroupItemType } from '../../../../../types'
import { colors } from '../../../../../assets/colors'
import Header from '../../../../../components/Header'
import { InlineButton } from '../../../../../components/Button/InlineButton'
import SearchInput from '../../../../../components/Input/SearchInput'
import ContactsList from '../../../../../components/blocks/Contact/ContactsList/ContactsList'
import { Loader } from '../../../../../components/Loader/Loader'
import styled from 'styled-components'
import { Tabs } from '../../../../../components/Tabs'
import { ContactRecipientItem, GroupRecipientItem } from './RecipientListItem'
import { useContactGroupListQuery } from '../../../../../hooks/queries/useContactGroupListQuery'
import { useGroupsQuery } from '../../../../../hooks/queries/useGroupsQuery'
import { Virtuoso } from 'react-virtuoso'
import { useNewMessageActions, useNewMessageStore } from '../store/NewMessageStore'
import { shallow } from 'zustand/shallow'

const Container = styled.div<{ state: string }>`
  transition: top ease-out 250ms;
  position: absolute;
  top: ${({ state }) => (state === 'entered' ? '0' : '100%')};
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: ${colors.silver};
  height: 100vh;
  overflow: hidden;
`

const tabNames = {
  groups: 'Groups',
  kate: 'Kate',
  email: 'Email'
}

interface ISelectContacts {
  state: string
  onChange: (selected: string[]) => void
}

const isGroupType = (item: GroupItemType | ContactItemType): item is GroupItemType => {
  return 'name' in item
}

const SelectRecipients: React.FC<ISelectContacts> = ({ state, onChange }) => {
  const history = useHistory()

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [touched, setTouched] = useState<boolean>(false)
  const [items, setItems] = useState<ContactItemType[] | GroupItemType[]>([])
  const [currentTab, setCurrentTab] = useState<string>(tabNames.kate)

  const { setSelectRecipientsModal } = useNewMessageActions()

  const recipients = useNewMessageStore((state) => state.recipients, shallow)

  const selectRecipientsModal = useNewMessageStore((storeState) => storeState.modals.selectRecipientsModal)
  const [selectedRecipients, setSelectedRecipients, clearSelectedRecipients] = useNewMessageStore((state) => [
    state.selectedRecipients,
    state.actions.setSelectedRecipients,
    state.actions.clearSelectedRecipients
  ])

  const { data: contacts, isLoading: isLoadingContacts } = useContactGroupListQuery(searchQuery)
  const { data: groups, isLoading: isLoadingGroups } = useGroupsQuery()

  const isLoading = isLoadingContacts || isLoadingGroups

  useEffect(() => {
    // set selected recipients from new message form as selected
    if (recipients?.length) {
      recipients.map((item) => {
        setSelectedRecipients(item.id)
      })
    }
  }, [recipients])

  useEffect(() => {
    if (!contacts && !groups) return

    if (searchQuery?.length > 1) {
      if (currentTab === tabNames.groups) {
        setItems(groups?.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase())))
        return
      }
      if (currentTab === tabNames.kate) {
        setItems(
          contacts?.filter(
            (item) =>
              item.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
              item.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
              item?.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              item?.email2?.toLowerCase().includes(searchQuery.toLowerCase())
          )
        )
        return
      }
      if (currentTab === tabNames.email) {
        setItems(
          contacts?.filter(
            (item) =>
              item?.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              item?.email2?.toLowerCase().includes(searchQuery.toLowerCase())
          )
        )
        return
      }
    } else {
      return currentTab === tabNames.groups ? setItems(groups) : setItems(contacts)
    }
  }, [contacts, groups, searchQuery, currentTab])

  const onSelectContacts = () => {
    const selectedRecipientsIds = Object.keys(selectedRecipients).filter((item) => selectedRecipients[item])
    onChange(selectedRecipientsIds)
    setSelectRecipientsModal(false)
  }

  return (
    <Container state={state}>
      <Header style={{ zIndex: 999 }}>
        <Header.SideButtons>
          <InlineButton
            onClick={() => {
              if (selectRecipientsModal) {
                clearSelectedRecipients()
                setSelectRecipientsModal(false)
                return
              }
              clearSelectedRecipients()
              history.goBack()
            }}
          >
            Cancel
          </InlineButton>
        </Header.SideButtons>
        <Header.Title>
          <span>Select Contacts</span>
        </Header.Title>
        <Header.EndButtons>
          <InlineButton onClick={onSelectContacts}>Select</InlineButton>
        </Header.EndButtons>
      </Header>
      <Tabs tabs={Object.values(tabNames)} defaultTab={currentTab} onChange={setCurrentTab} />

      <>
        <SearchInput
          onFocus={() => setTouched(true)}
          onClick={() => {
            setTouched(false)
            setSearchQuery('')
          }}
          onChange={(e: { target: { value: string } }) => setSearchQuery(e.target.value)}
          showCancel={touched}
          value={searchQuery}
        />
        {isLoading ? (
          <div style={{ width: '100%', height: '100%', backgroundColor: colors.silver, paddingTop: '20px' }}>
            <Loader width={'20px'} height={'20px'} fill={colors.lightGrey} />
          </div>
        ) : (
          <ContactsList>
            <ContactsList.ItemsList>
              <Virtuoso
                style={{ height: 'calc(100vh - 125px)' }}
                data={items as any}
                itemContent={(_, recipient) => {
                  if (currentTab === tabNames.groups && isGroupType(recipient)) {
                    return (
                      <GroupRecipientItem
                        recipient={recipient}
                        onClick={() => setSelectedRecipients(recipient?.id)}
                        isSelected={selectedRecipients[recipient?.id]}
                      />
                    )
                  }
                  return (
                    <ContactRecipientItem
                      isSelected={selectedRecipients[recipient?.id]}
                      recipient={recipient}
                      onClick={() => setSelectedRecipients(recipient?.id)}
                    />
                  )
                }}
              />
            </ContactsList.ItemsList>
          </ContactsList>
        )}
      </>
    </Container>
  )
}

export default SelectRecipients
