import React, { useEffect, useState } from 'react'
import InviteLink from '../../../components/Icon/InviteLink'
import CopyToClipboard from 'react-copy-to-clipboard'
import styled from 'styled-components'
import Checkmark from '../../../components/Icon/Checkmark'

const SingleIconContainer = styled.button`
  background-color: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

interface Props {
  meetingId: string
}

const ShareConferenceLinkButton = ({ meetingId }: Props) => {
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    if (isCopied) {
      const copiedTimeout = setTimeout(() => {
        setIsCopied(false)
      }, 3000)

      return () => {
        clearTimeout(copiedTimeout)
      }
    }
  }, [isCopied])

  return (
    <CopyToClipboard
      text={`${window.location.origin}/conference/${meetingId}`}
      onCopy={() => {
        setIsCopied(true)
      }}
    >
      <SingleIconContainer title="Copy to clipboard">
        {isCopied ? <Checkmark width={'20'} fill={'#1D70B6'} /> : <InviteLink />}
      </SingleIconContainer>
    </CopyToClipboard>
  )
}

export default ShareConferenceLinkButton
