import React from 'react'

interface AddProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const Exit = ({
  style = {},
  fill = '#000',
  width = '32px',
  height = '32px',
  className = '',
  viewBox = '0 0 32 32'
}: AddProps) => (
  <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="22.6274"
      y="24.4854"
      width="22"
      height="2"
      rx="1"
      transform="rotate(-135 22.6274 24.4854)"
      fill="#171717"
    />
    <rect x="7.07107" y="23.071" width="22" height="2" rx="1" transform="rotate(-45 7.07107 23.071)" fill="#171717" />
  </svg>
)

export default Exit
