import axios from 'axios'
import axiosRetry from 'axios-retry'
import APIClient from './apiClient'
import tokenManager from './services/TokenManager'
import { accountLockService, LockReason } from './services/AccountLockService'

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  timeout: 20000
})

const vonageClient = axios.create({
  baseURL: process.env.REACT_APP_VONAGE_URL,
  withCredentials: true,
  timeout: 20000
})

const vonageGuestClient = axios.create({
  baseURL: process.env.REACT_APP_VONAGE_URL,
  withCredentials: true,
  timeout: 20000
})

vonageClient.interceptors.request.use(function (config) {
  const kateTokenX = localStorage.getItem('kateTokenX')
  if (config?.headers) config.headers.Authorization = `Bearer ${kateTokenX}`
  return config
})

axiosRetry(vonageClient, { retries: 0 })
axiosRetry(vonageGuestClient, { retries: 0 })

export const authClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  timeout: 20000
})

const disabledTokenRefreshEndpoints = ['/login', '/logout', '/refreshtoken', '/deregister']

client.interceptors.request.use((config) => {
  const token = localStorage.getItem('kateTokenX')
  if (token && config?.headers) config.headers['Authorization'] = `Bearer ${token}`

  return config
})

vonageClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('kateTokenX')
  if (token && config?.headers) config.headers['Authorization'] = `Bearer ${token}`

  return config
})

vonageGuestClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('kateGuestTokenX')
  if (token && config?.headers) config.headers['Authorization'] = `Bearer ${token}`

  return config
})

client.interceptors.response.use(
  (response) => {
    if (response.headers['x-session-token']) {
      localStorage.setItem('kateTokenX', response.headers['x-session-token'])
      client.defaults.headers.common['Authorization'] = `Bearer ${response.headers['x-session-token']}`
      vonageClient.defaults.headers.common['Authorization'] = `Bearer ${response.headers['x-session-token']}`
    }
    return response
  },
  async (error) => {
    const originalConfig = error.config

    if (accountLockService.isAccountLocked()) {
      console.log('Account is currently locked')
      // We're already handling a lock, so simply reject further requests.
      return Promise.reject(error)
    }
    // Handling the 423 status for locked accounts
    if (error.response && error.response.status === 423) {
      console.log('Recognized locked account. Disabling app access...')
      accountLockService.setLockReason(LockReason.BILLING_LOCK)
      window.location.href = '/account-locked'

      return Promise.reject(error)
    }

    // Handling the 401 status for locked accounts
    if (error.response && error.response.status === 401 && error.response.data.locked) {
      console.log('Recognized locked account. Disabling app access...')

      return Promise.reject(error)
    }

    if (disabledTokenRefreshEndpoints.includes(error?.response?.config?.url)) {
      return Promise.reject(error)
    }

    if (error.response && error.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true
      try {
        await tokenManager.refreshKateToken()
        return client(originalConfig)
      } catch (err) {
        return Promise.reject(err)
      }
    }

    return Promise.reject(error)
  }
)

vonageClient.interceptors.response.use(
  (response) => {
    if (response && response.headers && response.headers['x-session-token']) {
      localStorage.setItem('kateTokenX', response.headers['x-session-token'])
      client.defaults.headers.common['Authorization'] = `Bearer ${response.headers['x-session-token']}`
      vonageClient.defaults.headers.common['Authorization'] = `Bearer ${response.headers['x-session-token']}`
    }
    return response
  },
  async (error) => {
    const originalConfig = error.config

    if (disabledTokenRefreshEndpoints.includes(error?.response?.config?.url)) {
      return Promise.reject(error)
    }

    if (error.response && (error.response.status === 401 || error.response.status === 403) && !originalConfig._retry) {
      originalConfig._retry = true
      try {
        await tokenManager.refreshKateToken()
        return vonageClient(originalConfig)
      } catch (err) {
        return Promise.reject(err)
      }
    }

    return Promise.reject(error)
  }
)

vonageGuestClient.interceptors.response.use(
  (response) => {
    if (response && response.headers && response.headers['x-session-token']) {
      localStorage.setItem('kateGuestTokenX', response.headers['x-session-token'])
    }
    return response
  },
  async (error) => {
    const originalConfig = error.config

    if (disabledTokenRefreshEndpoints.includes(error?.response?.config?.url)) {
      return Promise.reject(error)
    }

    if (error.response && (error.response.status === 401 || error.response.status === 403) && !originalConfig._retry) {
      const firstName = localStorage.getItem('guestFirstName')
      const lastName = localStorage.getItem('guestLastName')
      const email = localStorage.getItem('guestEmail')
      if (!firstName || !lastName || !email) {
        localStorage.setItem('kateGuestTokenX', '')
        localStorage.setItem('guestFirstName', '')
        localStorage.setItem('guestLastName', '')
        localStorage.setItem('guestEmail', '')
        // window.location.reload()
      } else {
        const newTokenData = await APIClient.getGuestJWT({ firstName: firstName, lastName: lastName, email: email })
        localStorage.setItem('kateGuestTokenX', newTokenData.data.jwtToken)
        localStorage.setItem('guestFirstName', newTokenData.data.firstName)
        localStorage.setItem('guestLastName', newTokenData.data.lastName)
        localStorage.setItem('guestEmail', newTokenData.data.email)
        client.defaults.headers.common['Authorization'] = `Bearer ${newTokenData.data.jwtToken}`
        return vonageGuestClient(originalConfig)
      }
    }

    return Promise.reject(error)
  }
)

export { client, vonageClient, vonageGuestClient }
