import React from 'react'
import styled from 'styled-components'

const HostInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;
  background: rgba(23, 23, 23, 0.05);
  border-radius: 10px;
`

const HostInfoTitle = styled.p`
  color: #171717;
  font-size: 15px;
  margin-top: 2.5px;
  margin-bottom: 7.5px;
`

const HostInfoDescription = styled.p<IProps>`
  color: #1d70b6;
  font-size: 15px;
  margin-top: 2.5px;
  margin-bottom: 2.5px;
`

// #2ECC71
// color: ${(props) => (props.joined ? '#2ECC71' : '#707070')};

interface IProps {
  joined?: boolean
  hostName: string
}

const HostInfo = (props: IProps) => {
  return (
    <HostInfoContainer>
      <HostInfoTitle>{props.hostName?.split(' ')[0]}</HostInfoTitle>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <HostInfoDescription hostName={props.hostName} joined={props.joined}>
          Host of the call
        </HostInfoDescription>

        <p style={{ fontSize: 12, color: '#707070', fontWeight: 600 }}>{props.joined ? 'joined' : 'not joined yet'}</p>
      </div>
    </HostInfoContainer>
  )
}

export default HostInfo
