import React from 'react'
import styled from 'styled-components'
import Icon from '../../../Icon'
import { colors } from '../../../../assets/colors'

const StyledButton = styled.button`
  background-color: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
  opacity: 0.95;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

interface IProps {
  onClick: (e: any) => void
  name: string
  isConfidential?: boolean
  width?: string
}

const Button = ({ onClick, name, isConfidential, width = '50' }: IProps) => (
  <StyledButton onClick={onClick}>
    <Icon name={name} width={width} fill={isConfidential ? colors.mediumGrey2 : colors.darkBlue} />
  </StyledButton>
)

export default Button
