import React from 'react'

interface UserAccountProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const UserAccount = ({
  style = {},
  fill = '#000',
  width = '30px',
  className = '',
  viewBox = '0 0 30 30'
}: UserAccountProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
      M 9.5 3 C 6.012 3 4 5.721 4 9 C 4 10.104 4.5273438 11.214844 4.5273438 11.214844 C 4.3153438 11.336844 3.9666875 11.724109 4.0546875 12.412109 C 4.2186875 13.695109 4.7749062 14.019875 5.1289062 14.046875 C 5.2639062 15.244875 6.55 16.777 7 17 L 7 19 C 6 22 0 20 0 27 L 9 27 C 9 22.824 10.863594 20.989188 12.683594 19.992188 C 12.381594 19.731188 12.139 19.417 12 19 L 12 17 C 12.383 16.811 13.363375 15.676469 13.734375 14.605469 C 13.420375 14.107469 13.174312 13.479062 13.070312 12.664062 C 12.984312 11.987063 13.085969 11.399156 13.292969 10.910156 C 13.137969 10.371156 13 9.696 13 9 C 13 7.614 13.286828 6.3441875 13.798828 5.2421875 C 13.336828 4.7781875 12.743 4.5 12 4.5 C 12 4.5 11.626 3 9.5 3 z M 20.5 3.0019531 C 17.012 3.0009531 15 5.721 15 9 C 15 10.104 15.527344 11.214844 15.527344 11.214844 C 15.315344 11.336844 14.966688 11.724109 15.054688 12.412109 C 15.218687 13.695109 15.774906 14.019875 16.128906 14.046875 C 16.263906 15.244875 17.55 16.777 18 17 L 18 19 C 17 22 11 20 11 27 L 30 27 C 30 20 24 22 23 19 L 23 17 C 23.45 16.777 24.736094 15.245828 24.871094 14.048828 C 25.225094 14.021828 25.781312 13.695109 25.945312 12.412109 C 26.033313 11.723109 25.684656 11.336844 25.472656 11.214844 C 25.472656 11.214844 26 10.212953 26 9.0019531 C 26 6.5739531 25.047 4.5019531 23 4.5019531 C 23 4.5019531 22.626 3.0019531 20.5 3.0019531 z
      "
    />
  </svg>
)

export default UserAccount
