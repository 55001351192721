import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { TagType } from '../../../../types'
import TagsView from './TagsView'
import { useAutoPlayStore } from '../../../../store/autoPlay'
import { shallow } from 'zustand/shallow'
import { useGetTags } from '../../../../hooks/queries/useGetTags'

const TagsPage: React.FC = () => {
  const history = useHistory()
  const { data: tagsData, isLoading } = useGetTags()

  const [personalTags, setPersonalTags] = useState<TagType[]>([])
  const [smartViews, setSmartViews] = useState<TagType[]>([])
  const [tagsFromSender, setTagsFromSender] = useState<TagType[]>([])

  const nextUnreadMessageId = useAutoPlayStore((state) => state.nextUnreadMessageId, shallow)

  const onOptionsClick = useCallback(() => {
    history.push('/options')
  }, [nextUnreadMessageId])

  useEffect(() => {
    if (!tagsData) return
    setPersonalTags(tagsData.tags)
    setSmartViews(tagsData.views.filter((item: TagType) => !item.categories || !item.categories.sender))
    setTagsFromSender(tagsData.views.filter((item: TagType) => item.categories && item.categories.sender))
  }, [tagsData])

  return (
    <TagsView
      personalTags={personalTags}
      smartViews={smartViews}
      tagsFromSender={tagsFromSender}
      onOptionsClick={onOptionsClick}
      onTagItemClick={(name: string) => history.push(`/tags/messages`, { name })}
      loading={isLoading}
    />
  )
}

export default TagsPage
