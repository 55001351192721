import React, { SVGProps } from 'react'

const Name = (props: SVGProps<SVGSVGElement>) => (
  <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 0.125C4.59033 0.125 2.625 2.09033 2.625 4.5C2.625 6.00635 3.39404 7.34424 4.55859 8.13281C2.32959 9.08984 0.75 11.3018 0.75 13.875H2C2 12.0684 2.95459 10.4961 4.38281 9.61719C4.80273 10.6475 5.82568 11.375 7 11.375C8.17432 11.375 9.19727 10.6475 9.61719 9.61719C11.0454 10.4961 12 12.0684 12 13.875H13.25C13.25 11.3018 11.6704 9.08984 9.44141 8.13281C10.606 7.34424 11.375 6.00635 11.375 4.5C11.375 2.09033 9.40967 0.125 7 0.125ZM7 1.375C8.7334 1.375 10.125 2.7666 10.125 4.5C10.125 6.2334 8.7334 7.625 7 7.625C5.2666 7.625 3.875 6.2334 3.875 4.5C3.875 2.7666 5.2666 1.375 7 1.375ZM7 8.875C7.5127 8.875 8.00098 8.94824 8.46484 9.08984C8.24756 9.69287 7.68115 10.125 7 10.125C6.31885 10.125 5.75244 9.69287 5.53516 9.08984C5.99902 8.94824 6.48731 8.875 7 8.875Z"
      fill="#1D70B6"
    />
  </svg>
)

export default Name
