import { useCallback } from 'react'
import { showAlertModal } from '../../store/modals'
import APIClient from '../../apiClient'
import { useParams } from 'react-router-dom'
import { useAudioMessageActions, useAudioMessageStore } from '../../store/audioMessage'
import { useHistory } from 'react-router'
import { useQueryClient } from '@tanstack/react-query'
import { MessageType } from '../../types'
import { DESKTOP_APP_WIDTH, isDesktop } from '../../constants'
import useIsMobile from '../../hooks/useIsMobile'

export const useMessageList = (message: MessageType) => {
  const { type } = useParams<{ type?: string }>()
  const isMobile = useIsMobile()

  const history = useHistory<{
    newMessage?: boolean
    forwardId?: string
    recipients?: { label: string; id: string }[]
    source?: string
    source_kate_account?: { id?: string; first_name?: string; last_name?: string; link_account_type?: string }
  }>()

  const queryClient = useQueryClient()

  const { setAudioPlaying, setAudioMessageId, setCurrentMessageId } = useAudioMessageActions()
  const { playing, currentMessageId } = useAudioMessageStore((state) => ({
    playing: state.playing,
    currentMessageId: state.currentMessageId
  }))

  const selectMessageAndLoadAudio = async (messageUniqueId: string, confidential = false) => {
    try {
      setCurrentMessageId(messageUniqueId)
      if (isDesktop) {
        return history.push(`/messages/${type}?id=${messageUniqueId}${confidential ? '&confidential=true' : ''}`)
      }

      if (currentMessageId !== messageUniqueId) {
        setAudioPlaying(false)
        await queryClient.prefetchQuery({
          queryKey: ['message', messageUniqueId, confidential],
          queryFn: async () => {
            const { data } = await APIClient.messagesItem(messageUniqueId, confidential)
            return data
          }
        })
      }

      // click on current
      if (currentMessageId === messageUniqueId) {
        setAudioPlaying(!playing)
        setAudioMessageId(messageUniqueId)
      }

      if (!message.new && !message.urgent) return

      setAudioMessageId(messageUniqueId)
      setAudioPlaying(true)
    } catch (e: any) {
      console.log('play audio error: ', e)
      if (!e.toString().includes('401') && !e?.name?.toLowerCase().includes('axios')) {
        showAlertModal(`Something's wrong. ${e.toString()}`)
      }
    }
  }

  const deleteMessage = useCallback(async (messageUniqueId: string) => {
    try {
      await APIClient.moveMessagesItem({ id: messageUniqueId, toFolder: 'deleted' })
      await queryClient.invalidateQueries(['messages', type])
      await queryClient.invalidateQueries(['messageCounter'])
      await queryClient.invalidateQueries(['messages', 'deleted'])
    } catch (e) {
      console.log('error on deleteMessage: ', e)
    }
  }, [])

  const replyMessage = useCallback(
    (messageId: string) => {
      if (message.uniqueId !== messageId) return
      if (message && message.contact) {
        const recipients = [
          {
            id: message.contact?.id,
            label: `${message.contact?.first_name} ${message.contact?.last_name}`
          }
        ]

        history.push(`${history.location.pathname}`, { ...history.location.state, newMessage: true, recipients })
      } else {
        history.push(`${history.location.pathname}`, { ...history.location.state, newMessage: true })
      }
    },
    [message]
  )

  const forwardMessage = useCallback((messageUniqueId: string) => {
    history.push(`${history.location.pathname}`, { newMessage: true, forwardId: messageUniqueId })
  }, [])

  const messageInfoClick = useCallback((messageId: string, confidential = false) => {
    setAudioPlaying(false)
    history.push(`/message/${messageId}${confidential ? '?confidential=true' : ''}`)
  }, [])

  const addContact = useCallback(
    (messageId: string) => {
      if (!messageId || messageId !== message.uniqueId) return
      if (message.source_is_kate && message.source_kate_account) {
        return history.push(isMobile ? '/contactCreate' : '/contacts?action=create', {
          source: message.source,
          source_kate_account: message.source_kate_account
        })
      }
      history.push(isMobile ? '/contactCreate' : '/contacts?action=create', {
        source: message.source
      })
    },
    [message, isMobile]
  )

  return {
    selectMessageAndLoadAudio,
    addContact,
    deleteMessage,
    replyMessage,
    forwardMessage,
    messageInfoClick
  }
}
