import styled from 'styled-components'
import { colors } from '../../../../../assets/colors'

const MeetingUrl = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.veryDark};
`

export default MeetingUrl
