import React, { FC } from 'react'
import { colors } from '../../../../assets/colors'
import styled from 'styled-components'
import { IChildren } from '../../../../types'

const ListItemContainer = styled.div`
  color: ${colors.mediumGrey};
  background: ${colors.silver};
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  height: 66px;

  &:hover {
    background: #d7d7d7;
  }

  &:active {
    opacity: 0.8;
  }
`
const ListItemIconContainer = styled.div`
  padding-right: 20px;
`

interface IProps extends IChildren {
  onClick: () => void
}

const DropdownListItem = ({ children, ...rest }: IProps) => {
  return <ListItemContainer {...rest}>{children}</ListItemContainer>
}

DropdownListItem.ListIconContainer = ListItemIconContainer

export default DropdownListItem
