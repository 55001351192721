import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { InlineButton } from '../Button/InlineButton'

const StyledButton = styled(InlineButton)`
  height: 100%;
  width: fit-content;
  padding: 0;
  margin: 0 5px;
`

type TextButtonProps = {
  children?: ReactNode
  onClick: () => void
  style?: object
}

const IconButton = ({ children, ...props }: TextButtonProps) => (
  <StyledButton onClick={props.onClick} {...props}>
    {children}
  </StyledButton>
)

export default IconButton
