import React from 'react'
import NoContacts from '../../../components/Icon/NoContacts'
import styled from 'styled-components'
import TextNote from '../components/TextNote'
import NewActionButton from '../components/NewActionButton'
import { useHistory } from 'react-router-dom'

const NoContactsTextNote = styled(TextNote)`
  text-align: center;
`

const NewContactButton = styled(NewActionButton)`
  margin-top: 1rem;
  padding: 12px 10px;
  height: 36px;
`
const NoContactsYet = ({ buttonLabel = 'New Contact', text = 'You don’t have any contacts yet' }) => {
  const history = useHistory()
  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <NoContacts />
        <NoContactsTextNote>{text}</NoContactsTextNote>
        <NewContactButton onClick={() => history.push(`${window.location.pathname}?action=create`)}>
          <span style={{ fontSize: '22px', marginRight: '5px' }}>+ </span>
          {buttonLabel}
        </NewContactButton>
      </div>
    </>
  )
}

export default NoContactsYet
