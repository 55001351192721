import { useFormik } from 'formik'
import APIClient from '../../../apiClient'
import * as Yup from 'yup'
import { useHistory } from 'react-router'
import { useState } from 'react'
import * as Sentry from '@sentry/react'
import { showAlertModal, useModalActions } from '../../../store/modals'

export type EventType = {
  startTime: string
  endTime: string
  description: string
  title: string
  participants: any[]
}

const CreateMeetingSchema = Yup.object().shape({
  startTime: Yup.date().required('Start time is required'),
  endTime: Yup.date()
    .min(Yup.ref('startTime'), () => `End time needs to be before start time`)
    .required('End time is required'),
  description: Yup.string().nullable(),
  title: Yup.string().nullable().required('Title is required!'),
  participants: Yup.array()
    .min(1, 'There should be at least one participant')
    .required('There should be at least one participant')
})

export const useMeetingRoom = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  const { handleSubmit, handleChange, errors, values, setValues, resetForm, touched } = useFormik({
    initialValues: { startTime: '', endTime: '', description: '', title: '', participants: [] },
    validationSchema: CreateMeetingSchema,
    onSubmit: async (values: EventType) => {
      try {
        setIsLoading(true)
        const eventMeeting = await APIClient.createEventMeeting({
          startTime: values.startTime,
          endTime: values.endTime,
          attendees: values.participants,
          description: values.description || '-',
          title: values.title || 'New meeting'
        })
        if (eventMeeting) history.push('/video-conferencing')
      } catch (e) {
        if (e.response?.status === 402) {
          showAlertModal(`Your account has reached a limit of free video conferencing minutes for this month.`)
        } else {
          showAlertModal('Something went wrong. Please try again.')
        }

        console.log('Error on creating event: ', e)
        Sentry.captureException(e)
      } finally {
        setIsLoading(false)
      }
    }
  })

  return {
    handleSubmit,
    handleChange,
    values,
    errors,
    setValues,
    resetForm,
    touched,
    isLoading
  }
}
