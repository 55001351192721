import React from 'react'
import MeetingCard from './MeetingCard'
import { MonthText } from './components/MonthText'
import { PriceText } from './components/PriceText'

interface IProps {
  children: React.ReactNode
}

const ReportRoom = (props: IProps) => {
  return <div>{props.children}</div>
}

ReportRoom.MeetingCard = MeetingCard
ReportRoom.MonthText = MonthText
ReportRoom.PriceText = PriceText

export default ReportRoom
