import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
`

interface Props {
  children?: JSX.Element | string
}

const From: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>
}

export default From
