import React, { useRef } from 'react'
import styled from 'styled-components'
import { colors } from '../../../assets/colors'

const PlaybackBarContainer = styled.div`
  //user-select: none;
  height: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const PlaybackBarTime = styled.div`
  color: ${colors.mediumGrey};
  font-size: 14px;
  text-align: center;
`

const PlaybackBarProgress = styled.div<{ isConfidential?: boolean }>`
  flex: 1;
  width: 100%;
  height: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background: ${({ isConfidential }) => (isConfidential ? colors.lightGrey : colors.lightBlue)};
  }
`

const PlaybackBarProgressKnob = styled.div<{ isConfidential?: boolean }>`
  position: absolute;
  top: 0;
  height: 5px;
  min-width: 10px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: 3px;
    width: 100%;
    background-color: ${({ isConfidential }) => (isConfidential ? colors.mediumGrey2 : colors.darkBlue)};
  }

  &::after {
    content: '';
    position: absolute;
    top: -6px;
    right: -6px;
    height: 9px;
    width: 9px;
    border: 3px solid ${colors.veryLightBlue};
    background-color: ${({ isConfidential }) => (isConfidential ? colors.mediumGrey2 : colors.darkBlue)};
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
    border-radius: 50%;
  }
`

const PlaybackDurationContainer = styled.div`
  user-select: none;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

interface IMessagePlaybackBar {
  duration: number
  curTime: number
  playProgress: number
  onTimeUpdate: (time: number | undefined) => void
  isConfidential?: boolean
}

export const MessageShowPlaybackBar: React.FC<IMessagePlaybackBar> = (props: IMessagePlaybackBar) => {
  const progressBar = useRef<HTMLDivElement>(null)
  const { duration, curTime, onTimeUpdate, playProgress } = props
  const isConfidential = props.isConfidential || false
  function formatDuration(duration: number) {
    const minutes = Math.floor(duration / 60)
    const seconds = Math.floor(duration - minutes * 60)
    const minutesStr = minutes ? (minutes < 10 ? `0${minutes}` : minutes) : '00'
    const secondsStr = seconds ? (seconds < 10 ? `0${seconds}` : seconds) : '00'
    return `${minutesStr}:${secondsStr}`
  }

  const calcClickedTime = (e: MouseEvent | TouchEvent) => {
    let clickPositionInPage
    if ('pageX' in e) {
      clickPositionInPage = e.pageX
    } else {
      clickPositionInPage = e.changedTouches[e.changedTouches.length - 1].pageX
    }

    const bar: HTMLDivElement | null = progressBar.current
    if (bar) {
      const barStart = bar.getBoundingClientRect().left + window.scrollX
      const barWidth = bar.offsetWidth
      const clickPositionInBar = clickPositionInPage - barStart >= 0 ? clickPositionInPage - barStart : 0
      const timePerPixel = duration / barWidth
      return timePerPixel * clickPositionInBar
    }
  }

  function handleTimeDrag(e: MouseEvent | TouchEvent | any) {
    onTimeUpdate(calcClickedTime(e))

    const updateTimeOnMove = (eMove: MouseEvent | TouchEvent) => {
      onTimeUpdate(calcClickedTime(eMove))
    }
    if (e.type === 'mousedown') {
      document.addEventListener('mousemove', updateTimeOnMove)

      document.addEventListener('mouseup', () => {
        document.removeEventListener('mousemove', updateTimeOnMove)
      })
    } else {
      document.addEventListener('touchmove', updateTimeOnMove)

      document.addEventListener('touchend', () => {
        document.removeEventListener('touchmove', updateTimeOnMove)
      })
    }
  }

  return (
    <PlaybackBarContainer
      onMouseDown={(e) => handleTimeDrag(e)}
      onTouchStart={(e) => handleTimeDrag(e)}
      className="bar"
    >
      <PlaybackBarProgress
        ref={progressBar}
        isConfidential={isConfidential}
        className="bar__progress"
        style={{
          background: `linear-gradient(to right, ${colors.blue} ${playProgress}%, 90deg, #000, #000 35%, transparent 35%, transparent 100%)`
        }}
        // onMouseDown={e => handleTimeDrag(e)}
        // onTouchStart={e => handleTimeDrag(e)}
      >
        <PlaybackBarProgressKnob
          isConfidential={isConfidential}
          className="bar__progress__knob"
          style={{ width: `${playProgress - 2}%` }}
        />
      </PlaybackBarProgress>
      <PlaybackDurationContainer>
        <PlaybackBarTime className="bar__time">{formatDuration(curTime)}</PlaybackBarTime>
        <PlaybackBarTime className="bar__time">{formatDuration(duration)}</PlaybackBarTime>
      </PlaybackDurationContainer>
    </PlaybackBarContainer>
  )
}
