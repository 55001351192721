import React from 'react'
import useIsMobile from '../../../../../../hooks/useIsMobile'
import OptionsNavigation from '../Blocks/OptionsNavigation'
import { OptionsWrapper } from '../Styled'
import { RightPanel } from '../../../../../../layout/Layout'

const OptionsLayout = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useIsMobile()

  return (
    <>
      <OptionsWrapper>
        <OptionsNavigation />
        <RightPanel style={{ display: 'block', position: 'relative', padding: '30px' }}>{children}</RightPanel>
      </OptionsWrapper>
    </>
  )
}

export default OptionsLayout
