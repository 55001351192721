import React, { useEffect } from 'react'
import { useAudioPlayingContext } from '../../../../mobile/pages/NewMessage/provider/AudioPlayingProvider'
import { useNewMessageActions } from '../../../../mobile/pages/NewMessage/store/NewMessageStore'
import { IReplyPage } from './index'
import SendMessage from '../SendMessage'

const Reply = ({ loading, label, contactId }: IReplyPage) => {
  const { setRecipients, onDiscardAudio } = useNewMessageActions()
  const { setAudio } = useAudioPlayingContext()

  useEffect(() => {
    onDiscardAudio()
    setAudio(null)
  }, [])

  useEffect(() => {
    if (loading) return
    setRecipients([{ id: contactId, label }])
  }, [loading, label, contactId])

  return <SendMessage />
}

export default Reply
