import React, { useEffect } from 'react'
import './styles.css'
import { useVideoActions, useVideoStore, VideoStateEnum } from '../../store/video'
import './App.css'
import Waiting from './blocks/WaitingRoom'
import ConferenceWrapper from './blocks/Conference/ConferenceWrapper'

const Video: React.FC = () => {
  const { setVideoData, setRoom } = useVideoActions()
  const videoRoomState = useVideoStore((state) => state.videoRoomState)

  useEffect(() => {
    return () => {
      setVideoData(null)
      setRoom(null)
    }
  }, [])

  return <>{videoRoomState === VideoStateEnum.WaitingRoom ? <Waiting /> : <ConferenceWrapper />}</>
}

export default Video
