import React from 'react'
import styled from 'styled-components'
import WeekDaysSpan from './WeekDaysSpan'

const WeekDays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 5px;
  margin-bottom: 10px;
`

const WeekDaysGrid = () => {
  return (
    <>
      <WeekDays>
        <WeekDaysSpan>sun</WeekDaysSpan>
        <WeekDaysSpan>mon</WeekDaysSpan>
        <WeekDaysSpan>tue</WeekDaysSpan>
        <WeekDaysSpan>wed</WeekDaysSpan>
        <WeekDaysSpan>thu</WeekDaysSpan>
        <WeekDaysSpan>fri</WeekDaysSpan>
        <WeekDaysSpan>sat</WeekDaysSpan>
      </WeekDays>
    </>
  )
}

export default WeekDaysGrid
