import React from 'react'
import Reply from './reply'

export interface IReplyPage {
  label: string
  contactId?: string
  loading: boolean
  contact?: any
}

const ReplyMessage = (props: IReplyPage) => {
  return (
    <>
      <Reply {...props} />
    </>
  )
}

export default ReplyMessage
