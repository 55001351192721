import styled from 'styled-components'

const SwitchContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
`

export default SwitchContainer
