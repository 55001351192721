import APIClient from '../../../apiClient'
import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import { usePopup } from 'react-custom-popup'
import { showAlertModal } from '../../../store/modals'
import { useUser } from '../../../store/user'
import { useGetConferences } from '../../../hooks/queries/useGetConferences'
import { useGetRecentMeetings } from '../../../hooks/queries/useGetRecentMeetings'
import { useQueryClient } from '@tanstack/react-query'

export const useVideoConferencing = () => {
  const user = useUser()
  const queryClient = useQueryClient()
  const accountNumber = user?.account_number

  const { data: scheduledConferences, isLoading: isLoadingConferences } = useGetConferences(accountNumber)
  const { data: recentConference, isLoading: isLoadingRecentMeetings } = useGetRecentMeetings()

  const isLoading = isLoadingConferences || isLoadingRecentMeetings

  const { hideModal } = usePopup()

  const onDelete = async (conference) => {
    try {
      await APIClient.deleteMeetingEvent(conference.meetingId)
    } catch (e) {
      Sentry.captureException(e)
      showAlertModal(`Unable to delete meeting`)
    } finally {
      hideModal()

      queryClient.invalidateQueries(['conferences', accountNumber])
      queryClient.invalidateQueries(['recentMeetings'])
    }
  }

  return {
    scheduledConferences,
    isLoading,
    recentConference,
    onDelete
  }
}
