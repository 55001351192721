import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useFormik } from 'formik'

import { colors } from '../../../../assets/colors'
import { GroupItemType } from '../../../../types'
import Header from '../../../../components/Header'
import { InlineButton } from '../../../../components/Button/InlineButton'
import Icon from '../../../../components/Icon'
import Divider from '../../../../components/Divider'
import RoundedInput from '../../../../components/Input/RoundedInput'
import ChangeViewButton from '../../../../components/Button/ChangeViewButton'
import Form from '../../../../components/Input/Form'
import CaptionText from '../../../../components/Field/CaptionText'
import ContactsList from '../../../../components/blocks/Contact/ContactsList/ContactsList'
import SwipeItem from '../../../../components/blocks/Message/SwipeItem'
import Contact from '../../../../components/blocks/Contact/ContactItem'
import RedButton from '../../../../components/Button/RedButton'
import SearchInput from '../../../../components/Input/SearchInput'
import { Loader } from '../../../../components/Loader/Loader'
import APIClient from '../../../../apiClient'
import { useGroupsQuery } from '../../../../hooks/queries/useGroupsQuery'
import { useContactGroupListQuery } from '../../../../hooks/queries/useContactGroupListQuery'
import { useGetGroupQuery } from '../../../../hooks/queries/useGetGroupQuery'
import { useQueryClient } from '@tanstack/react-query'

const GroupEdit: React.FC = () => {
  const history = useHistory()
  const urlParams = new URLSearchParams(history.location.search)
  const id = urlParams.get('id') || ''
  const search = urlParams.get('filter') || ''

  const [initialMembers, setInitialMembers] = useState<string[]>([])
  const [memberIds, setMemberIds] = useState<string[]>([])
  const [added, setAdded] = useState<{ [id: string]: boolean }>({})
  const [contactsIds, setContactsIds] = useState<string[]>([])
  const [selectMembers, setSelectMembers] = useState<boolean>(false)
  const [touched, setTouched] = useState<boolean>(false)

  const { data: groups } = useGroupsQuery()
  const { data: group, isLoading: isLoadingGroup } = useGetGroupQuery(id)
  const { data: contacts } = useContactGroupListQuery(search)

  const groupData = groups?.find((group) => group.id === id)

  useEffect(() => {
    if (group) {
      setMemberIds(group.map((member) => member.id))
      setInitialMembers(group.map((member) => member.id))
    }
  }, [group, isLoadingGroup])

  const queryClient = useQueryClient()

  const formik = useFormik({
    initialValues: groupData || { id: '', name: '' },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const valid = validate(values)
      if (valid) {
        try {
          const updateGroupResponse = await APIClient.contactGroupUpdate({
            id: id,
            req: {
              name: values.name,
              contacts: {
                add: memberIds
                  .filter((memId) => !initialMembers.find((memberId) => memberId === memId))
                  .map((memId) => {
                    return { id: memId, phone_numbers: ['kate_number'] }
                  }),
                remove: initialMembers.filter((memId) => !memberIds.find((memberId) => memberId === memId))
              }
            }
          })
          if (updateGroupResponse.status >= 300)
            throw new Error(`group update error ${updateGroupResponse.status}, ${updateGroupResponse.statusText}`)

          await queryClient.invalidateQueries(['contacts'])
          await queryClient.invalidateQueries(['groups'])

          history.push('/groups')
        } catch (e) {
          console.log('error on group update ===', e)
          window.alert('Ups, group cannot be updated')
        }
      }
    }
  })

  const groupDeleteFunction = useCallback(async () => {
    try {
      const conf: boolean = window.confirm(
        `This action will permanently delete group refugees. Please double check this is the action you want.`
      )
      if (conf) {
        const deleteResponse = await APIClient.contactGroupDelete(id)
        if (deleteResponse.status >= 300)
          throw new Error(`status = ${deleteResponse.status}, ${deleteResponse.statusText}`)

        await queryClient.invalidateQueries(['groups'])
        await queryClient.invalidateQueries(['contacts'])
        history.replace('/groups')
      }
    } catch (e) {
      console.log('error on group delete ===', e)
      window.alert('Ups, group cannot be deleted. Please reload the page and try again.')
    }
  }, [])

  const memberDeleteFunction = (memberId: string) => {
    setContactsIds([...contactsIds, memberId])
    setMemberIds(memberIds.filter((memId) => memId !== memberId))
  }

  const { values, handleChange, handleSubmit, handleReset, isSubmitting } = formik

  if (selectMembers) {
    return (
      <>
        <Header>
          <Header.SideButtons>
            <InlineButton onClick={() => setSelectMembers(false)}>Back</InlineButton>
          </Header.SideButtons>
          <Header.Title>
            <span>Select contacts</span>
          </Header.Title>
          <Header.EndButtons>
            <InlineButton
              onClick={async () => {
                setMemberIds([...memberIds, ...Object.keys(added).filter((addId) => added[addId])])
                setContactsIds([...contactsIds.filter((contactId) => !added[contactId])])
                setSelectMembers(false)
                setAdded({})
              }}
            >
              Done
            </InlineButton>
          </Header.EndButtons>
        </Header>
        <SearchInput
          onFocus={() => setTouched(true)}
          onClick={() => {
            history.replace(`groupEdit?id=${id}`)
            setTouched(false)
          }}
          onChange={(e: { target: { value: string } }) =>
            history.replace(`groupEdit?id=${id}&filter=${e.target.value}`)
          }
          showCancel={touched}
          value={search}
        />
        <ContactsList>
          <ContactsList.ItemsList>
            {contacts?.length ? (
              contacts.map((contact) => {
                return (
                  <div key={contact.id}>
                    <Contact border={false} onClick={() => setAdded({ ...added, [contact.id]: !added[contact.id] })}>
                      <Contact.Avatar
                        initials={`${contact?.first_name?.[0]}${contact?.last_name?.[0]}`}
                        src={contact?.photo_url}
                      />
                      <Contact.Info>
                        <Contact.ContactName>{`${contact.first_name} ${contact.last_name}`}</Contact.ContactName>
                        <Contact.KateNumber>{contact?.kate_number}</Contact.KateNumber>
                      </Contact.Info>
                      {added[contact.id] ? <Icon name={'checkmark'} fill={colors.darkBlue} width={'20px'} /> : null}
                    </Contact>
                    <Divider marginLeft={'60px'} />
                  </div>
                )
              })
            ) : (
              <ContactsList.Header>No Options</ContactsList.Header>
            )}
          </ContactsList.ItemsList>
        </ContactsList>
      </>
    )
  }

  return (
    <>
      <Header>
        <Header.SideButtons>
          <InlineButton onClick={() => history.push('/groups')}>Back</InlineButton>
        </Header.SideButtons>
        <Header.Title>
          <span>Edit Group</span>
        </Header.Title>
        <Header.EndButtons>
          {isSubmitting ? (
            <Loader width={'20px'} height={'20px'} fill={colors.white} style={{ left: '90%' }} />
          ) : (
            <InlineButton onClick={handleSubmit}>Save</InlineButton>
          )}
        </Header.EndButtons>
      </Header>
      <Form
        autoComplete="off"
        onReset={handleReset}
        style={{
          backgroundColor: colors.silver,
          width: '100%',
          height: '100%'
        }}
      >
        <RoundedInput
          placeholder={'add group name'}
          value={values.name}
          onChange={handleChange}
          padding={'30px 10px 10px 0'}
          name={'name'}
        />
        <Divider marginLeft={'10%'} />
        <ChangeViewButton style={{ width: '90%', marginLeft: '10%' }}>
          <Icon name={'add'} width={'30px'} fill={colors.mediumGrey} />
          <ChangeViewButton.Button onClick={() => setSelectMembers(true)}>Add group members</ChangeViewButton.Button>
        </ChangeViewButton>
        <CaptionText>Swipe to Remove</CaptionText>
        <ContactsList>
          {isLoadingGroup ? (
            <div style={{ width: '100%', height: '100%', backgroundColor: colors.silver, paddingTop: '20px' }}>
              <Loader width={'20px'} height={'20px'} fill={colors.lightGrey} />
            </div>
          ) : (
            <ContactsList.ItemsList>
              {memberIds
                ? memberIds.map((memberId) => {
                    const member = contacts?.find((contact) => contact.id === memberId)
                    if (!member) return null
                    return (
                      <SwipeItem
                        key={memberId}
                        autoClose
                        right={[
                          {
                            text: 'delete',
                            onPress: () => memberDeleteFunction(memberId),
                            style: { backgroundColor: colors.red, color: colors.white, overflow: 'hidden' }
                          }
                        ]}
                        onOpen={() => console.log('open')}
                        onClose={() => console.log('close')}
                      >
                        <Contact>
                          <Contact.Avatar
                            initials={`${member?.first_name?.[0]}${member?.last_name?.[0]}`}
                            src={member?.photo_url}
                          />
                          <Contact.Info>
                            <Contact.ContactName>{`${member?.first_name} ${member?.last_name}`}</Contact.ContactName>
                            <Contact.KateNumber>{member?.kate_number}</Contact.KateNumber>
                          </Contact.Info>
                        </Contact>
                      </SwipeItem>
                    )
                  })
                : null}
            </ContactsList.ItemsList>
          )}
        </ContactsList>
      </Form>
      <RedButton onClick={groupDeleteFunction}>Delete Group</RedButton>
    </>
  )
}

const validate = (values: GroupItemType) => {
  const errors = []
  if (!values.name) {
    errors.push('Name')
  }

  const errorMessage = `Please enter ${errors.join(', ')}`
  if (Object.keys(errors).length) window.alert(errorMessage)
  return !Object.keys(errors).length
}

export default GroupEdit
