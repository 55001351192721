import APIClient from '../apiClient'
import { client } from '../client'
import * as Sentry from '@sentry/react'
import { useUserStore } from '../store/user'
import { queryClient } from '../index'
import { accountLockService } from '../services/AccountLockService'

// async function deregisterOneSignal() {
//   if (!process.env.REACT_APP_ONESIGNAL_APP_ID) return false
//   // const OneSignal = window.OneSignal
//   // if (!OneSignal || !OneSignal.initialized) return false
//
//   const userId = await OneSignal.getUserId()
//   if (!userId) return
//
//   // Pwa deregister from api
//   await APIClient.pwaDeregister(userId)
//   OneSignal.removeExternalUserId()
// }

export function cleanLocalStorage() {
  const kateTokenX = localStorage.getItem('kateTokenX')
  const kateTerms = localStorage.getItem('kateTerms')
  const userStorage = localStorage.getItem('user-storage')
  const chatUrl = localStorage.getItem('chatUrl')
  const awsAccessTokenId = localStorage.getItem('awsAccessTokenId')
  const messagesAmountStorage = localStorage.getItem('messages-storage')

  if (kateTerms) localStorage.removeItem('kateTerms')
  if (kateTokenX) localStorage.removeItem('kateTokenX')
  if (userStorage) localStorage.removeItem('user-storage')
  if (messagesAmountStorage) localStorage.removeItem('messages-storage')
  if (chatUrl) localStorage.removeItem('chatUrl')
  if (awsAccessTokenId) localStorage.removeItem('awsAccessTokenId')
  accountLockService.clearLockReason()

  client.defaults.headers.common['Authorization'] = ''
}

export async function logoutUser() {
  Sentry.setUser(null)
  const { setAuthenticated, clearUser } = useUserStore.getState().actions

  try {
    queryClient.clear()
    cleanLocalStorage()
    await APIClient.logout()
    setAuthenticated(false)
    clearUser()
  } catch (e) {
    Sentry.captureException(e, {
      tags: {
        page: 'Logout - on logout'
      }
    })
    console.log('Error on user logout: ', e)
  }
}
