import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'
import { colors } from '../../../../assets/colors'
import Header from '../../../../components/Header'
import { InlineButton } from '../../../../components/Button/InlineButton'
import Field from '../../../../components/Field'
import Icon from '../../../../components/Icon'
import Divider from '../../../../components/Divider'
import ContactShowHeader from '../../../../components/blocks/Contact/ContactShowHeader'
import styled from 'styled-components'
import { Loader, LoaderContainer } from '../../../../components/Loader/Loader'
import { useContactsActions, useGetContactStore } from '../../../../store/contacts'
import { useGetContact } from '../../../../hooks/queries/useGetContact'
import { showAlertModal } from '../../../../store/modals'

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.silver};
`

const StyledLink = styled.a`
  cursor: pointer;
  opacity: 0.95;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

const ContactShowPage: React.FC = () => {
  const history = useHistory()

  const urlParams = new URLSearchParams(history.location.search)
  const id = urlParams.get('id') || ''
  const { data: contactApi } = useGetContact(id)
  const contact = useGetContactStore()

  // Set contact to store to omit loading state
  const { setContact } = useContactsActions()

  const data = contactApi || contact

  const sendMessage = useCallback(() => {
    const recipients = [
      {
        id: id,
        label: `${data?.first_name} ${data?.last_name}`
      }
    ]
    history.push(`${history.location.pathname}?id=${id}`, { newMessage: true, recipients })
  }, [])

  useEffect(() => {
    return () => {
      setContact(null)
    }
  })

  useEffect(() => {
    if (!id) {
      showAlertModal('Contact not found')
      history.push('/contacts', { newMessage: false, recipients: [] })
    }
  }, [id])

  return (
    <>
      <Header>
        <Header.SideButtons>
          <InlineButton
            onClick={() => {
              history.push('/contacts', { newMessage: false, recipients: [] })
            }}
          >
            Back
          </InlineButton>
        </Header.SideButtons>
        <Header.EndButtons>
          <InlineButton
            onClick={() => {
              setContact(data)
              history.push(`/contactEdit?id=${data.id}`)
            }}
          >
            Edit
          </InlineButton>
        </Header.EndButtons>
      </Header>
      <Background>
        {!data ? (
          <LoaderContainer>
            <Loader width={'20px'} height={'20px'} fill={colors.lightGrey} />
          </LoaderContainer>
        ) : (
          <>
            <ContactShowHeader>
              <ContactShowHeader.Avatar
                initials={
                  `${data?.first_name && data?.first_name?.[0]}${data?.last_name && data?.last_name?.[0]}` || '#'
                }
                src={data?.photo_url}
              />
              <ContactShowHeader.Title>{`${data?.first_name} ${data?.last_name}`}</ContactShowHeader.Title>
            </ContactShowHeader>
            {data?.kate_number && (
              <Field>
                <Field.InfoContainer onClick={sendMessage}>
                  <Field.Label>CommuniKate</Field.Label>
                  <Field.Content>{data?.kate_number}</Field.Content>
                </Field.InfoContainer>
                <Field.Actions>
                  <StyledLink
                    href={`tel:+1${data?.kate_number.replace(/\D/g, '')}`}
                    style={{ padding: 0, textDecoration: 0, margin: 0, outline: 0 }}
                  >
                    <Icon name={'phone'} fill={colors.mediumGrey} width={'30px'} />
                  </StyledLink>
                  <StyledLink onClick={sendMessage}>
                    <Icon name={'microphone'} fill={colors.mediumGrey} width={'30px'} />
                  </StyledLink>
                </Field.Actions>
              </Field>
            )}
            {data.mobile_phone ? (
              <>
                <Divider />
                <Field>
                  <Field.InfoContainer>
                    <Field.Label>mobile</Field.Label>
                    <Field.Content>{data.mobile_phone}</Field.Content>
                  </Field.InfoContainer>
                  <Field.Actions>
                    <StyledLink
                      href={`tel:+1${data.mobile_phone.replace(/\D/g, '')}`}
                      style={{ padding: 0, textDecoration: 0, margin: 0, outline: 0 }}
                    >
                      <Icon name={'phone'} fill={colors.mediumGrey} width={'30px'} />
                    </StyledLink>
                  </Field.Actions>
                </Field>
              </>
            ) : null}
            {data.email && (
              <>
                <Divider />
                <Field>
                  <Field.InfoContainer>
                    <Field.Label>personal</Field.Label>
                    <Field.Content>{data?.email}</Field.Content>
                  </Field.InfoContainer>
                  <Field.Actions>
                    <StyledLink
                      href={`mailto:${data?.email}`}
                      style={{ padding: 0, textDecoration: 0, margin: 0, outline: 0 }}
                    >
                      <Icon name={'letter'} fill={colors.mediumGrey} width={'30px'} />
                    </StyledLink>
                  </Field.Actions>
                </Field>
              </>
            )}
            {data?.email2 && (
              <>
                <Divider />
                <Field>
                  <Field.InfoContainer>
                    <Field.Label>work</Field.Label>
                    <Field.Content>{data?.email2}</Field.Content>
                  </Field.InfoContainer>
                  <Field.Actions>
                    <a href={`mailto:${data?.email2}`} style={{ padding: 0, textDecoration: 0, margin: 0, outline: 0 }}>
                      <Icon name={'letter'} fill={colors.mediumGrey} width={'30px'} />
                    </a>
                  </Field.Actions>
                </Field>
              </>
            )}
            <Field.LongTextField label={'Notes'} text={data?.extra_info} />
          </>
        )}
      </Background>
    </>
  )
}

export default ContactShowPage
