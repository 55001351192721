import styled from 'styled-components'

export const ReportRectangleContainer = styled.div`
  flex-direction: row;
  display: flex;
  flex-flow: wrap;
  width: 100%;
  margin-bottom: 40px;
  justify-content: flex-start;
  & > div:nth-child(4n) {
    margin-right: 0px;
  }
`
