import styled from 'styled-components'
import React from 'react'

const TabButtonsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  @media (min-width: 1024px) {
    justify-content: space-between;
    width: 100%;
  }
`

export default TabButtonsContainer
