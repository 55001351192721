import styled from 'styled-components'

const Line = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  width: calc(100% - 30px);
  height: 1px;
  background-color: rgba(112, 112, 112, 0.2);
`

export default Line
