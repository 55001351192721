import React, {
  ChangeEvent,
  ReactComponentElement,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useRef,
  useState
} from 'react'
import styled from 'styled-components'
import { getOrientation } from 'get-orientation/browser'
import Cropper from 'react-easy-crop'
import { getRotatedImage } from './rotateImage'
import getCroppedImg from './cropImage'
import NewActionButton from '../../components/NewActionButton'
import { Box, Slider } from '@mui/material'

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90
}

const CropContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ccc;
  z-index: 999;
  display: flex;
  flex-direction: column;
`

const CropperContainer = styled.div`
  position: relative;
  flex: 1;
  background: #000;
`

const CropActionsContainer = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
`

const ImageCropButton = styled(NewActionButton)`
  border-radius: 0;
  margin-right: 1px;
`

interface ICropImage {
  saveCroppedImage: (imageFile: any) => void
  element: ReactComponentElement<any>
  children: ReactNode
}

export const ImageCropDesktop = ({ saveCroppedImage, element, children, ...props }: ICropImage) => {
  const photoInputRef = useRef<HTMLInputElement>(null)

  const [src, setSrc] = useState('')
  const [imageRotation, setImageRotation] = useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState()
  const [crop, setCrop] = useState<any>({
    x: 0,
    y: 0
  })
  const aspect = 1
  const [zoom, setZoom] = useState(1)

  const readFile = (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    e.persist()
    if (!e.target.files || !e.target.files.length) return
    const orientation = await getOrientation(e.target.files[0])
    const defaultImageUrl = await readFile(e.target.files[0])
    // @ts-ignore
    const rotation = orientation ? ORIENTATION_TO_ANGLE[orientation] : undefined
    if (rotation) {
      const rotatedImageUrl = await getRotatedImage(defaultImageUrl, rotation)
      return setSrc(rotatedImageUrl)
    }
    if (typeof defaultImageUrl === 'string') setSrc(defaultImageUrl)
  }

  useEffect(() => {
    if (!src) return
    setSrc(src)
    setCrop({ x: 0, y: 0 })
    setZoom(1)
  }, [src])

  const prepareCroppedFile = async () => {
    try {
      return await getCroppedImg(src, croppedAreaPixels, imageRotation)
    } catch (e) {
      console.error(e)
    }
  }

  const cancelImageSelection = (e: SyntheticEvent) => {
    e.stopPropagation()
    closeImageCrop()
  }

  const closeImageCrop = () => {
    setSrc('')
    setImageRotation(0)
    if (photoInputRef.current) photoInputRef.current.blur()
  }

  const rotateImage = (e: SyntheticEvent) => {
    e.preventDefault()
    setImageRotation(imageRotation + 90)
  }

  const onSaveCroppedImage = async (e: SyntheticEvent) => {
    e.preventDefault()
    const file = await prepareCroppedFile()
    if (!file) return null
    if (photoInputRef && photoInputRef.current) photoInputRef.current.blur()
    saveCroppedImage(file)
    closeImageCrop()
  }

  const onImageCropClick = (e: SyntheticEvent) => {
    e.stopPropagation()
    if (photoInputRef && photoInputRef.current) {
      photoInputRef.current.click()
    }
  }

  const onCropChange = (crop: any) => {
    setCrop(crop)
  }

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const onZoomChange = (zoomEvent: any) => {
    setZoom(zoomEvent.target.value)
  }

  return (
    <div>
      <div>
        <input ref={photoInputRef} type="file" accept="image/*" onChange={(event) => onSelectFile(event)} hidden />
      </div>
      <element.type {...element.props} onClick={(e: SyntheticEvent) => onImageCropClick(e)} {...props}>
        {children}
      </element.type>
      {src && crop && (
        <CropContainer>
          {/*<Header style={{ zIndex: 9999 }}>*/}
          {/*  <Header.SideButtons>*/}
          {/*    <InlineButton onClick={(e: SyntheticEvent) => cancelImageSelection(e)}>Cancel</InlineButton>*/}
          {/*  </Header.SideButtons>*/}
          {/*  <Header.Title>*/}
          {/*    <span>CROP</span>*/}
          {/*  </Header.Title>*/}
          {/*  <Header.EndButtons>*/}
          {/*    <InlineButton onClick={(e: SyntheticEvent) => onSaveCroppedImage(e)}>Done</InlineButton>*/}
          {/*  </Header.EndButtons>*/}
          {/*</Header>*/}
          {/*<CropActionsContainer>*/}
          {/*  <NewActionButton style={{ borderRadius: 0 }} onClick={rotateImage}>*/}
          {/*    Rotate*/}
          {/*  </NewActionButton>*/}
          {/*</CropActionsContainer>*/}
          <CropperContainer>
            <Cropper
              image={src}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              rotation={imageRotation}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              // onZoomChange={onZoomChange}
              onRotationChange={setImageRotation}
            />
          </CropperContainer>
          <CropActionsContainer>
            <ImageCropButton onClick={(e: SyntheticEvent) => cancelImageSelection(e)}>Cancel</ImageCropButton>
            <ImageCropButton onClick={(e: SyntheticEvent) => onSaveCroppedImage(e)}>Done</ImageCropButton>
            <ImageCropButton onClick={(e: SyntheticEvent) => rotateImage(e)}>Rotate</ImageCropButton>
          </CropActionsContainer>
          <Box position={'absolute'} bottom={'3%'} left={'10px'} height={'85%'} padding={'10px'} alignSelf={'center'}>
            <Slider
              orientation="vertical"
              step={0.1}
              min={1}
              max={10}
              onChange={onZoomChange}
              defaultValue={1}
              aria-label="Default"
              valueLabelDisplay="auto"
            />
          </Box>
        </CropContainer>
      )}
    </div>
  )
}
