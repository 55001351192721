import React, { FC } from 'react'
import styled from 'styled-components'
import { colors } from '../../../assets/colors'

const ListContainer = styled.div`
  position: absolute;
  top: 110%;
  left: 0;
  min-width: 100%;
  background: ${colors.white};
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(154, 169, 188, 0.1);

  & > div:nth-child(even) {
    background: ${colors.white};
  }
`

interface Props {
  children: JSX.Element | JSX.Element[]
}

const DropdownList: FC<Props> = ({ children }) => {
  return <ListContainer>{children}</ListContainer>
}

export default DropdownList
