import { useEffect, useMemo } from 'react'
import { useAutoPlayActions } from '../../store/autoPlay'
import { useAudioMessageStore } from '../../store/audioMessage'
import { MessageType } from '../../types'

function useNextUnreadMessage(messages: MessageType[]) {
  const { setNextUnreadMessageId } = useAutoPlayActions()
  const audioMessage = useAudioMessageStore((state) => state.messageId)

  // GET UNREAD MESSAGE LIST
  const nextUnreadMessage = messages?.find(
    (msg) => !msg.read_time && msg.new && msg.length && audioMessage !== msg.uniqueId
  )

  useEffect(() => {
    // UPDATE LIST
    setNextUnreadMessageId(nextUnreadMessage?.uniqueId || '')
  }, [nextUnreadMessage])
}

export default useNextUnreadMessage
