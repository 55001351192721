import React from 'react'

interface Reply5Props {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const Reply5 = ({ style = {}, fill = '#000', width = '30px', className = '', viewBox = '0 0 30 30' }: Reply5Props) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
M 15 3 C 11.946149 3 9.1595062 4.1553732 7.0390625 6.0390625 L 5 4 L 5 10 L 11 10 L 8.4550781 7.4550781 C 10.209519 5.9310235 12.488026 5 15 5 C 20.534534 5 25 9.4654664 25 15 C 25 20.534534 20.534534 25 15 25 C 9.4654664 25 5 20.534534 5 15 A 1.0001 1.0001 0 1 0 3 15 C 3 21.615466 8.3845336 27 15 27 C 21.615466 27 27 21.615466 27 15 C 27 8.3845336 21.615466 3 15 3 z M 12.763672 11.537109 L 12.431641 15.664062 L 14.009766 15.664062 C 14.233766 15.308063 14.584031 15.082031 15.082031 15.082031 C 15.736031 15.082031 16.205078 15.552859 16.205078 16.255859 C 16.205078 16.944859 15.741891 17.392578 15.087891 17.392578 C 14.496891 17.392578 14.028313 17.051203 13.945312 16.533203 L 12.318359 16.533203 C 12.343359 17.812203 13.438219 18.775437 15.074219 18.773438 C 16.773219 18.773437 17.896484 17.733937 17.896484 16.210938 C 17.896484 14.838937 16.939563 13.847656 15.601562 13.847656 C 14.878563 13.847656 14.380422 14.131312 14.107422 14.570312 L 14.013672 14.570312 L 14.146484 12.933594 L 17.451172 12.933594 L 17.451172 11.537109 L 12.763672 11.537109 z
"
    />
  </svg>
)

export default Reply5
