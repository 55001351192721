import { useQuery } from '@tanstack/react-query'
import APIClient from '../../apiClient'

export const useGetConferences = (accountNumber) => {
  return useQuery<unknown, unknown, any[]>({
    queryKey: ['conferences', accountNumber],
    queryFn: async () => {
      const res = await APIClient.getConferences(accountNumber)
      return res.data
    },
    enabled: !!accountNumber,
    cacheTime: 1000 * 60 * 15 // 15 minutes
  })
}
