import React from 'react'

interface CarProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const Car = ({ style = {}, fill = '#000', width = '30px', className = '', viewBox = '0 0 30 30' }: CarProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
M 26.207031 12.558594 L 24.148438 6.679688 C 23.585938 5.078125 22.070312 4 20.371094 4 L 9.628906 4 C 7.929688 4 6.414062 5.078125 5.851562 6.679688 L 3.796875 12.558594 C 2.695312 13.28125 2 14.53125 2 15.902344 L 2 23 C 2 24.105469 2.894531 25 4 25 C 5.105469 25 6 24.105469 6 23 L 6 21.738281 C 6 21.738281 11.28125 22 15 22 C 18.71875 22 24 21.738281 24 21.738281 L 24 23 C 24 24.105469 24.894531 25 26 25 C 27.105469 25 28 24.105469 28 23 L 28 15.902344 C 28 14.53125 27.304688 13.28125 26.207031 12.558594 Z M 6.59375 10.613281 L 7.742188 7.339844 C 8.023438 6.535156 8.777344 6 9.628906 6 L 20.371094 6 C 21.222656 6 21.976562 6.535156 22.257812 7.339844 L 23.40625 10.613281 C 23.585938 11.128906 23.15625 11.648438 22.617188 11.558594 C 20.65625 11.242188 18.136719 11 15 11 C 11.863281 11 9.34375 11.242188 7.382812 11.558594 C 6.84375 11.648438 6.414062 11.128906 6.59375 10.613281 Z M 6.5 18 C 5.671875 18 5 17.328125 5 16.5 C 5 15.671875 5.671875 15 6.5 15 C 7.328125 15 8 15.671875 8 16.5 C 8 17.328125 7.328125 18 6.5 18 Z M 18 17 L 12 17 C 11.449219 17 11 16.550781 11 16 C 11 15.449219 11.449219 15 12 15 L 18 15 C 18.550781 15 19 15.449219 19 16 C 19 16.550781 18.550781 17 18 17 Z M 23.5 18 C 22.671875 18 22 17.328125 22 16.5 C 22 15.671875 22.671875 15 23.5 15 C 24.328125 15 25 15.671875 25 16.5 C 25 17.328125 24.328125 18 23.5 18 Z M 23.5 18
"
    />
  </svg>
)

export default Car
