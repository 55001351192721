import React, { memo, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import debounce from 'lodash.debounce'
import format from 'date-fns/format'
import Header from '../../../../components/Header'
import { InlineButton } from '../../../../components/Button/InlineButton'
import MessageInfo from '../../../../components/blocks/MessageShow/MessageInfo'
import AvatarPlaceholder from '../../../../assets/images/avatar_placeholder.png'
import TagsBar from '../../../../components/blocks/MessageShow/TagsBar'
import AudioButtons from '../../../../components/blocks/MessageShow/AudioButtons'
import ButtonsBar from '../../../../components/blocks/MessageShow/ButtonsBar'
import APIClient, { SuggestedTag } from '../../../../apiClient'
import { Loader } from '../../../../components/Loader/Loader'
import { colors } from '../../../../assets/colors'
import ActionsModal from '../../../../components/Modal/ActionsModal'
import { useFormik, FieldArray, FormikProvider } from 'formik'
import MessageInput from '../../../../components/blocks/MessageShow/MessageInput'
import Form from '../../../../components/Input/Form'
import TagsInput from '../../../../components/Input/TagsInput'
import Icon from '../../../../components/Icon'
import styled from 'styled-components'
import Tag from '../../../../components/blocks/Tags/Tag'
import ImageField from '../../../../components/Field/ImageField'
import { MessageShowPlaybackBar } from '../../../../components/blocks/MessageShow/MessagePlaybackBar'
import { shallow } from 'zustand/shallow'
import { useAudioMessageActions, useAudioMessageStore } from '../../../../store/audioMessage'
import { showAlertModal, showInfoModal } from '../../../../store/modals'
import { useGetMessagesById } from '../../../../hooks/queries/useGetMessagesById'
import { useQueryClient } from '@tanstack/react-query'
import { getSuggestedTags } from '../../../../hooks/queries/getSuggestedTags'
import { useGetMessageTags } from '../../../../hooks/queries/useGetMessageTags'
import confidential from '../../../../components/Icon/Confidential'

const audioRates = [1, 1.25, 1.5, 1.75, 2]

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: ${colors.silver};
`

const MessageShowPage: React.FC = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const confidential = params.get('confidential') === 'true'

  const history = useHistory<{ newMessage?: boolean; forwardId?: string; recipients?: any[] }>()
  const { id } = useParams<{ id?: string }>()
  const tagsRef = useRef(null)

  const queryClient = useQueryClient()

  const { data: message, isLoading } = useGetMessagesById(id, confidential)
  const { data: allSuggestedTags } = getSuggestedTags()
  const { data: messageTags } = useGetMessageTags(id)

  const isConfidential = message?.confidential

  const { setAudioPlaying, setAudioMessageId, setAudioSeek, setAudioSpeed } = useAudioMessageActions()

  const [suggestedTags, setSuggestedTags] = useState<SuggestedTag[]>([])

  const [allTags, setAllTags] = useState<{ tag: string; curated?: boolean }[]>()
  const [tags, setTags] = useState<string[]>()

  const [showAddModal, setShowAddModal] = useState(false)
  const [showSpeedModal, setShowSpeedModal] = useState(false)
  const [showMarkModal, setShowMarkModal] = useState(false)
  const [inputOpen, setInputOpen] = useState(false)
  const [tagsOpen, setTagsOpen] = useState(false)

  const inputRef = useRef<HTMLTextAreaElement>(null)

  const playing = useAudioMessageStore((state) => state.playing, shallow)
  const audioLoading = useAudioMessageStore((state) => state.loading, shallow)
  const duration = useAudioMessageStore((state) => state.duration, shallow)
  const audioSpeed = useAudioMessageStore((state) => state.speed, shallow)
  const curTime = useAudioMessageStore((state) => (id ? state.currentTime[id] : 0), shallow)

  const invalidateMessageLists = async () => {
    await queryClient.invalidateQueries(['messageCounter'])
    await queryClient.invalidateQueries(['messages', 'saved'])
    await queryClient.invalidateQueries(['messages', 'inbox'])
    await queryClient.invalidateQueries(['messages', 'new'])
    await queryClient.invalidateQueries(['messages', 'deleted'])
  }

  useEffect(() => {
    if (!id || !message) return
    setAudioMessageId(id)
  }, [id, message])

  useEffect(() => {
    if (!allSuggestedTags) return
    setSuggestedTags(allSuggestedTags)
    setAllTags(allSuggestedTags)
  }, [allSuggestedTags])

  useEffect(() => {
    if (!id || !messageTags) return
    setTags(messageTags.map((tag) => tag.tag))
  }, [messageTags, id])

  // action functions
  const replyMessage = useCallback(() => {
    if (!message) return
    let recipients
    if (message && message?.contact) {
      recipients = [
        {
          id: message?.contact.id,
          label: `${message?.contact.first_name} ${message?.contact.last_name}`
        }
      ]
    }
    // @ts-ignore
    history.push(`${history.location.pathname}`, { ...history.location.state, newMessage: true, recipients })
  }, [message])

  const forwardMessage = useCallback(() => {
    history.push(`${history.location.pathname}`, { newMessage: true, forwardId: id })
  }, [message])

  const deleteMessage = useCallback(async () => {
    try {
      if (!id) return null
      await APIClient.moveMessagesItem({ id, toFolder: 'deleted' })
      await invalidateMessageLists()
      history.push('/messages')
    } catch (e) {
      console.log('error on deleteMessage: ', e)
    }
  }, [id])

  const markMessageUnread = useCallback(async () => {
    try {
      if (!id) return null
      setShowMarkModal(false)
      await APIClient.markMessagesItemStatus({ id, status: 'unplayed' })
      await queryClient.invalidateQueries(['message', id])
    } catch (e) {
      console.log('error ===', e)
    }
  }, [])

  const markMessageUrgent = useCallback(async () => {
    try {
      if (!id) return null
      setShowMarkModal(false)
      await APIClient.markMessagesItemStatus({ id, status: 'urgent' })
      await queryClient.invalidateQueries(['message', id])
    } catch (e) {
      console.log('error ===', e)
    }
  }, [])

  const saveMessage = useCallback(async () => {
    try {
      if (!id) return null
      await APIClient.moveMessagesItem({ id, toFolder: 'saved' })
      await invalidateMessageLists()
      await queryClient.invalidateQueries(['message', id])
      showInfoModal('Message moved to saved')
    } catch (e) {
      console.log('error ===', e)
    }
  }, [id])

  const onAudioClick = async (e: SyntheticEvent) => {
    e.stopPropagation()
    try {
      if (!id) return
      if (playing) return setAudioPlaying(false)
      return setAudioPlaying(true)
    } catch (e) {
      console.log('error ===', e)
    }
  }

  useEffect(() => {
    setShowSpeedModal(false)
  }, [audioSpeed])

  // formik

  const formik = useFormik({
    initialValues: {
      note: message?.note,
      tags: tags || []
    },
    onSubmit: async (values) => {
      if (id && values.note !== message?.note && message) {
        try {
          await APIClient.messageNoteUpdate({ messageUniqueId: id, note: values.note || message.note || '' })
        } catch (e) {
          console.log('error ===', e)
        }
      }

      // @ts-ignore
      let tagsInputValue = tagsRef?.current?.value
      if (id && (values.tags !== tags || tagsInputValue)) {
        const tagsToAdd = values.tags?.filter((tag) => !tags?.find((t) => t === tag)) || []
        const tagsToRemove = tags?.filter((tag) => !values.tags?.find((t) => t === tag)) || []
        if (tagsInputValue) {
          if (tagsInputValue[0] !== '#') {
            tagsInputValue = `#${tagsInputValue}`
          }
          tagsToAdd.push(tagsInputValue)
        }
        try {
          await Promise.all(
            tagsToAdd.map(async (tag) => {
              await APIClient.messageAddTag({ id, newTag: tag })
            })
          )
          await Promise.all(
            tagsToRemove.map(async (tag) => {
              await APIClient.messageRemoveTag({ id, newTag: tag })
            })
          )
        } catch (e) {
          console.log('error ===', e)
        }
      }

      setInputOpen(false)
      setTagsOpen(false)
    }
  })

  useEffect(() => {
    formik.setFieldValue('tags', tags)
  }, [tags])

  useEffect(() => {
    const filtered = allTags?.filter((tag) => !formik.values.tags || !formik.values.tags.includes(tag.tag))
    setSuggestedTags(filtered || [])
  }, [formik.values.tags])

  const addToTagsList = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement> | string, push: (value: string) => void) => {
      if (typeof e === 'string') {
        push(e)
        return undefined
      }
      let value = e.currentTarget?.value
      if (value?.includes(' ')) {
        showAlertModal('Space is not allowed in a tag')
        e.currentTarget.value = e.currentTarget.value.slice(0, -1)
      }

      if (e.keyCode === 13 && value?.length) {
        if (value[0] !== '#') {
          value = `#${value}`
        }

        if (formik.values.tags.filter((tag) => tag === value).length) {
          return undefined
        }

        push(value)
        e.currentTarget.value = ''
      }
    },
    [formik.values.tags]
  )

  const updateTagsList = useCallback(
    (
      e: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
      index: number,
      remove: (index: number) => void,
      replace: (index: number, value: string) => void
    ) => {
      let value = e.currentTarget.value

      if (value[0] !== '#') {
        value = `#${value}`
      }

      if (!value.length || value.length === 1) {
        remove(index)
      } else {
        replace(index, value)
      }
    },
    []
  )

  const messageDate = useMemo(() => {
    if (!message || !message?.timestamp) return null
    return format(new Date(message?.timestamp * 1000), 'EEEE • hh:mmaaaa')
  }, [message])

  const messageExpirationDate = useMemo(() => {
    if (!message || !message?.expiration_timestamp) return null
    return (
      <>
        {' '}
        {'•'} Exp {format(new Date(message?.expiration_timestamp * 1000), 'd MMM')}
      </>
    )
  }, [message])

  if (isLoading || audioLoading || !message) {
    return (
      <>
        <Header style={isConfidential ? { background: colors.mediumGrey2 } : null}>
          <Header.SideButtons>
            <InlineButton onClick={() => history.push('/')}>Back</InlineButton>
          </Header.SideButtons>
          <Header.Title>
            <span>Message</span>
          </Header.Title>
          <Header.EndButtons></Header.EndButtons>
        </Header>
        <LoaderContainer>
          <Loader fill={colors.mediumGrey} />
        </LoaderContainer>
      </>
    )
  }

  if (tagsOpen) {
    return (
      <>
        <Header style={isConfidential ? { background: colors.mediumGrey2 } : null}>
          <Header.SideButtons>
            <InlineButton onClick={() => history.push('/')}>Back</InlineButton>
          </Header.SideButtons>
          <Header.Title>
            <span>Message</span>
          </Header.Title>
          <Header.EndButtons>
            <InlineButton onClick={formik.handleSubmit}>Done</InlineButton>
          </Header.EndButtons>
        </Header>

        <FormikProvider value={formik}>
          <FieldArray
            name="tags"
            render={({ push, remove, replace }) => (
              <Form>
                <TagsInput>
                  <Icon
                    name={'bookmark'}
                    fill={colors.mediumGrey}
                    width={'30px'}
                    style={{ marginRight: '5px', minWidth: '30px' }}
                  />
                  {formik.values.tags?.map((tag, index) => (
                    <TagsInput.Tag
                      key={`${tag}_selected`}
                      defaultValue={tag}
                      size={tag.length + 1}
                      onBlur={(e) => updateTagsList(e, index, remove, replace)}
                      onKeyUp={(e) => {
                        if (e.key === ' ') {
                          updateTagsList(e, index, remove, replace)
                        }
                      }}
                    />
                  ))}
                  <TagsInput.HiddenInput
                    ref={tagsRef}
                    onKeyDown={(e) => {
                      const code = e.keyCode || e.which
                      if (code === 13) {
                        e.preventDefault()
                        return false
                      }
                    }}
                    onKeyUp={(e) => addToTagsList(e, push)}
                  />
                </TagsInput>
                {suggestedTags?.map((tag) => (
                  <div style={{ margin: '5px' }} key={tag.tag}>
                    <Tag label={tag.tag} onClick={() => addToTagsList(tag.tag, push)} />
                  </div>
                ))}
              </Form>
            )}
          />
        </FormikProvider>
      </>
    )
  }

  return (
    <>
      <Header style={isConfidential ? { background: colors.mediumGrey2 } : null}>
        <Header.SideButtons>
          <InlineButton onClick={() => history.push('/')}>Back</InlineButton>
        </Header.SideButtons>
        <Header.Title>
          <span>Message</span>
        </Header.Title>
        <Header.EndButtons>
          {inputOpen ? <InlineButton onClick={formik.handleSubmit}>Done</InlineButton> : null}
        </Header.EndButtons>
      </Header>

      <MessageInfo>
        <MessageInfo.Status color={(message?.urgent && colors.red) || (message?.new && colors.darkBlue) || undefined} />
        {message?.contact && message?.contact['has_photo'] && message?.contact['photo_url'] ? (
          <MessageInfo.Avatar src={message?.contact['photo_url']} />
        ) : (
          <MessageInfo.Avatar src={AvatarPlaceholder} />
        )}
        <MessageInfo.AboutContainer>
          <MessageInfo.ContactName>
            {message?.contact?.first_name || message?.source_kate_account?.first_name}{' '}
            {message?.contact?.last_name || message?.source_kate_account?.last_name}
          </MessageInfo.ContactName>
          <MessageInfo.AboutInfo>
            {messageDate}
            {messageExpirationDate}
          </MessageInfo.AboutInfo>
        </MessageInfo.AboutContainer>
        <MessageInfo.AddButton isConfidential={isConfidential} onClick={() => setShowAddModal(true)} />
      </MessageInfo>

      <Form
        autoComplete="off"
        onReset={formik.handleReset}
        style={{
          backgroundColor: colors.silver,
          width: '100%',
          height: '100%'
        }}
      >
        <MessageInput
          ref={inputRef}
          name="note"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.note}
          defaultValue={message?.note}
          onFocus={() => setInputOpen(true)}
        />

        {message?.photo_url && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              padding: 20
            }}
          >
            <ImageField size="big" src={message?.photo_url} alt={'message_photo'} />
          </div>
        )}
      </Form>

      {tags && tags.length ? (
        <TagsBar onClick={() => setTagsOpen(true)}>
          <TagsBar.Tag>{tags?.join(' ')}</TagsBar.Tag>
        </TagsBar>
      ) : null}

      <MessageShowPlaybackBar
        isConfidential={isConfidential}
        duration={duration}
        curTime={curTime}
        playProgress={(curTime / duration) * 100 || 0}
        onTimeUpdate={debounce((time) => setAudioSeek(time), 5)}
      />

      <AudioButtons>
        <AudioButtons.Button isConfidential={isConfidential} onClick={() => setAudioSeek(0.1)} name={'skipToStart'} />
        <AudioButtons.Button isConfidential={isConfidential} onClick={onAudioClick} name={playing ? 'pause' : 'play'} />
        <AudioButtons.Button
          isConfidential={isConfidential}
          onClick={() => setAudioSeek(curTime - 5 > 0 ? curTime - 5 : 0.1)}
          name={'reply5'}
        />
        <AudioButtons.SpeedButton onClick={() => setShowSpeedModal(true)}>
          {' '}
          {audioSpeed}x speed
        </AudioButtons.SpeedButton>
      </AudioButtons>

      <ButtonsBar isConfidential={isConfidential}>
        <ButtonsBar.Button onClick={deleteMessage} name={'remove'} />
        {!isConfidential && <ButtonsBar.Button onClick={saveMessage} name={'download'} />}
        <ButtonsBar.Button onClick={replyMessage} name={'replyArrow'} />
        {!isConfidential && <ButtonsBar.Button onClick={forwardMessage} name={'forwardArrow'} />}
        <ButtonsBar.Button onClick={() => setShowMarkModal(true)} name={'ellipsis'} />
      </ButtonsBar>

      <ActionsModal show={showAddModal} onCancel={() => setShowAddModal(false)}>
        <ActionsModal.Action
          onClick={() => {
            setShowAddModal(false)
            if (inputRef && inputRef.current) {
              inputRef.current.focus()
            }
          }}
        >
          Add Note
        </ActionsModal.Action>
        <ActionsModal.Action
          onClick={() => {
            setTagsOpen(true)
            setShowAddModal(false)
          }}
        >
          Add Tag
        </ActionsModal.Action>
      </ActionsModal>

      <ActionsModal show={showMarkModal} onCancel={() => setShowMarkModal(false)}>
        <ActionsModal.Action onClick={markMessageUnread}>Mark Unread</ActionsModal.Action>
        <ActionsModal.Action onClick={markMessageUrgent}>Mark Urgent</ActionsModal.Action>
      </ActionsModal>

      <ActionsModal show={showSpeedModal} onCancel={() => setShowSpeedModal(false)}>
        {audioRates.map((audioRate) => (
          <ActionsModal.Action key={audioRate} onClick={() => setAudioSpeed(audioRate)}>
            {audioRate}X
          </ActionsModal.Action>
        ))}
      </ActionsModal>
    </>
  )
}

export default memo(MessageShowPage)
