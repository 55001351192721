import React from 'react'

const MessageIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
    <g clipPath="url(#clip0_6993_7990)">
      <path
        d="M0 3.69982L4.61169 8.16709L0 13.0098V3.69982ZM14 0C14.0882 0.000666104 14.1755 0.0193012 14.2568 0.0548404C14.3381 0.0903797 14.4118 0.142127 14.4737 0.207128C14.5357 0.27213 14.5846 0.349112 14.6178 0.433679C14.651 0.518246 14.6677 0.608743 14.6671 0.7C14.6677 0.791258 14.651 0.881753 14.6178 0.96632C14.5846 1.05089 14.5357 1.12787 14.4737 1.19287C14.4118 1.25787 14.3381 1.30962 14.2568 1.34516C14.1755 1.3807 14.0882 1.39933 14 1.4H2C1.91175 1.39933 1.82449 1.3807 1.74321 1.34516C1.66192 1.30962 1.58821 1.25787 1.52626 1.19287C1.46432 1.12787 1.41536 1.05089 1.38219 0.96632C1.34901 0.881753 1.33227 0.791258 1.33292 0.7C1.33227 0.608743 1.34901 0.518246 1.38219 0.433679C1.41536 0.349112 1.46432 0.27213 1.52626 0.207128C1.58821 0.142127 1.66192 0.0903797 1.74321 0.0548404C1.82449 0.0193012 1.91175 0.000666104 2 0H14ZM16 3.69982V13.0098L11.3883 8.17345L16 3.69982ZM10.4086 9.11909L15.0572 14H0.942769L5.59385 9.11909L6.28431 9.78855C6.75286 10.2329 7.36428 10.4814 8 10.486C8.63572 10.4814 9.24714 10.2329 9.71569 9.78855L10.4086 9.11909ZM1.03877 2.8H14.9612L8.80739 8.76145C8.5872 8.97462 8.2967 9.09296 7.99508 9.09236C7.69345 9.09296 7.40296 8.97462 7.18277 8.76145L1.03877 2.8Z"
        fill="#1D70B6"
      />
    </g>
    <defs>
      <clipPath id="clip0_6993_7990">
        <rect width="16" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default MessageIcon
