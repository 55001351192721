import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { colors } from '../../../assets/colors'

const Input = styled.textarea`
  border: none;
  background-color: transparent;
  outline: none;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  line-height: 18px;
  height: inherit;
  font-family: inherit;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.silver};
`

const MessageInput = ({ ...props }, ref: React.Ref<HTMLTextAreaElement>) => (
  <Container>
    <Input ref={ref} {...props} />
  </Container>
)

export default forwardRef(MessageInput)
