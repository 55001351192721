import React, { useState } from 'react'
import { FormikProvider } from 'formik'
import { colors } from '../../../../../../../assets/colors'
import BackArrow from '../../../../../../../components/Icon/BackArrow'
import StyledLink from '../../../../../../../components/Button/Link'
import { useHistory } from 'react-router'
import { Button } from '../../../../../../../components/Button'
import useCreateContact from '../../../../../../mobile/pages/Contacts/Common/Hooks/useCreateContact'
import Modal from '../../../../../../../components/Modal'
import { StyledCreateContactForm } from '../../Styled/StyledCreateContactForm'
import GroupSelect from '../GroupSelect'
import { GroupItemType } from '../../../../../../../types'
import { StyledGroupName } from '../../../../../../../components/GroupsAndContacts/Styled'
import { Loader } from '../../../../../../../components/Loader/Loader'
import { showContactsModal } from '../../../../../../../store/modals'
import NotSavedFields from '../../Components/Modals/NotSavedFields'

interface IProps {
  groupsData?: GroupItemType[]
}

const CreateContactForm = ({ groupsData }: IProps) => {
  const history = useHistory()
  const {
    lookupTouched,
    selectGroups,
    selectAccount,
    setKateNumber,
    parseNumber,
    formik,
    accounts,
    setSelectedRadioOption,
    selectedRadioOption,
    setSelectAccount,
    createMany,
    chooseAccount,
    isSaving,
    groupSelectionVisible,
    setGroupSelectionVisible
  } = useCreateContact()

  const [isModalOpen, setModalOpen] = useState(false)
  const [nextLocation, setNextLocation] = useState(null)

  const unblock = history.block((location) => {
    if (formik.dirty) {
      setModalOpen(true)
      setNextLocation(location)
      return false
    }
  })

  return (
    <>
      <div style={{ width: '100%', position: 'relative' }}>
        <FormikProvider value={formik}>
          <StyledCreateContactForm.Wrapper>
            <StyledLink
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px'
              }}
              onClick={() => {
                history.goBack()
              }}
            >
              <BackArrow style={{ marginRight: '10px' }} />
              New contact
            </StyledLink>
            <Button
              style={{ width: '150px', height: '40px' }}
              type="submit"
              onClick={() => {
                if (formik.dirty) {
                  formik.handleSubmit()
                  formik.resetForm({ values: formik.values })
                }
              }}
              isLoading={isSaving}
            >
              Create
            </Button>
          </StyledCreateContactForm.Wrapper>
          <StyledCreateContactForm.GroupSectionWrapper>
            <div style={{ maxWidth: 'calc(100% - 100px)' }}>
              <p style={{ fontSize: '12px', color: colors.lightGrey, marginBottom: '7px' }}>Groups</p>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {isSaving ? null : formik.values?.groups?.length ? (
                  formik.values?.groups?.map((group) => (
                    <StyledGroupName key={group.id} to={`/groups?id=${group.id}`}>
                      {group.name}
                    </StyledGroupName>
                  ))
                ) : (
                  <p style={{ fontSize: '13px', color: colors.mediumGrey }}>no groups yet</p>
                )}
              </div>
            </div>
            <StyledLink
              style={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 600,
                fontSize: '14px',
                width: '100px'
              }}
              onClick={() => setGroupSelectionVisible(true)}
            >
              <span style={{ marginRight: '10px', fontSize: '20px' }}>+</span>Add group
            </StyledLink>
          </StyledCreateContactForm.GroupSectionWrapper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginBottom: '25px'
            }}
          >
            <div style={{ width: '100%', marginRight: '10px' }}>
              <StyledCreateContactForm.Label htmlFor="kate_number">Kate number</StyledCreateContactForm.Label>
              <StyledCreateContactForm.Input
                id="kate_number"
                type="text"
                placeholder="Kate number"
                onChange={async (e) => {
                  setKateNumber(parseNumber(e.target.value))
                  formik.handleChange(e)
                }}
                value={formik.values.kate_number}
              />
            </div>
            <div style={{ width: '100%', marginLeft: '10px' }}>
              <StyledCreateContactForm.Label htmlFor="first_name">First name</StyledCreateContactForm.Label>
              <StyledCreateContactForm.Input
                id="first_name"
                type="text"
                onChange={formik.handleChange}
                value={formik.values?.first_name}
                placeholder="First name"
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginBottom: '25px'
            }}
          >
            <div style={{ width: '100%', marginRight: '10px' }}>
              <StyledCreateContactForm.Label htmlFor="last_name">Last name</StyledCreateContactForm.Label>
              <StyledCreateContactForm.Input
                id="last_name"
                onChange={formik.handleChange}
                value={formik.values.last_name}
                type="text"
                placeholder="Last name"
              />
              {/*{meta.touched && meta.error && (*/}
              {/*  <div style={{ color: colors.red, fontSize: '12px', marginTop: '4px' }}>{meta.error}</div>*/}
              {/*)}*/}
            </div>
            <div style={{ width: '100%', marginLeft: '10px' }}>
              <StyledCreateContactForm.Label htmlFor="mobile_phone">Mobile phone</StyledCreateContactForm.Label>
              <StyledCreateContactForm.Input
                id="mobile_phone"
                type="text"
                placeholder="Mobile phone"
                onChange={formik.handleChange}
                value={formik.values.mobile_phone}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginBottom: '25px'
            }}
          >
            <div style={{ width: '100%', marginRight: '10px' }}>
              <StyledCreateContactForm.Label htmlFor="email">Personal email</StyledCreateContactForm.Label>
              <StyledCreateContactForm.Input
                id="email"
                type="email"
                placeholder="Personal email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </div>
            <div style={{ width: '100%', marginLeft: '10px' }}>
              <StyledCreateContactForm.Label htmlFor="email2">Work email</StyledCreateContactForm.Label>
              <StyledCreateContactForm.Input
                id="email2"
                type="email"
                placeholder="Work email"
                onChange={formik.handleChange}
                value={formik.values.email2}
              />
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <StyledCreateContactForm.Label htmlFor="extra_info">Notes</StyledCreateContactForm.Label>
            <StyledCreateContactForm.TextAreaInput
              id="extra_info"
              placeholder="Notes"
              onChange={formik.handleChange}
              value={formik.values.extra_info}
            />
          </div>
        </FormikProvider>
        {!lookupTouched && !selectGroups && selectAccount && (
          <Modal show={true}>
            <div style={{ width: '400px', padding: '30px' }}>
              <p style={{ fontSize: '15px', fontWeight: 600, textAlign: 'center', marginBottom: '20px' }}>
                Create contact
              </p>
              {accounts.map((account, key) => (
                <StyledCreateContactForm.RadioWrapper
                  active={selectedRadioOption === account.account_number}
                  key={account.account_number}
                  onClick={() => setSelectedRadioOption(account.account_number)}
                >
                  <input
                    type="radio"
                    id="contact"
                    name="contact"
                    value={account.account_number}
                    checked={selectedRadioOption === account.account_number}
                    style={{ margin: '0 15px 0 0' }}
                    onChange={(e) => setSelectedRadioOption(e.target.value)}
                  />
                  <p style={{ fontSize: '12px' }}>
                    {account.first_name} {account.last_name}
                  </p>
                </StyledCreateContactForm.RadioWrapper>
              ))}
              <StyledCreateContactForm.RadioWrapper
                active={selectedRadioOption === 'both'}
                onClick={() => setSelectedRadioOption('both')}
              >
                <input
                  type="radio"
                  id="contact"
                  name="contact"
                  value="both"
                  style={{ margin: '0 15px 0 0' }}
                  checked={selectedRadioOption === 'both'}
                  onChange={(e) => setSelectedRadioOption(e.target.value)}
                />
                <p style={{ fontSize: '12px' }}>Both accounts</p>
              </StyledCreateContactForm.RadioWrapper>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
                <Button
                  type="button"
                  style={{
                    width: '87px',
                    background: 'rgba(112, 112, 112, 0.20)',
                    color: '#707070',
                    marginRight: '15px'
                  }}
                  onClick={() => setSelectAccount(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  style={{ width: '87px' }}
                  onClick={async () => {
                    if (selectedRadioOption === 'both') {
                      await createMany()
                    } else {
                      const account = accounts?.find((account) => account?.account_number === selectedRadioOption)
                      if (!account) return
                      chooseAccount(account)
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal>
        )}
        {groupSelectionVisible && (
          <GroupSelect onClose={() => setGroupSelectionVisible(false)} groupsData={groupsData} formik={formik} />
        )}
      </div>
      <NotSavedFields
        visible={isModalOpen}
        handleClose={() => {
          setModalOpen(false)
          setNextLocation(null)
        }}
        handleConfirm={() => {
          unblock()
          setModalOpen(false)
          history.push(nextLocation.pathname)
        }}
      />
    </>
  )
}

export default CreateContactForm
