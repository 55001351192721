import React from 'react'

interface DownloadProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const Download = ({
  style = {},
  fill = '#000',
  width = '30px',
  className = '',
  viewBox = '0 0 30 30'
}: DownloadProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
M 15 1 C 14.448 1 14 1.448 14 2 L 14 6 L 16 6 L 16 2 C 16 1.448 15.552 1 15 1 z M 16 6 L 16 18.585938 L 18.292969 16.292969 C 18.683969 15.901969 19.316031 15.901969 19.707031 16.292969 C 20.098031 16.683969 20.098031 17.316031 19.707031 17.707031 L 15.707031 21.707031 C 15.512031 21.902031 15.256 22 15 22 C 14.744 22 14.487969 21.902031 14.292969 21.707031 L 10.292969 17.707031 C 9.9019687 17.316031 9.9019688 16.683969 10.292969 16.292969 C 10.683969 15.901969 11.316031 15.901969 11.707031 16.292969 L 14 18.585938 L 14 6 L 6 6 C 4.895 6 4 6.895 4 8 L 4 25 C 4 26.105 4.895 27 6 27 L 24 27 C 25.105 27 26 26.105 26 25 L 26 8 C 26 6.895 25.105 6 24 6 L 16 6 z
"
    />
  </svg>
)

export default Download
