import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const NavigationLink = styled(Link)<{ active?: boolean }>`
  text-decoration: none;
  color: ${(props: { active?: boolean }) => (props.active ? '#1D70B6' : '#707070')};
  background: ${(props: { active?: boolean }) => (props.active ? 'rgba(0, 0, 0, 0.07)' : 'transparent')};
  display: block;
  padding: 16px;

  &:hover {
    background: rgba(0, 0, 0, 0.07);
  }
`

//#1D70B6
