import styled from 'styled-components'

const Avatar = styled.img`
  height: 35px;
  min-width: 35px;
  width: 35px;
  border-radius: 50%;
  margin: 0 5px;
`

export default Avatar
