import React from 'react'
import styled from 'styled-components'
import { colors } from '../../assets/colors'
import IcReports from '../../assets/images/ic_reports.png'

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const ReportsText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.darkBlue};
  margin-left: 5px;
`

const ReportsLink = () => (
  <Container>
    <img width={17} height={22} src={IcReports} alt="reports" />
    <ReportsText>Reports</ReportsText>
  </Container>
)

export default ReportsLink
