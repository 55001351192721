import React, { SVGProps } from 'react'

const CommuniKateLogoTitle = (props: SVGProps<SVGSVGElement>) => (
  <svg width="160" height="23" viewBox="0 0 103 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0383 13.3147C13.6131 13.3147 14.0573 13.1485 14.4231 12.7925C14.7889 12.4365 14.9718 11.9856 14.9718 11.416C14.9718 10.8463 14.7889 10.3717 14.4231 10.0394C14.0573 9.68337 13.587 9.51723 13.0383 9.51723C12.4634 9.51723 12.0193 9.68337 11.6535 10.0394C11.2876 10.3954 11.1047 10.8463 11.1047 11.416C11.1047 11.9856 11.2876 12.4602 11.6535 12.7925C12.0193 13.1485 12.4896 13.3147 13.0383 13.3147ZM13.0383 14.9523C12.2283 14.9523 11.5228 14.8099 10.8957 14.5014C10.2686 14.1928 9.77217 13.7894 9.40637 13.2435C9.04056 12.6976 8.88379 12.1042 8.88379 11.416C8.88379 10.7277 9.06669 10.1343 9.40637 9.58843C9.77217 9.04254 10.2686 8.63907 10.8957 8.33053C11.5228 8.02198 12.2544 7.87958 13.0383 7.87958C13.8483 7.87958 14.5538 8.02198 15.1809 8.33053C15.8079 8.63907 16.3044 9.04254 16.6702 9.58843C17.036 10.1343 17.1928 10.7277 17.1928 11.416C17.1928 12.1042 17.0099 12.6976 16.6702 13.2435C16.3044 13.7894 15.8079 14.1928 15.1809 14.5014C14.5538 14.8099 13.8483 14.9523 13.0383 14.9523Z"
      fill="#2C3E50"
    />
    <path
      d="M28.3762 7.85592C29.3168 7.85592 30.0746 8.11699 30.6233 8.61541C31.172 9.11382 31.4594 9.87331 31.4594 10.8939V14.8337H29.2646V11.2024C29.2646 10.6565 29.1339 10.2531 28.8988 9.99198C28.6375 9.73091 28.2978 9.5885 27.8275 9.5885C27.3049 9.5885 26.913 9.73091 26.5994 10.0395C26.312 10.348 26.1552 10.7989 26.1552 11.3923V14.8337H23.9604V11.2024C23.9604 10.1107 23.4901 9.5885 22.5233 9.5885C22.0269 9.5885 21.6088 9.73091 21.3214 10.0395C21.034 10.348 20.8772 10.7989 20.8772 11.3923V14.8337H18.6824V7.95085H20.7727V8.73408C21.0601 8.44927 21.3998 8.21193 21.8178 8.06952C22.2359 7.92712 22.6801 7.83218 23.1504 7.83218C23.673 7.83218 24.1694 7.92712 24.6136 8.11699C25.0578 8.30686 25.3975 8.59167 25.6588 8.94768C25.9723 8.59167 26.3643 8.30686 26.8346 8.11699C27.2788 7.95085 27.8014 7.85592 28.3762 7.85592Z"
      fill="#2C3E50"
    />
    <path
      d="M43.1386 7.85592C44.0793 7.85592 44.837 8.11699 45.3857 8.61541C45.9344 9.11382 46.2219 9.87331 46.2219 10.8939V14.8337H44.027V11.2024C44.027 10.6565 43.8964 10.2531 43.6612 9.99198C43.3999 9.73091 43.0603 9.5885 42.5899 9.5885C42.0674 9.5885 41.6754 9.73091 41.3619 10.0395C41.0745 10.348 40.9177 10.7989 40.9177 11.3923V14.8337H38.7229V11.2024C38.7229 10.1107 38.2525 9.5885 37.2858 9.5885C36.7893 9.5885 36.3713 9.73091 36.0838 10.0395C35.7964 10.348 35.6397 10.7989 35.6397 11.3923V14.8337H33.4448V7.95085H35.5351V8.73408C35.8226 8.44927 36.1622 8.21193 36.5803 8.06952C36.9984 7.92712 37.4425 7.83218 37.9129 7.83218C38.4354 7.83218 38.9319 7.92712 39.3761 8.11699C39.8203 8.30686 40.16 8.59167 40.4212 8.94768C40.7348 8.59167 41.1267 8.30686 41.597 8.11699C42.0674 7.95085 42.5638 7.85592 43.1386 7.85592Z"
      fill="#2C3E50"
    />
    <path
      d="M55.8374 7.97458V14.8575H53.7471V14.0505C53.4597 14.359 53.12 14.5726 52.7019 14.7388C52.31 14.9049 51.8658 14.9761 51.3955 14.9761C50.4026 14.9761 49.6187 14.715 49.0178 14.1929C48.4429 13.6707 48.1555 12.9113 48.1555 11.867V7.97458H50.3503V11.5584C50.3503 12.6739 50.8729 13.2198 51.892 13.2198C52.4145 13.2198 52.8326 13.0537 53.1461 12.7451C53.4597 12.4366 53.6165 11.9619 53.6165 11.3685V7.97458H55.8374Z"
      fill="#2C3E50"
    />
    <path
      d="M62.4742 7.85588C63.4148 7.85588 64.1726 8.11695 64.7474 8.61537C65.3222 9.13752 65.6097 9.89701 65.6097 10.8938V14.8337H63.4148V11.2024C63.4148 10.6565 63.2842 10.253 63.0229 9.99194C62.7616 9.73087 62.3697 9.58846 61.8732 9.58846C61.3245 9.58846 60.8803 9.7546 60.5406 10.0631C60.201 10.3717 60.0442 10.8464 60.0442 11.4397V14.8337H57.8494V7.95082H59.9397V8.75777C60.2271 8.47296 60.5929 8.23563 61.011 8.09322C61.5074 7.95082 61.9777 7.85588 62.4742 7.85588Z"
      fill="#2C3E50"
    />
    <path d="M69.8162 7.97458H67.6213V14.8575H69.8162V7.97458Z" fill="#2C3E50" />
    <path
      d="M75.0945 12.1518L74.0493 13.1012V14.8337H71.8545V5.36386H74.0493V10.7278L77.2632 7.97461H79.8761L76.7145 10.8702L80.1635 14.8337H77.4983L75.0945 12.1518Z"
      fill="#15B0F8"
    />
    <path
      d="M83.8997 13.5995C84.2655 13.5995 84.6052 13.5283 84.8926 13.3622C85.18 13.196 85.389 12.9824 85.5197 12.6739V11.9619H84.0042C83.1158 11.9619 82.6455 12.2229 82.6455 12.7688C82.6455 13.0299 82.75 13.2198 82.9852 13.3859C83.1942 13.5283 83.5078 13.5995 83.8997 13.5995ZM84.0565 7.85588C85.2323 7.85588 86.1206 8.11695 86.7477 8.61537C87.3748 9.11378 87.6884 9.89701 87.6884 10.9176V14.8337H85.6242V13.9793C85.2061 14.6201 84.4484 14.9286 83.3248 14.9286C82.75 14.9286 82.2274 14.8337 81.8094 14.6675C81.3913 14.4777 81.0516 14.2403 80.8426 13.9318C80.6074 13.6233 80.5029 13.2672 80.5029 12.8638C80.5029 12.2229 80.7642 11.7245 81.2868 11.3448C81.8094 10.9888 82.6455 10.7989 83.7429 10.7989H85.4935C85.4935 10.3717 85.3368 10.0394 85.0494 9.80207C84.7619 9.56473 84.3177 9.44606 83.7429 9.44606C83.351 9.44606 82.9329 9.49353 82.541 9.6122C82.149 9.73087 81.8094 9.87327 81.5481 10.0869L80.7642 8.68657C81.1823 8.4255 81.6787 8.21189 82.2536 8.06949C82.8545 7.92708 83.4555 7.85588 84.0565 7.85588Z"
      fill="#15B0F8"
    />
    <path
      d="M94.5865 14.5015C94.3775 14.6439 94.1162 14.7626 93.8026 14.8338C93.4891 14.905 93.1494 14.9525 92.8097 14.9525C91.9214 14.9525 91.2159 14.7388 90.7194 14.3354C90.223 13.9082 89.9878 13.3148 89.9878 12.4841V9.65976H88.812V8.11705H89.9878V6.43193H92.1826V8.11705H94.0639V9.65976H92.1826V12.4604C92.1826 12.7452 92.261 12.9825 92.4178 13.1249C92.5746 13.2911 92.8097 13.3623 93.1233 13.3623C93.463 13.3623 93.7765 13.2673 94.0117 13.1012L94.5865 14.5015Z"
      fill="#15B0F8"
    />
    <path
      d="M99.0544 9.37498C98.5579 9.37498 98.1399 9.49365 97.8002 9.75472C97.4605 10.0158 97.2515 10.3718 97.1731 10.799H100.91C100.831 10.3718 100.622 10.0158 100.282 9.75472C99.9689 9.49365 99.5508 9.37498 99.0544 9.37498ZM103 11.4398C103 11.4636 102.974 11.6534 102.948 11.9857H97.2253C97.3299 12.4129 97.565 12.7452 97.9569 13.0063C98.3489 13.2436 98.8192 13.386 99.394 13.386C99.786 13.386 100.126 13.3386 100.439 13.2199C100.753 13.125 101.04 12.9351 101.301 12.7215L102.477 13.8844C101.772 14.6202 100.727 14.9999 99.3418 14.9999C98.4795 14.9999 97.7218 14.8575 97.0686 14.549C96.4153 14.2405 95.8928 13.837 95.5531 13.2911C95.1873 12.7452 95.0305 12.1519 95.0305 11.4636C95.0305 10.799 95.2134 10.1819 95.5531 9.63605C95.8928 9.09017 96.3892 8.66296 97.0163 8.37815C97.6434 8.06961 98.3228 7.9272 99.0805 7.9272C99.8121 7.9272 100.491 8.06961 101.092 8.35441C101.693 8.63922 102.164 9.06644 102.503 9.61232C102.817 10.087 103 10.7041 103 11.4398Z"
      fill="#15B0F8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.7193 4.60449C67.9354 4.60449 67.3083 5.17411 67.3083 5.88613C67.3083 6.59815 67.9354 7.16777 68.7193 7.16777C69.5032 7.16777 70.1303 6.59815 70.1303 5.88613C70.1041 5.19785 69.477 4.60449 68.7193 4.60449Z"
      fill="#1A85F8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.1846 2.89528C65.5575 2.89528 65.061 3.34623 65.061 3.91584C65.061 4.48546 65.5575 4.93641 66.1846 4.93641C66.8117 4.93641 67.3081 4.48546 67.3081 3.91584C67.3081 3.34623 66.8117 2.89528 66.1846 2.89528Z"
      fill="#15B0F8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.1183 1.47133C67.6742 1.47133 67.3083 1.80361 67.3083 2.20709C67.3083 2.61056 67.6742 2.94284 68.1183 2.94284C68.5625 2.94284 68.9283 2.61056 68.9283 2.20709C68.9283 1.80361 68.5625 1.47133 68.1183 1.47133Z"
      fill="#2DDAFF"
    />
    <path
      d="M4.23288 14.9523C3.42288 14.9523 2.69128 14.8099 2.06418 14.5014C1.41096 14.1928 0.914511 13.7894 0.548706 13.2435C0.182902 12.6976 0 12.1042 0 11.416C0 10.7277 0.182902 10.1343 0.548706 9.58843C0.914511 9.04254 1.41096 8.63907 2.06418 8.33053C2.7174 8.02198 3.42288 7.87958 4.23288 7.87958C5.04288 7.87958 5.72223 8.02198 6.32319 8.33053C6.92416 8.63907 7.34222 9.06628 7.62964 9.6359L5.93126 10.4666C5.53933 9.82577 4.96449 9.51723 4.23288 9.51723C3.65804 9.51723 3.18773 9.68337 2.82192 10.0394C2.45612 10.3717 2.24708 10.8463 2.24708 11.4397C2.24708 12.033 2.42999 12.484 2.82192 12.84C3.18773 13.1723 3.68417 13.3621 4.23288 13.3621C4.99062 13.3621 5.53933 13.0536 5.93126 12.4128L7.62964 13.2672C7.36835 13.8131 6.92416 14.2403 6.32319 14.5488C5.72223 14.7862 5.01675 14.9523 4.23288 14.9523Z"
      fill="#2C3E50"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.681 0C66.3413 0 66.08 0.237341 66.08 0.545884C66.08 0.854426 66.3413 1.09177 66.681 1.09177C67.0207 1.09177 67.282 0.854426 67.282 0.545884C67.282 0.261075 66.9946 0 66.681 0Z"
      fill="#7EE9FC"
    />
  </svg>
)

export default CommuniKateLogoTitle
