import React, { useEffect } from 'react'

class WaveForm {
  constructor(opt) {
    opt = opt || {}

    this.phase = 0
    this.run = false

    // UI vars

    this.ratio = opt.ratio || window.devicePixelRatio || 1

    this.width = this.ratio * (opt.width || 320)
    this.width_2 = this.width / 2
    this.width_4 = this.width / 4

    this.height = this.ratio * (opt.height || 100)
    this.height_2 = this.height / 2

    this.MAX = this.height_2 - 4

    // Constructor opt

    this.amplitude = opt.amplitude || 1
    this.speed = opt.speed || 0.8
    this.frequency = opt.frequency || 6

    // Color mixer

    this.color =
      (function hex2rgb(hex) {
        let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
          return r + r + g + g + b + b
        })
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
        return result
          ? parseInt(result[1], 16).toString() +
              ',' +
              parseInt(result[2], 16).toString() +
              ',' +
              parseInt(result[3], 16).toString()
          : null
      })(opt.color || '#fff') || '255,255,255'

    // Canvas element

    this.canvas = document.createElement('canvas')
    this.canvas.width = this.width
    this.canvas.height = this.height
    this.canvas.style.width = this.width / this.ratio + 'px'
    this.canvas.style.height = this.height / this.ratio + 'px'

    this.container = opt.container || document.body
    this.container.appendChild(this.canvas)

    this.ctx = this.canvas.getContext('2d')
  }
}

WaveForm.prototype._GATF_cache = {}
WaveForm.prototype._globAttFunc = function (x) {
  if (WaveForm.prototype._GATF_cache[x] == null) {
    WaveForm.prototype._GATF_cache[x] = Math.pow(4 / (4 + Math.pow(x, 4)), 2)
  }
  return WaveForm.prototype._GATF_cache[x]
}

WaveForm.prototype._xpos = function (i) {
  return this.width_2 + i * this.width_4
}

WaveForm.prototype._ypos = function (i, attenuation) {
  let att = (this.MAX * this.amplitude) / attenuation
  return this.height_2 + this._globAttFunc(i) * att * Math.sin(this.frequency * i - this.phase)
}

WaveForm.prototype._drawLine = function (attenuation, color, width) {
  this.ctx.moveTo(0, 0)
  this.ctx.beginPath()
  this.ctx.strokeStyle = color
  this.ctx.lineWidth = width || 1

  let i = -2
  while ((i += 0.01) <= 2) {
    this.ctx.lineTo(this._xpos(i), this._ypos(i, attenuation))
  }

  this.ctx.stroke()
}

WaveForm.prototype._clear = function () {
  this.ctx.globalCompositeOperation = 'destination-out'
  this.ctx.fillRect(0, 0, this.width, this.height)
  this.ctx.globalCompositeOperation = 'source-over'
}

WaveForm.prototype._draw = function () {
  if (this.run === false) return

  this.phase = (this.phase + Math.PI * this.speed) % (2 * Math.PI)

  this._clear()
  this._drawLine(-2, 'rgba(' + this.color + ',0.1)')
  this._drawLine(-6, 'rgba(' + this.color + ',0.2)')
  this._drawLine(4, 'rgba(' + this.color + ',0.4)')
  this._drawLine(2, 'rgba(' + this.color + ',0.6)')
  this._drawLine(1, 'rgba(' + this.color + ',1)', 1.5)

  requestAnimationFrame(this._draw.bind(this))
}

WaveForm.prototype.start = function () {
  this.phase = 0
  this.run = true
  this._draw()
}

WaveForm.prototype.stop = function () {
  this.phase = 0
  this.run = false
}

WaveForm.prototype.setSpeed = function (v) {
  this.speed = v
}

WaveForm.prototype.setNoise = WaveForm.prototype.setAmplitude = function (v) {
  this.amplitude = Math.max(Math.min(v, 1), 0)
}

const WaveformVisualizer = ({ id = 'waveForm', width = window.innerWidth, height = 200 }) => {
  useEffect(() => {
    let waveForm = new WaveForm({
      width,
      height,
      color: '#222',
      container: document.getElementById(id)
    })
    waveForm.setSpeed(0.085) // 0.2
    waveForm.setNoise(0.4) // 0.9
    waveForm.start()

    return () => {
      waveForm.stop()
    }
  }, [])

  return <div id={id} />
}

export default WaveformVisualizer
