import React from 'react'

interface DeleteConfProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const DeleteConf = ({
  style = {},
  fill = '#707070',
  width = '18px',
  className = '',
  viewBox = '0 0 18 18'
}: DeleteConfProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M13.5 3.6H18V5.4H16.2V17.1C16.2 17.3387 16.1052 17.5676 15.9364 17.7364C15.7676 17.9052 15.5387 18 15.3 18H2.7C2.46131 18 2.23239 17.9052 2.0636 17.7364C1.89482 17.5676 1.8 17.3387 1.8 17.1V5.4H0V3.6H4.5V0.9C4.5 0.661305 4.59482 0.432387 4.7636 0.263604C4.93239 0.0948211 5.1613 0 5.4 0H12.6C12.8387 0 13.0676 0.0948211 13.2364 0.263604C13.4052 0.432387 13.5 0.661305 13.5 0.9V3.6ZM14.4 5.4H3.6V16.2H14.4V5.4ZM6.3 1.8V3.6H11.7V1.8H6.3Z"
    />
  </svg>
)

export default DeleteConf
