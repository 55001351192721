import React, { createContext, useEffect } from 'react'
import { useAuth, useUserActions } from '../store/user'
import { accountLockService } from '../services/AccountLockService'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const isAuthenticated = useAuth()
  const { fetchUser, fetchUserAvatar } = useUserActions()

  useEffect(() => {
    if (!isAuthenticated || accountLockService.isAccountLocked()) return
    fetchUser()
    fetchUserAvatar()
  }, [isAuthenticated])

  return <AuthContext.Provider value={{}}>{children}</AuthContext.Provider>
}
