export const getStripeCustomerQuery = `
  query getStripeCustomerQuery {
    getStripeCustomer {
      id
      name
      invoice_settings {
        default_payment_method
      }
    }
  }
`

export type StripeCustomerQueryResponse = {
  data: {
    getStripeCustomer: {
      id: string
      name: string
      invoice_settings: {
        default_payment_method: string
      }
    }
  }
}
