import React from 'react'

interface VoiceMailProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const VoiceMail = ({
  style = {},
  fill = '#000',
  width = '30px',
  className = '',
  viewBox = '0 0 30 30'
}: VoiceMailProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
      M 7 8 C 3.1458514 8 0 11.145851 0 15 C 0 18.854149 3.1458514 22 7 22 L 23 22 C 26.854149 22 30 18.854149 30 15 C 30 11.145851 26.854149 8 23 8 C 19.145851 8 16 11.145851 16 15 C 16 16.956263 16.81305 18.727446 18.115234 20 L 11.884766 20 C 13.18695 18.727446 14 16.956263 14 15 C 14 11.145851 10.854149 8 7 8 z M 7 10 C 9.7732684 10 12 12.226732 12 15 C 12 17.773268 9.7732684 20 7 20 C 4.2267316 20 2 17.773268 2 15 C 2 12.226732 4.2267316 10 7 10 z M 23 10 C 25.773268 10 28 12.226732 28 15 C 28 17.773268 25.773268 20 23 20 C 20.226732 20 18 17.773268 18 15 C 18 12.226732 20.226732 10 23 10 z
"
    />
  </svg>
)

export default VoiceMail
