import { BillableEventData } from '../../../apiClient'
import { conferenceEventsLogger } from '../../logger/ConferenceEventsLogger'

export const createBillableEvent = (event: any, eventName: string, isCreatedEvent: boolean) => {
  let billableEvent: BillableEventData = {
    event: eventName,
    meetingId: event.stream?.connection.data.split(',')[0].replace('meetingId=', ''),
    connectionId: event.stream?.connection.connectionId,
    customerIdParticipant: event.stream?.connection.data.split(',')[2].replace('customerIdParticipant=', ''),
    participantName: event.stream?.connection.data.split(',')[5].replace('participantName=', ''),
    participantIsSubscriber: true,
    timestampStart: Date.now()
  }
  if (!isCreatedEvent) {
    billableEvent = {
      ...billableEvent,
      timestampStart: Number(localStorage.getItem('created_time')),
      timestampEnd: Date.now()
    }
  }
  return billableEvent
}

export const publisherEventHandlers = {
  streamCreated: (event: any) => {
    const billableEvent: BillableEventData = createBillableEvent(event, 'connectionCreated', true)
    localStorage.setItem('bevent', JSON.stringify(billableEvent))
    localStorage.setItem('created_time', billableEvent.timestampStart.toString())
    conferenceEventsLogger.enqueueEvent(billableEvent)
  }
}
