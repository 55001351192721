import React, { FC } from 'react'
import styled from 'styled-components'

const OverlayContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  opacity: 0.8;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
`

interface Props {
  children: JSX.Element
}

const SharingScreenOverlay: FC<Props> = ({ children }) => {
  return (
    <OverlayContainer>
      {children}
      <Overlay>You are currently sharing your screen.</Overlay>
    </OverlayContainer>
  )
}

export default SharingScreenOverlay
