import React, { useEffect } from 'react'
import styled from 'styled-components'
import { colors } from '../../assets/colors'
import Icon from '../Icon'
import { hideInfoModal } from '../../store/modals'

const Wrapper = styled.div`
  max-width: 200px;
  max-height: 150px;
  border-radius: 15px;
  background-color: ${colors.mediumGrey};
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
`

const Text = styled.span`
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin-top: 5px;
`

interface InfoI {
  text: string
  icon: string
}

const IconInfo = ({ text, icon }: InfoI) => {
  useEffect(() => {
    setTimeout(() => {
      hideInfoModal()
    }, 2000)
  }, [])
  return (
    <Wrapper>
      <Icon name={icon} width={'40px'} fill={colors.white} />
      <Text>{text}</Text>
    </Wrapper>
  )
}

export default IconInfo
