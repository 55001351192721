import React from 'react'
import styled from 'styled-components'
import Clock from '../../../../../../components/Icon/Clock'
import TimePicker from 'react-timepicker-c'
import Subtitle from '../../../../Subtitle'
import './style.css'

interface TimePickerFieldProps {
  onChange: (time: string) => void
  timeValue: string
  name: string
  subtitle: string
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const TimePickerField = ({ onChange, timeValue, name, subtitle }: TimePickerFieldProps) => {
  return (
    <Container>
      <Clock />
      <Subtitle>{subtitle}</Subtitle>
      {/*// @ts-ignore*/}
      <TimePicker onChange={onChange} timeValue={timeValue} name={name} interval={15} />
    </Container>
  )
}

export default TimePickerField
