import React from 'react'
import { StyledPaymentOptions } from '../Styled'
import { colors } from '../../../../../../../assets/colors'
import { Loader } from '../../../../../../../components/Loader/Loader'

interface IProps {
  isDefaultPayment?: boolean
  loading?: boolean
}

const PreferredLabel = ({ isDefaultPayment, loading }: IProps) => {
  if (loading) {
    return (
      <StyledPaymentOptions.PreferredLabel>
        <Loader fill={colors.mediumGrey} />
      </StyledPaymentOptions.PreferredLabel>
    )
  }
  return <StyledPaymentOptions.PreferredLabel>{isDefaultPayment ? 'Default' : ''}</StyledPaymentOptions.PreferredLabel>
}

export default PreferredLabel
