import React from 'react'
import { StyledCreateContactForm } from '../../../../../../desktop/pages/Contacts/Ui/Styled/StyledCreateContactForm'
import StyledLink from '../../../../../../../components/Button/Link'
import BackArrow from '../../../../../../../components/Icon/BackArrow'
import { Button } from '../../../../../../../components/Button'
import ContactsSelect from '../ContactsSelect'
import ContactRow from '../../../../../../../components/GroupsAndContacts/ContactRow'
import { ContactItemType } from '../../../../../../../types'
import { colors } from '../../../../../../../assets/colors'
import useCreateGroup from '../../../Common/Hooks/useCreateGroup'
import { Virtuoso } from 'react-virtuoso'

interface IProps {
  contactsData?: ContactItemType[]
}

const CreateGroupForm = ({ contactsData }: IProps) => {
  const {
    history,
    createGroup,
    saving,
    setGroupName,
    setError,
    error,
    showContactsSelect,
    groupName,
    selectedContactsIds,
    toggleContactId,
    contactsSelectVisible,
    hideContactsSelect
  } = useCreateGroup()

  return (
    <div style={{ width: '100%', position: 'relative', height: '100%' }}>
      <StyledCreateContactForm.Wrapper>
        <StyledLink
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px'
          }}
          onClick={() => history.goBack()}
        >
          <BackArrow style={{ marginRight: '10px' }} />
          New group
        </StyledLink>
        <Button style={{ width: '150px', height: '40px' }} type="submit" onClick={createGroup} isLoading={saving}>
          Create
        </Button>
      </StyledCreateContactForm.Wrapper>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '20px'
        }}
      >
        <div style={{ width: '50%' }}>
          <StyledCreateContactForm.Label htmlFor="name">Group name</StyledCreateContactForm.Label>
          <StyledCreateContactForm.Input
            id="name"
            type="text"
            placeholder="Group name"
            onChange={(e) => {
              setGroupName(e.target.value)
              setError('')
            }}
            value={groupName}
          />
          {error && <p style={{ fontSize: '12px', color: colors.red, marginTop: '4px' }}>{error}</p>}
        </div>
        <StyledLink
          style={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: 600,
            fontSize: '14px'
          }}
          onClick={showContactsSelect}
        >
          <span style={{ marginRight: '10px', fontSize: '20px' }}>+</span>Add member
        </StyledLink>
      </div>
      <div style={{ height: 'calc(100% - 160px)' }}>
        <Virtuoso
          style={{ height: '100%' }}
          data={contactsData?.filter((contact) => selectedContactsIds.includes(contact.id))}
          itemContent={(_, contact) => {
            return (
              <ContactRow
                onClick={() => toggleContactId(contact.id)}
                type={'groupList'}
                nameStyle={{
                  width: '100%',
                  height: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'initial'
                }}
                wrapperStyle={{ paddingLeft: 0, paddingRight: 0 }}
                to={`/contacts?id=${contact.id}`}
                active={false}
                avatar={{
                  initials: `${contact?.first_name ? contact?.first_name?.[0] : ''}${
                    contact?.last_name ? contact?.last_name?.[0] : ''
                  }`,
                  src: contact?.photo_url && contact?.photo_url
                }}
                name={`${contact?.first_name || ''} ${contact?.last_name || ''}`}
                kateNumber={`${contact?.kate_number || ''}`}
                selectedContactsIds={selectedContactsIds}
                currentId={contact.id}
              />
            )
          }}
        />
      </div>
      {contactsSelectVisible && (
        <ContactsSelect
          data={contactsData}
          onClose={hideContactsSelect}
          toggleContactId={toggleContactId}
          selectedContactsIds={selectedContactsIds}
        />
      )}
    </div>
  )
}

export default CreateGroupForm
