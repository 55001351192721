import styled from 'styled-components'

const TermsConditionsContent = styled.div`
  padding: 12px;
  overflow: auto;
  p {
    font-size: 12px;
    line-height: 1.3;
    padding: 16px 0;
  }
`

export default TermsConditionsContent
