import React, { SVGProps } from 'react'

const Dots = (props: SVGProps<SVGSVGElement>) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.66667 8.33334C3.75 8.33334 3 9.08334 3 10C3 10.9167 3.75 11.6667 4.66667 11.6667C5.58333 11.6667 6.33333 10.9167 6.33333 10C6.33333 9.08334 5.58333 8.33334 4.66667 8.33334ZM16.3333 8.33334C15.4167 8.33334 14.6667 9.08334 14.6667 10C14.6667 10.9167 15.4167 11.6667 16.3333 11.6667C17.25 11.6667 18 10.9167 18 10C18 9.08334 17.25 8.33334 16.3333 8.33334ZM10.5 8.33334C9.58333 8.33334 8.83333 9.08334 8.83333 10C8.83333 10.9167 9.58333 11.6667 10.5 11.6667C11.4167 11.6667 12.1667 10.9167 12.1667 10C12.1667 9.08334 11.4167 8.33334 10.5 8.33334Z"
      fill={props.fill || 'white'}
    />
  </svg>
)

export default Dots
