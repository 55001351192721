import React, { SVGProps } from 'react'

const NoContacts = (props: SVGProps<SVGSVGElement>) => (
  <svg width="86" height="79" viewBox="0 0 86 79" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.3"
      d="M86 10L78.5 7.5L76 0L75.0909 9.08909L66 10L73.5 12.5L76 20L76.9091 10.9073L86 10Z"
      fill="#8E8E93"
    />
    <path opacity="0.3" d="M76 0L73.5 7.5L66 10L76 9.99818V0Z" fill="#8E8E93" />
    <path opacity="0.3" d="M76 19.9999L78.5 12.4999L86 9.99986L76 9.99805V19.9999Z" fill="#1D70B6" />
    <path
      opacity="0.3"
      d="M63.9961 72.9988L59.4974 71.4989L57.998 67L57.331 72.3328L52 72.9988L56.4987 74.4986L57.998 78.9976L58.6638 73.6661L63.9961 72.9988Z"
      fill="#8E8E93"
    />
    <path opacity="0.3" d="M57.998 78.9978L59.4974 74.4989L63.9961 72.999H57.998V78.9978Z" fill="#8E8E93" />
    <path opacity="0.3" d="M52 72.9988L56.4987 71.4989L57.998 67V72.9988H52Z" fill="#1D70B6" />
    <path
      opacity="0.3"
      d="M17.9995 27L11.1892 24.8109L9.00003 18L8.437 26.437L0 27L6.81091 29.1891L9.00003 36.0001L9.56193 27.5619L17.9995 27Z"
      fill="#8E8E93"
    />
    <path
      opacity="0.3"
      d="M8.99977 36.0007L11.189 29.1893L17.9998 27.0001L8.99921 26.9995L8.99977 36.0007Z"
      fill="#8E8E93"
    />
    <path opacity="0.3" d="M9.00063 18L6.81136 24.8114L0 27.0006L9.00007 27.0001L9.00063 18Z" fill="#8E8E93" />
    <circle cx="37.5" cy="34" r="6.3" stroke="#D5D5D7" strokeWidth="2.4" />
    <path
      d="M28.5254 47.5641C28.9535 45.8794 30.4701 44.7 32.2083 44.7H42.7917C44.5299 44.7 46.0465 45.8794 46.4746 47.5641L48.2534 54.5641C48.8638 56.966 47.0487 59.3 44.5705 59.3H30.4295C27.9513 59.3 26.1362 56.966 26.7466 54.5641L28.5254 47.5641Z"
      stroke="#D5D5D7"
      strokeWidth="2.4"
    />
    <circle cx="54.5" cy="27" r="6.3" stroke="#1D70B6" strokeWidth="2.4" />
    <path
      d="M45.5254 40.5641C45.9535 38.8794 47.4701 37.7 49.2083 37.7H59.7917C61.5299 37.7 63.0465 38.8794 63.4746 40.5641L65.2534 47.5641C65.8638 49.966 64.0487 52.3 61.5705 52.3H47.4295C44.9513 52.3 43.1362 49.966 43.7466 47.5641L45.5254 40.5641Z"
      stroke="#1D70B6"
      strokeWidth="2.4"
    />
  </svg>
)

export default NoContacts
