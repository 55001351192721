import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../assets/colors'

interface IProps {
  label: string
  count?: number
  onClick?: () => void
}

const Container = styled.div`
  display: flex;
  background-color: ${colors.mediumGrey};
  color: ${colors.white};
  border-radius: 4px;
  width: fit-content;
  padding: 8px;
  align-items: center;
`

const Text = styled.span`
  margin-right: 5px;
  font-size: 15px;
  font-weight: normal;

  @media (min-width: 1024px) {
    font-weight: lighter;
  }
`

const Tag = ({ label, count, onClick }: IProps) => {
  return (
    <Container onClick={onClick}>
      <Text>{label}</Text>
      {count && (
        <>
          <Text>|</Text>
          <Text>{count}</Text>
        </>
      )}
    </Container>
  )
}

export default Tag
