import { useUser } from '../../store/user'
import { useQuery } from '@tanstack/react-query'
import APIClient from '../../apiClient'

export const useGetReports = () => {
  const user = useUser()

  return useQuery<unknown, unknown, any>({
    queryKey: ['reports'],
    queryFn: async () => {
      const res = await APIClient.reportMonths()
      return res.data
    },
    cacheTime: 1000 * 60 * 30, // 30 minutes
    enabled: !!user?.account_number
  })
}
