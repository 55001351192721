import React, { SVGProps } from 'react'

const MessagesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="86" height="79" viewBox="0 0 86 79" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.3"
      d="M86 10L78.5 7.5L76 0L75.0909 9.08909L66 10L73.5 12.5L76 20L76.9091 10.9073L86 10Z"
      fill="#8E8E93"
    />
    <path opacity="0.3" d="M76 0L73.5 7.5L66 10L76 9.99818V0Z" fill="#8E8E93" />
    <path opacity="0.3" d="M76 20L78.5 12.5L86 9.99999L76 9.99817V20Z" fill="#1D70B6" />
    <path
      opacity="0.3"
      d="M63.9961 72.9988L59.4974 71.4989L57.998 67L57.331 72.3328L52 72.9988L56.4987 74.4986L57.998 78.9976L58.6638 73.6661L63.9961 72.9988Z"
      fill="#8E8E93"
    />
    <path opacity="0.3" d="M57.998 78.9976L59.4974 74.4986L63.9961 72.9988H57.998V78.9976Z" fill="#8E8E93" />
    <path opacity="0.3" d="M52 72.9988L56.4987 71.4989L57.998 67V72.9988H52Z" fill="#1D70B6" />
    <path
      opacity="0.3"
      d="M17.9995 27L11.1892 24.8109L9.00003 18L8.437 26.437L0 27L6.81091 29.1891L9.00003 36.0001L9.56193 27.5619L17.9995 27Z"
      fill="#8E8E93"
    />
    <path
      opacity="0.3"
      d="M8.99977 36.0007L11.189 29.1893L17.9998 27.0001L8.99921 26.9995L8.99977 36.0007Z"
      fill="#8E8E93"
    />
    <path opacity="0.3" d="M9.00063 18L6.81136 24.8114L0 27.0006L9.00007 27.0001L9.00063 18Z" fill="#8E8E93" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.0681 35.0833H23.9248C20.0196 35.2173 16.9586 38.4851 17.0798 42.3908V52.4424C17.0218 54.3287 17.7179 56.1603 19.0142 57.5318C20.3105 58.9033 22.0999 59.7016 23.9865 59.7499H38.6631C39.6788 59.7709 40.642 60.2052 41.3302 60.9524L44.984 64.8528C45.2935 65.1904 45.7285 65.3856 46.1865 65.3924C46.6595 65.3804 47.1081 65.1797 47.4324 64.8352C47.7567 64.4906 47.9298 64.0307 47.9131 63.5578V42.3908C48.0344 38.4851 44.9734 35.2173 41.0681 35.0833ZM23.9248 57.4374C21.2964 57.3044 19.2701 55.0714 19.3923 52.4424V42.3908C19.2701 39.7618 21.2964 37.5287 23.9248 37.3958H41.0682C43.6966 37.5287 45.7229 39.7618 45.6007 42.3908V62.1241L43.0877 59.3799C41.9385 58.1575 40.3409 57.456 38.6632 57.4374H23.9248Z"
      fill="#D5D5D7"
    />
    <path
      d="M30.7096 45.4895H26.3649C25.7263 45.4895 25.2086 44.9718 25.2086 44.3333C25.2086 43.6947 25.7263 43.177 26.3649 43.177H30.7096C31.3481 43.177 31.8658 43.6947 31.8658 44.3333C31.8658 44.9718 31.3481 45.4895 30.7096 45.4895Z"
      fill="#D5D5D7"
    />
    <path
      d="M26.7152 49.3437H38.2777C38.9163 49.3437 39.434 49.8613 39.434 50.4999C39.434 51.1385 38.9163 51.6562 38.2777 51.6562H26.7152C26.0767 51.6562 25.559 51.1385 25.559 50.4999C25.559 49.8613 26.0767 49.3437 26.7152 49.3437Z"
      fill="#D5D5D7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.9319 18.0833H61.0752C64.9804 18.2173 68.0414 21.4851 67.9202 25.3908V35.4424C67.9782 37.3287 67.2821 39.1603 65.9858 40.5318C64.6895 41.9033 62.9001 42.7016 61.0135 42.7499H46.3369C45.3212 42.7709 44.358 43.2052 43.6698 43.9524L40.016 47.8528C39.7065 48.1904 39.2715 48.3856 38.8135 48.3924C38.3405 48.3804 37.8919 48.1797 37.5676 47.8352C37.2433 47.4906 37.0702 47.0307 37.0869 46.5578V25.3908C36.9656 21.4851 40.0266 18.2173 43.9319 18.0833ZM61.0752 40.4374C63.7036 40.3044 65.7299 38.0714 65.6077 35.4424V25.3908C65.7299 22.7618 63.7036 20.5287 61.0752 20.3958H43.9318C41.3034 20.5287 39.2771 22.7618 39.3993 25.3908V45.1241L41.9123 42.3799C43.0615 41.1575 44.6591 40.456 46.3368 40.4374H61.0752Z"
      fill="#1D70B6"
    />
    <path
      d="M46.7223 28.4895H57.7942C58.4328 28.4895 58.9505 27.9718 58.9505 27.3333C58.9505 26.6947 58.4328 26.177 57.7942 26.177H46.7223C46.0837 26.177 45.566 26.6947 45.566 27.3333C45.566 27.9718 46.0837 28.4895 46.7223 28.4895Z"
      fill="#1D70B6"
    />
    <path
      d="M50.7166 32.3437H46.7223C46.0837 32.3437 45.566 32.8613 45.566 33.4999C45.566 34.1385 46.0837 34.6562 46.7223 34.6562H50.7166C51.3552 34.6562 51.8728 34.1385 51.8728 33.4999C51.8728 32.8613 51.3552 32.3437 50.7166 32.3437Z"
      fill="#1D70B6"
    />
  </svg>
)

export default MessagesIcon
