import styled from 'styled-components'
import { colors } from '../../../../assets/colors'

export const Title = styled.h2`
  color: ${colors.darkBlue};
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  @media (max-width: 460px) {
    font-size: 24px;
    line-height: 30px;
  }
`
