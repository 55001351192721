import React from 'react'

interface BookmarkProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const MovieCamera = ({
  style = {},
  fill = '#000',
  width = '20px',
  className = '',
  viewBox = '0 0 30 18'
}: BookmarkProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
      M2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H19C19.5304 18 20.0391 17.7893 20.4142 17.4142C20.7893 17.0391 21 16.5304 21 16V2C21 1.46957 20.7893 0.960859 20.4142 0.585786C20.0391 0.210714 19.5304 0 19 0H2ZM29 2C28.7387 1.99924 28.4874 2.10082 28.3 2.283L23 7V11L28.324 15.736L28.34 15.75C28.4844 15.8768 28.6622 15.9594 28.8523 15.9878C29.0424 16.0162 29.2366 15.9892 29.4117 15.91C29.5869 15.8309 29.7355 15.703 29.8398 15.5416C29.9441 15.3802 29.9998 15.1922 30 15V3C30 2.73478 29.8946 2.48043 29.7071 2.29289C29.5196 2.10536 29.2652 2 29 2Z
      "
    />
  </svg>
)

export default MovieCamera
