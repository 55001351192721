import React from 'react'

interface AddProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const Add = ({ style = {}, fill = '#000', width = '30px', className = '', viewBox = '0 0 30 30' }: AddProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
      M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M20,16h-4v4 c0,0.553-0.448,1-1,1s-1-0.447-1-1v-4h-4c-0.552,0-1-0.447-1-1s0.448-1,1-1h4v-4c0-0.553,0.448-1,1-1s1,0.447,1,1v4h4 c0.552,0,1,0.447,1,1S20.552,16,20,16z
"
    />
  </svg>
)

export default Add
