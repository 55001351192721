import styled from 'styled-components'
import React from 'react'
import { colors } from '../../../../assets/colors'

const Header = styled.div`
  flex-shrink: 0;
  height: 40px;
  width: 100%;
  background-color: ${colors.silver};
  color: ${colors.mediumGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
`

export default Header
