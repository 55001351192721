import styled from 'styled-components'
import { colors } from '../../../../../assets/colors'

export const TimeText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: ${colors.veryDark}
  margin-bottom: 10px;
`
