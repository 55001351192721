export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  lightBlack: '#171717',
  grey: '#292929',
  mediumGrey: '#707070',
  mediumGrey2: '#454545',
  lightGrey: '#bebebe',
  darkGrey: '#222',
  silver: '#efefef',
  lightSliver: '#dbdadf',
  shadowGrey: '#c9c9c9',
  veryLightGreen: 'rgba(31,166,139,0.1)',
  lightGreen: '#c9eae0',
  green: '#1fa68b',
  red: '#cc0000',
  red2: '#FF0000',
  red3: '#F73737',
  orange: '#eb9e34',
  kateOrange: '#F08D31',
  groupChatOrange: '#F0DAC5',
  veryLightBlue: 'rgba(63, 181, 233, 0.1)',
  veryLightBlue2: 'rgba(29, 112, 182, 0.05)',
  lightBlue: '#7AE4FD',
  lightBlue2: '#1D70B626',
  blue: '#3FB5E9',
  darkBlue: '#1D70B6',
  backgroundGrey: '#f4f4f4',
  darkBlue2: '#207AC7',
  contrastBlue: '#007AFF'
}
