import styled from 'styled-components'

const InfoContainer = styled.button`
  border: 0;
  background: none;
  outline: 0;
  padding: 0;
  height: 90%;
  width: 90%;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export default InfoContainer
