import React, { memo } from 'react'
import Contact from '../../../../../components/blocks/Contact/ContactItem'
import Icon from '../../../../../components/Icon'
import { colors } from '../../../../../assets/colors'
import Divider from '../../../../../components/Divider'
import { areEqual } from 'react-window'
import { ContactItemType, GroupItemType } from '../../../../../types'

interface IRecipientListItem {
  recipient: ContactItemType | GroupItemType
  // index: number
  onClick: () => void

  style?: {}
  isSelected: boolean
}

interface IGroupRecipient extends IRecipientListItem {
  recipient: GroupItemType
}

interface IContactRecipient extends IRecipientListItem {
  recipient: ContactItemType
}

export const GroupRecipientItem = memo(({ recipient, onClick, isSelected }: IGroupRecipient) => {
  return (
    <>
      <Contact border={false} onClick={onClick}>
        <Contact.Avatar initials={`${recipient?.name?.[0]}`} />
        <Contact.Info>
          <Contact.ContactName>{recipient?.name}</Contact.ContactName>
          <Contact.KateNumber />
        </Contact.Info>
        {isSelected ? <Icon name={'checkmark'} fill={colors.darkBlue} width={'20px'} /> : null}
      </Contact>
      <Divider marginLeft={'50px'} />
    </>
  )
})

export const ContactRecipientItem = memo(({ recipient, onClick, isSelected }: IContactRecipient) => {
  return (
    <>
      <Contact border={false} onClick={onClick}>
        <>
          <Contact.Avatar
            initials={
              recipient?.first_name && recipient?.last_name
                ? `${recipient.first_name[0]}${recipient.last_name[0]}`
                : '#'
            }
            src={recipient?.has_photo ? recipient?.photo_url : undefined}
          />
          <Contact.Info>
            <Contact.ContactName>
              {recipient?.first_name && recipient?.last_name ? `${recipient?.first_name} ${recipient?.last_name}` : ''}
            </Contact.ContactName>
            <Contact.KateNumber>
              {recipient?.kate_number || recipient?.email || recipient?.email2 || ''}
            </Contact.KateNumber>
          </Contact.Info>
        </>
        {isSelected ? <Icon name={'checkmark'} fill={colors.darkBlue} width={'20px'} /> : null}
      </Contact>
      <Divider marginLeft={'50px'} />
    </>
  )
}, areEqual)
