import { useQuery } from '@tanstack/react-query'
import { ContactItemType } from '../../types'
import APIClient from '../../apiClient'

export const useGetContact = (id) => {
  return useQuery<unknown, unknown, ContactItemType>({
    queryKey: ['contactView', id],
    queryFn: async () => {
      const res = await APIClient.contactView(id)
      return res.data
    },
    enabled: !!id,
    cacheTime: 1000 * 60 * 15 // 15 minutes
  })
}
