import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import App from './App'
import { PopupProvider } from 'react-custom-popup'
import { AuthProvider } from './providers/AuthContext'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { showAlertModal } from './store/modals'
import { Amplify } from 'aws-amplify'
import JwtService from './services/JwtService'
import { AudioPlayingProvider } from './navigation/mobile/pages/NewMessage/provider/AudioPlayingProvider'
import ErrorFallback from './ErrorFallback'
import { useUser } from './store/user'
import buildNumber from './buildNumber'
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'

new JwtService()

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_AWS_REGION // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_BUCKET, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_AWS_REGION
    },
    customPrefix: {
      public: 'uploads/'
    }
  },
  API: {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_API_URL,
    aws_appsync_region: process.env.REACT_APP_AWS_REGION,
    aws_appsync_authenticationType: 'AWS_IAM',
    // Add custom header
    graphql_headers: async () => {
      const kateToken = localStorage.getItem('kateTokenX')
      return {
        'x-kate-authorization': kateToken
      }
    },
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: process.env.REACT_APP_AWS_REGION }),
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
    })
  }
})

if (process.env.REACT_APP_ENV) {
  Sentry.init({
    dsn: 'https://daef831cce7a05d45cdbadbd1faf8dac@o160332.ingest.sentry.io/4506111734841344',
    environment: process.env.REACT_APP_ENV,
    ignoreErrors: ['Non-Error exception captured'],
    release: buildNumber
  })
}

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      Sentry.captureException(error)
      if (query?.meta?.errorMessage) {
        const message = query?.meta?.errorMessage as string
        showAlertModal(message)
      }
    }
  })
})

const AppCore = () => {
  const user = useUser()

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => {
        return <ErrorFallback error={error} resetError={resetError} user={user} />
      }}
    >
      <QueryClientProvider client={queryClient}>
        <PopupProvider>
          <AuthProvider>
            <AudioPlayingProvider>
              <App />
            </AudioPlayingProvider>
          </AuthProvider>
        </PopupProvider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  )
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<AppCore />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register()
