import React from 'react'
import { JoinNowContainer } from './styled'
import { useVideoSession } from '../../../../hooks/useVideoSession'
import AudioVideoSwitch from '../AudioVideoSwitch'
import Button from '../../../../../../components/Button'
import { useParams } from 'react-router-dom'
import { useUserStore } from '../../../../../../../store/user'
import { useVideoActions, useVideoStore, VideoStateEnum } from '../../../../../../store/video'

const JoinNow = ({ isGuestHasData }) => {
  const videoData = useVideoStore((state) => state.videoData)
  const room = useVideoStore((state) => state.room)
  const { setVideoRoomState } = useVideoActions()
  const { getRoomToken } = useVideoSession()
  const { id: meetingId } = useParams<{ id: string }>()

  const authenticated = useUserStore((state) => state.isAuthenticated)

  return (
    <>
      <JoinNowContainer>
        {authenticated && room && videoData?.sessionId ? (
          <Button
            disabled={!room?.canJoin}
            onClick={async () => {
              if (!videoData.sessionId) {
                await getRoomToken(meetingId)
              }
              setVideoRoomState(VideoStateEnum.Conference)
            }}
            style={{ width: 139, height: 46 }}
          >
            Join Now
          </Button>
        ) : null}
        <AudioVideoSwitch />
      </JoinNowContainer>
    </>
  )
}

export default JoinNow
