import styled from 'styled-components'
import React, { ReactNode } from 'react'
import Tag from './Tag'
import HiddenInput from './HiddenInput'
import { colors } from '../../../assets/colors'

const Container = styled.div`
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-bottom: 1px solid ${colors.lightGrey};
  margin-bottom: 20px;
`

interface IProps {
  children: ReactNode
}

const TagsInput = ({ children }: IProps) => <Container>{children}</Container>

TagsInput.Tag = Tag
TagsInput.HiddenInput = HiddenInput

export default TagsInput
