import React from 'react'
import { Route, RouteProps, Switch, Redirect } from 'react-router-dom'
import Header from '../../../../components/Header'
import MessagesNavigation from './components/MessagesNavigation'
import ChangeViewFooter from '../../../../containers/ChangeViewFooter'
import Folder from './components/Folder'

const MessagesPage: React.FC<RouteProps & { match: any }> = () => {
  return (
    <>
      <Header style={{ justifyContent: 'flex-end' }} />
      <MessagesNavigation />
      <Switch>
        <Route path="/messages/:type" component={Folder} />
        <Route exact path="/messages">
          <Redirect to={'/messages/new'} />
        </Route>
      </Switch>
      <ChangeViewFooter />
    </>
  )
}

export default MessagesPage
