import React from 'react'
import Header from '../../components/Header'
import { InlineButton } from '../../components/Button/InlineButton'
import { useHistory } from 'react-router'
import TermsConditionsContent from '../../components/TermsConditions'
import { logoutUser } from '../../auth/logout'

const TermsConditions = () => {
  const history = useHistory()

  const onBackToLogin = async () => {
    await logoutUser()
    history.push('/login')
  }

  const onAccept = () => {
    if (!localStorage.getItem('kateTerms')) {
      localStorage.setItem('kateTerms', 'accepted')
    }
    history.push('/')
  }

  return (
    <>
      <Header>
        <Header.SideButtons>
          <InlineButton onClick={onBackToLogin}>Login</InlineButton>
        </Header.SideButtons>
        <Header.Title>
          <span>Terms and Conditions</span>
        </Header.Title>
        <Header.EndButtons>
          <InlineButton onClick={onAccept}>Accept</InlineButton>
        </Header.EndButtons>
      </Header>
      <TermsConditionsContent>
        <h1>EXCLUSIVE END USER LICENSE AGREEMENT</h1>
        <p>
          This end user license agreement (“EULA”) is the sole and exclusive EULA and is in lieu of the license
          otherwise granted by Apple to users to perform, display, and use the software, content and digital materials
          (“Licensed Application”) distributed via the marketplace Apple has created and operates which allows World
          Wide Group, LLC (“WWG”), as a registered Developer pursuant to a certain Developer Distribution Agreement
          between Apple and WWG, to distribute Licensed Applications directly to users of any device (“Market”) that can
          access the Market (“Devices”). This EULA is concluded between WWG and end users only; and WWG, not Apple, is
          solely responsible for the Licensed Application and the content thereof.
        </p>
        <p>
          The license granted to the end-user for the Licensed Application is limited to a non-transferable license to
          use the Licensed Application on an iOS Product that the end-user owns or controls and as permitted by the
          Usage Rules set forth in the App Store Terms of Service. WWG is solely responsible for providing any
          maintenance and support services with respect to the Licensed Application, as specified in this EULA or as
          required under applicable law. WWG and the end-user hereby acknowledge that Apple has no obligation whatsoever
          to furnish any maintenance and support services with respect to the Licensed Application.
        </p>
        <p>
          WWG is solely responsible for any product warranties, whether express or implied by law, to the extent not
          effectively disclaimed. There is no purchase price for the Licensed Application required of an end-user. In
          the event of any failure of the Licensed Application to conform to any applicable warranty and to the maximum
          extent permitted by applicable law, Apple will have no warranty obligation whatsoever with respect to the
          Licensed Application, and any other claims, losses, liabilities, damages, costs or expenses attributable to
          any failure to conform to any warranty will be the sole responsibility of WWG.
        </p>

        <p>
          WWG and the end-user hereby acknowledge that WWG, not Apple, is responsible for addressing any claims of the
          end-user or any third party relating to the Licensed Application or the end-user’s possession and/or use of
          that Licensed Application, including, but not limited to: (i) product liability claims; (ii) any claim that
          the Licensed Application fails to conform to any applicable legal or regulatory requirement; and (iii) claims
          arising under consumer protection or similar legislation. This EULA does not, and may not, limit WWG’s
          liability to the end-user beyond what is permitted by applicable law.
        </p>
        <p>
          WWG and the end-user hereby acknowledge that, in the event of any third party claim that the Licensed
          Application or the end-user’s possession and use of that Licensed Application infringes that third party’s
          intellectual property rights, WWG, not Apple, will be solely responsible for the investigation, defense,
          settlement and discharge of any such intellectual property infringement claim.
        </p>
        <p>
          The end-user hereby represents and warrants that (i) the end user is not located in a country that is subject
          to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting”
          country; and (ii) the end user is not listed on any U.S. Government list of prohibited or restricted parties.
        </p>
        <p>
          Except as otherwise provided by (i) this EULA, (ii) the Standard Terms and Conditions and (iii) the Service
          Agreement applicable to, your CommuniKate service (collectively, the “CommuniKate Agreement”), as published by
          Parus Holdings, Inc. (“Parus”) from time to time (“CommuniKate Services”), WWG, as a sub-licensor of Parus,
          grants you for so long as you are a registered user with Parus of the CommuniKate Services in good standing,
          or any successor service(s) thereto, a personal, non-exclusive, non-transferable, restricted and
          nontransferable license to access and use the Licensed Application and CommuniKate Services for authorized
          purposes as a Parus registered user. You shall not modify, translate, reproduce, distribute, or create
          derivative works of the Licensed Application, the CommuniKate Services or any part thereof. You shall not
          rent, lease, loan, sell, sublicense, assign or otherwise transfer any rights in the Licensed Application or
          the CommuniKate Services. WWG, Parus and/or Parus’ licensor(s), retain their respective ownership of the
          Licensed Application itself and CommuniKate Services themselves and any copies or portions thereof, as their
          interests may subsist. The Licensed Application is licensed, not sold, to you by WWG for use only under this
          CommuniKate Agreement in connection with your authorized use of the CommuniKate Services, and Parus reserves
          all rights not expressly granted to you. Your rights under this license to use and access the Licensed
          Application and the CommuniKate Services will terminate automatically without notice from Parus and/or WWG if
          you fail to comply with any of these provisions. Parus may terminate or suspend you as a registered Parus user
          of the CommuniKate Services at any time in Parus’ sole discretion. If Parus terminates you as a registered
          Parus user, Parus reserves the right to deny your reapplication at any time in Parus’ sole discretion. You may
          terminate your participation as a registered Parus user at any time, for any reason, by notifying Parus in
          writing as provided in the Agreement of your intent to do so. Upon any termination or, at Parus’ discretion,
          suspension, all rights and licenses granted to you by Parus and WWG will cease, including your right to access
          the Licensed Application subject to this CommuniKate Agreement.
        </p>
        <p>
          Any end-user questions, complaints or claims with respect to the Licensed Application should be directed to
          WWG at KateWWDB, 3000 Lakeside Drive, Suite 200N, Bannockburn, IL 60015, phone number 800-760-5176, and email
          Support@KateWWDB.com.
        </p>
        <p>
          Neither any nor all of the rights granted herein nor any interest therein may be sold, assigned, conveyed,
          delegated, sublicensed, or otherwise transferred or encumbered by you, a registered Parus user, at any time.
          All licenses not expressly granted in this CommuniKate Agreement are reserved and no other licenses, immunity
          or rights, express or implied are granted by Parus or WWG, by implication, estoppel, or otherwise. This
          CommuniKate Agreement does not grant you any rights to use any trademarks, logos or service marks belonging to
          Parus, including but not limited to the CommuniKate or CommuniKate word or design marks. All license rights
          granted to you hereunder are personal to you only and may be subject to one or more of the following Parus
          patents: 6,775,264; 6,807,257; 7,327,723; 8,098,600; 6,721,705; 7,076,431; 7,386,455; 7,881,941; 8,185,402;
          7,561,190; CAD 2321697; EP 106671481. Other U.S. and international patents are pending.
        </p>
      </TermsConditionsContent>
    </>
  )
}

export default TermsConditions
