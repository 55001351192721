import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
// import AccountLockedForBillingPage from '../mobile/pages/AccountLockedForBilling'
// import AccountUnlockForBillingComplete from '../mobile/pages/AccountUnlockForBillingComplete'
import AccountLockedPage from '../mobile/pages/AccountLocked'

export const AccountLockedRouter = () => {
  return (
    <Switch>
      <Route path={'/account-locked'} component={AccountLockedPage} />
      {/*<Route path={'/billing-locked'} component={AccountLockedForBillingPage} />*/}
      {/*<Route path={'/billing-unlock'} component={AccountUnlockForBillingComplete} />*/}
      <Route path="*">
        <Redirect to={'/account-locked'} />
      </Route>
    </Switch>
  )
}
