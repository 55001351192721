import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Label } from './Label'
import { StyledInput } from './StyledInput'

interface IProps {
  children?: ReactNode
  style?: { [key: string]: any }
}

const Wrapper = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  min-height: 40px;
`

const Input = ({ children, style }: IProps) => <Wrapper style={style}>{children}</Wrapper>

Input.Label = Label
Input.StyledInput = StyledInput

export default Input
