import React, { PropsWithChildren, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Logo from '../../assets/images/communikate_logo_blue.svg'
import Login from '../../components/blocks/Login'
import Image from '../../components/blocks/Login/Image'
import { Formik, FormikProps } from 'formik'
import Input from '../../components/Input'
import Form from '../../components/Input/Form'
import StyledLink from '../../components/Button/Link'
import { Button } from '../../components/Button'
import { loginUser } from '../../auth/login'
import * as Sentry from '@sentry/react'
import { showAlertModal } from '../../store/modals'
import { useUserActions } from '../../store/user'
import { LockReason } from '../../services/AccountLockService'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import Patents from './Patents'

// Shape of form values
interface FormValues {
  login: string
  password: string
}

interface ILoginUser {
  success?: boolean
  errorMessage?: string
  locked?: boolean
  accountLockReason?: string
}

const OkButton = styled.button`
  font-weight: bold;
  font-size: 16px;
  color: #000000;
`

const LoginPage: React.FC<PropsWithChildren<RouteComponentProps>> = (props) => {
  const { setAuthenticated, fetchUser } = useUserActions()
  const [error, setError] = useState<LockReason>(null)

  const validate = (values: FormValues) => {
    const errors = []
    if (!values.login) {
      errors.push('Login')
    }
    if (!values.password) {
      errors.push('Password')
    }

    const errorMessage = `Please enter ${errors.join(', ')}`
    if (Object.keys(errors).length) showAlertModal(errorMessage)
    return !Object.keys(errors).length
  }

  const submitLogin = async (
    values: { login: string; password: string },
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      let valid: boolean
      valid = validate(values)
      if (!valid) return false
      const username = values.login.trim().toLowerCase()
      const password = values.password

      Sentry.setUser({ username })

      const userLoggedIn: ILoginUser = await loginUser({ username, password })

      if (userLoggedIn.locked && userLoggedIn.accountLockReason === LockReason.BILLING_LOCK) {
        setAuthenticated(true)

        return (window.location.href = '/account-locked')
      }

      if (!userLoggedIn.success && userLoggedIn.errorMessage && userLoggedIn.accountLockReason === LockReason.GENERAL) {
        return setError(LockReason.GENERAL)
      }

      if (!userLoggedIn.success && userLoggedIn.errorMessage) {
        const { errorMessage } = userLoggedIn
        return showAlertModal(errorMessage)
      }

      setSubmitting(false)

      await fetchUser()
      setAuthenticated(true)

      if (localStorage.getItem('kateTerms')) {
        const item = localStorage.getItem('kateTerms')
        if (item && item === 'accepted') {
          props.history.push('/')
        }
      }

      props.history.push('/termsAndConditions')
    } catch (e) {
      if (!e.toString().includes('401') && !e?.name?.toLowerCase().includes('axios')) {
        showAlertModal(`Something's wrong. ${e.toString()}`)
      }
      Sentry.captureException(e, {
        tags: {
          page: 'Login - on login'
        }
      })
      console.log('error: ', e)
    }
  }

  const renderForm = (props: FormikProps<FormValues>) => {
    const { values, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset } = props
    return (
      <Form autoComplete="off" onReset={handleReset} onSubmit={handleSubmit}>
        <Input>
          <Input.StyledInput
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            name="login"
            placeholder="Kate Email or Kate Number"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.login}
          />
        </Input>
        <Input>
          <Input.StyledInput
            autoComplete="off"
            name="password"
            placeholder="CommuniKate Password"
            type="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />
        </Input>
        <Login.HelpSection style={{ textAlign: 'right' }}>
          <StyledLink href="https://reset2.securekate.com" target="_blank">
            Login Problems?
          </StyledLink>
        </Login.HelpSection>
        <Button isLoading={isSubmitting} type="submit">
          Log In
        </Button>
      </Form>
    )
  }

  const accountLockedInfo = () => {
    return (
      <>
        <Typography fontWeight={'bold'} textAlign={'center'}>
          Your account is locked.
        </Typography>
        <Typography textAlign={'center'} marginTop={'15px'} marginBottom={'10px'}>
          Please click OK to reset your password and unlock your account
        </Typography>
        <Button
          onClick={() => {
            return (window.location.href = 'https://reset2.securekate.com/')
          }}
          style={{ marginTop: 20 }}
        >
          OK
        </Button>
      </>
    )
  }

  return (
    <Login>
      <Login.Content>
        <Login.Header>
          <Image src={Logo} alt="CommuniKate" />
        </Login.Header>
        <Formik
          initialValues={{ login: '', password: '' }}
          onSubmit={(values, { setSubmitting }) => submitLogin(values, setSubmitting)}
        >
          {error === LockReason.GENERAL ? accountLockedInfo : renderForm}
        </Formik>
      </Login.Content>
      <Login.Copyright>
        <Patents />
      </Login.Copyright>
    </Login>
  )
}

export default LoginPage
