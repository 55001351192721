import React, { useState } from 'react'
import styled from 'styled-components'
import Dots from '../../../../../components/Icon/Dots'
import { Link, useParams } from 'react-router-dom'

const IconContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  min-width: 50px;
  opacity: 0.92;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
  @media (max-width: 599px) {
    min-width: 30px;
    max-width: 30px;
    max-height: 20px;
  }
`

const StyledText = styled.a`
  font-size: 15px;
  margin-top: 6px;
  color: white;
`

const StyledButton = styled(Link)`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  font-weight: 600;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
`

const More = () => {
  const { id: meetingId } = useParams<{ id: string }>()
  const [menu, setMenu] = useState(false)

  return (
    <div style={{ position: 'relative' }}>
      <IconContainer onClick={() => setMenu((prev) => !prev)}>
        <Dots />
        <StyledText>More</StyledText>
      </IconContainer>

      {menu && (
        <div
          style={{
            position: 'absolute',
            bottom: '50px',
            left: '-100px',
            width: '300px',
            height: '40px',
            background: '#292929',
            borderRadius: '15px',
            zIndex: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <StyledButton to={`/stream-conference/${meetingId}`} target={'_blank'}>
            Live on Custom Live Streaming Service
          </StyledButton>
        </div>
      )}
    </div>
  )
}

export default More
