import React from 'react'
import styled from 'styled-components'
// import { Duration } from '../../../../pages/MeetingRoom/useTime'
import Subtitle from '../../../../Subtitle'
import './style.css'

// TODO: Temporary not used component. To remove later.

interface DurationPickerFieldProps {
  onChange: React.ChangeEventHandler<HTMLSelectElement> | undefined
  name: string
  subtitle: string
  value: number
  // durations: Duration[]
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const SelectBox = styled.select`
  background: rgba(23, 23, 23, 0.05);
  border-radius: 10px;
  border-color: rgba(23, 23, 23, 0.05);
  width: 82px;
  height: 42px;
  margin-left: 5px;
  margin-right: 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  text-align-last: center;
  -moz-text-align-last: center;
`

const DurationPickerFragment = ({
  name,
  subtitle,
  value,
  onChange
}: // durations
DurationPickerFieldProps) => {
  return (
    <Container>
      <Subtitle>{subtitle}</Subtitle>
      <SelectBox name={name} value={value} onChange={onChange}>
        {/*{durations.map((duration: Duration) => (*/}
        {/*  <option key={duration.value} value={duration.value}>*/}
        {/*    {duration.text}*/}
        {/*  </option>*/}
        {/*))}*/}
      </SelectBox>
    </Container>
  )
}

export default DurationPickerFragment
