import React, { ReactNode, useRef, useState } from 'react'
import styled, { CSSObject } from 'styled-components'
import { colors } from '../../../assets/colors'
import LinkIcon from '../../../components/Icon/LinkIcon'
import PlusLight from '../../../components/Icon/PlusLight'
import DropdownList from './components/DropdownList'
import DropdownListItem from './components/DropdownListItem'
import IconLeft from './components/IconLeft'
import IconRight from './components/IconRight'
import { useHistory } from 'react-router'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { useVideoSession } from '../../pages/Video/hooks/useVideoSession'
import { Loader } from '../../../components/Loader/Loader'
import Icon from '../../../components/Icon'
import Spinner from '../../../components/Icon/Spinner'

const StyledDropDownContainer = styled.div`
  display: inline-flex;
  position: relative;
  z-index: 1000;
`

const StyledDropDownButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(224.26deg, #3fb5e9 -23.22%, #1d70b6 123.1%);
  color: ${colors.white};
  text-transform: capitalize;
  border: 0;
  font-size: 0.875rem;
  border-radius: 30px;
  padding: 15px 30px;
  cursor: pointer;
  position: relative;

  &:hover {
    background: linear-gradient(224.26deg, #39a8d7 -23.22%, #165f97 123.1%);
  }

  &:active {
    background: linear-gradient(224.26deg, #3fb5e9 -23.22%, #1d70b6 123.1%);
    opacity: 0.8;
  }
`

const StyledDiv = styled.div`
  cursor: pointer;
  opacity: 0.95;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

type DropDownButtonProps = {
  children: ReactNode
  onClick?: (e: any) => void
  type?: 'submit' | 'reset' | 'button'
  style?: CSSObject
}

const DropDownButton: React.FC<DropDownButtonProps> = ({ children, ...props }) => {
  const history = useHistory()
  const ref = useRef()
  const { start, isLoading } = useVideoSession()

  const [dropdownVisible, setDropdownVisible] = useState(false)

  const toggleDropdown = () => setDropdownVisible((value) => !value)
  useOnClickOutside(ref, () => setDropdownVisible(false))

  return (
    <StyledDropDownContainer {...props} ref={ref}>
      <StyledDropDownButton onClick={toggleDropdown}>
        <IconLeft />
        {children}
        <IconRight />
      </StyledDropDownButton>
      {dropdownVisible && (
        <DropdownList>
          <DropdownListItem onClick={() => history.push('/meeting')}>
            <DropdownListItem.ListIconContainer>
              <LinkIcon />
            </DropdownListItem.ListIconContainer>
            <div>Create a meeting for later</div>
          </DropdownListItem>
          <DropdownListItem
            onClick={() => {
              if (isLoading) return
              start()
            }}
          >
            <DropdownListItem.ListIconContainer>
              {isLoading ? <Spinner width={'24px'} fill={colors.mediumGrey} /> : <PlusLight />}
            </DropdownListItem.ListIconContainer>
            <StyledDiv>Start an instant meeting</StyledDiv>
          </DropdownListItem>
        </DropdownList>
      )}
    </StyledDropDownContainer>
  )
}

export default DropDownButton
