import React from 'react'
import styled from 'styled-components'
import Icon from '../../../Icon'
import { colors } from '../../../../assets/colors'

const StyledButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

interface IProps {
  onClick: () => void
  name: string
  label?: string
}

const StyledSpan = styled.span`
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  color: ${colors.mediumGrey};
`

const Button = ({ onClick, name, label }: IProps) => (
  <StyledButton onClick={onClick}>
    <Icon name={name} width={'30px'} fill={colors.mediumGrey} />
    {label && <StyledSpan>{label}</StyledSpan>}
  </StyledButton>
)

export default Button
