import styled from 'styled-components'
import { Button } from '../../../../components/Button'

const NewActionButton = styled(Button)`
  height: 36px;
  width: 125px;
  padding: 12px 10px;
`

export default NewActionButton
