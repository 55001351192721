import React from 'react'

interface MenuProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const Menu = ({ style = {}, fill = '#000', width = '30px', className = '', viewBox = '0 0 30 30' }: MenuProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill={fill} d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
  </svg>
)

export default Menu
