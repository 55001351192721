import React, { SVGProps } from 'react'

const NoPhones = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.960947 -0.000244141L-0.000244141 0.960947L19.0375 20L20 19.0375L9.48701 8.52518C10.0363 8.01896 10.5899 7.5545 10.9677 7.25962C11.1066 7.15108 11.2751 7.08728 11.4511 7.07666C11.627 7.06604 11.802 7.1091 11.9529 7.20014L13.3451 8.02908C13.4798 8.10955 13.6337 8.15235 13.7906 8.15302C13.9476 8.15369 14.1018 8.1122 14.2373 8.03288L17.722 5.99027C17.886 5.89444 18.0145 5.7481 18.0885 5.57313C18.1624 5.39816 18.1777 5.20397 18.132 5.01959C17.9911 4.50306 17.7099 4.03567 17.3195 3.66924C15.7907 2.14045 13.2343 1.32796 7.66524 6.70658L0.960947 -0.000244141ZM5.79095 8.64098C1.41465 13.5456 2.23283 15.8869 3.6705 17.3202C4.03746 17.7113 4.50583 17.9928 5.02339 18.1333C5.20694 18.1789 5.40033 18.1638 5.57461 18.0904C5.7489 18.0169 5.89472 17.889 5.99027 17.7258L8.03541 14.2316C8.11464 14.0966 8.15609 13.9427 8.15542 13.7862C8.15475 13.6297 8.11199 13.4762 8.03162 13.3419L7.20141 11.9498C7.11036 11.7989 7.0673 11.6238 7.07792 11.4479C7.08855 11.272 7.15235 11.1034 7.26089 10.9645C7.36783 10.8279 7.49818 10.6652 7.64056 10.4906L5.79095 8.64098Z"
      fill="#ffffff"
    />
  </svg>
)

export default NoPhones
