import { useFormik } from 'formik'
import { ContactItemType, GroupItemType, Recipient } from '../../../../../types'
import { useContactGroupListQuery } from '../../../../../hooks/queries/useContactGroupListQuery'
import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { showAlertModal, showInfoModal } from '../../../../../store/modals'
import APIClient from '../../../../../apiClient'
import { useNewMessageActions, useNewMessageStore } from '../store/NewMessageStore'
import { useHistory } from 'react-router'
import { useAudioPlayingContext } from '../provider/AudioPlayingProvider'

interface IFormDataRecipient {
  data?: string
  group_id?: string
  link_account_type: number
}

// Shape of form values
interface FormValues {
  recipients: IFormDataRecipient[]
  note: string
  mp3File?: File | null
  urgent?: boolean
  confidential?: boolean
  message_id?: string
}

const sendVoiceMessage = async (formData: FormData) => {
  try {
    await APIClient.voiceMessageSubmit(formData)
  } catch (e: any) {
    console.log('error on sendVoiceMessage:', e)
    if (!e.toString().includes('401') && !e?.name?.toLowerCase().includes('axios')) {
      showAlertModal(`Something's wrong. ${e.toString()}`)
    }
  }
}
interface IUseForm {
  recipients: Recipient[]
  mp3File: File | null
  isUrgent: boolean
  imageFile: File | null
}

export const useForm = () => {
  const history = useHistory()
  const [isLoadingSend, setIsLoadingSend] = useState(false)
  const location = useLocation<{ forwardId?: string }>()
  const { data: contacts } = useContactGroupListQuery()
  const { clear, setIsConfidential } = useNewMessageActions()
  const { setAudio } = useAudioPlayingContext()
  const forwardId = useMemo(() => location.state && location.state.forwardId, [location.state])

  const recipients = useNewMessageStore((state) => state.recipients)
  const mp3File = useNewMessageStore((state) => state.mp3File)
  const isUrgent = useNewMessageStore((state) => state.isUrgent)
  const imageFile = useNewMessageStore((state) => state.imageFile)
  const isConfidential = useNewMessageStore((state) => state.isConfidential)

  const formik = useFormik({
    initialValues: { recipients: [], note: '' },
    onSubmit: async (values) => {
      try {
        setIsLoadingSend(true)
        const recipientsFormData: IFormDataRecipient[] = recipients.map((recipient) => {
          if (recipient.isGroup) return { group_id: recipient.id, link_account_type: 0 }

          const contact: ContactItemType = contacts.find((item) => item.id === recipient.id)

          return {
            data: contact.kate_number || contact.email || contact.email2,
            link_account_type: 0
          }
        })

        const data = { ...values, mp3File, recipients: recipientsFormData, message_id: forwardId }
        const valid = validate(data)

        if (valid && (mp3File || forwardId)) {
          const formData = new FormData()
          formData.append('attached_files', mp3File || forwardId)

          if (imageFile) {
            formData.append('photo', imageFile)
          }

          const params: FormValues = {
            recipients: recipientsFormData,
            note: values.note,
            urgent: isUrgent,
            confidential: isConfidential,
            message_id: forwardId
          }

          formData.append('jsonData', JSON.stringify(params))
          await sendVoiceMessage(formData)
          clear()
          setAudio(null)
          setIsConfidential(false)
          history.push('/')
          showInfoModal(`Message sent`)
        }
      } catch (e) {
        console.log('error onSubmit: ', e)
        showAlertModal(`Something's wrong. ${e.toString()}`)
      } finally {
        setIsLoadingSend(false)
      }
    }
  })

  return {
    ...formik,
    isLoadingSend
  }
}

const validate = (values: FormValues) => {
  const errors = []
  if (!values.recipients.length) {
    errors.push('To')
  }
  if (!values.mp3File && !values.message_id) {
    errors.push('Voice message')
  }

  const errorMessage = `Please enter ${errors.join(', ')}`
  if (Object.keys(errors).length) {
    showAlertModal(errorMessage)
  }
  return !Object.keys(errors).length
}
