import { DocumentNode } from 'graphql/language/ast'
import { API } from 'aws-amplify'
import tokenManager from '../../services/TokenManager'
import retry from 'async-retry'

const appSyncClient = {
  graphql: async ({ query, variables }: { query: string | DocumentNode; variables?: { [key: string]: any } }) => {
    return await retry(
      async () => {
        // @ts-ignore
        return API.graphql({ query, variables })
      },
      {
        maxTimeout: 3500,
        minTimeout: 1000,
        onRetry: async (e: any) => {
          console.log('e', e?.errors)
          if (e?.errors) {
            const is401 = e?.errors?.find((e) => e?.errorType?.includes('401'))
            if (is401) {
              await tokenManager.refreshKateToken()
              return
            }
            throw new Error(`Graphql error: ${e?.errors[0]?.errorType || ''} ${e?.errors[0]?.message}`)
          }
        }
      }
    )
  }
}

export default appSyncClient
