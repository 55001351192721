import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import { getMessagesWithTags } from '../../../../mobile/pages/TagMessages'
import { colors } from '../../../../../assets/colors'
import { Virtuoso } from 'react-virtuoso'
import { useGetMessagesById } from '../../../../../hooks/queries/useGetMessagesById'
import ArrowLeft from '../../../../../components/Icon/ArrowLeft'
import Spinner from '../../../../../components/Icon/Spinner'
import TagSparkles from '../../../../../components/Icon/TagSparkles'
import TextNote from '../../../components/TextNote'
import AudioPlayer from '../../Messages/containers/AudioPlayer'
import ActionButtons from '../../Messages/components/MessageView/components/ActionButtons'
import { useAudioMessageActions, useAudioMessageStore } from '../../../../../store/audioMessage'
import { shallow } from 'zustand/shallow'
import { Box, Typography } from '@mui/material'

const TagMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 58px;
  min-height: 58px;
  cursor: pointer;
`
const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`
const Name = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: ${colors.lightBlack};
`

const MessageDuration = styled.span`
  padding: 0 10px;
  width: 50px;
  color: ${colors.mediumGrey};
`
const Image = styled.img`
  width: 86px;
  height: 49px;
  border-radius: 10px;
  object-fit: cover;
`

const Line = styled.div`
  height: 1px;
  background: rgba(112, 112, 112, 0.2);
  width: auto;
  margin-left: 50px;
`

const BackButton = styled.button`
  border: none;
  background: transparent;
  color: ${colors.darkBlue};
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;

  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;

  span {
    margin-left: 5px;
  }
`

const SenderContainer = styled.div`
  display: flex;
  align-items: center;

  font-size: 15px;

  padding: 10px;

  span {
    margin-left: 5px;
  }

  #to {
    font-weight: 600;
  }
`

const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
`

const NoTagMessage = () => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <TagSparkles />
      <TextNote style={{ maxWidth: 300, textAlign: 'center' }}>
        You didn’t tag messages yet. Tag some messages your Inbox to show up here.
      </TextNote>
    </div>
  )
}

const MessageList = ({ data, isLoading }) => {
  const history = useHistory()
  const { type: activeTab, tag } = useParams<{ type?: string }>()
  // Get Id from query params
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const id = params.get('id')

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
        <Spinner fill={colors.darkBlue} width={'35px'} />
      </div>
    )
  }

  if (id) {
    return <MessagePlayerView id={id} />
  }

  if (!data?.length && !id) {
    return <NoTagMessage />
  }

  return (
    <Virtuoso
      style={{ height: '100%' }}
      data={data as any}
      itemContent={(index, message) => {
        let names
        if (tag === 'Sent Items' || tag === 'SavedSent') {
          names = message?.recipients
            ? message?.recipients
                .map((recipient) => {
                  if (recipient?.first_name || recipient?.last_name) {
                    return `${recipient?.first_name || ''} ${recipient?.last_name || ''}`
                  }
                  return recipient?.kate_number
                })
                .join(', ')
            : ''
        } else {
          names =
            message?.contact?.first_name || message?.contact?.last_name
              ? `${message?.contact?.first_name || ''} ${message?.contact?.last_name || ''}`
              : message?.source_kate_account?.first_name || message?.source_kate_account?.last_name
              ? `${message?.source_kate_account?.first_name || ''} ${message?.source_kate_account?.last_name || ''}`
              : message?.source
        }

        const initials = names
          .split(' ')
          .map((n) => n[0])
          .join('')
          .slice(0, 2)

        return (
          <TagMessageContainer
            key={message?.uniqueId}
            onClick={() => history.push(`/tags/${activeTab}/${tag}?id=${message?.uniqueId}`)}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {message?.contact?.photo_url ? (
                  <Avatar src={message?.contact?.photo_url} />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 40,
                      height: 40,
                      backgroundColor: '#1D70B626',
                      borderRadius: 20
                    }}
                  >
                    <div style={{ color: '#1D70B6', fontSize: 13 }}>{initials}</div>
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0 10px',
                    height: '100%',
                    justifyContent: 'center'
                  }}
                >
                  <Name>{names}</Name>
                  <TextNote>{message?.note}</TextNote>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                {message?.photo_url ? <Image src={message?.photo_url} /> : null}
                <MessageDuration>{message?.length}s</MessageDuration>
              </div>
            </div>
            <Line />
          </TagMessageContainer>
        )
      }}
    />
  )
}

const MessagePlayerView = ({ id }) => {
  const history = useHistory()
  const { tag, type } = useParams<{ type?: string }>()
  const { data: message, isLoading } = useGetMessagesById(id)

  const loadingAudioMessage = useAudioMessageStore((state) => state.loading, shallow)

  const tagWithHash = `#${tag}`

  const { data } = getMessagesWithTags(tagWithHash)

  const {
    setAudioPlaying,
    setAudioSeek,
    setAudioMessageId,
    setCurrentMessageId,
    setAudioSpeed,
    setAudioCurrentTime
  } = useAudioMessageActions()

  useEffect(() => {
    if (message) {
      setCurrentMessageId(message.uniqueId)
      setAudioMessageId(message.uniqueId)
    }
    return () => {
      setAudioMessageId(null)
      setCurrentMessageId(null)
      setAudioPlaying(false)
      setAudioCurrentTime(message?.uniqueId, 0)
    }
  }, [message])

  const messageTagData = data?.find((messageTag) => messageTag?.uniqueId === message?.uniqueId)

  if (isLoading || loadingAudioMessage) {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spinner fill={colors.darkBlue} width={'50px'} />
      </div>
    )
  }

  const name =
    message?.contact?.first_name || message?.contact?.last_name
      ? `${message?.contact?.first_name || ''} ${message?.contact?.last_name || ''}`
      : message?.source_kate_account?.first_name || message?.source_kate_account?.last_name
      ? `${message?.source_kate_account?.first_name || ''} ${message?.source_kate_account?.last_name || ''}`
      : message?.source

  const recipientName = messageTagData?.recipients?.map((recipient) =>
    recipient?.first_name || recipient?.first_name
      ? `${recipient?.first_name || ''} ${recipient?.last_name || ''}`
      : recipient?.kate_number
  )

  const visibleName = tag.includes('Sent') ? recipientName : name
  const nameOfReceivers = Array.isArray(visibleName) ? visibleName?.join(', ') : visibleName

  const initials = visibleName?.[0]
    .split(' ')
    .map((n) => n[0])
    ?.join('')

  return (
    <div style={{ height: '95%' }}>
      <div style={{ height: '20%' }}>
        <BackButton onClick={() => history.push(`/tags/${type}/${tag}`)}>
          <ArrowLeft width={'14px'} fill={colors.darkBlue} />
          <span>{tag}</span>
        </BackButton>
        <SenderContainer>
          {message?.contact?.photo_url ? (
            <Avatar src={message?.contact?.photo_url} />
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 40,
                height: 40,
                backgroundColor: '#1D70B626',
                borderRadius: 20
              }}
            >
              <div style={{ color: '#1D70B6' }}>{initials}</div>
            </div>
          )}
          <div>
            <span id={'to'}>to:</span>
            <Name>{nameOfReceivers}</Name>
          </div>
        </SenderContainer>
        <Line style={{ marginLeft: 0 }} />
        <Box width={'100%'} p={2} height={'70px'}>
          {message?.note && (
            <Box>
              <Typography maxHeight={'70px'} sx={{ overflowY: 'scroll' }}>
                {message?.note}
              </Typography>
            </Box>
          )}
        </Box>
      </div>
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <AudioPlayer />
      </div>
    </div>
  )
}

const MessageTagView = () => {
  const { tag } = useParams<{ tag?: string }>()

  const tagWithHash = `#${tag}`

  const { data, isLoading } = getMessagesWithTags(tagWithHash)

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', margin: 10 }}>
      <MessageList data={data} isLoading={isLoading} />
    </div>
  )
}

export default MessageTagView
