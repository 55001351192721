import styled from 'styled-components'
import { colors } from '../../assets/colors'

const LongTextInput = styled.input`
  border: none;
  background-color: transparent;
  color: ${colors.lightGrey};
  outline: none;
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 12px;
  padding: 5px 10px calc(100% - 17px) 10px;
  ::placeholder {
    width: 100%;
    text-align: center;
  }
  :placeholder-shown {
    padding: 5px 10px;
  }
`

export default LongTextInput
