import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../../../assets/colors'
import Avatar from '../../../../../components/blocks/VideoRoom/components/Avatar'

const Background = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: ${colors.grey};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  z-index: 1;
`

const BiggerCircle = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 40px;
  left: calc(50% - 80px / 2 + 0.5px);
  top: calc(50% - 80px / 2 - 0.5px);
  background: ${colors.mediumGrey};
  backdrop-filter: blur(33.3333px);
  display: flex;
  justify-content: center;
  align-items: center;
`

const CircleText = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: ${colors.white};
`

interface IProps {
  name: string
  avatar?: string
}

const NoVideoViewPublisher: React.FC<IProps> = (props: IProps) => {
  return (
    <>
      <Background>
        {props.avatar !== 'null' && props.avatar ? (
          <Avatar src={props.avatar} alt={props.name} name={''} style={{ width: 60, height: 60 }} />
        ) : (
          <BiggerCircle>
            <CircleText>{props?.name?.[0]?.toUpperCase()}</CircleText>
          </BiggerCircle>
        )}
      </Background>
    </>
  )
}

export default NoVideoViewPublisher
