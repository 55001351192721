// @ts-ignore
import sha1 from 'js-sha1'
import { useEffect, useState } from 'react'
import APIClient from '../apiClient'
import { MessageType } from '../types'
import { client } from '../client'
import { IUserState } from '../types/user'
import * as Sentry from '@sentry/react'

interface IGetHighQualityAudioSrc {
  filename: string | undefined
  hqa: boolean | undefined
  aws_api_endpoint: string
  katemsg_api_key: string
  jwt: string
}

function useGetAudioSrc(message: MessageType, user: IUserState, messageInAudioSrcList: boolean) {
  const [audioSrc, setAudioSrc] = useState<string>(null)

  useEffect(() => {
    async function getHighQualityAudioSrc({
      hqa,
      filename,
      aws_api_endpoint,
      katemsg_api_key,
      jwt
    }: IGetHighQualityAudioSrc) {
      try {
        // check for high quality audio
        if (hqa && filename && aws_api_endpoint) {
          // get audio from aws
          const hqaData = {
            aws_api_endpoint: `${aws_api_endpoint}getmessage?no_redirect=true`,
            katemsg_api_key,
            jwt,
            message_id: sha1(filename)
          }
          const { data } = await APIClient.highQualityAudio(hqaData)
          if (data && data.Location) await client.get(data.Location)
          return data
        }
        return false
      } catch (e) {
        console.log('error on getHiqhQualityAudio: ', e)
        Sentry.captureException(e, {
          tags: {
            page: 'useGetAudioSrc - getHighQualityAudioSrc'
          }
        })
        return false
      }
    }

    async function getAudioSrc(message: MessageType) {
      try {
        if (!message) return null
        // Get High Quality Audio
        const { katemsg_api_key, jwt, hqa } = user
        const aws_api_endpoint = user?.aws_api_endpoint
        const hqaPlayback = await getHighQualityAudioSrc({
          hqa,
          aws_api_endpoint,
          katemsg_api_key,
          jwt,
          filename: message.filename
        })
        if (hqaPlayback) return hqaPlayback.Location

        // download standard audio from api
        // @ts-ignore
        const { data } = await APIClient.standardAudio(message.uniqueId)
        if (!data) return null
        const mp3 = new Blob([data], { type: 'audio/mpeg' })
        // @ts-ignore
        return window.URL.createObjectURL(mp3)
      } catch (e: any) {
        console.log('Error on getAudio: ', e)
        Sentry.captureException(e, {
          tags: {
            page: 'useGetAudioSrc - getAudioSrc'
          }
        })
        // if (!e.toString().includes('401') && !e?.name?.toLowerCase().includes('axios')) {
        //   showAlertModal(`Something's wrong. ${e.toString()}`)
        // }
      }
    }

    async function requestAudio(message: MessageType) {
      if (!message || messageInAudioSrcList) return
      const src = await getAudioSrc(message)
      if (src) setAudioSrc(src)
    }
    if (!message || messageInAudioSrcList) return

    requestAudio(message)
  }, [message, messageInAudioSrcList])

  return audioSrc
}

export default useGetAudioSrc
