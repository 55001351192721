import React from 'react'
import { useSession } from '../../../providers/SessionProvider'
import styled, { css } from 'styled-components'
import AudioCircle from '../../../../../components/blocks/VideoRoom/components/AudioCircle'
import NoVideoViewPublisher from './NoVideoViewPublisher'
import NameContainer from '../../../../../components/blocks/VideoRoom/components/NameContainer'
import { useVideoStore } from '../../../../../store/video'

const isSharingScreenStyles = css`
  display: none;
  width: 100%;
  height: 18%;
  max-width: 100%;
  margin: 15px 5px 5px 10px;

  @media (min-width: 1024px) {
    height: 18%;
  }

  @media (min-width: 1281px) {
    height: 21%;
  }

  @media (min-width: 1367px) {
    height: 23%;
  }

  @media (min-width: 1601px) {
    height: 25%;
  }

  @media (min-width: 1921px) {
    height: 100%;
    max-height: 310px;
    width: 85%;
  }
`

const areParticipantsInRoom = css`
  height: calc(100% - 50px);
  width: 18%;
  max-width: 200px;
  max-height: 200px;
  order: 2;
  margin: 10px 15px 15px 5px;
`

const sharingByMeCSS = css`
  display: none;
`

const VideoPublisher = styled.div<{ participants?: boolean; isSharingScreen?: boolean }>`
  position: relative;
  display: flex;
  height: calc(100% - 110px);
  width: 95%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  clip-path: content-box;
  justify-content: center;
  align-items: center;

  @media (max-width: 601px) {
    display: none;
  }

  ${({ participants }) => participants && areParticipantsInRoom}
  ${({ isSharingScreen }) => isSharingScreen && isSharingScreenStyles}
`
export const Publisher = ({ isSharingScreen, audio, video }) => {
  const { streams, publisherDiv } = useSession()
  const publisher = useVideoStore((state) => state.publisherState)

  const publisherStreamNameArray = publisher?.stream?.name?.split('#img#')
  const name = publisherStreamNameArray?.[0]
  const avatar = publisherStreamNameArray?.[1]

  const areParticipants = streams.length > 0

  return (
    <VideoPublisher ref={publisherDiv} participants={areParticipants} isSharingScreen={isSharingScreen}>
      {name && <NameContainer name={name} />}
      {!audio && (
        <AudioCircle
          className={'icon'}
          imgStyle={areParticipants ? { transform: 'scale(1)' } : { transform: 'scale(1)' }}
          style={areParticipants ? { right: '2%', top: '2%', zIndex: 500 } : { right: '1%', top: '1%', zIndex: 500 }}
        />
      )}
      {!video && <NoVideoViewPublisher name={name || 'U'} avatar={avatar || ''} />}
    </VideoPublisher>
  )
}
