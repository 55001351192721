import React, { SVGProps } from 'react'

const PlusLight = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill="#707070" d="M11 4h2v16h-2z" />
    <path fill="#707070" d="M4 13v-2h16v2z" />
  </svg>
)

export default PlusLight
