import React, { useEffect, useState } from 'react'
import StyledLink from '../../../../../../../components/Button/Link'
import BackArrow from '../../../../../../../components/Icon/BackArrow'
import { Button } from '../../../../../../../components/Button'
import { StyledCreateContactForm } from '../../Styled/StyledCreateContactForm'
import { colors } from '../../../../../../../assets/colors'
import { FormikProvider, useFormik } from 'formik'
import { Loader } from '../../../../../../../components/Loader/Loader'
import Icon from '../../../../../../../components/Icon'
import Modal from '../../../../../../../components/Modal'
import useEditContact from '../../../../../../mobile/pages/Contacts/Common/Hooks/useEditContact'
import styled from 'styled-components'
import GroupSelect from '../GroupSelect'
import { GroupItemType } from '../../../../../../../types'
import { Link, Prompt } from 'react-router-dom'
import { StyledGroupName } from '../../../../../../../components/GroupsAndContacts/Styled'
import { showContactsModal } from '../../../../../../../store/modals'
import NotSavedFields from '../../Components/Modals/NotSavedFields'

interface IProps {
  groupsData?: GroupItemType[]
}

const EditContactForm = ({ groupsData }: IProps) => {
  const {
    contactLoading,
    formik,
    saving,
    deleteFunction,
    history,
    confirmShow,
    setConfirmShow,
    groupSelectionVisible,
    setGroupSelectionVisible
  } = useEditContact()

  const [isModalOpen, setModalOpen] = useState(false)
  const [nextLocation, setNextLocation] = useState(null)

  const unblock = history.block((location) => {
    if (formik.dirty) {
      setModalOpen(true)
      setNextLocation(location)
      return false
    }
  })

  if (contactLoading) return <Loader height={'20px'} width={'20px'} fill={colors.lightGrey} />

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative'
        }}
      >
        <div>
          <FormikProvider value={formik}>
            <StyledCreateContactForm.Wrapper>
              <StyledLink
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px'
                }}
                onClick={() => {
                  history.goBack()
                }}
              >
                <BackArrow style={{ marginRight: '10px' }} />
                Edit contact
              </StyledLink>
              <Button
                style={{ width: '150px', height: '40px' }}
                type="submit"
                onClick={() => {
                  if (formik.dirty) {
                    formik.handleSubmit()
                    formik.resetForm({ values: formik.values })
                  }
                }}
                isLoading={saving}
              >
                Save
              </Button>
            </StyledCreateContactForm.Wrapper>
            <StyledCreateContactForm.GroupSectionWrapper>
              <div style={{ maxWidth: 'calc(100% - 100px)' }}>
                <p style={{ fontSize: '12px', color: colors.lightGrey, marginBottom: '7px' }}>Groups</p>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {formik.values?.groups?.length ? (
                    formik.values?.groups?.map((group) => (
                      <StyledGroupName key={group.id} to={`/groups?id=${group.id}`}>
                        {group.name}
                      </StyledGroupName>
                    ))
                  ) : (
                    <p style={{ fontSize: '13px', color: colors.mediumGrey }}>no groups yet</p>
                  )}
                </div>
              </div>
              <StyledLink
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 600,
                  fontSize: '14px',
                  width: '100px'
                }}
                onClick={() => setGroupSelectionVisible(true)}
              >
                <span style={{ marginRight: '10px', fontSize: '20px' }}>+</span>
                {formik.values?.groups?.length ? 'Edit groups' : 'Add group'}
              </StyledLink>
            </StyledCreateContactForm.GroupSectionWrapper>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '25px'
              }}
            >
              <div style={{ width: '100%', marginRight: '10px' }}>
                <StyledCreateContactForm.Label htmlFor="kate_number">Kate number</StyledCreateContactForm.Label>
                <StyledCreateContactForm.Input
                  disabled
                  id="kate_number"
                  type="text"
                  placeholder="Kate number"
                  value={formik.values.kate_number}
                />
              </div>
              <div style={{ width: '100%', marginLeft: '10px' }}>
                <StyledCreateContactForm.Label htmlFor="first_name">First name</StyledCreateContactForm.Label>
                <StyledCreateContactForm.Input
                  id="first_name"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values?.first_name}
                  placeholder="First name"
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '25px'
              }}
            >
              <div style={{ width: '100%', marginRight: '10px' }}>
                <StyledCreateContactForm.Label htmlFor="last_name">Last name</StyledCreateContactForm.Label>
                <StyledCreateContactForm.Input
                  id="last_name"
                  onChange={formik.handleChange}
                  value={formik.values.last_name}
                  type="text"
                  placeholder="Last name"
                />
                {/*{meta.touched && meta.error && (*/}
                {/*  <div style={{ color: colors.red, fontSize: '12px', marginTop: '4px' }}>{meta.error}</div>*/}
                {/*)}*/}
              </div>
              <div style={{ width: '100%', marginLeft: '10px' }}>
                <StyledCreateContactForm.Label htmlFor="mobile_phone">Mobile phone</StyledCreateContactForm.Label>
                <StyledCreateContactForm.Input
                  id="mobile_phone"
                  type="text"
                  placeholder="Mobile phone"
                  onChange={formik.handleChange}
                  value={formik.values.mobile_phone}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '25px'
              }}
            >
              <div style={{ width: '100%', marginRight: '10px' }}>
                <StyledCreateContactForm.Label htmlFor="email">Personal email</StyledCreateContactForm.Label>
                <StyledCreateContactForm.Input
                  id="email"
                  type="email"
                  placeholder="Personal email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </div>
              <div style={{ width: '100%', marginLeft: '10px' }}>
                <StyledCreateContactForm.Label htmlFor="email2">Work email</StyledCreateContactForm.Label>
                <StyledCreateContactForm.Input
                  id="email2"
                  type="email"
                  placeholder="Work email"
                  onChange={formik.handleChange}
                  value={formik.values.email2}
                />
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <StyledCreateContactForm.Label htmlFor="extra_info">Notes</StyledCreateContactForm.Label>
              <StyledCreateContactForm.TextAreaInput
                id="extra_info"
                placeholder="Notes"
                onChange={formik.handleChange}
                value={formik.values.extra_info}
              />
            </div>
          </FormikProvider>
        </div>
        <Button
          onClick={() => setConfirmShow(true)}
          style={{
            background: 'transparent',
            color: colors.red,
            padding: 0,
            fontSize: '14px',
            fontWeight: 600,
            width: 'fit-content'
          }}
        >
          <Icon name={'remove'} fill={colors.red} style={{ width: '16px', height: '16px', marginRight: '10px' }} />
          Delete contact
        </Button>
        <Modal show={confirmShow}>
          <Modal.TextContainer>
            <Modal.HeroText>Are you sure you want to delete this contact?</Modal.HeroText>
          </Modal.TextContainer>
          <Modal.ButtonsContainer>
            <Modal.Button onClick={() => setConfirmShow(false)} bold color={colors.contrastBlue}>
              Keep it
            </Modal.Button>
            <Modal.Button onClick={deleteFunction} color={colors.red}>
              Delete
            </Modal.Button>
          </Modal.ButtonsContainer>
        </Modal>
        {groupSelectionVisible && (
          <GroupSelect onClose={() => setGroupSelectionVisible(false)} groupsData={groupsData} formik={formik} />
        )}
      </div>
      <NotSavedFields
        visible={isModalOpen}
        handleClose={() => {
          setModalOpen(false)
          setNextLocation(null)
        }}
        handleConfirm={() => {
          unblock()
          setModalOpen(false)
          history.push(nextLocation.pathname)
        }}
      />
    </>
  )
}

export default EditContactForm
