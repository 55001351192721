import React from 'react'
import styled from 'styled-components'
import BackLink from './components/BackLink'
import ReportRectangle from './components/ReportRectangle'
import { ReportRectangleContainer } from './components/ReportRectangleContainer'
import { YearText } from './components/YearText'

interface IProps {
  children: React.ReactNode
}

const Container = styled.div`
  align-items: flex-start;
  max-width: 700px;
  @media (min-width: 712px) {
    width: 700px;
  }
  @media (max-width: 711px) {
    width: 100%;
  }
  display: flex;
  flex-wrap: wrap;
`

const ReportsRoom = (props: IProps) => {
  return <Container>{props.children}</Container>
}

ReportsRoom.ReportRectangle = ReportRectangle
ReportsRoom.ReportRectangleContainer = ReportRectangleContainer
ReportsRoom.YearText = YearText
ReportsRoom.BackLink = BackLink

export default ReportsRoom
