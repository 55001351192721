import { useSession } from '../../../providers/SessionProvider'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import NoVideoView from '../../../../../components/blocks/VideoRoom/components/NoVideoView'
import AudioCircle from '../../../../../components/blocks/VideoRoom/components/AudioCircle'
import throttle from 'lodash/throttle'
import NameContainer from '../../../../../components/blocks/VideoRoom/components/NameContainer'
import OtherParticipantsContainer, {
  Background,
  ConferenceAtendee,
  ConferenceAtendees
} from '../../../../../components/blocks/VideoRoom/components/OtherParticipantsContainer'
import { useSortStreams } from './useSortStreams'
import { VISIBLE_STREAMS, VISIBLE_STREAMS_SCREENSHARE } from '../ConferenceNew'
import * as Sentry from '@sentry/react'

const isSharingScreenStyles = css`
  width: 98%;
  height: 24%;
  min-height: 24%;
  max-height: 24%;

  @media (min-width: 1921px) {
    height: 25%;
  }
`

const speaking = css`
  outline: 5px solid #1d70b6 !important;
  border-radius: 10px;
`

const VideoSubscriber = styled.div<{
  height?: string
  width: string
  isSharingScreen?: boolean
  isSpeaking?: boolean
}>`
  position: relative;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  clip-path: content-box;

  transition: top 0.5s ease-in-out, bottom 0.5s ease-in-out, left 0.5s ease-in-out, right 0.5s ease-in-out,
    outline 0.25s ease-in-out;

  width: ${({ width, isSharingScreen }) => (isSharingScreen ? '100%' : `calc(${width}% - 20px)`)};
  height: ${({ height, isSharingScreen }) => (isSharingScreen ? '100%' : `calc(${height}% - 15px)`)};
  margin: 10px 5px 5px 10px;
  padding: 5px;

  ${({ isSharingScreen }) => isSharingScreen && isSharingScreenStyles}
  ${({ isSpeaking }) => isSpeaking && speaking}
`

const isSharingScreenStylesContainer = css`
  position: relative;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 110px);
  order: 2;
  flex-wrap: nowrap;
  justify-content: start;
  margin-right: 15px;
`

const SubscriberContainerStyled = styled.div<{ participantsLength?: number; isSharingScreen?: boolean }>`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  height: calc(100% - 100px); // 100% of the viewport height
  width: 100%; // 100% of the viewport width
  justify-content: ${({ participantsLength }) =>
    participantsLength >= 5 ? 'center' : 'space-around'}; // make space around the subscribers
  align-content: start; // start aligning from the top

  ${({ isSharingScreen }) => isSharingScreen && isSharingScreenStylesContainer}
`

const calcDimensions = (participantsLength: number) => {
  const conditions = [
    { limit: 2, numColumns: participantsLength, numRows: 1 },
    { limit: 4, numColumns: 2, numRows: 2 },
    { limit: 6, numColumns: 3, numRows: 2 },
    { limit: Infinity, numColumns: 3, numRows: 3 }
  ]

  const condition = conditions.find((c) => participantsLength <= c.limit)

  return {
    height: 100 / condition.numRows,
    width: 100 / condition.numColumns
  }
}

export const SubscribersContainer = ({ isSharingScreen }) => {
  const { streams } = useSession()
  const { onCalcPosition, positions, invisibleStreams } = useSortStreams()

  const { height, width } = calcDimensions(streams?.length)

  if (!streams.length) return null

  const remainingCount = streams?.length - (isSharingScreen ? VISIBLE_STREAMS_SCREENSHARE : VISIBLE_STREAMS)

  return (
    <>
      <SubscriberContainerStyled participantsLength={streams?.length} isSharingScreen={isSharingScreen}>
        {streams?.map((stream) => {
          return (
            <>
              <Subscriber
                key={stream?.streamId}
                stream={stream}
                height={isSharingScreen ? null : height}
                width={isSharingScreen ? null : width}
                positions={positions}
                isSharingScreen={isSharingScreen}
                updateChangeStream={onCalcPosition}
              />
            </>
          )
        })}
        {streams?.length > (isSharingScreen ? VISIBLE_STREAMS_SCREENSHARE + 1 : VISIBLE_STREAMS + 1) && (
          <OtherParticipantsContainer
            visibleStreams={invisibleStreams}
            width={width}
            height={height}
            remainingCount={remainingCount}
            isSharingScreen={isSharingScreen}
          />
        )}
      </SubscriberContainerStyled>
    </>
  )
}

export const Subscriber = ({ stream, height, width, positions, isSharingScreen, updateChangeStream }) => {
  const subscriberDiv = useRef()
  const [isSpeaking, setIsSpeaking] = useState(false)
  const { session } = useSession()

  const stylePosition = positions?.find((pos) => pos.streamId === stream.streamId)?.position

  const styles = {
    insertMode: 'append',
    width: '100%',
    height: '100%',
    style: {
      archiveStatusDisplayMode: 'auto',
      nameDisplayMode: 'off',
      buttonDisplayMode: 'off',
      audioLevelDisplayMode: 'off',
      videoDisabledDisplayMode: 'off'
    }
  }

  const _throttleStreamsCalc = (streamId) => {
    updateChangeStream({ id: streamId, audioLevel: Date.now() })
  }

  const throttleSetSpeakingTrue = () => setIsSpeaking(true)
  const throttleSetSpeakingFalse = throttle(() => setIsSpeaking(false), 3000)
  const throttleStreamsCalc = throttle((streamId) => _throttleStreamsCalc(streamId), 500)

  useEffect(() => {
    let subscriber = null
    if (!session || !stream) return

    // @ts-ignore
    subscriber = session.subscribe(stream, subscriberDiv.current, styles, (error) => {
      console.log('Subsciber initialized')
      if (error) {
        console.log('Subscriber Error init', error)
        Sentry.captureException(error, {
          tags: {
            context: '[Single Subscriber] Subscriber Init - Error'
          }
        })
      }
    })

    subscriber.on('streamDestroyed', (event) => {
      session.unsubscribe(event.target)
    })

    subscriber.on('audioLevelUpdated', function (event) {
      if (event.audioLevel > 0.3) {
        console.log('moving', event.audioLevel)
        throttleStreamsCalc(event.target?.streamId)
      }
      if (event.audioLevel > 0.15) {
        throttleSetSpeakingTrue()
      } else {
        throttleSetSpeakingFalse()
      }
    })

    return () => {
      if (subscriber) {
        session.unsubscribe(subscriber)
        subscriber.off('streamDestroyed')
        subscriber.off('audioLevelUpdated')
        subscriber.off()
      }
    }
  }, [stream, session])

  const subscriberStreamNameArray = stream?.name?.split('#img#')
  const name = subscriberStreamNameArray?.[0]
  const avatar = subscriberStreamNameArray?.[1]

  if (!stream) return null

  // @ts-ignore
  return (
    <VideoSubscriber
      key={stream?.streamId}
      ref={subscriberDiv}
      height={height}
      width={width}
      isSharingScreen={isSharingScreen}
      isSpeaking={isSpeaking}
      style={stylePosition ? { position: 'absolute', ...stylePosition } : {}}
    >
      {!stream?.hasVideo && <NoVideoView name={name} avatar={avatar} />}
      {!stream?.hasAudio && <AudioCircle className={'icon'} />}
      {stream && <NameContainer name={name} />}
    </VideoSubscriber>
  )
}
