import { create } from 'zustand'
import { ContactItemType } from '../types'

interface ContactsStore {
  contact: ContactItemType | null
  actions: {
    setContact: (contacts: ContactItemType) => void
  }
}
export const useContactsStore = create<ContactsStore>()((set) => ({
  contact: null,
  actions: {
    setContact: (contact) => set({ contact })
  }
}))

export const useContactsActions = () => useContactsStore((state) => state.actions)

export const useGetContactStore = () => useContactsStore((state) => state.contact)
