import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { colors } from '../../../assets/colors'
import Title from './Title'
import From from './From'
import FormattedDate from './FormattedDate'
import Counter from './Counter'
import Cancel from './Cancel'
import { Transition } from 'react-transition-group'

const Container = styled.div<{ state: string }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9;
  padding: 30px;
  background: rgba(0, 0, 0, 0.85);
  color: ${colors.white};
  opacity: ${(props) => (props.state === 'entered' ? 1 : 0)};
  transition: opacity 200ms ease-in-out;
`

interface Props {
  children?: JSX.Element | JSX.Element[]
  visible: boolean
}

const AutoPlayer = ({ children, visible = false }: Props) => {
  return (
    <Transition in={visible} timeout={200} unmountOnExit mountOnEnter>
      {(state) => (
        // state change: exited -> entering -> entered -> exiting -> exited
        <Container state={state}>{children}</Container>
      )}
    </Transition>
  )
}

AutoPlayer.Title = Title
AutoPlayer.From = From
AutoPlayer.FormattedDate = FormattedDate
AutoPlayer.Counter = Counter
AutoPlayer.Cancel = Cancel

export default AutoPlayer
