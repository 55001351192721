import React from 'react'
import styled from 'styled-components'
import { colors } from '../../assets/colors'

const LongText = styled.span`
  font-size: 12px;
  width: 100%;
  padding: 12px;
  color: ${colors.lightGrey};
  word-wrap: break-word;
`

interface ILongText {
  text?: string
  label: string
}

const LongTextField = ({ text, label }: ILongText) => <LongText>{text || label}</LongText>

export default LongTextField
