import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  padding: 30px 0;
`

interface Props {
  children?: JSX.Element
}

const Cancel: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>
}

export default Cancel
