import React, { useCallback, useEffect, useMemo } from 'react'
import { AutoPlayView } from './AutoPlayView'
import format from 'date-fns/format'
import useNextUnreadMessage from './useNextUnreadMessage'
import useGetAudioSrc from '../../hooks/useGetAudioSrc'
import { shallow } from 'zustand/shallow'
import { useAudioMessageActions, useAudioMessageStore } from '../../store/audioMessage'
import { useAutoPlayActions, useAutoPlayStore } from '../../store/autoPlay'
import { useUser } from '../../store/user'
import { MessagesType, useGetMessagesByType } from '../../hooks/queries/useGetMessagesByType'
import { useGetMessagesById } from '../../hooks/queries/useGetMessagesById'

const AutoPlayContainer: React.FC = () => {
  const { data: messages } = useGetMessagesByType(MessagesType.new, {})
  const user = useUser()

  const { setAudioMessageId, setCurrentMessageId, setAudioPlaying, setAudioMessagesSrcList } = useAudioMessageActions()
  const { setAutoPlayVisible } = useAutoPlayActions()

  useNextUnreadMessage(messages)

  const autoPlayVisible = useAutoPlayStore((state) => state.visible, shallow)
  const nextUnreadMessageId = useAutoPlayStore((state) => state.nextUnreadMessageId)

  const isNextMessageInAudioSrcList = useAudioMessageStore((state) =>
    nextUnreadMessageId ? state.audioMessagesSrcList[nextUnreadMessageId] : false
  )

  const nextUnreadMessage = messages?.find((message) => message?.uniqueId === nextUnreadMessageId)

  // Prefetching next message audio src
  useGetMessagesById(nextUnreadMessageId)

  // get audio src for the next unread message
  const nextUnreadMessageAudioSrc = useGetAudioSrc(nextUnreadMessage, user, !!isNextMessageInAudioSrcList)

  useEffect(() => {
    if (!nextUnreadMessageAudioSrc) return

    setAudioMessagesSrcList({ [nextUnreadMessageId]: nextUnreadMessageAudioSrc })
  }, [nextUnreadMessageAudioSrc])

  const messageFrom = useMemo(() => {
    if (!nextUnreadMessage) return ''
    if (!nextUnreadMessage?.contact) return nextUnreadMessage?.source
    return `${nextUnreadMessage?.contact?.first_name} ${nextUnreadMessage?.contact?.last_name}`
  }, [nextUnreadMessage])

  const messageDate = useMemo(() => {
    if (!nextUnreadMessage) return ''
    const timestamp = nextUnreadMessage?.timestamp
    if (!timestamp) return ''
    return format(new Date(timestamp * 1000), 'EEEE • hh:mmaaaa')
  }, [nextUnreadMessage])

  const selectMessageList = (messageId: string) => {
    setCurrentMessageId(messageId)
  }

  const onTimerEnd = useCallback(() => {
    setAutoPlayVisible(false)
    selectMessageList(nextUnreadMessageId)
    setAudioMessageId(nextUnreadMessageId || '')
    setCurrentMessageId(nextUnreadMessageId || '')
    setAudioPlaying(true)
  }, [nextUnreadMessageId])

  if (!nextUnreadMessageId) return null

  return (
    <AutoPlayView
      visible={autoPlayVisible}
      messageFrom={messageFrom}
      messageDate={messageDate}
      onTimerEnd={onTimerEnd}
      onTimerCancel={() => {
        setAutoPlayVisible(false)
      }}
    />
  )
}

export default AutoPlayContainer
