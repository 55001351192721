import useIsMobile from '../../../../../../hooks/useIsMobile'
import { useGroupsQuery } from '../../../../../../hooks/queries/useGroupsQuery'
import { useHistory } from 'react-router'
import { useContactGroupListQuery } from '../../../../../../hooks/queries/useContactGroupListQuery'
import { useGetGroupQuery } from '../../../../../../hooks/queries/useGetGroupQuery'
import { useEffect } from 'react'

const useGroups = () => {
  const isMobile = useIsMobile()
  const history = useHistory()
  const urlParams = new URLSearchParams(history.location.search)
  const search = urlParams.get('filter') || ''
  const groupId = urlParams.get('id') || ''
  const { data: groups, isLoading } = useGroupsQuery()
  // const { data: contacts, isLoading: groupContactsLoading } = useContactGroupListQuery(search)
  const { data: group, isLoading: isLoadingGroup } = useGetGroupQuery(groupId)
  const { data: contactsData, isLoading: contactsDataLoading } = useContactGroupListQuery()

  /*
   Redirect to first found group
   */
  useEffect(() => {
    if (!groups?.length || history.location.search) return
    history.replace(`/groups?id=${groups?.[0]?.id}`)
  }, [groups, history.location.search])

  return {
    isMobile,
    currentGroup: groups?.find((group) => group?.id === groupId),
    filteredGroups: search
      ? groups?.filter((group) => group?.name?.toLowerCase().includes(search.toLowerCase()))
      : groups,
    isLoading,
    search,
    groupContacts: group,
    groupContactsLoading: isLoadingGroup,
    contactsData
  }
}

export default useGroups
