import styled from 'styled-components'
import Swipeout from 'rc-swipeout'
import { colors } from '../../../assets/colors'

const SwipeItem = styled(Swipeout)`
  width: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  .rc-swipeout-content {
    position: relative;
    background-color: ${colors.white};
  }
  .rc-swipeout-cover {
    position: absolute;
    z-index: 2;
    background: transparent;
    height: 100%;
    width: 100%;
    top: 0;
    display: none;
  }
  .rc-swipeout-content,
  .rc-swipeout-actions {
    transition: all 200ms;
  }
  .rc-swipeout-swiping .rc-swipeout-content {
    transition: none;
  }
  .rc-swipeout-actions {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    &.rc-swipeout-actions-left {
      left: 0;
    }
    &.rc-swipeout-actions-right {
      right: 0;
    }
  }
  .rc-swipeout-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    .rc-swipeout-btn-text {
      padding: 0 12px;
    }
  }
`

export default SwipeItem
