export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  grey: '#292929',
  mediumGrey: '#707070',
  lightGrey: '#bebebe',
  darkGrey: '#222',
  silver: '#efefef',
  lightSliver: '#dbdadf',
  shadowGrey: '#c9c9c9',
  veryLightGreen: 'rgba(31,166,139,0.1)',
  lightGreen: '#c9eae0',
  green: '#1fa68b',
  red: '#cc0000',
  orange: '#eb9e34',
  veryLightBlue: 'rgba(63, 181, 233, 0.1)',
  lightBlue: '#7AE4FD',
  blue: '#3FB5E9',
  darkBlue: '#1D70B6',
  contrastBlue: '#007AFF',

  navyBlueTransparent: 'rgba(23,23,23,0.05)',
  navyBlueSemiTransparent: 'rgba(23,23,23,0.3)',
  veryDark: '#171717',
  paleBlueTranparent: 'rgba(154,169,188,0.1)',
  blackTransparent: 'rgba(0,0,0,0.025)',
  paleGrey: '#FAFBFC'
}
