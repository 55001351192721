import React from 'react'
import WaitingRoom from '../../../../../../components/blocks/WaitingRoom'
import { InfoContainer } from './styled'
import GuestForm from './GuestForm'
import JoinNow from './JoinNow'
import Spinner from '../../../../../../../components/Icon/Spinner'
import { colors } from '../../../../../../../assets/colors'
import AmountOfParticipants from '../AmountOfParticipants'
import RoomParticipants from '../RoomParticipants'
import { useAvatar, useUser, useUserStore } from '../../../../../../../store/user'
import { useVideoStore } from '../../../../../../store/video'
import { useGetAllContacts } from '../../../../../../../hooks/queries/useGetAllContacts'

const Info = () => {
  const videoData = useVideoStore((state) => state.videoData)
  const room = useVideoStore((state) => state.room)
  const user = useUser()
  const userAvatar = useAvatar()
  const authenticated = useUserStore((state) => state.isAuthenticated)
  const { data: contacts } = useGetAllContacts()

  const hostId = room?.hostId
  const host = room?.participants?.find((member) => member.customerId === hostId)

  const guestFirstName = localStorage.getItem('guestFirstName')
  const guestLastName = localStorage.getItem('guestLastName')
  const guestEmail = localStorage.getItem('guestEmail')

  const isGuestHasData = !!guestFirstName && !!guestLastName && !!guestEmail

  const participantsCustomerIds = room?.participants?.map((participant) => participant.customerId)

  const participantsAvatars = contacts?.reduce((uniqueAvatars, contact) => {
    if (
      participantsCustomerIds?.includes(contact?.account_id) &&
      !uniqueAvatars?.some((avatar) => avatar?.account_id === contact?.account_id)
    ) {
      uniqueAvatars.push(contact)
    }
    return uniqueAvatars
  }, [])

  const roomParticipants = room?.participants?.map((participant) => {
    if (participant?.customerId === user?.account_number) {
      return {
        ...participant,
        avatar: userAvatar
      }
    }
    return {
      ...participant,
      avatar: participantsAvatars?.find((part) => part?.account_id === participant?.customerId)?.photo_url || null
    }
  })

  if (!authenticated && !isGuestHasData) {
    return (
      <InfoContainer>
        <GuestForm />
      </InfoContainer>
    )
  }

  if (!room || !videoData) {
    return (
      <InfoContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            minWidth: 380,
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Spinner width={'25px'} fill={colors.mediumGrey} />
          <span style={{ marginTop: 10, color: colors.mediumGrey }}>Obtaining Video Conference data</span>
        </div>
      </InfoContainer>
    )
  }

  return (
    <InfoContainer>
      <WaitingRoom.HostInfo joined={!!room?.canJoin && !!host} hostName={room?.nameOfHost} />
      {roomParticipants?.length && (
        <AmountOfParticipants participants={roomParticipants} isHost={user?.account_number === hostId} />
      )}
      {room?.participants && (
        <RoomParticipants participantsAvatars={participantsAvatars} participants={roomParticipants} />
      )}
      <JoinNow isGuestHasData={isGuestHasData} />
    </InfoContainer>
  )
}

export default Info
