import { useAvatar, useUser, useUserActions } from '../../../../../../../store/user'
import { useMessagesActions, useMessagesStore } from '../../../../../../../store/messages'
import { shallow } from 'zustand/shallow'
import APIClient from '../../../../../../../apiClient'
import { showAlertModal } from '../../../../../../../store/modals'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useAccountInfo = () => {
  const location = useLocation()
  const user = useUser()
  const avatar = useAvatar()
  const messageCounter = useMessagesStore((state) => state.messageCounter, shallow)
  const { fetchUser, fetchUserAvatar } = useUserActions()
  const { setMessageCounter } = useMessagesActions()

  useEffect(() => {
    ;(async function () {
      try {
        const res = await APIClient.voiceMailCounts()
        setMessageCounter(res.data)
      } catch (e) {
        console.log(e)
      }
    })()
  }, [location.pathname])

  const onImageUpload = async (imageFile: any) => {
    try {
      if (!imageFile) return
      const formData = new FormData()
      formData.append('photo', imageFile)
      await APIClient.setUserPhoto(formData)
      await fetchUser()
      await fetchUserAvatar()
    } catch (e: any) {
      console.log(e)
      showAlertModal(e.message)
    }
  }

  return {
    user,
    avatar,
    messageCounter,
    onImageUpload
  }
}

export default useAccountInfo
