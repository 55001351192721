import styled from 'styled-components'
import React from 'react'
import { colors } from '../../assets/colors'
import Icon from '../Icon'
import { useNewMessageStore } from '../../navigation/mobile/pages/NewMessage/store/NewMessageStore'

interface RecordButtonProps {
  isRecording?: boolean
  afterRecording?: boolean
  isPlaying?: boolean
  onRecord: () => void
  onRemove: () => void
  onPause: () => void
  onPlay: () => void
  onStopRecording: () => void
  timeLeft?: string
}

const Wrapper = styled.div`
  min-height: 135px;
  position: absolute;
  bottom: 0;
  padding: 10px 0 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.white};
`

const IconsContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`

const Text = styled.span`
  font-size: 12px;
  text-align: center;
  padding-top: 5px;
  color: ${colors.mediumGrey};
`

const Button = styled.button`
  outline: none;
  text-decoration: none;
  border-style: none;
  background: transparent;
  cursor: pointer;
  opacity: 0.95;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`

const RecordButton: React.FC<RecordButtonProps> = ({
  isRecording,
  isPlaying,
  afterRecording,
  onStopRecording,
  onPause,
  onPlay,
  onRecord,
  onRemove,
  timeLeft
}) => {
  const isConfidential = useNewMessageStore((state) => state.isConfidential)

  const fillColor = isConfidential ? colors.mediumGrey : colors.darkBlue

  if (isRecording && timeLeft) {
    return (
      <Wrapper>
        <Button onClick={onStopRecording}>
          <Icon name={'pauseButton'} fill={fillColor} style={{ width: '90px', height: '90px' }} />
        </Button>
        <Text>Time left: {timeLeft}</Text>
      </Wrapper>
    )
  }
  if (afterRecording) {
    return (
      <Wrapper>
        <IconsContainer>
          <Button onClick={onRemove}>
            <Icon name={'remove'} fill={fillColor} style={{ width: '60px', height: '60px' }} />
          </Button>
          {isPlaying ? (
            <Button onClick={onPause}>
              <Icon name={'pauseButton'} fill={fillColor} style={{ width: '60px', height: '60px' }} />
            </Button>
          ) : (
            <Button onClick={onPlay}>
              <Icon name={'play'} fill={fillColor} style={{ width: '60px', height: '60px' }} />
            </Button>
          )}
        </IconsContainer>
      </Wrapper>
    )
  }
  return (
    <Wrapper>
      <Button onClick={onRecord}>
        <Icon name={'microphone'} fill={fillColor} style={{ width: '90px', height: '90px' }} />
      </Button>
      <Text>Tap to record</Text>
    </Wrapper>
  )
}

export default RecordButton
