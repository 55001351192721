import styled from 'styled-components'
import React, { ReactElement } from 'react'
import { colors } from '../../assets/colors'
import { NavLinkProps, NavLink } from 'react-router-dom'

interface TabButtonProps {
  numberOf: number
  children: ReactElement
  text: string
}

const NumberOf = styled.span`
  font-size: 1rem;
  color: ${colors.grey};
`

const Text = styled.span`
  font-size: 12px;
  margin-left: 2px;
  padding-top: 5px;
`

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
`

const NavButton = styled(NavLink)<NavLinkProps>`
  padding: 5px 0 10px 0;
  color: ${colors.mediumGrey};
  height: 100%;
  border-style: none;
  background-color: transparent;
  border-bottom: 2px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 15px;
  outline: none;
  text-decoration: none;
  opacity: 0.9;

  &:hover {
    opacity: 1;
    border-bottom: ${(props) => (props.isActive ? `2px solid ${colors.darkBlue}` : `2px solid ${colors.mediumGrey}`)};
  }

  &:active {
    opacity: 0.7;
  }

  &.selected {
    border-bottom-color: ${colors.darkBlue};
  }

  &.selected ${NumberOf}, &.selected ${Text} {
    color: ${colors.darkBlue};
  }

  &.selected svg path {
    fill: ${colors.darkBlue};
  }
`

const TabButton: React.FC<NavLinkProps & TabButtonProps> = ({ to, numberOf, children, text, onClick }) => (
  <NavButton to={to} activeClassName="selected">
    <NumberOf>{numberOf}</NumberOf>
    <Wrapper>
      {React.cloneElement(children, {
        fill: colors.mediumGrey,
        width: '16px'
      })}
      <Text>{text}</Text>
    </Wrapper>
  </NavButton>
)

export default TabButton
