import React from 'react'
import styled from 'styled-components'
import { colors } from '../../assets/colors'
import Icon from '../Icon'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.white};
`

const StyledInput = styled.input`
  font-size: 14px;
  border: none;
  background-color: transparent;
  color: ${colors.black};
  outline: none;
  height: 40px;
  width: 100%;
  margin-left: 10px;
  padding: 0;
`

const CancelButton = styled.button`
  outline: 0;
  border: 0;
  text-decoration: none;
  color: ${colors.lightGrey};
  background-color: transparent;
  font-size: 14px;
  padding: 0 10px 0 0;
`

const StyledIcon = styled(Icon)`
  width: 30px;
  height: 30px;
  min-width: 30px;
  max-width: 30px;
  margin-left: 5px;
`

interface SearchProps {
  showCancel?: boolean
  onChange?: (e: { target: { value: string } }) => void
  onClick?: () => void
  onFocus?: () => void
  value?: string
}

const SearchInput = ({ showCancel, onChange, onClick, onFocus, ...props }: SearchProps) => (
  <Wrapper>
    <StyledIcon name={'search'} fill={colors.lightGrey} />
    <StyledInput onChange={onChange} onFocus={onFocus} {...props} />
    {showCancel ? <CancelButton onClick={onClick}>Cancel</CancelButton> : null}
  </Wrapper>
)

export default SearchInput
