import React, { useEffect, useState } from 'react'
import Header from '../../../../components/Header'
import { InlineButton } from '../../../../components/Button/InlineButton'
import { logoutUser } from '../../../../auth/logout'
import { Button } from '../../../../components/Button'
import ImportantFilled from '../../../../components/Icon/ImportantFilled'
import styled from 'styled-components'
import { Loader } from '../../../../components/Loader/Loader'
import { colors } from '../../../../VideoConferencing/assets/colors'
import * as Sentry from '@sentry/react'
import { toast, ToastContainer } from 'react-toastify'
import useSentryUserIdFromJWT from '../../../../hooks/useSentryUserIdFromJWT'
import { accountLockService } from '../../../../services/AccountLockService'

const PageContainer = styled.div`
  display: flex;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: auto;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const TextBlock = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
`

const LogoutButton = styled(InlineButton)`
  background: #b23b3b;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  color: #fff;
`

const TextCTA = styled.span`
  display: inline-block;
  font-weight: 700;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const AccountLockedPage = () => {
  useSentryUserIdFromJWT()

  useEffect(() => {
    document.title = 'Account Locked'
  }, [])

  const openCustomerSupportChat = () => {
    // @ts-ignore
    SnapEngage.openProactiveChat(true, true)
  }

  const redirectToBillingWebsite = () => {
    accountLockService.clearLockReason()
    window.location.href = process.env.REACT_APP_BILLING_WEBSITE_URL || '/'
  }

  return (
    <PageContainer>
      <Header>
        <Header.SideButtons />
        <Header.Title>Account Needs Attention</Header.Title>
        <Header.SideButtons></Header.SideButtons>
      </Header>
      <Content>
        <TextBlock>
          <ImportantFilled width={'55px'} fill={'#b23b3b'} />
          <div style={{ margin: '15px 0' }}>
            <h2 style={{ fontSize: '16px', color: '#b23b3b', textAlign: 'center', lineHeight: 1.5 }}>
              It looks like your subscription is past due.
            </h2>
            <h2 style={{ fontSize: '16px', color: '#b23b3b', textAlign: 'center', lineHeight: 1.5 }}>
              Please click the button below to restore access.
            </h2>
          </div>
          <div>
            <Button onClick={redirectToBillingWebsite} style={{ textTransform: 'initial', fontWeight: 700 }}>
              Make a payment
            </Button>
          </div>
        </TextBlock>
        <div style={{ padding: '0 15px' }}>
          <div style={{ width: '100%', opacity: 0.2 }}>
            <hr />
          </div>
          <p style={{ margin: '30px 0', lineHeight: 1.5, textAlign: 'center', color: colors.grey }}>
            If you have any questions, click{' '}
            <TextCTA style={{ color: '#000' }} onClick={openCustomerSupportChat}>
              Chat Now
            </TextCTA>{' '}
            to talk with our customer service representative.
          </p>
        </div>
        <LogoutButton onClick={logoutUser}>LOG OUT</LogoutButton>
      </Content>
      <ToastContainer theme="light" position="bottom-right" autoClose={5000} />
    </PageContainer>
  )
}

export default AccountLockedPage
