import React from 'react'
import { useNewMessageActions, useNewMessageModals, useNewMessageStore } from './store/NewMessageStore'
import { ConfirmDiscardMessageModal } from './ConfirmDiscardMessageModal'
import { useAudioPlayingContext } from './provider/AudioPlayingProvider'
import { useHistory, useLocation } from 'react-router'
import ImageModal from '../../../desktop/pages/Messages/components/ImageModal'

const Modals = () => {
  const history = useHistory()
  const location = useLocation()
  const { cancelMessage, discardAudio, imageModal } = useNewMessageModals()
  const { setAudio, pauseAudio } = useAudioPlayingContext()
  const {
    setCancelMessageModal,
    setDiscardAudioModal,
    onDiscardAudio,
    onDiscardMessage,
    setImageModal
  } = useNewMessageActions()

  const image = useNewMessageStore((state) => state.image)

  const locationParams =
    location?.pathname && location?.search
      ? `${location?.pathname}${location?.search}`
      : location?.pathname
      ? location?.pathname
      : '/'

  return (
    <>
      <ConfirmDiscardMessageModal
        onConfirm={() => {
          pauseAudio()
          onDiscardMessage()
          setAudio(null)
          setTimeout(() => {
            history.push(locationParams, { newMessage: false, recipients: [] })
          }, 250)
        }}
        onCancel={() => setCancelMessageModal(false)}
        show={cancelMessage}
      />

      <ConfirmDiscardMessageModal
        onConfirm={() => {
          pauseAudio()
          setAudio(null)
          onDiscardAudio()
        }}
        onCancel={() => setDiscardAudioModal(false)}
        show={discardAudio}
      />

      <ImageModal imageUrl={image} visible={imageModal} onClose={() => setImageModal(false)} />
    </>
  )
}

export default Modals
