import styled from 'styled-components'
import React from 'react'
import { colors } from '../../../../assets/colors'

interface IImg {
  width: string
}

const AvatarImg = styled.img<IImg>`
  width: ${(props) => props.width};
  height: ${(props) => props.width};
  border-radius: 50%;
`

const AddAvatar = styled.div<IImg>`
  width: ${(props) => props.width};
  height: ${(props) => props.width};
  border-radius: 50%;
  border: 1px solid ${colors.lightGrey};
  color: ${colors.lightGrey};
  background-color: ${colors.white};
  text-decoration: none;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface IAvatar {
  src?: string
  initials: string
  width?: string
}

const Avatar = ({ src, initials, width }: IAvatar) => {
  if (!src) {
    return <AddAvatar width={width || '50px'}>{initials.toUpperCase()}</AddAvatar>
  }
  return <AvatarImg src={src} width={width || '50px'} />
}

export default Avatar
