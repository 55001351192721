import React from 'react'
import styled from 'styled-components'
import Avatar from '../blocks/WaitingRoom/components/Avatar'

interface IProps {
  text: string
}

const RoundedContainer = styled.div`
  width: 80px;
  height: 80px;
  background: #707070;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledText = styled.div`
  font-size: 32px;
  color: white;
`

const RoundedAvatar = ({ text, avatar }: IProps) => {
  return avatar ? (
    <Avatar src={avatar} alt={text} name={''} style={{ width: 100, height: 100 }} />
  ) : (
    <RoundedContainer>
      <StyledText>{text}</StyledText>
    </RoundedContainer>
  )
}

export default RoundedAvatar
