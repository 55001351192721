import React from 'react'
import styled from 'styled-components'
import { colors } from '../../assets/colors'

const Title = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  color: ${colors.white};
`

export default Title
