import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import apiClient from '../../apiClient'

const Patents = () => {
  const [patents, setPatents] = useState('')

  useEffect(() => {
    ;(async () => {
      const patentsRes = await fetch(process.env.REACT_APP_PATENTS_URL)
      const response = await patentsRes.json()
      setPatents(response.text)
    })()
  }, [])

  if (!patents) return <Box sx={{ width: { xs: 350, md: 500 }, height: 50 }} />

  return <Box sx={{ width: { md: 500 } }}>{patents}</Box>
}

export default Patents
