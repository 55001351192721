import React, { useEffect, useState } from 'react'
import Spinner from '../../../../../components/Icon/Spinner'
import { colors } from '../../../../../assets/colors'
import styled, { css } from 'styled-components'
import appSyncClient from '../../../../../gql/client'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/react'
import { loadStripe } from '@stripe/stripe-js'
import { getConfigQuery, GetConfigQueryResponse } from '../../../../../gql/queries/getConfig'
import { getStripeCustomerPaymentMethodsQuery } from '../../../../../gql/queries/getStripeCustomerPaymentMethodsQuery'
import { setBillingActionEventAsHandledByCustomer } from '../../../../../gql/queries/setBillingActionEventAsHandledByCustomer'

const StyledHref = styled.a`
  color: ${colors.darkBlue};
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin: 5px;
  cursor: pointer;
  text-decoration: underline;
`

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const isLoadingBtn = css`
  cursor: not-allowed;
  background: ${colors.mediumGrey};

  &:hover {
    background: ${colors.mediumGrey};
  }
`

const ProceedButton = styled.button<{ isLoading?: boolean }>`
  border: none;
  background: ${colors.darkBlue};
  color: ${colors.white};
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  border-radius: 50px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 15px;

  display: flex;
  align-items: center;

  :hover {
    background: ${colors.darkBlue2};
  }

  ${(props) => props.isLoading && isLoadingBtn}
`

const ContactSupportButton = styled.button`
  margin-left: 10px;
  border: none;
  background: ${colors.white};
  color: ${colors.darkBlue};
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 15px;

  cursor: pointer;
`

const ResolveNow = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingEvents, setIsLoadingEvents] = useState(true)
  const [paymentEvent, setPaymentEvent] = useState<{
    eventType: string
    paymentNextActionSecret?: string
    hostedVerificationUrl?: string
  }>(null)

  useEffect(() => {
    ;(async () => {
      try {
        const res = (await appSyncClient.graphql({ query: getConfigQuery })) as GetConfigQueryResponse
        const foundAchEvent = res?.data?.getConfig?.billing?.accountNeedsAttention?.find(
          (item) => item?.hostedVerificationUrl
        )
        if (foundAchEvent) {
          setPaymentEvent(foundAchEvent)
        } else {
          const foundPaymentEvent = res?.data?.getConfig?.billing?.accountNeedsAttention?.find(
            (item) => item?.paymentNextActionSecret
          )
          setPaymentEvent(foundPaymentEvent)
        }
      } catch (err) {
        Sentry.captureException(err)
        toast.error('Something went wrong. Please try again later.')
      } finally {
        setIsLoadingEvents(false)
      }
    })()
  }, [])

  const onResolve = async () => {
    if (paymentEvent.eventType === 'microdeposits_verification_required' && paymentEvent.hostedVerificationUrl) {
      setIsLoading(true)
      setTimeout(() => {
        window.location.replace(paymentEvent.hostedVerificationUrl)
      }, 3000)
      return
    }
    const stripe = await loadStripe(process.env.REACT_APP_PUBLISHABLE_STRIPE_KEY)

    try {
      setIsLoading(true)
      // TODO: Handle proper type
      const paymentMethod: any = await appSyncClient.graphql({
        query: getStripeCustomerPaymentMethodsQuery
      })

      const { error, paymentIntent } = await stripe.confirmCardPayment(paymentEvent?.paymentNextActionSecret, {
        // TODO: Fix data structure
        payment_method: paymentMethod?.data?.getStripeCustomerPaymentMethods?.data?.[0]?.id
      })

      if (error) {
        toast.error(error?.message)
        Sentry.captureException(error, {
          tags: {
            page: 'Resolving account need attention - Error'
          }
        })
        return
      }

      if (paymentIntent?.status === 'succeeded') {
        await appSyncClient.graphql({
          query: setBillingActionEventAsHandledByCustomer,
          variables: { eventType: paymentEvent?.eventType }
        })
        setPaymentEvent(null)
        toast.success('Success! Your payment method has been saved.')
      }
    } catch (error) {
      toast.error('Error! Your payment could not be processed.')
      Sentry.captureException(error, {
        tags: {
          page: 'Resolving account need attention - Error'
        }
      })
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoadingEvents) {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spinner width={'50px'} fill={colors.darkBlue} />
      </div>
    )
  }

  if (!paymentEvent && !isLoadingEvents) {
    return (
      <InfoContainer>
        <p>There are no actions to resolve at this time.</p>
      </InfoContainer>
    )
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          flexDirection: 'column'
        }}
      >
        <InfoContainer>
          The payment method on your account requires additional steps, please follow the instructions on the following
          screens or reach out to{' '}
          <StyledHref
            onClick={() => {
              //@ts-ignore
              SnapEngage.openProactiveChat(true, true)
            }}
          >
            customer service
          </StyledHref>{' '}
          for assistance.
        </InfoContainer>
        <ButtonsContainer>
          <ProceedButton disabled={isLoading} onClick={onResolve} isLoading={isLoading}>
            {isLoading ? (
              <>
                Processing
                <Spinner fill={colors.white} width={'15px'} style={{ marginLeft: 5 }} />
              </>
            ) : (
              'Proceed'
            )}
          </ProceedButton>
          <ContactSupportButton
            onClick={() => {
              //@ts-ignore
              SnapEngage.openProactiveChat(true, true)
            }}
          >
            Contact Support
          </ContactSupportButton>
        </ButtonsContainer>
      </div>
    </>
  )
}

export default ResolveNow
