import React, { useEffect, useState } from 'react'
import APIClient from '../../apiClient'
import AvatarPlaceholder from '../../assets/images/avatar_placeholder.png'
import { useAvatar } from '../../store/user'

export const Profile = ({ width = 35, height = 35, borderRadius = 20, style = {} }) => {
  const userAvatar = useAvatar()

  if (userAvatar) {
    return <img height={height} width={width} style={{ borderRadius, ...style }} src={userAvatar} alt="User Avatar" />
  }

  return (
    <div style={{ height, width, ...style }}>
      <img
        height={height}
        width={width}
        style={{ borderRadius }}
        src={AvatarPlaceholder}
        alt="User Avatar Placeholder"
      />
    </div>
  )
}
