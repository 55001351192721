import { useLocation } from 'react-router-dom'

const useCurrentParam = <T>(param: string) => {
  const searchParams = new URLSearchParams(useLocation().search)
  const currentTab = searchParams.get(param) as T

  return [currentTab]
}

export default useCurrentParam
