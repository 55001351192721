import { useEffect } from 'react'
import jwt_decode from 'jwt-decode'
import * as Sentry from '@sentry/react'

const useSentryUserIdFromJWT = () => {
  useEffect(() => {
    const kateJWT = localStorage.getItem('kateTokenX')
    if (!kateJWT) return

    const decodedJWT: {
      account_id: string | undefined | null
    } = jwt_decode(kateJWT)

    if (decodedJWT?.account_id) {
      Sentry.setUser({ id: decodedJWT?.account_id })
    }
  }, [])
}

export default useSentryUserIdFromJWT
