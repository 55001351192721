import { useHistory } from 'react-router'
import MessageList from '../../components/blocks/MessageList'
import { Loader } from '../../components/Loader/Loader'
import { colors } from '../../assets/colors'
import { Virtuoso } from 'react-virtuoso'
import MessageListItem from '../MessageListItem'
import ActionsModal from '../../components/Modal/ActionsModal'
import React, { useEffect, useState } from 'react'
import useIsMobile from '../../hooks/useIsMobile'

enum MessagesOrder {
  oldestToNewest = 'Oldest to Newest',
  newestToOldest = 'Newest to Oldest'
}

const MessageListView = ({ messages, isLoading }: { messages: any[]; isLoading: boolean }) => {
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [order, setOrder] = useState(MessagesOrder.newestToOldest)
  const [orderedMessages, setOrderedMessages] = useState(messages)

  const messagesFolderNew = history.location.pathname === '/messages/new'

  useEffect(() => {
    if (!isLoading && messages) {
      setOrderedMessages(messages)
    }
  }, [messages, isLoading])

  const changeOrder = (newOrder: any) => {
    setOrder(newOrder)
    setShowModal(false)

    const sortedMessages = [...orderedMessages].sort((a, b) => {
      if (newOrder === MessagesOrder.oldestToNewest) {
        return (a.timestamp || 0) - (b.timestamp || 0)
      } else {
        return (b.timestamp || 0) - (a.timestamp || 0)
      }
    })

    setOrderedMessages(sortedMessages)
  }

  return (
    <>
      <MessageList>
        {isLoading && (
          <MessageList.Header>
            <Loader fill={colors.mediumGrey} style={{ margin: '5' }} />
          </MessageList.Header>
        )}

        {!isLoading && !messages?.length && messagesFolderNew && <MessageList.UpToDateBox />}

        {!isLoading && !messages?.length && !messagesFolderNew && <MessageList.Header>No Messages</MessageList.Header>}

        {!isLoading && !!messages?.length && (
          <MessageList.Header onClick={() => setShowModal(true)}>{order}</MessageList.Header>
        )}
        {!isLoading && !!messages?.length && (
          <Virtuoso
            style={{ height: 'calc(100% - 40px)' }}
            data={orderedMessages}
            itemContent={(_, item) => {
              return <MessageListItem message={item} />
            }}
          />
        )}

        <ActionsModal show={showModal} onCancel={() => setShowModal(false)}>
          <ActionsModal.Action key={'new'} onClick={() => changeOrder(MessagesOrder.newestToOldest)}>
            {MessagesOrder.newestToOldest}
          </ActionsModal.Action>
          <ActionsModal.Action key={'old'} onClick={() => changeOrder(MessagesOrder.oldestToNewest)}>
            {MessagesOrder.oldestToNewest}
          </ActionsModal.Action>
        </ActionsModal>
      </MessageList>
    </>
  )
}
export default MessageListView
