import React from 'react'
import Avatar from '../../blocks/Contact/ContactItem/Avatar'
import { colors } from '../../../assets/colors'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Icon from '../../Icon'
import IconButton from '../../Header/IconButton'

interface IProps {
  to: string
  active: boolean
  avatar: {
    initials: string
    src?: string
  }
  name: string
  kateNumber?: string
  wrapperStyle?: React.CSSProperties
  nameStyle?: React.CSSProperties
  type?: null | 'groupSelect' | 'groupList'
  onClick?: any
  selectedContactsIds?: string[]
  currentId?: string
}

const ContactRow = ({
  to,
  active,
  avatar,
  name,
  kateNumber,
  wrapperStyle,
  nameStyle,
  type,
  onClick,
  selectedContactsIds,
  currentId
}: IProps) => {
  if (type === 'groupSelect')
    return (
      <StyledButtonRow active={active} style={wrapperStyle} onClick={onClick}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Avatar initials={avatar.initials} src={avatar?.src} />
          <ContactInfoWrapper style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
            <div>
              <ContactBorder />
              <ContactName style={nameStyle}>{name}</ContactName>
              <p
                style={{
                  color: colors.lightGrey,
                  marginTop: '3px',
                  fontSize: '12px',
                  height: '12px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {kateNumber}
              </p>
            </div>
            <div>
              {selectedContactsIds?.includes(currentId) && (
                <Icon name="checkmark" fill={colors.darkBlue} style={{ width: '20px', marginRight: '20px' }} />
              )}
            </div>
          </ContactInfoWrapper>
        </div>
      </StyledButtonRow>
    )

  if (type === 'groupList')
    return (
      <StyledButtonRow active={active} style={wrapperStyle}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Avatar initials={avatar.initials} src={avatar?.src} />
          <ContactInfoWrapper style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
            <div>
              <ContactBorder />
              <ContactName style={nameStyle}>{name}</ContactName>
              <p
                style={{
                  color: colors.lightGrey,
                  marginTop: '3px',
                  fontSize: '12px',
                  height: '12px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {kateNumber}
              </p>
            </div>
            <div>
              {selectedContactsIds?.includes(currentId) && (
                <IconButton onClick={onClick}>
                  <Icon name="remove" fill={colors.red} style={{ width: '20px', marginRight: '20px' }} />
                </IconButton>
              )}
            </div>
          </ContactInfoWrapper>
        </div>
      </StyledButtonRow>
    )

  return (
    <StyledRow to={to} active={active} style={wrapperStyle}>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Avatar initials={avatar.initials} src={avatar?.src} />
        <ContactInfoWrapper>
          <ContactBorder />
          <ContactName style={nameStyle}>{name}</ContactName>
          <p style={{ color: colors.lightGrey, marginTop: '3px', fontSize: '12px' }}>{kateNumber}</p>
        </ContactInfoWrapper>
      </div>
    </StyledRow>
  )
}

export default ContactRow

const StyledRow = styled(Link)`
  height: 58px;
  width: 100%;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: ${(props: { active?: boolean }) => (props?.active ? 'rgba(29, 112, 182, 0.05)' : 'transparent')};

  &:hover {
    background-color: rgba(29, 112, 182, 0.05);
  }
`

const StyledButtonRow = styled.button`
  height: 58px;
  width: 100%;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  //outline: none;
  border: none;
  background-color: ${(props: { active?: boolean }) => (props?.active ? 'rgba(29, 112, 182, 0.05)' : 'transparent')};

  &:hover {
    background-color: rgba(29, 112, 182, 0.05);
  }
`

const ContactInfoWrapper = styled.div`
  height: 30px;
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-left: 5px;
`

const ContactName = styled.p`
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 220px;
  color: ${colors.darkGrey};
`

const ContactBorder = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  background: rgba(112, 112, 112, 0.2);
  bottom: -14px;
`
