import React from 'react'

interface MicrophoneProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const Microphone = ({
  style = {},
  fill = '#000',
  width = '30px',
  className = '',
  viewBox = '0 0 30 30'
}: MicrophoneProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
      M 15 2 C 13.343 2 12 3.343 12 5 L 12 17 C 12 18.657 13.343 20 15 20 C 16.657 20 18 18.657 18 17 L 18 5 C 18 3.343 16.657 2 15 2 z M 8.984375 11.986328 A 1.0001 1.0001 0 0 0 8 13 L 8 17 C 8 20.513997 10.617426 23.430774 14 23.919922 L 14 26 L 11 26 A 1.0001 1.0001 0 1 0 11 28 L 14.832031 28 A 1.0001 1.0001 0 0 0 15.158203 28 L 19 28 A 1.0001 1.0001 0 1 0 19 26 L 16 26 L 16 23.919922 C 19.382574 23.430774 22 20.513997 22 17 L 22 13 A 1.0001 1.0001 0 1 0 20 13 L 20 17 C 20 19.735431 17.833516 21.933922 15.113281 21.994141 A 1.0001 1.0001 0 0 0 14.984375 21.986328 A 1.0001 1.0001 0 0 0 14.875 21.994141 C 12.16029 21.92784 10 19.731486 10 17 L 10 13 A 1.0001 1.0001 0 0 0 8.984375 11.986328 z
      "
    />
  </svg>
)

export default Microphone
