import React from 'react'
import Input from './Input'
import InputContainer from './InputContainer'
import Line from './Line'

interface LargeInputProps {
  name: string
  value: string
  handleChange: (
    eventOrPath: string | React.ChangeEvent<any>
  ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void)
  placeholder?: string
  error?: boolean
}

const LargeInput = ({ name, value, handleChange, placeholder, error }: LargeInputProps) => {
  return (
    <InputContainer>
      <Input name={name} type="text" value={value} onChange={handleChange} placeholder={placeholder} />
      <Line error={error} />
    </InputContainer>
  )
}

export default LargeInput
