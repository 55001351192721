import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CopyToClipboard from 'react-copy-to-clipboard'
import IcCopy from '../../../../../assets/images/ic_copy.png'
import Checkmark from '../../../../../components/Icon/Checkmark'

interface IProps {
  url: string | null
  showText: boolean
}

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  min-width: 105px;
  opacity: 0.92;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
  @media (max-width: 599px) {
    min-width: 30px;
    max-width: 30px;
    max-height: 20px;
  }
`

const StyledImg = styled.img`
  margin-bottom: 8px;
`

const StyledText = styled.a`
  font-size: 15px;
  color: white;
`

const CopyInviteLink = ({ showText, url }: IProps) => {
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    if (isCopied) {
      const copiedTimeout = setTimeout(() => {
        setIsCopied(false)
      }, 3000)

      return () => {
        clearTimeout(copiedTimeout)
      }
    }
  }, [isCopied])

  return (
    <>
      <CopyToClipboard text={url} onCopy={() => setIsCopied(true)}>
        <IconContainer>
          {isCopied ? (
            <Checkmark width={'20'} fill={'#fff'} style={{ minWidth: '30px', marginBottom: 8 }} />
          ) : (
            <StyledImg src={IcCopy} width={20} height={20} style={{ marginBottom: 8 }} />
          )}
          {showText ? <StyledText>{isCopied ? 'Copied!' : 'Copy Invite Link'}</StyledText> : null}
        </IconContainer>
      </CopyToClipboard>
    </>
  )
}

export default CopyInviteLink
