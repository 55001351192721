import React from 'react'
import Container from './components/Container'

import styled from 'styled-components'
import BasicInfoContainer from './components/BasicInfoContainer'
import Title from './components/Title'
import LargeInput from './blocks/LargeInput'
import LeftPanel from './components/LeftPanel'
import RightPanel from './components/RightPanel'
import InputContainer from './components/InputContainer'
import TimePickerField from './blocks/TimePickerField'
import DescriptionField from './blocks/DescriptionField'
import UrlContainer from './blocks/UrlContainer'
import Panels from './components/Panels'
import ConferenceParticipantsContainer from './components/ConferenceParticipantsContainer'
import TimePickerFragment from './blocks/TimePickerFragment'
import DurationPickerFragment from './blocks/DurationPickerFragment'

interface IProps {
  children: React.ReactNode
}

const RoomContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Meeting = (props: IProps) => {
  return <RoomContainer>{props.children}</RoomContainer>
}

Meeting.Container = Container
Meeting.BasicInfoContainer = BasicInfoContainer
Meeting.Title = Title
Meeting.LargeInput = LargeInput
Meeting.LeftPanel = LeftPanel
Meeting.RightPanel = RightPanel
Meeting.InputContainer = InputContainer
Meeting.TimePickerField = TimePickerField
Meeting.TimePickerFragment = TimePickerFragment
Meeting.DurationPickerFragment = DurationPickerFragment
Meeting.DescriptionField = DescriptionField
Meeting.UrlContainer = UrlContainer
Meeting.Panels = Panels
Meeting.ConferenceParticipantsContainer = ConferenceParticipantsContainer

export default Meeting
