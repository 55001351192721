import styled from 'styled-components'
import { colors } from '../../../assets/colors'

const OptionsHeader = styled.div`
  color: ${colors.mediumGrey};
  text-decoration: none;
  font-weight: 300;
  border-bottom: 1px solid ${colors.lightSliver};
  font-size: 14px;
  padding: 10px;
  width: 100%;
  outline: none;
  flex-shrink: 0;
  min-height: 18px;
  display: flex;
  align-items: flex-end;
`

export default OptionsHeader
