import { showAlertModal, showInfoModal } from '../../../../../../../store/modals'
import APIClient from '../../../../../../../apiClient'
import { useCallback, useEffect, useState } from 'react'
import { Howl } from 'howler'
// @ts-ignore
import MicRecorder from 'mic-recorder-to-mp3'
import { useUser } from '../../../../../../../store/user'
import { useHistory } from 'react-router'
import useAudioPlaying from '../../../../../../../hooks/useAudioPlaying'

const useNameRecording = () => {
  // fallback for Safari's AudioContext reference
  // @ts-ignore
  window.AudioContext = window.AudioContext || window.webkitAudioContext
  const user = useUser()
  const history = useHistory()
  const [isRecording, setRecording] = useState(false)
  const [isLoading, setLoading] = useState(true)

  // Audio from database
  const currentGreeting = useAudioPlaying()

  const getCurrentMp3 = useCallback(async () => {
    try {
      if (user && !user.phone_settings.name_recording) return
      const res = await APIClient.nameRecordingGet()
      if (res.data) {
        const blob = new Blob([res.data], { type: 'audio/mp3' })
        // @ts-ignore
        const blobURL = window.URL.createObjectURL(blob)
        currentGreeting.setAudio(
          new Howl({
            html5: true,
            src: blobURL,
            format: ['mp3']
          })
        )
      }
    } catch (e) {
      console.log('error on getCurrentMp3:', e)
      showAlertModal("Can't get current name recording")
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getCurrentMp3()
  }, [])

  // Audio record
  const {
    audio,
    curTime,
    duration,
    playing,
    playProgress,
    setAudio,
    playAudio,
    pauseAudio,
    setClickedTime
  } = useAudioPlaying()

  const [mp3File, setMp3File] = useState<File | null>(null)
  const [Mp3Recorder] = useState(new MicRecorder({ bitRate: 128, sampleRate: 22050 }))

  const startRecording = async () => {
    try {
      const recordingStarted = await Mp3Recorder.start()
      if (recordingStarted) {
        setRecording(true)
      }
    } catch (e) {
      console.log('error on startRecording:', e)
      showAlertModal('Recording error')
    }
  }

  const stopRecording = async () => {
    try {
      const [buffer, blob] = await Mp3Recorder.stop().getMp3()

      if (buffer && blob) {
        const timestamp = Date.now()

        const file = new File(buffer, `name-recording-${timestamp}.mp3`, {
          type: 'audio/mpeg',
          lastModified: Date.now()
        })

        const blobURL = URL.createObjectURL(file)
        setMp3File(file)
        setRecording(false)
        setAudio(
          new Howl({
            html5: true,
            src: blobURL,
            format: ['mp3']
          })
        )
      }
      // TODO Add Proper types
    } catch (e: any) {
      showAlertModal(`error on stop recording: ${e.toString()}`)
      console.log('error on stopRecording:', e)
    }
  }

  const submitFile = useCallback(async () => {
    if (!mp3File) {
      showAlertModal(`No greeting recorded`)
      return null
    }
    try {
      const formData = new FormData()
      formData.append('recording', mp3File)
      await APIClient.nameRecordingSave(formData)
      showInfoModal('Recording updated')
      setAudio(null)
      await getCurrentMp3()
    } catch (e: any) {
      showAlertModal(`error on submitting file: ${e.toString()}`)
      console.log('error on submitFile:', e)
    }
  }, [mp3File])

  const playCurrent = async () => {
    if (currentGreeting.playing) {
      await currentGreeting.pauseAudio()
    } else {
      await currentGreeting.playAudio()
    }
  }

  return {
    isRecording,
    stopRecording,
    startRecording,
    currentGreeting,
    curTime,
    playCurrent,
    setAudio,
    audio,
    duration,
    playing,
    playProgress,
    setClickedTime,
    playAudio,
    pauseAudio,
    mp3File,
    submitFile
  }
}

export default useNameRecording
