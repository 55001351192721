import React, { lazy, Suspense, useEffect, useState } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import MuiThemeProvider from './providers/MuiThemeProvider'
import FallbackComponent from '../components/FallbackComponent'
import * as Sentry from '@sentry/react'
import tokenManager from '../services/TokenManager'

const ChatPage = lazy(() => import('./ChatPage'))

const Chat = () => {
  const history = useHistory()
  const [isLoadingCreds, setIsLoadingCreds] = useState(false)

  const getChatCreds = async () => {
    const awsAccessTokenId = localStorage.getItem('awsAccessTokenId')
    const chatUrl = localStorage.getItem('chatUrl')

    if (!awsAccessTokenId || !chatUrl) {
      try {
        setIsLoadingCreds(true)
        const res = await tokenManager.refreshKateToken()
        if (res?.data?.aws?.tokens?.id && res?.data?.aws?.urls?.chat) {
          window.location.reload()
        } else {
          history.push('/messages')
        }
      } catch (e) {
        Sentry.captureException(e)
      } finally {
        setIsLoadingCreds(false)
      }
    }
    setIsLoadingCreds(false)
  }
  // Get credentials when user do not have them for chat, but they are logged in.
  // After getting new refresh token in, creds to chat will be saved in localstorage.
  // This is needed to load apollo client for chat service.
  useEffect(() => {
    ;(async () => {
      await getChatCreds()
    })()
  }, [])

  if (isLoadingCreds) {
    return <FallbackComponent />
  }

  return (
    <Suspense fallback={<FallbackComponent />}>
      <MuiThemeProvider>
        <Switch>
          <Route path="/chat/:id" component={ChatPage} />
          <Route exact path="/chat">
            <Redirect to={'/chat/new'} />
          </Route>
        </Switch>
      </MuiThemeProvider>
    </Suspense>
  )
}

export default Chat
