import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../../assets/colors'
import Avatar from '../../VideoRoom/components/Avatar'

const Background = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: ${colors.grey};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  z-index: 2;
`

const BiggerCircle = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 150px;
  left: calc(50% - 120px / 2 + 0.5px);
  top: calc(50% - 120px / 2 - 0.5px);
  background: ${colors.mediumGrey};
  backdrop-filter: blur(33.3333px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`

const CircleText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 30px;
  color: ${colors.white};
`

interface IProps {
  name: string
  avatar?: string
}

const NoVideoView: React.FC<IProps> = (props: IProps) => {
  return (
    <>
      <Background></Background>
      {props.avatar !== 'null' && props.avatar ? (
        <Avatar
          src={props.avatar}
          alt={props.name}
          name={''}
          style={{
            width: 150,
            height: 150,
            zIndex: 999,
            position: 'absolute',
            top: 'calc(50% - 150px / 2 - 0.5px)',
            left: 'calc(50% - 150px / 2 + 0.5px)',
            margin: 0
          }}
        />
      ) : (
        <BiggerCircle>
          <CircleText>{props?.name?.[0]?.toUpperCase()}</CircleText>
        </BiggerCircle>
      )}
    </>
  )
}

export default NoVideoView
