import React from 'react'
import styled from 'styled-components'
import IcMute from '../../../../../assets/images/ic_mute.png'

interface IProps {
  className?: string | undefined
  style?: React.CSSProperties
  imgStyle?: React.CSSProperties
}

const Circle = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  top: 10px;
  right: 10px;
  background: #991b1b;
  backdrop-filter: blur(37.5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`

const AudioCircle: React.FC<IProps> = (props: IProps) => {
  return (
    <Circle className={props.className} style={props.style}>
      <img style={props.imgStyle} src={IcMute} alt="mute"></img>
    </Circle>
  )
}

export default AudioCircle
