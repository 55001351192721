import styled from 'styled-components'
import React from 'react'
import { colors } from '../../../assets/colors'

interface AvatarProps {
  width?: string
  fontSize?: string
}

const AvatarImg = styled.img<AvatarProps>`
  width: ${(props) => props.width || '40px'};
  height: ${(props) => props.width || '40px'};
  border-radius: 40px;

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.7;
  }
`

const AddAvatar = styled.div<AvatarProps>`
  width: ${(props) => props.width || '40px'};
  height: ${(props) => props.width || '40px'};
  border-radius: 40px;
  border: 1px solid ${colors.lightGrey};
  color: ${colors.lightGrey};
  text-decoration: none;
  font-size: ${(props) => props.fontSize || '10px'};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.white};
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.7;
  }
`

interface IAvatar {
  src?: string
  width?: string
  fontSize?: string
  onClick?: () => void
}

const Avatar = ({ src, width, fontSize, ...props }: IAvatar) => {
  if (!src) {
    return (
      <AddAvatar width={width} fontSize={fontSize} {...props}>
        Add Photo
      </AddAvatar>
    )
  }
  return <AvatarImg src={src} width={width} {...props} />
}

export default Avatar
