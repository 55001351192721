import React from 'react'
import { colors } from '../../../../../../../assets/colors'
import Icon from '../../../../../../../components/Icon'
import { NewMessagePlaybackBar } from '../../../../../../../components/blocks/Message/NewMessagePlaybackBar'
import { Button } from '../../../../../../../components/Button'
import RecordingVisualizerBox from '../../../../../../../components/blocks/RecordingVisualizerBox'
import useNameRecording from '../../Common/Hooks/useNameRecording'
import styled from 'styled-components'

const StyledButton = styled.button`
  background-color: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
  opacity: 0.92;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
`
const NameRecordingContent = () => {
  const {
    isRecording,
    stopRecording,
    startRecording,
    currentGreeting,
    curTime,
    playCurrent,
    setAudio,
    audio,
    duration,
    playing,
    playProgress,
    setClickedTime,
    playAudio,
    pauseAudio,
    mp3File,
    submitFile
  } = useNameRecording()

  return (
    <>
      <div
        style={{
          width: '100%',
          borderBottom: `1px solid ${colors.lightGrey}`,
          fontSize: '13px',
          paddingBottom: '10px'
        }}
      >
        {currentGreeting.audio ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <p> Current Name Recording</p>
            <div onClick={playCurrent}>
              {currentGreeting.playing ? (
                <StyledButton>
                  <Icon name={'pause'} fill={colors.darkBlue} width={'30px'} />
                </StyledButton>
              ) : (
                <StyledButton>
                  <Icon name={'play'} fill={colors.darkBlue} width={'30px'} />
                </StyledButton>
              )}
            </div>
          </div>
        ) : (
          <p style={{ padding: '11px 0 12px 0' }}>No Name Recorded</p>
        )}
      </div>
      <div
        style={{
          width: '100%',
          fontSize: '13px',
          padding: '10px 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <p>Record New Name</p>
        <div
          onClick={
            isRecording
              ? stopRecording
              : () => {
                  setAudio(null)
                  startRecording()
                }
          }
        >
          {isRecording ? (
            <StyledButton>
              <Icon name={'pause'} fill={colors.darkBlue} width={'30px'} />
            </StyledButton>
          ) : (
            <StyledButton>
              <Icon name={'microphone'} fill={colors.darkBlue} width={'30px'} />
            </StyledButton>
          )}
        </div>
      </div>
      {audio && (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <div style={{ width: 'calc(100% - 30px)' }}>
              <NewMessagePlaybackBar
                curTime={curTime}
                duration={duration}
                playProgress={playProgress}
                onTimeUpdate={(time) => {
                  if (!time) return
                  setClickedTime(time)
                }}
              />
            </div>
            <div onClick={playing ? pauseAudio : playAudio}>
              {playing ? (
                <StyledButton>
                  <Icon name={'pause'} fill={colors.darkBlue} width={'30px'} />
                </StyledButton>
              ) : (
                <StyledButton>
                  <Icon name={'play'} fill={colors.darkBlue} width={'30px'} />
                </StyledButton>
              )}
            </div>
          </div>
          {mp3File && (
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '30px' }}>
              <Button type="button" style={{ width: '150px', marginRight: '20px' }} onClick={submitFile}>
                Save
              </Button>
              <Button
                type="button"
                onClick={() => setAudio(null)}
                style={{ width: '150px', background: colors.lightGrey, color: '#707070' }}
              >
                Cancel
              </Button>
            </div>
          )}
        </>
      )}
      {isRecording && <RecordingVisualizerBox />}
    </>
  )
}

export default NameRecordingContent
