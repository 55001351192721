import styled from 'styled-components'
import React from 'react'
import { colors } from '../../../assets/colors'

const PlaybackProgress = styled.div`
  position: absolute;
  left: 0;
  bottom: -6px;
  width: 0;
  height: 3px;
  border-radius: 3px;
  background-color: ${colors.darkBlue};
`

export default PlaybackProgress
