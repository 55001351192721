import styled from 'styled-components'
import React from 'react'
import { colors } from '../../../../../assets/colors'

const Header = styled.div`
  flex-shrink: 0;
  height: calc(100% - 58px);
  width: 100%;
  background-color: ${colors.white};
  color: ${colors.mediumGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
`

export default Header
