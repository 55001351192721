import React from 'react'
import styled from 'styled-components'

const StyledText = styled.a`
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  color: #707070;
`

const TextContainer = styled.div`
  display: flex;
  white-space: pre-wrap;
  justify-content: center;
  width: 100%;
  height: fit-content;
  padding: 10px 0;
`

interface IProps {
  participants: any
  isHost: boolean
}

const AmountOfParticipants = ({ participants, isHost }: IProps) => {
  const participantsCount = participants?.length

  if (participantsCount === 0) {
    return <StyledText>No one in the call yet</StyledText>
  }

  return (
    <TextContainer>
      <StyledText>
        {participantsCount} participant{participantsCount > 1 ? 's' : ''} in this waiting room. <br />
        {!isHost && "You'll be joined as soon as host starts the call."}
      </StyledText>
    </TextContainer>
  )
}

export default AmountOfParticipants
