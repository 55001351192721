import styled from 'styled-components'
import { colors } from '../../../../../assets/colors'

export const MeetingContainer = styled.div`
  background: ${colors.white};
  box-shadow: 0px 8px 20px rgba(154, 169, 188, 0.1);
  border-radius: 15px;
  width: 700px;
  padding: 15px;
  margin-bottom: 20px;
  @media (max-width: 1023px) {
    width: 100%;
  }
`
