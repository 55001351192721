import React from 'react'
import { cc_format } from '../../Common/utils/CC_format'

const CustomCCInput = ({ ccNumber }: { ccNumber: string }) => {
  return (
    <input
      placeholder={'1234 1234 1234 1234'}
      style={{
        background: 'transparent',
        color: '#171717',
        fontSize: '14px',
        fontWeight: 600,
        marginLeft: '10px'
      }}
      value={cc_format(ccNumber)}
      disabled
    />
  )
}

export default CustomCCInput
