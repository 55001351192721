import styled from 'styled-components'
import { colors } from '../../../../../../assets/colors'

export const MeetingDate = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.mediumGrey};
  margin-left: 5px;
`
