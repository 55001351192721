import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../assets/colors'

const Container = styled.div`
  background-color: ${colors.silver};
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  flex: 1;
  overflow: auto;
`

interface Props {
  children: JSX.Element | JSX.Element[]
}

const TagList: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>
}

export default TagList
