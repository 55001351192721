import React from 'react'
import styled from 'styled-components'
import Icon from '../Icon'
import { colors } from '../../assets/colors'

const StyledLoader = styled.div`
  font-size: inherit;
  position: relative;
`

const StyledLoaderLabel = styled.div`
  font-size: inherit;
  opacity: 0;
`

const SpinnerImage = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const LoaderContainer = styled.div`
  flex: 1;
  background-color: ${colors.silver};
  padding-top: 20px;
`

type LoaderProps = {
  fill?: string
  height?: string
  width?: string
  style?: object
}

export const Loader: React.FC<LoaderProps> = (props) => (
  <StyledLoader>
    <SpinnerImage name="spinner" {...props} />
    <StyledLoaderLabel>Loading...</StyledLoaderLabel>
  </StyledLoader>
)
