import React from 'react'

interface BookmarkProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const CopyToClipboard = ({
  style = {},
  fill = '#000',
  width = '16px',
  className = '',
  viewBox = '0 0 30 18'
}: BookmarkProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
      M11.5 0.5H5.5C4.39543 0.5 3.5 1.39543 3.5 2.5V13C3.5 14.1046 4.39543 15 5.5 15H13.8556C14.9602 15 15.8556 14.1046 15.8556 13V4.5L11.5 0.5Z
      "
    />
    <path
      fill={fill}
      d="
    M2 14.5V4H1C0.447715 4 0 4.44772 0 5V17.5C0 18.0523 0.447715 18.5 1 18.5H11C11.5523 18.5 12 18.0523 12 17.5V16.5H4C2.89543 16.5 2 15.6046 2 14.5Z
    "
    />
  </svg>
)

export default CopyToClipboard
