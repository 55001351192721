import APIClient from '../../../apiClient'
import { MonthApi, Year } from './report.types'
import { useQuery } from '@tanstack/react-query'
import { ContactItemType } from '../../../types'

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const monthTo = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

export function getReports(reports) {
  if (reports && reports?.length) {
    const fullYearsInfo: Year[] = []
    for (let i = 0; i < reports?.length; i++) {
      const y = reports?.[i]?.year
      const monthsToSet = reports?.[i]?.months?.map((month: MonthApi) => {
        return {
          name: monthNames[month.month - 1],
          dollarAmount: month.dollarAmount,
          from: y + '-' + (month.month.toString().length === 1 ? '0' + month.month.toString() : month.month.toString()),
          to:
            y +
            '-' +
            (month.month.toString().length === 1 ? '0' + month.month.toString() : month.month.toString()) +
            '-' +
            monthTo[month.month]
        }
      })

      const yearToSet = reports?.[i]?.year
      const fullYearInfo: Year = {
        year: yearToSet,
        months: monthsToSet
      }
      fullYearsInfo.push(fullYearInfo)
    }
    return fullYearsInfo
  }
}
