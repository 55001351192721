import styled from 'styled-components'

const ActionButton = styled.a`
  padding: 10px 18px;
  gap: 10px;
  background: #f73737;
  border-radius: 100px;
  color: white;
  cursor: pointer;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  &:hover {
    background: #d22c2c;
  }

  &:active {
    background: #f73737;
    opacity: 0.8;
  }
`

export default ActionButton
