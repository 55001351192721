import styled from 'styled-components'

const HeaderLabel = styled.h4`
  margin: 0;
  text-transform: capitalize;
  font-weight: bold;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
`

export default HeaderLabel
