import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../../assets/colors'

const ContactsListWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`

const Box = styled.div`
  width: 4px;
  margin-right: 4px;
  height: 30px;
`

interface IProps {
  strength: number
}

const AudioStrength = (props: IProps) => {
  return (
    <ContactsListWrapper>
      {[2, 4, 6, 8, 10, 12, 14, 16, 19].map((height, index) => (
        <Box
          style={{ backgroundColor: index < props.strength ? colors.darkBlue : 'lightGrey', height: height }}
          key={index}
        />
      ))}
    </ContactsListWrapper>
  )
}

export default AudioStrength
