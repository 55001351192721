import styled, { css } from 'styled-components'
import React, { ReactNode } from 'react'
import { colors } from '../../../assets/colors'
import Status from './Status'
import Avatar from './Avatar'
import Main from './Main'
import ContactName from './ContactName'
import Note from './Note'
import PlaybackProgress from './PlaybackProgress'
import About from './About'
import PlayArea from './PlayArea'
import Details from './Details'
import AudioLength from './AudioLength'
import ClickHandler from './ClickHandler'
import ToContact from './ToContact'
import { Photo } from './Photo'
import AvatarButton from './AvatarButton'
import { isDesktop } from '../../../constants'
import { useUserStore } from '../../../store/user'

const currentMessage = css`
  background-color: ${colors.white};
`
const noBorder = css`
  border: 0;
`

const MessageWrapper = styled.div`
  flex-shrink: 0;
  height: fit-content;
  max-height: 100px;
  min-height: 50px;
  width: 100%;
  background-color: ${colors.silver};
  border-top: 1px solid ${colors.lightSliver};
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.875rem;
  justify-content: stretch;
  padding: 5px 3px;

  ${(props: MessageProps) => props.isCurrentMessage && currentMessage};
  ${(props: MessageProps) => props.noBorder && noBorder};
`

export interface MessageProps {
  onClick?: () => void
  uniqueId?: string
  children?: ReactNode
  isCurrentMessage?: boolean
  timestamp?: number
  new?: boolean
  urgent?: boolean
  length?: number
  noBorder?: boolean
  contact?: {
    has_photo: boolean
    photo_url: string
    first_name: string
    last_name: string
  }
}

const Message = ({ children, ...rest }: MessageProps) => <MessageWrapper {...rest}>{children}</MessageWrapper>

Message.Status = Status
Message.Avatar = Avatar
Message.Main = Main
Message.ClickHandler = ClickHandler
Message.About = About
Message.ContactName = ContactName
Message.Note = Note
Message.PlayArea = PlayArea
Message.AudioLength = AudioLength
Message.Details = Details
Message.PlaybackProgress = PlaybackProgress
Message.ToContact = ToContact
Message.Photo = Photo
Message.AvatarButton = AvatarButton

export default Message
