import { useState, useEffect } from 'react'
import { Howl } from 'howler'
import * as Sentry from '@sentry/react'

function useAudioPlaying() {
  const [audio, setAudio] = useState<Howl | null>(null)
  const [duration, setDuration] = useState<number>(0)
  const [curTime, setCurTime] = useState(0)
  const [playing, setPlaying] = useState(false)
  const [playProgress, setPlayProgress] = useState<number>(0)
  const [clickedTime, setClickedTime] = useState<number>()

  const playAudio = async () => {
    try {
      if (audio && !audio.playing()) audio.play()
    } catch (e) {
      console.log('error on playAudio: ', e)
      Sentry.captureException(e)
    }
  }

  const step = () => {
    if (audio) {
      const seek = audio.seek()
      const progress = (+seek / audio.duration()) * 100
      setPlayProgress(progress)
      setCurTime(+seek)
      if (audio.playing()) requestAnimationFrame(step)
    }
  }

  const onPlaying = () => {
    if (audio) {
      setPlaying(true)
      step()
    }
  }

  const pauseAudio = async () => {
    try {
      if (audio) {
        audio.pause()
        setPlaying(false)
      }
    } catch (e) {
      console.log('pause error: ', e)
    }
  }

  const stopAudio = async () => {
    try {
      if (audio) {
        audio.stop()
        setPlaying(false)
        setPlayProgress(0)
      }
    } catch (e) {
      console.log('stop error: ', e)
    }
  }

  useEffect(() => {
    if (!audio || clickedTime === curTime || !clickedTime) return
    audio.seek(+clickedTime)
  }, [clickedTime])

  useEffect(() => {
    if (!audio) return
    // Set correct duration when audio changed
    if (audio.duration()) setDuration(audio.duration())

    audio.on('play', onPlaying)
    audio.on('end', stopAudio)
    audio.on('seek', step)
    audio.once('load', () => {
      setDuration(audio.duration())
      setCurTime(+audio.seek())
    })

    // effect cleanup
    return () => {
      // Stop audio playing if still playing before unmount
      if (audio.playing()) audio.stop()

      // Remove audio listeners
      audio.off('play', onPlaying)
      audio.off('end', stopAudio)
      audio.off('seek', step)
    }
  }, [audio])

  return {
    audio,
    curTime,
    duration,
    playing,
    playProgress,
    setClickedTime,
    setAudio,
    pauseAudio,
    stopAudio,
    playAudio
  }
}

export default useAudioPlaying
