import styled from 'styled-components'
import { colors } from '../../../assets/colors'
import { Link } from 'react-router-dom'

export const ListSelectionWrapper = styled.div`
  position: absolute;
  height: calc(100vh - 62px);
  width: calc(100vw - 604.5px);
  right: -50px;
  top: -40px;
  z-index: 100;
  background: ${colors.white};
  padding: 40px 50px;
  border-radius: 10px;
`

export const StyledGroupName = styled(Link)`
  border-right: 1px solid grey;
  padding-right: 5px;
  margin-right: 5px;
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
  color: ${colors.darkGrey};

  &:last-of-type {
    border-right: none;
  }
`

export const StyledNavTabLink = styled(Link)`
  font-weight: 600;
  color: ${(props: { active }) => (props?.active ? colors.darkGrey : colors.mediumGrey)};
  border-bottom: ${(props: { active }) => (props?.active ? `2px solid ${colors.darkBlue}` : 'none')};
  padding-bottom: 8px;
  text-decoration: none;
  font-size: 14px;
`
