import React from 'react'

interface LetterProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const Letter = ({ style = {}, fill = '#000', width = '30px', className = '', viewBox = '0 0 30 30' }: LetterProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
M 2.5371094 6 L 13.810547 16.521484 C 14.477547 17.145484 15.523453 17.145484 16.189453 16.521484 L 27.462891 6 L 2.5371094 6 z M 1 7.3007812 L 1 22.585938 L 8.90625 14.679688 L 1 7.3007812 z M 29 7.3007812 L 21.09375 14.679688 L 29 22.585938 L 29 7.3007812 z M 10.367188 16.044922 L 2.4140625 24 L 27.585938 24 L 19.630859 16.044922 L 17.554688 17.982422 C 16.837688 18.651422 15.917047 18.986328 14.998047 18.986328 C 14.079047 18.986328 13.160359 18.651422 12.443359 17.982422 L 10.367188 16.044922 z
"
    />
  </svg>
)

export default Letter
