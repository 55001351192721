import styled from 'styled-components'

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  width: 100%;
`

export default InputContainer
