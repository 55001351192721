import React from 'react'

const AccountBalance = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <p style={{ fontSize: '16px', fontWeight: 600, textAlign: 'center' }}>
        The billing system is currently under maintenance.
      </p>
      <br />
      <p style={{ fontSize: '16px', fontWeight: 600, textAlign: 'center' }}>Sorry for the inconvenience</p>
    </div>
  )
}

export default AccountBalance
