import React from 'react'

interface ImportantProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const Important = ({
  style = {},
  fill = '#000',
  width = '30px',
  className = '',
  viewBox = '0 0 30 30'
}: ImportantProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
      M 12.355469 3 L 12.785156 19 L 17.199219 19 L 17.644531 3 L 12.355469 3 z M 15.007812 22 C 13.364813 22 12.339844 22.953813 12.339844 24.507812 C 12.339844 26.047813 13.365813 27 15.007812 27 C 16.649812 27 17.662109 26.047812 17.662109 24.507812 C 17.662109 22.953813 16.650813 22 15.007812 22 z
"
    />
  </svg>
)

export default Important
