import { useQuery } from '@tanstack/react-query'
import APIClient from '../../apiClient'
import { MessageType } from '../../types'

interface Options {
  params?: {
    confidential?: boolean
  }
}

export const useGetMessagesById = (id: string, confidential = false, options?: Options) => {
  return useQuery<unknown, unknown, MessageType>({
    queryKey: ['message', id, options?.params, confidential],
    queryFn: async () => {
      const res = await APIClient.messagesItem(id, confidential, { ...options?.params })
      return res.data
    },
    enabled: !!id,
    cacheTime: 1000 * 60 * 10 // 10 minutes
  })
}
