import React, { SVGProps } from 'react'

const ImagePreview = ({ width = 14, height = 12 }) => (
  <svg width={width} height={height} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.994452 12C0.629285 12 0.333252 11.7034 0.333252 11.3377V0.662267C0.333252 0.296507 0.636792 0 0.994452 0H13.0054C13.3706 0 13.6666 0.296633 13.6666 0.662267V11.3377C13.6666 11.7035 13.3631 12 13.0054 12H0.994452ZM12.3333 8V1.33333H1.66659V10.6667L8.33325 4L12.3333 8ZM12.3333 9.8856L8.33325 5.8856L3.55221 10.6667H12.3333V9.8856ZM4.33325 5.33333C3.59687 5.33333 2.99992 4.7364 2.99992 4C2.99992 3.26362 3.59687 2.66667 4.33325 2.66667C5.06963 2.66667 5.66658 3.26362 5.66658 4C5.66658 4.7364 5.06963 5.33333 4.33325 5.33333Z"
      fill="white"
    />
  </svg>
)

export default ImagePreview
