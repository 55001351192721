import React from 'react'
import ConferenceNew from './ConferenceNew'
import { SessionProvider } from '../../providers/SessionProvider'

const ConferenceWrapper = () => {
  return (
    <SessionProvider>
      <ConferenceNew />
    </SessionProvider>
  )
}

export default ConferenceWrapper
