import styled from 'styled-components'

const MonthDay = styled.a`
  padding: 8px 5px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #171717;
  width: 36px;
  height: 36px;
  opacity: 0.95;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.7;
  }
  cursor: pointer;
`

export default MonthDay
