import React, { SVGProps } from 'react'

const ChatMessage = ({
  style = {},
  fill = '#000',
  width = '22',
  height = '21',
  className = '',
  viewBox = '0 0 22 21'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M9.4001 0.400024C4.5401 0.400024 0.600098 3.98162 0.600098 8.40002C0.600098 10.67 1.64531 12.7143 3.31572 14.1703C3.07528 14.9527 2.57528 15.7314 1.65947 16.425C1.65886 16.4255 1.65852 16.4261 1.65791 16.4266C1.57076 16.46 1.49806 16.5229 1.45239 16.6043C1.40672 16.6857 1.39095 16.7805 1.40783 16.8723C1.42471 16.9642 1.47316 17.0472 1.5448 17.107C1.61644 17.1669 1.70675 17.1998 1.8001 17.2C1.82848 17.1999 1.85676 17.1968 1.88447 17.1906C3.43217 17.1852 4.7531 16.525 5.80478 15.6969C6.90346 16.1451 8.11788 16.4 9.4001 16.4C14.2601 16.4 18.2001 12.8184 18.2001 8.40002C18.2001 3.98162 14.2601 0.400024 9.4001 0.400024ZM6.2001 6.00002H12.6001C13.0417 6.00002 13.4001 6.35842 13.4001 6.80002C13.4001 7.24162 13.0417 7.60002 12.6001 7.60002H6.2001C5.7585 7.60002 5.4001 7.24162 5.4001 6.80002C5.4001 6.35842 5.7585 6.00002 6.2001 6.00002ZM6.2001 9.20002H11.0001C11.4417 9.20002 11.8001 9.55842 11.8001 10C11.8001 10.4416 11.4417 10.8 11.0001 10.8H6.2001C5.7585 10.8 5.4001 10.4416 5.4001 10C5.4001 9.55842 5.7585 9.20002 6.2001 9.20002ZM19.622 10.1328C18.7972 14.2856 15.0754 17.5148 10.4642 17.95C11.6226 18.9684 13.2273 19.6 15.0001 19.6C15.845 19.6 16.6492 19.4525 17.3876 19.1922C18.3694 19.8781 19.5578 20.3867 20.9188 20.3906C20.9455 20.3965 20.9728 20.3997 21.0001 20.4C21.0939 20.4 21.1847 20.367 21.2567 20.3068C21.3286 20.2466 21.3771 20.163 21.3937 20.0707C21.4102 19.9783 21.3938 19.8831 21.3473 19.8016C21.3008 19.7202 21.2271 19.6577 21.1392 19.625C20.4064 19.069 19.9363 18.4576 19.6548 17.8344C20.7338 16.832 21.4001 15.4857 21.4001 14C21.4001 12.4984 20.7212 11.1384 19.622 10.1328Z"
      fill={fill}
    />
  </svg>
)

export default ChatMessage
