import { useEffect, useState } from 'react'
import appSyncClient from '../../../../../../../gql/client'
import {
  getStripeCustomerQuery,
  StripeCustomerQueryResponse
} from '../../../../../../../gql/queries/getStripeCustomerQuery'

const useGetDefaultPayment = () => {
  const [data, setData] = useState<StripeCustomerQueryResponse | null>(null)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        const getStripeCustomerQueryRes: StripeCustomerQueryResponse = await appSyncClient.graphql({
          query: getStripeCustomerQuery,
          variables: {}
        })
        setData(getStripeCustomerQueryRes)
      } catch (e) {
        setData(null)
        console.error('Error during getting default payment', e)
      } finally {
        setLoading(false)
      }
    }

    getData()
  }, [])

  return {
    data,
    loading
  }
}

export default useGetDefaultPayment
