import styled from 'styled-components'

const StyledTextArea = styled.textarea`
  border-radius: 10px;
  padding: 10px;
  background: rgba(23, 23, 23, 0.05);
  width: 340px;
  height: 80px;
`

export default StyledTextArea
