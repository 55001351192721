import styled from 'styled-components'
import { colors } from '../../../../../../../assets/colors'

interface INavigationAside {
  isMobile?: boolean
  expanded?: boolean
}

export const NavigationAside = styled.aside<INavigationAside>`
  min-height: calc(100vh - 60px);
  width: 200px;
  margin: 5px;
  border-radius: 10px;
  padding-top: 30px;
  background: ${colors.white};
  position: ${({ isMobile }: INavigationAside) => (isMobile ? 'fixed' : 'initial')};
  z-index: ${({ isMobile }: INavigationAside) => (isMobile ? '100' : 'auto')};
  left: ${({ isMobile, expanded }: INavigationAside) => (!isMobile ? '' : expanded ? '0' : '-220px')};
`
