import styled from 'styled-components'
import { colors } from '../../../../assets/colors'

const AboutInfo = styled.div`
  font-size: small;
  color: ${colors.mediumGrey};

  font-weight: normal;

  @media (min-width: 1024px) {
    font-weight: lighter;
  }
`

export default AboutInfo
