import React, { FC } from 'react'
import ArrowDown from '../../../../components/Icon/ArrowDown'

const IconRight: FC = () => {
  return (
    <div style={{ alignItems: 'center', marginLeft: 15 }}>
      <ArrowDown />
    </div>
  )
}

export default IconRight
