import React from 'react'

interface VideocamOffProps {
  style?: object
  fill?: string
  width?: string
  className?: string
  viewBox?: string
}

const VideocamOff = ({
  style = {},
  fill = '#000',
  width = '24px',
  className = '',
  viewBox = '0 0 24 24'
}: VideocamOffProps) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="
      M21 6.5l-4 4V7c0-.55-.45-1-1-1H9.82L21 17.18V6.5zM3.27 2L2 3.27 4.73 6H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.21 0 .39-.08.54-.18L19.73 21 21 19.73 3.27 2z
      "
    />
  </svg>
)

export default VideocamOff
