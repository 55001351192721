import React, { useEffect, useState } from 'react'
import { Button } from '../../../../../../../components/Button'
import { ContactItemType } from '../../../../../../../types'
import ContactRow from '../../../../../../../components/GroupsAndContacts/ContactRow'
import { StyledCreateContactForm } from '../../../../../../desktop/pages/Contacts/Ui/Styled/StyledCreateContactForm'
import { ListSelectionWrapper } from '../../../../../../../components/GroupsAndContacts/Styled'
import { Virtuoso } from 'react-virtuoso'
import { colors } from '../../../../../../../assets/colors'

interface IProps {
  onClose: () => void
  toggleContactId: (id: string) => void
  clearSelection: () => void
  selectedContactsIds: string[]
  data: ContactItemType[]
}

const ContactsSelect = ({ onClose, toggleContactId, selectedContactsIds, data, clearSelection }: IProps) => {
  const [filteredContacts, setFilteredContacts] = useState<ContactItemType[]>([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (search.length >= 2) {
      const foundContacts = data?.filter((contact) => {
        return (
          contact?.first_name?.toLowerCase()?.startsWith(search?.toLowerCase()) ||
          contact?.last_name?.toLowerCase()?.startsWith(search?.toLowerCase())
        )
      })

      setFilteredContacts(foundContacts)
    } else {
      setFilteredContacts(data)
    }
  }, [data, search])

  return (
    <ListSelectionWrapper>
      <StyledCreateContactForm.Wrapper>
        <p style={{ color: colors.darkBlue, fontSize: '14px' }}>Edit group members</p>
        <Button style={{ width: '150px', height: '40px' }} type="submit" onClick={onClose}>
          Done
        </Button>
      </StyledCreateContactForm.Wrapper>
      <div style={{ height: 'calc(100% - 80px)', overflowY: 'auto' }}>
        <Virtuoso
          style={{ height: '100%' }}
          data={filteredContacts}
          itemContent={(_, contact) => {
            return (
              <ContactRow
                onClick={() => toggleContactId(contact.id)}
                type={'groupSelect'}
                nameStyle={{
                  width: '100%',
                  height: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'initial'
                }}
                wrapperStyle={{ paddingLeft: 0, paddingRight: 0 }}
                to={`/contacts?id=${contact.id}`}
                active={false}
                avatar={{
                  initials: `${contact?.first_name ? contact?.first_name?.[0] : ''}${
                    contact?.last_name ? contact?.last_name?.[0] : ''
                  }`,
                  src: contact?.photo_url && contact?.photo_url
                }}
                name={`${contact?.first_name || ''} ${contact?.last_name || ''}`}
                kateNumber={`${contact?.kate_number || ''}`}
                selectedContactsIds={selectedContactsIds}
                currentId={contact.id}
              />
            )
          }}
        />
      </div>
    </ListSelectionWrapper>
  )
}

export default ContactsSelect
