import React from 'react'
import Input from '../../../../../../components/Input'
import Name from '../../../../../../../components/Icon/Name'
import Email from '../../../../../../../components/Icon/Email'
import Button from '../../../../../../components/Button'
import { useInfo } from './useInfo'
import styled from 'styled-components'

const ErrorMessage = styled.p`
  color: #b91c1c;
  margin-top: 5px;
`
const InputWrapper = styled.div`
  margin-top: 5px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const GuestForm = () => {
  const { handleChange, values, handleSubmit, isSubmitting, errors } = useInfo()

  return (
    <>
      <Wrapper
        style={{
          minWidth: '390px'
        }}
      >
        <Wrapper>
          <div style={{ height: '100%', width: '100%' }}>
            <InputWrapper>
              <Input
                onChange={handleChange}
                value={values.firstName}
                label={'First name'}
                name={'firstName'}
                icon={<Name />}
              />
              {errors.firstName && <ErrorMessage>{errors.firstName}</ErrorMessage>}
            </InputWrapper>
            <InputWrapper>
              <Input
                onChange={handleChange}
                value={values.lastName}
                label={'Last name'}
                name={'lastName'}
                icon={<Name />}
              />
              {errors.lastName && <ErrorMessage>{errors.lastName}</ErrorMessage>}
            </InputWrapper>
            <InputWrapper>
              <Input onChange={handleChange} value={values.email} label={'E-mail'} name={'email'} icon={<Email />} />
              {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
            </InputWrapper>
          </div>

          <Button
            style={{ width: 139, height: 46, marginTop: 20 }}
            isLoading={isSubmitting}
            disabled={!!errors.firstName || !!errors.lastName || !!errors.email}
            type="submit"
            onClick={async () => {
              await handleSubmit()
            }}
          >
            Submit
          </Button>
        </Wrapper>
      </Wrapper>
    </>
  )
}

export default GuestForm
