import React from 'react'
import TextAvatar from './TextAvatar'

interface IProps {
  count: number
}

const Avatar = (props: IProps) => {
  return <TextAvatar name={''} text={`+${props.count}`} />
}

export default Avatar
