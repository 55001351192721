import React, { useEffect, useState } from 'react'
import appSyncClient from '../../../../../gql/client'
import { createStripeSetupIntent } from '../../../../../gql/queries/createStripeSetupIntent'
import { loadStripe, StripeElementsOptionsMode } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import 'react-toastify/dist/ReactToastify.min.css'
import { toast, ToastContainer } from 'react-toastify'
import ExistingCardsView from './Ui/Blocks/ExistingCardsView'
import SetupForm from './Ui/Blocks/SetupForm'
import { StyledPaymentOptions } from './Ui/Styled'
import EmptyCardView from './Ui/Blocks/EmptyCardView'
import useCurrentActionTab from './Common/Hooks/useCurrentActionTab'
import useGetPaymentMethods from './Common/Hooks/useGetPaymentMethods'
import { Loader } from '../../../../../components/Loader/Loader'
import { colors } from '../../../../../assets/colors'
import Spinner from '../../../../../components/Icon/Spinner'

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_STRIPE_KEY)

const PaymentOptions = () => {
  const [clientSecret, setClientSecret] = useState('')
  const [currentActionTab] = useCurrentActionTab()
  const { data: cardsData, loading: cardsDataLoading } = useGetPaymentMethods()

  useEffect(() => {
    async function createSetupIntent() {
      try {
        const setupIntent = await appSyncClient.graphql({
          query: createStripeSetupIntent
        })
        const clientSecretValue = setupIntent.data.stripeSetupIntent
        if (clientSecretValue.toLowerCase().includes('error')) {
          throw new Error(clientSecretValue)
        }
        setClientSecret(clientSecretValue)
      } catch (e) {
        console.error('Error during creating setup intent', e)
        toast.error('Something went wrong')
      }
    }

    createSetupIntent()
  }, [])

  const options = {
    clientSecret: clientSecret
  } as StripeElementsOptionsMode

  if (cardsDataLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spinner width={'20px'} fill={colors.darkBlue} />
      </div>
    )
  }

  return (
    <>
      {clientSecret && (
        <StyledPaymentOptions.Wrapper>
          <Elements
            stripe={stripePromise}
            options={{
              ...options,
              appearance: {
                theme: 'stripe',
                variables: {
                  borderRadius: '10px',
                  focusBoxShadow: 'none'
                },
                rules: {
                  '.Input::placeholder': {
                    color: 'transparent'
                  }
                }
              }
            }}
          >
            {currentActionTab === 'add' ? (
              <SetupForm />
            ) : cardsData?.data?.length ? (
              <ExistingCardsView cardsData={cardsData} />
            ) : (
              <EmptyCardView />
            )}
          </Elements>
        </StyledPaymentOptions.Wrapper>
      )}
      <ToastContainer position="bottom-right" />
    </>
  )
}

export default PaymentOptions
