import { useEffect, useState } from 'react'
import appSyncClient from '../../../../../../../gql/client'
import {
  getStripeCustomerPaymentMethodsQuery,
  StripePaymentMethodResponse
} from '../../../../../../../gql/queries/getStripeCustomerPaymentMethodsQuery'

const useGetPaymentMethods = () => {
  const [data, setData] = useState<StripePaymentMethodResponse | null>(null)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        const paymentsRes = await appSyncClient.graphql({
          query: getStripeCustomerPaymentMethodsQuery,
          variables: {}
        })
        setData(paymentsRes?.data?.getStripeCustomerPaymentMethods as StripePaymentMethodResponse)
      } catch (e) {
        setData(null)
        console.error('Error during fetching payment methods', e)
      } finally {
        setLoading(false)
      }
    }

    getData()
  }, [])

  return {
    data,
    loading
  }
}

export default useGetPaymentMethods
