import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { colors } from '../../../assets/colors'

interface IProps {
  children: ReactNode
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  min-height: fit-content;
`

const Title = styled.div`
  color: ${colors.mediumGrey};
  font-size: 14px;
  line-height: 1.2;
  padding: 0 0 5px 30px;

  font-weight: normal;

  @media (min-width: 1024px) {
    font-weight: lighter;
  }
`

const GroupContent = styled.div`
  width: 100%;
  padding: 0 0 0 60px;
  flex: 1;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

const Item = styled.div`
  padding: 5px 5px 5px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`

const TagGroup = ({ children }: IProps) => {
  return <Container>{children}</Container>
}

TagGroup.Title = Title
TagGroup.Content = GroupContent
TagGroup.Item = Item

export default TagGroup
