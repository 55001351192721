import React from 'react'
import WaitingRoom from '../../../../../components/blocks/WaitingRoom'
import styled from 'styled-components'
import { useGetContact } from '../../../../../../hooks/queries/useGetContact'
import { useGetAllContacts } from '../../../../../../hooks/queries/useGetAllContacts'

const AvatarRowContainer = styled.div<{ isMoreThanFour?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    justify-content: ${({ isMoreThanFour }) => (isMoreThanFour ? 'flex-start' : 'center')};
    flex-wrap: wrap;
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 15px 0;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    width: 380px;
    min-height: 220px;
    height: 100%;
    padding: 15px 0;
  }
`

const RoomParticipants = ({ participants }) => {
  return (
    <Container>
      <AvatarRowContainer isMoreThanFour={participants?.length > 4}>
        {participants
          ?.slice(0, 7)
          .map((guest: any, index: any) =>
            guest?.avatar ? (
              <WaitingRoom.Avatar
                key={index}
                alt={guest?.customerName?.split(' ')[0]}
                src={guest?.avatar}
                name={guest?.customerName?.split(' ')[0]}
              />
            ) : (
              <WaitingRoom.TextAvatar
                key={index}
                text={guest?.customerName?.substring(0, 1)}
                name={guest?.customerName?.split(' ')[0]}
              />
            )
          )}
        {participants?.length > 7 ? <WaitingRoom.NumberAvatar count={participants?.length - 7} /> : null}
      </AvatarRowContainer>
    </Container>
  )
}

export default RoomParticipants
