import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import '../../styles.css'
import VideoRoom from '../../../../components/blocks/VideoRoom'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { showAlertModal } from '../../../../../store/modals'
import { useVideoActions, useVideoStore, VideoStateEnum } from '../../../../store/video'
import apiClient from '../../../../../apiClient'
import StreamingLive from './components/StreamingLive'

import { useSession } from '../../providers/SessionProvider'
import { SubscribersContainer } from './newComp/Subscriber'
import { Publisher } from './newComp/Publisher'
import { ScreenPublisher } from './newComp/Screenshare'
import { ScreenSubscriber } from './newComp/ScreenSubscriber'
import { FullscreenProcessingLoader } from '../../../../../components/Loader/FullscreenProcessingLoader'
import ScreenShareOverlay from './newComp/ScreenShareOverlay'
import { useAvatar } from '../../../../../store/user'

const ACCESS_DENIED = 'OT_USER_MEDIA_ACCESS_DENIED'

const sharingScreenStyles = css`
  position: relative;
  flex-direction: column; // TODO: ScreenSharing
  align-items: flex-end; // TODO: ScreenSharing
  justify-content: flex-start;
  width: 20%;
  order: 2;
  margin: 0;

  @media (min-width: 1281px) {
    width: 16%;
  }
`

const ParticipantsContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

const SubContainer = styled.div<{ isSharingScreen?: boolean }>`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  margin: 5px;
  ${({ isSharingScreen }) => isSharingScreen && sharingScreenStyles}
`

export const VISIBLE_STREAMS = 8
export const VISIBLE_STREAMS_SCREENSHARE = 3

const Conference: React.FC = () => {
  const history = useHistory()
  const { id: meetingId } = useParams<{ id: string }>()
  const userAvatar = useAvatar()
  const { session, streams, sharedStream, isLoading, setIsLoading } = useSession()
  const publisher = useVideoStore((state) => state.publisherState)

  const [blurredBackground, setBlurredBackground] = useState(false)

  const sharingScreen = useVideoStore((state) => state.sharingScreen)
  const audio = useVideoStore((state) => state.audio)
  const video = useVideoStore((state) => state.video)

  const [broadcastId, setBroadcastId] = useState<string | null>(null)

  const { setVideoRoomState, setRoom, setVideoData, setSharingScreen, setAudio, setVideo } = useVideoActions()

  useEffect(() => {
    return () => {
      session?.disconnect()
      setRoom(null)
      setVideoData(null)
      setVideoRoomState(VideoStateEnum.WaitingRoom)
      setSharingScreen(false)
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
  }, [])

  useEffect(() => {
    ;(async () => {
      const res = await apiClient.getBroadcast(meetingId)
      if (res.data.status !== 'stopped' && res?.data?.broadcastUrls?.rtmp?.length) {
        setBroadcastId(res.data.id)
      }
    })()
  }, [meetingId])

  const toggleAudio = () => {
    if (!publisher) return
    setAudio(!publisher?.stream?.hasAudio)
    publisher?.publishAudio(!publisher?.stream?.hasAudio)
  }

  const toggleVideo = () => {
    if (!publisher) return
    setVideo(!publisher?.stream?.hasVideo)
    publisher?.publishVideo(!publisher?.stream?.hasVideo)
  }

  const toggleBlurredBackground = async () => {
    const videoFilter = publisher?.getVideoFilter()
    if (videoFilter?.type === 'backgroundBlur') {
      await publisher?.clearVideoFilter()
      setBlurredBackground(false)
      return
    }
    await publisher?.applyVideoFilter({ type: 'backgroundBlur', blurStrength: 'high' })
    setBlurredBackground(true)
  }

  const toggleSharingScreen = () => {
    if (sharedStream) {
      showAlertModal(`You cannot share the screen while someone else is doing it.`)
      setSharingScreen(false)
      return
    }
    if (sharingScreen) {
      setSharingScreen(false)
      return
    }
    setSharingScreen(true)
  }

  return (
    <>
      <VideoRoom>
        {broadcastId && <StreamingLive broadcastId={broadcastId} setBroadcastId={setBroadcastId} />}
        <ParticipantsContainer>
          {sharingScreen && (
            <div style={{ width: '85%', height: '90%', margin: '5px 15px 5px 10px', position: 'relative' }}>
              <ScreenShareOverlay>
                <ScreenPublisher />
              </ScreenShareOverlay>
            </div>
          )}

          {!!sharedStream && (
            <div style={{ width: '100%', height: '100%' }}>
              <ScreenSubscriber />
            </div>
          )}
          <SubContainer isSharingScreen={sharingScreen || !!sharedStream}>
            <Publisher
              isSharingScreen={sharingScreen || !!sharedStream}
              video={video}
              audio={audio}
              name={publisher?.stream?.name}
            />
            <SubscribersContainer isSharingScreen={sharingScreen || !!sharedStream} />
          </SubContainer>
        </ParticipantsContainer>
        <VideoRoom.Footer>
          <VideoRoom.MainControls
            participants={streams?.filter((str) => str.videoType !== 'screen').length + 1}
            url={`${window.location.origin}/conference/${meetingId}`}
            video={video}
            audio={audio}
            screenshare={sharingScreen}
            toggleAudio={() => toggleAudio()}
            toggleVideo={() => toggleVideo()}
            toggleScreenshare={() => {
              toggleSharingScreen()
            }}
            blurredBackground={blurredBackground}
            toggleBlurredBackground={() => toggleBlurredBackground()}
            quitCall={() => {
              session?.disconnect()
              publisher?.destroy()
              setRoom(null)
              setVideoData(null)
              setVideoRoomState(VideoStateEnum.WaitingRoom)
              if (broadcastId) {
                apiClient.stopBroadcast(meetingId, broadcastId)
                setBroadcastId(null)
              }
              history.push('/video-conferencing')
            }}
          />
        </VideoRoom.Footer>
      </VideoRoom>
      {isLoading && <FullscreenProcessingLoader name={'Joining...'} progressBar={false} />}
    </>
  )
}

export default Conference
