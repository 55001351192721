import React from 'react'
import RoundedAvatar from '../../../../../components/RoundedAvatar'
import Webcam from 'react-webcam'
import RoundedIconsContainer from '../../../../../components/RoundedIconsContainer'
import RoundedIcon from '../../../../../components/RoundedIcon'
import IcNoVideo from '../../../../../../assets/images/ic_no_video.png'
import IcVideo from '../../../../../../assets/images/ic_video.png'
import IcMute from '../../../../../../assets/images/ic_mute.png'
import IcUnmute from '../../../../../../assets/images/ic_unmute.png'
import WebcamContainer from '../../../../../components/WebcamContainer'
import styled from 'styled-components'
import { useAvatar, useUser, useUserStore } from '../../../../../../store/user'
import { useVideoActions, useVideoStore } from '../../../../../store/video'

const StyledWebcam = styled(Webcam)`
  max-width: 640px;
  width: 100%;
  height: 480px;
  border-radius: 10px;
`

const CameraPreview = () => {
  const authenticated = useUserStore((state) => state.isAuthenticated)
  const audio = useVideoStore((state) => state.audio)
  const video = useVideoStore((state) => state.video)
  const { toggleAudio, toggleVideo } = useVideoActions()
  const user = useUser()
  const userAvatar = useAvatar()

  const guestFirstName = localStorage.getItem('guestFirstName')

  const firstNameInitials = authenticated ? user?.first_name?.charAt(0) || '' : guestFirstName?.charAt(0) || ''

  return (
    <WebcamContainer>
      <RoundedAvatar text={firstNameInitials} avatar={userAvatar} />
      <StyledWebcam mirrored hidden={!video} style={{ position: 'absolute', objectFit: 'cover', borderRadius: 10 }} />
      <RoundedIconsContainer>
        <RoundedIcon onClick={() => toggleVideo()} selected={video} notSelectedSrc={IcNoVideo} selectedSrc={IcVideo} />
        <RoundedIcon onClick={() => toggleAudio()} selected={audio} notSelectedSrc={IcMute} selectedSrc={IcUnmute} />
      </RoundedIconsContainer>
    </WebcamContainer>
  )
}

export default CameraPreview
