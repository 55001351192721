import styled from 'styled-components'

export const LeftContainer = styled.div`
  @media (min-width: 1024px) {
    flex: 1;
    max-width: 405px;
  }
  @media (max-width: 1023px) {
    max-width: 600px;
    width: 100%;
    margin-bottom: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`
