import { showAlertModal } from '../../../store/modals'
import { Howl } from 'howler'
// @ts-ignore
import MicRecorder from 'mic-recorder-to-mp3'
import { useEffect, useState } from 'react'
import { useNewMessageActions, useNewMessageStore } from '../../mobile/pages/NewMessage/store/NewMessageStore'
import { useAudioPlayingContext } from '../../mobile/pages/NewMessage/provider/AudioPlayingProvider'

const timeLimit = 240 // 4min limit

const formatCountdownTimer = (length: number) => {
  const minutes = Math.floor(length / 60)
  const seconds = length - minutes * 60

  return `${minutes ? minutes + 'm' : ''} ${seconds ? seconds + 's' : '00s'}`
}

export const useRecording = () => {
  const [Mp3Recorder] = useState(new MicRecorder({ bitRate: 128, sampleRate: 22050 }))
  const { setIsRecording, setAfterRecording, setMp3File } = useNewMessageActions()
  const isRecording = useNewMessageStore((state) => state.isRecording)
  const { setAudio } = useAudioPlayingContext()

  // Audio record timer
  const [timeLeft, setTimeLeft] = useState(timeLimit)
  const [formattedTimeLeft, setFormattedTimeLeft] = useState('')
  const [countDownTimerId, setCountDownTimerId] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (timeLeft && isRecording) {
      setFormattedTimeLeft(formatCountdownTimer(timeLeft))
    }
    if (isRecording && timeLeft === 0) {
      stopRecording()
    }
  }, [timeLeft])

  const startRecordCountDownTimer = () => {
    if (countDownTimerId) clearInterval(countDownTimerId)
    setCountDownTimerId(
      setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1)
      }, 1000)
    )
  }

  const stopRecordCountDownTimer = () => {
    if (countDownTimerId) clearInterval(countDownTimerId)
    setTimeLeft(timeLimit)
  }

  const startRecording = async () => {
    try {
      const recordingStarted = await Mp3Recorder.start()
      if (recordingStarted) {
        startRecordCountDownTimer()
        setIsRecording(true)
      }
    } catch (e: any) {
      console.log('error on startRecording:', e)
      showAlertModal(e.toString())
    }
  }

  const stopRecording = async () => {
    try {
      stopRecordCountDownTimer()
      const [buffer, blob] = await Mp3Recorder.stop().getMp3()

      if (buffer && blob) {
        const timestamp = Date.now()

        const file = new File(buffer, `audio-message-${timestamp}.mp3`, {
          type: 'audio/mpeg',
          lastModified: Date.now()
        })

        const blobURL = URL.createObjectURL(file)
        setMp3File(file)
        setIsRecording(false)
        setAudio(
          new Howl({
            html5: true,
            src: blobURL,
            format: ['mp3']
          })
        )
        setAfterRecording(true)
      }
    } catch (e: any) {
      showAlertModal(`error on stop recording: ${e.toString()}`)
      console.log('error on stopRecording:', e)
    }
  }

  return {
    startRecording,
    stopRecording,
    timeLeft,
    formattedTimeLeft,
    formatCountdownTimer
  }
}
