import styled from 'styled-components'
import React, { ReactNode } from 'react'
import { colors } from '../../../assets/colors'
import Text from './Text'
import Caption from './Caption'
interface IProps {
  children: ReactNode
  onClick?: () => void
  style?: { [key: string]: any }
  clickable?: boolean
}
const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.silver};
  color: ${colors.mediumGrey};
  text-decoration: none;
  border: 0;
  font-size: 14px;
  padding: 10px;
  min-height: 36px;
  width: 100%;
  cursor: ${(props: { clickable: boolean }) => (props?.clickable ? 'pointer' : 'default')};

  &:hover {
    background-color: ${(props: { clickable: boolean }) => (props?.clickable ? '#E3E3E3' : colors.silver)};
  }
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.lightSliver};
  }
`
const Options = ({ children, onClick, style, clickable }: IProps) => (
  <Wrapper onClick={onClick} style={style} clickable={clickable}>
    {children}
  </Wrapper>
)
Options.Text = Text
Options.Caption = Caption
export default Options
