export const getConfigQuery = `
  query getConfigQueryNameGraphqlQueryConvention {
    getConfig{
        recentMessageCutoff
        fallbackToNextgenuc
        isUnderMaintenance
        videoBucket
        thumbnailBucket
        cognito {
          identityIds {
            chatS3ImageDownload
          }
        }
        nextgenucCloudfront
        microservices {
            mediaIn
            chat
        }
        minimumRequiredAppVersion
        s3Accelerate
        billing {
            accountNeedsAttention {
                eventType
                ... on NextAction {
                 paymentNextActionSecret
                }
                ... on AchVerificationAction {
                 hostedVerificationUrl
                }
            }
        }
    }
}
`

type AccountNeedsAttentionEvent = {
  eventType: string
  paymentNextActionSecret?: string
  hostedVerificationUrl?: string
}

export type GetConfigQueryResponse = {
  data: {
    getConfig: {
      cognito: {
        identityIds: {
          chatS3ImageDownload: string
        }
      }
      billing: {
        accountNeedsAttention: AccountNeedsAttentionEvent[]
      }
    }
  }
}
