import styled from 'styled-components'

const StyledText = styled.a`
  font-size: 14px;
  white-space: nowrap;
  text-align: center;
  line-height: 20px;
  font-color: #707070;
`

interface ContainerProps {
  height?: number
}

const InfoContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 100%;
  margin-left: 0;

  @media (min-width: 768px) {
    flex: 1;
    height: 480px;
    width: 100%;
    margin-left: 5%;
  }
`

const JoinNowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    padding-bottom: 0;
    justify-content: flex-end;
  }
`

export { StyledText, InfoContainer, JoinNowContainer }
