import React, { FC } from 'react'
import styled from 'styled-components'
import { colors } from '../../../../assets/colors'

type ReportRectangleProps = {
  month: string
  price: string
}

const StyledRectangle = styled.div`
  background: ${colors.white};
  border-radius: 15px;
  width: 154px;
  height: 120px;
  radius: 15px;
  margin-right: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 8px 20px rgba(154, 169, 188, 0.18);
  &:active {
    background: linear-gradient(224.26deg, ${colors.blue} -23.22%, ${colors.darkBlue} 123.1%);
    box-shadow: 0px 0px 0px rgba(154, 169, 188, 0.18);
    & > p {
       color: ${colors.white}};
    }
    cursor: pointer;
  }
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 700px) {
    width: calc(25vw - 20px);
    height: calc(0.7 * (25vw - 20px));
  }
`

const MonthText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.veryDark};
  text-decoration: none;
  @media (max-width: 500px) {
    font-size: 13px;
    line-height: 20px;
  }
  @media (max-width: 410px) {
    font-size: 12px;
    line-height: 16px;
  }
`

const PriceText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.darkBlue};
  margin-top: 5px;
  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 14px;
  }
  @media (max-width: 410px) {
    font-size: 10px;
    line-height: 14px;
  }
`

const ReportRectangle: FC<ReportRectangleProps> = ({ month, price }) => {
  return (
    <StyledRectangle>
      <MonthText>{month}</MonthText>
      <PriceText>{price}</PriceText>
    </StyledRectangle>
  )
}

export default ReportRectangle
