import React, { SVGProps } from 'react'

const Participants = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 6.35c.904 0 1.771.342 2.41.952.64.61 1 1.436 1 2.298v3.9H4.59V9.6c0-.862.36-1.689 1-2.298A3.496 3.496 0 0 1 8 6.35ZM2.742 8.304a4.347 4.347 0 0 0-.191 1.072l-.006.224v3.9H.5v-2.925c0-.56.217-1.101.61-1.519a2.427 2.427 0 0 1 1.517-.743l.115-.01Zm10.516 0c.607.035 1.177.29 1.593.712.417.422.649.98.649 1.559V13.5h-2.046V9.6c0-.45-.068-.885-.196-1.296ZM2.886 4.4c.452 0 .886.171 1.206.476.32.305.499.718.499 1.149 0 .431-.18.844-.5 1.149-.319.305-.753.476-1.205.476-.452 0-.885-.171-1.205-.476-.32-.305-.5-.718-.5-1.149 0-.431.18-.844.5-1.15.32-.304.753-.475 1.205-.475Zm10.228 0c.452 0 .885.171 1.205.476.32.305.5.718.5 1.149 0 .431-.18.844-.5 1.149-.32.305-.753.476-1.205.476-.452 0-.886-.171-1.206-.476-.32-.305-.499-.718-.499-1.149 0-.431.18-.844.5-1.15.319-.304.753-.475 1.205-.475ZM8 .5c.723 0 1.417.274 1.928.762a2.54 2.54 0 0 1 .8 1.838c0 .69-.288 1.35-.8 1.838A2.796 2.796 0 0 1 8 5.7a2.796 2.796 0 0 1-1.928-.762 2.54 2.54 0 0 1-.8-1.838c0-.69.288-1.35.8-1.838A2.796 2.796 0 0 1 8 .5Z"
      fill="#1D70B6"
    />
  </svg>
)

export default Participants
