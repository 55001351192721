import styled from 'styled-components'

const CancelButton = styled.a`
  padding: 10px 18px;
  gap: 10px;
  background: rgba(23, 23, 23, 0.05);
  border-radius: 100px;
  cursor: pointer;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  &:hover {
    background: rgba(23, 23, 23, 0.1);
  }

  &:active {
    background: rgba(23, 23, 23, 0.05);
    opacity: 0.8;
  }
`

export default CancelButton
