import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CloseImageModal from '../../../../../../components/Icon/CloseImageModal'
import { colors } from '../../../../../../assets/colors'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  height: '75%',
  boxShadow: 24,
  backgroundColor: colors.silver
}

function ImageModal({ visible, onClose, imageUrl }) {
  return (
    <>
      <Modal
        open={visible}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <Button onClick={() => onClose()} sx={{ position: 'absolute', right: -13 }}>
              <CloseImageModal />
            </Button>
            <img
              src={imageUrl}
              alt="Full Size"
              style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: 10 }}
            />
          </Box>
        </>
      </Modal>
    </>
  )
}

export default ImageModal
