import React from 'react'
import ActionButton from './components/ActionButton'
import CancelButton from './components/CancelButton'
import DialogContainer from './components/DialogContainer'
import DialogDescription from './components/DialogDescription'
import DialogTitle from './components/DialogTitle'
import ButtonsContainer from './components/ButtonsContainer'

type ConfirmDialogProps = {
  title: string
  message: string
  actionName: string
  onCancelPress?: () => void
  onActionPress?: () => void
  showCancel?: boolean
}

const ConfirmDialog = ({
  title,
  message,
  actionName,
  onCancelPress,
  onActionPress,
  showCancel = true
}: ConfirmDialogProps) => {
  return (
    <DialogContainer>
      <DialogTitle>{title}</DialogTitle>
      <DialogDescription>{message}</DialogDescription>
      <ButtonsContainer>
        {showCancel ? <CancelButton onClick={onCancelPress}>Cancel</CancelButton> : null}
        <ActionButton onClick={onActionPress}>{actionName}</ActionButton>
      </ButtonsContainer>
    </DialogContainer>
  )
}

export default ConfirmDialog
