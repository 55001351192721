import React from 'react'
import SearchInput from '../../../../../../../components/GroupsAndContacts/ContactsGroupsSearchInput'
import { colors } from '../../../../../../../assets/colors'
import { CentralPanel } from '../../../../../../../layout/Layout'
import { useHistory, useLocation } from 'react-router'
import { ContactItemType } from '../../../../../../../types'
import { Loader } from '../../../../../../../components/Loader/Loader'
import ContactRow from '../../../../../../../components/GroupsAndContacts/ContactRow'
import { Virtuoso } from 'react-virtuoso'
import { StyledNavTabLink } from '../../../../../../../components/GroupsAndContacts/Styled'
import useCurrentParam from '../../../../../../../hooks/useCurrentParam'
import Exit from '../../../../../../../components/Icon/Exit'

interface IProps {
  // search: string
  currentContactId: string
  filteredContacts?: ContactItemType[]
  isLoading: boolean
}

const ContactsListPanel = ({ search, currentContactId, filteredContacts, isLoading }: IProps) => {
  const history = useHistory()
  const location = useLocation()
  const [currentTab] = useCurrentParam<'create' | 'edit' | null>('action')

  return (
    <CentralPanel style={{ paddingTop: '30px' }}>
      <div style={{ paddingLeft: '35px', display: 'flex' }}>
        <StyledNavTabLink active to="/contacts" style={{ marginRight: '40px' }}>
          Contacts
        </StyledNavTabLink>
        <StyledNavTabLink to="/groups">Groups</StyledNavTabLink>
      </div>
      <div>
        <SearchInput
          onClick={() => {
            history.replace('/contacts')
          }}
          onChange={(e: { target: { value: string } }) => {
            // Parse the current query string
            const queryParams = new URLSearchParams(location.search)

            // Update the 'filter' parameter
            queryParams.set('filter', e.target.value)

            // Construct the new query string
            const newQueryString = queryParams.toString()

            // Update the URL
            history.replace(`/contacts?${newQueryString}`)
          }}
          value={search}
        />
      </div>
      <div style={{ marginTop: '15px', overflowY: 'auto', height: '100%' }}>
        {isLoading && <Loader width={'20px'} height={'20px'} fill={colors.lightGrey} />}
        <Virtuoso
          style={{ height: '100%' }}
          data={filteredContacts}
          itemContent={(_, contact) => {
            return (
              <ContactRow
                to={search ? `/contacts?id=${contact.id}&filter=${search}` : `/contacts?id=${contact.id}`}
                active={contact.id === currentContactId}
                avatar={{
                  initials: `${contact?.first_name ? contact?.first_name?.[0] : ''}${
                    contact?.last_name ? contact?.last_name?.[0] : ''
                  }`,
                  src: contact?.photo_url && contact?.photo_url
                }}
                name={`${contact?.first_name || ''} ${contact?.last_name || ''}`}
                kateNumber={`${contact?.kate_number || ''}`}
              />
            )
          }}
        />
      </div>
    </CentralPanel>
  )
}

export default ContactsListPanel
