import { useLocation } from 'react-router-dom'

type TTabs = null | 'add'

const useCurrentActionTab = () => {
  const searchParams = new URLSearchParams(useLocation().search)
  const currentTab = searchParams.get('action') as TTabs

  return [currentTab]
}

export default useCurrentActionTab
