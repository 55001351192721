import styled from 'styled-components'
import { colors } from '../../assets/colors'

const RedButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.red};
  color: ${colors.white};
  border: 0;
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  flex-shrink: 0;
  outline: 0;
  margin: 0;
  &:hover {
    background-color: #b60000;
    opacity: 1;
  }
  &:active {
    background-color: ${colors.red};
    opacity: 0.8;
  }
`

export default RedButton
