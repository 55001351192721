import Modal from '../../../../components/Modal'
import React from 'react'
import { colors } from '../../../../assets/colors'

interface IConfirmModal {
  show: boolean
  onConfirm: () => void
  onCancel: () => void
}

export const ConfirmDiscardMessageModal = ({ show, onConfirm, onCancel }: IConfirmModal) => (
  <Modal show={show}>
    <Modal.TextContainer>
      <Modal.HeroText>Are you sure you want to discard this voice mail?</Modal.HeroText>
    </Modal.TextContainer>
    <Modal.ButtonsContainer>
      <Modal.Button onClick={onCancel} bold color={colors.contrastBlue}>
        Keep it
      </Modal.Button>
      <Modal.Button onClick={onConfirm} color={colors.red}>
        Discard
      </Modal.Button>
    </Modal.ButtonsContainer>
  </Modal>
)
