import styled from 'styled-components'
import { colors } from '../../../../assets/colors'

export const MonthText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.veryDark}
}
`
