import React from 'react'
import Forward from './forward'

export interface IForwardPage {
  label: string
  contactId?: string
  loading: boolean
  contact?: any
}

const ForwardMessage = (props: IForwardPage) => {
  return (
    <>
      <Forward {...props} />
    </>
  )
}

export default ForwardMessage
